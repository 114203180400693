<template>
  
  <div class="bg-[#464851] text-white rounded-xl p-4"  v-if="loading">
    <block-loading/>
  </div>

  <div class="pb-2"  v-else>
    <div class="bg-theme-green border-0 rounded-t-xl px-4 py-2 text-white">
      <div class="flex justify-between items-center">
        <h2 class="font-semibold leading-tight text-2xl mt-0">Merge Product</h2>
        <span @click="closeMergeProducts" class="cursor-pointer rounded-md  text-white py-2 pl-1  hover:opacity-70">CLOSE</span>
      </div>
    </div>

    <div class="pt-3 bg-[#2B2C32] px-[16px] py-[16px] rounded-lg">
      <div class="max-h-[600px] pr-5 pl-2">
        <div v-if="forceMerge" class="border border-theme-green bg-theme-green/[0.3] rounded-lg px-2 py-2 mt-4">
            <div>
                <business-search-select placeholder="Search" :selectedIds="data" @select="selectForceProductItem" />
            </div>
            <div class="text-sm py-2 " >Please search product you would like to merge</div>
            <div class="grid grid-cols-2 gap-1 mt-2 mb-1">
                <button @click="forceMerge=false" class="bg-theme-green hover:bg-theme-green-300 border-theme-green w-full border rounded uppercase  py-2">Back</button>
                <button :disabled="!mergeIndex && !mergeProduct.id" class="w-full border border-theme-green rounded uppercase py-2" :class="(mergeIndex || mergeProduct.id)?'bg-theme-green hover:bg-theme-green-300 border-theme-green':'border-transparent bg-field-black text-theme-modalbg'" @click="confirmForceMerge">Confirm</button>
            </div>

        </div>
        <div v-else class="border border-theme-orange bg-theme-orange/[0.1] rounded-lg px-2 py-1 mt-4">
            <div class="flex flex-row">
                <div class="pt-1 pr-2">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.325 11.175H10.825V4.85H9.325V11.175ZM10 15C10.2333 15 10.4292 14.9208 10.5875 14.7625C10.7458 14.6042 10.825 14.4083 10.825 14.175C10.825 13.9417 10.7458 13.7458 10.5875 13.5875C10.4292 13.4292 10.2333 13.35 10 13.35C9.76667 13.35 9.57083 13.4292 9.4125 13.5875C9.25417 13.7458 9.175 13.9417 9.175 14.175C9.175 14.4083 9.25417 14.6042 9.4125 14.7625C9.57083 14.9208 9.76667 15 10 15ZM10 20C8.58333 20 7.26667 19.7458 6.05 19.2375C4.83333 18.7292 3.775 18.025 2.875 17.125C1.975 16.225 1.27083 15.1667 0.7625 13.95C0.254167 12.7333 0 11.4167 0 10C0 8.6 0.254167 7.29167 0.7625 6.075C1.27083 4.85833 1.975 3.8 2.875 2.9C3.775 2 4.83333 1.29167 6.05 0.775C7.26667 0.258333 8.58333 0 10 0C11.4 0 12.7083 0.258333 13.925 0.775C15.1417 1.29167 16.2 2 17.1 2.9C18 3.8 18.7083 4.85833 19.225 6.075C19.7417 7.29167 20 8.6 20 10C20 11.4167 19.7417 12.7333 19.225 13.95C18.7083 15.1667 18 16.225 17.1 17.125C16.2 18.025 15.1417 18.7292 13.925 19.2375C12.7083 19.7458 11.4 20 10 20ZM10 18.5C12.3333 18.5 14.3333 17.6667 16 16C17.6667 14.3333 18.5 12.3333 18.5 10C18.5 7.66667 17.6667 5.66667 16 4C14.3333 2.33333 12.3333 1.5 10 1.5C7.66667 1.5 5.66667 2.33333 4 4C2.33333 5.66667 1.5 7.66667 1.5 10C1.5 12.3333 2.33333 14.3333 4 16C5.66667 17.6667 7.66667 18.5 10 18.5Z" fill="#FCB852"/>
                    </svg>
                </div>
                <div class="font-bold pb-2">This is a brand new product</div>
            </div>
            <div class="text-sm pb-8">We couldn’t find any potential master products that that could be associated with this GTIN.</div>
            <div class="grid grid-cols-2 gap-1 mt-2 mb-1">
                <button class="bg-theme-green hover:bg-theme-green-300 border-theme-green w-full border rounded uppercase  py-2" @click="doForceMerge">Force Merge</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent } from 'vue';
import BusinessSearchSelect from '../Add/businessSearchSelect'
import {mapGetters, mapMutations} from 'vuex'
import { useSnackbar } from "vue3-snackbar"
import ProductApi from '@/controllers/BusinessProductApis'

export default defineComponent({
    props: ['data'],
    components: {
        BusinessSearchSelect,
    },
    setup() {
        const snackbar = useSnackbar();
        const forceMerge = ref(false)
        const mergeIndex = ref('')
        const mergeProduct = ref({})

        return {snackbar, forceMerge, mergeIndex, mergeProduct}
    },
    computed: {
        ...mapGetters({
            vg_locationSelected: "location/selected",
        })
    },
    methods: {
        selectForceProductItem(productObj){
            this.mergeIndex = null
            this.mergeProduct = productObj
        },
        doForceMerge(){
            this.forceMerge = true
        },
        async confirmForceMerge(){
            if(this.data?.length>0){
                this.vm_spinner(true)
                let variantIds = this.data.map(each => parseInt(each))
                let mergeData = {
                    variantIds: variantIds,
                    locationId: this.vg_locationSelected.id
                }
                let mergeProduct = await ProductApi.mergeProductAssigningVariant(this.mergeProduct.id, mergeData)
                if(mergeProduct.status==201){
                    this.snackbar.add({
                        type: 'info',
                        text: "Product merged.",
                        duration: 3000,
                    })
                }
                this.$emit('close')
                this.emitter.emit('reloadProductList')
                this.vm_spinner(false)
            }
        },
        closeMergeProducts() {
            this.$emit('close')
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }
})
</script>
