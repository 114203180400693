<template>
  <div>
    <div class="bg-theme-green border-0 rounded-t-xl px-4 py-3 text-white">
      <div class="flex justify-between items-center">
        <div class="mr-2">
          <h2 class="font-semibold tracking-wider leading-tight truncate break-all text-2xl mt-0 mb-3">
            {{ form.name.value }}
          </h2>
          <h3 class="text-sm font-semibold leading-tight">
            {{ form.isSubCategory ? 'Sub ' : 'Main' }} Category
          </h3>
        </div>
        <div class="flex items-end">
          <button
            @click.prevent="updateCategory()"
            class="tracking-widest cursor-pointer bg-white py-2 px-4 rounded text-theme-green hover:bg-theme-darkgreen hover:text-white mr-3"
          >
            SAVE
          </button>
          <button
            @click.prevent="openDeleteConfirmModal()"
            class="cursor-pointer rounded-md bg-white text-theme-red py-2 px-4 hover:bg-theme-darkgreen hover:text-white mr-3"
          >
            DELETE
          </button>
          <span
            @click.prevent="clearComponent"
            class="cursor-pointer rounded-md text-white py-2 pl-1 hover:opacity-70"
            >CLOSE</span
          >
          <!-- <mdicon @click.prevent="clearComponent" size="32" name="close" class="cursor-pointer block hover:opacity-60"/>
              <span class="mt-2 flex justify-end">
                <mdicon @click.prevent="openDeleteConfirmModal()"  name="close" class="cursor-pointer bg-theme-red hover:bg-theme-black hover:text-white px-2 py-2 rounded-[4px] mr-2"/>
                <mdicon @click.prevent="updateCategory()" class="cursor-pointer bg-white hover:bg-theme-darkgreen hover:text-white text-theme-green px-2 py-2 rounded-[4px]" name="check"/>
              </span> -->
        </div>
      </div>
    </div>

    <div class="pt-3 bg-[#2B2C32] px-[16px] py-[16px] rounded-lg">
      <div class="flex gap-3 xl:flex-row flex-col items-start mb-3">
        <div class="w-full xl:w-4/4">
          <div :class="'input-wrapper ' + form.name.error_type">
            <label class="text-xs mb-1">Name</label>
            <input
              v-model="form.name.value"
              @keyup="validateFields(['name'])"
              placeholder="Dried Flower"
              class="placeholder-theme-878686 block border-none rounded bg-gray-black w-full text-sm text-theme-white"
              type="text"
            />
            <p
              class="field-error label-text mt-1"
              v-if="form.name.error_message"
              v-text="form.name.error_message"
            ></p>
          </div>
        </div>

        <div class="w-full xl:w-2/4 flex xl:justify-end justify-start">
          <div class="input-wrapper">
            <span class="mb-0 text-xs">Category Type</span>
            <div class="flex mt-1">
              <span
                @click="form.isSubCategory = false"
                :class="
                  'cursor-pointer mr-2 text-sm font-medium ' +
                  (form.isSubCategory ? 'text-theme-878686' : 'text-white')
                "
              >
                Main
              </span>
              <label class="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  v-model="form.isSubCategory"
                  class="hidden peer"
                />
                <div
                  :class="'relative toggle-bg  border-2  h-6 w-11 rounded-full bg-theme-green border-theme-green'"
                ></div>
              </label>
              <span
                @click="form.isSubCategory = true"
                :class="
                  'cursor-pointer ml-2 text-sm font-medium ' +
                  (form.isSubCategory ? 'text-white' : 'text-theme-878686')
                "
              >
                Sub
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="globalLotSetting">
        <div class="w-full w-full flex" v-if="!item.lotTracking">
          <div class="input-wrapper">
            <span class="mb-0 text-xs">Lot Tracking</span>
            <div class="flex mt-1">
              <span
                @click="form.lotTracking = false"
                :class="
                  'cursor-pointer mr-2 text-sm font-medium ' +
                  (form.lotTracking ? 'text-theme-878686' : 'text-white')
                "
              >
                Off
              </span>
              <label class="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  v-model="form.lotTracking"
                  class="hidden peer"
                />
                <div
                  class="relative toggle-bg border-2 h-6 w-11 rounded-full"
                  :class="
                    form.lotTracking
                      ? 'bg-theme-green border-theme-green'
                      : 'bg-theme-red border-theme-red'
                  "
                ></div>
              </label>
              <span
                @click="form.lotTracking = true"
                :class="
                  'cursor-pointer ml-2 text-sm font-medium ' +
                  (form.lotTracking ? 'text-white' : 'text-theme-878686')
                "
              >
                On
              </span>
            </div>
          </div>
        </div>
        <div v-else class="bg-[#E7A644] bg-opacity-10 p-2 rounded">
          <div class="flex items-center">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 11.75C8.2125 11.75 8.39063 11.6781 8.53438 11.5344C8.67813 11.3906 8.75 11.2125 8.75 11V8C8.75 7.7875 8.67813 7.60938 8.53438 7.46563C8.39063 7.32188 8.2125 7.25 8 7.25C7.7875 7.25 7.60938 7.32188 7.46563 7.46563C7.32188 7.60938 7.25 7.7875 7.25 8V11C7.25 11.2125 7.32188 11.3906 7.46563 11.5344C7.60938 11.6781 7.7875 11.75 8 11.75ZM8 5.75C8.2125 5.75 8.39063 5.67813 8.53438 5.53438C8.67813 5.39062 8.75 5.2125 8.75 5C8.75 4.7875 8.67813 4.60938 8.53438 4.46563C8.39063 4.32188 8.2125 4.25 8 4.25C7.7875 4.25 7.60938 4.32188 7.46563 4.46563C7.32188 4.60938 7.25 4.7875 7.25 5C7.25 5.2125 7.32188 5.39062 7.46563 5.53438C7.60938 5.67813 7.7875 5.75 8 5.75ZM8 15.5C6.9625 15.5 5.9875 15.3031 5.075 14.9094C4.1625 14.5156 3.36875 13.9813 2.69375 13.3063C2.01875 12.6313 1.48438 11.8375 1.09063 10.925C0.696875 10.0125 0.5 9.0375 0.5 8C0.5 6.9625 0.696875 5.9875 1.09063 5.075C1.48438 4.1625 2.01875 3.36875 2.69375 2.69375C3.36875 2.01875 4.1625 1.48438 5.075 1.09063C5.9875 0.696875 6.9625 0.5 8 0.5C9.0375 0.5 10.0125 0.696875 10.925 1.09063C11.8375 1.48438 12.6313 2.01875 13.3063 2.69375C13.9813 3.36875 14.5156 4.1625 14.9094 5.075C15.3031 5.9875 15.5 6.9625 15.5 8C15.5 9.0375 15.3031 10.0125 14.9094 10.925C14.5156 11.8375 13.9813 12.6313 13.3063 13.3063C12.6313 13.9813 11.8375 14.5156 10.925 14.9094C10.0125 15.3031 9.0375 15.5 8 15.5ZM8 14C9.675 14 11.0938 13.4188 12.2563 12.2563C13.4188 11.0938 14 9.675 14 8C14 6.325 13.4188 4.90625 12.2563 3.74375C11.0938 2.58125 9.675 2 8 2C6.325 2 4.90625 2.58125 3.74375 3.74375C2.58125 4.90625 2 6.325 2 8C2 9.675 2.58125 11.0938 3.74375 12.2563C4.90625 13.4188 6.325 14 8 14Z" fill="#E7A644"/>
            </svg>
            <span class="pl-3 text-theme-orange text-sm">Lot tracking is enabled</span>
          </div>
          <div  class="text-sm">Lot tracking cannot be updated without going through our support, click <span @click="showHelp" class="cursor-pointer underline text-theme-orange text-sm">Help</span></div>

        </div>
      </div>

      <div class="bg-theme-borderblack h-1 w-full my-4"></div>
      <div class="flex gap-3 xl:flex-row flex-col items-start mb-3">
        <div class="w-full">
          <div class="input-wrapper">
            <label class="text-xs mb-1">Description</label>
            <div class="flex">
              <textarea
                v-model="form.description"
                class="border-none block rounded bg-gray-black w-full text-sm"
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-theme-borderblack h-1 w-full my-4"></div>
      <div
        class="flex gap-3 xl:flex-row flex-col items-start mb-3"
        v-if="form.isSubCategory"
      >
        <div class="w-full">
          <div :class="'input-wrapper ' + form.parent.error_type">
            <label class="text-xs mb-1">Parent Category</label>
            <div class="flex justify-between items-center">
              <select
                @change="validateFields(['parent'])"
                v-model.number="form.parent.value"
                :class="
                  'border-none block rounded bg-gray-black w-full text-sm ' +
                  (form.parent.value != null
                    ? 'text-white'
                    : 'text-theme-878686')
                "
              >
                <option :value="null" disabled>-- Select --</option>
                <template
                  v-for="category in existingProductParentCategories"
                  v-bind:key="'drop_cat' + category.id"
                >
                  <option v-if="category.id != item.id" :value="category.id">
                    {{ category.name }}
                  </option>
                </template>
              </select>
            </div>
            <p
              class="field-error label-text mt-1"
              v-if="form.parent.error_message"
              v-text="form.parent.error_message"
            ></p>
          </div>
        </div>
      </div>

      <div
        class="bg-theme-borderblack h-1 w-full my-4"
        v-if="form.isSubCategory"
      ></div>
      <div class="flex gap-3 xl:flex-row flex-col items-start mb-3">
        <div class="xl:w-6/12 flex">
          <div class="input-wrapper w-full mr-4">
            <label class="text-xs mb-1">Category Items</label>
            <div class="flex justify-between items-center">
              <span
                class="placeholder-white block bg-theme-modal_inner_bg pl-3 w-full focus:outline-0 text-sm text-theme-white border-none mt-3"
                >{{ selectedPids ? selectedPids.length : 0 }} items
                selected</span
              >
              <!-- <a
                  href="#"
                  class="bg-white p-2 rounded hover:bg-slate-300"
                  @click.prevent="showCategoryWithProductModal('catProd')"
                >
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.3 19.1508L2.75 15.6008L3.8 14.5508L6.3 17.0258L10.775 12.5508L11.825 13.6258L6.3 19.1508ZM6.3 11.1508L2.75 7.60078L3.8 6.55078L6.3 9.02578L10.775 4.55078L11.825 5.62578L6.3 11.1508ZM13.75 17.2508V15.7508H22.75V17.2508H13.75ZM13.75 9.25078V7.75078H22.75V9.25078H13.75Z"
                      fill="#2BA66B"
                    />
                  </svg>
                </a> -->
            </div>
          </div>
          <div class="input-wrapper" v-if="!item.parent">
            <label class="text-xs mb-1">Sort</label>
            <div class="flex justify-between items-center">
              <div class="flex justify-between items-center">
                <a href="#" @click.prevent="itemOrder = true" class="bg-white px-2 pt-3 pb-2 rounded hover:bg-slate-300">
                  <svg
                    width="28"
                    height="22"
                    viewBox="0 0 28 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.58268 21.0993L7.18268 19.6993L9.31602 17.4993C6.87157 17.4993 4.77713 16.666 3.03268 14.9993C1.28824 13.3327 0.416016 11.2771 0.416016 8.83268C0.416016 6.4549 1.24379 4.41602 2.89935 2.71602C4.5549 1.01602 6.57157 0.166016 8.94935 0.166016H14.016V2.16602H8.94935C7.12713 2.16602 5.58268 2.81602 4.31602 4.11602C3.04935 5.41602 2.41602 6.98824 2.41602 8.83268C2.41602 10.7216 3.11046 12.3049 4.49935 13.5827C5.88824 14.8605 7.54935 15.4993 9.48268 15.4993L7.28268 13.2993L8.68268 11.8993L13.216 16.466L8.58268 21.0993ZM16.816 17.4993V15.4993H27.0827V17.4993H16.816ZM16.816 9.83268V7.83268H27.0827V9.83268H16.816ZM16.016 2.16602V0.166016H27.0827V2.16602H16.016Z"
                      fill="#2BA66B"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="xl:w-6/12 ">
          <div class="text-xs">Tax</div>
          <TaxSelectCheckbox v-model="categoryTaxes" :options="taxes" placeholder="Select" class="mt-2 block rounded bg-gray-black w-full border-none text-sm tracking-wider" :class="categoryTaxes.length>0?'text-white':'text-theme-878686'" />
        </div>
        <!-- <div class="xl:w-6/12 w-full">
              <div class="input-wrapper">
                  <label class="text-xs mb-1">Recommendations</label>
                  <div class="flex justify-between items-center">
                      <span class="placeholder-white block bg-theme-modal_inner_bg pl-3 w-full focus:outline-0 text-sm text-theme-white border-none">0 items selected</span>
                      <div class="flex justify-between items-center">
                          <svg width="28" height="22" class="mr-4" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.58268 21.0993L7.18268 19.6993L9.31602 17.4993C6.87157 17.4993 4.77713 16.666 3.03268 14.9993C1.28824 13.3327 0.416016 11.2771 0.416016 8.83268C0.416016 6.4549 1.24379 4.41602 2.89935 2.71602C4.5549 1.01602 6.57157 0.166016 8.94935 0.166016H14.016V2.16602H8.94935C7.12713 2.16602 5.58268 2.81602 4.31602 4.11602C3.04935 5.41602 2.41602 6.98824 2.41602 8.83268C2.41602 10.7216 3.11046 12.3049 4.49935 13.5827C5.88824 14.8605 7.54935 15.4993 9.48268 15.4993L7.28268 13.2993L8.68268 11.8993L13.216 16.466L8.58268 21.0993ZM16.816 17.4993V15.4993H27.0827V17.4993H16.816ZM16.816 9.83268V7.83268H27.0827V9.83268H16.816ZM16.016 2.16602V0.166016H27.0827V2.16602H16.016Z" fill="#2BA66B"/>
                          </svg>
                          <a href="#" class="bg-white p-2 rounded hover:bg-slate-300" @click.prevent="openRecommendCategoryModal()">
                              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6.3 19.1508L2.75 15.6008L3.8 14.5508L6.3 17.0258L10.775 12.5508L11.825 13.6258L6.3 19.1508ZM6.3 11.1508L2.75 7.60078L3.8 6.55078L6.3 9.02578L10.775 4.55078L11.825 5.62578L6.3 11.1508ZM13.75 17.2508V15.7508H22.75V17.2508H13.75ZM13.75 9.25078V7.75078H22.75V9.25078H13.75Z" fill="#2BA66B"/>
                              </svg>
                          </a>
                      </div>
                  </div>
              </div>
          </div> -->
      </div>

      <div class="flex gap-3 xl:flex-row flex-col items-start mb-3">
        <div class="w-full xl:w-1/2">
          <div :class="'input-wrapper ' + form.posColour.error_type">
            <label class="text-xs mb-1">Color</label>
            <div
              class="block rounded bg-gray-black w-full text-sm px-1 py-[5px]"
            >
              <label for="cp" class="w-full flex">
                <input
                  class="w-6 bg-transparent"
                  @change="validateFields(['posColour'])"
                  v-model="form.posColour.value"
                  id="cp"
                  type="color"
                />
                <span
                  :class="
                    'pl-2 items-center flex ' +
                    (form.posColour.value ? 'text-white' : 'text-theme-878686')
                  "
                  >{{
                    form.posColour.value ? form.posColour.value : '-- Select --'
                  }}</span
                >
              </label>
            </div>
            <p
              class="field-error label-text mt-1"
              v-if="form.posColour.error_message"
              v-text="form.posColour.error_message"
            ></p>
          </div>
        </div>
        <div class="w-full xl:w-1/2">
          <div :class="'input-wrapper ' + form.ComplianceType.error_type">
            <label class="mb-0 text-xs">Compliance Type</label>
            <select
              @change="validateFields(['ComplianceType'])"
              v-model="form.ComplianceType.value"
              :class="
                'block rounded bg-gray-black border-none w-full text-sm ' +
                (form.ComplianceType.value ? 'text-white' : 'text-theme-878686')
              "
            >
              <option value="" disabled>--Select--</option>
              <option value="Dried flower">Dried flower</option>
              <option value="Extracts ingested">Extracts ingested</option>
              <option value="Extracts inhaled">Extracts inhaled</option>
              <option value="Edibles solids">Edibles solids</option>
              <option value="Edibles non-solids">Edibles non-solids</option>
              <option value="Non-cannabis">Non-cannabis</option>
              <option value="Topicals">Topicals</option>
            </select>
            <p
              class="field-error label-text mt-1"
              v-if="form.ComplianceType.error_message"
              v-text="form.ComplianceType.error_message"
            ></p>
          </div>
        </div>
      </div>

      <div class="flex gap-3 xl:flex-row flex-col items-start mb-3">
        <div class="w-full xl:w-3/4">
          <div class="input-wrapper">
            <label class="mb-0 text-xs">THC/CBD Values</label>
            <label
              for="thc/cbd-togglestatus"
              class="flex relative items-center cursor-pointer px-1 py-1"
            >
              <input
                type="checkbox"
                value="range"
                id="thc/cbd-togglestatus"
                class="hidden peer"
              />
              <span
                @click="thcCbdValueTypeRange = false"
                :class="
                  'mr-2 text-sm font-medium ' +
                  (thcCbdValueTypeRange ? 'text-theme-878686' : 'text-white')
                "
              >
                Single
              </span>
              <label class="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  v-model="thcCbdValueTypeRange"
                  class="hidden peer"
                />
                <div
                  :class="'relative toggle-bg  border-2  h-6 w-11 rounded-full bg-theme-green border-theme-green'"
                ></div>
              </label>
              <span
                @click="thcCbdValueTypeRange = true"
                :class="
                  'ml-2 text-sm font-medium ' +
                  (thcCbdValueTypeRange ? 'text-white' : 'text-theme-878686')
                "
              >
                Range
              </span>
            </label>
          </div>
        </div>
        <div class="w-full xl:w-3/4">
          <div :class="'input-wrapper ' + form.concentrationUnit.error_type">
            <label class="mb-0 text-xs">THC/CBD Unit</label>
            <select
              @change="validateFields(['concentrationUnit'])"
              v-model="form.concentrationUnit.value"
              :class="
                'block rounded bg-gray-black border-none w-full text-sm ' +
                (form.concentrationUnit.value
                  ? 'text-white'
                  : 'text-theme-878686')
              "
            >
              <option value="" disabled>--Select--</option>
              <option value="%">%</option>
              <option value="mg/g">Mg/g</option>
              <option value="mg/ml">Mg/Ml</option>
              <option value="mg">Mg</option>
              <option value="ml">Ml</option>
              <option value="mg/bottle">Mg/bottle</option>
              <option value="mg/pack">Mg/pack</option>
              <option value="mg/unit">Mg/unit</option>
            </select>
            <p
              class="field-error label-text mt-1"
              v-if="form.concentrationUnit.error_message"
              v-text="form.concentrationUnit.error_message"
            ></p>
          </div>
        </div>
        <!-- <div class="w-full xl:w-3/4">
          <div :class="'input-wrapper ' + form.stockThreshold.error_type">
            <label class="text-xs tracking-[-.2px] mb-1">Min. Stock Threshold</label>
            <input
              :onkeypress="preventNonNumbers"
              @keyup="validateFields(['stockThreshold'])"
              v-model="form.stockThreshold.value"
              type="number"
              class="placeholder-white block rounded bg-gray-black border-none w-full text-sm text-theme-white"
            />
            <p
              class="field-error label-text mt-1"
              v-if="form.stockThreshold.error_message"
              v-text="form.stockThreshold.error_message"
            ></p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <CategoryWithProductModal
    v-if="
      categoryWithproductObj.type == 'catProd' && categoryWithproductObj.active
    "
    :existingproducts="selectedPids"
    :closeClick="closeCategoryWithProductModal"
    :actionClick="setCategoriesWithProduct"
  />
  <ReturnConfirmModal
    v-if="deleteObj.modal"
    :closeClick="closeDeleteModal"
    :actionClick="deleteCategory"
    :obj="deleteObj.obj"
    :heading="'You Are About To Delete ' + deleteObj.obj.name"
  />
  <SelectCategoryModal
    v-if="itemOrder"
    @close="itemOrder = false"
    :item="item"
  />
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import BusinessCategoryApis from '@/controllers/BusinessCategoryApis'
import BusinessProductApis from '@/controllers/BusinessProductApis'
import LocationCannabisSettingApi from '@/controllers/LocationCannabisSetting'
import { useSnackbar } from 'vue3-snackbar'
import Helpers from '@/helpers'
import ReturnConfirmModal from '@/components/general/dialog/ReturnConfirmModal.vue'
import SelectCategoryModal from '@/views/Products/Category/RegularCategory/ItemOrderModal.vue'
import CategoryWithProductModal from '@/components/CategoriesWithProductModal.vue';
import BusinessTaxApis from "@/controllers/BusinessTaxApis";
import TaxSelectCheckbox from '@/components/form/CategorySelectCheckbox.vue'

export default defineComponent({
  name: 'ProductCategoryUpdate',
  components: {
    SelectCategoryModal,
    ReturnConfirmModal,
    CategoryWithProductModal,
    TaxSelectCheckbox
  },
  props: {
    clearComponent: {
      type: Function,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },

  setup() {
    const snackbar = useSnackbar()
    const locationTaxes = ref([])
    const form = ref({
      businessId: '',
      isSubCategory: false,
      lotTracking: false,
      parent: {
        value: null,
        error_type: '',
        error_message: '',
        validations: ['required_if:isSubCategory|true|string']
      },
      name: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'min:3', 'max:30']
      },
      description: '',
      ComplianceType: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required']
      },
      // stockThreshold: {
      //   value: 1,
      //   error_type: '',
      //   error_message: '',
      //   validations: ['required', 'positivenumber', 'maxval:500000']
      // },
      posColour: {
        value: '#000000',
        error_type: '',
        error_message: '',
        validations: ['required']
      },
      concentrationUnit: {
        value: '',
        error_type: '',
        error_message: '',
        validations: [
          'notrequired_multiple_if:ComplianceType|Non-cannabis|object'
        ]
      },
      thcCbdValueType: 'single'
    })
    const thcCbdValueTypeRange = ref(false)
    const existingProductParentCategories = ref([])
    const deleteObj = ref({
      modal: false,
      obj: {}
    })
    const itemOrder = ref(false)
    const globalLotSetting = ref(false)
    const selectedPids = ref([])
    const categoryWithproductObj = ref({
      type: '',
      active: false
    })
    const taxes = ref([])
    const categoryTaxes = ref([])

    return {
      snackbar,
      locationTaxes,
      form,
      thcCbdValueTypeRange,
      existingProductParentCategories,
      deleteObj,
      itemOrder,
      globalLotSetting,
      selectedPids,
      categoryWithproductObj,
      taxes,
      categoryTaxes
    }
  },

  computed: {
    ...mapGetters({
      vg_activeBusiness: 'auth/activeBusiness',
      vg_selectedLocation: 'location/selected'
    })
  },
  watch: {
    thcCbdValueTypeRange(value) {
      this.form.thcCbdValueType = value ? 'range' : 'single'
    },
    'form.isSubCategory'(value) {
      this.form.parent.value = value ? this.form.parent.value : null
      if (value == false) {
        this.validateFields(['parent'])
      }
    },
    item() {
      this.assignValues()
    }
  },

  async mounted() {
    this.fetchLotSetting()
    this.fetchExistingProductCategories()
    this.fetchSelectedCategoryProduct()
    this.fetchTaxes()
    this.assignValues()
  },

  methods: {
    async assignValues() {
      this.form = await Helpers.assignFormValues(this.form, this.item)
      this.form.isSubCategory = this.item.parent ? true : false
      this.form.parent.value = this.item.parent ? this.item.parent : null
      this.form.thcCbdValueType = (this.item.thcCbdValueType == 'single') ? 'single' : 'range'
      this.thcCbdValueTypeRange =(this.item.thcCbdValueType == 'range') ? true : false
      // this.form.stockThreshold.value = this.item.stockThreshold;
    },

    /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields)
      // capture the fields
      this.form = res.data
      return res.error ? false : true
    },

    /**
     * Fetch location lot setting
     */
    async fetchLotSetting() {
      let res = await LocationCannabisSettingApi.fetchSetting()
      this.globalLotSetting =
        res.status == 200 ? res.data.payload.lotTracking : false
    },

    /**
     * Fetch existing categories
     */
    async fetchExistingProductCategories() {
      let res = await BusinessCategoryApis.fetchAllBusinessCategries({
        businessId: this.vg_activeBusiness.id,
        page: 1,
        perPage: 1000
      })
      if (res.status == 200) {
        this.existingProductParentCategories = res.data.payload.category.filter(
          (item) => !item.parent
        )
      }
    },

    async fetchSelectedCategoryProduct(){
      let res = await BusinessCategoryApis.fetchProductsOfCategory(this.item.id)
      if(res.status == 200) {
          if(res.data?.payload?.products){
              this.selectedPids =res.data.payload.products.map(each => each.id)
          }
      }else{
          this.selectedPids = []
      }
    },

    async fetchTaxes(){
      let res = await BusinessTaxApis.getTaxForBusiness(this.vg_activeBusiness.id)
      if(res.status==200){
        this.taxes = res.data.payload
      }
      if(this.vg_selectedLocation.id){
        let categoryTax = await BusinessTaxApis.getCategoryTaxes(this.item.id, this.vg_selectedLocation.id)
        if(categoryTax.status==200 && categoryTax.data.payload){
          this.categoryTaxes = categoryTax.data.payload.map(each => each.id)
        }else{
          this.categoryTaxes = []
        }
      }else{
        this.categoryTaxes = []
      }
    },

    async updateCategory() {
      // validate business field
      let validate = await this.validateFields([
        'name',
        'parent',
        'ComplianceType',
        // 'stockThreshold',
        'posColour',
        'concentrationUnit'
      ])
      if (!validate) {
        return
      }

      this.form.businessId = this.vg_activeBusiness.id
      this.form.thcCbdValueType =
        this.form.thcCbdValueType == 'single' ? 'single' : 'range'
      let data = Helpers.getFormKeyValue(this.form)

      this.va_spinner(true)
      let res = await BusinessCategoryApis.updateCategory(this.item.id, data)
      await BusinessProductApis.addProductToCategoryAndFeaturedCategory({
        categoryId: this.item.id,
        productIds: this.selectedPids,
        replace: true
      })
      if(this.vg_selectedLocation?.id){
        // update only if location selected
        await BusinessTaxApis.assignTaxToCategory(this.item.id, {taxIds: this.categoryTaxes, locationId: this.vg_selectedLocation.id})
      }
      await this.va_spinner(false)
      this.snackbar.add({
        type: 'info',
        text:
          res.status == 200
            ? res.data.message
            : Helpers.catchErrorMessage(res.response.data),
        duration: 3000
      })
      if (res.status == 200) {
        this.clearComponent()
        this.emitter.emit('refresh-business-categories-list', true)
      }
    },
    /**
     * Do not allow to add +,-,e
     */
    preventNonNumbers(e) {
      var ASCIICode = e.which ? e.which : e.keyCode
      if (ASCIICode == 43 || ASCIICode == 45 || ASCIICode == 101) {
        return false
      }
      return true
    },

    /**
     * Open delete confirm modal
     */
    openDeleteConfirmModal() {
      this.deleteObj = {
        modal: true,
        obj: this.item
      }
    },

    /**
     * Close delete confirm modal
     */
    closeDeleteModal() {
      this.deleteObj = {
        modal: false,
        obj: {}
      }
    },

    /**
     * Delete location
     */
    async deleteCategory(obj) {
      this.va_spinner(true)
      let res = await BusinessCategoryApis.deleteCategory(obj.id)
      this.va_spinner(false)
      this.snackbar.add({
        type: 'info',
        text:
          res.status == 200
            ? res.data.message
            : Helpers.catchErrorMessage(res.response.data),
        duration: 3000
      })
      if (res.status == 200) {
        this.clearComponent()
        this.emitter.emit('refresh-business-categories-list', true)
      }
    },
    /**
     * Open product with category Model
     */
    showCategoryWithProductModal(type) {
      this.categoryWithproductObj.type = type
      this.categoryWithproductObj.active = true
    },
    /**
     * Close select category modal
     */
    closeCategoryWithProductModal() {
      this.categoryWithproductObj.active = false
    },
    /**
     * Get data selected from category modal
     */
    setCategoriesWithProduct(data) {
      this.selectedPids = data.pids
      this.closeCategoryWithProductModal()
    },

    /**
     * Open category modal
     */
    openCategoryModal() {
      alert('waiting for api')
    },

    /**
     * Open category modal
     */
    openRecommendCategoryModal() {
      alert('waiting for api')
    },

    showHelp(){
      this.va_help(true)
    },

    ...mapActions({
      va_spinner: 'spinner/setSpinner',
      va_help: 'help/setHelp'
    })
  }
})
</script>
