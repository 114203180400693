
import { EyeIcon, EyeOffIcon } from '@heroicons/vue/outline'
import Helpers from '@/helpers';
import { ref, computed, defineComponent } from "vue";
import Base from "./Base.vue";
import { mapGetters, mapActions } from "vuex";
import { useSnackbar } from "vue3-snackbar";

export default defineComponent({
  name: 'LoginView',
  components: {
    Base,
    EyeIcon,
    EyeOffIcon
  },

 setup() {
    const showpassword = ref(false);
    const snackbar:any = useSnackbar();
    const loginForm = ref({
      email: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'email'],
      },
      password: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'min:9'],
      }
    });
    return {
      loginForm,
      showpassword,
      snackbar
    };
 },

 computed: {
    ...mapGetters({
      leftNav: "nav/leftNav",
      vg_userType: 'auth/userType',
      vg_locations: "location/list",
      vg_locationSelected: "location/selected",
      vg_userPermisiions: "auth/userPermissions",
      vg_userRole: "auth/userRoles"
    }),
  },
  
  mounted() {
    this.va_spinner(false);
    // check whether the query has rreload true and pass if it has session expired query
    if(this.$route.query.reload) {
      // if query has reload then reload with removing query
      window.location.href = this.$route.path + (this.$route.query.sessionExpired ? '?sessionExpired=1' : '')
    }
    // Session expired notice
    if(this.$route.query.sessionExpired && !this.$route.query.reload) {
      this.snackbar.add({
        type: 'info',
        text: 'Session expired. Please relogin.',
        duration: 3000,
      })
    }
  },

  watch:{
    // Fields cannot have white spaces
    'loginForm.email.value'(val){
      this.loginForm.email.value = val.replaceAll(/\s/g,'').trim();
    },
     // Fields cannot have white spaces
    'loginForm.password.value'(val){
      this.loginForm.password.value = val.replaceAll(/\s/g,'').trim();
    },
  },

  methods: {
    /**
     * Check if its employee
     */
    is_employee() {
      return  (this.vg_userRole.indexOf('employee') == -1 ) ? false : true;
    },

    /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.loginForm, fields);
      // capture the fields
      this.loginForm = res.data;
      return res.error ? false : true;
    },

    /**
     * Submit login form
     */
    async onSubmit() {
      let validate = await this.validateFields(['email', 'password']);
      if(!validate) {
        return ;
      }
      this.va_spinner(true);
      let res = await this.va_setUser({
        email: this.loginForm.email.value.toString(),
        password: this.loginForm.password.value.toString(),
      })

      // now here if user is superamdin then fine else redirect to product page
    
      if(res.status == 201) {
        // allow all user type to got through select business and location
        // this.va_spinner(false);

        // previous logic
        // if(res.data.payload.userType == 'super') {
        //   this.va_spinner(false);
        //   this.$router.push({
        //     name: 'SelectBusiness'
        //   })
        // } else {

        //   // Fetch all business then select 1st
          let businessesRes = await this.va_setUserBusinesses();
          if(businessesRes.status == 200 && businessesRes.data && businessesRes.data.payload.length > 0) {
            // await this.selectBusiness(businessesRes.data.payload[0]);
            this.$router.push({
              name: 'SelectBusiness'
            })
          } else {
            this.va_spinner(false);
            this.snackbar.add({
              type: 'info',
              text: 'Logging out, No business found. Please contact support!',
              duration: 3000,
            })
            this.$router.push({
              name: 'Login',
              query: {
                reload: 1
              }
            })
            this.va_clearUserData();
          }
        // }
        
      }else{
        this.snackbar.add({
          type: 'info',
          text: Helpers.catchErrorMessage(res.response.data),
          duration: 3000,
        })
      }
      this.va_spinner(false);
    },

    /**
     * Select business
     */
     async selectBusiness(business:any) {
      // as son as business selected get refresh token 
      let res = await this.va_setUserActiveBusiness(business);
      this.va_spinner(false);
      if(res.status == 200) {

        this.$router.push({
          name: 'Products'
        })


      } else {
        
        if(res.response && res.response.status == 401) {
          this.$router.push({
            name: 'Login'
          })
          this.va_clearUserData();
        }
        // catch only error, success redirects
        this.snackbar.add({
          type: 'info',
          text: Helpers.catchErrorMessage(res.response.data),
          duration: 3000,
        })

      }
    },

     ...mapActions({
      va_spinner: "spinner/setSpinner",
      va_setUser: "auth/setUser",
      va_clearUserData: "auth/clearUserData",
      va_setUserBusinesses: "auth/setUserBusinesses",
      va_setUserActiveBusiness: "auth/setUserActiveBusiness",
    })
  }

});

