
import  { defineComponent , ref} from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { useSnackbar } from "vue3-snackbar";

export default defineComponent({
  name: "CloverResponse",
  components: {

  },

  setup() {
    
  },

  computed: {
    ...mapGetters({
      vg_selectedLocation: 'location/selected'
    }),
  },

  watch: {
 
  },

  async mounted() {
    await this.vm_mutateCloverDetails({
        clover_response: this.$route.query, //this.$route.query
    })
  },

  methods: {
    ...mapMutations({
      vm_mutateCloverDetails: "clover/detailsMutate",
    })
  }
});
