<template>
  <div>
    <ul class="flex bg-[#2B2C32] rounded-full px-8 py-1">
      <li class="mr-4">
        <router-link :class="'text-theme-white tracking-wide text-sm' + ($route.name == 'LocationDevicesPos' ? ' active' : '')" :to="{ name: 'LocationDevicesPos'}">
          POS STATIONS
        </router-link>
      </li>
      <!-- <li class="mr-4">
        <router-link  :class="'text-theme-white tracking-wide text-sm' + ($route.name == 'LocationDeviceTablet' ? ' active' : '')" :to="{ name: 'LocationDeviceTablet'}">
          TABLETS
        </router-link>
      </li>
      <li class="mr-4">
        <router-link  :class="'text-theme-white tracking-wide text-sm' + ($route.name == 'LocationDevicePrinter' ? ' active' : '')" :to="{ name: 'LocationDevicePrinter'}">
          PRINTERS
        </router-link>
      </li> -->
     
    </ul>
  </div>
</template>

<style scoped>
  .active:before {
    content: '';
    position: absolute;
    background: #2BA66B;
    width: 100%;
    height: 4px;
    bottom: -10px;
  }
  .active{
    position:relative;
  }
</style>