<template>
  <div class="rounded bg-light-black large:pb-[120px]">
    <Form @submit="submit" :validation-schema="schema">
        <div class="rounded-tl rounded-tr header h-20 bg-secondary px-2">
            <div class="flex flex-row justify-between h-full">
                <div class="title self-center font-bold ml-2">{{form.role}}</div>
    
                <div class="flex ">
                    <div class="flex  items-center">
                        <button v-if="can_create_update_roles_permission" class="tracking-widest cursor-pointer bg-white py-2 px-4 rounded text-theme-green hover:bg-theme-darkgreen hover:text-white mr-3">
                            SAVE
                        </button>
                        <button v-if="can_create_update_roles_permission" @click.prevent="openDeleteConfirmModal()" class="cursor-pointer rounded-md bg-white text-theme-red py-2 px-4  hover:bg-theme-darkgreen hover:text-white mr-3">DELETE</button>
                        <span @click.prevent="close" class="cursor-pointer rounded-md  text-white py-2 mr-2 hover:opacity-70">CLOSE</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-row flex-wrap py-2">
            <label class="w-full block p-2">
                <Field v-slot="{field, errors, errorMessage}" name="role" v-model="form.role" >
                    <div :class="labelClass(field.value, errorMessage)">Role:</div>
                    <input :disabled="!can_create_update_roles_permission" class="block w-full rounded bg-gray-black" :class="inputClass(field.value, errorMessage)" v-bind="field" type="text" />
                    <div v-if="errors.length>0" class="text-sm"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                </Field>
            </label>
            <label class="w-full block p-2">
                <Field  v-slot="{field, errors, errorMessage}" name="description" v-model="form.description" >
                    <div :class="labelClass(field.value, errorMessage)">Description:</div>
                    <textarea :disabled="!can_create_update_roles_permission" class="block w-full rounded bg-gray-black" :class="inputClass(field.value, errorMessage)" v-bind="field"></textarea>
                    <div v-if="errors.length>0" class="text-sm" :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                </Field>
            </label>
            <!-- Checkbox -->
            <div :class="'w-full' + (can_create_update_roles_permission ?  '' : ' disable-div')" v-for="(eachPermissionCategory, ind) in permissionCategory" :key="eachPermissionCategory.id">
                <!-- toggler -->
                <div class="p-2 flex flex-row justify-between">
                    <div class="w-full flex flex-row">
                        <div class=" text-2xl mr-4 font-medium text-gray-400">{{eachPermissionCategory.name}}</div>
                        <div>
                            <label class="flex flex-row w-full block p-2 cursor-pointer">
                                <Field @change="toggleSelectAll(ind)" v-slot="{field}" :name="eachPermissionCategory.name" type="checkbox" v-model="toggler[ind].selectAll" :value="true" :unchecked-value="false">
                                    <span class="self-center text-slate-400 text-sm font-medium mr-2">Off</span>
                                    <input type="checkbox" class="hidden peer" :value="true" v-bind="field">
                                    <div class="self-center relative toggle-bg bg-gray-400 peer-checked:bg-theme-green border-2 border-gray-400 peer-checked:border-theme-green h-6 w-11 rounded-full"></div>
                                    <span class="self-center ml-2 text-white text-sm font-medium ">On</span>
                                </Field>
                            </label>
                        </div>
                    </div>
                    
                    <div class="self-center cursor-pointer" @click.prevent="togglePermission(ind)">
                        <mdicon :name="toggler[ind].visibility?'chevron-down':'chevron-up'" class="flex text-right justify-end"/>
                    </div>
                </div>
                <!-- Permissions -->
                <div class="px-2 w-full mt-4 transition-all duration-2000 ease-in-out" :class="toggler[ind].visibility?'display':'hidden'">
                    <div class="flex flex-row justify-between mb-6" v-for="each in eachPermissionCategory.permissions" :key="'per-'+each.id">
                        <div>
                            <div class="font-bold text-lg">{{each.name}}</div>
                            <div class="text-gray-400 text-sm">{{each.description}}</div>
                        </div>
                        <div>
                            <label class="flex items-center cursor-pointer">
                                <Field v-slot="{field}" name="permission[]" type="checkbox" v-model="form.permission" :value="each.id">
                                    <input type="checkbox" class="hidden peer" :value="each.id" v-bind="field">
                                    <div class="self-center relative toggle-bg bg-gray-400 peer-checked:bg-theme-green border-2 border-gray-400 peer-checked:border-theme-green h-6 w-11 rounded-full"></div>
                                </Field>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Form>
    <ReturnConfirmModal v-if="deleteObj.modal" :closeClick="closeDeleteModal" :actionClick="deleteRole" :obj="deleteObj.obj" :heading="'You Are About To Delete ' + deleteObj.obj.role + ' Security Role!'"/>
  </div>
</template>

<script>
import {ref, defineComponent} from "vue"
import { Form, Field } from 'vee-validate';
import ReturnConfirmModal from "@/components/general/dialog/ReturnConfirmModal.vue";
import {mapGetters, mapMutations} from 'vuex'
import RoleApi from "@/controllers/RoleApis";
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';

export default defineComponent({
    props: ['editItem'],
    components: {
        Form,
        Field,
        ReturnConfirmModal
    },
    setup(){
        const snackbar = useSnackbar();
        const form = ref({
            role: '',
            description: '',
            pos: false,
            view: false,
            edit: false,
            permission:[]
        })
        const schema = {
            role: 'required',
            description: 'required'
        }
        const deleteObj = ref({
            modal: false,
            obj: {},
        });
        const permissionCategory = ref({})
        const permission = ref([])
        const toggler = ref([
            {selectAll: false, visibility: true},
            {selectAll: false, visibility: true},
            {selectAll: false, visibility: true}
        ])
        
        return {snackbar, form, schema, permissionCategory, permission, toggler, deleteObj}
    },
    async mounted(){
        this.vm_spinner(true)
        this.form.role = this.editItem.role
        this.form.description = this.editItem.description
        await this.getPermissionCategory()
        await this.getPermissionOfUser()
        this.vm_spinner(false)
    },
    watch: {
        async editItem(editItem){
            this.vm_spinner(true)
            this.form.role = this.editItem.role
            this.form.description = this.editItem.description
            await this.getPermissionCategory()
            await this.getPermissionOfUser()
            this.vm_spinner(false)
        },
        'form.permission'(val){
            this.autoToggleSelectAll()
        }
    },
    computed:{
        can_create_update_roles_permission() {
            return Helpers.getMyPermission('can_create_update_roles_permission');
        },
        ...mapGetters({
            vg_spinner: 'spinner/status'
        })
    },
    methods: {
        labelClass(value, error){
            if(value && error){
                return 'text-theme-red'
            }else if(error){
                return 'text-theme-orange'
            }else if(value){
                return 'text-theme-green'
            }
        },
        inputClass(value, error){
            if(value && error){
                return 'border-theme-red'
            }else if(error){
                return 'border-theme-orange'
            }else if(value){
                return 'border-theme-green'
            }
        },
        errorClass(value, error){
            if(value && error){
                return 'text-theme-red'
            }else if(error){
                return 'text-theme-orange'
            }
        },
        async getPermissionCategory(){
            let {data: {payload}} = await RoleApi.getCategory()
            if(payload){
                this.permissionCategory = payload
            }
        },
        async getPermissionOfUser(){
            let {data: {payload}} = await RoleApi.getPermissions(this.editItem.id)
            this.permission = payload
            this.form.permission = []
            this.form.permission = payload.map(each => each.id)
            this.autoToggleSelectAll()
        },
        autoToggleSelectAll(){
            for(let i=0; i<this.permissionCategory.length; i++){
                let ids = this.permissionCategory[i].permissions.map(each => each.id)
                let common = this.form.permission.filter(each => ids.includes(each))
                if(common.length>0){
                    this.toggler[i].selectAll = true
                }else{
                    this.toggler[i].selectAll = false
                }
            }
        },
        async submit(form, {resetForm}){
            this.vm_spinner(true)
            await RoleApi.editRole(this.editItem.id, form)
            let res = await RoleApi.updatePermission(this.editItem.id, {permissionIds: this.form.permission})
            this.vm_spinner(false)
            resetForm()
            this.snackbar.add({
                type: 'info',
                text: (res.data.message)?res.data.message : Helpers.catchErrorMessage(res.response.data),
                duration: 3000,
            })
            this.$emit('edited')
        },
        close(){
            this.$emit('close')
        },

        /**
         * Delete
         */
        async openDeleteConfirmModal(){

            console.log(this.editItem)
            this.deleteObj = {
                modal: true,
                obj: this.editItem,
            };
        },

        /**
         * Delete role
         */
        async deleteRole() {

            this.vm_spinner(true)
            let res = await RoleApi.deleteRole(this.editItem.id)
            this.vm_spinner(false)
            this.snackbar.add({
                type: 'info',
                text: (res.data.message) ? res.data.message : Helpers.catchErrorMessage(res.response.data),
                duration: 3000,
            })
            this.$emit('edited')

        },

        /**
         * Close delete confirm modal
         */
        closeDeleteModal() {
            this.deleteObj = {
                modal: false,
                obj: {},
            };
        },


        toggleSelectAll(ind){
            let ids = this.permissionCategory[ind].permissions.map(each => each.id)
            if(this.toggler[ind].selectAll){
                this.form.permission = [...new Set([...this.form.permission, ...ids])]
            }else{
                for(let i = 0; i < ids.length; i++){
                    let idInd = this.form.permission.indexOf(ids[i]);
                    if(idInd >= 0){
                        this.form.permission.splice(idInd, 1);
                    }        
                }
            }
        },
        togglePermission(i){
            this.toggler[i].visibility = !this.toggler[i].visibility
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }

})
</script>