<template>
    <div>
        <div
            class="flex justify-between gap-3 box-border items-center text-white h-[76px] rounded p-4 bg-[#2B2C32]"
        >
            <div>
            <h2 class="font-bold text-xl leading-[30px] tracking-[0.05px]">
                Schedule Report
            </h2>
            <p class="text-sm">Every user you add to receive this email will get a report when your store opens the next day. This report covers all the End of Day reports per POS Station closed the day before.</p>
            </div>
            <buttton @click="addUser"
            class="h-[44px] w-[113px] py-2 uppercase cursor-pointer flex justify-center hover:opacity-60 items-center bg-white rounded-md font-medium text-sm leading-[17.5px] tracking-[1.2px] text-[#2BA66B]"
            >Add User</buttton
            >
        </div>

        <block-loading v-if="table.isLoading" />
        <table-lite
            v-else
            class="admin-template"
            :is-loading="table.isLoading"
            :is-slot-mode='true'
            :columns="column"
            :rows="table.rows"
            :rowClasses="customRowClass"
            :total="table.totalRecordCount"
            :sortable="table.sortable"
            :is-static-mode="true"
            @is-finished="table.isLoading = false"
            :page-size='table.pagesize'
            :pageOptions='table.pageOptions'
            :has-checkbox="false"
            :checked-return-type="'id'"
            :is-hide-paging="false"
            :pageSizeChangeLabel="'pageSizeChangeLabel'"
            :messages="{
                pagingInfo: 'Total: {2}',
                pageSizeChangeLabel: 'Items per Page',
                gotoPageLabel: '',
                noDataAvailable: 'No records found!',
            }"
        >
            <template v-slot:reportSubscribersLocations="data">
                <div class="flex flex-wrap gap-1">
                    <div v-for="(location, ind) in data.value.reportSubscribersLocations" :key="location.locationId">
                        <span>{{location.location.name}} {{ind+1!==data.value.reportSubscribersLocations.length?',':""}}</span>
                    </div>
                </div>
            </template>
            <template v-slot:action="data">
                <div class="flex flex-row gap-8">
                <svg @click="Edit(data.value)" class="hover:opacity-50 cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.659 3C17.409 3 17.149 3.1 16.959 3.29L15.129 5.12L18.879 8.87L20.709 7.04C21.099 6.65 21.099 6.02 20.709 5.63L18.369 3.29C18.169 3.09 17.919 3 17.659 3ZM14.059 9.02L14.979 9.94L5.91902 19H4.99902V18.08L14.059 9.02ZM2.99902 17.25L14.059 6.19L17.809 9.94L6.74902 21H2.99902V17.25Z" fill="white"/>
                </svg>
                <svg @click="Remove(data.value)" class="hover:opacity-50 cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 21C6.45 21 5.97933 20.8043 5.588 20.413C5.196 20.021 5 19.55 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8043 20.021 18.413 20.413C18.021 20.8043 17.55 21 17 21H7ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z" fill="white"/>
                </svg>
                </div>
            </template>
        </table-lite>

        <div>
            <component :is="type.name" :data="type.data" @close="close" />
        </div>
    </div>
</template>

<script>
import Env from '@/env';
import ScheduleApi from '@/controllers/ScheduleReport';
import { defineComponent , ref} from "vue";
import New from './New'
import Edit from './Edit'
import Helpers from '@/helpers';
import { useSnackbar } from "vue3-snackbar";
import { mapActions } from "vuex";

export default defineComponent({
    components: {
        New,
        Edit
    },
    setup() {
        const snackbar = useSnackbar();
        const table = ref({
            isLoading: false,
            rows: [],
            totalRecordCount: 0,
            sortable: {
                order: "email",
                sort: "asc",
            },
            pagesize: Env.defaultPageSize,
            pageOptions: Env.pageList,
            count: 0,
            page: 1,  
        });
        const column = ref([
            {
                label: "Email",
                field: "email",
                sortable: true,
                isKey: true,
            },
            {
                label: "Locations",
                field: "reportSubscribersLocations",
                sortable: false,
            },
            {
                label: "Actions",
                field: "action",
                sortable: false,
            },
        ]);
        const type = ref({
            name: '',
            data: []
        })

        return  {
            snackbar,
            table,
            column,
            type
        }
    },
    async mounted(){
        this.fetchList()
        
    },
    methods: {
        async fetchList(){
            this.table.isLoading = true
            let res = await ScheduleApi.fetchAll()
            if(res.status===200){
                this.table.rows = res.data.payload
                this.table.totalRecordCount = res.data.payload.length
            }else{
                this.table.rows = []
                this.table.totalRecordCount = 0
            }
            this.table.isLoading = false
        },
        addUser(){
            this.type.name = 'New'
        },
        Edit(data){
            this.type = {
                name: "Edit",
                data: data
            }
        },
        Remove(data){
            this.confirmNew('Delete', `Are you sure you want ${data.email} to stop receiving scheduled emails?`).then(async () => {
                this.va_spinner(true)
                let res = await ScheduleApi.remove(data.id)
                this.snackbar.add({
                    type: 'info',
                    text: res.status == 200 ? res.data.message: Helpers.catchErrorMessage(res.response.data),
                    duration: 3000,
                })
                this.fetchList()
                this.va_spinner(false)
            })
        },
        close(reload){
            console.log('close '+reload?'reload':'no reload')
            this.type.name = ''
            if(reload){
                this.fetchList()
            }
        },
        ...mapActions({
            va_spinner: "spinner/setSpinner",
        })
    }
})
</script>
