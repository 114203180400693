/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import store from '@/store/index';
import Env from '@/env';
const apiVersion = 'api';
import AuthApis from '@/controllers/AuthApis';
import Helpers from '@/helpers';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /*
   * fetch all the promotions
   */
  fetchAll(perPage, page, orderby = 'title', sort = 'asc') {
    const url = `${Env.productUrl}/api/discounts/byBusiness?perPage=${perPage}&page=${page}&orderby=${orderby}&sort=${sort}`; // &userType=all
    return AuthApis.getApi(url, {
        api_token: Env.productApiToken,
        'Authorization': 'Bearer ' + store.getters['auth/userToken'],

      }).then((res) => res)
      .catch((err) => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      })
  },


  /*
   * add discount
   */
  async addDiscount(data) {
    data = await Helpers.setEmptyValueToNull(data);
    const url = `${Env.productUrl}/api/discounts`;
    return AuthApis.postApi(url, {
        'Authorization': 'Bearer ' + store.getters['auth/userToken'],
        api_token: Env.productApiToken,
      }, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /*
   * Update 
   */
  async update(discountsId, data) {
    data = await Helpers.setEmptyValueToNull(data);
    const url = `${Env.productUrl}/api/discounts/${discountsId}`;
    return AuthApis.patchApi(url, {
        'Authorization': 'Bearer ' + store.getters['auth/userToken'],
        api_token: Env.productApiToken,
      }, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /*
   * Delete
   */
  delete(discountsId) {
    const url = `${Env.productUrl}/api/discounts/${discountsId}`;
    return AuthApis.deleteApi(url, {
        'Authorization': 'Bearer ' + store.getters['auth/userToken'],
        api_token: Env.productApiToken,
      })
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /*
   * add discount location
   */
  addLocations(discountsId, locationIds) {
    const url = `${Env.productUrl}/api/discounts/${discountsId}/addLocations`;
    return AuthApis.postApi(url, {
        'Authorization': 'Bearer ' + store.getters['auth/userToken'],
        api_token: Env.productApiToken,
      }, {
        locationIds
      })
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Fetch assigned locations
   */
  fetchAssignedLocations(discountsId) {
    const url = `${Env.productUrl}/api/discounts/${discountsId}/allLocations`;
    return AuthApis.getApi(url, {
        'Authorization': 'Bearer ' + store.getters['auth/userToken'],
        api_token: Env.productApiToken,
      })
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Assign product 
   */
  assignProducts(discountsId, productIds) {
    const url = `${Env.productUrl}/api/discounts/${discountsId}/syncProducts`;
    return AuthApis.postApi(url, {
        'Authorization': 'Bearer ' + store.getters['auth/userToken'],
        api_token: Env.productApiToken,
      }, {
        productIds
      })
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Fetch assigned products
   */
  fetchAssignedProducts(discountsId) {
    const url = `${Env.productUrl}/api/discounts/${discountsId}/allProducts`;
    return AuthApis.getApi(url, {
        'Authorization': 'Bearer ' + store.getters['auth/userToken'],
        api_token: Env.productApiToken,
      })
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  updateSortOrder(data){
    const url = `${Env.productUrl}/${apiVersion}/discounts/sort/updateSortOrder`;
    return AuthApis.putApi(url, {
        'Authorization': 'Bearer ' + store.getters['auth/userToken'],
        api_token: Env.productApiToken,
      }, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  }

}