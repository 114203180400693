import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "bg-[#23242A] text-white h-screen appheader overflow-y-auto p-3",
  id: "content_screen"
}
const _hoisted_2 = { class: "p-3 flex content-main" }
const _hoisted_3 = { class: "custom-full" }
const _hoisted_4 = { class: "bg-[#2B2C32] rounded-lg px-[16px] max-w-full py-[16px] mb-[80px]" }
const _hoisted_5 = {
  key: 1,
  class: "text-center text-theme-red"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Nav = _resolveComponent("Nav")!
  const _component_CustomerImport = _resolveComponent("CustomerImport")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Nav, { activeTab: "CustomerImport" }),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.vg_selectedLocation.id)
            ? (_openBlock(), _createBlock(_component_CustomerImport, { key: 0 }))
            : (_openBlock(), _createElementBlock("p", _hoisted_5, "Please select a location from the top left corner."))
        ])
      ])
    ])
  ]))
}