import store from '@/store/index';
import router from '@/router';

export default {

    /**
     * Is status is 401 then logout user with clear token adn refresh
     */
    detectStatusAndRedirect(err)  {
      if(err.response.status == 401) {
        store.dispatch('auth/clearUserData');
        router.push({
          name: 'Login',
          query: {
            reload: 1,
            sessionExpired: 1,
          }
        })
      }
    },


}
