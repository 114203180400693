
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "CustomModal",
  components: {
  },
  props: {
    size: {
      required: false,
      type: String,
    }
  },
  setup() {
  
  },
});
