/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import store from '@/store/index';
import ResourceApis from '@/controllers/ResourceApis';
import router from '@/router';

export default {
    /*
      * Fetch all products with location
    */
    getPresignedUrl(data){
        let userToken = store.getters['auth/userToken'];
        const url = `/resources/upload`;
        return ResourceApis.postApi(url, {
            'Authorization': 'Bearer '+ userToken
        }, data)
        .then(res => res)
        .catch(err => {
            if(err.response.status == 401) {
                // clear user login data
                store.dispatch('auth/clearUserData');
                router.push({
                  name: 'Login'
                })
            }
            return err;
        })
    },

    /*
      * Fetch all products with location
    */
    uploadResource(url, data){
        let userToken = store.getters['auth/userToken'];
        return ResourceApis.putApi(url, {}, data)
        .then(res => res)
        .catch(err => {
            if(err.response.status == 401) {
                // clear user login data
                store.dispatch('auth/clearUserData');
                router.push({
                  name: 'Login'
                })
            }
            return err;
        })
    }
}