import store from '@/store';
import Env from '@/env';
import isEmpty from 'loadsh/isEmpty';
/**
 * Check if token exist or not, if not redirect back to login page
 */
export default function activebusiness({ next, router, to}) {
    let activeBusiness = store.getters['auth/activeBusiness'];
    if (!isEmpty(activeBusiness)) {
      // if token push to SelectBusiness
      return router.push({ name: 'SelectLocation' });
    }
    return next();
}