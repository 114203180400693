/**
 * @module Employee 
 *  Apis must need auth details to trigger url - AuthApi
 */

 import store from '@/store/index';
 import Env from '@/env';
 import NormalApis from '@/controllers/NormalApis';
 import AuthApis from '@/controllers/AuthApis';
 import ApiHelper from '@/controllers/ApiHelpers';
 const apiVersion = 'api';
 import router from '@/router';

export default {
    /**
      * Get All Users
      */
     getAllUsers(query='') {
        let userToken = store.getters['auth/userToken'];
        const url = `${Env.loginUrl}/${apiVersion}/users/all?${query}`;
        return NormalApis.getApi(url, {
            'Authorization': 'Bearer '+ userToken
        })
        .then(res => res)
        .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
        });
    },

    createUser(data){
        let userToken = store.getters['auth/userToken'];
        const url = `${Env.loginUrl}/${apiVersion}/users/create`;
        return NormalApis.postApi(url, {
            'Authorization': 'Bearer '+ userToken
        }, data)
        .then(res => res)
        .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
        });
    },

    editUser(userId, data){
        let userToken = store.getters['auth/userToken'];
        const url = `${Env.loginUrl}/${apiVersion}/users/${userId}/update`;
        return NormalApis.patchApi(url, {
            'Authorization': 'Bearer '+ userToken
        }, data)
        .then(res => res)
        .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
        });
    },

    deleteUser(userId){
        let userToken = store.getters['auth/userToken'];
        const url = `${Env.loginUrl}/${apiVersion}/businesses/users/${userId}/remove`;
        return NormalApis.deleteApi(url, {
            'Authorization': 'Bearer '+ userToken
        })
        .then(res => res)
        .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;  
        });
    },

    getUserLocations(userId){
      let userToken = store.getters['auth/userToken'];
      const url = `${Env.loginUrl}/${apiVersion}/users/${userId}/locations/all`;
      return NormalApis.getApi(url, {
          'Authorization': 'Bearer '+ userToken
      })
      .then(res => res)
      .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
      });
    },

    addLocationsToUser(userId, data){
      let userToken = store.getters['auth/userToken'];
      if(data.locationIds===null) data.locationIds = []
      const url = `${Env.loginUrl}/${apiVersion}/users/${userId}/locations/sync`;
        return NormalApis.postApi(url, {
            'Authorization': 'Bearer '+ userToken
        }, data)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
      });
    },

    listRoles(){
      let userToken = store.getters['auth/userToken'];
      const url = `${Env.loginUrl}/${apiVersion}/business-user-roles/all`;
      return NormalApis.getApi(url, {
          'Authorization': 'Bearer '+ userToken
      })
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
    },

    assignRoleToUser(userId, data){
      let userToken = store.getters['auth/userToken'];
      const url = `${Env.loginUrl}/${apiVersion}/users/${userId}/roles/assign`;
        return NormalApis.postApi(url, {
            'Authorization': 'Bearer '+ userToken
        }, data)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
    },

    /**
     * 
     * @param id 
     * @returns user pin
     */
    getUserPinByUserId(id) {
        const url = `${Env.loginUrl}/${apiVersion}/users/${id}/show-pin`;
        return AuthApis.getApi(url, null)
        .then(res => res)
        .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
        });
        
    }

    
}