
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';
import LoyaltyFaqApis from "@/controllers/LoyaltyFaqApis";
import ReturnConfirmModal from "@/components/general/dialog/ReturnConfirmModal.vue";

export default defineComponent({
  name: "MembershipFaqsUpdate",
  components: {
    ReturnConfirmModal
  },
  props:{
    loyaltyProgram: {
      type: Object,
      required: true,
    },
    faqCategory: {
      type: Object,
      required: true,
    },
    clearComponent: {
      type: Function,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const snackbar = useSnackbar();
    const form = <any>ref({
      question: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'min:3'],
      },
      answer: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'min:3'],
      },
      sort_order: 1,
    });
    const deleteObj = ref({
      modal: false,
      obj: {},
    });
    return {
      form,
      snackbar,
      deleteObj
    };
  },

  watch:{
    'item'() {
      this.assignValues();
    },
  },

  computed: {
    ...mapGetters({
     
    }),
    /**
     * Edit restriction
     */
    can_edit_membership_details() {
      return Helpers.getMyPermission('can_edit_membership_details')
    },
  },

  mounted() {
    this.assignValues();
  },
 
  methods: {
    /**
     * Assign fields 
     */
    async assignValues() {
      this.form = await Helpers.assignFormValues(this.form, this.item);
    },
   /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },
    
    /**
     * Update
     */
    async update() {
      let validate = await this.validateFields(['question', 'answer', 'sort_order']);
      if(!validate) {
        return ;
      }
      this.va_spinner(true);
      let res = await LoyaltyFaqApis.update(this.loyaltyProgram.id, this.faqCategory.id, this.item.id, Helpers.getFormKeyValue(this.form));
      this.va_spinner(false);
      if(res.status == 200 ) {
        this.clearComponent();
        this.emitter.emit("refresh-faqs-list", true);
      }
      this.snackbar.add({
        type: 'info',
        text: res.status == 200 ? res.data.msg : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
    },

     /**
       * Open delete confirm modal
       */
      openDeleteConfirmModal() {
        this.deleteObj = {
          modal: true,
          obj: this.item,
        };
      },

      /**
       * Close delete confirm modal
       */
      closeDeleteModal() {
        this.deleteObj = {
          modal: false,
          obj: {},
        };
      },

     /**
     * Delete  
     */
      async deleteFaq(obj) {
        this.va_spinner(true);
        let res = await LoyaltyFaqApis.remove(this.loyaltyProgram.id, this.faqCategory.id, this.item.id);
        this.va_spinner(false);
          this.snackbar.add({
          type: 'info',
          text: res.status == 200 ? res.data.msg : Helpers.catchErrorMessage(res.response.data),
          duration: 3000,
        })
        if(res.status == 200 ) {
          this.clearComponent();
          this.emitter.emit("refresh-faqs-list", true);
        }
      },

    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  },
});

