
import {defineComponent, ref} from "vue"
import { mapGetters} from "vuex";
import Nav from './Nav.vue';
import ProductImport from './ProductImport.vue';
import TemplateImport from './TemplateImport.vue';



export default defineComponent({
  name: "ProductTab",
  components: {
    Nav,
    ProductImport,
    TemplateImport
  },

  

  computed: {
    ...mapGetters({
     vg_selectedLocation: 'location/selected'
    }),
  },
 
});

