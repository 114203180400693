
  
  import {defineComponent, ref} from "vue"
  import LocationCannabisSetting from '@/controllers/LocationCannabisSetting';
  import { mapGetters, mapActions } from "vuex";
  import Helpers from '@/helpers';
  import { useSnackbar } from "vue3-snackbar";

  
  export default defineComponent({
    name: "LocationLotTracking",
    setup() {
        const snackbar = useSnackbar();
        const loading = ref(false);
        const form = ref({
            cannabisWeightLimit: {
                value: 0,
                error_type: '',
                error_message: '',
                validations: ['required_min_0'],
            },

          lotTracking: false,
        });
        const locationLotTraking = ref({});
      
      return {
        form,
        loading,
        locationLotTraking,
        snackbar
      };
    },
  
    computed: {
      ...mapGetters({
        va_activeBusiness: 'auth/activeBusiness',
        vg_selectedLocation: 'location/selected'
      }),
    },

    watch : {
      'vg_selectedLocation'(val) {
        // if location selected clear component
        this.fetchLocationLot();
      },
    },
   
  
    async mounted() {
      if(this.vg_selectedLocation.id){
        this.loading = true;
        await this.fetchLocationLot();
        this.loading = false;
      }
    },
  
    methods: {
  
      /**
       * Validation captures
       */
      async validateFields(fields) {
        let res = await Helpers.validateFormFields(this.form, fields);
        // capture the fields
        this.form = res.data;
        return res.error ? false : true;
      },
  
      
  
      /**
       * Create business branding
       */
      async createLocationLot() {
        // validate business field
        let validate = await this.validateFields(['cannabisWeightLimit']);
        if(!validate) {
          return ;
        }
        this.va_spinner(true);
       
        let res = await LocationCannabisSetting.create(Helpers.getFormKeyValue(this.form));
        this.va_spinner(false);
        this.snackbar.add({
          type: 'info',
          text: (res.status == 201 || res.status == 200) ? res.data.message : Helpers.catchErrorMessage(res.response.data),
          duration: 3000,
        })
  
      },
  


      /**
       * Fetch business lot
       */
       async fetchLocationLot() {
        let res = await LocationCannabisSetting.fetchSetting();
        this.form.cannabisWeightLimit.value =  res.status == 200 ? res.data.payload.cannabisWeightLimit : 0;
        this.form.lotTracking =  res.status == 200 ? res.data.payload.lotTracking : false;

       },
  
  
      ...mapActions({
        va_spinner: 'spinner/setSpinner',
      })
  
    },
  });
  
  