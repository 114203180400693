
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
import List from '@/views/Membership/Program/Badges/List.vue';
import Add from '@/views/Membership/Program/Badges/Add.vue';
import Update from '@/views/Membership/Program/Badges/Update.vue';
import Nav from "@/views/Membership/Program/Nav.vue";
import LoyaltyProgramApis from "@/controllers/LoyaltyProgramApis";
import LoyaltyEarnSettingApis from "@/controllers/LoyaltyEarnSettingApis";
import Helpers from '@/helpers';

  export default defineComponent({
  name: "MembershipBadges",
  components: {
    Nav,
    List,
    Add,
    Update,
  },

  setup() {
    const action = ref<object>({
      component: '',
      data: {},
    });
    const loyaltyProgram = <any>ref({
      id: null
    });
    const earningSetting = ref({});
    const loadingBadges = ref(false);
    return {
      action,
      earningSetting,
      loyaltyProgram,
      loadingBadges
    };
  },

  watch : {
    'vg_selectedLocation'(val) {
      // if location selected clear component
      this.clearComponent();
    },
  },

  computed: {

    /**
     * Edit restriction
     */
    can_edit_membership_details() {
      return Helpers.getMyPermission('can_edit_membership_details')
    },

    ...mapGetters({
     vg_selectedLocation: 'location/selected'
    }),
  },

  async mounted() {
    this.loadingBadges = true;
    let loyaltyProgramRes = await LoyaltyProgramApis.getProgramByCurrentActiveBusiness();
    if(loyaltyProgramRes.status == 200 || loyaltyProgramRes.status == 201) {
     this.loyaltyProgram = loyaltyProgramRes.data;
     let res = await LoyaltyEarnSettingApis.fetchSetting(loyaltyProgramRes.data.id);
     if(res.status == 200) {
      this.earningSetting = res.data;
     }
    }
    this.loadingBadges = false;
    this.emitter.on("reset-badges-component", data => {
      this.clearComponent();
    });
  },

  beforeDestroy() {
    this.emitter.off('reset-badges-component', () => {});
  },
 
  methods: {
    /**
     * Clear action
     */
    clearComponent() {
      this.action = {
        component: '',
        data: {},
      };
      let activeItem = document.getElementsByClassName('active-row');
      if(activeItem.length > 0){
        activeItem[0].classList.remove("active-row");
      }
    },

    /**
     * Change add / update component
     */
    addUpdate(val: any) {
      this.action = val;
    },

    /**
     * Capture selected item
     */
    selectedItem(item) {
      this.addUpdate({
        component: 'Update', 
        data: item
      })
    },

    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  },
});

