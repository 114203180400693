<template>
    <div class="text-white flex-1 col-span-1 border-0 rounded-t-xl transition-opacity duration-1000 ease-in-out">
        <div class="text-white flex-1 col-span-1 border-0 rounded-t-xl">
            <div class="bg-theme-green rounded-t-xl px-4 py-2 text-white">
                <div class="flex justify-between items-center">
                    <div>
                        <h2 class="font-bold tracking-wider truncate text-single-line leading-tight text-2xl mt-0 mb-4">{{editItem.name}}</h2>
                        <h3 class="text-sm font-semibold truncate text-single-line leading-tight" v-if="editItem.brand">{{editItem.brand.name}}</h3>
                    </div>
                    <div class="flex flex-col">
                        <button @click="closeEdit" class="mb-3 flex justify-end">
                            CLOSE
                        </button>
                        <div class="mb-1 relative flex justify-end gap-2" tabindex="1"  @focusout="focusOutDropDown">
                            <button @click="save" class="uppercase hover:bg-slate-100 px-[14px] py-[10px] bg-white rounded text-sm ml-3 tracking-widest text-theme-green">
                                save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <component :is="editPage" :brands="brands" :editItem="editItem" />
        </div> 
    </div>
</template>
<script>
import { ref, defineComponent } from 'vue'
import DefaultVariant from './defaultVariant.vue'
import {mapGetters, mapMutations} from 'vuex'
import ProductApi from '@/controllers/BusinessProductApis'

export default defineComponent({
    props: ['editItem'],
    components: {
        DefaultVariant,
    },
    setup() {
        const categories = ref([])
        const brands = ref([])
        const taxes = ref([])
        const editPage = ref('DefaultVariant')

        return {categories, brands, taxes, editPage}
    },
    async mounted(){
        this.vm_spinner(true)
        await this.getBrand()
        this.vm_spinner(false)
        this.emitter.on("reloadProduct", this.reloadProduct);
    },
    beforeDestroy(){
        this.emitter.off("reloadProduct", this.reloadProduct);
    },
    unmounted(){
        this.emitter.off("reloadProduct", this.reloadProduct);
    },
    watch: {
        async editItem(item){
            this.vm_spinner(true)
            this.vm_spinner(true)
            await this.getBrand()
            this.vm_spinner(false)
            this.emitter.on("reloadProduct", this.reloadProduct);
        }
    },
    computed: {
        ...mapGetters({
            vg_selectedBusiness: 'auth/activeBusiness',
            vg_selectedLocation: 'location/selected'
        })
    },
    methods: {
        closeEdit(){
            this.$emit('close')
        },
        save(){
            this.emitter.emit('saveEditProduct', true)
        },
        async getCategory(){
            this.categories = []
            let res = await ProductApi.fetchAllCategory()
            if(res.data?.payload?.category){
                for(let i=0; i<res.data.payload.category.length; i++){
                    this.categories.push(res.data.payload.category[i])
                    if(res.data.payload.category[i].subCategories && res.data.payload.category[i].subCategories.length>0){
                        for(let j=0; j<res.data.payload.category[i].subCategories.length; j++){
                            this.categories.push(res.data.payload.category[i].subCategories[j])
                        }
                    }
                }
            }
        },
        async getBrand(){
            let res = await ProductApi.fetchAllBrands()
            if(res.data?.payload?.brands){
                this.brands = res.data.payload.brands
            }
        },
        async getTaxes(){
            if(this.vg_selectedBusiness.id){
                let res = await ProductApi.fetchAllTaxes(this.vg_selectedBusiness.id)
                if(res.data?.payload){
                    this.taxes = res.data.payload
                }
            }
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }
})
</script>