/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import store from '@/store/index';
import Env from '@/env';
const apiVersion = 'api';
import AuthApis from '@/controllers/AuthApis';
import Helpers from '@/helpers';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /*
   * Fetch all
   */
  fetchAll(perPage, page, orderby = 'title', sort = 'asc') {
    const url = `${Env.productUrl}/${apiVersion}/sale/forBusiness?perPage=${perPage}&page=${page}&orderby=${orderby}&sort=${sort}`; // &search=thr
    return AuthApis.getApi(url, {
      api_token: Env.productApiToken,
      'Authorization': 'Bearer ' + store.getters['auth/userToken'],
    }).then((response) => {
      return response;
    }).catch((err) => {
      ApiHelper.detectStatusAndRedirect(err);
      return err;
    })
  },

  /*
   * Create
   */
  async create(data) {
    data = await Helpers.setEmptyValueToNull(data);
    const url = `${Env.productUrl}/${apiVersion}/sale/create`; // &search=thr
    return AuthApis.postApi(url, {
      api_token: Env.productApiToken,
      'Authorization': 'Bearer ' + store.getters['auth/userToken'],
    }, data).then((response) => {
      return response;
    }).catch((err) => {
      ApiHelper.detectStatusAndRedirect(err);
      return err;
    })
  },

  /*
   * Update
   */
  async update(id, data) {
    data = await Helpers.setEmptyValueToNull(data);
    const url = `${Env.productUrl}/${apiVersion}/sale/update/${id}`; // &search=thr
    return AuthApis.patchApi(url, {
      api_token: Env.productApiToken,
      'Authorization': 'Bearer ' + store.getters['auth/userToken'],
    }, data).then((response) => {
      return response;
    }).catch((err) => {
      ApiHelper.detectStatusAndRedirect(err);
      return err;
    })
  },

  /*
   * Delete
   */
  delete(id) {
    const url = `${Env.productUrl}/${apiVersion}/sale/${id}`; // &search=thr
    return AuthApis.deleteApi(url, {
      api_token: Env.productApiToken,
      'Authorization': 'Bearer ' + store.getters['auth/userToken'],
    }).then((response) => {
      return response;
    }).catch((err) => {
      ApiHelper.detectStatusAndRedirect(err);
      return err;
    })
  },

  /**
   * Assign location
   */
  assignLocation(id, locationIds) {
    const url = `${Env.productUrl}/${apiVersion}/sale/${id}/assignLocations`;
    return AuthApis.postApi(url, {
      api_token: Env.productApiToken,
      'Authorization': 'Bearer ' + store.getters['auth/userToken'],
    }, {
      locationIds
    }).then((response) => {
      return response;
    }).catch((err) => {
      ApiHelper.detectStatusAndRedirect(err);
      return err;
    })
  },

  /**
   * Fetch Assigned location
   */
  fetchAssignedLocation(id) {
    const url = `${Env.productUrl}/${apiVersion}/sale/${id}/getLocations`;
    return AuthApis.getApi(url, {
      api_token: Env.productApiToken,
      'Authorization': 'Bearer ' + store.getters['auth/userToken'],
    }).then((response) => {
      return response;
    }).catch((err) => {
      ApiHelper.detectStatusAndRedirect(err);
      return err;
    })
  },


  /**
   * Assign product
   */
  assignProduct(id, productIds) {
    const url = `${Env.productUrl}/${apiVersion}/sale/${id}/assignProducts`;
    return AuthApis.postApi(url, {
      api_token: Env.productApiToken,
      'Authorization': 'Bearer ' + store.getters['auth/userToken'],
    }, {
      productIds
    }).then((response) => {
      return response;
    }).catch((err) => {
      ApiHelper.detectStatusAndRedirect(err);
      return err;
    })
  },

  /**
   * Fetch Assigned product
   */
  fetchAssignedProducts(id) {
    const url = `${Env.productUrl}/${apiVersion}/sale/${id}/getProducts`;
    return AuthApis.getApi(url, {
      api_token: Env.productApiToken,
      'Authorization': 'Bearer ' + store.getters['auth/userToken'],
    }).then((response) => {
      return response;
    }).catch((err) => {
      ApiHelper.detectStatusAndRedirect(err);
      return err;
    })
  },


}