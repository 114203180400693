
import store from '@/store';
import Env from '@/env';
import isEmpty from 'lodash/isEmpty';
/**
 * Check if token exist or not, if not redirect back to login page
 */
export default function guest({ next, router, to}) {
    let userToken = store.getters['auth/userToken'];
    if (userToken) {
      let activeBusiness = store.getters['auth/activeBusiness'];
      if(isEmpty(activeBusiness)) {
        return router.push({ name: 'SelectBusiness' });
      } else {
        return router.push({ name: 'Products' });
      }
      // if token push to Products
    }
    return next();
}
