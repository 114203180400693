
import  { defineComponent, ref} from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import newTableList from '@/components/table/newTableLite.vue'
import Env from '@/env';
import moment from 'moment';
import ProductDiscountApis from '@/controllers/ProductDiscountApis';

export default defineComponent({
  name: 'ProductDiscountCodeList',
  components: {
    newTableList
  },
  props: {
    iscomponentactive: {
      type: Boolean,
      required: false,
    },
    selectedCallback: {
      type: Function,
      required: true,
    },
    clearComponent: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const snackbar = useSnackbar();
    const confirmStatusObj = ref({
      modal: false,
      obj: {
        status: Boolean,
        id: Number,
      },
    });
    const activeItem = ref<any>({});
    const loading = ref<Boolean>(false);
    const columnsMain = ref([
      {
        label: "Name",
        field: "title",
        sortable: true,
        
      },
      {
        label: "Applied To",
        field: "applyDiscountOn",
        sortable: false,
        
      },
      {
        label: "Value",
        field: "discount",
        sortable: false,
        
      },
       {
        label: "User Type",
        field: "userType",
        sortable: false,
        
      },
       {
        label: "Start Date",
        field: "startDate",
        sortable: false,
        
      },
      {
        label: "End Date",
        field: "endDate",
        sortable: false,
      },
     
   
    ]);

    const table = <any>ref({
      loading: false,
      draggable: true,
      columns: [],
      rows: [],
      totalRecords: 0,
      page: 1,
      sortable: {
        order: "title",
        sort: "asc",
      },
      pagination: true,
      pagesize: Env.defaultPageSize,
      pageOptions: Env.pageList,
    });

    return  {
      table,
      columnsMain,
      activeItem,
      loading,
      confirmStatusObj,
      snackbar,
    };
  },

 

  async mounted() {
    this.columns()
    this.loading = true;
    await this.fetchList(this.table.page);
    this.loading = false;
    this.emitter.on("refresh-product-discount-list", data => {
      this.fetchList(this.table.page);
    });
    
  },


  beforeDestroy() {
   this.emitter.off('refresh-product-discount-list', () => {})
  },

  watch: {
    'vg_selectedLocation'(val) {
      // if location selected is changed fetch the data
      this.fetchList(this.table.page);
    },
    iscomponentactive(isCompActive){
      if(!isCompActive){
        this.columns()
      }
    }
  },

  computed: {
    ...mapGetters({
      vg_selectedLocation: 'location/selected'
    }),
  },

  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    /**
     * Fetch list
     */
    
    async fetchList(page) {
      this.table.loading = true;
      let res = await ProductDiscountApis.fetchAll( this.table.pagesize, page, this.table.sortable.order, this.table.sortable.sort);
      this.table.loading = false;
      this.table.rows = (res.status == 200) ? this.sort(res.data.payload.discounts) : [];
      this.table.totalRecords = (res.status == 200) ? res.data.payload.totalRecords : 0
      this.table.page = page;
    },

    sort(items){
      return items.sort(function(a, b) { 
        return - ( b.sort - a.sort);
      });
    },

    /**
     * Select the item
     */
    setActive(item) {
      this.selectedCallback(item);
      let self = this
      setTimeout(function(){
        self.columns()
      }, 100)
    },

    selectedRow(obj){
      this.setActive(obj)
    },

    /**
     * Compute columns
     */
    columns() {
      if(this.iscomponentactive){
        let removeFields= ['startDate', 'endDate', 'userType'];
        this.table.columns = this.columnsMain.filter(item => {
            if( removeFields.indexOf(item.field) == -1 ) {
              return item;
            }
        })
      }else{
        this.table.columns = this.columnsMain;
      }
    },

    async paginate(page){
      this.columns()
      this.table.loading = true;
      this.table.page = page
      await this.fetchList(this.table.page);
      this.table.loading = false;
      this.emitter.on("refresh-product-discount-list", data => {
        this.fetchList(this.table.page);
      });
    },

    async sortedRow(rows){
      let ids = rows.map(each => each.id)
      this.vm_spinner(true)
      await ProductDiscountApis.updateSortOrder({discountIds: ids})
      await this.fetchList(this.table.page);
      this.vm_spinner(false)
    },

    ...mapMutations({
      vm_spinner: "spinner/setSpinner",
    })

  }
});
