<template>
  <div class="bg-[#23242A] text-white custom p-3" id="content_screen">
    <div :class="'p-3 flex content-main ' + (action.component ? ' half-it gap-5' : 'gap-5')">
      <div class="custom-full custom-left">
        <Nav/>
        <div class="bg-[#2B2C32] rounded-lg px-[16px]">
          <template v-if="vg_selectedLocation.id">
            <div class="flex justify-between mb-5">
              <h2 class="text-[24px] font-semibold text-white">Audits</h2>
              <mdicon @click="add" size="32" name="plus" class="text-white bg-theme-green hover:bg-theme-darkgreen mt-2 py-1 h-10 px-1 text-2xl border-0 rounded-md cursor-pointer"/>
            </div>
            <List :selectedCallback="selectedItem" :clearComponent="clearComponent" :iscomponentactive="action.component ? true : false"/>
          </template>
          <p v-else class="text-center text-theme-red">Please select a location from the top left corner.</p>
        </div>
      </div>
      <div :class="'w-full hidden-component custom-right'" v-if="action.component" >
        <component :is="action.component" :item="action.data" :clearComponent="clearComponent"></component>
      </div>
    </div>

    <CustomDailogue v-if="confirmModal.modal" @click="confirmModal.modal=false" size="max-w-[500px]">
      <template v-slot:maincontent>
        <div class="flex flex-col justify-center items-center mx-6 my-6">
          <div>
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.26676 56C6.77787 56 6.33342 55.8778 5.93342 55.6333C5.53342 55.3889 5.22231 55.0667 5.00009 54.6667C4.77787 54.2667 4.65565 53.8333 4.63342 53.3667C4.6112 52.9 4.73342 52.4444 5.00009 52L29.6668 9.33333C29.9334 8.88889 30.2779 8.55556 30.7001 8.33333C31.1223 8.11111 31.5556 8 32.0001 8C32.4445 8 32.8779 8.11111 33.3001 8.33333C33.7223 8.55556 34.0668 8.88889 34.3334 9.33333L59.0001 52C59.2668 52.4444 59.389 52.9 59.3668 53.3667C59.3445 53.8333 59.2223 54.2667 59.0001 54.6667C58.7779 55.0667 58.4668 55.3889 58.0668 55.6333C57.6668 55.8778 57.2223 56 56.7334 56H7.26676ZM32.0001 48C32.7556 48 33.389 47.7444 33.9001 47.2333C34.4112 46.7222 34.6668 46.0889 34.6668 45.3333C34.6668 44.5778 34.4112 43.9444 33.9001 43.4333C33.389 42.9222 32.7556 42.6667 32.0001 42.6667C31.2445 42.6667 30.6112 42.9222 30.1001 43.4333C29.589 43.9444 29.3334 44.5778 29.3334 45.3333C29.3334 46.0889 29.589 46.7222 30.1001 47.2333C30.6112 47.7444 31.2445 48 32.0001 48ZM32.0001 40C32.7556 40 33.389 39.7444 33.9001 39.2333C34.4112 38.7222 34.6668 38.0889 34.6668 37.3333V29.3333C34.6668 28.5778 34.4112 27.9444 33.9001 27.4333C33.389 26.9222 32.7556 26.6667 32.0001 26.6667C31.2445 26.6667 30.6112 26.9222 30.1001 27.4333C29.589 27.9444 29.3334 28.5778 29.3334 29.3333V37.3333C29.3334 38.0889 29.589 38.7222 30.1001 39.2333C30.6112 39.7444 31.2445 40 32.0001 40Z" fill="#FCB852"/>
            </svg>
          </div>
          <h1 class="mt-4 text-xl font-bold">Permission is disabled</h1>
          <p class="mt-2 text-center">Sorry, you do not have permission to perform an inventory audit. Please check your roles and permissions or ask your manager for help.</p>
          </div>
      </template>
  </CustomDailogue>
  </div>
</template>


<script>
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
import Nav from '@/views/Inventory/Audits/Nav.vue';
import List from '@/views/Inventory/Audits/List.vue';
import Add from '@/views/Inventory/Audits/Add.vue';
import Helpers from '@/helpers'
import CustomDailogue from "@/components/general/dialog/CustomDailogue.vue";

  export default defineComponent({
  name: "ProductAuditIndex",
  components: {
    Nav,
    List,
    Add,
    CustomDailogue
  },

  setup() {
    const confirmModal = ref({
        modal: false,
    });
    const action = ref({
      component: '',
      data: {},
    });
    return {
      action,
      confirmModal
    };
  },

   watch: {
    'vg_selectedLocation'(val) {
      // if location selected clear component
      this.clearComponent();
    },
  },

  computed: {
    can_edit_audit() {
        return Helpers.getMyPermission('can_perform_inventory_audits')
    },
    ...mapGetters({
     vg_selectedLocation: 'location/selected'
    }),
  },

  mounted() {
    this.emitter.on("reset-product-categories-component", data => {
      this.clearComponent();
    });
  },

  beforeDestroy() {
    this.emitter.off('reset-product-categories-component', () => {});
    this.emitter.off('refresh-product-categories-list', () => {});
  },
 
  methods: {
    /**
     * Clear action
     */
    clearComponent() {
      this.action = {
        component: '',
        data: {},
      };
      let activeItem = document.getElementsByClassName('active-row');
      if(activeItem.length > 0){
        activeItem[0].classList.remove("active-row");
      }
    },

    add(){
      if(!this.action.component && this.can_edit_audit){
        this.addUpdate({ component: 'Add', data: {}})
      }else{
        this.confirmModal.modal = true;
      }
    },

    /**
     * Change add / update component
     */
    addUpdate(val) {
      this.action = val;
    },

    /**
     * Capture selected item
     */
    selectedItem(item) {
      if(this.can_edit_audit){
        this.$router.push({name: 'ProductAuditsView', params: {id: item.id}})
      }else{
        this.confirmModal.modal = true;
      }
    },

    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  },
});

</script>