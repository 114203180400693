<template>
    <div class="qr-wrapper html2pdf__page-break">
      <div class="corner-top-left"></div>
      <div class="corner-top-right"></div>
      <div class="hero-qr" :style="`background-color: ${vg_branding.primaryColor}`">
        <h1>{{ product.category?.name }}</h1>
      </div>
      <div class="qr-items-content">
        <h3 class="content-margin-top">
            {{ product.brand && product.brand.name }}
        </h3>
      
         <h2 :class="'content-margin-top ' +  (type)">
            {{ product.name ? (product.name.length > 65 ? (product.name.substring(0, 65) + '...') : product.name) : '' }}
        </h2>
        <div class="plant-type-wrapper content-margin-top">
          <p :style="`color: ${vg_branding.primaryColor}`" >{{ product.variantName ? product.variantName : '-N/A-' }}</p>
          <div class="plant-type" :style="`background-color: ${vg_branding.primaryColor}`">
            <span>{{
              product.strain
            }}</span>
          </div>
        </div>

        <div class="plant-type-range content-margin-top">
          <div class="thc-wrapper" :style="`color: ${vg_branding.secondaryColor}`">
            <span :style="`color: ${vg_branding.secondaryColor}`">THC</span>
            <span :style="`color: ${vg_branding.secondaryColor}`" v-if="product.thcCbdValueType=='range'">
                {{ product.thcMin }} - {{ product.thcMax}}{{product.concentration_unit}}
            </span>
            <span :style="`color: ${vg_branding.secondaryColor}`" v-else>
                {{product.thc}}{{product.concentration_unit}}
            </span>
          </div>
          <div class="cbd-wrapper" :style="`color: ${vg_branding.secondaryColor}`">
            <span :style="`color: ${vg_branding.secondaryColor}`">CBD</span>
            <span :style="`color: ${vg_branding.secondaryColor}`" v-if="product.thcCbdValueType=='range'">
                {{ product.cbdMin }} - {{ product.cbdMax}}{{product.concentration_unit}}
            </span>
            <span :style="`color: ${vg_branding.secondaryColor}`" v-else>
                {{product.cbd}}{{product.concentration_unit}}
            </span>
          </div>
        </div>
        <div class="qr-footer content-margin-top">
          <div class="scanner-wrapper">
            <p :style="`color: ${vg_branding.secondaryColor}`">more info</p>
            <div class="qr-scanner" style="max-width:304px;">
                <qrcode-vue :value="getQrCodeValue" :size="288" level="L" />
                <div class="qrcode-image">
                    <svg width="40" height="40" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.2771 10.1888L23.8023 19.7587C24.3641 20.7318 23.6618 21.9482 22.5381 21.9482L13.3069 21.9482L13.3069 10.1888L18.2771 10.1888Z" fill="white"/>
                        <path d="M10.736 0.782108C11.2979 -0.191042 12.7025 -0.191041 13.2644 0.78211L17.0922 7.41213H6.9082L10.736 0.782108Z" fill="#B0EDAE"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.355 11.2836L1.14586 20.3061C1.0054 20.5493 1.18098 20.8534 1.4619 20.8534L9.59827 20.8534L9.59826 11.2836L6.355 11.2836ZM0.197744 19.7587L5.72292 10.1888L10.6931 10.1888L10.6931 21.9482L1.4619 21.9482C0.338206 21.9482 -0.364105 20.7318 0.197744 19.7587Z" fill="white"/>
                    </svg>
                </div>
            </div>
          </div>
          <div class="price-wrapper">
            <p :style="`color: ${vg_branding.secondaryColor}`">price</p>
            <div class="total-price-amount">
              <span :style="`color: ${vg_branding.secondaryColor}`">${{ (product.ppiSalePrice && (product.ppiSalePrice < product.ppiPrice)) ? product.ppiSalePrice?.toFixed(2) : product.ppiPrice >= 0 ? product.ppiPrice?.toFixed(2) : 'N/A' }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="corner-bottom-left"></div>
      <div class="corner-bottom-right"></div>
    </div>
</template>
<script>
import { defineComponent, ref } from "vue"
import { mapGetters, mapActions } from "vuex";
import QrcodeVue from 'qrcode.vue'
import env from "@/env";

export default defineComponent({
    components: {
      QrcodeVue
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
         type: {
          type: String,
          required:  false,
        }
    },
    computed: {
        ...mapGetters({
            vg_activeBusiness: 'auth/activeBusiness',
            vg_locationSelected: "location/selected",
            vg_branding: "auth/branding"
        }),
        getQrCodeValue() {
          if(this.product?.id && this.vg_activeBusiness?.slug && this.vg_locationSelected?.slug){
            let productSlug = this.product?.name?.trim().toLowerCase().replace(/[^a-zA-Z0-9]+/g, '_')+'-'+this.product.id
            return (
                env.webStoreUrl + '/' +
                this.vg_activeBusiness.slug +
                '/' +
                this.vg_locationSelected.slug +
                '/' +
                productSlug +
                '?in_store=true&noage=true'
            )
          }
        },
        ...mapActions({
            va_spinner: 'spinner/setSpinner',
        })
    },
})
</script>
<style>
    @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@400;500;900&display=swap');
    .qr-wrapper {
        background: #fff;
        width: 900px;
        height: 1425px;
        margin: 0 auto;
        border: 1px solid #c4c4c4;
        position: relative;
        box-sizing: border-box;
      
      }
      .scanner-wrapper p,
      .price-wrapper p{
        padding-bottom: 16px;
      }
      .hero-qr {
        height: 138px;
        padding: 24px 140px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .hero-qr h1 {
        text-transform: uppercase;
        font-size: 60px;
        color: #fff;
        font-family: 'Alegreya Sans SC';
        font-weight: 500;
        margin:0;
        padding-bottom:69px;
        line-height: 55px;
        word-break: break-all;
      
      }
      .qr-items-content {
        padding: 0px 54px;
      }
      .qr-items-content h3 {
        height: 38px;
        font-size: 32px;
        line-height: 38.4px;
        letter-spacing: 2px;
        color: #000;
        margin-top: 108px;
        text-transform: uppercase;
      }
      .qr-items-content h3 span{
        font-size: 40px;
        word-break: break-all;
      }
     
      .qr-items-content h2 {
        font-weight: 400;
        height: 228px;
        font-size: 76px;
        letter-spacing: 1.25px;
        line-height: 76px;
        color: #000;
        margin-top: 108px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .qr-items-content h2.pdf {
        line-height: 66px;
        padding-top: 0px !important;
      }
      .qr-items {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 40px;
      }
      .plant-type-wrapper p {
        text-transform: uppercase;
        font-weight: 900;
        font-size: 38px;
        height: 48px;
        line-height: 47.5px;
        letter-spacing: 0.5px;
        margin-bottom: 0px;
        font-family: 'Alegreya Sans SC';
      }
      .plant-type-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* padding-bottom: 70px; */
        height: 76px;
        margin-top: 108px;
      }
      .plant-type {
        width: 231px;
        height: 92px;
        padding-bottom: 50px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items:center;
      }
      .plant-type span {
        text-transform: uppercase;
        font-weight: 900;
        font-size: 48px;
        letter-spacing: 0.5px;
        color: #fff;
        font-family: 'Alegreya Sans SC';
      }
      .plant-type-range {
        display: flex;
        justify-content: space-between;
        height: 106px;
        position: relative;
        /* overflow: hidden; */
        margin-top: 108px;
      }
      .thc-wrapper,
      .cbd-wrapper {
        display: flex;
        flex-direction: column;
      }
      .thc-wrapper{
        width: 304px;
      }
      .cbd-wrapper{
        width: 384px;
      }
      .thc-wrapper span:first-child,
      .cbd-wrapper span:first-child {
        height: 34px;
        margin-bottom: 16px;
        color: #404828;
        font-weight: 500;
        font-size: 34px;
        line-height: 34px;
        letter-spacing: 0.1px;
      }
      .thc-wrapper span:last-child,
      .cbd-wrapper span:last-child {
        height: 56px;
        font-weight: 400;
        font-size: 56px;
        color: #404828;
        line-height: 56px;
        letter-spacing: 0.5px;
      }
      .price-wrapper p,
      .scanner-wrapper p {
        text-transform: uppercase;
        color: #404828;
        font-weight: 500;
        font-size: 32px;
        line-height: 32px;
        letter-spacing: 0.1px;
        margin-bottom: 16px;
      }
      .total-price-amount {
        width: 384px;
        height: 304px;
        border-radius: 6px;
        border-bottom-left-radius: 6px;
         border-bottom-right-radius: 6px;
        display: flex;
        justify-content: center;
        /* padding: 63px 0; */
        align-items: center;
        background: #eff0eb;
      }
      .total-price-amount span {
        font-weight: 500;
        font-size: 120px;
        color: #404828;
        padding-bottom:140px;
      }
      .qr-scanner {
        width: 310px;
        height: 304px;
        position: relative;
      }
     
      .qr-scanner p {
        text-transform: uppercase;
        color: #404828;
        padding-bottom: 18px;
        font-weight: 500;
        font-size: 32px;
        line-height: 32px;
        letter-spacing: 0.1px;
      }
      .qr-footer {
        display: flex;
        justify-content: space-between;
        height: 370px;
        position: relative;
        overflow: hidden;
        margin-top: 108px;
      }
      .plant-type-wrapper,
      .thc-wrapper span:first-child,
      .cbd-wrapper span:first-child,
      .scanner-wrapper p,
      .price-wrapper p {
        font-family: 'Alegreya Sans SC';
      }
      .qr-items-content h3 {
        font-family: 'AlegreyaSC-Regular';
      }
      .qr-items-content h2,
      .thc-wrapper span:last-child,
      .cbd-wrapper span:last-child,
      .total-price-amount span {
        font-family: 'Bebas Neue';
      }
      .content-margin-top {
        margin-top: 70px !important;
      }
      .corner-bottom-left {
        position: absolute;
        border-top: 1px solid #c4c4c4;
        border-right: 1px solid #c4c4c4;
        width: 16px;
        height: 16px;
        bottom: 0;
      }
      .corner-bottom-right {
        position: absolute;
        border-top: 1px solid #c4c4c4;
        border-left: 1px solid #c4c4c4;
        width: 16px;
        height: 16px;
        bottom: 0;
        right: 0;
      }
      .corner-top-left {
        position: absolute;
        border-bottom: 1px solid #c4c4c4;
        border-right: 1px solid #c4c4c4;
        width: 16px;
        height: 16px;
        top: 0;
      }
      .corner-top-right {
        position: absolute;
        border-bottom: 1px solid #c4c4c4;
        border-left: 1px solid #c4c4c4;
        width: 16px;
        height: 16px;
        top: 0;
        right: 0;
      }
      .qrcode-image {
          background: black;
        width:40px;
        height:40px;
        border-radius: 5px;
        position: absolute;
        top: 120px;
        left: 130px;
        padding: 0px 4px;
    }
    .qrcode-image svg{
        width:100%;
        height:100%;
    }
</style>