<template>
    <div class="bg-[#23242A] h-full overflow-y-auto">
        <div class="pt-[16px] px-[24px] max-w-full">
            <div class="flex justify-between shadow-xl box-border items-center text-white h-[76px] rounded p-4 bg-[#2B2C32] mb-4">
                <h1 class="font-bold text-2xl leading-[30px] tracking-[0.05px]">Transfers</h1>
                 <button @click="newTransfer" class="flex justify-center items-center text-center h-[44px] p-4  hover:opacity-60 bg-theme-green text-white rounded font-medium text-sm leading-[17.5px] tracking-[0.15px]">New Transfer</button>
            </div>
        </div>
       

        <!-- Selected products -->
        <div class=" px-[24px] max-w-full mb-20">
            <div class="flex flex-col  shadow-xl box-border  text-white  rounded p-4 bg-[#2B2C32] mb-4">
              <div class="flex justify-between  w-full relative mb-9">
                  <svg  class="absolute z-[99] left-[16px] top-[13px]" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 15L10.3333 10.3333M11.8889 6.44444C11.8889 9.45133 9.45133 11.8889 6.44444 11.8889C3.43756 11.8889 1 9.45133 1 6.44444C1 3.43756 3.43756 1 6.44444 1C9.45133 1 11.8889 3.43756 11.8889 6.44444Z" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                  </svg>
                  <input type="text" v-model="search" class="rounded w-full relative pl-10 text-base leading-5 font-medium h-[44px] placeholder-[#9E9E9E] align-top bg-[#464851] border-0" placeholder="Search by Status, Transfer #, Transfer From, Transfer To, ETA, Created By, or Last Modified">
              </div>
              

              <!-- Table list -->
               <List :search="search" :selectedCallback="selectedItem" :clearComponent="clearComponent" :iscomponentactive="action.component ? true : false"/>
            </div>
        </div>
    </div>
</template>
<script>
import {defineComponent, ref} from "vue"
import List from '@/views/Inventory/Transfer/TransferList.vue';
import Helpers from '@/helpers'
import { mapGetters, mapActions } from "vuex";
import { useSnackbar } from "vue3-snackbar";
export default defineComponent({
  name: "Transfer",
  components: {
    List,
  },

  setup() {
    const snackbar = useSnackbar();
    const search = ref('')
    const action = ref({
      component: '',
      data: {},
    });
    return {
      snackbar,
      search,
      action,
    };
  },

   watch: {
    'vg_selectedLocation'(val) {
      // if location selected clear component
      this.clearComponent();
    },
  },

  computed: {
    can_edit_audit() {
        return Helpers.getMyPermission('can_edit_audit')
    },
    ...mapGetters({
     vg_selectedLocation: 'location/selected'
    }),
  },

  mounted() {
    this.emitter.on("reset-product-categories-component", data => {
      this.clearComponent();
    });
  },

  beforeDestroy() {
    this.emitter.off('reset-product-categories-component', () => {});
    this.emitter.off('refresh-product-categories-list', () => {});
  },
 
  methods: {
    newTransfer(){
      this.$router.push({name: 'NewTransfer', query: {locationId: this.vg_selectedLocation.id}})
    },
    /**
     * Clear action
     */
    clearComponent() {
      this.action = {
        component: '',
        data: {},
      };
      let activeItem = document.getElementsByClassName('active-row');
      if(activeItem.length > 0){
        activeItem[0].classList.remove("active-row");
      }
    },

    /**
     * Change add / update component
     */
    addUpdate(val) {
      this.action = val;
    },

    /**
     * Capture selected item
     */
    selectedItem(item) {
      if(this.vg_selectedLocation.id===item.toLocationId){
        if(item.status==='shipped transfer' || item.status==='pending'){
          this.$router.push({name: 'ReceiveTransfer', params: {id: item.id}, query: {locationId: item.fromLocationId, toLocationId: item.toLocationId, status:item.status==='pending'?'incomplete':'complete'}})
        }else{
          this.$router.push({name: 'EditTransfer', params: {id: item.id}, query: {locationId: item.fromLocationId, toLocationId: item.toLocationId, status: 'completed'}})
        }
      }else{
        let status = ''
        if(item.status==='shipped transfer'){
          this.$router.push({name: 'ReceiveTransfer', params: {id: item.id}, query: {locationId: item.fromLocationId, toLocationId: item.toLocationId, status:item.status==='pending'?'incomplete':'complete'}})
        }else if(item.status==='completed transfer'){
          status = 'completed'
          this.$router.push({name: 'EditTransfer', params: {id: item.id}, query: {locationId: item.fromLocationId, toLocationId: item.toLocationId, status: status}})
        }else{
          status = 'pending'
          this.$router.push({name: 'EditTransfer', params: {id: item.id}, query: {locationId: item.fromLocationId, toLocationId: item.toLocationId, status: status}})
        }
      }
    },

    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  },
});

</script>
