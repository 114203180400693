
import { defineComponent, ref } from 'vue'
import {mapGetters, mapMutations} from 'vuex'

export default defineComponent({
    setup() {
   
    },
    mounted(){
      this.vm_active(false)
    },
    computed: {
      ...mapGetters({
        vg_active: 'confirm/active',
        vg_msg: 'confirm/msg',
        vg_extraMsg: 'confirm/extraMsg'
      })
    },
    methods: {
      reject(){
        this.vm_reject()
      },
      accept(){
        this.vm_accept()
      },
      ...mapMutations({
        vm_active: 'confirm/active',
        vm_accept: 'confirm/accept',
        vm_reject: 'confirm/reject'
      })
    }
})
