<template>
<div class="custom-modal-wrap overflow-y-auto  overflow-x-hidden fixed top-0 right-0 left-0 z-[100] md:inset-0 h-modal md:h-full justify-center items-center flex">
    <div class="absolute top-[30px] left-0 flex justify-center items-center  my-0 mx-auto right-0 w-full z-10 text-white flex-1 col-span-1 border-0 rounded-t-xl overflow-hidden">
        <div class="w-[550px] mx-auto mt-10 pt-3 bg-[#464851] px-[16px] py-[16px] rounded-lg">
            <div class="flex justify-center relative pb-5">
                <span class="text-3xl">Product Import</span>
                <a  @click.prevent="close"  href="" class="right-0 top-2 absolute"><mdicon class="cursor-pointer hover:text-slate-200 " name="close"/></a>
            </div>
            <div class="px-[16px] py-[16px] bg-[#2B2C32] rounded-xl">  
                <div v-if="uploadType" class="flex flex-col justify-center">
                    <p  class="text-sm text-center tracking-wider mt-4">Drag &amp; Drop your order bellow or upload it manually to continue</p>
                        <div @dragenter.prevent="dragging=true" @dragover.prevent="dragging=true" @dragleave.prevent="dragging=false" @drop.prevent="addFile" draggable="false">
                            <label for="uploadFile" class="self-center cursor-pointer">
                                <span class="flex justify-center py-9 hover:text-dark-gray" >
                                    <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M34.0013 75.3327V41.9993H0.667969V33.9993H34.0013V0.666016H42.0013V33.9993H75.3346V41.9993H42.0013V75.3327H34.0013Z" fill="#1C1D21"/>
                                    </svg>
                                </span>
                            </label>
                            <input ref="uploadFile" id="uploadFile" class="hidden" type="file" @change="addFile" />
                            <div v-if="file.name" class="flex justify-center mt-4">
                                <p class="text-sm text-center tracking-wider text-white underline">{{file.name}}</p>
                                <a @click.prevent="clearFile" href="" class="ml-4"><mdicon class="cursor-pointer hover:text-white text-theme-red" name="close"/></a>
                            </div>
                            <p v-else class="text-sm text-center tracking-wider text-theme-878686">No file uploaded...</p>
                        </div>
                    <div class="flex justify-center mt-4">
                        <button @click="uploadFile" :disabled="!file" class="rounded-lg bg-theme-green cursor-pointer disabled:bg-black disabled:cursor-default disabled:text-gray-black text-white px-[140px] py-[14px] text-sm tracking-wider font-medium">CONTINUE</button>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</div> 
</template>
<script>
import Helpers from '@/helpers';
import { ref, defineComponent } from 'vue'
import ProductApi from '@/controllers/BusinessProductApis'
import { useSnackbar } from "vue3-snackbar"
import {mapGetters, mapMutations} from 'vuex'

export default defineComponent({
    props: ['payload'],
    setup() {
        const snackbar = useSnackbar();
        let uploadType = ref('ocs')
        let file = ref("")
        return {snackbar, uploadType, file}
    },
    computed: {
        ...mapGetters({
            vg_selectedLocation: 'location/selected',
            vg_selectedBusiness: 'auth/activeBusiness',
        }),
    },
    methods: {
        selected(type){
            this.uploadType = type
        },
        close(){
            this.$emit('close')
        },
        async addFile(e){
            let fileList = e.target.files || e.dataTransfer.files
            if(fileList && fileList[0]){
                this.file = fileList[0]
            }
        },
        async uploadFile(){
            if(this.file){
                this.vm_spinner(true)
                let fileData = new FormData()
                fileData.append('importFile', this.file)
                let res = await ProductApi.importAlbertaCatalogue(fileData)
                this.snackbar.add({
                    type: 'info',
                    text: res.status == 201 ? res.data.payload : Helpers.catchErrorMessage(res.response.data),
                    duration: 3000,
                })
                this.vm_spinner(false)
                this.clearFile()
                if(res.data?.payload && res.data.payload.length>0){
                    this.$emit('uploadedProduct', res.data.payload)
                    this.emitter.emit('reloadProductList')
                }
            }
        },
        readFile(file, callback){
            let reader = new FileReader()
            reader.readAsText(file)
            reader.onload = e => {
                let result = reader.result
                callback(result)
            }
        },
        clearFile(){
            this.file = ""
            this.$refs.uploadFile.value = ''
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }
})
</script>
<style>
.custom-modal-wrap:before {
  content: '';
  position: fixed;
  top: 0;
  width: 0;
  height: 100%;
  width: 100%;
  background: rgb(0 0 0 / 64%);
}
</style>