<template>

    <block-loading v-if="loading" />
    <table-lite
      v-else
      :class="'admin-table'"
      :is-loading="table.isLoading"
      :is-slot-mode='true'
      :columns="columns"
      :rows="table.rows"
      :rowClasses="customRowClass"
      :total="table.totalRecordCount"
      :sortable="table.sortable"
      @is-finished="table.isLoading = false"
      :page-size='table.pagesize'
      @do-search="doSearch"
      :pageOptions='table.pageOptions'
      :has-checkbox="false"
      @row-clicked="selectedRow"
      :checked-return-type="'id'"
      :is-hide-paging="false"
      :pageSizeChangeLabel="'pageSizeChangeLabel'"
      :messages="{
        pagingInfo: 'Total: {2}',
        pageSizeChangeLabel: 'Items per Page',
        gotoPageLabel: '',
        noDataAvailable: 'No records found!',
      }"
    >
    </table-lite>

</template>

<script>
import  { defineComponent, ref} from "vue";
import { mapGetters, mapActions } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import Env from '@/env';
import Helpers from '@/helpers';
import LoyaltyFaqApis from "@/controllers/LoyaltyFaqApis";

export default defineComponent({
  name: "FAQsList",
  components: {
  },
  props: {
    loyaltyProgram: {
      type: Object,
      required: true,
    },
    faqCategory: {
      type: Object,
      required: true,
    },
    iscomponentactive: {
      type: Boolean,
      required: false,
    },
    selectedCallback: {
      type: Function,
      required: true,
    },
    clearComponent: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const snackbar = useSnackbar();
    const activeItem = ref({});
    const loading = ref(false);
    const columnsMain = ref([
      {
        label: "Question",
        field: "question",
        sortable: true,
        width: '50%'
      },
      {
        label: "Answer",
        field: "answer",
        sortable: true,
        width: ''
      },
    ]);

    const table = ref({
      isLoading: false,
      rows: [],
      totalRecordCount: 0,
      count: 0,
      sortable: {
        order: "",
        sort: "",
      },
      pagesize: Env.defaultPageSize,
      pageOptions: Env.pageList,
      page: 1,
    });
 
    return  {
      table,
      columnsMain,
      activeItem,
      loading,
      snackbar,
    };
  },

  async mounted() {
    this.loading = true;
    await this.fetchList(this.table.page);
    this.loading = false;
    this.emitter.on("refresh-faqs-list", data => {
      console.log(this.table.page)
      this.fetchList(this.table.page);
    });
  },

  beforeDestroy() {
    this.emitter.off('refresh-faqs-list', () => {});
  },

  watch: {
   
  },

  computed: {
    ...mapGetters({
      vg_selectedLocation: 'location/selected'
    }),

    /**
     * Compute columns
     */
    columns() {
      // No filter for this
      return this.columnsMain;
    }
   
  },

  methods: {
    /**
     * Fetch list
     */
     async fetchList(page) {
      this.table.isLoading = true;
      let res = await LoyaltyFaqApis.fetchAll({
        program_id: this.loyaltyProgram.id,
        faq_category_id: this.faqCategory.id,
        limit: this.table.pagesize,
        page,
      });
      this.table.isLoading = false;
      this.table.rows = (res.status == 200) ? this._lodash.orderBy(res.data.results, [this.table.sortable.order],[this.table.sortable.sort])  : [];
      this.table.totalRecordCount = (res.status == 200) ? res.data.total_results : 0
    },

    /**
     * Select the item
     */
    setActive(item) {
      // Select unselect the item
      if(this.activeItem.id) {
        let oldactive = document.getElementsByClassName('thisid_'+this.activeItem.id);
        if(oldactive.length > 0){
          oldactive[0].classList.remove("active-row");
        }
      }
      this.activeItem = item;
      let newActive = document.getElementsByClassName('thisid_' + this.activeItem.id);
      if(newActive.length > 0){
        newActive[0].classList.add("active-row");
      }
      this.selectedCallback(item);
    },

    /**
     * Create custom class on tr for active colors
     */
    customRowClass(item) {
      return 'thisid_' + item.id;
    },

    selectedRow(obj){
      this.setActive(obj)
    },
 
    /**
     * Search now
     */
    doSearch(offset, limit, order, sort) {
      this.table.sortable.order = order
      this.table.sortable.sort = sort
      this.table.pagesize = limit;
      let page = (offset/limit)+1
      this.table.page = page;
      this.fetchList(page);
    },

    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })

  }
});
</script>

