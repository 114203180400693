
  import {defineComponent, ref} from "vue"
  import { mapGetters, mapActions } from "vuex";
  import FeaturedCategory from '@/views/Products/Category/FeaturedCategory/Index.vue';
  import RegularCategory from '@/views/Products/Category/RegularCategory/Index.vue';
  import Nav from '@/views/Products/Category/Nav.vue';
  import Helpers from '@/helpers';
  
    export default defineComponent({
    name: "ProductMainCategory",
    components: {
      Nav,
      FeaturedCategory,
      RegularCategory,
    },
  
    setup() {
    
      const activeTab = ref('featured')
      return {
        activeTab
      };
    },
  
     watch: {
     
    },
  
    computed: {
      
    },
  
    mounted() {
     
    },
  
   
    methods: {
      
        changeActiveTab(data) {
        this.activeTab = data;
        },

  
      ...mapActions({
        va_spinner: 'spinner/setSpinner',
      })
    },
  });
  
  