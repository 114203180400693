<template>

    <block-loading v-if="loading" />
    <table-lite
      v-else
      :class="'admin-table'"
      :is-loading="table.isLoading"
      :is-slot-mode='true'
      :columns="columns"
      :rows="table.rows"
      :rowClasses="customRowClass"
      :total="table.totalRecordCount"
      :sortable="table.sortable"
      @is-finished="table.isLoading = false"
      :page-size='table.pagesize'
      @do-search="doSearch"
      :pageOptions='table.pageOptions'
      :has-checkbox="false"
      @row-clicked="selectedRow"
      :checked-return-type="'id'"
      :is-hide-paging="false"
      :pageSizeChangeLabel="'pageSizeChangeLabel'"
      :messages="{
        pagingInfo: 'Total: {2}',
        pageSizeChangeLabel: 'Items per Page',
        gotoPageLabel: '',
        noDataAvailable: 'No records found!',
      }"
    >
      <template v-slot:is_active="data">
        <label class="flex items-center cursor-pointer" @click.prevent="openConfirmStatusModal(data.value)">
          <input type="checkbox" v-model="data.value.is_active" class="hidden peer">
          <div :class="'relative toggle-bg  border-2  h-6 w-11 rounded-full ' + (data.value.is_active ? 'bg-theme-green border-theme-green' : 'bg-[#F53333] border-[#F53333]')"></div>
          <span class="ml-2 text-white text-sm font-medium uppercase" :class="data.value.is_active ? 'text-theme-green' : 'text-[#F53333]'">{{data.value.is_active ? 'ON' : 'OFF'}}</span>
        </label>
      </template>
       <template v-slot:action_name="data">
        <a href="#" @click.prevent="setActive(data.value)">{{ data.value.action_name }}</a> 
      </template>
      <template v-slot:start_date="data">
        {{ makereadAbleDate(data.value.start_date) }}
      </template>
      <template v-slot:end_date="data">
        {{ makereadAbleDate(data.value.end_date) }}
      </template>
    </table-lite>

    <ReturnConfirmModal v-if="confirmStatusObj.modal" :obj="confirmStatusObj.obj" :closeClick="confirmStatusClose" :actionClick="toggleActiveStatus"  :heading="'You Are About To Update ' + confirmStatusObj.obj.action_name "/>
</template>

<script>
import  { defineComponent , ref} from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import Env from '@/env';
import Helpers from '@/helpers';
import LoyaltyProgramActionApis from "@/controllers/LoyaltyProgramActionApis";
import moment from 'moment';
import ReturnConfirmModal from "@/components/general/dialog/ReturnConfirmModal.vue";

export default defineComponent({
  name: "EarningPointsList",
  components: {
    ReturnConfirmModal
  },
  props: {
    loyaltyProgram: {
      type: Object,
      required: true,
    },
    iscomponentactive: {
      type: Boolean,
      required: false,
    },
    selectedCallback: {
      type: Function,
      required: true,
    },
    clearComponent: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const snackbar = useSnackbar();
    const activeItem = ref({});
    const loading = ref(false);
    const columnsMain = ref([
      {
        label: "Name",
        field: "action_name",
        sortable: true,
      },
      {
        label: "Point Value",
        field: "earn_value",
        sortable: true,
        width: ''
      },
      {
        label: "Starts",
        field: "start_date",
        sortable: true,
        width: ''
      },
      {
        label: "Ends",
        field: "end_date",
        sortable: true,
        width: ''
      },
      {
        label: "Status",
        field: "is_active",
        sortable: false,
        width: ''
      },
    ]);

    const table = ref({
      isLoading: false,
      rows: [],
      totalRecordCount: 0,
      count: 0,
      sortable: {
        order: "",
        sort: "",
      },
      pagesize: Env.defaultPageSize,
      pageOptions: Env.pageList,
      page: 1,
    });
    const confirmStatusObj = ref({
      modal: false,
      obj: {
        status: Boolean,
        id: Number,
      },
    });
 
    return  {
      table,
      columnsMain,
      activeItem,
      loading,
      snackbar,
      confirmStatusObj
    };
  },

  async mounted() {
    this.loading = true;
    await this.fetchList(this.table.page);
    this.loading = false;
    this.emitter.on("refresh-earningpoints-list", data => {
      this.fetchList(this.table.page);
    });
  },

  beforeDestroy() {
    this.emitter.off('refresh-earningpoints-list', () => {});
  },

  watch: {
   
  },

  computed: {
    ...mapGetters({
      vg_selectedLocation: 'location/selected'
    }),

    /**
     * Compute columns
     */
    columns() {
      if(this.iscomponentactive){
        let removeFields= ['is_active', 'start_date', 'end_date'];
        return this.columnsMain.filter(item => {
            if( removeFields.indexOf(item.field) == -1 ) {
              return item;
            }
        })
      }
      return this.columnsMain;
    },

    /**
     * Edit restriction
     */
     can_edit_customer_details_in_admin() {
      return Helpers.getMyPermission('can_edit_customer_details_in_admin')
    },
   
  },

  methods: {
    /**
     * Fetch list
     */
     async fetchList(page) {
      this.table.isLoading = true;
      let res = await LoyaltyProgramActionApis.fetchAll({
        program_id: this.loyaltyProgram.id,
        limit: this.table.pagesize,
        page,
      });
      this.table.isLoading = false;
      this.table.rows = (res.status == 200) ? this._lodash.orderBy(res.data.results, [this.table.sortable.order],[this.table.sortable.sort])  : [];
      this.table.totalRecordCount = (res.status == 200) ? res.data.total_results : 0
    },

    /**
     * Select the item
     */
    setActive(item) {
      // Select unselect the item
      if(this.activeItem.id) {
        let oldactive = document.getElementsByClassName('thisid_'+this.activeItem.id);
        if(oldactive.length > 0){
          oldactive[0].classList.remove("active-row");
        }
      }
      this.activeItem = item;
      let newActive = document.getElementsByClassName('thisid_' + this.activeItem.id);
      if(newActive.length > 0){
        newActive[0].classList.add("active-row");
      }
      this.selectedCallback(item);
    },

    /**
     * Create custom class on tr for active colors
     */
    customRowClass(item) {
      return 'thisid_' + item.id;
    },

    selectedRow(obj){
      //this.setActive(obj)
    },
 
    /**
     * Search now
     */
    doSearch(offset, limit, order, sort) {
      this.table.sortable.order = order
      this.table.sortable.sort = sort
      this.table.pagesize = limit;
      let page = (offset/limit)+1
      this.fetchList(page);
    },

    /**
     * Read able date format
     */
    makereadAbleDate(dateStr) {
      return dateStr ? moment.parseZone(dateStr).format('YYYY-MM-DD') : '-';
    },

    /**
     * Close Confirm status modal
     */
    confirmStatusClose() {
      this.confirmStatusObj = {
        modal: false,
        obj: {
          status: Boolean,
          id: Number,
        },
      };
    },

    /**
     * Open confirm satus modal
     */
    openConfirmStatusModal(obj) {
      if(this.can_edit_customer_details_in_admin){
        this.confirmStatusObj = {
          modal: true,
          obj,
        };
      }
    },

     /**
     * Toggle  status
     */
    async toggleActiveStatus() {
      this.va_spinner(true);
      let res = await LoyaltyProgramActionApis.update({
        program_id: this.loyaltyProgram.id, 
        action_id:  this.confirmStatusObj.obj.id, 
        data: { is_active: !this.confirmStatusObj.obj.is_active }
      });
      this.va_spinner(false);
        this.snackbar.add({
        type: 'info',
        text: res.status == 200 ? res.data.msg : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
      if(res.status == 200 ) {
        this.confirmStatusClose();
        this.fetchList(this.table.page);
        this.clearComponent();
      }
    },
    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })

  }
});
</script>

