<template>
    <div class="rounded bg-light-black">
        <block-loading v-if="loading" />

        <Form @submit="submit" v-else :validation-schema="schema">
            <div class="rounded-tl rounded-tr header h-20 bg-secondary px-2">
                <div class="flex flex-row justify-between h-full">
                    <div class="title self-center truncate text-single-line break-all font-bold ml-2">{{editItem.name }}</div>
                    <div class="actions self-center flex ">

                        <button class="  tracking-widest cursor-pointer bg-white py-2 px-4 rounded text-theme-green hover:bg-theme-darkgreen hover:text-white mr-3">
                            SAVE
                        </button>
                        <button @click.prevent="remove()" class="cursor-pointer rounded-md bg-white text-theme-red py-2 px-4  hover:bg-theme-darkgreen hover:text-white mr-3">REVOKE ACCESS</button>
                        <span @click.prevent="close" class="cursor-pointer rounded-md  text-white py-2 mr-2 hover:opacity-70">CLOSE</span>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-2 py-2">
                <label class="block p-2 w-full">
                    <Field v-slot="{field, errors, errorMessage}" name="name" v-model="form.name" >
                        <div :class="labelClass(field.value, errorMessage)">Name:</div>
                        <input class="block w-full rounded bg-gray-black" :class="inputClass(field.value, errorMessage)" v-bind="field" type="text" />
                        <div v-if="errors.length>0" class="text-sm"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                    </Field>
                </label>
                <label class="block p-2 w-full">
                    <Field  v-slot="{field, errors, errorMessage}" name="email" v-model="form.email" >
                        <div :class="labelClass(field.value, errorMessage)">Email:</div>
                        <input disabled class="block w-full rounded bg-gray-black" :class="inputClass(field.value, errorMessage)" v-bind="field" type="text" />
                        <div v-if="errors.length>0" class="text-sm" :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                    </Field>
                </label>
                <label class="block p-2 w-full">
                    <Field  v-slot="{field, errors, errorMessage}" name="phone" v-model="form.phone"  >
                        <div :class="labelClass(field.value, errorMessage)">Phone:</div>
                        <input class="block w-full rounded bg-gray-black" :class="inputClass(field.value, errorMessage)" v-model="form.phone" @input="acceptNumber" type="text" />
                        <div v-if="errors.length>0" class="text-sm"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                    </Field>
                </label>
                <label class="block p-2 w-full">
            
                    <Field v-slot="{field, errors, errorMessage}" name="pin" v-model="form.pin" >
                        <div class="relative">
                            <div :class="labelClass(field.value, errorMessage)">Pin:</div>
                            <div class="relative">
                                <input :disabled="!canManagePin" class="inline-block w-full rounded bg-gray-black" :class="inputClass(field.value, errorMessage)" v-bind="field" :type="pinVisibility?'number':'password'" />
                                <mdicon class="cursor-pointer inline-block absolute z-40 right-[10px] top-[9px]" :name="pinVisibility ? 'eye' : 'eye-off'" @click.prevent="toggleVisibility" />
                            </div>
                            <div v-if="errors.length>0" class="text-sm"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        </div>
                    </Field>
                </label>
                <label class="block p-2 w-full">
                    <Field  v-slot="{field, errors, errorMessage}" name="role" v-model="form.role" >
                        <div :class="labelClass(field.value, errorMessage)">Role:</div>
                        <select :disabled="!can_assign_employee_role" class=" w-full block rounded bg-gray-black" :class="inputClass(field.value, errorMessage)" v-bind="field" >
                            <option v-for="role in roles" :key="role.id" :value="role.id">{{role.role}}</option>
                        </select>
                        <div v-if="errors.length>0" class="text-sm"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                    </Field>
                </label>
                <label class="block p-2 w-full">
                    <div>Locations</div>
                    <select-checkbox mode="tags" class="bg-gray-black border border-gray-500" v-model="form.location" :options="options" >
                        <template v-slot:placeholderIcon >
                            <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.75 18.025C10.9667 16.0083 12.6042 14.1792 13.6625 12.5375C14.7208 10.8958 15.25 9.45 15.25 8.2C15.25 6.23333 14.6208 4.625 13.3625 3.375C12.1042 2.125 10.5667 1.5 8.75 1.5C6.93333 1.5 5.39583 2.125 4.1375 3.375C2.87917 4.625 2.25 6.23333 2.25 8.2C2.25 9.45 2.79167 10.8958 3.875 12.5375C4.95833 14.1792 6.58333 16.0083 8.75 18.025ZM8.75 20C6.06667 17.7167 4.0625 15.5958 2.7375 13.6375C1.4125 11.6792 0.75 9.86667 0.75 8.2C0.75 5.7 1.55417 3.70833 3.1625 2.225C4.77083 0.741667 6.63333 0 8.75 0C10.8667 0 12.7292 0.741667 14.3375 2.225C15.9458 3.70833 16.75 5.7 16.75 8.2C16.75 9.86667 16.0875 11.6792 14.7625 13.6375C13.4375 15.5958 11.4333 17.7167 8.75 20Z" fill="white"/>
                            </svg>
                        </template>
                    </select-checkbox>
                </label>
                <label class="block p-2 w-full"  v-if="vg_isUserTypeSuper">
                    <Field  v-slot="{field, errors, errorMessage}" name="userType" v-model="form.userType" >
                        <div :class="labelClass(field.value, errorMessage)">User Type:</div>
                        <select class=" w-full block rounded bg-gray-black" :class="inputClass(field.value, errorMessage)" v-bind="field" >
                            <option value="super">Super</option>
                            <option value="client">Client</option>
                        </select>
                        <div v-if="errors.length>0" class="text-sm"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                    </Field>
                </label>
            </div>
        </Form>
    </div>
</template>

<script>
import {ref, defineComponent} from "vue"
import { Form, Field, ErrorMessage } from 'vee-validate';
import {mapGetters, mapMutations} from 'vuex'
import EmployeeApi from "@/controllers/EmployeeApis";
import pick from 'lodash/pick'
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';
import ObjectHelper from '@/helpers/object'
import SelectCheckbox from '@/components/form/SelectCheckbox.vue'

export default defineComponent({
    props: ['editItem'],
    components: {
        Form,
        Field,
        ErrorMessage,
        SelectCheckbox
    },
    setup(){
        const snackbar = useSnackbar();
        const roles = ref([])
        const loading = ref(false);
        const form = ref({
            name: '',
            email: '',
            phone: '',
            userType: '',
            pin: '',
            location: [],
            role: ''
        })
        const schema = {
            name: 'required',
            email: 'required|email',
            phone: 'phone',
            pin: 'pin',
            userType: 'required'
        }
        const pinVisibility = ref(true)
        
        return {snackbar, loading, roles, form, schema, pinVisibility}
    },
    async mounted(){
        this.fetchAndAssign({ triggerRole: true });
    },
    watch: {
        editItem(editItem){
            this.fetchAndAssign({ triggerRole: false });
        }
    },
    computed: {

        /**
         * Manage employee pin
         */
        canManagePin() {
            // if user edited user and logged user is same check for his permission
            return (this.editItem.id == this.vg_authUser.id) ? this.can_manage_pin_for_self : this.can_manage_pin_for_users;
        },

        /**
         * Manage change role
         */
         can_assign_employee_role() {
            // if user edited user and logged user is same check for his permission
            return Helpers.getMyPermission('can_assign_employee_role')
        },

        /**
         * Self pin access
         */
        can_manage_pin_for_self() {
            return Helpers.getMyPermission('can_manage_pin_for_self')
        },
        /**
         * all Pin access
         */
        can_manage_pin_for_users() {
            return Helpers.getMyPermission('can_manage_pin_for_users')
        },
        
        options() {
            return this.vg_location.map(each => ({id:each.id, name:each.name}))
        },
        ...mapGetters({
            vg_location: 'location/list',
            vg_isUserTypeSuper: 'auth/isUserTypeSuper',
            vg_authUser: 'auth/user'
        })
    },
    methods: {
        
        /**
         * Fetch and assign update datas
         */
        async fetchAndAssign({ triggerRole }) {
            this.loading = true;
            let rolesRes = triggerRole ? this.listRole() : null; // only trigger this api for first time
            let locationRes = EmployeeApi.getUserLocations(this.editItem.id);
            let userPinRes = this.canManagePin ? EmployeeApi.getUserPinByUserId(this.editItem.id) : null; // hit only if has permission


            Promise.all([rolesRes, locationRes, userPinRes]).then((res) => {
                this.form = pick(this.editItem, ['name', 'email', 'phone', 'userType', 'pin'])
                if(this.editItem?.roles[0]){
                    this.form.role = this.getRoleId(this.editItem.roles[0])
                }
                if(res[1].data && res[1].data.payload){
                    this.form.location = res[1].data.payload.map(each => each.id)
                }
                if(res[2] && res[2].data && res[2].data.payload){
                    this.form.pin = res[2].data.payload.pin.toString()
                }else{
                    this.form.pin = ''
                }

                this.loading = false;
            });
        },

        async listRole(){
            let {data: {payload}} = await EmployeeApi.listRoles()
            this.roles = payload
        },

        getRoleId(value){
            let role =  this.roles.find(each => each.role==value)
            if(role){
                return role.id
            }
        },
        
        labelClass(value, error){
            if(value && error){
                return 'text-theme-red'
            }else if(error){
                return 'text-theme-orange'
            }else if(value){
                return 'text-theme-green'
            }
        },
        inputClass(value, error){
            if(value && error){
                return 'border-theme-red'
            }else if(error){
                return 'border-theme-orange'
            }else if(value){
                return 'border-theme-green'
            }
        },
        errorClass(value, error){
            if(value && error){
                return 'text-theme-red'
            }else if(error){
                return 'text-theme-orange'
            }
        },
        toggleVisibility(){
            this.pinVisibility = !this.pinVisibility
        },

        acceptNumber() {
            var x = this.form.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.form.phone = !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        async submit(form, { resetForm }){
            this.vm_spinner(true)
            // ObjectHelper.removeEmptyString(this.form)
            this.form = Helpers.setEmptyValueToNull(this.form);
            this.form.pin = this.form.pin?this.form.pin.toString():null
            let res = await EmployeeApi.editUser(this.editItem.id, this.form)
            let res1 = null
            let res2 = null
            let err = null
            if(res.data && res.data.payload && res.data.payload.id){
                res1 = await EmployeeApi.addLocationsToUser(res.data.payload.id, {locationIds: this.form.location})
                if(this.form.role){
                    res2 = await EmployeeApi.assignRoleToUser(res.data.payload.id, {roleId: this.form.role})
                }
            }
            if(res1 && res1.status!==201){
                err = res1
            }else if(res2 && res2.status!==201){
                err = res2
            }else{
                err = res
            }
            this.vm_spinner(false)
            this.form.location = []
            //resetForm()
            this.snackbar.add({
                type: 'info',
                text: res.status == 200 ? res.data.message : Helpers.catchErrorMessage(err.response.data),
                duration: 3000,
            })
            this.$emit('edited')
        },
        async remove(){
            this.confirm("Are you sure to delete this user?").then(async () => {
                let self = this
                self.vm_spinner(true)
                let res = await EmployeeApi.deleteUser(self.editItem.id)
                self.vm_spinner(false)
                self.snackbar.add({
                    type: 'info',
                    text: (res.data?.message)?res.data.message:Helpers.catchErrorMessage(res.response.data),
                    duration: 3000,
                })
                self.close()
                self.$emit('edited')
            })
        },
        close(){
            this.$emit('close')
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }

})
</script>