/**
 * @module business
 *  Apis must need auth details to trigger url - AuthApi
 */

import Env from '@/env'
const apiVersion = 'api'
import AuthApis from '@/controllers/AuthApis'
import ApiHelper from '@/controllers/ApiHelpers'
import store from '@/store/index';

export default {
  /**
   * Get all location intregations
   */
  fetchAll(locationId, type='clover') {
    const url = `${Env.loginUrl}/${apiVersion}/location-integrations/all?integrator=${type}`
    return AuthApis.getApi(url, {
      'Authorization': 'Bearer ' + store.getters['auth/userToken'],
      apiToken: Env.loginApiToken,
    })
      .then((res) => res)
      .catch((err) => {
        // ApiHelper.detectStatusAndRedirect(err)
        return err
      })
  },

  /**
   * Create location intregations
   */
  create(data) {
    const url = `${Env.loginUrl}/${apiVersion}/location-integrations`
    return AuthApis.postApi(url, null, data)
      .then((res) => res)
      .catch((err) => {
        ApiHelper.detectStatusAndRedirect(err)
        return err
      })
  },

  /**
   * CreateMany location intregations
   */
  createMany(data) {
    const url = `${Env.loginUrl}/${apiVersion}/location-integrations/create-many`
    return AuthApis.postApi(url, null, data)
      .then((res) => res)
      .catch((err) => {
        ApiHelper.detectStatusAndRedirect(err)
        return err
      })
  },

  /**
   * Update location intregations
   */
  update(id, data) {
    const url = `${Env.loginUrl}/${apiVersion}/location-integrations/${id}`
    return AuthApis.patchApi(url, null, data)
      .then((res) => res)
      .catch((err) => {
        ApiHelper.detectStatusAndRedirect(err)
        return err
      })
  },

  /**
   * Delete location intregations
   */
  delete(id) {
    const url = `${Env.loginUrl}/${apiVersion}/location-integrations/${id}`
    return AuthApis.deleteApi(url, null)
      .then((res) => res)
      .catch((err) => {
        ApiHelper.detectStatusAndRedirect(err)
        return err
      })
  },

  /**
   * Delete clover from location
   */
  deleteClover() {
    const url = `${Env.loginUrl}/${apiVersion}/location-integrations/integration-provider/clover/remove`
    return AuthApis.deleteApi(url, null)
      .then((res) => res)
      .catch((err) => {
        ApiHelper.detectStatusAndRedirect(err)
        return err
      })
  },

  /**
   * Get location payment type
   */
  fetchPaymentProvider() {
    const url = `${Env.loginUrl}/${apiVersion}/location-payment-provider/show`
    return AuthApis.getApi(url, {
      'Authorization': 'Bearer ' + store.getters['auth/userToken'],
      'apiToken': Env.loginApiToken
    })
      .then((res) => res)
      .catch((err) => {
        return err
      })
  },

  updatePaymentProvider(data){
    const url = `${Env.loginUrl}/${apiVersion}/location-payment-provider/upsert`
    return AuthApis.postApi(url, null, data)
      .then((res) => res)
      .catch((err) => {
        ApiHelper.detectStatusAndRedirect(err)
        return err
      })
  },

  sync(locationId){
    const url = `${Env.productUrl}/${apiVersion}/leafly/${locationId}`
    return AuthApis.postApi(url, {
      'Authorization': 'Bearer ' + store.getters['auth/userToken'],
      'api_token': Env.productApiToken,
    }, '')
      .then((res) => res)
      .catch((err) => {
        ApiHelper.detectStatusAndRedirect(err)
        return err
      })
  }
}
