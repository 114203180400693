<template>
<div class="text-white flex-1 col-span-1 border-0 rounded-t-xl">
  <div class="bg-theme-green px-4 py-2 text-white">
    <div class="flex justify-between items-center">
      <h2 class="font-bold break-all leading-tight text-2xl mt-0 mr-2">{{ form.action_name.value ? form.action_name.value : 'New Action'}}</h2>
      <div class="flex items-end">
         <button v-if="can_edit_membership_details" @click.prevent="update()" class="  tracking-widest cursor-pointer bg-white py-2 px-4 rounded text-theme-green hover:bg-theme-darkgreen hover:text-white mr-3">
            SAVE
          </button>
          <button v-if="can_edit_membership_details" @click.prevent="deleteRecordConfirm()" class="cursor-pointer rounded-md bg-white text-theme-red py-2 px-4  hover:bg-theme-darkgreen hover:text-white mr-3">DELETE</button>
          <span @click.prevent="clearComponent" class="cursor-pointer rounded-md  text-white py-2 pl-1  hover:opacity-70">CLOSE</span>
          <!-- <mdicon @click.prevent="clearComponent" size="32" name="close" class="cursor-pointer hover:opacity-60 mb-2"/>
        <div class="flex justify-end">
          <button @click.prevent="update()" class="text-sm font-medium tracking-widest cursor-pointer bg-white py-2 px-4 rounded text-theme-green hover:bg-theme-darkgreen hover:text-white mr-3">
            <span>SAVE</span>
          </button>
          <mdicon @click.prevent="deleteRecordConfirm()" class="bg-white hover:bg-theme-red hover:text-white text-red-500 rounded cursor-pointer px-2 py-2" name="trash-can-outline" />
        </div> -->
      </div>
    </div>
  </div>


  <div class="bg-[#2B2C32] px-[16px] py-[12px] rounded-b-lg">
    
    <block-loading v-if="loading" />

    <template v-else>
      <div class="flex items-center xl:flex-row flex-col gap-2 my-2">   
        <div class="w-full xl:w-1/2">
            <div class="input-wrapper flex flex-col">
              <label class="text-xs tracking-[0.4px] mt-1.5">Action Name</label>
              <input placeholder="Name" @keyup="validateFields(['action_name'])" v-model="form.action_name.value" class="block tracking-wider border-none rounded placeholder-theme-878686 bg-gray-black w-full text-sm" type="text">  
            </div>
        </div>
        <div class="w-full xl:w-1/2">
            <div class="input-wrapper flex flex-col">
                <label class="text-xs tracking-[0.4px] mt-1.5">Action Type</label>
                <select disabled @change="validateFields(['action_key'])" v-model="form.action_key.value" name="action-type" class="block tracking-wider border-none rounded bg-gray-black w-full text-white text-sm">
                  <option disabled value="">Select</option>
                  <option v-bind:key="'actionTyoe'+action.value" :value="action.value" v-for="action in actionType">{{ action.text }}</option>
                </select>
            </div>
        </div>
      </div>

      <div class="flex items-center xl:flex-row flex-col gap-2 my-2">   
        <div class="w-full">
            <div :class="'input-wrapper relative ' + (form.desc.error_type)">
              <label class="text-xs tracking-[0.4px] mt-1.5">Description</label>
              <textarea placeholder="Name" @keyup="validateFields(['desc'])" v-model="form.desc.value" class="block tracking-wider border-none rounded placeholder-theme-878686 bg-gray-black w-full text-sm"></textarea>  
              <p class="field-error label-text mt-1" v-if="form.desc.error_message" v-text="form.desc.error_message"></p>
            </div>
        </div>
      </div>

      <div class="flex items-center xl:flex-row flex-col gap-2 my-2">   
        <div class="w-full xl:w-1/2">
          <div :class="'input-wrapper relative ' + (form.start_date.error_type)">
            <label class="text-xs tracking-[0.4px]">Start Date</label>
            <div class="top-[30px] left-2 z-10 absolute">
              <mdicon size="24" name="calendar-month-outline" class=""/>
            </div>
            <Datepicker :lowerLimit="new Date()" :inputFormat='"yyyy-MM-dd"' v-model="form.start_date.value" class="block tracking-wider border-none rounded pl-12  bg-gray-black w-full text-sm placeholder-theme-878686" placeholder="Select a Date"  />
            <p class="field-error label-text mt-1" v-if="form.start_date.error_message" v-text="form.start_date.error_message"></p>
          </div>
        </div>
        <div class="w-full xl:w-1/2">
          <div :class="'input-wrapper relative ' + (form.end_date.error_type)">
            <label class="text-xs tracking-[0.4px]">End Date</label>
            <div class="top-[30px] left-2 z-10 absolute">
              <mdicon size="24" name="calendar-month-outline" class=""/>
            </div>
            <Datepicker :lowerLimit="endDateLimit" :inputFormat='"yyyy-MM-dd"' v-model="form.end_date.value" class="block tracking-wider border-none rounded pl-12  bg-gray-black w-full text-sm placeholder-theme-878686" placeholder="Select a Date"  /> 
            <p class="field-error label-text mt-1" v-if="form.end_date.error_message" v-text="form.end_date.error_message"></p>
          </div>
        </div>
      </div>
      <div class="flex items-center xl:flex-row flex-col gap-2 my-2">   
        <div class="w-full xl:w-1/2">
          <label class="text-xs tracking-[0.4px]">Is Active</label>
            <label class="flex items-center cursor-pointer" @click.prevent="form.is_active = !form.is_active">
              <input type="checkbox" v-model="form.is_active" class="hidden peer">
              <div :class="'relative toggle-bg  border-2  h-6 w-11 rounded-full ' + (form.is_active ? 'bg-theme-green border-theme-green' : 'bg-[#F53333] border-[#F53333]')"></div>
              <span class="ml-2 text-white text-sm font-medium uppercase" :class="form.is_active ? 'text-theme-green' : 'text-[#F53333]'">{{form.is_active ? 'ON' : 'OFF'}}</span>
            </label>
        </div>
        <div class="w-full xl:w-1/2">
          <div :class="'input-wrapper ' + (form.earn_value.error_type)">
            <label class="text-xs tracking-[0.4px]">Point Value</label>
            <div class="relative">
              <input placeholder="earn_value" @keyup="validateFields(['earn_value'])"  v-model="form.earn_value.value" class="block tracking-wider border-none pr-[100px] rounded placeholder-theme-878686 bg-gray-black w-full text-sm" type="number">
              <span class="absolute bottom-1 right-2 text-base font-bold tracking-wider border border-theme-green rounded text-theme-878686 py-[1px] w-[110px] text-center px-[3px] truncate">$ {{ pointToCash }}</span>
            </div>
            <p class="field-error label-text mt-1" v-if="form.earn_value.error_message" v-text="form.earn_value.error_message"></p>
          </div>
        </div>
      </div>


    <!-- extra 2-->
      <div class="flex items-center xl:flex-row flex-col gap-2 my-2" v-if="appStore.includes(form.action_key.value)">   
        <div class="w-full xl:w-1/2">
          <div :class="'input-wrapper ' + (form.max_per_user.error_type)">
            <label class="text-xs tracking-[0.4px] mt-1.5">Max Per User</label>
            <input placeholder="Max Per User" @keyup="validateFields(['max_per_user'])" :onkeypress="preventNonNumbersAndDecimal" v-model="form.max_per_user.value" class="block tracking-wider border-none rounded placeholder-theme-878686 bg-gray-black w-full text-sm" type="number">  
            <p class="field-error label-text mt-1" v-if="form.max_per_user.error_message" v-text="form.max_per_user.error_message"></p>
          </div>
        </div>
        <div class="w-full xl:w-1/2">
          <div :class="'input-wrapper ' + (form.daily_max_per_user.error_type)">
            <label class="text-xs tracking-[0.4px] mt-1.5">Daily Max Per User</label>
            <input placeholder="Max Per User" @keyup="validateFields(['daily_max_per_user'])"  :onkeypress="preventNonNumbersAndDecimal" v-model="form.daily_max_per_user.value" class="block tracking-wider border-none rounded placeholder-theme-878686 bg-gray-black w-full text-sm" type="number">  
            <p class="field-error label-text mt-1" v-if="form.daily_max_per_user.error_message" v-text="form.daily_max_per_user.error_message"></p>
          </div>
        </div>
      </div>


     <!-- extra 1-->
      <div class="flex items-center xl:flex-row flex-col gap-2 my-2" v-if="form.action_key.value === 'app_store_checkin_bonus'">   
        <div class="w-full xl:w-1/2">
          <div :class="'input-wrapper ' + (form.session_limit_minutes.error_type)">
            <label class="text-xs tracking-[0.4px] mt-1.5">Session Limit in Minutes</label>
            <input placeholder="Session Time" @keyup="validateFields(['session_limit_minutes'])" :onkeypress="preventNonNumbersAndDecimal" v-model="form.session_limit_minutes.value" class="block tracking-wider border-none rounded placeholder-theme-878686 bg-gray-black w-full text-sm" type="number">  
            <p class="field-error label-text mt-1" v-if="form.session_limit_minutes.error_message" v-text="form.session_limit_minutes.error_message"></p>
          </div>
        </div>
        <div class="w-full xl:w-1/2">
        </div>
      </div>

    </template>

  </div>
</div>
<ReturnConfirmModal v-if="deleteObj.modal" :obj="deleteObj.obj" :closeClick="closeDeleteModal" :actionClick="deleteNow" :heading="'You Are About To Delete ' + deleteObj.obj.action_name "/>
</template>


<script>
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';
import Datepicker from 'vue3-datepicker'
import moment from 'moment';
import LoyaltyProgramActionApis from "@/controllers/LoyaltyProgramActionApis";
import ReturnConfirmModal from "@/components/general/dialog/ReturnConfirmModal.vue";

export default defineComponent({
  name: "MembershipProgramEarningPointsEdit",
  components: {
    Datepicker,
    ReturnConfirmModal
  },
  props:{
    clearComponent: {
      type: Function,
      required: true,
    },
     redeemingSetting: {
      type: Object,
      required: true,
    },
    loyaltyProgram: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const snackbar = useSnackbar();
    const form = ref({

      action_name: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      action_key: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      desc: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      start_date: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      end_date: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
     // auto_apply: true,
      is_active: false,
     // action_trigger: null,
    //  action_condition: {},
      max_per_user: {
        value: 0,
        error_type: '',
        error_message: '',
        validations: ['required', 'number', 'maxval:9999999'],
      },
      daily_max_per_user: {
        value: 0,
        error_type: '',
        error_message: '',
        validations: ['required', 'number', 'maxval:9999999'],
      },
      earn_type: 'fixed',
      earn_value: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'number', 'maxval:99999'],
      },
      image_url: '',
      session_limit_minutes: {
        value: 60,
        error_type: '',
        error_message: '',
        validations: ['required', 'number', 'maxval:99999'],
      }
    });

    const actionType  = ref([
        { text: 'Sign Up', value: 'signup' },
        { text: 'Checkout', value: 'checkout' },
        { text: 'Birthday', value: 'birthday' },
        { text: 'Referral', value: 'referral' },
        { text: 'Check-in', value: 'app_store_checkin_bonus' },
        { text: 'Delivery Order', value: 'app_store_checkout_delivery_bonus' },
        { text: 'Pickup Order', value: 'app_store_checkout_pickup_bonus' },
        { text: 'Instore Order', value: 'instore_order' },
        // { text: 'Pickup Bonus', value: 'app_store_checkout_takeout_bonus' },
    ]);

    const appStore = ref([
      'app_store_checkout_takeout_bonus',
      'app_store_checkin_bonus',
      'app_store_checkout_delivery_bonus',
      'app_store_checkout_pickup_bonus',
      'checkout',
      'referral',
      'instore_order'
    ]);

    const actionTrigger = ref([
      { text: 'App', value: 'app' },
      { text: 'Instore order', value: 'instore_order' },
      { text: 'Check In', value: 'checkin' },
      { text: 'Checkout', value: 'checkout' },
      { text: 'Ecommerce order', value: 'ecommerce_order' },
      { text: 'Transaction count', value: 'transaction_count' }
    ]);
    const loading = ref(false);
    const earnType = ref(['fixed', 'incremental', 'percentage']);
    const deleteObj = ref({
      modal: false,
      obj: {},
    });
    return {
      form,
      snackbar,
      actionType,
      appStore,
      loading,
      deleteObj
    };
  },

  watch:{
   
    // Do not let add more than 5 numbers count
    'form.earn_value.value'(val, old) {
      if(val.toString().length > 7){
        this.form.earn_value.value = old;
      }
    },

    'form.start_date.value'() {
      this.validateFields(['start_date']);
      if(this.form.end_date.value != '') {
        // then need to check
        let endDate = moment(this.form.end_date.value);
        let startDate = moment(this.form.start_date.value);
        let dayGap = endDate.diff(startDate, 'day');
        if(dayGap < 0) {
          this.snackbar.add({
            type: 'info',
            text: 'End date must be equal or greater than start date.',
            duration: 3000,
          })
          this.form.end_date.value = '';
        }
      }
    },
    'form.end_date.value'() {
      this.validateFields(['end_date']);
    },
    'item.id'() {
      this.assignValues();
    },
     'form.is_active'(val) {
      //console.log(val)
    },

  },

  computed: {
    
    pointToCash() {
      if(this.form.earn_value?.value && this.redeemingSetting?.points_for_dollar) {
        return (Math.round(this.form.earn_value.value / this.redeemingSetting.points_for_dollar * 100) / 100).toFixed(2)
      }
      return 0.00.toFixed(2);
    },
    
    endDateLimit() {
      return this.form.start_date?.value ?  moment(this.form.start_date.value).toDate() : new Date();
    },

    ...mapGetters({
      vg_selectedBusiness: 'auth/activeBusiness',
    }),

    /**
     * Edit restriction
     */
    can_edit_membership_details() {
      return Helpers.getMyPermission('can_edit_membership_details')
    },
  },

  mounted() {
    this.assignValues();
  },
 
  methods: {
    /**
     * Assign values
     */
    async assignValues() {
      
      this.loading = true;
      this.form = await Helpers.assignFormValues(this.form, this.item);
     
      
      this.$nextTick(() => {
        let startDateOnly = this.item.start_date ? this.item.start_date.split('T')[0] : '';
        let endDateOnly = this.item.end_date ? this.item.end_date.split('T')[0] : '';
        this.form.start_date.value = this.item.start_date ? moment(startDateOnly).toDate() : '';
        this.form.end_date.value = this.item.end_date ? moment(endDateOnly).toDate(): '';
        this.form.is_active = this.item.is_active
        this.loading = false;
      })
    },
     /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },

    /**
     * Save
     */
    async update() {
      // validate business field
      let validate = await this.validateFields(['action_name', 'action_key', 'earn_value', 'start_date', 'end_date']);
      if(!validate) {
        return ;
      }
      
      let data = Helpers.getFormKeyValue(this.form);
      if (this.appStore.includes(data.action_key)) {
          data.max_per_user = parseInt(data.max_per_user)
          data.daily_max_per_user = parseInt(data.daily_max_per_user)
          data.session_limit_minutes = parseInt(data.session_limit_minutes)
          if (data.action_key === 'app_store_checkin_bonus') {
            data.action_trigger = 'checkin'
          } else if (data.action_key === 'referral') {
            data.action_trigger = 'referral'
          } else if (data.action_key === 'instore_order') {
            data.action_trigger = 'checkout_instore'
          } else if (data.action_key === 'app_store_checkout_delivery_bonus') {
            data.action_trigger = 'checkout_delivery'
          } else if (data.action_key === 'app_store_checkout_pickup_bonus') {
            data.action_trigger = 'checkout_pickup'
          } else {
            data.action_trigger = 'checkout'
          }
      } else {
        data.action_trigger = null;
      }
      if(!data.max_per_user) data.max_per_user = 0
      if(!data.daily_max_per_user) data.daily_max_per_user = 0
     
      data.start_date = moment(data.start_date).format('YYYY-MM-DD')
      data.end_date = moment(data.end_date).format('YYYY-MM-DD')
      data.action_condition = {};

      this.va_spinner(true);
      let res = await LoyaltyProgramActionApis.update( { 
        program_id: this.loyaltyProgram.id, 
        action_id: this.item.id, 
        data: data 
      })
      if(res.status == 200 ) {
        this.clearComponent();
        this.emitter.emit("refresh-earningpoints-list", true);
      }
      this.va_spinner(false);
      this.snackbar.add({
        type: 'info',
        text: res.status == 200 ? res.data.msg : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
    },


    /**
     * Do not allow to add +,-,e
     */
    preventNonNumbers(e) {
       var ASCIICode = (e.which) ? e.which : e.keyCode
      if(ASCIICode == 43 || ASCIICode == 45 || ASCIICode == 101){
        return false;
      }
      return true;
    },

     /**
     * Open Delete confirm
     */
    async deleteRecordConfirm() {
      this.deleteObj = {
        modal: true,
        obj: this.item,
      };
    },

    /**
     * Close delete confirm 
     */
    closeDeleteModal() {
      this.deleteObj = {
        modal: false,
        obj: {},
      };
    },

    /**
     * Delete 
     */
    async deleteNow(obj) {
      this.va_spinner(true);
      let res = await LoyaltyProgramActionApis.delete(this.loyaltyProgram.id, obj.id);
      this.va_spinner(false);
        this.snackbar.add({
        type: 'info',
        text: res.status == 200 ? res.data.msg : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
      if(res.status == 200 ) {
        this.emitter.emit("refresh-earningpoints-list", true);
        this.clearComponent();
      }
    },

    /**
     * 
     *  Do not allow to add +,-,e ,. 
     */
     preventNonNumbersAndDecimal(e) {
      var ASCIICode = (e.which) ? e.which : e.keyCode
      if (ASCIICode == 43 || ASCIICode == 45 || ASCIICode == 101 || e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
        return false;
      }
      return true;
    },

    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  },
});

</script>