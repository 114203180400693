/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import store from '@/store/index';
import UserApis from '@/controllers/UserApis'
import ApiHelper from '@/controllers/ApiHelpers';

export default {
    /**
     * Fetch all customer user
     */
    getBusinessUsers({verified, businessId, limit, page, sort='', order='', search=''}){
        let url = `/users/forBusiness/${businessId}?perPage=${limit}&page=${page}`;
        if(sort){
          url+=`&sort=${sort}&order=${order?order:'asc'}`
        }
        if(search){
          url+=`&search=${search}`
        }
        if(verified){
          url += `&verified=true`
        }
        return UserApis.getApi(url, null)
          .then(res => res)
          .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
          });
    },

  /**
   * update
   */
  update(user_id, data) {
    const url = `/users/admin/updateOne/${user_id}?platform=web`;
    return UserApis.putApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * remove
   */
  remove(user_id) {
    const url = `/users/${user_id}/delete`;
    return UserApis.deleteApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

}