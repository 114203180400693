/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import Env from '@/env';
const apiVersion = 'api';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /**
   * get compliance
   */
  fetch() {
    const url = `${Env.loginUrl}/${apiVersion}/location-compliance-provider/show`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Set compliance
   */
  create(data) {
    const url = `${Env.loginUrl}/${apiVersion}/location-compliance-provider/create`;
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

}