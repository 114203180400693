/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import Env from '@/env';
const apiVersion = 'api/v1';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /**
   * Fetch all
   */
  fetchAll({
    program_id,
    faq_category_id,
    page,
    limit
  }) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/programs/${program_id}/faqs/categories/${faq_category_id}/faq?sortBy=sort_order&order=ASC&page=${page}&limit=${limit}`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * Create
   */
  create(program_id, faq_category_id, data) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/programs/${program_id}/faqs/categories/${faq_category_id}/faq`;
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * update
   */
  update(program_id, faq_category_id, faq_id, data) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/programs/${program_id}/faqs/categories/${faq_category_id}/faq/${faq_id}`;
    return AuthApis.patchApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * remove
   */
  remove(program_id, faq_category_id, faq_id) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/programs/${program_id}/faqs/categories/${faq_category_id}/faq/${faq_id}`;
    return AuthApis.deleteApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Loyalty Faq sync
   */
  sync(program_id, faq_category_id, programName) {
 
    let faqs = [
      {
        question: `What is the ${programName} ?`,
        answer:
          `It is our way of making sure you are treated like a VIP.  Earn points on actions, redeem your points on items and level up your status to unlock more perks!`,
        sort_order: 1
      },
      {
        question: `Who is eligible to join?`,
        answer:
          `Membership is free to legal residents 19 years of age and older. Additional restrictions may apply. Residents 19 & older are eligible to join as long as they have completed the sign-up process.  `,
        sort_order: 2
      },
      {
        question: `How do I join?`,
        answer:
          `Joining is simple.  Just click on this link to complete your sign-up process. You must sign-up with an email address that is unique to you and can only have one active Membership account associated with it.`,
        sort_order: 3
      },
      {
        question: `When do my points expire?`,
        answer:
          `Points are eligible to expire as per ${programName} specific policy. Unless stated otherwise, there are no expiration dates on current rewards.[Future we will add it here if they expire]`,
        sort_order: 5
      },
      {
        question: `How can I change my account details?`,
        answer:
          `You can go change your personal details at any point in your account settings, or through a budtender in store`,
        sort_order: 6
      },
      {
        question: `How can I check how many points I have?`,
        answer:
          `You can check your points  at any point in your account settings, or through a budtender in store`,
        sort_order: 7
      },
      {
        question: `How can I redeem my rewards?`,
        answer:
          `Rewards may be combined and are redeemable at all participating ${programName} stores and online on products and apparel, except when noted. Rewards have no cash value and may not be used toward the purchase of a gift card.`,
        sort_order: 8
      },
      {
        question:
          `How can I opt out of marketing communications or  cancel my membership?`,
        answer:
          `You can opt out of marketing communication or cancel your membership  in your account settings or through a budtender in store. Contact our store with any issues with your Membership program`,
        sort_order: 9
      },
      {
        question: `Is my personal information secure?`,
        answer:
          `Our Privacy Policy provides detailed information regarding how we use your personal information.`,
        sort_order: 10
      },
      {
        question: `What if I have an issue with my Membership account?`,
        answer:
          `Please contact our store for help with any issues with your Membership program`,
        sort_order: 11
      }
    ];
    const url = `${Env.loyaltyUrl}/${apiVersion}/programs/${program_id}/faqs/categories/${faq_category_id}/faq_batch`;
    return AuthApis.postApi(url, null, { faqs })
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },
}