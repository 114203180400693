/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import Env from '@/env';
const apiVersion = 'api/v1';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /**
   * create program
   */
  create(token = false) {
    // Hard Coded
    let data = {
      "name": "My Business Program",
      "desc": "program created with token",
      "is_active": true
    }
    const url = `${Env.loyaltyUrl}/${apiVersion}/programs${token ? '?program_token=true' : ''}`;
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Fetch loyalty programme by selected business , if no then create and return
   */
  getProgramByCurrentActiveBusiness() {
    const url = `${Env.loyaltyUrl}/${apiVersion}/programs?program_token=true`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(async (err) => {

        ApiHelper.detectStatusAndRedirect(err);
        // is ststus is not 400 ie 400 blank create new 
        // if (err.response.status != 401) {
        //   let res = await this.create();
        //   return res;
        // }
        return err;
      });
  },

  /**
   * Fetch loyalty programme by programe Id
   */
  getProgramById(program_id) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/programs/${program_id}?branding=true&faqs=true&tiers=true&program_token=true`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Update loyalty programme
   */
  update(program_id, data) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/programs/${program_id}`;
    return AuthApis.patchApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Sync
   */
  syncCatalogue(businessId) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/businesses/${businessId}/products/sync`;
    return AuthApis.postApi(url, null, {})
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  }


}