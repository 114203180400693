<template>

    <block-loading v-if="loading" />
    <table-lite
      v-else
      :class="'admin-table'"
      :is-loading="table.isLoading"
      :is-slot-mode='true'
      :columns="columns"
      :rows="table.rows"
      :rowClasses="customRowClass"
      :total="table.totalRecordCount"
      :sortable="table.sortable"
      @is-finished="table.isLoading = false"
      :page-size='table.pagesize'
      @do-search="doSearch"
      :pageOptions='table.pageOptions'
      :has-checkbox="false"
       @row-clicked="setActive"
      :checked-return-type="'id'"
      :is-hide-paging="false"
      :pageSizeChangeLabel="'pageSizeChangeLabel'"
      :messages="{
        pagingInfo: 'Total: {2}',
        pageSizeChangeLabel: 'Items per Page',
        gotoPageLabel: '',
        noDataAvailable: 'No records found!',
      }"
    >
      <template v-slot:name="data">
        {{ data.value.name }}
      </template>
      <template v-slot:applyDiscountOn="data">
        <span class="capitalize">{{ data.value.applyDiscountOn ? data.value.applyDiscountOn.replace(/_/g, ' ') : '-'}}</span>
      </template>
        <template v-slot:discount="data">
        <span>{{ data.value.discountType == 'flat' ? '$' : ''}}{{data.value.discount.toFixed(2)}}{{ data.value.discountType == 'flat' ? '' : '%'}}</span>
      </template>
      <template v-slot:startDate="data">
        <span>{{ data.value.startDate ? formatDate(data.value.startDate) : '-'}}</span>
      </template>
      <template v-slot:endDate="data">
        <span>{{ data.value.endDate ? formatDate(data.value.endDate) : '-'}}</span>
      </template>
      
    </table-lite>

</template>

<script>
import  { defineComponent , ref} from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import moment from 'moment';
import Helpers from '@/helpers';
import ProductSalesApis from '@/controllers/ProductSalesApis';
import Env from '@/env';

export default defineComponent({
  name: 'ProductSalesList',
  components: {

  },
  props: {
    iscomponentactive: {
      type: Boolean,
      required: false,
    },
    selectedCallback: {
      type: Function,
      required: true,
    },
    clearComponent: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const snackbar = useSnackbar();
    const confirmStatusObj = ref({
      modal: false,
      obj: {
        status: Boolean,
        id: Number,
      },
    });
    const activeItem = ref({});
    const loading = ref(false);
    const columnsMain = ref([
      {
        label: "Name",
        field: "name",
        sortable: true,
      },
      {
        label: "Applied To",
        field: "applyDiscountOn",
        sortable: false,
      },
      {
        label: "Value",
        field: "discount",
        sortable: false,
      },
      //  {
      //   label: "Discount Type",
      //   field: "discountType",
      //   sortable: false,
      // },
       {
        label: "Start Date",
        field: "startDate",
        sortable: false,
      },
      {
        label: "End Date",
        field: "endDate",
        sortable: false,
      },
      {
        label: "Schedule",
        field: "schedule",
        sortable: false,
      },
   
    ]);

    const table = ref({
      isLoading: false,
      rows: [],
      totalRecordCount: 0,
      sortable: {
        order: "name",
        sort: "asc",
      },
      pagesize: Env.defaultPageSize,
      pageOptions: Env.pageList,
      count: 0,
      page: 1,
    });

    return  {
      table,
      columnsMain,
      activeItem,
      loading,
      confirmStatusObj,
      snackbar,
    };
  },

  async mounted() {
    this.loading = true;
    await this.fetchList(this.table.page);
    this.loading = false;
    this.emitter.on("refresh-product-sales-list", data => {
      this.fetchList(this.table.page);
    });
  },

  beforeDestroy() {
   this.emitter.off('refresh-product-sales-list', () => {})
  },

  watch: {
    'vg_selectedLocation'(val) {
      // if location selected is changed fetch the data
      this.fetchList(this.table.page);
    },
  },

  computed: {
    ...mapGetters({
      vg_selectedLocation: 'location/selected'
    }),

    /**
     * Compute columns
     */
    columns() {
      if(this.iscomponentactive){
        let removeFields= ['schedule', 'discountType', 'applyDiscountOn'];
        return this.columnsMain.filter(item => {
            if( removeFields.indexOf(item.field) == -1 ) {
              return item;
            }
        })
      }
      return this.columnsMain;
    },
  },

  methods: {
      formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    /**
     * Fetch list
     */
    async fetchList(page) {
      this.table.isLoading = true;
      let res = await ProductSalesApis.fetchAll(this.table.pagesize, page, this.table.sortable.order, this.table.sortable.sort);
      this.table.isLoading = false;
      this.table.rows = (res.status == 200) ? res.data.payload.sales : []; 
      this.table.totalRecordCount = (res.status == 200) ? res.data.payload.totalRecords : 0
      this.table.page = page;
    },

    /**
     * Select the item
     */
    setActive(item) {
      // Select unselect the item
      if(this.activeItem.id) {
        let oldactive = document.getElementsByClassName('thisid_'+this.activeItem.id);
        if(oldactive.length > 0){
          oldactive[0].classList.remove("active-row");
        }
      }
      this.activeItem = item;
      let newActive = document.getElementsByClassName('thisid_' + this.activeItem.id);
      if(newActive.length > 0){
        newActive[0].classList.add("active-row");
      }
      this.selectedCallback(item);
    },

    /**
     * Create custom class on tr for active colors
     */
    customRowClass(item) {
      return 'thisid_' + item.id;
    },

  

    /**
     * Search now
     */
    doSearch(offset, limit, order, sort) {
      let page = (offset/limit)+1
      this.table.sortable.order = order
      this.table.sortable.sort = sort
      this.table.pagesize = limit;
      this.fetchList(page);
      
    },

    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })

  }
});
</script>

