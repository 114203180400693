export default {
    getProductVariantIds(products, checkedProductIds){
        if(checkedProductIds.length==0 || products.length==0) return

        let allProductVariantIds = []
        for(let i=0; i<products.length; i++){
            if(checkedProductIds.includes(products[i].id.toString())){
                let variantIds = products[i].variants.map(each => each.id)
                allProductVariantIds = [...allProductVariantIds, products[i].id, ...variantIds]
            }
        }
        return allProductVariantIds
    }
}