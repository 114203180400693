
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions } from "vuex";
import AnalyticNav from '@/views/Reports/ReportGeneration/Nav.vue';
import Env from '@/env';

export default defineComponent({
  name: "ReportGeneration",
  components: {
    AnalyticNav,
  },

  setup() {
    const activeTab = ref<any>('business');
    return {
      activeTab
    };
  },

   watch: {
    'vg_selectedLocation'(val) {
     
    },
  },

  computed: {
    /**
     * business and location iframes
     */
    //  businessIframe() {
    //     let businessID:any = this.vg_activeBusiness.id;
    //     let locationID:any = this.vg_selectedLocation.id;
    //     return `https://lookerstudio.google.com/embed/u/0/reporting/a2deb824-8730-42c6-be44-eddb33c83ab3/page/mhTND?params=%7B%22ds20.business%22:${businessID},%22ds20.location%22:${locationID}`;
    //  },

    
     locationIframe() {
        let businessID:any = this.vg_activeBusiness.id;
        let locationID:any = this.vg_selectedLocation.id;
        let url:any = Env.analyticInsightUrl;
        return url.replace(/__BUSINESSID__/g, businessID)

     },
    ...mapGetters({
      vg_selectedLocation: 'location/selected',
      vg_activeBusiness: 'auth/activeBusiness',
    }),
  },

  mounted() {
    
  },

  beforeDestroy() {
    
  },
 
  methods: {
    /**
     * Change tab
     */
    changeTab(val) {
      this.activeTab = val;
    },
    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  },
});

