
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
import LocationPosStationApis from "@/controllers/LocationPosStationApis";
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';

export default defineComponent({
  name: "LocationPosAdd",
  components: {
   
  },
  setup() {
    const snackbar = useSnackbar();
    const form = ref({
      name: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      // barcode_scanner_serial: {
      //   value: '',
      //   error_type: '',
      //   error_message: '',
      //   validations: ['required'],
      // },
      status: false,
      acceptOnlineOrders: false
    });
    return {
      form,
      snackbar,
    };
  },
  props:{
    clearComponent: {
      type: Function,
      required: true,
    },
  },

  watch:{
     // Fields cannot have white spaces
    // 'form.barcode_scanner_serial.value'(val){
    //   this.form.barcode_scanner_serial.value = val.replaceAll(/\s/g,'').trim();
    // },
  },

  computed: {
    ...mapGetters({
     
    }),
  },
 
  methods: {
    
     /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },


    /**
     * Add new location Tax
     */
    async addNew() {
      let validate = await this.validateFields(['name']);
      if(!validate) {
        return ;
      }
      this.va_spinner(true);
      let res = await LocationPosStationApis.addLocationPosStation({
          name: this.form.name.value,
          status: this.form.status,
          acceptOnlineOrders: this.form.acceptOnlineOrders
      });
      this.va_spinner(false);
        this.snackbar.add({
        type: 'info',
        text: res.status == 201 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
      if(res.status == 201 ) {
        this.clearComponent();
        this.emitter.emit("refresh-location-pos-station-list", true);
      }
    },

    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  },
});

