<template>
<div class="body">
  <div class="email-template-wrapper-pdf">
    <div class="business-logo-pdf">
      <img :src="vg_branding.logo" />
    </div>
    <div class="email-template-title-wrapper-pdf">
      <h1>End of Day Summary</h1>
      <h5><span>Date:</span> {{formatDate(this.date)}}</h5>
      <h5><span>Location:</span> {{location}}</h5>
    </div>
    <div class="overall-detail-wrapper-pdf">
      <h2>Overall</h2>
      <div class="overall-detail-content-pdf">
        <div class="item-detail-pdf">
          <h5>Total Sales</h5>
          <ul>
            <li>
              <p>Online</p>
              <span>{{displayPriceFormat(result.onlineSales)}}</span>
            </li>
            <li>
              <p>In-Store</p>
              <span>{{displayPriceFormat(result.instoreSales)}}</span>
            </li>
            <li>
              <p>Net Sales</p>
              <span>{{displayPriceFormat(result.netSales)}}</span>
            </li>
            <li>
              <p>Total Tax</p>
              <span>{{displayPriceFormat(result.totalTax)}}</span>
            </li>
          </ul>
          <div class="total-wrapper-pdf">
            <h6>Total Invoiced</h6>
            <span>{{displayPriceFormat(result.totalInvoice)}}</span>
          </div>
        </div>
        <div class="item-detail-pdf">
          <h5>Average Spent</h5>
          <ul>
            <li>
              <p>Transaction Average</p>
              <span>{{displayPriceFormat(result.averageSpent)}}</span>
            </li>
          </ul>
        </div>
        <div class="item-detail-pdf">
          <h5>Discounts</h5>
          <ul>
            <li>
              <p>Total Discounts</p>
              <span>{{displayPriceFormat(result.totalDiscount)}}</span>
            </li>
            <li>
              <p>Total Membership Redemptions</p>
              <span>{{displayPriceFormat(result.totalMembershipRedemptions)}}</span>
            </li>
          </ul>
        </div>
        <div class="item-detail-pdf">
          <h5>Cost/Profit</h5>
          <ul>
            <li>
              <p>Total Cost</p>
              <span>{{displayPriceFormat(result.totalCost)}}</span>
            </li>
            <li>
              <p>Gross Profit</p>
              <span>{{displayPriceFormat(result.grossProfit)}}</span>
            </li>
          </ul>
        </div>
        <div class="item-detail-pdf">
          <h5>Payment Methods</h5>
          <ul>
            <li>
              <p>Cash</p>
              <span>{{displayPriceFormat(result.cash)}}</span>
            </li>
            <li>
              <p>Debit</p>
              <span>{{displayPriceFormat(result.debit)}}</span>
            </li>
            <li>
              <p>VISA</p>
              <span>{{displayPriceFormat(result.visa)}}</span>
            </li>
            <li>
              <p>MC</p>
              <span>{{displayPriceFormat(result.mastercard)}}</span>
            </li>
            <li>
              <p>AMEX</p>
              <span>{{displayPriceFormat(result.amex)}}</span>
            </li>
            <li>
              <p>Gift Card</p>
              <span>{{displayPriceFormat(result.giftCard)}}</span>
            </li>
            <li>
              <p>Uber</p>
              <span>{{displayPriceFormat(result.uber)}}</span>
            </li>
            <li>
              <p>Other Card</p>
              <span>{{displayPriceFormat(result.otherCard)}}</span>
            </li>
          </ul>
        </div>
        <div class="item-detail-pdf">
          <h5>Taxes</h5>
          <ul>
            <li v-for="(value, key) in result.totalTaxByCode" :key="key">
              <p>{{key}}</p>
              <span>{{displayPriceFormat(value)}}</span>
            </li>
          </ul>
        </div>
        <div class="item-detail-pdf">
          <h5>Orders</h5>
          <ul>
            <li>
              <p># of Orders</p>
              <span>{{result.ordersSold}}</span>
            </li>
            <li>
              <p>Orders Refunded</p>
              <span>{{result.ordersRefund}}</span>
            </li>
          </ul>
          <div class="total-wrapper-pdf">
            <h6>Net Sold</h6>
            <span>{{result.netOrders}}</span>
          </div>
        </div>
        <div class="item-detail-pdf">
          <h5>Products Sold</h5>
          <ul>
            <li>
              <p>Product Sold</p>
              <span>{{result.productsSold?result.productsSold:0}}</span>
            </li>
            <li>
              <p>Product Refunded</p>
              <span>{{result.productsRefund?result.productsRefund:0}}</span>
            </li>
          </ul>
          <div class="total-wrapper-pdf">
            <h6>Net Sold</h6>
            <span>{{result.netProducts?result.netProducts:0}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="employee-sales-wrapper-pdf">
      <h2>Employee Sales</h2>
      <!-- for desktop -->
      <table class="employee-sales-table-desk-pdf">
        <tr>
          <th>Employee</th>
          <th>Total Invoiced</th>
          <th>Sales ($)</th>
          <th>Sales (#)</th>
          <th>Average Sale</th>
          <th>Refunds ($)</th>
          <th>Refunds (#)</th>
          <th>Average Refund</th>
        </tr>
        <template v-if="result.empSummary.length >0">
          <tr  v-for="(employee, ind) in result.empSummary" :key="ind">
            <td>{{employee.name}}</td>
            <td>{{displayPriceFormat(employee.totalInvoice)}}</td>
            <td>{{displayPriceFormat(employee.sales)}}</td>
            <td>{{employee.ordersSold}}</td>
            <td>{{displayPriceFormat(employee.averageSale)}}</td>
            <td>{{displayPriceFormat(employee.refunds)}}</td>
            <td>{{employee.ordersRefund}}</td>
            <td>{{displayPriceFormat(employee.averageRefund)}}</td>
          </tr>
        </template>
        
        <!-- If no record is found -->
        <div v-else>
          <tr>
            <td class="no-record-pdf" colspan="8">No records found.</td>
          </tr>
          </div>
      </table>
    </div>
  </div>
</div>
</template>
<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Vithkuqi&family=Open+Sans:wght@300&family=Roboto:wght@100;300;400&display=swap');
.body {
  box-sizing: border-box;
  color: #1c1d21;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  margin: 0px;
}
a {
  color: #1c1d21;
}
.business-logo-pdf img {
  margin: auto;
  width: auto;
  height: 100%;
}
.email-template-wrapper-pdf {
  background: #fff;
  width: 1100px;
  padding: 40px 20px;
  margin: 0 auto;
}
.business-logo-pdf {
  width: 400px;
  height: 100px;
  padding: 8px;
  margin: 0 auto;
  vertical-align: middle;
}
.business-logo-pdf p {
  text-align: center;
  background: #e7a644;
  margin: 0px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17.5px;
}
.email-template-title-wrapper-pdf {
  padding: 40px 0px;
  text-align: center;
  border-bottom: 1px solid #ececec;
}
.email-template-title-wrapper-pdf h1 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 0px;
}
.email-template-title-wrapper-pdf h5 {
  margin: 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17.5px;
  margin: 8px 0;
}
.email-template-title-wrapper-pdf h5 span {
  letter-spacing: 0.1px;
  font-weight: 700;
}
.email-template-title-wrapper-pdf h5:last-child {
  margin: 0px;
}
.overall-detail-wrapper-pdf {
  padding: 40px 0;
}
.overall-detail-wrapper-pdf h2 {
  margin: 0px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}
.overall-detail-content-pdf {
  display: grid;
  grid-template-columns: auto auto auto auto;
}
.item-detail-pdf {
  margin-top: 16px;
  width: 225px;
  border-radius: 6px;
  padding: 16px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  filter: drop-shadow(rgba(99, 99, 99, 0.2) 0px 2px 8px 0px);
  height: auto;
  /* border:1px solid #ececec; */
}
.item-detail-pdf h5 {
  margin: 0px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  border-bottom: 1px solid #ececec;
  padding-bottom: 15px;
}
.item-detail-pdf ul {
  margin: 0px;
  padding: 8px 0px;
  list-style-type: none;
}
.item-detail-pdf ul li {
  padding-bottom: 15px;
}

.item-detail-pdf ul li,
.total-wrapper-pdf {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 17.5px;
  font-weight: 400;
}
.item-detail-pdf ul li p {
  width: 174px;
  margin: 0px;
}
.total-wrapper-pdf {
  border-top: 1px solid #ececec;
  padding-top: 6px;
}
.total-wrapper-pdf h6,
.total-wrapper-pdf span {
  font-size: 14px;
  line-height: 17.5px;
  letter-spacing: 0.1px;
  font-weight: 700;
  margin: 0px;
}
.employee-sales-wrapper-pdf h2 {
  margin: 0px;
  font-weight: 400;
  padding-left: 8px;
  padding-bottom: 24px;
  font-size: 20px;
  line-height: 24px;
}
.employee-sales-table-desk-pdf {
  border-collapse: collapse;
  width: 100%;
  display: block;
}
.employee-sales-table-desk-pdf tr td {
  padding: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17.5px;
}
.employee-sales-table-desk-pdf th {
  border-bottom: 1px solid #ececec;
  text-align: left;
  padding: 15px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17.5px;
  letter-spacing: 0.1px;
}
.no-record-pdf {
  text-align: center !important;
  font-style: italic;
  font-weight: 400 !important;
  line-height: 17.5px !important;
  font-size: 14px !important;
}
.employee-sales-table-desk-pdf tr td:first-child,
.employee-sales-table-desk-pdf tr th:first-child {
  width: 170px;
}
.employee-sales-table-desk-pdf tr td:nth-child(2),
.employee-sales-table-desk-pdf tr td:nth-child(5),
.employee-sales-table-desk-pdf tr td:last-child,
.employee-sales-table-desk-pdf tr th:nth-child(2),
.employee-sales-table-desk-pdf tr th:nth-child(5),
.employee-sales-table-desk-pdf tr th:last-child {
  width: 130px;
}
.employee-sales-table-desk-pdf tr td:nth-child(3),
.employee-sales-table-desk-pdf tr td:nth-child(4),
.employee-sales-table-desk-pdf tr td:nth-child(6),
.employee-sales-table-desk-pdf tr td:nth-child(7),
.employee-sales-table-desk-pdf tr th:nth-child(3),
.employee-sales-table-desk-pdf tr th:nth-child(4),
.employee-sales-table-desk-pdf tr th:nth-child(6),
.employee-sales-table-desk-pdf tr th:nth-child(7) {
  width: 110px;
}
</style>
<script>
import { defineComponent, ref } from 'vue'
import moment from 'moment'
import { mapGetters } from "vuex";
import Env from '@/env';


export default defineComponent({
   computed: {
  
    webStoreUrl() {
      return Env.webStoreUrl;
    },
    ...mapGetters({
      vg_branding: 'auth/branding',
      vg_selectedLocation: 'location/selected',
      vg_activeBusiness: 'auth/activeBusiness',
    }),
    
  },
  
  props: ['result', 'date', 'location', 'logo'],

  methods: {
    formatDate(date){
        if(!date) return
        return moment(date).format('MMMM D, YYYY')
    },
    displayPriceFormat(price) {
        if(price > 0) {
            return '$' + parseFloat(price).toFixed(2);
        } else if(price<0){
            return '-$' + Math.abs(parseFloat(price)).toFixed(2);
        }else{
          return '$0.00';
        }
    },
  }
  
})
</script>

