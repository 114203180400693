<template>
    <div>
        <div class="text-[24px] text-white font-semibold mb-3 flex justify-between">
            Product Import
            <div>
                <button @click="create()" :disabled="IsNotValid" class="text-sm font-medium tracking-widest text-theme-green py-2 px-4 rounded bg-white disabled:bg-theme-grey disabled:text-grey  hover:text-white hover:bg-theme-darkgreen">IMPORT</button>
                <button @click="createPostInventory()" class="ml-1 text-sm font-medium tracking-widest text-white py-2 px-4 rounded bg-theme-green disabled:bg-theme-grey disabled:text-grey  hover:text-white hover:bg-theme-darkgreen">Submit inventory to OCS</button>
            </div>
        </div>

        <div v-if="error" class="flex flex-col relative border border-theme-red text-theme-red rounded w-full p-2 break-all pr-5">
            <div class="flex">
                <svg class="fill-theme-red mr-2 min-h-[24px] min-w-[24px]" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 17C12.2833 17 12.5208 16.9042 12.7125 16.7125C12.9042 16.5208 13 16.2833 13 16C13 15.7167 12.9042 15.4792 12.7125 15.2875C12.5208 15.0958 12.2833 15 12 15C11.7167 15 11.4792 15.0958 11.2875 15.2875C11.0958 15.4792 11 15.7167 11 16C11 16.2833 11.0958 16.5208 11.2875 16.7125C11.4792 16.9042 11.7167 17 12 17ZM12 13C12.2833 13 12.5208 12.9042 12.7125 12.7125C12.9042 12.5208 13 12.2833 13 12V8C13 7.71667 12.9042 7.47917 12.7125 7.2875C12.5208 7.09583 12.2833 7 12 7C11.7167 7 11.4792 7.09583 11.2875 7.2875C11.0958 7.47917 11 7.71667 11 8V12C11 12.2833 11.0958 12.5208 11.2875 12.7125C11.4792 12.9042 11.7167 13 12 13ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"/>
                </svg>
                <div v-if="Array.isArray(errorMsg)">
                    <span>You have {{errorMsg.length}} error messages, expand the right panel to view them. </span>
                    <span @click="toggleExpand" class="text-theme-green underline cursor-pointer">{{expandError ? 'Collapse View' : 'Expand View'}}</span>
                </div>
                <span v-else>{{errorMsg}}</span>
            </div>

            <div v-if="expandError" class="relative ml-2 px-[16px] bg-[#2B2C32] rounded-xl">
                <p class="text-xs text-theme-orange my-2" v-for="error in errorMsg" :key="error">{{error}}</p>
            </div>
        </div>

        <div class="py-2 grid gap-4 xl:grid-cols-4 grid-cols-1">
            <div class="flex flex-col gap-2">
                <div class="block xl:h-[40px] text-white text-sm">Inventory by Products incl. Packages</div>
                <Upload id="file" @change="addImportFile" :reset="reset" />
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, ref} from "vue"
import { useSnackbar } from "vue3-snackbar";
import { mapGetters, mapMutations } from "vuex"
import Upload from './Upload'
import ToolsApis from '@/controllers/ToolsApis'
import Helpers from '@/helpers'
import ProductApis from "@/controllers/ProductApis";
import BusinessProductApi from '@/controllers/BusinessProductApis'

export default defineComponent({
    components: {
        Upload
    },
    setup() {
        const snackbar = useSnackbar();
        const IsNotValid = ref(true)
        const form = ref({
            importFile: null,
            locationId: '',
            businessId: ''
        })
        const error = ref(false)
        const errorMsg = ref('')
        const expandError = ref(false)
        const reset = ref(false)

        return {snackbar, IsNotValid, form, reset, error, errorMsg, expandError}
    },
    mounted(){
        this.form.locationId = this.vg_location.id
        this.form.businessId = this.vg_business.id
    },
    watch: {
        vg_location(loc){
            this.form.locationId = loc.id
        }
    },
    computed: {
        ...mapGetters({
            vg_location: 'location/selected',
            vg_business: 'auth/activeBusiness',
        })
    },
    methods: {
        addImportFile(value){
            if(value.name) {
                this.form.importFile = value
                this.IsNotValid = false
            }else if(value=='') {
                this.form.importFile = null
                this.IsNotValid = true
                this.error = false
            }
        },
        async create(){
            this.vm_spinner(true)
            let formData = new FormData();
            formData.append("importFile", this.form.importFile.data, this.form.importFile.name)
            formData.append("locationId", this.form.locationId)
            formData.append("businessId", this.form.businessId)
            let res = await ToolsApis.importCovaProducts(formData)
            
            if(res.status!=201){
                this.errorMsg = res.response?.data?.message
                this.error =true
            }else{
                this.snackbar.add({
                    type: 'info',
                    text: res.data.message,
                    duration: 3000
                })
            }
            if(res.status===201){
                this.form.importFile = null
                this.IsNotValid = true
                this.resetComponent()
            }
            this.vm_spinner(false)
        },
        toggleExpand(){
            this.expandError = !this.expandError
        },
        resetComponent(){
            this.reset = !this.reset
        },
        async createPostInventory(){
            this.vm_spinner(true)
            let res = await BusinessProductApi.postInventory(this.vg_location.id)
            this.snackbar.add({
                type: 'info',
                text:
                res.status == 201
                    ? res.data.message
                    : Helpers.catchErrorMessage(res.response.data),
                duration: 3000
            })
            this.vm_spinner(false)
            
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }
})
</script>