import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-925bf80a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "badges-nav page-navebar" }
const _hoisted_2 = { class: "bg-[#2B2C32] rounded-full px-8 py-2 w-full min-w-fit" }
const _hoisted_3 = { class: "flex flex-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", {
          class: _normalizeClass('mr-4 w-max flex flex-nowrap' + (_ctx.$route.name == 'MembershipSetting' ? ' active' : ''))
        }, [
          _createVNode(_component_router_link, {
            to: { name: 'MembershipSetting' },
            class: _normalizeClass('uppercase  tracking-widest text-sm font-semibold px-2 ' + ( _ctx.$route.name == 'MembershipSetting' ? 'text-white' : 'text-theme-878686 hover:text-white'))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Membership Settings ")
            ]),
            _: 1
          }, 8, ["class"])
        ], 2),
        _createElementVNode("li", {
          class: _normalizeClass('mr-4 w-max  flex flex-nowrap' + (_ctx.$route.name == 'MembershipEarningPoints' ? ' active' : ''))
        }, [
          _createVNode(_component_router_link, {
            to: { name: 'MembershipEarningPoints' },
            class: _normalizeClass('uppercase tracking-widest text-sm font-semibold px-2 ' + ( _ctx.$route.name == 'MembershipEarningPoints' ? 'text-white' : 'text-theme-878686 hover:text-white'))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Earning ")
            ]),
            _: 1
          }, 8, ["class"])
        ], 2),
        _createElementVNode("li", {
          class: _normalizeClass('mr-4 w-max  flex flex-nowrap' + (_ctx.$route.name == 'MembershipRedeemingPoints' ? ' active' : ''))
        }, [
          _createVNode(_component_router_link, {
            to: { name: 'MembershipRedeemingPoints' },
            class: _normalizeClass('uppercase tracking-widest text-sm font-semibold px-2 ' + ( _ctx.$route.name == 'MembershipRedeemingPoints' ? 'text-white' : 'text-theme-878686 hover:text-white'))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Redeeming ")
            ]),
            _: 1
          }, 8, ["class"])
        ], 2),
        _createElementVNode("li", {
          class: _normalizeClass('mr-4 w-max  flex flex-nowrap' + (_ctx.$route.name == 'MembershipBadges' ? ' active' : ''))
        }, [
          _createVNode(_component_router_link, {
            to: { name: 'MembershipBadges' },
            class: _normalizeClass('uppercase tracking-widest text-sm font-semibold px-2 ' + ( _ctx.$route.name == 'MembershipBadges' ? 'text-white' : 'text-theme-878686 hover:text-white'))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Badges ")
            ]),
            _: 1
          }, 8, ["class"])
        ], 2),
        _createElementVNode("li", {
          class: _normalizeClass(' w-max  flex flex-nowrap' + (_ctx.$route.name == 'MembershipFaqs' ? ' active' : ''))
        }, [
          _createVNode(_component_router_link, {
            to: { name: 'MembershipFaqs' },
            class: _normalizeClass('uppercase tracking-widest text-sm font-semibold px-2 ' + ( _ctx.$route.name == 'MembershipFaqs' ? 'text-white' : 'text-theme-878686 hover:text-white'))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" FAQ ")
            ]),
            _: 1
          }, 8, ["class"])
        ], 2)
      ])
    ])
  ]))
}