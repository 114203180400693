
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
import List from '@/views/CashMgmt/EndOfDay/List.vue';
import Update from '@/views/CashMgmt/EndOfDay/Update.vue';


export default defineComponent({
  name: "EodReports",
  components: {
    List,
    Update,
  },

  setup() {
    const action:any = ref({
      component: '',
      data: {},
    });
    return {
      action,
    };
  },

   watch: {
    'vg_selectedLocation'(val) {
      this.clearComponent();
    },
  },

  computed: {
    ...mapGetters({
     vg_selectedLocation: 'location/selected'
    }),
  },

  mounted() {
    this.emitter.on("reset-eod-component", data => {
      this.clearComponent();
    });
  },

  beforeDestroy() {
    this.emitter.off('reset-eod-component', () => {});
    this.emitter.off('refresh-eod-list', () => {});
  },
 
  methods: {
    /**
     * Clear action
     */
    clearComponent() {
      this.action = {
        component: '',
        data: {},
      };
      let activeItem = document.getElementsByClassName('active-row');
      if(activeItem.length > 0){
        activeItem[0].classList.remove("active-row");
      }
    },

    /**
     * Change add / update component
     */
    addUpdate(val: any) {
      this.action = val;
    },

    /**
     * Capture selected item
     */
    selectedItem(item) {
      this.addUpdate({
        component: 'Update', 
        data: item
      })
    },

    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  },
});

