
import BusinessCategoryApis from "@/controllers/BusinessCategoryApis";
import { defineComponent, ref} from "vue";
import { mapGetters, mapActions } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';
import Env from '@/env';
import CustomSortableTable from '@/components/helpers/CustomSortableTable.vue';

export default defineComponent({
  components: {
    CustomSortableTable,
  },
  props: {
    iscomponentactive: {
      type: Boolean,
      required: false,
    },
    selectedCallback: {
      type: Function,
      required: true,
    },
    clearComponent: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const snackbar = useSnackbar();
    const activeItem = ref<any>({});
    const loading = ref<Boolean>(false);
    const tableHeaders = ref([
        {
          label: "Name",
          key: "name",
          sortable: false,
          show: true,
          width:'250px'
        },
        {
          label: "Type",
          key: "parent",
          sortable: false,
          show: true,
          width:'100px'
        },
        {
          label: "Product Type",
          key: "ComplianceType",
          sortable: false,
          show: true,
          width:'150px'
        },
        // {
        //   label: "Min. Stock Threshold",
        //   key: "stockThreshold",
        //   sortable: false,
        //   show: true,
        //   width:'150px'
        // },
        {
          label: "THC/CBD Value",
          key: "thcCbdValueType",
          sortable: false,
          show: true,
          width:'150px'
        },
        {
          label: "THC/CBD Unit",
          key: "concentrationUnit",
          sortable: false,
          show: true,
          width:'150px'
        },
        {
          label: "Action",
          key: "action",
          sortable: false,
          show: true,
          width:'200px'
        },
    ]);

    const table = <any>ref({
      headers: [],
      perPageDropdown: {
        active: 1000, // Env.defaultPageSize, no pagaing
        options: Env.pageList,
      },
      sort: {
        key: '',
        value: '',
      },
      pagination: {
        activePageNo: 1,
        noOfpages: 1,
        totalRecords: 0,
        show: false
      },
      records: [],
      totalrecords: 0,  
      loading: false,
      child: {
        display: true,
        key: 'subCategories',  // which key to display as child
        displayToggleKey: 'name' // which key to show toggle icon
      }
    });

    return  {
      tableHeaders,
      table,
      activeItem,
      loading,
      snackbar,
    };
  },

  async mounted() {
    this.table.headers = this.tableHeaders;
    this.loading = true;
    await this.fetchList(1);
    this.loading = false;
    this.emitter.on("refresh-business-categories-list", async (data) => {
      this.loading = true;
      await this.fetchList(1);
      this.loading = false;
    });
  },

  beforeDestroy() {
   this.emitter.off('refresh-business-categories-list', () => {})
  },

  computed: {
    /**
     * Edit restriction
     */
     can_edit_categories() {
      return Helpers.getMyPermission('can_edit_categories')
    },

    ...mapGetters({
       vg_activeBusiness: 'auth/activeBusiness',
    }),
  },

  watch: {
    'iscomponentactive'(val) {
      if(val){
        let removeFields= ['action', 'parent','thcCbdValueType', 'stockThreshold', 'concentrationUnit'];
        this.table.headers = this.tableHeaders.filter(item => {
            if( removeFields.indexOf(item.key) == -1 ) {
              return item;
            }
        })
      } else {
        this.table.headers = this.tableHeaders;
        this.activeItem = {};
      }
    },
  },

  methods: {
    /**
     * Fetch list
     */
    async fetchList(page) {

      let res = await BusinessCategoryApis.fetchAllBusinessCategries({
        businessId: this.vg_activeBusiness.id, 
        page: this.table.pagination.activePageNo,
        perPage: this.table.perPageDropdown.active,
      });  
 
      if(res.status == 200) {
        // before we push we need to add is active key on items
        res.data.payload.category.map(item => {
          item.isActive = false;
          if(item.subCategories && item.subCategories.length > 0){
            item.subCategories.map(submitem => {
              submitem.active = false;
            })
          }
        })
        let category = res.data.payload.category.filter(each => !each.featured)

        this.table.records = category;
        this.table.totalrecords = res.data.payload.totalRecords
        this.table.pagination.totalRecords = res.data.payload.totalRecords
        this.table.pagination.noOfpages = res.data.payload.numberOfPages
      }
    },

    /**
     * Create custom class on tr for active colors
     */
    customRowClass(item) {
      return 'thisid_' + item.id;
    },


    /**
     * Search now
     */
    doSearch(offset, limit, order, sort) {
      if(order && sort) {
        this.table.rows = this._lodash.orderBy(this.table.rows, [order],[sort]);
      }
      this.table.pagesize = limit;
      let pageNo = (offset/limit) +1;
      this.fetchList(pageNo)
    },

    /**
     * Select the item
     */
    setActive(item) { 
      this.activeItem = item;
      let contentScreen = <any>document.getElementById('content_screen');
      contentScreen.scroll({
        top: 0, 
        left: 0, 
        behavior: 'smooth' 
      });
      this.selectedCallback(item);
    },

    async search(data) {
      // console.log(data)
      if(data.frontendSort) {
        this.table.records = this._lodash.orderBy(this.table.records, [data.sort.key],[data.sort.value]);
      } else {
        this.table.loading = true;
        this.table.sort = {
          key: '',
          value: '',
        }; // reset sort
        await this.fetchList(data.pageNo)
        this.table.loading = false;
      }
     
    },

    async sortCategory(catIds) {
      this.va_spinner(true);
      await BusinessCategoryApis.sortCategory({categoryIds: catIds})
      await this.fetchList(1)
      this.loading = true;
      this.$nextTick(() => {
        this.loading = false;
      })
      this.va_spinner(false);
    },


    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })

  }
});
