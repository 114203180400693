<template>
  <div class="rounded-[10px] mb-4 bg-[#2B2C32] w-full pb-4">
    <new-table-lite
      :class="'admin-table'"
      :is-loading="table.loading"
      :is-slot-mode='true'
      :columns="columns"
      :rows="table.rows"
      :rowClasses="customRowClass"
      :total="table.totalRecordCount"
      :sortable="table.sortable"
      :page-size='table.pagesize'
      :page="table.page"
      @do-search="doSearch"
      :pageOptions='table.pageOptions'
      :has-checkbox="false"
      :checked-return-type="'id'"
      :pageSizeChangeLabel="'pageSizeChangeLabel'"
      @row-clicked="selectedRow"
      :messages="{
        pagingInfo: 'Total: {2}',
        pageSizeChangeLabel: 'Items per Page',
        gotoPageLabel: '',
        noDataAvailable: 'No records found!',
      }"
    >
        <template v-slot:status="data">
            <div class="flex flex-row gap-2 items-center">
                <div v-if="data.value.status=='pending'" class="font-bold text-white text-center flex justify-center items-center w-[100px] text-sm leading-[17.5px] tracking-[0.1px] h-[40px] rounded p-2 bg-[#B3261E]">Pending</div>
                <div v-else-if="data.value.status=='shipped' || data.value.status=='shipped transfer'" class="font-bold text-black text-center flex justify-center items-center w-[100px] text-sm leading-[17.5px] tracking-[0.1px] h-[40px] rounded p-2 bg-[#E7A644]">Shipped</div>
                <div v-else-if="data.value.status=='completed' || data.value.status=='completed transfer'" class="font-bold text-white text-center flex justify-center items-center w-[100px] text-sm leading-[17.5px] tracking-[0.1px] h-[40px] rounded p-2 bg-[#2BA66B]">Completed</div>
            </div>
        </template>
      <template v-slot:eta="data">
        <div v-if="data.value.eta">{{formatDate(data.value.eta)}}</div>
      </template>
      <template v-slot:updatedAt="data">
        <div v-if="data.value.updatedAt">{{formatDate(data.value.updatedAt)}}</div>
      </template>
      <!-- downloading icon -->
      <!-- <template v-slot:id="data">
        <div v-if="data.value.id">
            <svg @click.stop="createXlSheet(data.value)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M19 9.5H15V3.5H9V9.5H5L12 16.5L19 9.5ZM11 11.5V5.5H13V11.5H14.17L12 13.67L9.83 11.5H11ZM19 20.5V18.5H5V20.5H19Z" fill="white"/>
            </svg>
        </div>
      </template> -->
    </new-table-lite>
  </div>
</template>

<script>
import  { defineComponent , ref} from "vue";
import { mapGetters, mapActions } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import Env from '@/env';
import {debounce} from 'lodash'
import NewTableLite from '@/components/table/tableLite.vue'
import TransferApi from '@/controllers/Transfer'
import moment from 'moment'
import writeXlsxFile from 'write-excel-file';

export default defineComponent({
  name: 'TransferList',
  components: {
    NewTableLite
  },
  props: {
    iscomponentactive: {
      type: Boolean,
      required: false,
    },
    selectedCallback: {
      type: Function,
      required: true,
    },
    clearComponent: {
      type: Function,
      required: true,
    },
    search: {
      type: String,
      required: false
    }
  },
  setup() {
    const snackbar = useSnackbar();
    const activeItem = ref({});
    const columnsMain = ref([
      {
        label: "Status",
        field: "status",
        sortable: false,
      
      },
      {
        label: "Transfer Name",
        field: "name",
        sortable: false,
      },
      {
        label: "From",
        field: "fromLocationName",
        sortable: false,
      },
       {
        label: "To",
        field: "toLocationName",
        sortable: false,
      },
        {
        label: "ETA",
        field: "eta",
        sortable: false,
        // columnClasses:'bg-[#4D4E53]'
      },
        {
        label: "Created By",
        field: "createdByName",
        sortable: false,
      },
        {
        label: "Last Modified",
        field: "updatedAt",
        sortable: false,
      },
    ]);

    const table = ref({
      loading: false,
      rows: [],
      totalRecordCount: 0,
      count: 0,
      sortable: {
        order: "",
        sort: "",
      },
      pagesize: Env.defaultPageSize,
      pageOptions: Env.pageList,
      page: 1
    });

    return  {
      table,
      columnsMain,
      activeItem,
      snackbar,
    };
  },

  async mounted() {
    this.fetchList(this.table.page);
    this.emitter.on("refresh-product-audit-list", data => {
      this.fetchList(this.table.page);
    });
  },

  beforeDestroy() {
   this.emitter.off('refresh-product-audit-list', () => {})
  },

  watch: {
    search: debounce(function(val){
      this.fetchList(1, this.table.pagesize, this.search);
    }, 600),
    'vg_selectedLocation'(val) {
      // if location selected is changed fetch the data
      this.fetchList(this.table.page, this.table.pagesize, this.search);
    },
  },

  computed: {
    ...mapGetters({
      vg_selectedLocation: 'location/selected'
    }),

    /**
     * Compute columns
     */
    columns() {
      if(this.iscomponentactive){
        let removeFields= ['description'];
        return this.columnsMain.filter(item => {
            if( removeFields.indexOf(item.field) == -1 ) {
              return item;
            }
        })
      }
      return this.columnsMain;
    },
  },

  methods: {
    formatDate(date){
      if(!date) return
      return moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')
    },
    /**
     * Fetch list
     */
    async fetchList(page, pagesize, search) {
      this.table.loading = true
      this.table.page = page;
      let res = await TransferApi.fetchList(page, pagesize, search, this.vg_selectedLocation.id);
      if(res.data?.payload?.transfers){
        this.table.rows = res.data.payload.transfers;
        this.table.totalRecordCount = (res.status == 200) ? res.data.payload.totalRecords : 0
      }
      this.table.loading = false
    },

    /**
     * Select the item
     */
    selectedRow(item) {
      // Select unselect the item
      if(this.activeItem.id) {
        let oldactive = document.getElementsByClassName('thisid_'+this.activeItem.id);
        if(oldactive.length > 0){
          oldactive[0].classList.remove("active-row");
        }
      }
      this.activeItem = item;
      let newActive = document.getElementsByClassName('thisid_' + this.activeItem.id);
      if(newActive.length > 0){
        newActive[0].classList.add("active-row");
      }
      this.selectedCallback(item);
    },

    async createXlSheet(jsonData) {
      try {
        let schema = [
          {
            column: 'Name',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.name?i.name:''
          },
          {
            column: 'Category',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => {
              if(i.productName){
                return i.productName;
              } else {
                return '';
              }
            }
          },
          {
            column: 'Sku',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => {
              if(i.variantName){
                return i.variantName;
              } else {
                return '';
              }
            }
          },
          {
            column: 'Variant',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.employeeName ? i.employeeName : '' 
          },
          {
            column: 'Lot #',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.location ? i.location : '' 
          },
          {
            column: 'Qty at Source',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.category ? i.category : '' 
          },
          {
            column: 'Qty at Dst.',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.barcode ? i.barcode : '' 
          },
          {
            column: 'Transfer Qty',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.sku ? i.sku : '' 
          }
        ];

        let xlBlob = await writeXlsxFile([jsonData], {
          schema: schema,
        });
        let fileName = 'Transfer.xlsx';
        let url = URL.createObjectURL(xlBlob);
        let link = document.createElement("a");
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        let self = this
        setTimeout(function(){
          self.snackbar.add({
            type: 'info',
            text: 'File downloaded',
            duration: 3000,
          })
        }, 1000)
      } catch(err) {
        console.log(err)
        return this.snackbar.add({
          type: 'info',
          text: 'Something went wrong. Please try again later.',
          duration: 3000,
        })
      }
    },

    /**
     * Create custom class on tr for active colors
     */
    customRowClass(item) {
      return 'cursor-pointer thisid_' + item.id;
    },

  

    /**
     * Search now
     */
    doSearch(offset, limit, order, sort) {
      if(this.search===''){
        let page = (offset/limit)+1
        this.table.sortable.order = order
        this.table.sortable.sort = sort
        this.table.pagesize = limit;
        this.table.page = page;
        this.fetchList(page, limit);
      }
    },

    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })

  }
});
</script>

