/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

 import store from '@/store/index';
 import Env from '@/env';
 const apiVersion = 'api';
 import AuthApis from '@/controllers/AuthApis';
 import ApiHelper from '@/controllers/ApiHelpers';
 import router from '@/router';

 export default {
     /**
      * Fetch selected location delivery zone
      */

      fetchLocationDeliveryZone() {
        const url = `${Env.loginUrl}/${apiVersion}/location-delivery-zones/all`;
        return AuthApis.getApi(url, null)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
    },

    /**
      * Add location delivery zone
      */
     addNewLocationDeliveryZone(data) {
        const url = `${Env.loginUrl}/${apiVersion}/location-delivery-zones/create`;
        return AuthApis.postApi(url, null, data)
         .then(res => res)
         .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
         });
    },

    /**
      * Update delivery Zone
      */
     updateLocationDeliveryZone(zoneId, data) {
      const url = `${Env.loginUrl}/${apiVersion}/location-delivery-zones/${zoneId}/update`;
      return AuthApis.patchApi(url, null, data)
       .then(res => res)
       .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
       });
      },

    deleteLocationDeliveryZone(zoneId){
        let userToken = store.getters['auth/userToken'];
        const url = `${Env.loginUrl}/${apiVersion}/location-delivery-zones/${zoneId}/remove`;
        return AuthApis.deleteApi(url, {
            'Authorization': 'Bearer '+ userToken
        })
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;  
        });
    },
 }