<template>
    <div class="bg-[#fff] text-dark" :style="cssVars">
        <div class="grid grid-cols-1 lg:grid-cols-1 gap-3">
            <div class="text-dark flex-1 col-span-1 border-0 rounded-t-xl overflow-hidden">
                <div class="bg-[#fff] px-[16px] py-[16px] rounded-lg" v-if="product.name">
                    <div class="px-[16px] py-[16px] bg-[#fff] rounded-xl text-center">  
                        <div style="font-family: Arial;letter-spacing: 1px;" :class="'text-xl text-black mt-0 mb-5' + (typepdf ? '  tracking-wide' : '')" v-html="product.name+(product.variantName?' ('+product.variantName+')':'')"></div>
                          <div class="qr-scanner flex justify-center items-center">
                            <qrcode-vue :value="getQrCodeValue"  :size="120 * 2" level="L" />
                            <div class="qrcode-image">
                              <svg width="40" height="40" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M18.2771 10.1888L23.8023 19.7587C24.3641 20.7318 23.6618 21.9482 22.5381 21.9482L13.3069 21.9482L13.3069 10.1888L18.2771 10.1888Z" fill="white"/>
                                  <path d="M10.736 0.782108C11.2979 -0.191042 12.7025 -0.191041 13.2644 0.78211L17.0922 7.41213H6.9082L10.736 0.782108Z" fill="#B0EDAE"/>
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.355 11.2836L1.14586 20.3061C1.0054 20.5493 1.18098 20.8534 1.4619 20.8534L9.59827 20.8534L9.59826 11.2836L6.355 11.2836ZM0.197744 19.7587L5.72292 10.1888L10.6931 10.1888L10.6931 21.9482L1.4619 21.9482C0.338206 21.9482 -0.364105 20.7318 0.197744 19.7587Z" fill="white"/>
                              </svg>
                          </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</template>


<script>
  import { defineComponent, ref } from "vue"
  import { mapGetters, mapActions } from "vuex";
  import { useSnackbar } from "vue3-snackbar";
  import QrcodeVue from 'qrcode.vue'
  import env from "@/env";

  export default defineComponent({
    name: "QrCodeOnlyCard",
    components: {
      QrcodeVue
    },
    setup() {
      const loading = ref(false);
      const snackbar = useSnackbar();
      return {
        loading,
        snackbar,
      };
    },
    props: {
      product: {
        type: Object,
        required: true,
      },
      typepdf: {
        type: Boolean,
        required: false,
      },
    },
    computed: {
      ...mapGetters({
        vg_activeBusiness: 'auth/activeBusiness',
        vg_locationSelected: "location/selected",
      }),
      getQrCodeValue() {
        let productSlug = this.product.name?.trim().toLowerCase().replace(/[^a-zA-Z0-9]+/g, '_')+'-'+this.product.id
        return (
          env.webStoreUrl + '/' +
          this.vg_activeBusiness.slug +
          '/' +
          this.vg_locationSelected.slug +
          '/' +
          productSlug +
          '?in_store=true&noage=true'
        )
      },
    },
    async mounted() {
    
    },
    methods: {
      ...mapActions({
        va_spinner: 'spinner/setSpinner',
      })
    },
  });
</script>
