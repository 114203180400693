<template>
<div class="text-white flex-1 col-span-1 border-0 rounded-t-xl overflow-hidden mt-1">
    <div class="pt-3 bg-[#2B2C32] px-0 py-[16px] rounded-xl">
            <div>
                <div>

                    <div v-if="name">
                        <div class="flex flex-row gap-2 justify-center items-center cursor-pointer" @click="deleteImage(image)">
                            <u>{{name}}</u>
                            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.47578 13.8248L0.425781 12.7748L6.20078 6.9998L0.425781 1.2248L1.47578 0.174805L7.25078 5.9498L13.0258 0.174805L14.0758 1.2248L8.30078 6.9998L14.0758 12.7748L13.0258 13.8248L7.25078 8.0498L1.47578 13.8248Z" fill="#F53333"/>
                            </svg>
                        </div>
                    </div>
                    <div v-else class="flex flex-col items-center mx-auto w-full border border-theme-grey rounded" @dragenter.prevent="dragging=true" @dragover.prevent="dragging=true"  @dragleave.prevent="dragging=false" @drop.prevent="addFile" draggable="false">
                        <div class="flex flex-col w-[55px] m-5 rounded-tl-xl rounded-bl-xl">
                            <label :for="id" class="px-2 self-center cursor-pointer">
                                <div class="self-center input-wrapper">
                                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.9987 37.6673V21.0007H0.332031V17.0007H16.9987V0.333984H20.9987V17.0007H37.6654V21.0007H20.9987V37.6673H16.9987Z" fill="#1C1D21"/>
                                    </svg>
                                </div>
                            </label>
                        </div>
                        <input :ref="id" :id="id" class="hidden" type="file" @change="addFile" />
                        <p class="text-xs font-normal tracking-wider text-theme-878686 text-center px-1 pb-2">{{uploadMsg?uploadMsg:'Drag &amp; Drop your csv or xlsx file here or click on”+” to select from device directly'}}</p>
                    </div>
                    <div v-if="invalid" class="text-xs font-normal tracking-wider text-theme-red text-center pt-2 pb-2">Only xlsx or csv file allowed!</div>
                </div>
            </div>
    </div>
</div>
</template>
<script>
import { defineComponent, ref } from 'vue'


export default defineComponent({
    props: ['reset', 'id', 'uploadMsg'],
    setup() {
        const name = ref('')
        const invalid = ref(false)
        const validFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv']

        return {name, invalid, validFileTypes}
    },
    watch: {
        reset(){
            this.name = ''
        }
    },
    methods: {
        async addFile(e){
            let fileList = e.target.files || e.dataTransfer.files
            if(fileList){
                if(this.validFileTypes.includes(fileList[0].type)){
                    this.invalid = false
                    this.name = fileList[0].name
                    this.$emit('change', {data: fileList[0], name: fileList[0].name})
                }else{
                    this.invalid = true
                }
            }
        },
        
        deleteImage(){
            this.name = ''
            this.$emit('change', '')
        },
    }
})
</script>
