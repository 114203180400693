<template>
    <div class="rounded bg-light-black">
        <Form @submit="submit" :validation-schema="schema" autocomplete="off">
            <div class="bg-theme-green px-4 py-2  rounded-t-xl text-white">
                <div class="flex justify-between items-center">
                    <div class="font-bold tracking-wider leading-tight text-2xl mt-0">New Employee</div>
                    <div class="flex items-center">
                        
                        <!-- <a @click.prevent="close" class="flex justify-end mr-2 cursor-pointer"> -->
                            <!-- <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.30039 19.1004L0.900391 17.7004L8.60039 10.0004L0.900391 2.30039L2.30039 0.900391L10.0004 8.60039L17.7004 0.900391L19.1004 2.30039L11.4004 10.0004L19.1004 17.7004L17.7004 19.1004L10.0004 11.4004L2.30039 19.1004Z" fill="white"/>
                            </svg> -->
                        <!-- </a> -->
                        <div class="flex justify-between">
                            <button class="uppercase inline-block bg-white hover:bg-gray-300 mr-2 text-secondary rounded px-4 py-2 font-medium tracking-widest" >Save</button>
                            <span @click.prevent="close" class="cursor-pointer rounded-md  text-white py-2 hover:opacity-70">CLOSE</span>
                        </div>
                        
                        <!-- <button @click.prevent="close" class="cursor-pointer rounded-md  text-white py-2 px-4  hover:opacity-70 mr-3">CLOSE</button> -->
                    </div>
                </div>
            </div>
            <div class="grid xl:grid-cols-2  grid-cols-1 py-2">
                <div class="w-full">
                    <label class="block p-2">
                        <Field v-slot="{field, errors, errorMessage}" name="name" v-model="form.name" >
                            <div :class="'text-xs font-normal' + labelClass(field.value, errorMessage)">Name:</div>
                            <input class="block w-full rounded bg-gray-black" placeholder="E.g. Han Solo" autocomplete="off" :class="inputClass(field.value, errorMessage)" v-bind="field" type="text" />
                            <div v-if="errors.length>0" class="text-sm"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        </Field>
                    </label>
                </div>

                <div class="w-full">
                    <label class="block p-2">
                        <Field  v-slot="{field, errors, errorMessage}" name="email" v-model="form.email" >
                            <div :class="'text-xs font-normal' + labelClass(field.value, errorMessage)">Email:</div>
                            <input autocomplete="off" class="block w-full rounded bg-gray-black" placeholder="E.g. Hanz@zmail.com" :class="inputClass(field.value, errorMessage)" v-bind="field" type="text" />
                            <div v-if="errors.length>0" class="text-sm" :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        </Field>
                    </label>
                </div>
                
                <div class="w-full">
                    <label class="block p-2 ">
                        <Field  v-slot="{field, errors, errorMessage}" name="phone" v-model="form.phone"  >
                            <div class="text-xs font-normal" :class="labelClass(field.value, errorMessage)">Phone:</div>
                            <input autocomplete="off" placeholder="E.g. 416-124-1353" class="block w-full rounded bg-gray-black" :class="inputClass(field.value, errorMessage)" v-model="form.phone" @input="acceptNumber" type="text" />
                            <div v-if="errors.length>0" class="text-sm"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        </Field>
                    </label>
                </div>
                <div class="w-full">
                    <label class="block p-2">
                        <Field v-slot="{field, errors, errorMessage}" name="pin" v-model="form.pin" >
                            <div class="relative">
                                <div :class="'text-xs font-normal ' + labelClass(field.value, errorMessage)">PIN Code:</div>
                                
                                <div class="relative">
                                    <input name="pin" autocomplete="off" placeholder="****" class="inline-block w-full rounded bg-gray-black pr-[30px]" :class="inputClass(field.value, errorMessage)" v-bind="field" :type="pinVisibility?'number':'password'" />
                                    <mdicon class="cursor-pointer inline-block absolute z-40 right-[10px] top-[9px]" :name="pinVisibility ? 'eye' : 'eye-off'" @click.prevent="toggleVisibility" />
                                </div>
                                
                                <!-- <mdicon v-if="pinVisibility" class="cursor-pointer inline-block absolute z-40 right-[10px] top-8" name="eye-off" @click.prevent="toggleVisibility" />
                                <mdicon v-else class="cursor-pointer inline-block absolute z-40 right-[10px] top-8" name="eye" @click.prevent="toggleVisibility" /> -->

                                <div v-if="errors.length>0" class="text-sm"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                            </div>
                        </Field>
                    </label>
                </div>
                <div class="w-full">
                    <label class="block p-2">
                        <Field  v-slot="{field, errors, errorMessage}" name="role" v-model="form.role" >
                            <div :class="'text-xs font-normal' + labelClass(field.value, errorMessage)">Role:</div>
                            <select class="w-full block rounded bg-gray-black" :class="inputClass(field.value, errorMessage)" v-bind="field" >
                                <option value="" class="text-[#6b7280]"  >Select One</option>
                                <option v-for="role in roles" :key="role.id" :value="role.id">{{role.role}}</option>
                            </select>
                            <div v-if="errors.length>0" class="text-sm"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        </Field>
                    </label>
                </div>
                <div class="w-full">
                    <label class="block p-2">
                        <div :class="'text-xs font-normal' ">Locations</div>
                        <select-checkbox mode="tags" class="bg-gray-black border border-gray-500" placeholder="Select One" v-model="form.location" :options="options">
                            <template v-slot:placeholderIcon >
                                <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.75 18.025C10.9667 16.0083 12.6042 14.1792 13.6625 12.5375C14.7208 10.8958 15.25 9.45 15.25 8.2C15.25 6.23333 14.6208 4.625 13.3625 3.375C12.1042 2.125 10.5667 1.5 8.75 1.5C6.93333 1.5 5.39583 2.125 4.1375 3.375C2.87917 4.625 2.25 6.23333 2.25 8.2C2.25 9.45 2.79167 10.8958 3.875 12.5375C4.95833 14.1792 6.58333 16.0083 8.75 18.025ZM8.75 20C6.06667 17.7167 4.0625 15.5958 2.7375 13.6375C1.4125 11.6792 0.75 9.86667 0.75 8.2C0.75 5.7 1.55417 3.70833 3.1625 2.225C4.77083 0.741667 6.63333 0 8.75 0C10.8667 0 12.7292 0.741667 14.3375 2.225C15.9458 3.70833 16.75 5.7 16.75 8.2C16.75 9.86667 16.0875 11.6792 14.7625 13.6375C13.4375 15.5958 11.4333 17.7167 8.75 20Z" fill="white"/>
                                </svg>
                            </template>
                        </select-checkbox>
                    </label>
                </div>
                <div class="w-full" v-if="vg_isUserTypeSuper">
                    <label class="block p-2">
                        <Field  v-slot="{field, errors, errorMessage}" name="userType" v-model="form.userType" >
                            <div :class="'text-xs font-normal' + labelClass(field.value, errorMessage)">User Type:</div>
                            <select class="w-full block rounded bg-gray-black" :class="inputClass(field.value, errorMessage)" v-bind="field" >
                                <option value="super">Super</option>
                                <option value="client">Client</option>
                            </select>
                            <div v-if="errors.length>0" class="text-sm"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        </Field>
                    </label>
                </div>
            </div>
        </Form>
    </div>
</template>

<script>
import {ref, defineComponent, computed} from "vue"
import { Form, Field, ErrorMessage } from 'vee-validate';
import {mapGetters, mapMutations} from 'vuex'
import EmployeeApi from "@/controllers/EmployeeApis";
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';
import ObjectHelper from '@/helpers/object'
import SelectCheckbox from '@/components/form/SelectCheckbox.vue'

export default defineComponent({
    components: {
        Form,
        Field,
        ErrorMessage,
        SelectCheckbox
    },
    setup(){
        const snackbar = useSnackbar();
        const roles = ref([])
        const form = ref({
            name: '',
            email: '',
            phone: '',
            userType: 'client',
            role: '',
            pin: '',
            location: []
        })
        const schema = {
            name: 'required',
            email: 'required|email',
            phone: 'phone',
            pin: 'pin',
            role: 'required'
        }
        const pinVisibility = ref(true)
        
        return { snackbar, roles, form, schema, pinVisibility}
    },
    mounted(){
        this.listRole()
    },
    computed:{
        options() {
            return this.vg_location.map(each => ({id:each.id, name:each.name}))
        },
        ...mapGetters({
            vg_location: 'location/list',
            vg_isUserTypeSuper: 'auth/isUserTypeSuper'
        })
    },
    methods: {
        async listRole(){
            let {data: {payload}} = await EmployeeApi.listRoles()
            this.roles = payload
        },
        labelClass(value, error){
            if(value && error){
                return 'text-theme-red'
            }else if(error){
                return 'text-theme-orange'
            }else if(value){
                return 'text-theme-green'
            }
        },
        inputClass(value, error){
            if(value && error){
                return 'border-theme-red'
            }else if(error){
                return 'border-theme-orange'
            }else if(value){
                return 'border-theme-green'
            }
        },
        errorClass(value, error){
            if(value && error){
                return 'text-theme-red'
            }else if(error){
                return 'text-theme-orange'
            }
        },
        toggleVisibility(){
            this.pinVisibility = !this.pinVisibility
        },
        acceptNumber() {
            var x = this.form.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.form.phone = !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        async submit(form, { resetForm }){
            this.vm_spinner(true)
            ObjectHelper.removeEmptyString(this.form)
            this.form.pin = this.form.pin?this.form.pin.toString():''
            this.form.username = this.form.email
            let res = await EmployeeApi.createUser(this.form)
            let res1 = null
            let res2 = null
            let err = null
            if(res.data && res.data.payload && res.data.payload.id){
                res1 = await EmployeeApi.addLocationsToUser(res.data.payload.id, {locationIds: this.form.location})
                res2 = await EmployeeApi.assignRoleToUser(res.data.payload.id, {roleId: this.form.role})
            }
             if(res1 && res1.status!==201){
                err = res1
            }else if(res2 && res2.status!==201){
                err = res2
            }else{
                err = res
            }
            this.vm_spinner(false)
            this.snackbar.add({
                type: 'info',
                text: res.status == 201 ? res.data.message : Helpers.catchErrorMessage(err.response.data),
                duration: 3000,
            })
            if(res.status==201){
                this.form.location = []
                resetForm()
                this.$emit('added')
            }
        },
        close(){
            this.$emit('close')
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }

})
</script>