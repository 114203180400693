<template>

    <block-loading v-if="loading" />
    <table-lite
      v-else
      :class="'admin-table'"
      :is-loading="table.isLoading"
      :is-slot-mode='true'
      :columns="columns"
      :rows="table.rows"
      :rowClasses="customRowClass"
      :total="table.totalRecordCount"
      :sortable="table.sortable"
      @is-finished="table.isLoading = false"
      :page-size='table.pagesize'
      :page="table.page"
      @do-search="doSearch"
      :pageOptions='table.pageOptions'
      :has-checkbox="false"
      @row-clicked="selectedRow"
      :checked-return-type="'id'"
      :is-hide-paging="false"
      :pageSizeChangeLabel="'pageSizeChangeLabel'"
      :messages="{
        pagingInfo: 'Total: {2}',
        pageSizeChangeLabel: 'Items per Page',
        gotoPageLabel: '',
        noDataAvailable: 'No records found!',
      }"
    >
      <template v-slot:amount="data">
        <span class="cursor-pointer" :class="data.value.transactionType=='remove cash'?'text-[#FF6868]':(data.value.transactionType=='bank deposit'?'text-[#E7A644]':'')">{{ getAmount(data.value) }}</span>
      </template>
      <template v-slot:transactionType="data">
        <span>{{ucWordTransactionType(data.value.transactionType)}}</span>
      </template>
      <template v-slot:createdAt="data">
        <span>{{formatDate(data.value.createdAt)}}</span>
      </template>
      <template v-slot:createdByName="data">
        <span>{{ucWord(data.value.createdByName)}}</span>
      </template>
    </table-lite>

</template>

<script>
import  { defineComponent , ref} from "vue";
import { mapGetters, mapActions } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import Env from '@/env';
import Helpers from '@/helpers';
import VaultApis from "@/controllers/VaultApis";
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'
import moment from 'moment-timezone'
import debounce from 'lodash/debounce'

export default defineComponent({
  name: "VaultList",
  components: {
  },
  props: {
    current: {
      type: String,
      required: false
    },
    iscomponentactive: {
      type: Boolean,
      required: false,
    },
    selectedCallback: {
      type: Function,
      required: true,
    },
    clearComponent: {
      type: Function,
      required: true,
    },
    search: {
      type: String,
      required: false
    },
    fromDate: {
      type: Date,
      required: false
    },
    toDate: {
      type: Date,
      required: false
    }
  },
  setup() {
    const snackbar = useSnackbar();
    const activeItem = ref({});
    const loading = ref<Boolean>(false);
    const columnsMain = ref([
      {
        label: "Amount",
        field: "amount",
        sortable: false,
      },
      {
        label: "Transaction Type",
        field: "transactionType",
        sortable: false,
        width: ''
      },
      {
        label: "Date",
        field: "createdAt",
        sortable: false,
        width: ''
      },
      {
        label: "User",
        field: "createdByName",
        sortable: false,
        width: ''
      },
      {
        label: "Notes",
        field: "note",
        sortable: false,
        width: ''
      },
    ]);

    const table = ref({
      isLoading: false,
      rows: [],
      totalRecordCount: 0,
      count: 0,
      sortable: {
        order: "",
        sort: "",
      },
      pagesize: Env.defaultPageSize,
      pageOptions: Env.pageList,
      page: 1,
    });
 
    return  {
      table,
      columnsMain,
      activeItem,
      loading,
      snackbar,
    };
  },

  async mounted() {
    this.loading = true;
    await this.fetchList(this.table.page);
    this.loading = false;
    this.emitter.on("refresh-vault-list", data => {
      this.fetchList(this.table.page);
    });
  },

  beforeDestroy() {
    this.emitter.off('refresh-vault-list', () => {});
  },

  watch: {
    vg_selectedLocation(){
      this.fetchList(1)
    },
    search() {
      this.fetchList(1)
    },
    fromDate(){
      this.fetchList(1)
    },
    toDate(){
      this.fetchList(1)
    }
  },

  computed: {
    ...mapGetters({
      vg_selectedLocation: 'location/selected'
    }),

    /**
     * Compute columns
     */
    columns() {
      if(this.iscomponentactive){
        let removeFields= ['action_threshold', 'date_restriction', 'reward_type'];
        return this.columnsMain.filter(item => {
            if( removeFields.indexOf(item.field) == -1 ) {
              return item;
            }
        })
      }
      return this.columnsMain;
    },
   
  },

  methods: {
    /**
     * Fetch list
     */
    fetchList: debounce(async function(page) {
      this.table.isLoading = true;
      this.table.page = page
      let param = `page=${page}&perPage=${this.table.pagesize}`
      param += `&closed=${this.current?'true':'false'}`
      if(this.search && this.search.length>2){
        param += `&search=${this.search}`
      }
      if(this.fromDate){
        param += '&fromDate='+moment(this.fromDate).format('YYYY-MM-DD')
      }
      if(this.toDate){
        param += '&toDate='+moment(this.toDate).format('YYYY-MM-DD')
      }
      let res = await VaultApis.list(this.vg_selectedLocation.id, param);
      if(res.status==200){
        this.table.rows = res.data.payload.data
        this.table.totalRecordCount = res.data.payload.totalRecords
      }else{
        this.table.rows = []
        this.table.totalRecordCount = 0
      }
      this.table.isLoading = false;
      
    }, 400),

    /**
     * Select the item
     */
    setActive(item) {
      // Select unselect the item
      if(this.activeItem.id) {
        let oldactive = document.getElementsByClassName('thisid_'+this.activeItem.id);
        if(oldactive.length > 0){
          oldactive[0].classList.remove("active-row");
        }
      }
      this.activeItem = item;
      let newActive = document.getElementsByClassName('thisid_' + this.activeItem.id);
      if(newActive.length > 0){
        newActive[0].classList.add("active-row");
      }
      this.selectedCallback(item);
    },

    /**
     * Create custom class on tr for active colors
     */
    customRowClass(item) {
      return 'thisid_' + item.id;
    },

    selectedRow(obj){
      this.setActive(obj)
    },
 
    /**
     * Search now
     */
    doSearch(offset, limit, order, sort) {
      this.table.sortable.order = order
      this.table.sortable.sort = sort
      this.table.pagesize = limit;
      let page = (offset/limit)+1
      this.fetchList(page);
    },

    getAmount(item){
      if(item.transactionType=='remove cash'){
        return item.amount?'-$'+item.amount.toFixed(2):'0.00'
      }else if(item.transactionType=='bank deposit'){
        return item.amount?'-$'+item.amount.toFixed(2):'0.00'
      }else{
        return item.amount?'$'+item.amount.toFixed(2):'0.00'
      }
    },

    ucWord(words){
      if(!words) return
      return startCase(lowerCase(words))
    },
    ucWordTransactionType(words){
      if(!words) return
      if(words=='safe audit'){
        return 'Safe Audit'
      }else if(words=='close vault'){
        return 'Close Vault'
      }else{
        return startCase(lowerCase(words))
      }
    },

    formatDate(date){
      if(!date) return
      let defaultTimeZone = this.vg_selectedLocation.timezone?this.vg_selectedLocation.timezone:'America/Toronto';
      return moment(date).tz(defaultTimeZone).format('YYYY-MM-DD');
    },

    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })

  }
});
</script>

