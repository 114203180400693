/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import Env from '@/env';
import AuthApis from '@/controllers/AuthApis';
const apiVersion = 'api';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /**
   * Fetch Business of a user
   */
  fetchUserBusinesses() {
    const url = `${Env.loginUrl}/${apiVersion}/businesses/all?includeBranding=1`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Create business
   */
  createBusiness(data) {
    const url = `${Env.loginUrl}/${apiVersion}/businesses/create`;
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * Update business
   */
  updateBusiness(businessId, data) {
    const url = `${Env.loginUrl}/${apiVersion}/businesses/${businessId}/update`;
    return AuthApis.patchApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  }


}