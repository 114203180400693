<template>
  <div class="rounded-[10px] mb-4 bg-[#2B2C32] w-full pb-4">
    <div class="flex">
      <Datepicker ref="datePicker" format="yyyy-MM-dd" placeholder="Select Date Range" class="mb-5 w-[260px]" v-model="dateRange" range :enable-time-picker="false" />
      <div  class="text-theme-green cursor-pointer pt-2 px-3" @click="clearIt">Clear All</div>
    </div>
    <new-table-lite
      :class="'admin-template'"
      :is-loading="table.loading"
      :is-slot-mode='true'
      :columns="columns"
      :rows="table.rows"
      :rowClasses="customRowClass"
      :total="table.totalRecordCount"
      :sortable="table.sortable"
      :page-size='table.pagesize'
      @do-search="doSearch"
      @row-clicked="setActive"
      :pageOptions='table.pageOptions'
      :has-checkbox="false"
      :checked-return-type="'id'"
      :is-hide-paging="false"
      :pageSizeChangeLabel="'pageSizeChangeLabel'"
      :messages="{
        pagingInfo: 'Total: {2}',
        pageSizeChangeLabel: 'Items per Page',
        gotoPageLabel: '',
        noDataAvailable: 'No records found!',
      }"
    >
      <template v-slot:status="data">
        <span class="px-4 py-2 rounded bg-theme-green" v-if="data.value.status==='completed'">Completed</span>
        <span class="px-4 py-2 rounded bg-theme-red" v-else>In Progress</span>
      </template>
      <template v-slot:startedOn="data">
        <div v-if="data.value.startedOn">{{ formatDate(data.value.startedOn) }}</div>
      </template>
      <template v-slot:completedBy="data">
        <div>{{ data.value.completedBy? data.value.completedBy:'-' }}</div>
      </template>
      <template v-slot:completedOn="data">
        <div>{{ data.value.completedOn? formatDate(data.value.completedOn):'-' }}</div>
      </template>
    </new-table-lite>
  </div>
</template>

<script>
import  { defineComponent , ref} from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import moment from 'moment';
import Env from '@/env';
import ProductAuditApi from '@/controllers/ProductAuditApis'
import NewTableLite from '@/components/table/tableLite.vue'
import Datepicker from '@vuepic/vue-datepicker';

export default defineComponent({
  name: 'ProductAuditList',
  components: {
    NewTableLite,
    Datepicker
  },
  props: {
    iscomponentactive: {
      type: Boolean,
      required: false,
    },
    selectedCallback: {
      type: Function,
      required: true,
    },
    clearComponent: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const snackbar = useSnackbar();
    const activeItem = ref({});
    const dateRange = ref('')
    const columnsMain = ref([
      {
        label: "Status",
        field: "status",
        sortable: true,
      },
      {
        label: "Created By",
        field: "creator",
        sortable: false,
      },
      {
        label: "Started On",
        field: "startedOn",
        sortable: false,
      },
      {
        label: "Completed By",
        field: "completedBy",
        sortable: false,
      },
      {
        label: "Completed On",
        field: "completedOn",
        sortable: false,
      },
    ]);

    const table = ref({
      loading: false,
      rows: [],
      totalRecordCount: 0,
      count: 0,
      sortable: {
        order: "",
        sort: "",
      },
      pagesize: Env.defaultPageSize,
      pageOptions: Env.pageList,
      page: 1
    });

    return  {
      table,
      dateRange,
      columnsMain,
      activeItem,
      snackbar,
    };
  },

  async mounted() {
    this.fetchList(this.table.page);
    this.emitter.on("refresh-product-audit-list", data => {
      this.fetchList(this.table.page);
    });
  },

  beforeDestroy() {
   this.emitter.off('refresh-product-audit-list', () => {})
  },

  watch: {
    'vg_selectedLocation'(val) {
      // if location selected is changed fetch the data
      this.fetchList(this.table.page);
    },
    dateRange(){
      this.doSearch()
    }
  },

  computed: {
    ...mapGetters({
      vg_selectedLocation: 'location/selected'
    }),

    /**
     * Compute columns
     */
    columns() {
      if(this.iscomponentactive){
        let removeFields= ['startedOn', 'completedBy', 'completedOn'];
        return this.columnsMain.filter(item => {
            if( removeFields.indexOf(item.field) == -1 ) {
              return item;
            }
        })
      }
      return this.columnsMain;
    },
  },

  methods: {
    clearIt(){
      this.$refs.datePicker.clearValue()
    },
    /**
     * select row
     */
    selectedRow(item) {
       this.$router.push({
          name: 'ProductAuditsView',
          params: { id: item.id }
       })
    },
    /**
     * Fetch list
     */
    async fetchList(page) {
      this.table.loading = true
      let startRange = (this.dateRange?.[0]) ?(moment(this.dateRange[0]).format('YYYY-MM-DD')):''
      let endRange = (this.dateRange?.[1]) ?(moment(this.dateRange[1]).format('YYYY-MM-DD')) : ''
      let res = await ProductAuditApi.fetchAll(page?page:1, this.table.pagesize, this.table.sortable.order, this.table.sortable.sort, startRange, endRange);
      this.table.rows = res.data.payload?.inventoryAudits;
      this.table.totalRecordCount = (res.status == 200) ? res.data.payload?.totalRecords : 0
      this.table.page = page;
      this.table.loading = false
    },

    /**
     * Select the item
     */
    setActive(item) {
      this.selectedCallback(item);
    },

    /**
     * Create custom class on tr for active colors
     */
    customRowClass(item) {
      return 'cursor-pointer thisid_' + item.id;
    },

    formatDate(date){
      if(!date) return

      return moment(date).format('YYYY-MM-DD, hh:mm A')
    },

    /**
     * Search now
     */
    doSearch(offset, limit, order, sort) {
      let page = (offset/limit)+1
      this.table.sortable.order = order
      this.table.sortable.sort = sort
      this.table.pagesize = limit;
      this.table.page = page;
      this.fetchList(page);
    },

    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })

  }
});
</script>

