
import { defineComponent, ref} from "vue";
import ProductList from '@/views/Products/MasterProduct/List/ProductList.vue'
import editProduct from '@/views/Products/MasterProduct/Edit/Edit.vue'
import PurchaseOrderUploadPop from '@/views/Products/MasterProduct/List/OrderUploadPop.vue'
import ProductImportPop from '@/views/Products/MasterProduct/List/ProductImportPop.vue'


export default defineComponent({
    components: {
        ProductList,
        editProduct,
        PurchaseOrderUploadPop,
        ProductImportPop
    },
    setup(){
        // list 
        const listType = ref({
            component: 'ProductList', 
            data: []
        })
        const purchaseOrderUploadPop = ref(false)
        const importProductPop = ref(false)
        const add = ref<boolean>(false)
        const edit = ref(false)
        const editItem = <any>ref({})

        return {listType, purchaseOrderUploadPop, importProductPop, add, edit, editItem}
    },
    mounted(){
        this.emitter.on("closeAddEdit", this.closeEdit);
    },
    beforeDestroy(){
        this.emitter.off("closeAddEdit", this.closeEdit);
    },
    methods: {
        toggleAddEdit(status){
            this.editItem = status.editItem
            this.edit = status.edit
            this.add = status.add
        },
        closeEdit(){
            this.edit = false
        },
        updatedEditItem(item){
            this.editItem = item
        },
        togglePurchaseOrderUploadPop(status){
            this.purchaseOrderUploadPop = status
        },
        closePurchaseOrderUpload(){
            this.purchaseOrderUploadPop = false
        },
        uploadedPayload(){
            this.closePurchaseOrderUpload()
        },
        toggleProductImportPop(status){
            this.importProductPop = status
        },
        closeImportProductPop(){
            this.importProductPop = false
        },
        uploadedProduct(){
            this.closeImportProductPop()
        }
    }
})
