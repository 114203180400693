/**
 * @module Promotion 
 *  Apis must need auth details to trigger url - AuthApi
 */

import store from '@/store/index';
import Env from '@/env';
import NormalApis from '@/controllers/NormalApis';
import AuthApis from '@/controllers/AuthApis';
const apiVersion = 'api';

export default {
    /**
      * Get Promotion
      */
    getPromotion() {
        const url = `${Env.loginUrl}/${apiVersion}/promotions/all`;
        return AuthApis.getApi(url, null)
          .then(res => res)
          .catch(err => err);
    },

    /**
      * Create Promotion
      */
    createPromotion(data) {
        const url = `${Env.loginUrl}/${apiVersion}/promotions/create`;
        return AuthApis.postApi(url, null, data)
          .then(res => res)
          .catch(err => err);
    },

    /**
      * Assing location to Promotion
      */
    assignLocationToPromotion(promotionId, data) {
      const url = `${Env.loginUrl}/${apiVersion}/promotions/${promotionId}/assign-locations`;
      return AuthApis.postApi(url, null, data)
        .then(res => res)
        .catch(err => err);
    },

    getPromotionLocation(promotionId){
      const url = `${Env.loginUrl}/${apiVersion}/promotions/${promotionId}/get-locations`;
      return AuthApis.getApi(url, null)
        .then(res => res)
        .catch(err => err);
    },

    /**
      * Update Promotion
      */
    updatePromotion(promotionId, data) {
        const url = `${Env.loginUrl}/${apiVersion}/promotions/${promotionId}/update`;
        return AuthApis.patchApi(url, null, data)
          .then(res => res)
          .catch(err => err);
    },

    /**
      * Remove Promotion
      */
    removePromotion(promotionId) {
        const url = `${Env.loginUrl}/${apiVersion}/promotions/${promotionId}/remove`;
        return AuthApis.deleteApi(url, null)
          .then(res => res)
          .catch(err => err);
    }
}