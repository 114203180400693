<template>

    <block-loading v-if="loading" />
    <new-table-lite
      v-else
      :class="'admin-table-reward'"
      :is-loading="table.isLoading"
      :is-slot-mode='true'
      groupingKey="tier_name"
      hasGroupToggle= true
      :columns="columns"
      :rows="table.rows"
      :rowClasses="customRowClass"
      :total="table.totalRecordCount"
      :sortable="table.sortable"
      @is-finished="table.isLoading = false"
      :page-size='table.pagesize'
      @do-search="doSearch"
      :pageOptions='table.pageOptions'
      :has-checkbox="false"
       @row-clicked="setActive"
      :checked-return-type="'id'"
      :is-hide-paging="false"
      :pageSizeChangeLabel="'pageSizeChangeLabel'"
      :messages="{
        pagingInfo: 'Total: {2}',
        pageSizeChangeLabel: 'Items per Page',
        gotoPageLabel: '',
        noDataAvailable: 'No records found!',
      }"
    >
      <template v-slot:tier_name="data">
        <span class="capitalize cursor-pointer text-theme-green">{{ data.value.tier_name}}</span>
      </template>
      <template v-slot:rewards="data">
        <span class="capitalize">{{ data.value.rewards.length}} {{data.value.rewards.length==1?'Reward':'Rewards'}}</span>
      </template>
      <template v-slot:min_points="data">
        <span v-if="data.value.min_points">{{ data.value.min_points.toLocaleString('en-US')}} points</span>
        <span v-else>Free</span>
      </template>
       <template v-slot:is_active="data">
        <span>
          <label class="flex items-center cursor-pointer xl:mt-2" @click.stop="updateItem(data.value)">
            <input type="checkbox" v-model="data.value.is_active" class="hidden">
            <div :class="'relative toggle-bg  border-2  h-6 w-11 rounded-full ' + ((data.value.is_active) ? 'bg-theme-green border-theme-green' : 'bg-[#F53333] border-[#F53333]')"></div>
            <span class="ml-2 text-white text-sm font-medium uppercase" :class="(data.value.is_active)  ? 'text-theme-green' : 'text-[#F53333]'">{{data.value.is_active ? 'On' : 'Off'}}</span>
          </label>
        </span>
      </template>
 
      
    </new-table-lite>

</template>

<script>
import Helpers from '@/helpers';
import  { defineComponent , ref} from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import RewardTierApis from '@/controllers/RewardTierApis';
import NewTableLite from '@/components/table/tableLite.vue'
import Env from '@/env';
import debounce from 'lodash/debounce'

export default defineComponent({
  name: 'ProductSalesList',
  components: {
    NewTableLite
  },
  props: {
    iscomponentactive: {
      type: Boolean,
      required: false,
    },
    selectedCallback: {
      type: Function,
      required: true,
    },
    clearComponent: {
      type: Function,
      required: true,
    }
  },
  setup() {
    const snackbar = useSnackbar();
    const confirmStatusObj = ref({
      modal: false,
      obj: {
        status: Boolean,
        id: Number,
      },
    });
    const activeItem = ref({});
    const loading = ref(false);
    const columnsMain = ref([
      {
        label: "Name",
        field: "tier_name",
        sortable: false,
        columnClasses:'w-[200px]'
      },
      {
        label: "# of Rewards",
        field: "rewards",
        sortable: false,
        columnClasses:'w-[400px]'
      },
      {
        label: "Reward Cost",
        field: "min_points",
        sortable: false,
      },
      {
        label: "Status",
        field: "is_active",
        sortable: false,
      },
   
    ]);

    const table = ref({
      isLoading: false,
      rows: [],
      totalRecordCount: 0,
      sortable: {
        order: "name",
        sort: "asc",
      },
      groupingDisplay: function(){
        return (
          'ok'
        );
      },
      pagesize: Env.defaultPageSize,
      pageOptions: Env.pageList,
      count: 0,
      page: 1,
    });

    return  {
      table,
      columnsMain,
      activeItem,
      loading,
      confirmStatusObj,
      snackbar,
    };
  },

  async mounted() {
    this.loading = true;
    await this.fetchList(this.table.page);
    this.loading = false;
    this.emitter.on("refresh-reward-list", data => {
      this.fetchList(this.table.page);
    });
  },

  beforeDestroy() {
   this.emitter.off('refresh-reward-list', () => {})
  },

  watch: {
    'vg_selectedLocation'(val) {
      // if location selected is changed fetch the data
      this.fetchList(this.table.page);
    },
  },

  computed: {
    ...mapGetters({
      vg_selectedLocation: 'location/selected'
    }),

    /**
     * Compute columns
     */
    columns() {
      if(this.iscomponentactive){
        let removeFields= ['min_points', 'is_active'];
        return this.columnsMain.filter(item => {
            if( removeFields.indexOf(item.field) == -1 ) {
              return item;
            }
        })
      }
      return this.columnsMain;
    },
  },

  methods: {
    /**
     * Fetch list
     */
    async fetchList(page) {
      this.table.isLoading = true;
      let res = await RewardTierApis.fetchAll(true);
      this.table.isLoading = false;
      this.table.rows = (res.status == 200) ? res.data.results : []; 
      this.table.totalRecordCount = (res.status == 200) ? res.data.total_results : 0
      this.table.page = page;
      this.$emit('updateRewardTierCount', this.table.totalRecordCount)
    },

    /**
     * Select the item
     */
    setActive(item) {
      // Select unselect the item
      if(this.activeItem.id) {
        let oldactive = document.getElementsByClassName('thisid_'+this.activeItem.id);
        if(oldactive.length > 0){
          oldactive[0].classList.remove("active-row");
          let subGroup = document.getElementsByName('vtl-group-'+this.activeItem.tier_name)
          for(let i=0; i<subGroup.length; i++){
            subGroup[i].classList.remove('active-sub-row')
          }
        }
      }
      this.activeItem = item;
      let newActive = document.getElementsByClassName('thisid_' + this.activeItem.id);
      if(newActive.length > 0){
        newActive[0].classList.add("active-row");
        let subGroup = document.getElementsByName('vtl-group-'+item.tier_name)
        for(let i=0; i<subGroup.length; i++){
          subGroup[i].classList.add('active-sub-row')
        }
      }
      this.selectedCallback(item);
    },

    /**
     * Create custom class on tr for active colors
     */
    customRowClass(item) {
      return 'thisid_' + item.id;
    },

    updateItem:debounce(async function(item){
      if(this.can_edit_membership_details){
        let rewardArr = []
        for(let i=0; i<item.rewards.length; i++){
          let obj = {
            id: item.rewards[i].id,
            type: item.rewards[i].type,
            status: item.rewards[i].status
          }
          if(item.rewards[i].type=='item'){
            obj.sku = item.rewards[i].sku
            obj.name = item.rewards[i].name
          }else if(item.rewards[i].type=='discount_percentage'){
            obj.discount = item.rewards[i].discount
          }else if(item.rewards[i].type=='custom'){
            obj.name = item.rewards[i].name
            obj.image = item.rewards[i].image
          }
          rewardArr.push(obj)
        }
        let tier = {
          tier_name: item.tier_name,
          min_points: item.min_points,
          is_active: item.is_active,
          rewards: rewardArr
        }
        let res = await RewardTierApis.update(item.id, tier);
        this.snackbar.add({
            type: 'info',
            text: res.status == 200 ? res.data.msg : res.response.data.msg,
            duration: 3000,
        })
      }
    }),

    /**
     * Search now
     */
    doSearch(offset, limit, order, sort) {
      let page = (offset/limit)+1
      this.table.sortable.order = order
      this.table.sortable.sort = sort
      this.table.pagesize = limit;
      this.fetchList(page);
      
    },

    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })

  }
});
</script>

