/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import Env from '@/env';
const apiVersion = 'api';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /**
   * get clover access token
   */
  generateCloverAccessToken(code) {
    const url = `${Env.loginUrl}/${apiVersion}/locations/generateCloverAccessToken?authCode=${code}`;
    return AuthApis.postApi(url, null, {})
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

}