/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import Env from '@/env';
const apiVersion = 'api/v1';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {


  /**
   * Create badge reward
   */
  create(badge_id, data) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/badges/${badge_id}/rewards`;
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Get a badge rewards 
   */
  getAllRewardsByBadgeID(badge_id) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/badges/${badge_id}/rewards?nopage=true`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Update badge reward
   */
  update(badge_id, reward_id, data) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/badges/${badge_id}/rewards/${reward_id}`;
    return AuthApis.patchApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Update badge reward
   */
  delete(badge_id, reward_id) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/badges/${badge_id}/rewards/${reward_id}`;
    return AuthApis.deleteApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


}