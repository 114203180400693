import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom-modal-wrap overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[100] md:inset-0 h-modal md:h-full justify-center items-center flex" }
const _hoisted_2 = { class: "relative rounded-xl shadow bg-theme-modalbg h-max max-h-[calc(100vh-100px)] overflow-y-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass('relative  pt-[80px] pb-[80px]  grid items-center p-4 w-full h-full md:h-[100vh] ' + (_ctx.size ? _ctx.size : 'max-w-5xl'))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "maincontent")
      ])
    ], 2)
  ]))
}