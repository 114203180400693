export default {
    namespaced: true,
    state: {
        details: {},
    },
    getters: {
        details: state => state.details,
    },
    mutations: {
        detailsMutate(state, data) {
        state.details = data.clover_response;
        },
    },
    actions: {
        setDetails(context, data) {
        context.commit('detailsMutate', data);
        },
    },
    };
  