
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
import LoyaltyProgramApis from "@/controllers/LoyaltyProgramApis";
import LoyaltyFaqApis from "@/controllers/LoyaltyFaqApis";
import LoyaltyFaqCategoryApis from "@/controllers/LoyaltyFaqCategoryApis";

import List from '@/views/Membership/Program/Faqs/List.vue';
import Add from '@/views/Membership/Program/Faqs/Add.vue';
import Update from '@/views/Membership/Program/Faqs/Update.vue';
import Nav from "@/views/Membership/Program/Nav.vue";
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';

  export default defineComponent({
  name: "FaqIndex",
  components: {
    Nav,
    List,
    Add,
    Update,
    
  },

  setup() {
    const hasLoyaltyProgramAndCategory = ref(false);
    const loading = ref(false);
    const loyaltyProgram = <any>ref({});
    const faqCategory = <any>ref({});
    const action = ref<object>({
      component: '',
      data: {},
    });
    const syncConfirm = ref({
      modal: false,
    })
    const snackbar = useSnackbar();
    return {
      snackbar,
      hasLoyaltyProgramAndCategory,
      action,
      loading,
      loyaltyProgram,
      faqCategory,
      syncConfirm
    };
  },


  watch : {
    'vg_selectedLocation'(val) {
      // if location selected clear component
      this.clearComponent();
    },
  },

  computed: {
    /**
     * Edit restriction
     */
    can_edit_membership_details() {
      return Helpers.getMyPermission('can_edit_membership_details')
    },

    ...mapGetters({
     vg_selectedLocation: 'location/selected'
    }),
  },

  async mounted() {
    this.loading = true;
    // first get program details, if not available create
    await this.getProgramAndCategory();
    // first get categories, if not available create
    this.loading = false;
    
  },

  beforeDestroy() {
  
  },
 
  methods: {
    /**
     * Clear action
     */
    clearComponent() {
      this.action = {
        component: '',
        data: {},
      };
      let activeItem = document.getElementsByClassName('active-row');
      if(activeItem.length > 0){
        activeItem[0].classList.remove("active-row");
      }
    },

    /**
     * Change add / update component
     */
    addUpdate(val: any) {
      this.action = val;
    },

    /**
     * Capture selected item
     */
    selectedItem(item) {
      this.addUpdate({
        component: 'Update', 
        data: item
      })
    },

    /**
     * Get program and category
     */
    async getProgramAndCategory() {
      let loyaltyProgramRes = await LoyaltyProgramApis.getProgramByCurrentActiveBusiness();
      if(loyaltyProgramRes.status == 200 || loyaltyProgramRes.status == 201) {
         this.loyaltyProgram = loyaltyProgramRes.data;
         // Now category 
        let loyaltyFaqCategoryRes = await LoyaltyFaqCategoryApis.getAll(loyaltyProgramRes.data.id);
        if(loyaltyFaqCategoryRes.status == 200) {
          if(loyaltyFaqCategoryRes.data.total_results > 0) {
            this.faqCategory = loyaltyFaqCategoryRes.data.results[0];
            this.hasLoyaltyProgramAndCategory = true;
          }
        } else {
          let loyaltyFaqCategoryCreateRes = await LoyaltyFaqCategoryApis.create(this.loyaltyProgram.id, {
            name: loyaltyProgramRes.data.name + ' Faq Category',
            desc: "Frequently asked questions",
            sort_order: 1
          });
          if(loyaltyFaqCategoryCreateRes == 201) {
            this.faqCategory = loyaltyFaqCategoryCreateRes.data;
            this.hasLoyaltyProgramAndCategory = true;
          }
        }
      }
    },

    /**
     * syncFaq return confirm modal
     */
     async syncfaqs() {
  
      this.va_spinner(true);
      let res = await LoyaltyFaqApis.sync(this.loyaltyProgram.id, this.faqCategory.id, this.loyaltyProgram.name);

      if(res.status == 201) {
        this.emitter.emit("refresh-faqs-list", true);
      }
      this.syncConfirm.modal = false

      this.snackbar.add({
        type: 'info',
        text: (res.status == 201 || res.status == 200) ? res.data.msg : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })

      this.va_spinner(false);

     },

    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  },
});

