<template>
  <div class="pb-[160px]">
    <div class="bg-theme-modal_inner_bg border-0 rounded-t-xl px-4 py-2 text-white">
      <div class="flex justify-between items-center">
        <h2 class="font-semibold leading-tight text-2xl mt-0">New Audit</h2>
        <div class="flex flex-col">
          <div class="flex justify-end items-center">
            <button @click.prevent="clearComponent()" class="cursor-pointer rounded-md bg-white text-theme-red py-2 px-4 hover:bg-theme-darkgreen hover:text-white mt-2 mr-3">
              CANCEL 
            </button>
          </div>
        </div>
      </div>

      <div class="flex gap-3 xl:flex-row flex-col items-start mb-4">
        <div class="w-full xl:w-1/2">
          <div class="input-wrapper">
            <label class="text-xs mb-1">Audit Name</label>
            <input readonly :value="currentData" class="placeholder-theme-878686 block border-none rounded bg-[#1C1D21] w-full text-sm text-theme-white" type="text">
          </div>
        </div>

        <div class="w-full xl:w-1/2">
          <div :class="'input-wrapper'">
              <label class="text-xs mb-1">Employee</label>
              <input readonly class="placeholder-theme-878686 block border-none rounded bg-[#1C1D21] w-full text-sm text-theme-white" type="text" v-model="employeeName">
          </div>
        </div>
      </div>
    </div>

    <div class="flex bg-[#2B2C32] px-4 py-2 ">
        <input readonly class="placeholder-theme-878686 bg-[#1C1D21] text-center block border-none rounded w-full text-xs h-12 text-theme-white" type="text" value="REGULAR">
    </div>

    <div class="pt-3 bg-[#2B2C32] px-[16px] py-[16px] rounded-b-lg">
        <div class="grid grid-cols-2 gap-2 mt-2 mb-1">
            <button @click="select('allProducts')" class="w-full h-[52px] border border-theme-green rounded uppercase uppercase py-2" :class="createType=='allProducts'?'bg-theme-green/[0.33]':''">All Products</button>
            <button @click="select('custom')" class="w-full h-[52px] border border-theme-green rounded uppercase uppercase py-2" :class="createType=='custom'?'bg-theme-green/[0.33]':''">Custom</button>
        </div>

        <div v-if="createType=='custom'" class="mt-2 rounded h-[300px] overflow-auto">
            <block-loading v-if="loading || category?.length==0" />
            <div v-else>
                <div v-for="each in category" :key="each.id" class="flex items-center justify-between p-2 font-bold even:bg-theme-modalbg">
                    <div @click="selectCategory(each.id)" :class="each.parent?'ml-4':''" class="flex items-center cursor-pointer">
                        <div>
                            <svg v-if="isCategoryAdded(each.id)" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.2 17.55C1.5 17.85 1.85 18 2.25 18H17.25C17.65 18 18 17.85 18.3 17.55C18.6 17.25 18.75 16.9 18.75 16.5V1.5C18.75 1.1 18.6 0.75 18.3 0.45C18 0.15 17.65 0 17.25 0H2.25C1.85 0 1.5 0.15 1.2 0.45C0.9 0.75 0.75 1.1 0.75 1.5V16.5C0.75 16.9 0.9 17.25 1.2 17.55ZM17.25 16.5H2.25V1.5H17.25V16.5Z" fill="#2BA66B"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 16.5H17.25V1.5H2.25V16.5ZM15.45 5.75L8.225 12.975L4.175 8.925L5.25 7.85L8.225 10.825L14.375 4.675L15.45 5.75Z" fill="#2BA66B"/>
                                <path d="M8.225 12.975L15.45 5.75L14.375 4.675L8.225 10.825L5.25 7.85L4.175 8.925L8.225 12.975Z" fill="white"/>
                            </svg>
                            <svg v-else width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.25 18C1.85 18 1.5 17.85 1.2 17.55C0.9 17.25 0.75 16.9 0.75 16.5V1.5C0.75 1.1 0.9 0.75 1.2 0.45C1.5 0.15 1.85 0 2.25 0H17.25C17.65 0 18 0.15 18.3 0.45C18.6 0.75 18.75 1.1 18.75 1.5V16.5C18.75 16.9 18.6 17.25 18.3 17.55C18 17.85 17.65 18 17.25 18H2.25ZM2.25 16.5H17.25V1.5H2.25V16.5Z" fill="white"/>
                            </svg>
                        </div>
                        <span class="ml-2 block w-6 h-6 rounded" :style="`background-color:${each.posColour};`"></span>
                        <span class="ml-2">{{each.name}}</span>
                    </div>
                    <div class="text-[10px] tracking-widest text-grey">{{each.parent?'SUB CATEOGRY':'MAIN CATEOGRY'}}</div>
                </div>
            </div>
        </div>

        <div class="input-wrapper flex gap-2 items-center mt-3">
            <label class="block text-white text-sm" for="company">Include out of stock products</label>
            <div class="flex items-center cursor-pointer" @click.prevent="includeOutOfStockProducts = !includeOutOfStockProducts"> 
                <input type="checkbox" v-model="includeOutOfStockProducts" class="hidden peer">
                <div :class="'relative toggle-bg  border-2  h-6 w-11 rounded-full ' + (includeOutOfStockProducts ? 'bg-theme-green border-theme-green' : 'bg-[#F53333] border-[#F53333]')"></div>
                <span class="ml-2 text-white text-sm font-medium uppercase" :class="includeOutOfStockProducts ? 'text-theme-green' : 'text-[#F53333]'">{{includeOutOfStockProducts ? 'On' : 'Off'}}</span>
            </div>
        </div>

        <div class="grid mt-10 justify-center">
            <button @click="create" :disabled="createType=='custom' && selectedCategory.length==0" class="w-[346px] h-[40px] text-md border border-theme-green rounded uppercase uppercase py-2 bg-theme-green disabled:border-theme-grey disabled:text-grey disabled:bg-theme-grey">Start Audit</button>
        </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import { mapGetters, mapMutations} from "vuex";
import ProductAuditApi from '@/controllers/ProductAuditApis'
import BusinessCategoryApis from "@/controllers/BusinessCategoryApis";
import { useSnackbar } from "vue3-snackbar"
import Helpers from '@/helpers';

export default defineComponent({
    props: {
        clearComponent: {
            type: Function,
            required: true,
        },
    },
    setup() {
        const snackbar = useSnackbar();
        const currentData = ref('')
        const createType = ref('allProducts')
        const employeeName = ref('')
        const category = ref([])
        const loading = ref(false)
        const selectedCategory = ref([])
        const includeOutOfStockProducts = ref(false)

        return {snackbar, currentData, createType, employeeName, category, selectedCategory, loading, includeOutOfStockProducts}
    },
    mounted(){
        let currentDate = new Date()
        this.currentData = currentDate.toISOString().split('T')[0]
        this.employeeName = this.vg_user.name
        this.fetch()
    },
    watch: {
        includeOutOfStockProducts(){
            this.fetch()
        }
    },
    computed: {
        ...mapGetters({
            vg_user: 'auth/user',
            vg_locationSelected: "location/selected",
            vg_activeBusiness: 'auth/activeBusiness',
        })
    },
    methods: {
        async fetch(){
            this.loading = true
            this.selectedCategory = []
            this.category = []
            this.featuredCat = []
            let res = await BusinessCategoryApis.fetchAllBusinessCategries({
                businessId: this.vg_activeBusiness.id,
                page:1,
                perPage: 999,
                isPagination: true,
                isOnlyProduct: !this.includeOutOfStockProducts

            });
            if(res?.data?.payload?.category){
                for(let i=0; i<res.data.payload.category.length; i++){
                    if(res.data.payload.category[i].featured){
                        this.featuredCat.push(res.data.payload.category[i])
                    }else{
                        this.category.push(res.data.payload.category[i])
                        if(res.data.payload.category[i].subCategories){
                          this.category = [...this.category, ...res.data.payload.category[i].subCategories]
                        }
                    }
                }
            }
            this.loading = false
        },
        selectCategory(id){
            if(this.selectedCategory && this.selectedCategory.length>0){
                const ind = this.selectedCategory.indexOf(id)
                if(ind>-1){
                    this.selectedCategory.splice(ind, 1)
                }else{
                    this.selectedCategory.push(id)
                }
            }else{
                this.selectedCategory.push(id)
            }
        },
        isCategoryAdded(id){
            if(this.selectedCategory){
                return this.selectedCategory.includes(id)
            }
        },

        select(value){
            this.createType = value
        },
        async create(){
            if(!this.vg_locationSelected.id) return

            this.vm_spinner(true)
            let res =  await ProductAuditApi.createProductAudit(this.vg_locationSelected.id, {
                categories: this.selectedCategory,
                includeOutOfStockProducts: this.includeOutOfStockProducts.toString()});
            this.snackbar.add({
                type: 'info',
                text: res.status == 201 ? 'Product Audit Created.' : Helpers.catchErrorMessage(res.response.data),
                duration: 3000,
            })
            this.emitter.emit('refresh-product-audit-list')
            this.vm_spinner(false)
            if(res?.data?.payload?.inventoryAudit?.id){
                this.$router.push(`audits/${res.data.payload.inventoryAudit.id}/view?edit=true`)
            }
            this.clearComponent()
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }
})
</script>
