<template>
    <div class="bg-[#23242A] text-white  pb-52 h-screen overflow-y-auto px-8">
        <div class="p-3 flex content-main gap-5">
            <div class="custom-full">
                <template v-if="vg_selectedLocation.id">
                    <Nav />
                    <div class="bg-[#2B2C32] rounded-lg px-8 mt-1 pt-1 pb-5">
                        <div class="flex justify-between mt-5 h-[40px]">
                            <h2 class="text-[24px] font-semibold text-white">Delivery Zone</h2>
                        </div>
                        <div>
                            <canvas-map
                                v-if="center.lat && center.lng"
                                @addRegion="addRegion"
                                :drawable="drawable"
                                :regions="regions"
                                :centerLocation="center"
                                :cancelHandler="cancelHandler"
                                @selectZoneEmit="selectRegion"
                                @editRegion="emitEditZone"
                            />
                        </div>
                        <div class="table w-full mt-6 px-2 pb-4">
                            <table-lite
                                :is-slot-mode="true"
                                :is-loading="table.loading"
                                :is-static-mode="true"
                                :is-hide-paging="true"
                                :class="'block w-full admin-table admin-table-custom no-select'"
                                :columns="table.columns"
                                :rows="table.rows"
                                :sortable="table.sortable"
                                :total="table.rows.length"
                                :messages="{
                                    gotoPageLabel: 'Go to page',
                                    noDataAvailable: 'No records found!',
                                }"
                            >
                                <template v-slot:id="data">
                                    <span :style="`background-color: ${data.value.color}`" class="inline-block w-4 h-4 rounded align-middle"></span>
                                    <span class="ml-2">Zone {{ getIndex(data.value.id) }}</span>
                                </template>
                                <template v-slot:deliveryFee="data">
                                    <div v-if="edit.id==data.value.id" class="w-48 border border-field-black bg-transparent rounded">
                                        <span class="ml-2">$</span>
                                        <input class="w-40 bg-transparent border-0 focus:border-0 focus:ring-0 focus:outline-0" type="text" @keydown="validValueForPrice" v-model.number="form.deliveryFee" />
                                    </div>
                                    <span v-else>{{data.value.deliveryFee}}</span>
                                </template>
                                <template v-slot:minTotalForDelivery="data">
                                    <div v-if="edit.id==data.value.id" class="w-48 border border-field-black bg-transparent rounded">
                                        <span class="ml-2">$</span>
                                        <input class="w-40 bg-transparent border-0 focus:border-0 focus:ring-0 focus:outline-0" type="text" @keydown="validValueForPrice" v-model.number="form.minTotalForDelivery" />
                                    </div>
                                    <span v-else>{{data.value.minTotalForDelivery}}</span>
                                </template>
                                <template v-slot:freeDeliveryTotal="data">
                                    <!-- Select -->
                                    <div class="flex flex-row gap-2" v-if="edit.id==data.value.id">
                                        <select v-model="form.allowFreeDelivery" :class="(getIndex(data.value.id)%2)?'bg-[#464851]':'bg-[#2B2C32]'" class="w-28 border border-field-black rounded focus:border-0 focus:ring-field-black">
                                            <option :value="true">Amount</option>
                                            <option :value="false">N/A</option>
                                        </select>
                                        <div v-if="form.allowFreeDelivery" class="flex justify-center items-center w-24 border border-field-black bg-transparent rounded">
                                            <span class="ml-2">$</span>
                                            <input class="w-20 bg-transparent border-0 focus:border-0 focus:ring-0 focus:outline-0" type="text" @keydown="validValueForPrice" v-model.number="form.freeDeliveryTotal" />
                                        </div>
                                    </div>
                                    <!-- Amount -->
                                    <div v-else class="flex flex-row gap-2">
                                        <span >{{data.value.allowFreeDelivery?'Amount':'N/A'}}</span>
                                        <span v-if="data.value.allowFreeDelivery" >${{data.value.freeDeliveryTotal}}</span>
                                    </div>
                                </template>
                                <template v-slot:action="data">
                                    <!-- edit -->
                                    <div v-if="edit.id==data.value.id">
                                        <button @click.prevent="updateRow()">
                                            <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.59948 15.7995L0.132812 8.33281L1.56615 6.89948L7.59948 12.9328L20.3995 0.132812L21.8328 1.56615L7.59948 15.7995Z" fill="#2BA66B"/>
                                            </svg>
                                        </button>
                                        <button class="ml-2" @click.prevent="cancelRow()">
                                            <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.29844 25.1004L6.89844 23.7004L14.5984 16.0004L6.89844 8.30039L8.29844 6.90039L15.9984 14.6004L23.6984 6.90039L25.0984 8.30039L17.3984 16.0004L25.0984 23.7004L23.6984 25.1004L15.9984 17.4004L8.29844 25.1004Z" fill="#F53333"/>
                                            </svg>
                                        </button>
                                    </div>

                                    <!-- list -->
                                    <div v-else>
                                        <button @click="editRow(data.value)">
                                            <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2 22.9992H3.46667L19.6333 6.83251L18.9 6.09917L18.1667 5.36584L2 21.5325V22.9992ZM0 24.9992V20.7325L19.6 1.13251C19.9778 0.75473 20.45 0.571396 21.0167 0.582507C21.5833 0.593619 22.0556 0.788063 22.4333 1.16584L23.8667 2.59917C24.2444 2.97695 24.4333 3.44362 24.4333 3.99917C24.4333 4.55473 24.2444 5.0214 23.8667 5.39917L4.26667 24.9992H0ZM22.3333 3.96584L20.9667 2.59917L22.3333 3.96584ZM19.6333 6.83251L18.9 6.09917L18.1667 5.36584L19.6333 6.83251Z" fill="#2BA66B"/>
                                            </svg>
                                        </button>
                                        <button class="ml-2" @click.prevent="removeRow(data)">
                                            <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.8763 31.334C6.20964 31.334 5.6263 31.084 5.1263 30.584C4.6263 30.084 4.3763 29.5007 4.3763 28.834V5.08398H2.66797V2.58398H10.5013V1.33398H21.5013V2.58398H29.3346V5.08398H27.6263V28.834C27.6263 29.5007 27.3763 30.084 26.8763 30.584C26.3763 31.084 25.793 31.334 25.1263 31.334H6.8763ZM25.1263 5.08398H6.8763V28.834H25.1263V5.08398ZM11.293 25.2507H13.793V8.62565H11.293V25.2507ZM18.2096 25.2507H20.7096V8.62565H18.2096V25.2507ZM6.8763 5.08398V28.834V5.08398Z" fill="#F53333"/>
                                            </svg>
                                        </button>
                                    </div>
                                </template>
                            </table-lite>
                        </div>
                    </div>
                </template>
                <div v-else class="bg-[#2B2C32] rounded-lg px-8 mt-1 pt-1 pb-5">
                    <p class="text-center text-theme-red mt-3">Please select a location from the top left corner.</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Nav from './Nav'
import { defineComponent, ref, reactive } from 'vue'
import { mapGetters, mapActions, mapMutations } from "vuex";
import canvasMap from './canvasMap'
import { useSnackbar } from "vue3-snackbar";
import LocationDeliveryZoneApi from '@/controllers/LocationDeliveryZone'
import Helpers from '@/helpers';

export default defineComponent({
    components: {
        Nav,
        canvasMap
    },
    setup() {
        const snackbar = useSnackbar();
        let drawable = false
        const center = {} //{ lat: 43.6532, lng: -79.3832 }
        const cancelHandler = true
        const form = ref({
            deliveryFee: 0,
            minTotalForDelivery: 0,
            freeDeliveryTotal: 0,
            allowFreeDelivery: false,
            deliveryPolygon: []
        })
        const edit = ref({})
        const add = ref(false)

         // table
        const table = reactive({
            loading: true,
            columns: [
                {label: 'Name', field:'id', sortable: true, isKey: true},
                {label: 'Delivery Fee', field:'deliveryFee', sortable: true},
                {label: 'Order Minimum', field:'minTotalForDelivery', sortable: true},
                {label: 'Free Delivery', field:'freeDeliveryTotal', sortable: true},
                {label: '', field:'action', sortable: false}
            ],
            rows: [],
            sortable: {
                order: "role",
                sort: "asc",
            }
        })
        const regions = []
        const colors = ['#dc2626', '#65a30d', '#0891b2', '#4f46e5', '#db2777', '#475569', '#ca8a04', '#059669', '#2563eb', '#e11d48', '#854d0e', '#115e59', '#075985', '#3730a3', '#86198f', '#9f1239']

        return { snackbar, drawable, regions, center, cancelHandler, form, add, edit,  table, colors }
    },
    mounted(){
        this.getData()
        if(this.vg_selectedLocation?.lat && this.vg_selectedLocation?.long){
            this.center = {lat: parseFloat(this.vg_selectedLocation.lat), lng: parseFloat(this.vg_selectedLocation.long)}
        }else{
            this.center = {lat: 43.6532, lng: -79.3832}
        }
    },
    watch: {
        async vg_selectedLocation(){
            this.regions = []
            this.getData()
            if(this.vg_selectedLocation?.lat && this.vg_selectedLocation?.long){
                this.center = {lat: parseFloat(this.vg_selectedLocation.lat), lng: parseFloat(this.vg_selectedLocation.long)}
            }else{
                this.center = {lat: 43.6532, lng: -79.3832}
            }
        }
    },
    computed: {
        ...mapGetters({
            vg_selectedLocation: 'location/selected'
        })
    },
    methods: {
        getIndex(id){
            if(!id) return this.table.rows.length
            return this.add?(this.table.rows.findIndex(each => each.id==id)):(this.table.rows.findIndex(each => each.id==id)+1)
        },
        async getData(){
            this.table.loading = true
            let {data: {payload}} = await LocationDeliveryZoneApi.fetchLocationDeliveryZone()
            
            let mapData = []
            for(let i=0; i<payload.length; i++){
                let eachMapData = {...payload[i], color: this.colors[i]}
                mapData.push(eachMapData)
            }
            this.table.rows  = mapData
            this.regions = mapData
            this.table.loading = false
        },
        addRegion(data){
            //add to form
            this.form.deliveryPolygon = [...data.coordinates, data.coordinates[0]] 
            // add to edit
            let obj = {...this.form}
            obj.deliveryPolygon = data.coordinates
            obj.id = 0
            obj.color = '#000'
            this.table.rows = [obj, ...this.table.rows]
            this.edit = obj
            this.add = true
        },
        async selectRegion(zoneId) {
            console.log('select')
            console.log(zoneId)
        },
        emitEditZone(data, index) {
            console.log("emit")
            console.log(data)
            console.log(index)
        },
        async addRow(data) {
            this.drawable = false
            this.vm_spinner(true)
            let obj = {
                "deliveryFee": 10,
                "minTotalForDelivery": 30,
                "freeDeliveryTotal": 100,
                deliveryPolygon: [...data.coordinates, data.coordinates[0]]
            }
            let res = await LocationDeliveryZoneApi.addNewLocationDeliveryZone(obj)
            this.snackbar.add({
                type: 'info',
                text: res.status == 201 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
                duration: 3000,
            })
            this.vm_spinner(false)
            
        },
        editRow(data){
            this.edit = data
            this.form.deliveryFee = parseFloat(data.deliveryFee)
            this.form.minTotalForDelivery = parseFloat(data.minTotalForDelivery)
            this.form.freeDeliveryTotal = data.freeDeliveryTotal?parseFloat(data.freeDeliveryTotal):0
            this.form.allowFreeDelivery = data.allowFreeDelivery
            this.form.deliveryPolygon = data.deliveryPolygon
        },
        cancelRow(){
            this.add = false
            this.edit = {}
            this.form = {
                deliveryFee: 0,
                minTotalForDelivery: 0,
                freeDeliveryTotal: 0,
                allowFreeDelivery: false,
                deliveryPolygon: []
            }
            this.getData()
        },
        async removeRow(data){
            if(confirm("Are you sure to delete this zone?")){
                this.vm_spinner(true)
                let res = await LocationDeliveryZoneApi.deleteLocationDeliveryZone(data.value.id)
                this.snackbar.add({
                    type: 'info',
                    text: (res.data)?res.data.message : Helpers.catchErrorMessage(res.response.data)[0],
                    duration: 3000,
                })
                this.cancelRow()
                this.getData()
                this.vm_spinner(false)
            }
        },
        async updateRow(){
            this.vm_spinner(true)
            let res = null
            if(!this.form.minTotalForDelivery) this.form.minTotalForDelivery = 0
            if(!this.form.freeDeliveryTotal) this.form.freeDeliveryTotal = 0
            if(this.add){
                res = await LocationDeliveryZoneApi.addNewLocationDeliveryZone(this.form)
            }else{
                res = await LocationDeliveryZoneApi.updateLocationDeliveryZone(this.edit.id, this.form)
            }
            this.snackbar.add({
                type: 'info',
                text: (res.data)?res.data.message : Helpers.catchErrorMessage(res.response.data),
                duration: 3000,
            })
            
            // if success
            if(res.data){
                // remove add tag
                if(this.add){
                    this.add = false
                }
                // dis-select row
                this.cancelRow()
                this.getData()
            }
            this.vm_spinner(false)
        },
        validValueForPrice(e){
            console.log(e.keyCode)
            if(!(((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 36 && e.keyCode < 41)  || e.keyCode == 8 || e.keyCode==110 || e.keyCode==190) && (e.target.value.length<5 || e.keyCode == 8))) {
                e.preventDefault()
                return false;
            }
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }   
})
</script>

