<template>
    <custom-dialogue v-if="status" size="max-w-[435px]">
      <template v-slot:maincontent>
        <div class="w-[400px] flex flex-col justify-center items-center rounded-lg p-4 pb-6 bg-[#464851] text-white shadow-2xl">
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.26578 56C6.77689 56 6.33245 55.8778 5.93245 55.6333C5.53245 55.3889 5.22134 55.0667 4.99911 54.6667C4.77689 54.2667 4.65467 53.8333 4.63245 53.3667C4.61022 52.9 4.73245 52.4444 4.99911 52L29.6658 9.33333C29.9324 8.88889 30.2769 8.55556 30.6991 8.33333C31.1213 8.11111 31.5547 8 31.9991 8C32.4436 8 32.8769 8.11111 33.2991 8.33333C33.7213 8.55556 34.0658 8.88889 34.3324 9.33333L58.9991 52C59.2658 52.4444 59.388 52.9 59.3658 53.3667C59.3436 53.8333 59.2213 54.2667 58.9991 54.6667C58.7769 55.0667 58.4658 55.3889 58.0658 55.6333C57.6658 55.8778 57.2213 56 56.7324 56H7.26578ZM31.9991 48C32.7547 48 33.388 47.7444 33.8991 47.2333C34.4102 46.7222 34.6658 46.0889 34.6658 45.3333C34.6658 44.5778 34.4102 43.9444 33.8991 43.4333C33.388 42.9222 32.7547 42.6667 31.9991 42.6667C31.2436 42.6667 30.6102 42.9222 30.0991 43.4333C29.588 43.9444 29.3324 44.5778 29.3324 45.3333C29.3324 46.0889 29.588 46.7222 30.0991 47.2333C30.6102 47.7444 31.2436 48 31.9991 48ZM31.9991 40C32.7547 40 33.388 39.7444 33.8991 39.2333C34.4102 38.7222 34.6658 38.0889 34.6658 37.3333V29.3333C34.6658 28.5778 34.4102 27.9444 33.8991 27.4333C33.388 26.9222 32.7547 26.6667 31.9991 26.6667C31.2436 26.6667 30.6102 26.9222 30.0991 27.4333C29.588 27.9444 29.3324 28.5778 29.3324 29.3333V37.3333C29.3324 38.0889 29.588 38.7222 30.0991 39.2333C30.6102 39.7444 31.2436 40 31.9991 40Z" fill="#FCB852"/>
            </svg>
            <h2 class="font-normal mt-6 mb-2 text-xl tracking-[0.15px] leading-6">Insufficient Quantity</h2>
            <p class="font-normal text-base text-center leading-5">Transfer can not be shipped due to insufficient quantity(s), please go back to re-adjust the transfer quantity.</p>
            <button class="mt-10 border border-[#fff] py-2 px-10 rounded w-full" @click="close">GO BACK</button>
        </div>
      </template>
    </custom-dialogue>
</template>
<script>
import { defineComponent } from 'vue'
import CustomDialogue from "@/components/general/dialog/CustomDailogue.vue";

export default defineComponent({
    components: {
        CustomDialogue
    },
    props: ['status'],
    methods: {
        close(){
            this.$emit('close')
        }
    },
})
</script>
