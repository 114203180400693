<template>
    <table class=" table-auto">
      <thead class="h-[42px] ">
          <tr class="">
              <th class="text-start font-bold text-sm leading-[17.5px] tracking-[0.5px]">Name</th>
              <th class="text-start font-bold text-sm leading-[17.5px] tracking-[0.5px]">Category</th>
              <th class="text-start font-bold text-sm leading-[17.5px] tracking-[0.5px]">Sku</th>
              <th class="text-start font-bold text-sm leading-[17.5px] tracking-[0.5px]">Variant</th>
              <th class="text-start font-bold text-sm leading-[17.5px] tracking-[0.5px]">Lot #</th>
              <th class="text-start font-bold text-sm leading-[17.5px] tracking-[0.5px]">Qty at Source</th>
              <th class="text-start font-bold text-sm leading-[17.5px] tracking-[0.5px]">Qty at Dst.</th>
              <th class="text-start font-bold text-sm leading-[17.5px] tracking-[0.5px]">Transfer Qty</th>
          </tr>
      </thead>
      <tbody>
        <template v-for="(product) in products" :key="product.id">
          <tr class='h-[52px] border-y border-[#464851]' >
              <td class="font-normal text-sm leading-[17.5px]">
                <div class="flex items-center w-[220px]">{{product?.productDetails?.name}}</div></td>
              <td>
                  <div class="flex items-center" v-if="product?.categoryName">
                      <span v-if="product?.productDetails?.category?.posColour" class="inline-block w-4 h-4 mr-2 rounded-sm" :style="`background-color: ${product.productDetails.category.posColour};`"></span>
                      <span v-else class="inline-block w-4 h-4 mr-2 rounded-sm" style="background-color: rgb(0, 0, 0);"></span>
                      <span class="text-sm font-normal  leading-[17.5px] capitalize">{{product?.categoryName}}</span>
                  </div>
              </td>
              <td class="font-normal text-sm leading-[17.5px]">{{product?.productDetails?.sku}}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <div v-if="$route.query.status==='pending'" class="flex flex-row items-center gap-4">
                  <div class=" border-l border-[#464851]">
                      <svg @click.prevent="removeProduct(product)" class="cursor-pointer  w-[50px] hover:opacity-50" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7 21C6.45 21 5.97933 20.8043 5.588 20.413C5.196 20.021 5 19.55 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8043 20.021 18.413 20.413C18.021 20.8043 17.55 21 17 21H7ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z" fill="white"/>
                      </svg>
                  </div>
                </div>
            </td>
          </tr>
          <!-- main variant -->
          <template v-for="(batch, index) in product.transferBatches" :key="'m-'+index">
            <ProductListEach v-if="$route.query.status==='pending' || batch.transferQty>0" :showVariantName="index===0" :mainProduct="product" :product="product" :batch="batch" :item="selected" :selectedProduct="selectedProduct" />
          </template>
          <ProductListEach v-if="product.transferBatches.length===0 && ($route.query.status==='pending' || product.transferQty>0)" :showVariantName="true" :mainProduct="product" :product="product" :item="selected" :selectedProduct="selectedProduct" />
          
          <!-- sub variant -->
          <template v-for="(variant, index) in product.transferVariants" :key="index">
            <template v-for="(batch, i) in variant.transferBatches" :key="'b-'+index+i">
              <ProductListEach v-if="$route.query.status==='pending' || batch.transferQty>0" :showVariantName="i===0" :mainProduct="product" :product="variant" :batch="batch" :item="selected" :selectedProduct="selectedProduct" />
            </template>
            <ProductListEach v-if="variant.transferBatches.length===0 && ($route.query.status==='pending' || variant.transferQty>0)" :showVariantName="true" :mainProduct="product" :product="variant" :item="selected" :selectedProduct="selectedProduct" />
          </template>
        </template>
        <tr v-if="products.length==0">
          <td colspan="9" class="text-sm">No Product found.</td>
        </tr>
      </tbody>
  </table>

</template>

<script>
import  { defineComponent , ref} from "vue";
import { mapGetters, mapActions } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import ProductListEach from './ProductListEach.vue'
import TransferApi from '@/controllers/Transfer'
import Helpers from '@/helpers'

export default defineComponent({
  name: 'Transfer List',
  components: {
    ProductListEach
  },
  props: ['products', 'selected', 'selectedProduct'],
  setup() {
    const snackbar = useSnackbar();

    return  {
      snackbar
    };
  },

  async mounted() {
    
  },

  computed: {
    ...mapGetters({
      vg_selectedLocation: 'location/selected'
    }),

    /**
     * Compute columns
     */
    columns() {
      if(this.iscomponentactive){
        let removeFields= ['min_points', 'is_active'];
        return this.columnsMain.filter(item => {
            if( removeFields.indexOf(item.field) == -1 ) {
              return item;
            }
        })
      }
      return this.columnsMain;
    },
  },

  methods: {
    async removeProduct(product){
      this.confirmNew('Delete').then(async () => {
          this.va_spinner(true)
        let data = {
          transferId: product.transferId,
          productId: product.productDetails.id
        }
        let res = await TransferApi.deleteProduct(data)
        if(res.status===201){
          this.snackbar.add({
            type: 'info',
            text: res.status == 201 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
            duration: 3000,
          })
          this.$emit('deleteReload', product.id)
        }
        this.va_spinner(false)
      })
    },
    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })

  }
});
</script>

