import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-abf87918"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex bg-[#2B2C32] rounded-full py-1 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("ul", _hoisted_1, [
      _createElementVNode("li", {
        class: _normalizeClass('mr-4' + (_ctx.activeTab == 'ProductImport' ? ' active' : ''))
      }, [
        _createVNode(_component_router_link, {
          to: { name: 'ProductImport' },
          href: "#",
          class: "inline-block text-theme-white tracking-widest text-sm font-semibold px-2 uppercase"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Product Import ")
          ]),
          _: 1
        })
      ], 2),
      _createElementVNode("li", {
        class: _normalizeClass(_ctx.activeTab == 'CustomerImport' ? ' active' : '')
      }, [
        _createVNode(_component_router_link, {
          to: { name: 'CustomerImport' },
          href: "#",
          class: "inline-block text-theme-white tracking-widest text-sm font-semibold px-2 uppercase"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Customer Import ")
          ]),
          _: 1
        })
      ], 2)
    ])
  ]))
}