
import  { defineComponent } from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Helpers from '@/helpers';

export default defineComponent({
  name: "LocationIntegrationNav",

  setup() {
    
    return  {
      
    };
  },

 
  computed: {
    /** Check for permissions */
    can_manage_compliance_settings() {
        return Helpers.getMyPermission('can_manage_compliance_settings');
    },
  },

  async mounted() {
   
  },

  methods: {

    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })

  }
});
