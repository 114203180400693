/* eslint-disable indent */
export default 
{ 
  googleApiKey: process.env.VUE_APP_GOOGLE_API_KEY,
  loginUrl: process.env.VUE_APP_AUTH_URL,
  loginApiToken: process.env.VUE_APP_API_TOKEN,
  productUrl: process.env.VUE_APP_PRODUCT_URL,
  productApiToken: process.env.VUE_APP_PRODUCT_API_TOKEN,
  loyaltyUrl: process.env.VUE_APP_LOYALTY_URL,
  loyaltyApiToken: process.env.VUE_APP_LOYALTY_API_TOKEN,
  resourceUrl: process.env.VUE_APP_RESOURCE_URL,
  resourceApiToken: process.env.VUE_APP_RESOURCE_API_TOKEN,
  cloverVerificationUrl: process.env.VUE_APP_CLOVER_VERIFICATION_URL,
  cloverRedirectUrl: process.env.VUE_APP_CLOVER_REDIRECT_URL,
  cloverClientId: process.env.VUE_APP_CLOVER_CLIENT_ID,
  orderNestUrl: process.env.VUE_APP_ORDER_NEST_URL,
  intercomeID: process.env.VUE_APP_INTERCOM_ID,
  userUrl: process.env.VUE_APP_USER_URL,
  userToken: process.env.VUE_APP_USER_TOKEN,
  defaultPageSize: 10,
  webStoreUrl: process.env.VUE_APP_WEB_STORE_URL,
  analyticInsightUrl: process.env.VUE_APP_INSIGHT_URL,
  dashboardUrl: process.env.VUE_APP_DASHBOARD_URL,
  pageList: [{ value: 10, text: 10 }, { value: 30, text: 30 }, { value: 50, text: 50 }],
  badgesActionTypes: {
    // category_purchase: 'Category Purchases',
    order_placed: 'Orders  Submitted',
    total_spent: 'Total $ Spent',
    item_purchase: 'No. of Items Purchase',
    action_completed: 'Action Completed'
  },
  timeZones: {
    "America/Vancouver": "PST (Vancouver)",
    "America/Edmonton": "MST (Edmonton)",
    "America/Winnipeg": "CST (Winnipeg)",
    "America/Toronto": "EST (Toronto)",
    "America/Halifax": "AST (Halifax)",
    "America/St_Johns": "NST (St. John's)"
  },
}