<template>
<div class="text-white flex-1 col-span-1 border-0 rounded-t-xl overflow-hidden mt-1">
    <div class="pt-3 bg-[#2B2C32] px-0 py-[16px] rounded-xl">
            <div>
                <div>
                    <div class="flex flex-col items-center mx-auto w-full border border-theme-grey rounded" @dragenter.prevent="dragging=true" @dragover.prevent="dragging=true"  @dragleave.prevent="dragging=false" @drop.prevent="addFile" draggable="false">
                        <div v-if="!image" class="flex flex-col w-[55px] m-5 rounded-tl-xl rounded-bl-xl">
                            <block-loading v-if="uploading" class="self-center" style="height:150px !important; width:55px !important;" />
                            <label v-else for="uploadFile" class="px-2 self-center cursor-pointer">
                                <div class="self-center input-wrapper">
                                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.9987 37.6673V21.0007H0.332031V17.0007H16.9987V0.333984H20.9987V17.0007H37.6654V21.0007H20.9987V37.6673H16.9987Z" fill="#1C1D21"/>
                                    </svg>
                                </div>
                            </label>
                        </div>
                        <input ref="uploadFile" id="uploadFile" class="hidden" type="file" @change="addFile" />
                        <div v-if="image" class="flex flex-row flex-wrap min-h-[150px]">
                            <div class="m-1 relative">
                                <img :src="image" class="w-auto h-[150px]" />
                            </div>
                        </div>
                        <div v-else-if="!uploading && editItem?.id" class="flex flex-row flex-wrap items-center pl-2 min-h-[120px]">
                            No media found.
                        </div>
                        <div v-else>
                            <p class="text-xs font-normal tracking-wider text-theme-878686 text-center pb-2">Drag &amp; Drop your png or jpg files here or click on”+” to select from device directly</p>
                        </div>
                    </div>
                    <div v-if="image">
                        <div class="flex flex-row gap-2 justify-center items-center cursor-pointer" @click="deleteImage(image)">
                            <u>{{name}}</u>
                            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.47578 13.8248L0.425781 12.7748L6.20078 6.9998L0.425781 1.2248L1.47578 0.174805L7.25078 5.9498L13.0258 0.174805L14.0758 1.2248L8.30078 6.9998L14.0758 12.7748L13.0258 13.8248L7.25078 8.0498L1.47578 13.8248Z" fill="#F53333"/>
                            </svg>
                        </div>
                    </div>
                    <div v-else class="text-xs font-normal tracking-wider text-theme-878686 text-center pt-2 pb-2">Min banner size: 1300 x 300 px</div>
                </div>
            </div>
    </div>
</div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import ResourceApi from '@/controllers/BusinessProductResourceApis'
import ProductApis from '@/controllers/BusinessProductApis'
import { useSnackbar } from "vue3-snackbar";
import {mapMutations} from 'vuex'
import Helpers from '@/helpers';

export default defineComponent({
    props: {
        modelValue: String,
    },
    setup() {
        const snackbar = useSnackbar();
        const uploading = ref(false)
        const image = ref('')
        const name = ref('')

        return {snackbar, uploading, image, name}
    },
    mounted(){
        if(this.modelValue){
            this.image = this.modelValue
            this.name = this.getImageName(this.modelValue)
        }
    },
    watch: {
        modelValue() {
            if(this.modelValue){
                this.image = this.modelValue
                this.name = this.getImageName(this.modelValue)
            }
        }
    },
    methods: {
        async addFile(e){
            let fileList = e.target.files || e.dataTransfer.files
            if(fileList){
                let self = this
                this.uploading = true
                let cnt = 0
                for(let i=0; i<fileList.length; i++){
                    this.readFile(fileList[i], async fileData =>{
                        let presignedData = {
                            business_uuid: '123',
                            integrator_name: 'pos',
                            content_type: fileList[i].type,
                            name: fileList[i].name,
                            description: ''
                        }
                        // get presigned url
                        let presignedUrlData = await ResourceApi.getPresignedUrl(presignedData)
                        if(presignedUrlData?.data?.payload?.upload_url){
                            // upload image
                            let imageConfig = {
                                headers: {
                                    'Content-Type': fileList[i].type
                                }
                            }
                            await ResourceApi.uploadResource(presignedUrlData.data.payload.upload_url, fileList[i], imageConfig)
                            self.image = fileData
                            self.name = fileList[i].name
                            this.$emit('update:modelValue', presignedUrlData.data.payload.full_url)
                        }
                        self.uploading = false
                    })
                }
            }
        },
        readFile(file, callback){
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = e => {
                let result = reader.result
                callback(result)
            }
        },
        getImageName(image){
            return image.replace(/^.*[\\\/]/, '')
        },
        deleteImage(image){
            this.confirm("Are you sure to delete this image?").then(async () => {
                let self = this
                if(this.editItem?.id){
                    self.vm_spinner(true)
                    let res = await ProductApis.removeProductImage(image.id)
                    self.snackbar.add({
                        type: 'info',
                        text: (res.data.message)?res.data.message:Helpers.catchErrorMessage(res.response.data),
                        duration: 3000,
                    })
                    self.vm_spinner(false)
                    this.getProductImage()
                }else{
                    self.image = ''
                    self.name = ''
                    this.$emit('update:modelValue', '')
                }
            })
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }
})
</script>
