
import {defineComponent} from "vue"
import moment from 'moment'

export default defineComponent({
  name: "ViewCash",
   props:{
    item: {
      type: Function,
      required: true,
    },
    clearComponent: {
      type: Function,
      required: true,
    },
  },
  methods: {
    getAmount(item){
      if(item.transactionType=='remove cash'){
        return item.amount?'-$'+item.amount.toFixed(2):'0.00'
      }else{
        return item.amount?'$'+item.amount.toFixed(2):'0.00'
      }
    },
    formatDate(date){
      if(!date) return
      return moment(date).format('YYYY-MM-DD h:mm:s a')
    }
  }
});

