/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import Env from '@/env';
const apiVersion = 'api';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /**
   * show location operation hours
   */
  fetchLocationOperationHours() {
    const url = `${Env.loginUrl}/${apiVersion}/location-operation-hours/all`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * Create location operation hours
   */
  createLocationOperationHours(data) {
    const url = `${Env.loginUrl}/${apiVersion}/location-operation-hours/create`;
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * Update pickup Setting
   */
  // updateLocationPickupSetting(data) {
  //   const url = `${Env.loginUrl}/${apiVersion}/location-pickup-settings/update`;
  //   return AuthApis.patchApi(url, null, data)
  //    .then(res => res)
  //    .catch(err => {
  //      if(err.response.status == 401) {
  //        // clear user login data
  //        store.dispatch('auth/clearUserData');
  //        router.push({
  //          name: 'Login'
  //        })
  //      }
  //      return err;
  //    });
  // },


  /**
   * Delete location pickup Setting
   */
  deleteLocationOperationHours() {
    const url = `${Env.loginUrl}/${apiVersion}/location-operation-hours/remove`;
    return AuthApis.deleteApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


}