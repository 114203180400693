/**
 * @module Employee 
 *  Apis must need auth details to trigger url - AuthApi
 */

import store from '@/store/index';
import Env from '@/env';
import NormalApis from '@/controllers/NormalApis';
import AuthApis from '@/controllers/AuthApis';
const loginApiVersion = 'api';
import router from '@/router';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /**
   * Get All Users
   */
  getAllRoles(query = '') {
    let self = this
    let userToken = store.getters['auth/userToken'];
    const url = `${Env.loginUrl}/${loginApiVersion}/business-user-roles/all?${query}`;
    return NormalApis.getApi(url, {
        'Authorization': 'Bearer ' + userToken
      })
      .then(res => {
        if (res.data && res.data.payload && res.data.payload.length > 0) {
          return res
        } else {
          self.addDefaultRoles()
        }
      })
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  addDefaultRoles() {
    let self = this
    let userToken = store.getters['auth/userToken'];
    const url = `${Env.loginUrl}/${loginApiVersion}/businesses/add-default-roles-with-permissions`
    return NormalApis.postApi(url, {
        'Authorization': 'Bearer ' + userToken
      }, null).then(res => {
        self.getAllRoles()
      })
      .catch(err => {
        if (err.response.status == 401) {
          // clear user login data
          store.dispatch('auth/clearUserData');
          router.push({
            name: 'Login'
          })
        }
        return err;
      })
  },

  addRole(data) {
    let userToken = store.getters['auth/userToken'];
    const url = `${Env.loginUrl}/${loginApiVersion}/business-user-roles/create`;
    return NormalApis.postApi(url, {
        'Authorization': 'Bearer ' + userToken
      }, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  editRole(roleId, data) {
    let userToken = store.getters['auth/userToken'];
    const url = `${Env.loginUrl}/${loginApiVersion}/business-user-roles/${roleId}/update`;
    return NormalApis.patchApi(url, {
        'Authorization': 'Bearer ' + userToken
      }, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  getCategory() {
    let userToken = store.getters['auth/userToken'];
    const url = `${Env.loginUrl}/${loginApiVersion}/permission-categories/all?includePermissions=1`;
    return NormalApis.getApi(url, {
        'Authorization': 'Bearer ' + userToken
      })
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  getPermissions(roleId) {
    let userToken = store.getters['auth/userToken'];
    const url = `${Env.loginUrl}/${loginApiVersion}/business-user-roles/${roleId}/permissions/all`;
    return NormalApis.getApi(url, {
        'Authorization': 'Bearer ' + userToken
      })
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  updatePermission(roleId, data) {
    let userToken = store.getters['auth/userToken'];
    const url = `${Env.loginUrl}/${loginApiVersion}/business-user-roles/${roleId}/permissions/sync`;
    return NormalApis.postApi(url, {
        'Authorization': 'Bearer ' + userToken
      }, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  deleteRole(roleId) {
    let userToken = store.getters['auth/userToken'];
    const url = `${Env.loginUrl}/${loginApiVersion}/business-user-roles/${roleId}/remove`;
    return NormalApis.deleteApi(url, {
        'Authorization': 'Bearer ' + userToken
      })
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  }
}