/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

 import store from '@/store/index';
 import Env from '@/env';
 const apiVersion = 'api';
 import AuthApis from '@/controllers/AuthApis';
 import ApiHelper from '@/controllers/ApiHelpers';


// Categories are business based
 export default {
     /**
      * Fetch all location categories
      */
     fetchAllBusinessCategries({ businessId, page, perPage, isPagination= true, isOnlyProduct= false}) {
         let url = `${Env.productUrl}/${apiVersion}/category/forBusiness/${businessId}?sort=desc&sortby=id`;
         if(page) url += `&page=${page}`
         if(perPage) url += `&perPage=${perPage}`
        //  if(isPagination) url += `&isPagination=${isPagination}`
         if(isOnlyProduct) url += `&isOnlyProduct=${isOnlyProduct}`
         return AuthApis.getApi(url, {
          'Authorization': 'Bearer '+ store.getters['auth/userToken'],
          'api_token' : Env.productApiToken,
         })
          .then(res => res)
          .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
          });
     },


     /**
      * Fetch categories with product
      */
      fetchCategoryWithProduct(businessId) {
          const url = `${Env.productUrl}/${apiVersion}/category/products/${businessId}`;
          return AuthApis.getApi(url, {
            'Authorization': 'Bearer '+ store.getters['auth/userToken'],
            'api_token' : Env.productApiToken,
          })
           .then(res => res)
           .catch(err => {
              ApiHelper.detectStatusAndRedirect(err);
              return err;
           });
      },

      /**
      * Fetch products of a category
      */
      fetchProductsOfCategory(categoryId, parent=false) {
        let url = `${Env.productUrl}/${apiVersion}/product/byCategory/${categoryId}?isPagination=false&onlyParentProduct=${parent}`;
        return AuthApis.getApi(url, {
          'Authorization': 'Bearer '+ store.getters['auth/userToken'],
          'api_token' : Env.productApiToken,
        })
         .then(res => res)
         .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
         });
      },

      /**
      * Sort products of a category
      */
      sortProductsOfCategory(data) {
        const url = `${Env.productUrl}/${apiVersion}/product/sort/updateSortOrder`;
        return AuthApis.putApi(url, {
          'Authorization': 'Bearer '+ store.getters['auth/userToken'],
          'api_token' : Env.productApiToken
        },data)
         .then(res => res)
         .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
         });
      },

      /**
      * remove featured products of a category
      */
      removeFeaturedProductsOfCategory(data) {
        const url = `${Env.productUrl}/${apiVersion}/category/featuredCategory/remove/product`;
        return AuthApis.deleteApi(url, {
          'Authorization': 'Bearer '+ store.getters['auth/userToken'],
          'api_token' : Env.productApiToken
        },data)
         .then(res => res)
         .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
         });
      },

      /**
      * Sort featured products of a category
      */
      sortFeaturedProductsOfCategory(categoryId, data) {
        const url = `${Env.productUrl}/${apiVersion}/category/featuredProducts/sort/updateSortOrder/${categoryId}`;
        return AuthApis.putApi(url, {
          'Authorization': 'Bearer '+ store.getters['auth/userToken'],
          'api_token' : Env.productApiToken
        },data)
         .then(res => res)
         .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
         });
      },


      /**
      * Fetch categories with product limited fields
      */
       fetchCategoryWithProductLimitedFields(businessId) {
        const url = `${Env.productUrl}/${apiVersion}/category/products/${businessId}?productsWithLimitedFields=true`;
        return AuthApis.getApi(url, {
          'Authorization': 'Bearer '+ store.getters['auth/userToken'],
          'api_token' : Env.productApiToken,
        })
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
    },

    /**
      * Fetch categories with product
      */
     fetchCategoryWithProductsInDetail(businessId, locationId) {
      const url = `${Env.productUrl}/${apiVersion}/category/categoryProductTree/${businessId}?includeAroma=1&includeTerpene=1&includeAllergen=1&includeBrand=1&locationId=${locationId}`;
      return AuthApis.getApi(url, {
        'Authorization': 'Bearer '+ store.getters['auth/userToken'],
        'api_token' : Env.productApiToken,
      })
       .then(res => res)
       .catch(err => {
         ApiHelper.detectStatusAndRedirect(err);
         return err;
       });
    },

    /**
      * Fetch categories with product
      */
     fetchCategoryWithProductLimitedFieldsWithBrand(businessId, addQuery:any = null, locationId=null,) {

      let url = `${Env.productUrl}/${apiVersion}/category/categoryProductTree/${businessId}`;
      if(locationId){
        url += `?locationId=${locationId}`
      }
      if(addQuery) {
        url += addQuery;
      }

      return AuthApis.getApi(url, {
        'Authorization': 'Bearer '+ store.getters['auth/userToken'],
        'api_token' : Env.productApiToken,
      })
       .then(res => res)
       .catch(err => {
         ApiHelper.detectStatusAndRedirect(err);
         return err;
       });
    },


    /**
      * Fetch categories with product
      */
    fetchCategoryProductTreeWithFilterBrandSearch(businessId, addQuery:any = null) {
      let url = `${Env.productUrl}/${apiVersion}/category/categoryProductTree/${businessId}`;
      if(addQuery) {
        url += addQuery;
      }

      return AuthApis.getApi(url, {
        'Authorization': 'Bearer '+ store.getters['auth/userToken'],
        'api_token' : Env.productApiToken,
      })
       .then(res => res)
       .catch(err => {
         ApiHelper.detectStatusAndRedirect(err);
         return err;
       });
    },


  /**
    * Get Category By Id
    */
    getCategoryById(catId) {
      const url = `${Env.productUrl}/${apiVersion}/category/${catId}`;
      return AuthApis.getApi(url, {
        'Authorization': 'Bearer '+ store.getters['auth/userToken'],
        'api_token' : Env.productApiToken,
      })
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
    },


  /**
    * Add New Category
    */
    addNewCategory(data) {
      const url = `${Env.productUrl}/${apiVersion}/category`;
      return AuthApis.postApi(url, {
        'Authorization': 'Bearer '+ store.getters['auth/userToken'],
        'api_token' : Env.productApiToken,
      }, data)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
    },


    /**
      * Update Category
      */
    updateCategory(catId, data) {
      const url = `${Env.productUrl}/${apiVersion}/category/${catId}`;
      return AuthApis.putApi(url, {
        'Authorization': 'Bearer '+ store.getters['auth/userToken'],
        'api_token' : Env.productApiToken,
      }, data)
       .then(res => res)
       .catch(err => {
         ApiHelper.detectStatusAndRedirect(err);
         return err;
       });
    },


    /**
      * Delete category
      */
     deleteCategory(catId) {
      const url = `${Env.productUrl}/${apiVersion}/category/${catId}`;
      return AuthApis.deleteApi(url, {
        'Authorization': 'Bearer '+ store.getters['auth/userToken'],
        'api_token' : Env.productApiToken,
      })
       .then(res => res)
       .catch(err => {
         ApiHelper.detectStatusAndRedirect(err);
         return err;
       });
    },


    /**
      * Featured categories by location
      */
    fetchFeaturedCategoryByLocation(locationId) {
      const url = `${Env.productUrl}/${apiVersion}/category/featuredCategories/admin/byLocation/${locationId}?isPagination=false`;
      return AuthApis.getApi(url, {
       'Authorization': 'Bearer '+ store.getters['auth/userToken'],
       'api_token' : Env.productApiToken,
      })
       .then(res => res)
       .catch(err => {
         ApiHelper.detectStatusAndRedirect(err);
         return err;
      });
    },


    /**
      * Featured categories
      */
    FetchFeaturedCategory(locationId, categoryId) {
      const url = `${Env.productUrl}/${apiVersion}/category/${categoryId}/featuredCategory/allProducts?locationId=${locationId}`;
      return AuthApis.getApi(url, {
       'Authorization': 'Bearer '+ store.getters['auth/userToken'],
       'api_token' : Env.productApiToken,
      })
       .then(res => res)
       .catch(err => {
         ApiHelper.detectStatusAndRedirect(err);
         return err;
      });
    },

    syncFeaturedProduct(categoryId, data){
      const url = `${Env.productUrl}/${apiVersion}/category/${categoryId}/featuredCategory/syncProducts`;
      return AuthApis.postApi(url, {
       'Authorization': 'Bearer '+ store.getters['auth/userToken'],
       'api_token' : Env.productApiToken,
      }, data)
       .then(res => res)
       .catch(err => {
         ApiHelper.detectStatusAndRedirect(err);
         return err;
      });
    },

    sortCategory(data){
      const url = `${Env.productUrl}/${apiVersion}/category/sort/updateSortOrder`;
      return AuthApis.putApi(url, {
        'Authorization': 'Bearer '+ store.getters['auth/userToken'],
        'api_token' : Env.productApiToken,
       }, data)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
       });
    },

    sortFeaturedCategory(data){
      const url = `${Env.productUrl}/${apiVersion}/category/sort/updateFeaturedCategoriesSortOrder`;
      return AuthApis.putApi(url, {
        'Authorization': 'Bearer '+ store.getters['auth/userToken'],
        'api_token' : Env.productApiToken,
       }, data)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
       });
    }
 }