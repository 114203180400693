
import { defineComponent, ref } from "vue"
import FullHtml from '@/views/Products/Product/QrCode/fullHtml.vue'

export default defineComponent({
  name: "QrCodeFullCard",
  components: {
    FullHtml
  },
  setup() {
    return {
      
    };
  },
  props: {
    product: {
      type: Object,
      required: true,
    }
  },
  
  computed: {
    
  },
  methods: {
    
  },
});
