<template>

    <block-loading v-if="loading" />
    <div v-else class="responsive-table-wrap">
    <table-lite
     
      :class="'admin-table'"
      :is-loading="table.isLoading"
      :is-slot-mode='true'
      :columns="columns"
      :rows="table.rows"
      :rowClasses="customRowClass"
      :total="table.totalRecordCount"
      :sortable="table.sortable"
      @is-finished="table.isLoading = false"
      :page-size='table.pagesize'
      @do-search="doSearch"
      :pageOptions='table.pageOptions'
      :has-checkbox="false"
      @return-checked-rows="checkeddata"
      :checked-return-type="'id'"
      :is-hide-paging="true"
      :pageSizeChangeLabel="'pageSizeChangeLabel'"
      :messages="{
        pagingInfo: '',
        pageSizeChangeLabel: 'Items per Page',
        gotoPageLabel: '',
        noDataAvailable: 'No records found!',
      }"
    >
      <template v-slot:name="data">
        <div :class="'cursor-pointer hover:text-theme-green' " @click.prevent="setActive(data.value)">{{ data.value.name }}</div>
      </template>
      
      <template v-slot:status="data">
        <div class="flex items-center cursor-pointer" @click.prevent="openConfirmStatusModal(data.value)">
          <input type="checkbox" v-model="data.value.status" class="hidden peer">
          <div :class="'relative toggle-bg  border-2  h-6 w-11 rounded-full ' + (data.value.status ? 'bg-theme-green border-theme-green' : 'bg-[#F53333] border-[#F53333]')"></div>
          <span class="ml-2 text-white text-sm font-medium uppercase" :class="data.value.status ? 'text-theme-green' : 'text-[#F53333]'">{{data.value.status ? 'On' : 'Off'}}</span>
        </div>
      </template>
      <template v-slot:taxRate="data">
      <span>{{ (data.value.taxRateType == 'flat' ? '$' : '') +  (data.value.taxRate) + (data.value.taxRateType == 'percentage' ? '%' : '' )}}</span>
      </template>
      <template v-slot:taxType="data">
        <span class="capitalize h-10 flex items-center">{{ data.value.taxType }}</span>
      </template>
      <template v-slot:action="data">
        <div v-if="can_edit_tax" class="h-10 inline-block">
          <button v-if="vg_selectedLocation.id" @click.prevent="applyToAll(data.value)" class="bg-white text-theme-green  hover:text-white hover:bg-theme-green rounded w-64 text-[14px] p-[8px]">APPLY TO ALL ITEMS</button>
        </div>
      </template>
    </table-lite>
  </div>

    <ReturnConfirmModal v-if="confirmStatusObj.modal" :obj="confirmStatusObj.obj" :closeClick="confirmStatusClose" :actionClick="toggleLocationStatus"  :heading="'You Are About To Update ' + confirmStatusObj.obj.name "/>

</template>

<script>
import BusinessTaxApis from "@/controllers/BusinessTaxApis";
import  { defineComponent , ref} from "vue";
import { mapGetters, mapActions } from "vuex";
import ReturnConfirmModal from "@/components/general/dialog/ReturnConfirmModal.vue";
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';
import Env from '@/env';


export default defineComponent({
  components: {
    ReturnConfirmModal
  },
  props: {
    iscomponentactive: {
      type: Boolean,
      required: false,
    },
    selectedCallback: {
      type: Function,
      required: true,
    },
    clearComponent: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const snackbar = useSnackbar();
    const confirmStatusObj = ref({
      modal: false,
      obj: {
        status: Boolean,
        id: Number,
      },
    });
    const activeItem = ref({});
    const loading = ref(false);
    const columnsMain = ref([
      {
        label: "",
        field: "id",
        sortable: false,
        isKey: true,
        display: () => {
          return '';
        },
        columnClasses: ['hide'],
        headerClasses: ['hide'],
      },
      {
        label: "Name",
        field: "name",
        sortable: true,
      },
      {
        label: "Status",
        field: "status",
        sortable: true,
      },
      {
        label: "Type",
        field: "taxType",
        sortable: true,
      },
      {
        label: "Rate",
        field: "taxRate",
        sortable: true,
      },
      {
        label: "Tax Code",
        field: "code",
        sortable: true,
      },
      {
        label: "",
        field: "action",
        sortable: false,
        width: '100px',
      },
    ]);

    const table = ref({
      isLoading: false,
      rows: [],
      totalRecordCount: 0,
      sortable: {
        order: "",
        sort: "",
      },
      pagesize: Env.defaultPageSize,
      pageOptions: Env.pageList,
      count: 0,
    });
    

    const checkeddata = (rowsKey) => {
      console.log(rowsKey)
    };

    // const rowClicked = (rowsKey) => {
    //   // console.log(rowsKey)
    // };

    return  {
      table,
     // doSearch,
      checkeddata,
      //rowClicked,
      columnsMain,
      activeItem,
      loading,
      confirmStatusObj,
      snackbar,
    };
  },

  async mounted() {
    this.loading = true;
    await this.fetchList();
    this.loading = false;
    this.emitter.on("refresh-business-tax-list", data => {
      this.fetchList();
    });
  },

  beforeDestroy() {
   this.emitter.off('refresh-business-tax-list', () => {})
  },

  watch: {
    'vg_selectedLocation'(val) {
      // if location selected is changed fetch the data
      this.fetchList();
    },
  },

  computed: {
    can_edit_tax() {
      return Helpers.getMyPermission('can_manage_taxes')
    },
    ...mapGetters({
      vg_selectedLocation: 'location/selected'
    }),

    /**
     * Compute columns
     */
    columns() {
      if(this.iscomponentactive){
        let removeFields= ['code', 'action'];
        return this.columnsMain.filter(item => {
            if( removeFields.indexOf(item.field) == -1 ) {
              return item;
            }
        })
      }
      return this.columnsMain;
    },
  },

  methods: {
    /**
     * Fetch list
     */
    async fetchList() {
      console.log("refresh list")
      let res = await BusinessTaxApis.fetchAllBusinessTaxes();  
      if(res.status == 200) {
        this.table.rows = res.data.payload;
      }
    },

    /**
     * Select the item
     */
    setActive(item) {
      // Select unselect the item
      if(this.activeItem.id) {
        let oldactive = document.getElementsByClassName('thisid_'+this.activeItem.id);
        if(oldactive.length > 0){
          oldactive[0].classList.remove("active-row");
        }
      }
      this.activeItem = item;
      let newActive = document.getElementsByClassName('thisid_' + this.activeItem.id);
      if(newActive.length > 0){
        newActive[0].classList.add("active-row");
      }
      this.selectedCallback(item);
    },

    /**
     * Create custom class on tr for active colors
     */
    customRowClass(item) {
      return 'thisid_' + item.id;
    },

    /**
     * Apply to all
     */
    async applyToAll(item) {
      
      if(!item.status) {
        return this.snackbar.add({
          type: 'info',
          text: 'Please activate before applying to all items.',
          duration: 3000,
        });
      }
      this.va_spinner(true);
      let res = await BusinessTaxApis.assignTaxToAllProduct({
        taxId: item.id,
        locationId: this.vg_selectedLocation.id
      });
      this.va_spinner(false);
      this.snackbar.add({
        type: 'info',
        text: res.status == 201 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
    },

    /**
     * Close Confirm status modal
     */
    confirmStatusClose() {
      this.confirmStatusObj = {
        modal: false,
        obj: {
          status: Boolean,
          id: Number,
        },
      };
    },
    /**
     * Open confirm satus modal
     */
    openConfirmStatusModal(obj) {
      if(this.can_edit_tax){
        this.confirmStatusObj = {
          modal: true,
          obj,
        };
      }
    },

    /**
     * Search now
     */
    doSearch(offset, limit, order, sort) {
      if(order && sort) {
         this.table.rows = this._lodash.orderBy(this.table.rows, [order],[sort]);
      }
    },

    /**
     * Toggle business status
     */
    async toggleLocationStatus() {
      this.va_spinner(true);
      let res = await BusinessTaxApis.updateLocationTax(this.confirmStatusObj.obj.id, {
          status: !this.confirmStatusObj.obj.status,
      });
      this.va_spinner(false);
      this.snackbar.add({
        type: 'info',
        text: res.status == 200 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
      if(res.status == 200 ) {
        this.confirmStatusClose();
        this.fetchList();
        this.clearComponent();
      }
    },

    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })

  }
});
</script>

