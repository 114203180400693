<template>
    <custom-modal :size="'max-w-3xl'">
        <template v-slot:maincontent>
            <div class="text-white bg-light-black overflow-y-auto rounded-xl">
                <div class="bg-theme-green flex flex-row justify-between px-4 py-2">
                    <h1 class="text-[24px] font-normal tracking-wider">Item Order</h1>
                    <div class="flex flex-row gap-4 items-center mr-2">
                        <button @click="save" class="bg-white hover:bg-white/[0.8] h-[40px] text-theme-green px-4 rounded">Save</button>
                        <span class="cursor-pointer" @click="closeClick">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.3327 2.54602L17.4527 0.666016L9.99935 8.11935L2.54602 0.666016L0.666016 2.54602L8.11935 9.99935L0.666016 17.4527L2.54602 19.3327L9.99935 11.8793L17.4527 19.3327L19.3327 17.4527L11.8793 9.99935L19.3327 2.54602Z" fill="white"/>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="flex justify-center" v-if="loading">
                    <block-loading  />
                </div>
                <div v-else>
                    <div>
                        <div class="mx-2 mt-4">
                            <div class="text-xs pb-1">Search</div>
                            <div class="flex flex-row items-center bg-theme-modalbg rounded px-4">
                                <svg  width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.9 18.9766L10.325 12.4016C9.825 12.8349 9.24167 13.1724 8.575 13.4141C7.90833 13.6557 7.2 13.7766 6.45 13.7766C4.65 13.7766 3.125 13.1516 1.875 11.9016C0.625 10.6516 0 9.14323 0 7.37656C0 5.6099 0.625 4.10156 1.875 2.85156C3.125 1.60156 4.64167 0.976562 6.425 0.976562C8.19167 0.976562 9.69583 1.60156 10.9375 2.85156C12.1792 4.10156 12.8 5.6099 12.8 7.37656C12.8 8.09323 12.6833 8.7849 12.45 9.45156C12.2167 10.1182 11.8667 10.7432 11.4 11.3266L18 17.8766L16.9 18.9766ZM6.425 12.2766C7.775 12.2766 8.925 11.7974 9.875 10.8391C10.825 9.88073 11.3 8.72656 11.3 7.37656C11.3 6.02656 10.825 4.8724 9.875 3.91406C8.925 2.95573 7.775 2.47656 6.425 2.47656C5.05833 2.47656 3.89583 2.95573 2.9375 3.91406C1.97917 4.8724 1.5 6.02656 1.5 7.37656C1.5 8.72656 1.97917 9.88073 2.9375 10.8391C3.89583 11.7974 5.05833 12.2766 6.425 12.2766Z" fill="#878686"/>
                                </svg>
                                <input v-model="search" type="text" class="w-full focus:ring-0 bg-transparent border-0"  placeholder="Search by Name" />
                            </div>
                        </div>
                        <div class="mx-4 my-6" v-if="filterList.length==0">No product found.</div>
                        <div v-else id="sorting_list" class="flex flex-col overflow-auto my-2 max-h-[calc(100vh_-_250px)]">
                            <div v-for="(each,ind) in filterList" :key="ind" :id="each.id" class="flex flex-row gap-2 items-center mx-6 my-2">
                                <div class="flex justify-center gap-1 items-center cursor-ns-resize w-[24px] h-[24px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                        <path d="M21 11H3V9H21V11M21 13H3V15H21V13Z" fill="#878686" />
                                    </svg>
                                </div>
                                <span class="w-[24px] h-[24px] rounded" :style="`background-color: ${item.posColour}`"></span>
                                <div class="flex flex-col">
                                    <div class="text-[16px] leading-[20px] font-medium m-0">{{each?.name}}</div>
                                    <div class="text-grey leading[12px] text-[12px] m-0">{{each.brand?.name}}</div>
                                </div>
                                <div @click="removeItem(each)" class="flex justify-center gap-1 items-center cursor-pointer w-[16px] h-[16px]">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.2999 25.1004L6.8999 23.7004L14.5999 16.0004L6.8999 8.30039L8.2999 6.90039L15.9999 14.6004L23.6999 6.90039L25.0999 8.30039L17.3999 16.0004L25.0999 23.7004L23.6999 25.1004L15.9999 17.4004L8.2999 25.1004Z" fill="white"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </template>
    </custom-modal>
</template>
<script>
import { defineComponent, ref } from 'vue'
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';
import ObjectHelpers from '@/helpers/object';
import BusinessCategoryApis from "@/controllers/BusinessCategoryApis";
import debounce from 'lodash/debounce'
import { mapActions, mapGetters } from "vuex";
import Sortable from 'sortablejs';

export default defineComponent({
    props: ['item'],
    setup() {
        const el = ref(null)
        const sortedObj = ref([])
        const loading = ref(false)
        const snackbar = useSnackbar();
        const productList = ref([])
        const filterList = ref([])
        const search = ref('')
        const removeList = ref([])

        return {el, sortedObj, snackbar, loading, productList, search , filterList, removeList}
    },
    async mounted(){
        await this.getData()
        await this.filterProduct()
        this.draggable()
    },
    watch:{
        search: debounce(async function(val) {
            await this.filterProduct(val)
            this.draggable()
        }, 800)
    },
    computed: {
        ...mapGetters({
            vg_locationSelected: "location/selected",
        })
    },
    methods: {
        draggable(){
            this.el = document.getElementById('sorting_list');
            if(this.el){
                Sortable.create(this.el, {
                    animation: 100,
                    onSort: this.getSortedData
                });
                this.getSortedData()
            }
        },
        getSortedData(){
            this.sortedObj = []
            for(let item of this.el.children){
                this.sortedObj.push({id: parseInt(item.id)})
            }
        },
        async getData(){
            if(!this.item?.id) return

            this.loading = true
            let res = await BusinessCategoryApis.FetchFeaturedCategory(this.vg_locationSelected.id, this.item.id)
            if(res.status == 200) {
                if(res.data?.payload){
                    this.productList =ObjectHelpers.sort(res.data.payload, 'sort')
                }
            }else{
                this.productList = []
            }
        },
        async filterProduct(val){
            if(val){
                this.filterList = [...this.productList.filter(each => each.name.toLowerCase().includes(val.toLowerCase()))]
            }else{
                this.filterList = [...this.productList]
            }
            this.loading = false
        },
        async removeItem(item){
            this.productList = this.productList.filter(each => each.id!==item.id)
            this.removeList.push(item)
            this.filterProduct()

            // this.va_spinner(true);
            // let res = await BusinessCategoryApis.removeFeaturedProductsOfCategory({productIds: [item.id], categoryId:this.item.id, locationId: this.vg_locationSelected.id})
            // this.snackbar.add({
            //     type: 'info',
            //     text: res.status == 200 ? 'Product item is removed' : Helpers.catchErrorMessage(res.response.data),
            //     duration: 3000,
            // })
            // await this.getData()
            // await this.filterProduct()
            // this.draggable()
            // this.va_spinner(false);
        },
        async sortProduct(){
            this.va_spinner(true);
            let productsOrder = this.sortedObj.map(each => each.id)
            let res = await BusinessCategoryApis.sortFeaturedProductsOfCategory(this.item.id, {'locationId': this.vg_locationSelected.id, 'productIds': productsOrder})
            this.snackbar.add({
                type: 'info',
                text: res.status == 200 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
                duration: 3000,
            })
            this.va_spinner(false);
            this.$emit('close')
        },
        async save(){
            let removeIds = this.removeList.map(each => each.id)
            if(removeIds.length>0){
                await BusinessCategoryApis.removeFeaturedProductsOfCategory({productIds: removeIds, categoryId:this.item.id, locationId: this.vg_locationSelected.id})
            }
            this.sortProduct()
        },
        closeClick(){
            this.$emit('close')
        },
        ...mapActions({
            va_spinner: 'spinner/setSpinner',
        })
    }
})
</script>
