
import Helpers from '@/helpers';
import {defineComponent, ref} from "vue"
import { useSnackbar } from "vue3-snackbar";
import { mapGetters, mapActions } from "vuex";
import VaultApis from '@/controllers/VaultApis'

export default defineComponent({
  name: "AddCash",
  setup() {
    const form = ref({
      transactionType: {
        value: 'bank deposit',
        error_type: '',
        error_message: ''
      },
      amount: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required']
      }, 
      depositId: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required']
      }, 
      note: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required']
      }
    })
    const snackbar = useSnackbar();

    return {form, snackbar}
  },
  computed: {
    ...mapGetters({
      vg_selectedLocation: 'location/selected'
    })
  },
  methods: {
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },
    close(){
      this.$emit('close', false)
    },
    async saveBankDeposit(){
      let validate = await this.validateFields(['amount', 'depositId', 'note']);
      if(!validate) {
        return ;
      }

      this.va_spinner(true)
      let res = await VaultApis.create({
        locationId: this.vg_selectedLocation.id,
        amount: this.form.amount.value,
        transactionType: this.form.transactionType.value,
        depositId: this.form.depositId.value,
        note: this.form.note.value
      })
      if(res.status==201){
        this.snackbar.add({
          type: 'info',
          text: res.status == 201 ? this.getMessage(res) : Helpers.catchErrorMessage(res.response.data),
          duration: 3000,
        })
        this.$emit('close', true)
      }
      this.va_spinner(false)
    },
    getMessage(res){
      if(this.form.transactionType.value=='bank deposit'){
        return 'Bank deposit successful.'
      }else{
        return res.data.message
      }
    },
    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })
  }
});

