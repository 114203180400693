import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_full_html = _resolveComponent("full-html")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_full_html, {
      class: "full-qr-card",
      product: _ctx.product
    }, null, 8, ["product"])
  ]))
}