
import {defineComponent, ref} from "vue"
import ScheduleApi from '@/controllers/ScheduleReport'
import SelectLocations from '@/components/form/SelectLocations.vue'
import Helpers from '@/helpers';
import { mapGetters, mapActions } from "vuex";
import { useSnackbar } from "vue3-snackbar";

export default defineComponent({
  name: "ScheduleAdd",
  components: {
    SelectLocations
  },
  setup() {
    const form = ref({
      email: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'email']
      }, 
      locationId: {
        value: [],
        error_type: '',
        error_message: '',
        validations: []
      }
    })
    const snackbar = useSnackbar();

    return {form, snackbar}
  },
  computed: {
    ...mapGetters({
        vg_locations: 'location/list',
        vg_user: 'auth/user'
    })
  },
  methods: {
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },
    close(){
      this.$emit('close', false)
    },
    async save(){
      let validate = await this.validateFields(['email', 'locationId']);
      if(!validate) {
        return ;
      }

      this.va_spinner(true)
      let res = await ScheduleApi.create({
        reportName: 'end_of_day_summary_report',
        email: this.form.email.value,
        locationId: this.form.locationId.value,
      })
      this.snackbar.add({
        type: 'info',
        text: res.status == 201 ? res.data.message: Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
      if(res.status==201){
        this.$emit('close', true)
      }
      this.va_spinner(false)
    },
    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })
  }
});

