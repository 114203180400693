/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import Env from '@/env';
const apiVersion = 'api/v1';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {

  /**
   * get setting
   */
  fetchSetting(program_id) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/programs/${program_id}/settings`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Create
   */
  create(program_id, data) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/programs/${program_id}/settings/earning`;
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * update
   */
  update(program_id, data) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/programs/${program_id}/settings`;
    return AuthApis.patchApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * bulk Assign products
   */
  assignProducts(program_id, data) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/programs/${program_id}/products/order-settings`;
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

}