<template>
  <div class="bg-[#23242A] text-white custom p-3" id="content_screen">
    <div :class="'p-3 flex content-main ' +(action.component ? ' half-it gap-5' : 'gap-5')">
      <div class="custom-full custom-left">
        <div class="bg-[#2B2C32] rounded-lg px-[16px] max-w-full py-[16px]">
          <template v-if="vg_selectedLocation.id">
            <!-- Header -->
            <div class="flex justify-between mb-5 h-[40px]">
              <h2 class="text-[24px] font-semibold text-white">Banners</h2>
              <mdicon
                @click="addUpdate({ component: 'Add', data: {} })"
                v-if="!action.component"
                size="32"
                name="plus"
                class="text-white bg-theme-green hover:bg-theme-darkgreen py-1 h-10 px-1 text-2xl border-0 rounded-md cursor-pointer"
              />
            </div>

            <!-- Lists -->
            <List
              :selectedCallback="selectedItem"
              :clearComponent="clearComponent"
              :iscomponentactive="action.component ? true : false"
            />
          </template>
          <p v-else class="text-center text-theme-red">
            Please select a location from the top left corner.
          </p>
        </div>
      </div>
      <div :class="'w-full hidden-component custom-right'" v-if="action.component">
        <component
          :is="action.component"
          :item="action.data"
          :clearComponent="clearComponent"
          @reload="reload"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import BrandingApis from '@/controllers/BrandingApis'
import List from '@/views/Settings/Banner/List'
import Add from '@/views/Settings/Banner/Add'
import Edit from '@/views/Settings/Banner/Edit'
import FileManager from '@/components/manager/filemanager.vue'

export default defineComponent({
  components: {
    FileManager,
    List,
    Add,
    Edit
  },

  setup() {
    const loading = ref(false)
    const businessForm = ref({
      name: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'min:3']
      },
      logo: {
        value: ''
      }
    })
    const form = ref({
      primaryColor: '',
      secondaryColor: '',
      logo: ''
    })
    
    const action = ref({
      component: '',
      data: {}
    })

    return {
      loading,
      businessForm,
      form,
      action
    }
  },

  async mounted() {
    this.emitter.on('reloadBanner', this.clearComponent)
  },
  beforeDestroy() {
    this.emitter.off("reloadBanner", this.clearComponent);
  },

  computed: {
    ...mapGetters({
      vg_activeBusiness: 'auth/activeBusiness',
      vg_selectedLocation: 'location/selected'
    })
  },

  methods: {

    /**
     * Clear action
     */
    clearComponent() {
      this.action = {
        component: '',
        data: {}
      }
      let activeItem = document.getElementsByClassName('active-row')
      if (activeItem.length > 0) {
        activeItem[0].classList.remove('active-row')
      }
    },

    selectedItem(item){
      this.addUpdate({
        component: 'Edit', 
        data: item
      })
    },

    /**
     * Change add / update component
     */
    addUpdate(val) {
      this.action = val
    }
  }
})
</script>