/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import Env from '@/env';
const apiVersion = 'api';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /**
   * Fetch all  location pos station
   */
  fetchAll() {
    const url = `${Env.loginUrl}/${apiVersion}/location-payment-terminals/all`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * Add new  location pos station
   */
  create(data) {
    const url = `${Env.loginUrl}/${apiVersion}/location-payment-terminals/create`;
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * Update 
   */
  update(locationPosStationId, data) {
    const url = `${Env.loginUrl}/${apiVersion}/location-payment-terminals/${locationPosStationId}/update`;
    return AuthApis.patchApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * Delete 
   */
  delete(locationPosStationId) {
    const url = `${Env.loginUrl}/${apiVersion}/location-payment-terminals/${locationPosStationId}/remove`;
    return AuthApis.deleteApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


}