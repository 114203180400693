<template>
<div class="flex flex-col z-[99]" tabindex="0">
    <div class="flex flex-row justify-between h-10 p-2" :class="disabled?'cursor-not-allowed':'cursor-pointer'" @click="toggleOpen">
        <div class="flex" :class="selected?'text-white':'text-grey'">
            <span v-if="selected" class="w-4 h-4 mr-2 rounded self-center inline-block" :style="selected?`background-color:${getColor(selected)};`:''"></span>
            <span class="inline-block truncate">{{selected?getName(selected):placeholder}}</span>
        </div>
        <div class="mt-2">
            <svg v-if="open" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.83008 0.624219L0.830078 6.62422L1.90508 7.69922L6.83008 2.74922L11.7551 7.67422L12.8301 6.59922L6.83008 0.624219Z" fill="white"/>
            </svg>
            <svg v-else width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.83325 7.37578L0.833252 1.37578L1.90825 0.300781L6.83325 5.25078L11.7583 0.325781L12.8333 1.40078L6.83325 7.37578Z" fill="white"/>
            </svg>
        </div>
    </div>
    <div v-if="open" class="flex flex-col z-[999] absolute top-[90px] w-[200px] max-h-96 overflow-auto">
        <div @click="select('')" class="relative grid grid-cols-4 p-2 border-t border-light-black drop-shadow transition duration-500 bg-theme-modalbg hover:bg-theme-grey cursor-pointer" :key="'brd-all'" value="">
            <div class="mr-2 self-center"><span class="w-8 h-8 rounded inline-block"></span></div>
            <div class="col-span-3 inline-block self-center">All</div>
        </div>
        <div @click="select('noCategory')" class="relative grid grid-cols-4 p-2 border-t border-light-black drop-shadow transition duration-500 bg-theme-modalbg hover:bg-theme-grey cursor-pointer" :key="'brd-all'" value="">
            <div class="mr-2 self-center"><span class="w-8 h-8 rounded inline-block"></span></div>
            <div class="col-span-3 inline-block self-center">No Category</div>
        </div>
        <div @click="select(option.id)" :class="option.parent?'pl-[20px]':''" class="relative grid grid-cols-4 p-2 border-t border-light-black drop-shadow transition duration-500 bg-theme-modalbg hover:bg-theme-grey cursor-pointer" v-for="option in options" :key="'brd-'+option.id" :value="option.id">
            <div class="mr-2 self-center">
                <span class="w-8 h-8 rounded inline-block" :style="`background-color:${option.posColour};`"></span>
            </div>
            <div class="col-span-3 inline-block self-center">{{option.name}}</div>
        </div>
    </div>
</div>
</template>
<script>
import {defineComponent} from 'vue'
export default defineComponent({
    props: {
        modelValue: String,
        placeholder:String,
        options: Array,
        disabled: Boolean
    },
    data(){
        return {
            open: false,
            selected: ''
        }
    },
    mounted(){
        this.selected = this.modelValue
    },
    watch: {
        modelValue(){
            this.selected = this.modelValue
        }
    },
    methods: {
        toggleOpen(){
            if(!this.disabled){
                this.open = !this.open
            }
        },
        close(){
            this.open = false
        },
        isAdded(id){
            return this.selected.includes(id)
        },
        getName(id){
            if(!id) return
            
            if(id=='noCategory'){
                return 'No Category'
            }else{
                let obj = this.options.find(each => each.id==id)
                if(obj) return obj.name
            }
        },
        getColor(id){
            let obj = this.options.find(each => each.id==id)
            if(obj) return obj.posColour
        },
        select(id){
            this.selected = id
            this.$emit('update:modelValue', this.selected)
            this.close()
        }
    }
})
</script>