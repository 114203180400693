
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
import BusinessCategoryApis from "@/controllers/BusinessCategoryApis";
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';

  export default defineComponent({
  name: "ProductCategoryAdd",
  components: {

  },
  props: {
    clearComponent: {
      type: Function,
      required: true,
    },
  },

  setup() {
    const snackbar = useSnackbar();
    const form = <any>ref({
      businessId: '',
      name: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'min:3'],
      },
      reasonCode: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      description: "",
    })
    return {
      snackbar,
      form,
    };
  },

  computed: {
    ...mapGetters({
      vg_activeBusiness: 'auth/activeBusiness',
    }),
  },

  async mounted() {
  
  },


 
  methods: {

    /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },

   
 
    async addNew() {
      // validate business field
      let validate = await this.validateFields(['name', 'reasonCode']);
      if(!validate) {
        return ;
      }

      this.form.businessId = this.vg_activeBusiness.id;
      let data = <any>Helpers.getFormKeyValue(this.form);
      alert('Api in progress.')
      //this.va_spinner(true);
      // let res = await BusinessCategoryApis.addNew(data);  
      // this.va_spinner(false);
      // this.snackbar.add({
      //   type: 'info',
      //   text: res.status == 201 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
      //   duration: 3000,
      // })
      // if(res.status == 201) {
      //   this.clearComponent()
      //    this.emitter.emit("refresh-product-reasoncode-list", true);
      // }
    },
    /**
     * Do not allow to add +,-,e
     */
    preventNonNumbers(e) {
       var ASCIICode = (e.which) ? e.which : e.keyCode
      if(ASCIICode == 43 || ASCIICode == 45 || ASCIICode == 101){
        return false;
      }
      return true;
    },
    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  },
});

