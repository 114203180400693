
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
import LocationPaymentProcessorApis from "@/controllers/LocationPaymentProcessorApis";
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';

export default defineComponent({
  name: "LocationPosAdd",
  components: {
   
  },
  setup() {
    const snackbar = useSnackbar();
    const form = ref({
      processor: {
        value: 'moneris',
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      merchantId: {
        value: '', //E7JT323858QX1
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      terminalId: {
        value: '', // 3ERGK6HHS1MWG
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      active: false,
    });


    return {
      form,
      snackbar,
    };
  },
  props:{
    clearComponent: {
      type: Function,
      required: true,
    },
  },

  watch:{
    
    // terminalId cannot have whitespaces
    'form.terminalId.value'(val){
      if(val) {
        // val = val.toLowerCase();
        val = val.replaceAll(/\s/g,'').trim();
        this.form.terminalId.value = val;
      } else {
        this.form.terminalId.value = '';
      }
    },
    // merchantId cannot have whitespaces
    'form.merchantId.value'(val){
      if(val) {
        // val = val.toLowerCase();
        val = val.replaceAll(/\s/g,'').trim();
        this.form.merchantId.value = val;
      } else {
        this.form.merchantId.value = '';
      }
    },

   
  },

  computed: {
   
      ...mapGetters({
          vg_selectedLocation: 'location/selected'
      }),
  },


  mounted() {
   
  },
 
  methods: {

    
     /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },


    /**
     * Add 
     */
    async addNew() {
      let validate = false
      if(this.form.processor.value==='clover'){
        validate = await this.validateFields(['processor', 'merchantId', 'terminalId']);
      }else{
        validate = await this.validateFields(['processor', 'terminalId']);
      }
      if(!validate) {
        return ;
      }
      this.va_spinner(true);
      let data = Helpers.getFormKeyValue(this.form)
      if(this.form.processor.value==='moneris'){
        delete data['merchantId']
      }
      let res = await LocationPaymentProcessorApis.create(data);
      this.va_spinner(false);
        this.snackbar.add({
        type: 'info',
        text: res.status == 201 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
      if(res.status == 201 ) {
        this.clearComponent();
        this.emitter.emit("refresh-location-payment-processor-list", true);
      }
    },


    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  },
});

