<template>
  <div
    class="rounded-[10px] mb-4 bg-[#2B2C32] w-full"
  >
    <new-table-lite
      :is-loading="table.loading"
      :is-slot-mode="true"
      :columns="column"
      :rows="table.rows"
      :class="'block w-full min-h-[480px] admin-table cursor-pointer'"
      :rowClasses="row => row?'id-'+row.id:null"
      :total="table.totalRecordCount"
      :sortable="table.sortable"
      :page-size="table.pagesize"
      @row-clicked="selectedRow"
      :pageOptions="table.pageOptions"
      :has-checkbox="false"
      :checked-return-type="'id'"
      :is-hide-paging="true"
      :pageSizeChangeLabel="'pageSizeChangeLabel'"
      :messages="{
        pagingInfo: '',
        pageSizeChangeLabel: 'Items per Page',
        gotoPageLabel: '',
        noDataAvailable: 'No records found!'
      }"
    >
      <template v-slot:name="data">
        <div class="flex flex-row justify-start items-center">
          <div v-if="!iscomponentactive" class="w-[200px]">
            <img :src="data.value.image" class="mx-auto max-w-[200px] min-w-[100px] h-auto max-h-20" />
          </div>
          <div class="ml-2">{{ data.value.name }}</div>
        </div>
      </template>
      <template v-slot:featured="data">
        <div>{{ data.value.featured ? 'Featured' : 'Main' }}</div>
      </template>
      <template v-slot:ctaValue="data">
        <div>{{ data.value.ctaValue ? 'Yes' : 'No' }}</div>
      </template>
      <template v-slot:startDate="data">
        <div>
          {{ data.value.startDate ? formatDate(data.value.startDate) : '' }}
        </div>
      </template>
      <template v-slot:endDate="data">
        <div>
          {{ data.value.endDate ? formatDate(data.value.endDate) : '' }}
        </div>
      </template>
      <template v-slot:status="data">
        <label
          class="flex items-center cursor-pointer"
          :for="'pos_status_' + data.value.id"
          @click.prevent.stop="
            openConfirmStatusModal(data.value, !data.value.status)
          "
        >
          <input
            type="checkbox"
            :id="'pos_status_' + data.value.id"
            v-model="data.value.status"
            class="hidden peer"
          />
          <div
            :class="
              'relative toggle-bg  border-2  h-6 w-11 rounded-full ' +
              (data.value.status
                ? 'bg-theme-green border-theme-green'
                : 'bg-[#F53333] border-[#F53333]')
            "
          ></div>
          <span
            class="ml-2 text-white text-sm font-medium uppercase"
            :class="data.value.status ? 'text-theme-green' : 'text-[#F53333]'"
            >{{ data.value.status ? 'On' : 'Off' }}</span
          >
        </label>
      </template>
    </new-table-lite>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import PromotionApis from '@/controllers/PromotionApis'
import Env from '@/env'
import NewTableLite from '@/components/table/tableLite.vue'
import moment from 'moment'
import { useSnackbar } from 'vue3-snackbar'
import Helpers from '@/helpers';
import { mapActions} from "vuex";

export default defineComponent({
  props: {
    selectedCallback: {
      type: Function,
      required: true,
    },
    iscomponentactive: {
      type: Boolean
    }
  },
  components: {
    NewTableLite
  },
  setup() {
    const snackbar = useSnackbar()
    const column = ref([])
    const defaultColumn = [
      {
        label: 'Name',
        field: 'name',
        sortable: false
      },
      {
        label: 'Type',
        field: 'featured',
        sortable: false
      },
      // {
      //   label: 'Locations',
      //   field: 'startedOn',
      //   sortable: false,
      // },
      {
        label: 'Hyperlink',
        field: 'ctaValue',
        sortable: false
      },
      {
        label: 'Start Date',
        field: 'startDate',
        sortable: false
      },
      {
        label: 'End Date',
        field: 'endDate',
        sortable: false
      },
      {
        label: 'Status',
        field: 'status',
        sortable: false
      }
    ]
    const activeColumn = [
      {
        label: 'Name',
        field: 'name',
        sortable: false
      },
      {
        label: 'Type',
        field: 'featured',
        sortable: false
      },
      {
        label: 'Start Date',
        field: 'startDate',
        sortable: false
      },
      {
        label: 'End Date',
        field: 'endDate',
        sortable: false
      },
      {
        label: 'Status',
        field: 'status',
        sortable: false
      }
    ]
    const table = ref({
      loading: false,
      rows: [],
      totalRecordCount: 0,
      count: 0,
      sortable: {
        order: '',
        sort: ''
      },
      pagesize: Env.defaultPageSize,
      pageOptions: Env.pageList,
      page: 1
    })

    return {
      snackbar,
      table,
      column,
      defaultColumn,
      activeColumn
    }
  },
  async mounted() {
    await this.fetchBusinessPromotions(this.table.page)
    if(this.iscomponentactive){
      this.column = this.activeColumn
    }else{
      this.column = this.defaultColumn
    }

    this.emitter.on('reloadBanner', this.reload)
  },
  beforeDestroy() {
    this.emitter.off("reloadBanner", this.reload);
  },
  watch: {
    iscomponentactive(val){
      if(val){
        this.column = this.activeColumn
      }else{
        this.column = this.defaultColumn
      }
    }
  },
  methods: {
    reload(){
      this.fetchBusinessPromotions(this.table.page)
    },
    async fetchBusinessPromotions(page) {
      this.table.loading = true
      let { data } = await PromotionApis.getPromotion()
      if (data?.payload) {
        this.table.rows = data.payload
        this.table.totalRecordCount = data.payload.length
        this.table.page = page
      } else {
        this.table.rows = []
      }
      this.table.loading = false
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    async openConfirmStatusModal(promotion, value) {
      this.va_spinner(true)
       let res = await PromotionApis.updatePromotion(promotion.id, {...promotion, status: value})
       if(res.status==200){
            this.snackbar.add({
                type: 'info',
                text: res.status == 200 ? 'Banner updated.' : Helpers.catchErrorMessage(res.response.data),
                duration: 3000,
            })
       }
      this.va_spinner(false)
      this.fetchBusinessPromotions(this.table.page)
    },
    selectedRow(item){
      // selection effect
        let activeRow = document.getElementsByClassName('id-'+item.id);
        if(activeRow[0] && activeRow[0].parentNode && activeRow[0].parentNode.children){
          for(let sibling of activeRow[0].parentNode.children){
              sibling.classList.remove('active-row')
          }
        }
        activeRow[0].classList.add("active-row")
        this.selectedCallback(item);
    },
    ...mapActions({
      va_spinner: 'spinner/setSpinner'
    })
  }
})
</script>
