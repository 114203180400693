
  import { defineComponent, ref } from "vue"
  export default defineComponent({
    name: "QrCodeFullCard",
    setup() {
  
      return {
       
      };
    },
    props: {
      message: {
        type: Object,
        required: true,
        default: 'Please wait..'
      },
    }
  })

