
    import {
        defineComponent,
        ref
    } from "vue"
    import {
        mapGetters,
        mapActions,
        mapMutations
    } from "vuex";
    import Nav from '@/views/Location/Integrations/Nav.vue';
    import LocationIntegrationApis from "@/controllers/LocationIntegrationApis";
    import Helpers from '@/helpers';
    import { useSnackbar } from "vue3-snackbar";

    export default defineComponent({
        name: "LocationIntregationIndex",
        components: {
            Nav,
        },

        setup() {
            const loading = ref(false);
            const haveData = ref(false)
            const form:any = ref({
                integrationProvider: {
                    value: 'leafly',
                    error_type: '',
                    error_message: '',
                    validations: ['required'],
                },
                integrationKey: {
                    value: 'leafly',
                    error_type: '',
                    error_message: '',
                    validations: ['required'],
                },
                integrationValue: {
                    value: '',
                    error_type: '',
                    error_message: '',
                    validations: ['required'],
                },
            });
            const snackbar = useSnackbar();

            return {
                snackbar,
                form,
                loading,
                haveData
            };
        },

        watch: {
            'vg_selectedLocation'(val) {
              this.fetchData();
            },
        },

        computed: {
            ...mapGetters({
                vg_selectedLocation: 'location/selected',
                vg_user: 'auth/user',
            }),

            can_manage_compliance_settings() {
                return Helpers.getMyPermission('can_manage_compliance_settings');
            },

        },

        mounted() {
            this.fetchData();
        },

        beforeDestroy() {

        },

        methods: {

             /**
             * Validation captures
             */
            async validateFields(fields) {
                let res = await Helpers.validateFormFields(this.form, fields);
                // capture the fields
                this.form = res.data;
                return res.error ? false : true;
            },
            
            /**
             * Save data
             */
             async fetchData() {
                this.loading = true;
                let res = await LocationIntegrationApis.fetchAll(this.vg_selectedLocation.id, 'leafly');  
                if(res.status == 200) {
                    this.form = Helpers.assignFormValues(this.form, res.data.payload[0]);
                    this.haveData = true
                } else {
                    this.form = Helpers.resetFormValue(this.form)
                    this.form.integrationProvider.value = 'leafly'
                    this.form.integrationKey.value = 'ApiKey'
                    this.haveData = false
                }
                this.loading = false;
            },

            /**
             * Save data
             */
            async save() {
                let validate = await this.validateFields(['integrationProvider', 'integrationKey', 'integrationValue']);
                if(!validate) {
                    return ;
                }

                this.va_spinner(true);
                let res = await LocationIntegrationApis.create(Helpers.getFormKeyValue(this.form));
                this.va_spinner(false);
                this.snackbar.add({
                    type: 'info',
                    text: res.status == 201 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
                    duration: 3000,
                })
                this.fetchData()
            },

            async syncMenu(){
                this.va_spinner(true);
                let res = await LocationIntegrationApis.sync(this.vg_selectedLocation.id);
                this.va_spinner(false);
                this.snackbar.add({
                    type: 'info',
                    text: res.status == 201 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
                    duration: 3000,
                })
            },

            ...mapActions({
                va_spinner: 'spinner/setSpinner',
            })
        },
    });
