<template>
  <div>
  <div class="grid grid-cols-1 lg:grid-cols-1 gap-3" style="font-family:Arial">
    <div class="text-white flex-1 col-span-1 border-0 rounded-t-xl overflow-hidden">
        <div class="pt-3 bg-[#464851] px-[16px] py-[16px] rounded-lg">
            <div class="flex justify-center relative pb-5">
                <span class="text-3xl font-normal tracking-wider">Print Product Card{{ isSingle ? '' : 's'}}</span>
                <a v-if="!isSingle" href="#" @click.prevent="back" class="right-0 top-2 absolute">Back</a>
            </div>
            <div class="px-[16px] py-[16px] bg-[#2B2C32] rounded-xl">  
              
                <p v-if="isSingle" class="text-center text-2xl font-semibold tracking-wider mt-6 mb-2 text-theme-878686">Item Selected: <span class="text-white">{{products[0].name}}</span></p> 
                <p v-else class="text-center text-2xl font-semibold tracking-wider mt-6 mb-2 text-theme-878686">Items Selected: <span class="text-white">{{ count }}</span></p> 
                <div class="flex items-center  xl:flex-row flex-col gap-3">
                    <div class="w-full xl:w-1/2">
                        <div class="input-wrapper relative">
                            <input class="sr-only peer" type="radio" :value="true" name="cardtype" v-model="form.full_card" id="full_card">
                            <label class="flex px-5 py-10 bg-theme-grey border justify-center border-[#2BA66B] rounded-md cursor-pointer focus:outline-none hover:bg-[#2B5445] peer-checked:ring-[#2BA66B] peer-checked:bg-[#2B5445] peer-checked:border-[#2BA66B]" for="full_card">Full Card</label>
                        </div>
                    </div>

                    <div class="w-full xl:w-1/2">
                        <div class="input-wrapper relative">
                            <input class="sr-only peer" type="radio" :value="false" v-model="form.full_card" name="cardtype" id="qr_card">
                            <label class="flex px-5 py-10 bg-theme-grey border justify-center border-[#2BA66B] rounded-md cursor-pointer focus:outline-none hover:bg-[#2B5445] peer-checked:ring-[#2BA66B] peer-checked:bg-[#2B5445] peer-checked:border-[#2BA66B]" for="qr_card">QR code only</label>
                        </div>
                    </div>
                </div> 

                <p class="text-center text-2xl font-semibold tracking-wider mt-6 mb-2 text-theme-878686">Format</p> 
                <div class="flex items-center  xl:flex-row flex-col gap-3">
                    <div class="w-full xl:w-1/2">
                        <div class="input-wrapper relative">
                            <input class="sr-only peer" type="radio" :value="true" name="cardformat" v-model="form.pdf_format" id="pdfFormat">
                            <label class="flex px-5 py-10 bg-theme-grey border justify-center border-[#2BA66B] rounded-md cursor-pointer focus:outline-none hover:bg-[#2B5445] peer-checked:ring-[#2BA66B] peer-checked:bg-[#2B5445] peer-checked:border-[#2BA66B]" for="pdfFormat">PDF</label>
                        </div>
                    </div>

                    <div class="w-full xl:w-1/2">
                        <div class="input-wrapper relative">
                            <input class="sr-only peer" type="radio" :value="false" v-model="form.pdf_format" name="cardformat" id="imageFormat">
                            <label class="flex px-5 py-10 bg-theme-grey border justify-center border-[#2BA66B] rounded-md cursor-pointer focus:outline-none hover:bg-[#2B5445] peer-checked:ring-[#2BA66B] peer-checked:bg-[#2B5445] peer-checked:border-[#2BA66B]" for="imageFormat">Image</label>
                        </div>
                    </div>
                </div> 
                <div class="flex justify-center mt-10">
                    <button @click.prevent="createNow()" class="rounded-lg bg-theme-green hover:bg-theme-darkgreen text-white px-[140px] py-[12px] text-sm tracking-wider font-medium">SAVE</button>
                </div>
            </div>
            <div class=" flex justify-center items-center pt-[10px]">
                <button @click.prevent="closeCallBack" class="uppercase text-sm font-medium tracking-widest text-white hover:text-theme-green">Close</button>
            </div>

            <div>
              <div style="height:0px; overflow:hidden" id="generate_qr_full">
                <!-- style="height:0px; overflow:hidden" -->
                <div v-if="form.pdf_format" >
                  <vue3-html2pdf
                    :show-layout="false"
                    :float-layout="true"
                    :enable-download="true"
                    :preview-modal="true"
                    :pdf-quality="2"
                    :manual-pagination="true"
                    :filename= this.generateFileName
                    pdf-format="a3"
                    pdf-orientation="portrait"
                    ref="htmlPdf"
                  >
                    <template v-slot:pdf-content>
                        <!-- <full-html  v-bind:key="'pdf-qr-card'+product.id" v-for="product in products" class="pdf-card" :product="product" /> -->
                    
                    <full-html type="pdf"  v-bind:key="'pdf-qr-card'+product.id" v-for="product in products" :product="product" />
                 </template>
                  </vue3-html2pdf>
                </div>
                <div v-else>
                  <div v-bind:key="'product_full_card'+product.id" v-for="(product,index) in products" :id="'full_qr_'+index">
                    <full-html class="full-qr-card" :product="product" />
                  </div>
                </div>
              </div>
            </div>

            <div style="height:0px; overflow:hidden"> 
              <div class="" id="generate_qr_only">
                
                <div v-for="(productGroup, index) in productChungsThree" :key="index" style="page-break-after:auto; overflow:hidden">
                  <div  v-bind:key="'only_qr_group' + index+ind" v-for="product, ind in productGroup"  :id="'only_qr_'+ index">
                    <div v-bind:key="'only_qr_'+product.id" :id="'only_qr_'+product.id">
                        <qr-code-only class="qr-code-only qr-pdf " :typepdf="form.pdf_format" :product="product"/>
                    </div>
                  </div>
                </div>
                

              </div>
            </div>

        </div>
    </div> 
  </div>

  <loading-with-info  v-if="generateFile.modal" :message="generateFileMessage"></loading-with-info>

</div>
</template>


<script>
  import { defineComponent, ref } from "vue"
  import { mapGetters, mapActions, mapMutations } from "vuex";
  import { useSnackbar } from "vue3-snackbar";
  import FullCard from "@/views/Products/Product/QrCode/FullCard.vue";
  import FullHtml from '@/views/Products/Product/QrCode/fullHtml.vue'
  import QrCodeOnly from "@/views/Products/Product/QrCode/QrCodeOnly.vue";
  import * as htmlToImage from 'html-to-image';
  import LoadingWithInfo from '@/components/helpers/LoadingWithInfo.vue';
  import { changeDpiDataUrl, changeDpiBlob } from 'dpi-tools';
  import JSZip from 'jszip';
  import { saveAs } from 'file-saver';
  import html2pdf from 'html2pdf.js'
  import Vue3Html2pdf from 'vue3-html2pdf'
  import BrandingApis from '@/controllers/BrandingApis';


  export default defineComponent({
    name: "QrCodeSelectType",
    components: {
      FullCard,
      FullHtml,
      QrCodeOnly,
      Vue3Html2pdf,
      LoadingWithInfo,
    },
    setup() {
      const loading = ref(false);
      const snackbar = useSnackbar();
      const form = ref({
        full_card: true,
        pdf_format: true,
      })
      const html2Pdf = ref(null);
      const vueHtml2Pdf = ref(false)
      const generateFile = ref({
        modal: false,
        completed:  0,
      })
      return {
        html2Pdf,
        vueHtml2Pdf,
        form,
        loading,
        snackbar,
        generateFile
      };
    },
    props: {
      count: {
        type: Number,
        required: true,
      },
      isSingle: {
        type: Boolean,
        required: true,
      },
      products: {
        type: Array,
        required: true,
      },
      closeCallBack: {
        type: Function,
        required: true,
      },
      back: {
        type: Function,
        required: true,
      },
      locationAllCategoriesObj:{
        type: Object,
        required: false,
        default: {},
      },
    },

    async mounted(){
      let branding = await BrandingApis.fetchBusinessBranding(this.vg_activeBusiness.id)
        if(branding.status==200){
          this.vm_setBranding(branding.data.payload)
        }
    },

    computed: {

      productChungsThree() {
        return this.sliceIntoChunks(this.products, 3)
        
      },

      generateFileMessage() {
        return `Generating <strong class="text-black text-md">${this.generateFile.completed}/${this.products.length}</strong> product image cards. Please wait..`;
      },

      ...mapGetters({
        vg_activeBusiness: 'auth/activeBusiness',
      }),

      htmlToPdfOptions() {
        return {
          image: { type: 'jpeg', quality: 0.55 },
          html2canvas: {
            scrollX: 0,
            scrollY: 0,
            useCORS: true,
            dpi: 192,
            scale: 1,
            letterRendering: true
          }
        }
      },

      /**
       * Generate  file name based on selection
       */
      generateFileName() {
    
        let singleProduct = this.products[0];

         if(this.form.full_card && this.form.pdf_format) {
            
            if(this.isSingle) {
              return singleProduct.name ? (singleProduct.name + '.pdf') : 'productCard.pdf';
            }
            return this.products.length  < 2 ? 'full-card-'+this.products.length+'-product' : 'full-card-'+this.products.length+'-products';

        } else if (!this.form.full_card && this.form.pdf_format) {

            if(this.isSingle) {
              return singleProduct.name ? (singleProduct.name + '.pdf') : 'productCard.pdf';
            }
            return this.products.length < 2 ? 'qrcode-only-'+this.products.length+'-product.pdf' : 'qrcode-only-'+this.products.length+'-products.pdf'

        } else if (this.form.full_card && !this.form.pdf_format) {
            
            if(this.isSingle) {
              return singleProduct.name ? (singleProduct.name+'.png') : 'productCard.png';
            }
            return this.products.length < 2 ? 'full-qrcode-'+this.products.length+'-product.png' : 'full-qrcode-'+this.products.length+'-products.png'

        } else if (!this.form.full_card && !this.form.pdf_format) {
    
            if(this.isSingle) {
              return singleProduct.name ? (singleProduct.name + '.png') : 'productCard.png';
            }
            return this.products.length  < 2 ? 'qrcode-only-'+this.products.length+'-product.png' : 'qrcode-only-'+this.products.length+'-products.png'
        
        } 
      },
    },

    methods: {
      sliceIntoChunks(arr, chunkSize) {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            let chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
      },
      
      /**
       * Create pdf or image
       */
      createNow() {
        if(this.form.full_card && this.form.pdf_format) {
          this.createPdfFull();
        } else if (!this.form.full_card && this.form.pdf_format) {
          this.createPdfQrCodeOnly();
        } else if (this.form.full_card && !this.form.pdf_format) {
          this.createFullCardImage();
        } else if (!this.form.full_card && !this.form.pdf_format) {
          this.createQrOnlyImage();
        } else {
          alert('Cannot find the data');
        }
      },

      /**
       * Create PDF full
       */
      
      async createPdfFull() {
        this.va_spinner(true);
        this.$refs.htmlPdf.generatePdf()
        this.$nextTick(()=> {
          this.va_spinner(false);
        })
      },

      /**
       * Create pdf of qrcode only
       */
      async createPdfQrCodeOnly() {
        this.va_spinner(true);
        let elementHTML = document.getElementById("generate_qr_only");
        html2pdf().set({ filename: this.generateFileName }).from(elementHTML).save();
        this.$nextTick(()=> {
          this.va_spinner(false);
        })
      },

      /**
       * create FullCard Image
       */
      async createFullCardImage() {

        const self = this;

        if(this.products.length == 1) {
          
          let elementHTML = document.getElementById("generate_qr_full");
          htmlToImage.toPng(elementHTML, {
            backgroundColor: '#FFFFFF',
            quality: 1,
            pixelRatio: 1,
            width: 900,
            height: 1425
          })
          .then(function (dataUrl) {
            // download(dataUrl, fileName);
            let link = document.createElement('a');
            link.download = self.generateFileName;
            link.href = changeDpiDataUrl(dataUrl, 300);
            link.click();
          });

        } else {

          this.generateFile.modal = true;
          const zip = new JSZip();
          var img = zip.folder('product qr images');
          let products = this.products;
          for(let i = 0; i < this.products.length; i++) {
            let productName = (products[i].name).replace(/\/|\/$/g, '')+' ('+products[i].variantName.replace(/\/|\/$/g, '')+')'+ '.png';
            let elementHTML = document.getElementById("full_qr_" + i);

            let data = await htmlToImage.toBlob(elementHTML,{
              backgroundColor: '#FFFFFF',
              quality: 1,
              pixelRatio: 1,
              width: 900,
              height: 1425
            })
            .then(async (d) => {
              self.generateFile.completed = (i+1);
              return await changeDpiBlob(d, 300).then(b => b);
            });
      
            img.file(productName, data);
          }
          this.generateFile.modal = false
          this.generateFile.completed = 0;
          let zipName = 'product_qr_'+ this.products.length  + '.zip';
          zip.generateAsync({type:"blob"}).then(function(content) {
              saveAs(content, zipName);
          });
        }


      },

      /**
       * createQrOnlyImage
       */
      async createQrOnlyImage() {
        
        const self = this;

        if(this.products.length == 1) {
          
          let elementHTML = document.getElementById("generate_qr_only");
          htmlToImage.toPng(elementHTML)
          .then(function (dataUrl) {
            // download(dataUrl, fileName);
            let link = document.createElement('a');
            link.download = self.generateFileName;
            link.href = dataUrl;
            link.click();
          });

        } else {

          this.generateFile.modal = true;
          const zip = new JSZip();
          const img = zip.folder('product qr images');
          let products = this.products;
          for(let i = 0; i < products.length; i++) {
            let productName = (products[i].name).replace(/\/|\/$/g, '')+' ('+products[i].variantName.replace(/\/|\/$/g, '')+')'+ '.png';
            let elementHTML = document.getElementById("only_qr_" + products[i].id);
            let data = await htmlToImage.toBlob(elementHTML)
            .then(d => {
              self.generateFile.completed = (i+1);
              return d;
            });
            img.file(productName, data);
          }
          this.generateFile.modal = false
          this.generateFile.completed = 0;
          let zipName = 'product_qr_'+ this.products.length  + '.zip';
          zip.generateAsync({type:"blob"}).then(function(content) {
              saveAs(content, zipName);
          });
        }
      },
      ...mapMutations({
        vm_setBranding: 'auth/mutateBranding'
      }),
      ...mapActions({
        va_spinner: 'spinner/setSpinner',
      })
    },
  });
 
</script>
