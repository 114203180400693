
import  { defineComponent , ref} from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';
import LocationInfoApis from '@/controllers/LocationInfoApis';
import LocationOperationHoursApis from '@/controllers/LocationOperationHoursApis';
import moment from 'moment';

export default defineComponent({
  name: "LocationInfoStoreHours",
  components: {
  },

  setup() {
    const form = <any>ref({
      operationHours: []
    });
    
    const loading = ref(false);
    const snackbar = useSnackbar();
   
    return {
      loading,
      form,
      snackbar
    };
  },
 

  computed: {
    ...mapGetters({
      vg_selectedLocation: 'location/selected'
    }),
  },

  watch: {
    'vg_selectedLocation': async function() {
      this.loading = true;
      await this.fetchLocationOperationHours()
      this.loading = false;
    },

  },

  async mounted() {
    this.loading = true;
    await this.fetchLocationOperationHours()
    this.loading = false;
    this.emitter.on("location-info-submit", (infoData) => {
      this.validateStoreHoursAndSubmit(infoData);
    });
  },

  beforeDestroy() {
    this.emitter.off('location-info-submit', () => {})
  },

  methods: {
    /**
     * Assign api values to frontend readable values  
     */
    assignValues(payload) {
      this.form.operationHours = [];
      if(payload && payload.length > 0) {
        payload.forEach(record => {
           let open24HrTime = record.openTime;
           let open12HrTime = moment(open24HrTime, ["HH:mm:ss"]);
           let close24HrTime = record.closeTime;
           let close12HrTime = moment(close24HrTime, ["HH:mm:ss"]);
  
          this.form.operationHours.push({
            day: record.day,
            dayName: record.dayName,
            holidayDate: record.holidayDate,
            holidayDateDisplay: moment(record.holidayDate).format('MM/DD'),
            isOpen: record.isOpen,
            is_valid_openTime_closeTime: true,
            openTime: {
              HH: open12HrTime.format('hh'),
              MM: open12HrTime.format('mm'),
            },
            openTimeTypeAm: open12HrTime.format('A') == 'AM' ?  true : false,
            closeTime: {
              HH: close12HrTime.format('hh'),
              MM: close12HrTime.format('mm'),
            },
            closeTimeTypeAm: close12HrTime.format('A') == 'AM' ?  true : false,
          })
        })
      }
    },
    /**
     * Fetch location operation hours
     */ 
    async fetchLocationOperationHours() {
      this.form.operationHours = [];
      let defaultWeeklyHours = [
            {
                day: 0,
                dayName: "Sunday",
                isOpen: true,
                openTime: {
                  HH:'01',
                  MM:'00',
                },
                openTimeTypeAm: true,
                closeTime: {
                  HH:'01',
                  MM:'01',
                },
                closeTimeTypeAm: true,
                is_valid_openTime_closeTime: true,
            },
            {
                day: 1,
                dayName: "Monday",
                isOpen: true,
                openTime: {
                  HH:'01',
                  MM:'00',
                },
                openTimeTypeAm: true,
                closeTime: {
                  HH:'01',
                  MM:'01',
                },
                closeTimeTypeAm: true,
                is_valid_openTime_closeTime: true,
            },
            {
                day: 2,
                dayName: "Tuesday",
                isOpen: true,
                openTime: {
                  HH:'01',
                  MM:'00',
                },
                openTimeTypeAm: true,
                closeTime: {
                  HH:'01',
                  MM:'01',
                },
                closeTimeTypeAm: true,
                is_valid_openTime_closeTime: true,
            },
            {
              day: 3,
              dayName: "Wednesday",
              isOpen: true,
              openTime: {
                HH:'01',
                MM:'00',
              },
              openTimeTypeAm: true,
              closeTime: {
                HH:'01',
                MM:'01',
              },
              closeTimeTypeAm: true,
              is_valid_openTime_closeTime: true,
            },
            {
              day: 4,
              dayName: "Thursday",
              isOpen: true,
              openTime: {
                HH:'01',
                MM:'00',
              },
              openTimeTypeAm: true,
              closeTime: {
                HH:'01',
                MM:'01',
              },
              closeTimeTypeAm: true,
              is_valid_openTime_closeTime: true,
            },
            {
              day: 5,
              dayName: "Friday",
              isOpen: true,
              openTime: {
                HH:'01',
                MM:'00',
              },
              openTimeTypeAm: true,
              closeTime: {
                HH:'01',
                MM:'01',
              },
              closeTimeTypeAm: true,
              is_valid_openTime_closeTime: true,
            },
            {
              day: 6,
              dayName: "Saturday",
              isOpen: true,
              openTime: {
                HH:'01',
                MM:'00',
              },
              openTimeTypeAm: true,
              closeTime: {
                HH:'01',
                MM:'01',
              },
              closeTimeTypeAm: true,
              is_valid_openTime_closeTime: true,
            }
          ];
      let res = await LocationOperationHoursApis.fetchLocationOperationHours();
      if(res.status == 200) {
        if(res.data.payload && res.data.payload.length > 0) {
          this.assignValues(res.data.payload);
        } else {
          this.form.operationHours = defaultWeeklyHours;
        }
      } else {
        this.form.operationHours = defaultWeeklyHours;
      }
    },


     /**
      * validate store hours and submit
      */
    async validateStoreHoursAndSubmit(infoData) {
      // validated
      for (let i = 0; i < this.form.operationHours.length; i++) {
        if(this.form.operationHours[i].is_valid_openTime_closeTime == false) {
          return this.snackbar.add({
            type: 'info',
            text: 'End time must be greater than start time on ' + this.form.operationHours[i].dayName,
            duration: 3000,
          })
        }
      }
    
      // Make api readable format data
      let operationHours = <any>Helpers.convertLocation12hoursTo24hoursLocationInfo(this.form.operationHours);
      // now hit 2 apis 1 for location info update
      // next store hours 
      let storeInfo = LocationInfoApis.updateLocationInfo(infoData);
      let storeOperationHours = LocationOperationHoursApis.createLocationOperationHours({
        operationHours: operationHours
      });
      const self = this;
      this.va_spinner(true);
      Promise.all([storeInfo, storeOperationHours]).then(async function (res) {
        // this is always update
        self.va_spinner(false);
        self.snackbar.add({
          type: 'info',
          text: res[0].status == 200 ? res[0].data.message : Helpers.catchErrorMessage(res[0].response.data),
          duration: 3000,
        })
        // this mean current location this has been change so vm location
        if(res[0].status == 200) {
          // f some data changed then only sync else leva it
          if(self._lodash.isEqual(self.vg_selectedLocation, res[0].data.payload) == false) {
            self.va_setAllLocations();
            self.vm_mutateSelectedLocation(res[0].data.payload);
          }
        }
        // this is alays create / update 201
        self.snackbar.add({
          type: 'info',
          text: res[1].status == 201 ? res[1].data.message : Helpers.catchErrorMessage(res[1].response.data),
          duration: 3000,
        })
      })

    },

     /**
     * Validate start end time
     */
    async validateStartEndTime(index) {
      console.log('validateStartEndTime')
       this.form.operationHours = await Helpers.validateStartEndTime(this.form.operationHours, index);
    },

    /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      this.form = res.data;
      return res.error ? false : true;
    },

    save() {
      this.emitter.emit("validate-location-info", true);
      // 1no ma info save huna paryo uta validation success vayepaxi
      // 2 ma yeta validation agrnu paryo
      // 3 2 tai success vayepaxi 2 ta api hit garna paryo
    },
    ...mapMutations({
      vm_mutateSelectedLocation: "location/mutateSelectedLocation"
    }),

    ...mapActions({
      va_spinner: "spinner/setSpinner",
      va_setAllLocations: "location/setAllLocations",
    })
  }
});
