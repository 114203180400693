import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-40" }
const _hoisted_2 = { class: "capitalize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_block_loading = _resolveComponent("block-loading")!
  const _component_new_table_list = _resolveComponent("new-table-list")!

  return (_ctx.table.loading)
    ? (_openBlock(), _createBlock(_component_block_loading, { key: 0 }))
    : (_openBlock(), _createBlock(_component_new_table_list, {
        key: 1,
        table: _ctx.table,
        onRowClicked: _ctx.setActive,
        onDragged: _ctx.sortedRow,
        onDisabletoggle: ($event: any) => (true),
        onDraggable: ($event: any) => (true),
        onPaginate: _ctx.paginate
      }, {
        title: _withCtx((data) => [
          _createElementVNode("p", _hoisted_1, _toDisplayString(data.data.title), 1)
        ]),
        applyDiscountOn: _withCtx((data) => [
          _createElementVNode("span", _hoisted_2, _toDisplayString(data.data.applyDiscountOn ? data.data.applyDiscountOn.replace(/_/g, ' ') : '-'), 1)
        ]),
        discount: _withCtx((data) => [
          _createElementVNode("span", null, _toDisplayString(data.data.discountType == 'flat' ? '$' : '') + _toDisplayString(data.data.discount.toFixed(2)) + _toDisplayString(data.data.discountType == 'flat' ? '' : '%'), 1)
        ]),
        startDate: _withCtx((data) => [
          _createElementVNode("span", null, _toDisplayString(data.data.startDate ? _ctx.formatDate(data.data.startDate) : '-'), 1)
        ]),
        endDate: _withCtx((data) => [
          _createElementVNode("span", null, _toDisplayString(data.data.endDate ? _ctx.formatDate(data.data.endDate) : '-'), 1)
        ]),
        _: 1
      }, 8, ["table", "onRowClicked", "onDragged", "onPaginate"]))
}