
import { defineComponent, ref } from 'vue'
import { LocationMarkerIcon } from '@heroicons/vue/outline'
import { mapGetters} from "vuex";

export default defineComponent({
    components: {
        LocationMarkerIcon
    },
    setup() {
        const locations = ref([])
        const toggle = ref(false)

        return {locations, toggle}
    },
    mounted(){
        if(this.vg_locations && this.vg_locations.length>0){
            this.locations = this.vg_locations?.sort((a, b) => a.name.localeCompare(b.name))
        }
    },
    watch: {
        vg_locations(){
            if(this.vg_locations && this.vg_locations.length>0){
                this.locations = this.vg_locations?.sort((a, b) => a.name.localeCompare(b.name))
            }else{
                this.locations = []
            }
        },
    },
    computed: {
        ...mapGetters({
            vg_locations: "location/list",
            vg_isUserTypeSuper: "auth/isUserTypeSuper",
            vg_locationSelected: "location/selected",
        })
    },
    methods: {
        toggleMenu(){
            this.toggle = !this.toggle
        },
        closeMenu(){
            this.toggle = false
        },
        newLocation(){
            this.$emit('clickNewlocation')
            this.closeMenu()
        },
        selectTheLocation(location){
            this.closeMenu()
            this.$emit('selectTheLocation', location)
        }
    }
})
