
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
import LocationPosStationApis from "@/controllers/LocationPosStationApis";
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';
import ReturnConfirmModal from "@/components/general/dialog/ReturnConfirmModal.vue";

export default defineComponent({
  name: "LocationPosAdd",
  components: {
   ReturnConfirmModal
  },
  setup() {
    const snackbar = useSnackbar();
    const deleteObj = ref({
      modal: false,
      obj: {},
    });
    const form = ref({
      name: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      // barcode_scanner_serial: {
      //   value: '',
      //   error_type: '',
      //   error_message: '',
      //   validations: ['required'],
      // },
      status: false,
      acceptOnlineOrders: false
    });
    return {
      form,
      snackbar,
      deleteObj
    };
  },
  props:{
    clearComponent: {
      type: Function,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },

  watch:{
    'item'(val) {
      this.assignItemValue();
    },
     // Fields cannot have white spaces
    // 'form.barcode_scanner_serial.value'(val){
    //   this.form.barcode_scanner_serial.value = val.replaceAll(/\s/g,'').trim();
    // },
  },

  computed: {
    ...mapGetters({
     
    }),
  },

  mounted() {
    this.assignItemValue();
  },
 
  methods: {

   assignItemValue() {
      this.form.name.value = this.item.name;
      this.form.status = this.item.status;
      this.form.acceptOnlineOrders = this.item.acceptOnlineOrders;
    },
    
     /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },


    /**
     * Add new location Tax
     */
    async update() {
      let validate = await this.validateFields(['name']);
      if(!validate) {
        return ;
      }
      this.va_spinner(true);
      let res = await LocationPosStationApis.updateLocationPosStation(this.item.id, {
          name: this.form.name.value,
          status: this.form.status,
          acceptOnlineOrders: this.form.acceptOnlineOrders
      });
      this.va_spinner(false);
        this.snackbar.add({
        type: 'info',
        text: res.status == 200 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
      if(res.status == 200 ) {
        this.clearComponent();
        this.emitter.emit("refresh-location-pos-station-list", true);
      }
    },

     /**
     * Delete location tax
     */
    async deleteRecord() {
      this.deleteObj = {
        modal: true,
        obj: this.item,
      };
    },
    
    /**
     * Close delete modal
     */
    closeDeleteModal() {
      this.deleteObj = {
        modal: false,
        obj: {},
      };
    },

    /**
     * Delete Pos
     */
    async deleteNow(obj) {
      this.va_spinner(true);
      let res = await LocationPosStationApis.deleteLocationPosStation(obj.id);
      this.va_spinner(false);
        this.snackbar.add({
        type: 'info',
        text: res.status == 200 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
      if(res.status == 200 ) {
        this.emitter.emit("refresh-location-pos-station-list", true);
        this.clearComponent();
      }
    },

    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  },
});

