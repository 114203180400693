<template>

    <block-loading v-if="loading" />
    <table-lite
      v-else
      :class="'admin-table'"
      :is-loading="table.isLoading"
      :is-slot-mode='true'
      :columns="columns"
      :rows="table.rows"
      :rowClasses="customRowClass"
      :total="table.totalRecordCount"
      :sortable="table.sortable"
      @is-finished="table.isLoading = false"
      :page-size='table.pagesize'
      @do-search="doSearch"
      :pageOptions='table.pageOptions'
      :has-checkbox="false"
      :checked-return-type="'id'"
      :is-hide-paging="false"
      :pageSizeChangeLabel="'pageSizeChangeLabel'"
      :messages="{
        pagingInfo: 'Total: {2}',
        pageSizeChangeLabel: 'Items per Page',
        gotoPageLabel: '',
        noDataAvailable: 'No records found!',
      }"
    >
      <template v-slot:name="data">
        <div :class="'cursor-pointer hover:text-theme-green' " @click.prevent="setActive(data.value)">{{ data.value.name }}</div>
      </template>
      
    </table-lite>

</template>

<script>
import  { defineComponent , ref} from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';

export default defineComponent({
  components: {

  },
  props: {
    iscomponentactive: {
      type: Boolean,
      required: false,
    },
    selectedCallback: {
      type: Function,
      required: true,
    },
    clearComponent: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const snackbar = useSnackbar();
    const confirmStatusObj = ref({
      modal: false,
      obj: {
        status: Boolean,
        id: Number,
      },
    });
    const activeItem = ref({});
    const loading = ref(false);
    const columnsMain = ref([
      {
        label: "Reason Name",
        field: "name",
        sortable: true,
      },
      {
        label: "Reason Code",
        field: "reasonCode",
        sortable: true,
      },
      {
        label: "Description",
        field: "description",
        sortable: true,
      },
   
    ]);

    const table = ref({
      isLoading: false,
      rows: [],
      totalRecordCount: 0,
      sortable: {
        order: "",
        sort: "",
      },
      pagesize: 2,
      pageOptions: [{ value: 2, text: 2 }, { value: 5, text: 5 } ],
      count: 0,
    });

    return  {
      table,
      columnsMain,
      activeItem,
      loading,
      confirmStatusObj,
      snackbar,
    };
  },

  async mounted() {
    this.fetchList();
    this.emitter.on("refresh-product-reasoncode-list", data => {
      this.fetchList();
    });
  },

  beforeDestroy() {
   this.emitter.off('refresh-product-reasoncode-list', () => {})
  },

  watch: {
    'vg_selectedLocation'(val) {
      // if location selected is changed fetch the data
      this.fetchList();
    },
  },

  computed: {
    ...mapGetters({
      vg_selectedLocation: 'location/selected'
    }),

    /**
     * Compute columns
     */
    columns() {
      if(this.iscomponentactive){
        let removeFields= ['description'];
        return this.columnsMain.filter(item => {
            if( removeFields.indexOf(item.field) == -1 ) {
              return item;
            }
        })
      }
      return this.columnsMain;
    },
  },

  methods: {
    /**
     * Fetch list
     */
    async fetchList() {
      this.loading = true;
     
      this.loading = false;
    },

    /**
     * Select the item
     */
    setActive(item) {
      // Select unselect the item
      if(this.activeItem.id) {
        let oldactive = document.getElementsByClassName('thisid_'+this.activeItem.id);
        if(oldactive.length > 0){
          oldactive[0].classList.remove("active-row");
        }
      }
      this.activeItem = item;
      let newActive = document.getElementsByClassName('thisid_' + this.activeItem.id);
      if(newActive.length > 0){
        newActive[0].classList.add("active-row");
      }
      this.selectedCallback(item);
    },

    /**
     * Create custom class on tr for active colors
     */
    customRowClass(item) {
      return 'thisid_' + item.id;
    },

  

    /**
     * Search now
     */
    doSearch(offset, limit, order, sort) {
      if(order && sort) {
         this.table.rows = this._lodash.orderBy(this.table.rows, [order],[sort]);
      }
    },

    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })

  }
});
</script>

