<template>
    <div class="bg-[#23242A] text-white h-screen appheader overflow-y-auto p-6">
        <div class="grid grid-cols-1">
            <div>
                <Nav />
                <block-loading v-if="loading" />
                <div v-else class="bg-[#2B2C32] rounded-lg px-8 mt-1 pb-3">
                    <div class=" flex justify-between items-center pt-2">
                        <h1 class="text-white font-semibold text-[24px]">CRM</h1>
                        <button  @click.prevent="addNew()" class="bg-white text-theme-green rounded  mt-2 px-4 py-2 hover:bg-slate-300"><span class=" tracking-widest font-medium">SAVE</span></button>
                    </div>
                    <div class="flex gap-3 xl:flex-row flex-col items-start mb-3">
                        <div class="w-full xl:w-1/4">
                            <div :class="'input-wrapper ' + (form.provider.error_type)">
                                <label class="text-xs mb-1 font-normal">Provider</label>
                                <input placeholder="12341234" v-model="form.provider.value" type="text" class="block rounded bg-gray-black border-none w-full text-sm text-theme-white">
                                <p class="field-error label-text mt-1" v-if="form.provider.error_message" v-text="form.provider.error_message"></p>
                            </div>
                        </div>
                        <div class="w-full xl:w-1/4">
                            <div :class="'input-wrapper ' + (form.siteId.error_type)">
                                <label class="text-xs mb-1 font-normal">Site ID</label>
                                <input placeholder="12341234" v-model="form.siteId.value" type="text" class="block rounded bg-gray-black border-none w-full text-sm text-theme-white">
                                <p class="field-error label-text mt-1" v-if="form.siteId.error_message" v-text="form.siteId.error_message"></p>
                            </div>
                        </div>
                        <div class="w-full xl:w-1/4">
                            <div :class="'input-wrapper ' + (form.apiKey.error_type)">
                                <label class="text-xs mb-1 font-normal">API Key</label>
                                <input placeholder="12341234" v-model="form.apiKey.value" type="text" class="block rounded bg-gray-black border-none w-full text-sm text-theme-white">
                                <p class="field-error label-text mt-1" v-if="form.apiKey.error_message" v-text="form.apiKey.error_message"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, ref} from "vue"
import Nav from '@/views/Location/Integrations/Nav.vue';
import { mapGetters, mapActions, mapMutations } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import CrmApis from "@/controllers/CrmApis";
import Helpers from '@/helpers';

export default defineComponent({
  name: "LocationDevicesPos",
  components: {
    Nav,
  },
  setup() {
    const snackbar = useSnackbar();
    const loading = ref(false)
    const payload = ref({})
    const form = ref({
      provider: {
        id: '',
        value: '', 
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      siteId: {
        id: '',
        value: '', 
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      apiKey: {
        id: '',
        value: '', 
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
    });

    return {
      payload,
      form,
      snackbar,
      loading
    };
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData(){
      this.loading = true
      let res = await CrmApis.fetchAll()
      if(res.status==200 && res.data.payload){
        this.payload = res.data.payload
        this.form.provider.value = this.payload.provider
        this.form.siteId.value = this.payload.siteId
        this.form.apiKey.value = this.payload.apiKey
      }else{
        this.form.provider.value=''
        this.form.siteId.value=''
        this.form.apiKey.value=''
      }
      this.loading = false
    },
    
     /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },


    /**
     * Add 
     */
    async addNew() {
      let validate = await this.validateFields(['provider', 'siteId', 'apiKey']);
      if(!validate) {
        return ;
      }
      let res
      this.va_spinner(true);
      let formData = Helpers.getFormKeyValue(this.form)
      if(this.payload.id){
        res = await CrmApis.update(formData);
        this.snackbar.add({
            type: 'info',
            text: res.status == 200 ? "Crm credential updated." : Helpers.catchErrorMessage(res.response.data),
            duration: 3000,
        })
      }else{
        res = await CrmApis.create(formData);
        this.snackbar.add({
            type: 'info',
            text: res.status == 201 ? "Crm credential added." : Helpers.catchErrorMessage(res.response.data),
            duration: 3000,
        })
      }
      this.va_spinner(false);
    },
    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  }

})
</script>
