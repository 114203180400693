/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */
import Env from '@/env';
const apiVersion = 'api';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /**
   * Fetch all  location pos station
   */
  fetchAllLocationPosStation() {
    const url = `${Env.loginUrl}/${apiVersion}/location-pos-stations/all`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * Add new  location pos station
   */
  addLocationPosStation(data) {
    const url = `${Env.loginUrl}/${apiVersion}/location-pos-stations/create`;
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * Update  location pos station
   */
  updateLocationPosStation(locationPosStationId, data) {
    const url = `${Env.loginUrl}/${apiVersion}/location-pos-stations/${locationPosStationId}/update`;
    return AuthApis.patchApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * Delete location pos station
   */
  deleteLocationPosStation(locationPosStationId) {
    const url = `${Env.loginUrl}/${apiVersion}/location-pos-stations/${locationPosStationId}/remove`;
    return AuthApis.deleteApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


}