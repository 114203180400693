<template>
  <div class="mt-5">
    <div class="text-white flex-1 col-span-1 border-0 rounded-t-xl overflow-hidden">
      <div class="px-4 py-2 text-white">
        <div class="flex justify-between items-center">
          <div class="font-bold tracking-wider leading-tight text-2xl mt-0 capitalize">
            Moneris
          </div>
          <div class="flex gap-2 items-center">
            <!-- <mdicon @click.prevent="clearComponent" size="32" class="close-icon flex justify-end cursor-pointer hover:opacity-60 mb-2 " name="close" /> -->
            <button @click.prevent="addNew()" class="h-10 hover:bg-theme-darkgreen border-0 hover:text-white rounded-md cursor-pointer text-green-400 bg-white py-2 px-4 uppercase ">
              SAVE
            </button>
            <!-- <button @click="deleteRecord()" class="cursor-pointer rounded-md bg-white text-theme-red py-2 px-4  hover:bg-theme-darkgreen hover:text-white mr-3">DELETE</button> -->
          </div>
        </div>
      </div>

      <div class="pt-3 bg-[#2B2C32] px-[16px] py-[16px] rounded-lg rounded-t-none">
        <div v-if="loading" >
          <block-loading/>
        </div>
        <div v-else class="flex flex-col gap-3 items-start mb-3">        
            <div class="w-[400px] ">
              <div :class="'input-wrapper ' + (form.store_id.error_type)">
                <label class="text-xs mb-1">Moneris Store Id :</label>
                <input placeholder="monca123, mongo123"  @keyup="validateFields(['store_id'])" v-model="form.store_id.value" class="block rounded bg-gray-black w-full" type="text" />
                <p class="field-error label-text mt-1" v-if="form.store_id.error_message" v-text="form.store_id.error_message"></p>
             </div>
            </div>

            <div class="w-[400px] ">
              <div :class="'input-wrapper ' + (form.api_token.error_type)">
                <label class="text-xs mb-1">API Token:</label>
                <input placeholder="Token"  @keyup="validateFields(['api_token'])" v-model="form.api_token.value" class="block rounded bg-gray-black w-full" type="text" />
                <p class="field-error label-text mt-1" v-if="form.api_token.error_message" v-text="form.api_token.error_message"></p>
              </div>
            </div>

          </div>
        </div>
    </div>
  </div>
</template>


<script>
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
import LocationIntegrationApis from "@/controllers/LocationIntegrationApis";
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';

export default defineComponent({
  name: "LocationPosAdd",
  props:{
    clearComponent: {
      type: Function,
      required: true,
    },
    reloadIt: {
      type: Boolean,
      required: false
    }
  },
  components: {
   
  },
  setup() {
    const snackbar = useSnackbar();
    const loading = ref(false)
    const form = ref({
      store_id: {
        id: '',
        value: '', 
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      api_token: {
        id: '',
        value: '', 
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
    });


    return {
      form,
      snackbar,
      loading
    };
  },

  mounted() {
    this.fetchData()
  },

  watch:{
    reloadIt(){
      this.fetchData()
    },
    // terminalId cannot have whitespaces
    'form.store_id.value'(val){
      if(val) {
        // val = val.toLowerCase();
        val = val.replaceAll(/\s/g,'').trim();
        this.form.store_id.value = val;
      } else {
        this.form.store_id.value = '';
      }
    },
    // merchantId cannot have whitespaces
    'form.api_token.value'(val){
      if(val) {
        // val = val.toLowerCase();
        val = val.replaceAll(/\s/g,'').trim();
        this.form.api_token.value = val;
      } else {
        this.form.api_token.value = '';
      }
    },
  },

  computed: {
   
      ...mapGetters({
          vg_selectedLocation: 'location/selected'
      }),
  },
 
  methods: {
    async fetchData(){
      this.loading = true
      let res = await LocationIntegrationApis.fetchAll(this.vg_selectedLocation.id, 'moneris')
      if(res.status==200 && res.data.payload){
        for(let i=0; i<res.data.payload.length; i++){
          if(res.data.payload[i].integrationKey=="store_id"){
            this.form.store_id.id = res.data.payload[i].id
            this.form.store_id.value = res.data.payload[i].integrationValue
          }else if(res.data.payload[i].integrationKey=="api_token"){
            this.form.api_token.id = res.data.payload[i].id
            this.form.api_token.value = res.data.payload[i].integrationValue
          }
        }
      }else{
        this.form.store_id.value=''
        this.form.api_token.value=''
      }
      this.loading = false
    },
    
     /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },


    /**
     * Add 
     */
    async addNew() {
      let validate = await this.validateFields(['store_id', 'api_token']);
      if(!validate) {
        return ;
      }
      this.va_spinner(true);
      let res
      if(this.form.store_id.id){
        let data = {integrations: [
          {integrationProvider: "moneris", integrationKey:"store_id", integrationValue:this.form.store_id.value},
          {integrationProvider: "moneris", integrationKey:"api_token", integrationValue:this.form.api_token.value},
        ]}
        res = await LocationIntegrationApis.createMany(data);
        this.snackbar.add({
          type: 'info',
          text: (res.status==201) ? 'Successfully updated records!' : 'All records not updated.',
          duration: 3000,
        })
      }else{
        let formData = Helpers.getFormKeyValue(this.form)
        let data = {integrations: [
          {integrationProvider: 'moneris', integrationKey: 'store_id', integrationValue: formData.store_id},
          {integrationProvider: 'moneris', integrationKey: 'api_token', integrationValue: formData.api_token},
        ]}
        res = await LocationIntegrationApis.createMany(data);
        this.snackbar.add({
          type: 'info',
          text: res.status == 201 ? "Moneris credential added." : Helpers.catchErrorMessage(res.response.data),
          duration: 3000,
        })
        console.log(res)
      //   this.form.id = res.data.payload.id
      }
      this.va_spinner(false);
      this.$emit('doUpdated')
    },

    deleteRecord(){
      let self = this
      this.confirm('Are you sure to delete this?').then(async () => {
        self.va_spinner(true)
        let res = await LocationIntegrationApis.delete(this.form.id)
        await this.fetchData()
        this.snackbar.add({
          type: 'info',
          text: res.status == 200 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
          duration: 3000,
        })
        self.va_spinner(false)
      })
    },


    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  },
});

</script>