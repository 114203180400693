
    import {defineComponent, ref, shallowRef} from "vue"
    import Nav from '@/views/Location/Integrations/Nav.vue';
    import PaymentTerminals from '@/views/Location/Integrations/PaymentTerminals/Index.vue';
    import AccessToken from '@/views/Location/Integrations/AccessToken/Index.vue';
    import OnlineMoneris from '@/views/Location/Integrations/Moneris/Online.vue'
    import InStoreMoneris from '@/views/Location/Integrations/Moneris/InStore.vue'
    import LocationIntegrationApis from "@/controllers/LocationIntegrationApis";
    import { mapGetters } from "vuex";
    

    export default defineComponent({
        name: "LocationDevicesPos",
        components: {
          Nav,
          PaymentTerminals,
          AccessToken,
          OnlineMoneris,
          InStoreMoneris
        },
        setup(){
          const loading = false
          const reloadIt = ref(false)
          const paymentProcessors = ref([
            {
              name: 'clover'
            },
            {
              name: 'moneris'
            },
          ])
          const currentOnlineProcessor =ref(null)
          const currentInStoreProcessor =ref(null)

          return {
            loading,
            reloadIt,
            paymentProcessors,
            currentOnlineProcessor,
            currentInStoreProcessor
          }
        },

        async mounted(){
          this.loading =true
          let {data} = await LocationIntegrationApis.fetchPaymentProvider()
          if(data?.payload){
            this.currentOnlineProcessor = data.payload.ecommercePaymentProvider
            this.currentInStoreProcessor = data.payload.instorePaymentProvider
          }
          this.$nextTick(() => {
            this.loading = false
          })
        },

        watch: {
          async currentOnlineProcessor(){
            if(!this.loading && (this.currentInStoreProcessor || this.currentOnlineProcessor)){
              let data = {
                instorePaymentProvider: this.currentInStoreProcessor?this.currentInStoreProcessor:null,
                ecommercePaymentProvider: this.currentOnlineProcessor?this.currentOnlineProcessor:null
              }
              await LocationIntegrationApis.updatePaymentProvider(data)
            }
          },
          async currentInStoreProcessor(){
            if(!this.loading && (this.currentInStoreProcessor || this.currentOnlineProcessor)){
              let data = {
                instorePaymentProvider: this.currentInStoreProcessor?this.currentInStoreProcessor:null,
                ecommercePaymentProvider: this.currentOnlineProcessor?this.currentOnlineProcessor:null
              }
              console.log(data)
              await LocationIntegrationApis.updatePaymentProvider(data)
            }
          }
        },

        computed: {
          ...mapGetters({
            vg_selectedLocation: 'location/selected'
          }),
        },
      
     
        methods: {

          doUpdated(){
            this.reloadIt = !this.reloadIt
          },

           /**
           * selectedPaymentProcessors
           */
          selectedPaymentProcessors(data){  
            console.log(data)
          },

            
        },
       
   
    })


