/**
 * @module  
 *  Apis must need auth details to trigger url - AuthApi
 */

import Env from '@/env';
const apiVersion = 'api';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
    /**
     * Fetch
     */
    fetchSetting() {
        const url = `${Env.loginUrl}/${apiVersion}/location-cannabis-settings/show`;
        return AuthApis.getApi(url, null)
         .then(res => res)
         .catch(err => {
           ApiHelper.detectStatusAndRedirect(err);
           return err;
         });
    },


     /**
     * Add New 
     */
     create(data) {
       const url = `${Env.loginUrl}/${apiVersion}/location-cannabis-settings/create`;
       return AuthApis.postApi(url, null, data)
        .then(res => res)
        .catch(err => {
           ApiHelper.detectStatusAndRedirect(err);
           return err;
        });
   },

   
    
}