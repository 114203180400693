<template>
<div class="flex flex-col z-[99]" tabindex="0" @blur="close">
    <div class="flex flex-row justify-between h-10 p-2" :class="disabled?'cursor-not-allowed':'cursor-pointer'" @click="toggleOpen">
        <div class="flex" :class="selected?'text-white':'text-grey'">
            <span v-if="selected" class="w-4 h-4 mr-2 rounded self-center inline-block" :style="selected?`background-color:${getColor(selected)};`:''"></span>
            <span class="inline-block truncate">{{selected?getName(selected):placeholder}}</span>
        </div>
        <div class="mt-2">
            <svg v-if="open" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.83008 0.624219L0.830078 6.62422L1.90508 7.69922L6.83008 2.74922L11.7551 7.67422L12.8301 6.59922L6.83008 0.624219Z" fill="white"/>
            </svg>
            <svg v-else width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.83325 7.37578L0.833252 1.37578L1.90825 0.300781L6.83325 5.25078L11.7583 0.325781L12.8333 1.40078L6.83325 7.37578Z" fill="white"/>
            </svg>
        </div>
    </div>
    <div v-if="open" class="flex flex-col z-[999] absolute w-[200px] max-h-96 overflow-auto" :class="classValue?classValue:'top-[90px]'">
        <div @click="select('')" class="relative grid grid-cols-4 p-2 border-t border-light-black drop-shadow transition duration-500 bg-theme-modalbg hover:bg-theme-grey cursor-pointer" :key="'default'" value="">
            <div class="mr-2 self-center"><span class="w-8 h-8 rounded inline-block"></span></div>
            <div class="col-span-3 inline-block self-center">{{placeholder}}</div>
        </div>
        <div @click="select(option.id)" :class="option.parent?'pl-[20px]':''" class="relative grid grid-cols-4 p-2 border-t border-light-black drop-shadow transition duration-500 bg-theme-modalbg hover:bg-theme-grey cursor-pointer" v-for="option in options" :key="'brd-'+option.id" :value="option.id">
            <div>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12Z" fill="white"/>
                </svg>
            </div>
            <div class="col-span-3 inline-block break-all">{{option.name}}</div>
        </div>
    </div>
</div>
</template>
<script>
import {defineComponent} from 'vue'
export default defineComponent({
    props: {
        modelValue: String,
        placeholder:String,
        options: Array,
        disabled: Boolean,
        classValue: String
    },
    data(){
        return {
            open: false,
            selected: ''
        }
    },
    mounted(){
        this.selected = this.modelValue
    },
    watch: {
        modelValue(){
            this.selected = this.modelValue
        }
    },
    methods: {
        toggleOpen(){
            if(!this.disabled){
                this.open = !this.open
            }
        },
        close(){
            this.open = false
        },
        isAdded(id){
            return this.selected.includes(id)
        },
        getName(id){
            if(!id) return
            
            if(id=='noCategory'){
                return 'No Category'
            }else{
                let obj = this.options.find(each => each.id==id)
                if(obj) return obj.name
            }
        },
        getColor(id){
            let obj = this.options.find(each => each.id==id)
            if(obj) return obj.posColour
        },
        select(id){
            this.selected = id
            this.$emit('update:modelValue', this.selected)
            this.close()
        }
    }
})
</script>