
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
import Nav from '@/views/Inventory/Audits/Report/Nav.vue';
import Helpers from '@/helpers'
import moment from 'moment'
import BusinessProductApi from '@/controllers/BusinessProductApis'
import { useSnackbar } from "vue3-snackbar"

  export default defineComponent({
  name: "ComplianceReport",
  components: {
    Nav,
  },

  setup() {
    const snackbar = useSnackbar();
    const action = ref<object>({
      component: '',
      data: {},
    });
    const year = ref('')
    const month = ref('')
    const monthName = ref(['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'])
    return {
      snackbar,
      action,
      year,
      month,
      monthName
    };
  },

  computed: {
    years () {
      const year = new Date().getFullYear()
      // check if month is valid
      // if(this.month && !this.isFuture(this.month)){
        
      // }else if(this.year){
      //   this.month = ''
      // }
      return Array.from({length: year - 2015}, (value, index) => 2016 + index)
    },
    months () {
      const month = moment.months();
      return month
    },
    can_edit_audit() {
        return Helpers.getMyPermission('can_edit_audit')
    },
    ...mapGetters({
     vg_selectedLocation: 'location/selected',
     vg_selectedBusiness: 'auth/activeBusiness',
    }),
  },


  beforeDestroy() {
    this.emitter.off('reset-product-categories-component', () => {});
    this.emitter.off('refresh-product-categories-list', () => {});
  },
  
 
  methods: {
    isFuture(month){
      if(moment().format('YYYY')==this.year && moment().format('M')<=month)
        return true
      else
        return false
    },

    /**
     * Change add / update component
     */
    addUpdate(val: any) {
      this.action = val;
    },

    /**
     * Capture selected item
     */
    selectedItem(item) {
      this.$router.push({name: 'ComplianceReport', params: {id: item.id}})
    },

    async generateReport(){
      this.vm_spinner(true)
      let data = {
        month: this.month,
        year: this.year
      }
      let res = await BusinessProductApi.generateReport(this.vg_selectedLocation.id, data)
      this.snackbar.add({
          type: 'info',
          text: this.getMessage(res),
          duration: 3000,
      })
      if(res.status==201 || res.status==200){
        const url = window.URL.createObjectURL(new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}));
        const link = document.createElement('a');
        const filename = res.headers['x-suggested-filename']?res.headers['x-suggested-filename']:`Monthly-Report-${this.monthName[this.month]}-${this.year}.xlsx`
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        document.body.removeChild(link)
        link.click();
      }

      this.vm_spinner(false)
    },
    getMessage(res){
      if(res.status && (res.status==201 || res.status==200)){
        return 'Compliance report is generated.'
      }else if(res.response.status==400){
        return 'Please set your compliance provider in integration settings.'
      }else if(res.response.status==404){
        return "Data not found!"
      }else{
        return Helpers.catchErrorMessage(res.response.data)
      }
    },
    ...mapMutations({
      vm_spinner: 'spinner/mutateSpinner'
    }),
    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  },
});

