
  import  { defineComponent , ref} from "vue";
  import { mapGetters, mapActions, mapMutations } from "vuex";
  import LocationIntegrationApis from "@/controllers/LocationIntegrationApis";
  import { useSnackbar } from "vue3-snackbar";
  import Helpers from '@/helpers';
  import ReturnConfirmModal from "@/components/general/dialog/ReturnConfirmModal.vue";
  import Nav from '@/views/Location/Integrations/Nav.vue';


  export default defineComponent({
    components: {
      ReturnConfirmModal,
      Nav
    },
    props: {
      iscomponentactive: {
        type: Boolean,
        required: false,
      },
      selectedItemCallback: {
        type: Function,
        required: true,
      },
      clearComponent: {
        type: Function,
        required: true,
      },
    },
    setup() {
      const confirmStatusObj = ref({
        modal: false,
        obj: {},
      });
      const activeItemObject = ref<any>({});
      const initialLoading = ref<Boolean>(false);
      const accessTokenList:any = ref([]);
      const snackbar = useSnackbar();
      return  {
        activeItemObject,
        initialLoading,
        confirmStatusObj,
        accessTokenList,
        snackbar
      };
    },
  
    async mounted() {
      this.initialLoading = true;
      await this.fetchList();
      this.initialLoading = false;
      this.emitter.on("refresh-location-access-token-list", data => {
        this.fetchList();
      });
      this.emitter.on("reset-location-access-token-component", data => {
        this.activeItemObject = {};
      });
    },
  
    beforeDestroy() {
      this.emitter.off('refresh-location-access-token-list', () => {});
      this.emitter.off('reset-location-access-token-component', () => {});
    },
  
    watch: {
      'vg_selectedLocation': async function (val) {
        // if location selected is changed fetch the data
        this.initialLoading = true;
        await this.fetchList();
        this.initialLoading = false;
      },
    },
  
    computed: {
      ...mapGetters({
        vg_selectedLocation: 'location/selected'
      }),
    },
  
    methods: {
      /**
       * Fetch list
       */
      async fetchList() {
        let res = await LocationIntegrationApis.fetchAll(this.vg_selectedLocation.id);  
        console.log(res)
        if(res.status == 200) {
          this.accessTokenList = res.data.payload;
        } else {
          this.accessTokenList = []; 
        }
      },
  
      /**
       * Set active
       */
      setActiveItem(item) {
        this.activeItemObject = item;
        this.selectedItemCallback(item);
      },
      /**
       * Close Confirm status modal
       */
      closeConfirmStatusModal() {
        this.confirmStatusObj = {
          modal: false,
          obj: {},
        };
      },
      /**
       * Open confirm satus modal
       */
      openConfirmStatusModal(obj) {
        this.confirmStatusObj = {
          modal: true,
          obj,
        };
      },

      /**
       * Accesstoken display
       */

      visibleToken(token) {
        if(!token)
        return;
        return `${String(token).slice(0, 4)}*****${String(token).slice(-4)}`

      },
  
  
      ...mapActions({
        va_spinner: "spinner/setSpinner",
      })
      
    }
  });
  