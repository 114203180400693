export default {
    undefinedToEmptyString(value){
        if(value===undefined){
            value = ""
        }
        return value
    },
    undefinedOrNullToEmptyString(value){
        if(value===undefined || value===null){
            value = ""
        }
        return value
    },
    undefinedToZero(value){
        if(value===undefined){
            value = 0
        }
        return value
    },
    undefinedToNull(value){
        if(value===undefined){
            value = null
        }
        return value
    },
    undefinedToDefault(value, defaultValue){
        if(value===undefined){
            value = defaultValue
        }
        return value
    },
    emptyStringToNull(value){
        if(value===""){
            value = null
        }else if(value){
            value = parseFloat(value)
        }
        return value
    },
    roundOff(value, decimal=2){
        return (Math.round(value*100)/100).toFixed(decimal)
    },
    capitalize(string){
        if(!string) return

        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    arrayOfStringToNumber(stringArray){
        return stringArray.map((item) => {
            return parseInt(item);
        });
    }
}