<template>
    <div class="bg-[#23242A] text-white app-header p-3 custom">
        <div class='lg:p-3 p-1 flex content-main gap-5'>
            <div class="custom-full custom-left">
                <div class="bg-[#2B2C32] h-full rounded-lg max-w-full">
                    <h2 class="text-[24px] px-[16px] shadow-md rounded h-[62px] tracking-[0.05px] leading-[30px] flex items-center font-semibold text-white">SEO Settings</h2>
                    <div class="w-[564px] h-[176px] px-[16px] py-[16px]">
                        <h5 class="font-bold text-base leading-5 tracking-[0.15px] h-[20px]">Download Site Map</h5>
                        <p class="font-normal text-sm leading-[17.5px] my-6">Help your Business appear higher in Google search. Simply, copy the site map URL or download the file and add it to your Google Search Console</p>
                        <div class="flex flex-row gap-2">
                            <button @click="download()" class="flex items-center justify-center border-theme-green hover:bg-theme-green-300 w-[155px] h-[40px] rounded border-2 py-2 px-4">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19 9.5H15V3.5H9V9.5H5L12 16.5L19 9.5ZM11 11.5V5.5H13V11.5H14.17L12 13.67L9.83 11.5H11ZM19 20.5V18.5H5V20.5H19Z" fill="white" />
                                </svg>
                                Download
                            </button>
                            <button @click="copy()" class="flex items-center justify-center border-theme-green hover:bg-theme-green-300 w-[155px] h-[40px] rounded border-2 py-2 px-4">
                                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" fill="white" />
                                </svg>
                                <span class="pl-1">Copy</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import Env from '@/env'
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import SeoSettingApi from '@/controllers/SeoSetting'
import WebsiteSettingApi from "@/controllers/WebsiteSettingApis";
import isEmpty from 'lodash/isEmpty';
import moment from 'moment'

export default defineComponent({
  name: "Seo",
  setup() {
      const snackbar = useSnackbar();
      const domain = ref('')
      return {
        snackbar,
        domain
      }
  },
  mounted(){
    this.fetchWebsiteSetting()
  },
  computed: {
    ...mapGetters({
      vg_selectedBusiness: 'auth/activeBusiness',
    }),
  },
  methods: {
    async fetchWebsiteSetting(){
        let res = await WebsiteSettingApi.fetchWebsiteSetting();
        if(res.status == 200) {
            this.domain = res.data.payload.domain
        }
    },
    async download(){
        let allUrls = await this.getAllUrl()
        if(allUrls?.length>0){
            let xmlString = this.generateSiteMap(allUrls)
            try{
                var filename = "sitemap.xml";
                var pom = document.createElement('a');
                var bb = new Blob([xmlString], {type: 'text/plain'});

                pom.setAttribute('href', window.URL.createObjectURL(bb));
                pom.setAttribute('download', filename);

                pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
                pom.draggable = true; 
                pom.classList.add('dragout');

                pom.click();
            }catch(err){
                console.log(err)
                this.snackbar.add({
                    type: 'info',
                    text: 'Something went wrong',
                    duration: 3000,
                })
            }
        }
    },
    async getAllUrl(){
        this.va_spinner(true)
        const host = Env.webStoreUrl
        const businessSlug = this.vg_selectedBusiness.slug;
        let pages = [];

        const businessRes = await SeoSettingApi.fetchBusinessProfile(businessSlug);
        if (businessRes.status == 200 && businessRes?.data?.payload) {
            let categoryTreeProm = SeoSettingApi.categoryTree(businessRes.data.payload.id);
            let businessAllProductsProm = SeoSettingApi.fetchAllBusinessProducts(businessRes.data.payload.id);
            let businessLocationsProm = SeoSettingApi.fetchAllLocationByBusinessIdNotDetails(businessRes.data.payload.id);

            let [categoryTreeRes, businessProductRes, businessLocationRes] = await Promise.all([
                categoryTreeProm,
                businessAllProductsProm,
                businessLocationsProm
            ]);

            let allProductsSlug = [];

            if (businessProductRes.status == 200 && businessProductRes.data.payload.products) {
                allProductsSlug = businessProductRes.data.payload.products.map((p) => {
                    return this.createProductSlug(p);
                });
            }

            if (businessLocationRes.status == 200) {
                businessLocationRes.data.payload.forEach((loc) => {
                    // append all category of this business
                    if (categoryTreeRes.status == 200) {
                        categoryTreeRes.data.payload.forEach((cat) => {
                            let catPage = ''
                            if(this.domain){
                                catPage = this.domain.substring(this.domain.length-1)==='/'?this.domain:this.domain+'/'
                                catPage += businessSlug + '/' + loc.slug + '/category/' + cat.slug;
                            }else{
                                catPage = host + '/' + businessSlug + '/' + loc.slug + '/category/' + cat.slug;
                            }
                            pages.push({url: catPage, date: moment(cat.updatedAt).format('YYYY-MM-DD')});
                        });
                    }
                    // host/business/${location}/${product}
                    allProductsSlug.forEach((prodSlug) => {
                        let productPage = ''
                        if(this.domain){
                            productPage = this.domain.substring(this.domain.length-1)==='/'?this.domain:this.domain+'/'
                            productPage += businessSlug + '/' + loc.slug + '/' + prodSlug
                        }else{
                            productPage = host + '/' + businessSlug + '/' + loc.slug + '/' + prodSlug;
                        }
                        pages.push({url: productPage, date: moment(prodSlug.updatedAt).format('YYYY-MM-DD')});
                    });
                });
            }
        }
        this.va_spinner(false)
        return pages
    },
    createProductSlug(product, type = 'default') {
        if (isEmpty(product)) {
            return '#';
        }

        let productName = type == 'cart' ? product.productName : product.name;
        let variantName = type == 'cart' ? product.productVariationName : product.variantName;
        let pid = type == 'cart' ? product.productId : product.id;
        return (
            (productName + variantName)
                .trim()
                .toLowerCase()
                .replace(/[^a-zA-Z0-9]+/g, '_') +
            '-' +
            pid
        );
    },
    generateSiteMap(pages) {

            return `<?xml version="1.0" encoding="UTF-8"?>
        <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
            ${pages
                .map((p) => {
                    return `
            <url>
                <loc>https://${p.url}</loc>
                <lastmod>${p.date}</lastmod>  
            </url>
            `;
                })
                .join('')}
        </urlset>
        `;
    },
    copy(){
        let url = ''
        if(this.domain){
            url = this.domain.substring(this.domain.length-1)==='/'?this.domain+'sitemap.xml':this.domain+'/sitemap.xml'
        }else{
            url = Env.webStoreUrl+'/'+this.vg_selectedBusiness.slug+'/sitemap.xml'
        }
        navigator.clipboard.writeText(url)
         this.snackbar.add({
            type: 'info',
            text: 'Url copied to clipboard',
            duration: 3000,
        })
    },
    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  }
});

</script>