<template>
    <div class="h-screen bg-[#23242A]">
        <div class="flex lg:flex-row flex-col px-4 pt-4 bg-[#23242A] text-white">
            <div class="transition-all duration-100 ease-linear" :class="(add || edit)?'lg:w-[50%] w-full':'w-full'">
                <div ref="productList" class="rounded-[10px] bg-[#2B2C32] w-full min-h-[630px] overflow-x-auto overflow-hidden">
                    <div class="text-[24px] text-white px-4 pt-4">
                        <div class="flex justify-between">
                            <span class="font-semibold">Master Product List</span>
                            <div class="flex flex-row gap-2">
                                <input type="text" v-model="search" @keydown="searchData" class="rounded h-[40px] bg-field-black align-top mr-2 border-0" placeholder="Search..." />
                                <div class="relative" tabIndex="0" @blur="closeDropDown">
                                    <div @click="toggleDropDown"  class="text-white bg-theme-green hover:bg-green-600 py-1 h-10 px-1 text-2xl border-0 rounded-md cursor-pointer">
                                        <span>
                                            <svg fill="currentColor" width="32" height="32" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"></path>
                                            </svg>
                                        </span>
                                    </div>
                                    <div v-if="toggleAddDropDown" class="absolute top-14 right-0 w-60   mt-1  z-10 rounded-md shadow-lg">
                                        <div class="absolute right-0 -top-4 right-4 h-0 w-0 border-x-8 border-x-transparent border-b-[16px] border-b-gray-black"></div>
                                        <ul role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" class="bg-gray-black max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                            <li @click="openPurchaseOrderUpload" id="listbox-item-1" role="option" class="cursor-pointer hover:bg-light-black text-gray-900 select-none relative py-2 pl-3 pr-9">
                                                <div class="flex items-center">
                                                    <span class="ml-3 block text-white font-normal truncate uppercase">
                                                        OCS upload
                                                    </span>
                                                </div>
                                            </li>
                                            <li @click="openImportProductUpload" id="listbox-item-1" role="option" class="cursor-pointer hover:bg-light-black text-gray-900 select-none relative py-2 pl-3 pr-9">
                                                <div class="flex items-center">
                                                    <span class="ml-3 block text-white font-normal truncate uppercase">
                                                        Alberta order form upload 
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table w-full mt-6 px-2 pb-4">
                        <new-table-lite
                            :is-slot-mode="true"
                            :is-loading="table.loading"
                            :class="'block w-full min-h-[480px] admin-table admin-table-custom'"
                            :rowClasses="row => row?'id-'+row.id:null"
                            :columns="table.columns"
                            :rows="table.rows"
                            :total="table.totalRecords"
                            :sortable="table.sortable"
                            :page-size="table.pagesize"
                            @do-search="searchProduct"
                            :page="table.page"
                            @row-clicked="selectedRow"
                            :pageOptions='table.pageOptions'
                            :messages="{
                                pagingInfo: 'Total: {2}',
                                pageSizeChangeLabel: 'Items per Page',
                                gotoPageLabel: 'Go to page',
                                noDataAvailable: 'No records found!',
                            }"
                        >
                            <template v-slot:thc_cbd="data">
                                <div v-if="data.value.thcCbdValueType=='range'">
                                    <p><span class="text-white">THC:</span> {{ data.value.thcMin }} - {{ data.value.thcMax }}{{data.value.concentration_unit}}</p>
                                    <p><span class="text-white">CBD:</span> {{ data.value.cbdMin }} - {{ data.value.cbdMax }}{{data.value.concentration_unit}}</p>
                                </div>
                                <div v-else>
                                    <p><span class="text-white">THC:</span> {{ data.value.thc }}{{data.value.concentration_unit}}</p>
                                    <p><span class="text-white">CBD:</span> {{ data.value.cbd }}{{data.value.concentration_unit}}</p>
                                </div>
                            </template> 
                        </new-table-lite>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Env from '@/env';
import {mapGetters} from 'vuex'
import debounce from 'lodash/debounce'
import { defineComponent, reactive, ref} from "vue";
import BusinessProduct from '@/controllers/BusinessProductApis'
import NewTableLite from '@/components/table/tableLite.vue'
export default defineComponent({
    components: {
        NewTableLite
    },
    setup(){
        let search = ref('')
        const toggleAddDropDown = ref(false)
        const toggleImport = ref(false)
        const table = reactive({
            loading: false,
            columns: [
                {label: 'Id', field: 'id', sortable:false, isKey:true, columnClasses:'hidden', headerClasses:'hidden' },
                {label: 'Name', field:'name', sortable: true, isKey: true},
                {label: 'GTIN', field:'gtin', sortable: true},
                {label: 'Size', field:'size', sortable: true},
                {label: 'Weight Equivalency', field:'weight_equivalent_dry_cannabis', sortable: true},
                {label: 'SKU', field: 'sku', sortable: true},
                {label: 'THC/CBD', field:'thc_cbd', sortable: true},
                {label: 'Compliance Type', field:'complianceType', sortable: true},
                // {label: 'Product form', field:'product_form', sortable: false},
            ],
            rows: [],
            totalRecords: 0,
            page: 1,
            sortable: {
                order: "",
                sort: "",
            },
            pagesize: Env.defaultPageSize,
            pageOptions: Env.pageList,
            count: 0
        })

        return {toggleAddDropDown, toggleImport, search, table}
    },
    mounted(){
        this.searchProduct(0, Env.defaultPageSize, this.table.sortable.sort, this.table.sortable.order)
        this.emitter.on("reloadProductList", this.reloadProductList);
    },
    beforeDestroy() {
        this.emitter.off("reloadProductList", this.reloadProductList);
    },
    computed: {
        ...mapGetters({
            vg_selectedLocation: 'location/selected'
        })
    },
    methods: {
        toggleDropDown(){
            this.toggleAddDropDown = !this.toggleAddDropDown
        },
        closeDropDown(){
            this.toggleAddDropDown = false
        },
        openPurchaseOrderUpload(){
            this.toggleAddDropDown = false
            this.$emit('togglePurchaseOrderUploadPop', true)
        },
        toggleImportProduct(){
            this.openImportProduct = !this.openImportProduct
        },
        closeImportProduct(){
            this.openImportProduct = false
        },
        openImportProductUpload(){
            this.openImportProduct = false
            this.$emit('toggleProductImportPop', true)
        },
        async searchProduct(offset=0, limit=10, sort, order){
            let page = (offset/limit)+1
            this.table.page = page
            this.table.pagesize=limit
            this.table.loading = true
            let params = `page=${this.table.page}&perPage=${this.table.pagesize}`
            params += sort?`&sort=${sort}&order=${order}`:''
            params += this.search?`&search=${this.search.replace(/[^0-9a-z'àâçéèêëîïôûùüÿñ:;()/\."-\s]/gi, '')}`:''
            let {data: {payload}} = await BusinessProduct.fetchAllMasterProduct(params)
            this.table.rows = payload.products
            this.table.totalRecords = payload.totalRecords;
            this.table.sortable.order = order
            this.table.sortable.sort = sort
            this.table.loading = false
        },
        async reloadProductList(editItem){
            this.searchProduct()
        },
        searchData: debounce(function(){
            if(this.search.length>=3 || this.search==''){
                this.searchProduct(0, Env.defaultPageSize, this.table.sortable.sort, this.table.sortable.order)
            }
        }, 400),
        selectedRow(item){
            // selection effect
             let activeRow = document.getElementsByClassName('id-'+item.id);
             if(activeRow[0] && activeRow[0].parentNode && activeRow[0].parentNode.children){
                for(let sibling of activeRow[0].parentNode.children){
                    sibling.classList.remove('active-row')
                }
             }
             activeRow[0].classList.add("active-row")

            // action
            this.$emit('toggleAddEdit', {add: false, edit: true, editItem: item})
        },
    }
})
</script>