<template>
  <div>
    <ul class="flex bg-[#2B2C32] rounded-full px-8 py-1 mb-1 ">
      <li :class="'mr-5' + ($route.name == 'ProductPromotions' ? ' active' : '')">
        <router-link :to="{ name: 'ProductPromotions' }" class="inline-block text-theme-white tracking-widest text-sm font-semibold px-2">
          PROMO CODES
        </router-link>
      </li>
      <li :class="'mr-5' + ($route.name == 'ProductDiscounts' ? ' active' : '')">
        <router-link :to="{ name: 'ProductDiscounts' }" class="text-theme-dimwhite tracking-widest text-sm font-semibold">
          PRE-SET DISCOUNTS
        </router-link>
      </li>
      <li :class="'mr-5' + ($route.name == 'ProductSales' ? ' active' : '')">
        <router-link :to="{ name: 'ProductSales' }" class="text-theme-dimwhite tracking-widest text-sm font-semibold">
          PROMOTIONS 
        </router-link>
      </li>
      <!-- <li :class="'flex flex-shrink-0 mr-5' + $route.name == 'ProductReasonCode' ? ' active' : ''">
        <router-link :to="{ name: 'ProductReasonCode' }" class="text-theme-dimwhite tracking-widest text-sm font-semibold">
          REASON CODE
        </router-link>
      </li> -->
    </ul>
  </div>
</template>

<style scoped>
  .active:before {
    content: '';
    position: absolute;
    background: #2BA66B;
    width: 100%;
    height: 4px;
    bottom: -8px;
  }
  .active {
    position:relative;
  }
</style>