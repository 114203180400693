
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
import LocationHolidayHoursApis from "@/controllers/LocationHolidayHoursApis";
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';
 import Datepicker from 'vue3-datepicker'
//import Datepicker from '@vuepic/vue-datepicker';
import moment from 'moment';

export default defineComponent({
  name: "AddLocationHoliday",
  components: {
    Datepicker
  },
  props:{
    clearComponent: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const snackbar = useSnackbar();
    const taxRateTypePercentage = ref(true);
    const picked = ref(new Date())
    const holidayDates = <any>ref([]);
    const form = ref({
      name: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'min:3'],
      },
      message: {
        value: null,
        error_type: '',
        error_message: '',
        validations: ['required', 'min:3'],
      },
      startDate: {
        value: null,
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      endDate: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      isActive: false,
    });
    return {
      form,
      snackbar,
      taxRateTypePercentage,
      picked,
      holidayDates
    };
  },

  watch:{
    'form.startDate.value'() {
      this.validateFields(['startDate']);
    },
    'form.endDate.value'() {
      this.validateFields(['endDate']);
    },
  },

  computed: {
    // end date must be greated then start
    datePickersData() {
      let list = <any>[];
      if(this.form.startDate.value && this.form.endDate.value) {
          let startDate = moment(this.form.startDate.value);
          let endDate = moment(this.form.endDate.value);
          let totalDays = endDate.diff(startDate, 'days') + 1;
          if(totalDays > 0){
            for (let day = 0; day < totalDays; day++) {
              // startdate auto update so, first item 0 then all 1
              let theDay = startDate.add( day == 0 ? 0 : 1, 'days');
              list.push({
                day: theDay.day(),
                dayName: theDay.format('dddd'),
                holidayDate: theDay.format('YYYY-MM-DD'),
                holidayDateDisplay: theDay.format('MM/DD'),
                isOpen: true,
                openTime: {
                  HH: '01',
                  MM: '00',
                },
                is_valid_openTime_closeTime: true,
                openTimeTypeAm: true,
                closeTime: {
                  HH: '01',
                  MM: '01',
                },
                closeTimeTypeAm: true
              })
            }
          }
      } 
      this.holidayDates = list;
      return list;
    },

    ...mapGetters({
     
    }),
  },

  mounted() {
     
  },
 
  methods: {
    /**
     * Validate start end time
     */
    async validateStartEndTime(index) {
      this.holidayDates = await Helpers.validateStartEndTime(this.holidayDates, index);
    },
   
     /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },


    /**
     * Add new location Tax
     */
    async addNew() {
      let validate = await this.validateFields(['name', 'message', 'startDate', 'endDate']);
      if(!validate) {
        return ;
      }
      // Validate datepicker date before submit
      let startDate = moment(this.form.startDate.value);
      let endDate = moment(this.form.endDate.value);
      let totalDays = endDate.diff(startDate, 'days') + 1;
      if(totalDays < 1){
        return this.snackbar.add({
          type: 'info',
          text: 'End date ' + endDate.format('YYYY-MM-DD') +' must be greater or equals to start date ' + startDate.format('YYYY-MM-DD'),
          duration: 3000,
        })
      }
      // Validate holiday dates must be min 1 
      if(this.datePickersData.length == 0){
        return this.snackbar.add({
          type: 'info',
          text: 'You must have at least 1 holiday hours.',
          duration: 3000,
        })
      }

      // validate holidays date's time must be valid
      
      for (let i = 0; i < this.holidayDates.length; i++) {
        if(this.holidayDates[i].is_valid_openTime_closeTime == false) {
          return this.snackbar.add({
            type: 'info',
            text: 'End time must be greater than start time on ' + this.holidayDates[i].holidayDateDisplay,
            duration: 3000,
          })
        }
      }
      
      this.va_spinner(true);
      let data = <any>Helpers.getFormKeyValue(this.form);
      // now we need to chnage the date values
      data.startDate = moment(data.startDate).format('YYYY-MM-DD');
      data.endDate = moment(data.endDate).format('YYYY-MM-DD');
      data.holidayHours = <any>Helpers.convertLocation12hoursTo24hours(this.holidayDates);
      let res = await LocationHolidayHoursApis.addNewLocationHolidayHours(data);
      this.va_spinner(false);
      this.snackbar.add({
        type: 'info',
        text: res.status == 201 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
      if(res.status == 201 ) {
        this.clearComponent();
        this.emitter.emit("refresh-location-holidays-list", true);
      }
    },

    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  },
});

