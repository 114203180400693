<template>
    <div class="mt-1">
        <!-- Menu -->
        <div class="flex flex-col w-full focus:outline-none border-b border-theme-modalbg">
            <div class="flex flex-row w-full items-center py-4 text-gray-300 cursor-pointer">
                <div @click="toggleLefNav" :class="vg_leftNav?'px-3 ':'ml-6'">
                    <svg v-if="vg_leftNav" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.16667 12.5L6.66667 10M6.66667 10L9.16667 7.5M6.66667 10L13.3333 10M2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.8333 7.5L13.3333 10M13.3333 10L10.8333 12.5M13.3333 10L6.66667 10M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div v-if="vg_leftNav" class="ml-3 text-white text-center text-sm font-semibold uppercase select-none">Menu</div>
            </div>
        </div>
        <!-- Search -->
        <div class="flex flex-col w-full focus:outline-none">
            <div class="flex flex-row w-full items-center py-4 text-gray-300">
                <div v-if="vg_leftNav" class="flex flex-row items-center justify-center mx-3 bg-theme-modalbg border-0 rounded px-2">
                    <input @keyup.enter="searchProduct()" v-model="search" type="text" class="bg-theme-modalbg border-0 focus:outline-0 focus:ring-0 w-[175px]" placeholder="Quick Search" />
                    <button @click="searchProduct()" :disabled="search.length<3" class="flex hover:bg-theme-green hover:rounded disabled:bg-transparent w-[36px] h-[36px] justify-center items-center">
                        <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.8337 15.8334L11.9448 11.9445M13.2411 8.70372C13.2411 11.2095 11.2098 13.2408 8.70403 13.2408C6.19829 13.2408 4.16699 11.2095 4.16699 8.70372C4.16699 6.19799 6.19829 4.16669 8.70403 4.16669C11.2098 4.16669 13.2411 6.19799 13.2411 8.70372Z" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </div>
                <div v-else class="flex ml-4 bg-theme-modalbg w-[36px] h-[36px] rounded-full items-center justify-center">
                    <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.8337 15.8334L11.9448 11.9445M13.2411 8.70372C13.2411 11.2095 11.2098 13.2408 8.70403 13.2408C6.19829 13.2408 4.16699 11.2095 4.16699 8.70372C4.16699 6.19799 6.19829 4.16669 8.70403 4.16669C11.2098 4.16669 13.2411 6.19799 13.2411 8.70372Z" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
        </div>
        <!-- Menu List -->
        <div v-for="(item, index) in availableSidebarRoutes" :key="`${item.name}`">
            <!-- category with no subcategory -->
            <div v-if="item.path" class="flex flex-col w-full focus:outline-none">
                <div @click="openMenuItem(item)" :class="($route.name==item.name)?'border-theme-green bg-gradient-to-r border-l-4 bg-theme-darkgreen/[0.2]':''"
                    class="flex flex-row w-full cursor-pointer items-center px-3 py-4 text-gray-300 hover:text-white hover:bg-theme-darkgreen/[0.2] border-theme-green ease-in-out duration-300" >
                    <span class="w-[20px] flex justify-center items-center" :class="vg_leftNav?'':'ml-3'" v-if="item.meta.svg" v-html="item.meta.svg"></span>
                    <span v-if="vg_leftNav" class="ml-3 uppercase text-left text-sm font-semibold">{{item.meta.navText}}</span>
                </div>
            </div>

            <!-- category with subcategory -->
            <div v-else class="flex flex-col w-full focus:outline-none">
                <div @click="openMenu(item)" :class="(menus[index]?.open)?'bg-gradient-to-r':'hover:bg-theme-darkgreen/[0.2]'" class="flex flex-row w-full items-center cursor-pointer px-3 py-4 text-gray-300 hover:text-white  from-transparent to-theme-darkgreen border-theme-green ease-in-out duration-300" >
                    <span class="w-[20px]" :class="vg_leftNav?'':'ml-3'" v-if="item.meta.svg" v-html="item.meta.svg"></span>
                    <span v-if="vg_leftNav" class="ml-3 uppercase text-left text-sm font-semibold">{{item.meta.navText}}</span>
                    <mdicon v-if="item.children && vg_leftNav" class="flex-grow text-right" style="text-align:-webkit-right;" :name="(menus[index]?.open)?'chevron-up':'chevron-down'" />
                </div>
            </div>

            <!-- subcategory item -->
            <div v-show="menus[index]?.open && vg_leftNav">
                <div v-for="(child, ind) in item.children" :key="index+'-'+ind" class="border-theme-green bg-gradient-to-r"  :class="(child?.meta?.navText==$route?.meta?.navText)?'border-l-4':''">
                    <router-link :to="child.path" class="flex flex-row w-full items-center px-3 py-4 text-white hover:bg-theme-darkgreen/[0.2] hover:text-white" :class="(child?.meta?.navText==$route?.meta?.navText)?'bg-theme-darkgreen/[0.2]':''">
                        <span class="ml-3 text-left text-sm">{{child.meta.navText}}</span>
                    </router-link>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import debounce from 'lodash/debounce'

export default defineComponent({
    props: ['availableSidebarRoutes', 'default'],
    setup() {
        const menus = ref([])
        const search = ref('')

        return {menus, search}
    },
    mounted(){
        this.init()
    },
    watch: {
        availableSidebarRoutes (){
            this.init()
        }
    },
    computed: {
        ...mapGetters({
            vg_leftNav: 'nav/leftNav'
        })  
    },
    methods: {
        searchProduct: debounce(function(){
            if(this.search && this.search.length>=3){
                this.$router.push({name: 'Products', query: {search: this.search}})
            }
        }, 1000),
        init(){
            let list = []
            for(let i=0; i<this.availableSidebarRoutes.length; i++){
               list.push({
                    name: this.availableSidebarRoutes[i].name, 
                    open: (i==this.default)?true:false
                }) 
            }
            this.menus = [...list]
        },
        openMenu(item){
            let findIndex = this.menus.findIndex(each => each.name==item.name)
            if(findIndex>=0){
                for(let i=0; i<this.menus.length; i++){
                    if(findIndex==i){
                        this.menus[i].open = !this.menus[i].open
                        this.$router.push({name: item.children[0].name})
                        if(!this.vg_leftNav){
                            this.vm_leftNav(true)
                        }
                    }else{
                        this.menus[i].open = false
                    }
                }
            }
        },
        openMenuItem(item){
            let findIndex = this.menus.findIndex(each => each.name==item.name)
            if(findIndex>=0){
                for(let i=0; i<this.menus.length; i++){
                    if(findIndex==i){
                        this.$router.push({name: item.name})
                        if(!this.vg_leftNav){
                            this.vm_leftNav(true)
                        }
                    }else{
                        this.menus[i].open = false
                    }
                }
            }
        },
        toggleLefNav(){
            this.vm_leftNav(!this.vg_leftNav)
        },
        ...mapMutations({
            vm_leftNav: 'nav/leftNav'
        })
    }
})
</script>
