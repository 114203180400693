
import { defineComponent} from "vue";
import Helpers from '@/helpers';

export default defineComponent({
  name: "ReturnConfirmModal",
  components: {
  },
  
  setup() {
  
  },
  
  props: {
    heading: {
      type: String,
      required: true,
    },
    obj: {
      type: Object,
      required: true,
    },
    closeClick: {
      type: Function,
      required: true,
    },
    actionClick: {
      type: Function,
      required: true,
    },
  },

  methods: {
  },
});
