<template>
    <div class="newTableLite">
        <div v-if="table.loading" class="w-full text-center">
            <block-loading />
        </div>
        <div class="w-full overflow-x-auto" v-else>
            <table class="table-auto w-full">
                <thead>
                    <tr>
                        <th class="text-left px-2 py-3" v-if="table.draggable"></th>
                        <th class="text-left px-2 py-3" v-for="(column, ind) in table.columns" :key="ind">
                            <div class="flex flex-row cursor-pointer">
                                <div>{{column.label}}</div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody v-if="displayRows.length>0" id="featured_sorting_tr">
                    <tr v-for="(row, index) in displayRows" :key="index" :id="row.id" class="row" @click="selectRow(row)" >
                        <td @click.stop class="text-left w-2 cursor-ns-resize table-lite-drag" v-if="table.draggable" :class="(selected.id==row.id)?'border-t-4 border-b-4 border-[#2BA66B] bg-[#2BA66B]/30':'border-t-4 border-b border-transparent'">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 14.25V12.75H20V14.25H4ZM4 11.25V9.75H20V11.25H4Z" fill="#878686"/>
                            </svg>
                        </td>

                        <td class="text-left px-2 py-3 text-sm cursor-pointer" :class="(selected.id==row.id)?'border-t-4 border-b-4 border-[#2BA66B] bg-[#2BA66B]/30':'border-t-4 border-b border-transparent'" v-for="(column, ind) in table.columns" :key="ind+index">
                            <slot :name="table.columns[ind]?.field" :data="row">{{row[table.columns[ind]?.field]}}</slot>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td class="text-center" :colspan="3">No records found!</td>
                    </tr>
                </tbody>
            </table>
            <div v-if="table.pagination" class="flex my-3 py-3 float-right">
                <div v-if="table.page==1" class="cursor-default px-2 mx-1">&lt;</div>
                <div v-else class="cursor-pointer px-2 mx-1" @click="prevPage()">&lt;</div>
                <div class="rounded px-2 mx-1 " :class="(table.page==pag)?'cursor-default bg-theme-green':'cursor-pointer'" v-for="(pag, ind) in pagination" :key="ind" @click="selectPage(pag)">{{pag}}</div>
                <div v-if="table.page==totalPage" class="cursor-default px-2 mx-1">&gt;</div>
                <div v-else class="cursor-pointer px-2 mx-1" @click="nextPage()">&gt;</div>
            </div>
            <div v-else class=" my-3 py-3"></div>
        </div>
    </div>
</template>

<script>
import Sortable from 'sortablejs';
import {defineComponent, ref} from 'vue'

export default defineComponent({
    props: ['table'],
    setup(){
        const selected = ref({})
        const drag = ref({index: null, id: null})
        const drop = ref({index: null, id: null})
        const rows = ref([]) 
        const displayRows = ref([])
        const el = ref(null)
        const pagination = ref([])
        const totalPage = ref(0)

        return {selected, drag, drop, rows, displayRows, el, pagination, totalPage}
    },
    async mounted(){
        this.rows = [...this.table.rows]
        await this.getDisplayRow()
        this.draggable()
        this.getPagination()
    },
    watch: {
        'table.rows'(){
            this.rows = [...this.table.rows]
            this.getDisplayRow()
        }
    },
    methods: {
        selectRow(row){
            this.selected = row
            this.$emit('row-clicked', row)
        },
        getDisplayRow(){
            this.displayRows = [...this.rows]
        },
        draggable(){
            if(this.table.draggable){
                this.el = document.getElementById('featured_sorting_tr');
                if(this.el){
                    Sortable.create(this.el, {
                        animation: 100,
                        onSort: this.collectData
                    });
                }
            }
        },
        collectData(e){
            let sortedObj = []
            for(let item of this.el.children){
                sortedObj.push({id: parseInt(item.id)})
            }
            this.$emit('dragged', sortedObj)
        },
        getPagination(){
            // current: 1
            // total: 12
            this.pagination = []
            if(this.table.page && this.table.pagesize && this.table.totalRecords){
                if(this.table.isStatic){
                    this.totalPage = Math.ceil(this.table.rows.length/this.table.pagesize)
                }else{
                    this.totalPage = Math.ceil(this.table.totalRecords/this.table.pagesize)
                }
                let currentPage = parseInt(this.table.page)
                if(this.totalPage<=5){
                    // total page is less than 5
                    for(let pag=1; pag<=this.totalPage; pag++){
                        this.pagination.push(pag)
                    }
                }else{
                    if(currentPage<=2){
                        // current is 1
                        for(let pag=1; pag<=5; pag++){
                            this.pagination.push(pag)
                        }
                    }else if(this.totalPage-currentPage<5){
                        // current is near to last 5
                        for(let pag=this.totalPage; pag>(this.totalPage-5); pag--){
                            this.pagination.push(pag)
                        }
                        this.pagination.reverse()
                    }else{
                        // current is in middle
                        this.pagination.push(currentPage-2)
                        this.pagination.push(currentPage-1)
                        this.pagination.push(currentPage)
                        this.pagination.push(currentPage+1)
                        this.pagination.push(currentPage+2)
                    }
                }
            }else{
                return false
            }
        },
        selectPage(pag){
            this.$emit('paginate', pag)
        },
        prevPage(pag){
            this.selectPage((this.table.page-1))
        },
        nextPage(pag){
            this.selectPage((this.table.page+1))
        }
    }
})
</script>
<style>
.newTableLite table tbody tr:nth-child(even){
    background: #464851;
}
</style>
