/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import store from '@/store/index';
import Env from '@/env';
const apiVersion = 'api';
import AuthApis from '@/controllers/AuthApis';
import Helpers from '@/helpers';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /*
   * fetch all the promotions
   */
  fetchAll(locationId, page = 1, perPage = 10, sortBy = 'code', sortOrder = 'asc') {
    let url = `${Env.productUrl}/api/promo-codes/all?page=${page}&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`; // &userType=all
    if(locationId){
      url += `&locationId=${locationId}`
    }
    return AuthApis.getApi(url, {
      api_token: Env.productApiToken,
      'Authorization': 'Bearer ' + store.getters['auth/userToken'],
    }).then((response) => {
      return response;
    }).catch((err) => {
      ApiHelper.detectStatusAndRedirect(err);
      return err;
    })
  },


  /*
   * add new promocode
   */
  async addNewPromo(data) {
    data = await Helpers.setEmptyValueToNull(data);
    const url = `${Env.productUrl}/api/promo-codes/create`;
    return AuthApis.postApi(url, {
        'Authorization': 'Bearer ' + store.getters['auth/userToken'],
        api_token: Env.productApiToken,
      }, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /*
   * Update promocode
   */
  async update(promoCodeId, data) {
    data = await Helpers.setEmptyValueToNull(data);
    const url = `${Env.productUrl}/api/promo-codes/${promoCodeId}/update`;
    return AuthApis.patchApi(url, {
        'Authorization': 'Bearer ' + store.getters['auth/userToken'],
        api_token: Env.productApiToken,
      }, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /*
   * Delete
   */
  delete(promoCodeId) {
    const url = `${Env.productUrl}/api/promo-codes/${promoCodeId}/remove`;
    return AuthApis.deleteApi(url, {
        'Authorization': 'Bearer ' + store.getters['auth/userToken'],
        api_token: Env.productApiToken,
      })
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Assign product on promocode
   */
  assignProducts(promoCodeId, productIds) {
    const url = `${Env.productUrl}/${apiVersion}/promo-codes/${promoCodeId}/promoCodeProducts/assign`;
    return AuthApis.postApi(url, {
        'Authorization': 'Bearer ' + store.getters['auth/userToken'],
        api_token: Env.productApiToken,
      }, {
        productIds
      })
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Assign locations
   */
  assignLocations(promoCodeId, locationIds) {
    const url = `${Env.productUrl}/${apiVersion}/promo-codes/${promoCodeId}/promoCodeLocations/assign`;
    return AuthApis.postApi(url, {
        'Authorization': 'Bearer ' + store.getters['auth/userToken'],
        api_token: Env.productApiToken,
      }, {
        locationIds
      })
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Fetch assigned products
   */
  fetchAssignedProducts(promoCodeId) {
    const url = `${Env.productUrl}/${apiVersion}/promo-codes/${promoCodeId}/promoCodeProducts/all`;
    return AuthApis.getApi(url, {
        'Authorization': 'Bearer ' + store.getters['auth/userToken'],
        api_token: Env.productApiToken,
      })
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Fetch assigned locations
   */
  fetchAssignedLocations(promoCodeId) {
    const url = `${Env.productUrl}/${apiVersion}/promo-codes/${promoCodeId}/promoCodeLocations/all`;
    return AuthApis.getApi(url, {
        'Authorization': 'Bearer ' + store.getters['auth/userToken'],
        api_token: Env.productApiToken,
      })
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  updateSortOrder(data){
    const url = `${Env.productUrl}/${apiVersion}/promo-codes/sort/updateSortOrder`;
    return AuthApis.putApi(url, {
        'Authorization': 'Bearer ' + store.getters['auth/userToken'],
        api_token: Env.productApiToken,
      }, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  }

}