/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import store from '@/store/index';
import Env from '@/env';
const apiVersion = 'api';
import AuthApis from '@/controllers/AuthApis';
import Helpers from '@/helpers';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /*
   * fetch all the promotions
   */
  fetchAll(page = 1, perPage = 10, column, order, startRange, endRange) {
    const location = store.getters['location/selected']
    let url = `${Env.productUrl}/api/location-inventory-audits/forLocation/${location.id}?page=${page}&perPage=${perPage}`;
    if(column && order){
      if(column==='status'){
        url += `&sortByStatus=${order}`
      }else if(column==='name'){
        url += `&sortByDate=${order}`
      }
    }
    if(startRange){
      url += `&fromDate=${startRange}`
    }
    if(endRange){
      url += `&toDate=${endRange}`
    }

    return AuthApis.getApi(url, {
      api_token: Env.productApiToken,
      'Authorization': 'Bearer ' + store.getters['auth/userToken'],
    }).then((response) => {
      return response;
    }).catch((err) => {
      ApiHelper.detectStatusAndRedirect(err);
      return err;
    })
  },


  /*
   * fetch individual audit
   */
  async auditDetail(id) {
    const url = `${Env.productUrl}/api/location-inventory-audits/v2/${id}`;
    return AuthApis.getApi(url, {
        'Authorization': 'Bearer ' + store.getters['auth/userToken'],
        api_token: Env.productApiToken,
      })
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
    });
  },

  // fetch live inventory
  async liveInventory(id) {
    const url = `${Env.productUrl}/api/location-inventory-audits/v2/liveInventory/${id}`;
    return AuthApis.getApi(url, {
        'Authorization': 'Bearer ' + store.getters['auth/userToken'],
        api_token: Env.productApiToken,
      })
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
    });
  },

  // Create audit
  async createProductAudit(locationId, data){
    const url = `${Env.productUrl}/api/location-inventory-audits/v2/forLocation/${locationId}`;
    return AuthApis.postApi(url, {
      'Authorization': 'Bearer ' + store.getters['auth/userToken'],
      api_token: Env.productApiToken,
    }, data)
    .then(res => res)
    .catch(err => {
      ApiHelper.detectStatusAndRedirect(err);
      return err;
    });
  },

  // Update audit
  async updateProductAudit(locationInventoryAuditProductId, data){
    const url = `${Env.productUrl}/api/location-inventory-audits/v2/forLocationProductAudit/${locationInventoryAuditProductId}`;
    return AuthApis.putApi(url, {
      'Authorization': 'Bearer ' + store.getters['auth/userToken'],
      api_token: Env.productApiToken,
    }, data)
    .then(res => res)
    .catch(err => {
      ApiHelper.detectStatusAndRedirect(err);
      return err;
    });
  },

  async completeProductAudit(auditId){
    const url = `${Env.productUrl}/api/location-inventory-audits/v2/updateAuditStatus/${auditId}`;
    return AuthApis.putApi(url, {
      'Authorization': 'Bearer ' + store.getters['auth/userToken'],
      api_token: Env.productApiToken,
    }, null)
    .then(res => res)
    .catch(err => {
      ApiHelper.detectStatusAndRedirect(err);
      return err;
    });
  },

  fetchAllBusinessCategories(businessId) {
    const url = `${Env.productUrl}/${apiVersion}/category/forBusiness/${businessId}?isPagination=false`;
    return AuthApis.getApi(url, {
      'Authorization': 'Bearer '+ store.getters['auth/userToken'],
      'api_token' : Env.productApiToken,
      })
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
   },

}