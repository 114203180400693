<template>
    <div class="relative">
      <div class="table-loading" v-if="table.loading">
        <block-loading />
      </div>
      <table class="table-auto custom-table custom-table-bg-same w-full">
        <thead>
          <tr>
            <template v-for="head in table.headers"  v-bind:key="'tbl_head'+ head">
              <th :width="head.width" v-if="head.show" @click.prevent="head.sortable ? sortMe(head.key): void(0)" :class="'text-left px-2 py-3 text-sm ' + (head.sortable ? 'cursor-pointer' : '')">
                <div class="flex justify-between">
                  <span>{{ head.label }}</span>
                  <span v-if="head.sortable" :class="sortClass(head)"></span> 
                </div>
              </th>
            </template>
          </tr>
        </thead>
        <tbody id="sorting_tr">
          <template v-if="records.length == 0">
            <tr>
              <td :colspan="visibleHeaders.length"><p class="text-center text-sm py-4">No record found.</p></td>
            </tr>
          </template>
    
          <template v-else>
            <template  v-for="(record, index) in records" v-bind:key="'table-record-item' + index">
    
              <tr :class="'main-cat thisid_'+ record.id" :id="'main_cat_'+record.id" :attr-catid="record.id">
                <td :colspan="visibleHeaders.length" :class="'main-td'">
                  <table class="w-full">
                    <tr :class="myclass() + (record.id == activeItem?.id ? 'active-row' : '')">
                      <template v-bind:key="'table_col_main'+ head.key" v-for="head in table.headers">
                      
                        <td :width="head.width" v-if="head.show" class="text-left px-2 py-3 text-sm">
                          <div class="flex">
    
                            <svg v-if="draggable && (head.key == table.child.displayToggleKey)" class="draggable  mr-2 mt-2" width="16" height="6" viewBox="0 0 16 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0 5.25V3.75H16V5.25H0ZM0 2.25V0.75H16V2.25H0Z" fill="#878686"/>
                            </svg>
    
    
                            <template v-if="table.child && table.child.display && (head.key == table.child.displayToggleKey)">
                              <div class="w-7 mt-2 mr-1" v-if="disabletoggle == false">
                                <div @click="toggleParent(index)" :class="'ml-1 table-toggle-icon w-5 h-2 hover:opacity-60 cursor-pointer relative ' + (record.toggle ? 'active' : '')" v-if="record.subCategories && record.subCategories.length > 0"></div>
                              </div>
                            </template>
    
                            <slot :value="record" :name="head.key" v-if="$slots[head.key]"></slot>
                            <template v-else>
                              {{ record[head.key] }}
                            </template>
                          </div>
                        </td>
                      </template>
                    </tr>
                    <template v-if="disabletoggle ? table.child && table.child.display && record.subCategories && record.subCategories.length > 0 : table.child && table.child.display && record.subCategories && record.subCategories.length > 0 && record.toggle">
                      <tr :attr-catid="subcat.id" v-bind:key="'subcat' + index" v-for="(subcat, index) in record.subCategories" :class="myclass() + ('child_of_' + record.id + ' thisid_'+ subcat.id ) + ( subcat.id == activeItem?.id ? ' active-row' : '') ">
                          <template v-bind:key="'table_col_sub'+ head.key" v-for="(head, childindex) in table.headers">
                            <td v-if="head.show" class="text-left px-2 py-3 text-sm">
                              <div :class="(childindex == 0 ) ? 'ml-[60px]' : ''">
                                <slot :value="subcat" :name="head.key" v-if="$slots[head.key]"></slot>
                                <template v-else>
                                  {{ subcat[head.key] }}
                                </template>
                              </div>
                            </td>
                          </template>
                      </tr>
                    </template>
                  </table>
                </td>
              </tr>
    
            </template>
          
          </template>
        </tbody>
      </table>
      <div class="custom-table-actions flex justify-between mt-5" v-if="table.pagination.show">
        <div class="text-left">
          <span class="text-theme-878686 text-sm">Items per page</span>
          <select @change="changePerPageValue()" v-model="table.perPageDropdown.active" class="form-select border-0 text-sm ml-4 bg-theme-modalbg rounded-[4px] py-[8px] px-3 pr-7 focus:outline-none appearance-none">
              <option v-for="option in table.perPageDropdown.options" v-bind:key="'custom_table_displaycount'+ option.value" :value="option.value">{{ option.text }}</option>
          </select>
        </div>
    
        <div class="flex items-center justify-between">
            <nav class="flex content-center">
              <a href="#" @click.prevent="(table.pagination.activePageNo > 1) ? movePage('decrease'): void(0)" class="relative  items-center  px-1 py-1 text-sm font-medium text-white rounded-[4px]">
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
                </svg>
              </a>
              <a @click.prevent="changePage(page)" v-bind:key="'pageno'+ page" v-for="page in generatePages" href="#" 
              :class="'hover:opacity-100 relative hover:bg-theme-green text-white rounded-[4px] flex justify-center items-center w-[30px] px-2 py-1 mr-1 text-sm ' + (table.pagination.activePageNo == page ? 'bg-theme-green' : '') ">{{ page }}</a>
              <a href="#" @click.prevent="(table.pagination.activePageNo < table.pagination.noOfpages) ? movePage('increase') : void(0)" class="hover:opacity-100 relative  px-1 py-1 text-sm font-medium text-white rounded-[4px]">
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                </svg>
              </a>
            </nav>
          </div>
    
        </div>
    </div>
    </template>
    
    <script>
    import {defineComponent, ref} from 'vue';
    import Sortable from 'sortablejs';


    export default defineComponent({
      name: 'CustomTableComponent',
      props: {
        table: {
          type: Object,
          required: true,
        },
        activeItem: {
          type: Object,
          required: false,
        },
        disabletoggle: {
          type: Boolean,
          required: false,
        },
        callback: {
          type: Function,
          required: true,
        },
        sortingcallback: {
          type: Function,
          required: true,
          
        },
        draggable: {
          type: Boolean,
          required: false,
          default: false,
        },
      },
    
      setup(){
          const listCount = ref(0);
          const records = ref([]);
          return {listCount, records}
      },
     
      computed: {
        /**
         * Get visible header count
         */
        visibleHeaders() {
            return this.table.headers.filter(item => item.show);
        },
    
        /**
         * Generate page navs
         */
        generatePages() {
          let currentPage = this.table.pagination.activePageNo;
          // pagination works 
          let totalNoOfPages = this.table.pagination.noOfpages;
          let limitPage = 5;
          let nav = [];
          // If less than 6 or equals to 5
          if( totalNoOfPages <= limitPage ){
            for (let x = 1; x <= totalNoOfPages; x++) {
              nav.push(x);
            }
          } else if(currentPage < 3) {
            for (let x = 1; x <= limitPage; x++) {
              nav.push(x);
            }
          } else {
            if(totalNoOfPages >= (currentPage+2)) {
              nav.push(currentPage-2, currentPage-1, currentPage, currentPage+1, currentPage+2);
            } else if(totalNoOfPages >= (currentPage+1)) {
              nav.push(currentPage-3, currentPage-2, currentPage-1, currentPage , currentPage+1);
            } else if(totalNoOfPages == currentPage){
              nav.push(currentPage-4, currentPage-3, currentPage-2, currentPage-1, currentPage);
            }
          } 
          return nav;
        }
      },

      // watch: {
      //   table: {
      //   handler:function(newVal) {
      //    //console.log("new Value is ")
      //     //this.initData();
      //   },
      //     deep:true
      //   },
      // },
    
    
      async mounted() {
        this.initData();
      },
    
      methods: {

        initData() {
          this.records = this.table.records;
          if(this.table.child && this.table.child.display) {
            // we need to append toggle key in parent categories
            this.records.map(item => {
              item.toggle = this.disabletoggle ? true : false; // show all if toggle disabled
            })
          }

          let el = document.getElementById('sorting_tr');
          var sortable = Sortable.create(el, {
              animation: 100,
              onSort: this.collectData,
          });
        },
        
        /**
         * 
         * @param e On cange collect catIds and action
         */
        async collectData(e) {
            let catIds = await this.getCategoryOrder()
            this.sortingcallback(catIds);
        },
        
       
        myclass() {
          // this.listCount++;
          // if(this.listCount % 2 == 0){
          //  return 'data-tr cursor-move even-item ';
          // }
          // return 'data-tr cursor-move odd-item ';
          return 'data-tr cursor-move odd-item td-has-border ';
        },
    
       
        /**
         * get category order in array
         */
        getCategoryOrder() {
          let currentOrder = [];
          let items = document.querySelectorAll('.custom-table .main-cat');
         
          items.forEach(function (item) {
            let catId = item.getAttribute('attr-catid');
            if(catId > 0) {
              currentOrder.push( parseInt(catId) )
            }
          });
          
          let mainTrs = document.querySelectorAll('.custom-table .data-tr');
          let start = 0;
          mainTrs.forEach(function (item) {
             start++;
            let itemClass = start % 2 == 0 ? 'even-item' : 'odd-item';
            item.classList.remove("even-item", "odd-item");
            item.classList.add(itemClass);
          });
          // adjust class
          return currentOrder;
        },
    
        
       /**
        * Toggle parent
        */
       toggleParent(recordIndex) {
        // if toggle is disabled
          if(this.disabletoggle) {
            return;
          }
          this.records.map((item, index) => {
            if(index === recordIndex) {
              this.records[index].toggle = !this.records[index].toggle
            } else {
              this.records[index].toggle = false;
            }
          })
       },
    
        /**
         * Create short html class
         */
        sortClass(head) {
          if(head.key == this.table.sort.key) {
            return (this.table.sort.value == 'desc') ? 'sort sort-desc' : 'sort sort-asc';
          }
          return 'sort sort-both';
        },
    
        /**
         * When dropdown changes
         */
        changePerPageValue() {
          //we need to reset the page for this because we are in page and has 15 data, now select 30records we dont have age 2 on this
          this.table.pagination.activePageNo = 1;
          this.triggerCallBack()
        },
    
        /**
         * Call back on parent component
         */
        triggerCallBack( frontendSort = false) {
          this.callback({
            pageNo: this.table.pagination.activePageNo,
            sort: this.table.sort,
            perpage: this.table.perPageDropdown.active,
            frontendSort: frontendSort
          })
        },
    
        /**
         * When sort heading changes
         */
        sortMe(key) {
          // If same key toggle
          if(key == this.table.sort.key) {
            this.table.sort.value = !this.table.sort.value ? 'asc' : ( this.table.sort.value == 'asc' ? 'desc' : 'asc');
          } else {
            // New key asc
            this.table.sort.value = 'asc';
          }
          this.table.sort.key = key;
          this.triggerCallBack(true)
        },
    
        /**
         * When page item clicked
         */
        changePage(pageNo) {
          this.table.pagination.activePageNo = pageNo;
          this.triggerCallBack()
        },
    
         /**
         * When page next arrow clicked
         */
        movePage(type){
          if(type == 'increase') {
            if( this.table.pagination.activePageNo < this.table.pagination.noOfpages) {
              this.table.pagination.activePageNo =  this.table.pagination.activePageNo + 1;
            }
          } else {
            if(this.table.pagination.activePageNo > 1) {
              this.table.pagination.activePageNo =  this.table.pagination.activePageNo - 1;
            }
          }
          this.triggerCallBack()
        },
      },
    
    
      
    
    });
    
    </script>
    <style scoped>
    .table-loading {
      background: #000000ab;
      height: 100%;
      width: 100%;
      position: absolute;
      justify-content: center;
      align-items: center;
      display: flex;
      z-index: 9;
    }
    td.main-td.active {
        padding-top: 90px;
        position: relative;
    }
    td.main-td.active:before {
        position: absolute;
        height: 88px;
        width: 100%;
        background: #2b2c31;
        top: 0;
        content: '';
        border-top: 1px dotted #fff;
        border-bottom: 1px dotted #fff;
    }
    
    .custom-table.custom-table-bg-same tr.even-item td{
      background: #464851;
      
    }
    table,tr,td{
      position: relative;
      overflow: hidden;
    }
    </style>