<template>
    <div>
        <CustomerImport />
        <CustomerMigration />
    </div>
</template>

<script>
import CustomerImport from './Customer.vue'
import CustomerMigration from './Migration.vue'
import { defineComponent } from 'vue'

export default defineComponent({
    components: {
        CustomerImport,
        CustomerMigration
    }
})
</script>
