/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import store from '@/store/index';
import Env from '@/env';
const apiVersion = 'api';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
      /**
   * Fetch all sales
   */
  fetchAll() {
    const url = `${Env.orderNestUrl}/api/report-subscriber`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch((err) => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },
  create(data){
    const url = `${Env.orderNestUrl}/api/report-subscriber`;
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch((err) => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },
  update(id, data){
    const url = `${Env.orderNestUrl}/api/report-subscriber/${id}`;
    return AuthApis.putApi(url, null, data)
      .then(res => res)
      .catch((err) => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },
  remove(id){
    const url = `${Env.orderNestUrl}/api/report-subscriber/${id}`;
    return AuthApis.deleteApi(url, null)
      .then(res => res)
      .catch((err) => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  }
}