export default {
    emptyStringToNull(objectData){
        if(!objectData || Object.keys(objectData).length==0) return

        for(const ind in objectData){
            if(objectData[ind]===""){
                objectData[ind] = null
            }
        }

        return objectData
    },
    undefinedToEmptyString(objectData){
        if(!objectData || Object.keys(objectData).length==0) return

        for(const ind in objectData){
            if(objectData[ind]===undefined){
                objectData[ind] = ""
            }
        }

        return objectData
    },
    removeEmptyString(objectData){
        if(!objectData || Object.keys(objectData).length==0) return

        for(const ind in objectData){
            if(objectData[ind]==="" || objectData[ind]===null){
                delete objectData[ind]
            }
        }
    },
    pickWithDefault(object, array, defaults){
        if(!object || Object.keys(object).length==0 || array.length==0) return
        
        let result = {}
        for(let ind=0; ind<array.length; ind++){
            if(object[array[ind]]==undefined || object[array[ind]]==null || object[array[ind]]=='Invalid Date'){
                if(defaults && defaults.length>0){
                    result[array[ind]] = defaults[ind]
                }
            }else{
                result[array[ind]] = object[array[ind]]
            }
        }
        return result
    },
    sort(arrayOfObject, key){
        return arrayOfObject.sort(function(a, b) {
            return parseFloat(a[key]) - parseFloat(b[key]);
          })
    },
    moveItem(data, from, to) {
        var f = data.splice(from, 1)[0];
        data.splice(to, 0, f);
    },
    reset(objectData){
        if(!objectData || Object.keys(objectData).length==0) return

        for(const ind in objectData){
            if(typeof objectData[ind]==='string'){
                objectData[ind] = ""
            }else if(typeof objectData[ind]==='number'){
                objectData[ind] = 0
            }else{
                objectData[ind] = null
            }
        }
    },
    compareObjects(object1, object2, fields){
        for(const ind in fields){
            if(object1[fields[ind]]!=object2[fields[ind]]){
                return false
            }
        }
        return true
    }


}