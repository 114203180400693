<template>
    <div class="absolute z-50 top-[30%] left-[40%] w-[400px] h-[200px] drop-shadow-2xl">
        <div class="flex flex-row justify-between w-full h-[50px] bg-theme-green p-4">
            <div class="text-white">Create Folder</div>
            <div class="cursor-pointer" @click="closeAddFolderPop">
                <svg class="fill-white h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/></svg>
            </div>
        </div>
        <div class="bg-field-black px-4 py-2">
            <div class="my-2">
                <input v-model="folderName" class="w-full border-0 focus:ring-0 bg-theme-modalbg text-white" type="text" name="name" placeholder="Name" />
            </div>
            <div class="flex flex-row-reverse text-white my-2">
                <button :disabled="!folderName" @click="createFolder" class="disabled:bg-theme-modalbg disabled:border-0 disabled:cursor-not-allowed mx-2 px-4 py-1 rounded border border-white hover:border-theme-modalbg" type="button">Save</button>
                <button class="mx-2 px-4 py-1 rounded border border-white hover:border-theme-modalbg" type="button" @click="closeAddFolderPop">Close</button>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import {mapMutations} from 'vuex'
import Api from './api'

export default defineComponent({
    props: ['selectedFolder'],
    setup() {
        const folderName = ref('')

        return {folderName}
    },
    methods: {
        closeAddFolderPop(){
            this.$emit('close')
        },
        async createFolder(){
            let data
            this.vm_spinner(true)
            if(this.selectedFolder?.id){
                data = {
                    name: this.folderName,
                    parent_id: this.selectedFolder.id
                }
                await Api.createFolder(data)
            }else{
                data = {
                    name: this.folderName
                }
                await Api.createFolder(data)
            }
            this.vm_spinner(false)
            this.$emit('close', true)
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }
})
</script>
