import LocationsApis from '@/controllers/LocationsApis';
import LoginApis from '@/controllers/LoginApis';
import store from '@/store';

export default {
  namespaced: true,
  state: {
    list: false,
    selected: {},
  },
  getters: {
    list: state => state.list,
    allLocationIds: state => state.list.map(o => o.id),
    selected: state => state.selected,
  },
  mutations: {
    mutateLocations(state, data) {
      state.list = data;
    },
    mutateSelectedLocation(state, data) {
      state.selected = data;
    },
  },
  actions: {

    async setAllLocations(context, data) {
      let res = await LocationsApis.fetchAllLocations();
      if(res.status == 200) {
        context.commit('mutateLocations', res.data.payload);
      } else {
        context.commit('mutateLocations', []);
      }
      return res;
    },

    async selectTheLocation(context, data) {
      let activeBusiness = store.getters['auth/activeBusiness'];
      let res: any = await LoginApis.getRefreshToken({
        businessId: activeBusiness.id,
        locationId: data.id,
      });
      if(res.status = 200) {
        // now save this refresh toke on user profile
        await store.commit('auth/mutateUserByKey', {
          token: res.data.payload.newToken
        })
        await context.commit('mutateSelectedLocation', data);

      } else {
        context.commit('mutateSelectedLocation', {});
      }
      return res;
    },

  },
};
