<template>
    <div class="pb-40">
        <div class="text-white flex-1 col-span-1 border-0 rounded-t-xl overflow-hidden">
            <div class="bg-theme-green px-4 py-2 text-white">
                <div class="flex justify-between items-center">
                    <h2 class="font-bold tracking-wider leading-tight truncate break-all text-2xl mt-0">
                        {{item.editItem.name}}
                    </h2>
                    <div class="flex flex-row items-center">
                        <button @click="saveFeaturedCategory" class="uppercase tracking-widest cursor-pointer bg-white py-2 px-4 rounded text-theme-green hover:bg-theme-darkgreen hover:text-white mr-3">
                            save
                        </button>
                        <button @click="removeFeaturedCategory" class="cursor-pointer rounded-md bg-white text-theme-red py-2 px-4  hover:bg-theme-darkgreen hover:text-white mr-3">
                            Delete
                        </button>
                        <span @click.prevent="closeAdd" class="cursor-pointer rounded-md  text-white py-2 pl-2  hover:opacity-70">CLOSE</span>
                        <!-- <div @click="closeAdd" class="flex flex-col relative justify-between items-center">
                            <span role="img" class=" close-icon flex justify-end cursor-pointer hover:opacity-60 text-4xl ml-3 mdi mdi-close">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.29844 19.1004L0.898438 17.7004L8.59844 10.0004L0.898438 2.30039L2.29844 0.900391L9.99844 8.60039L17.6984 0.900391L19.0984 2.30039L11.3984 10.0004L19.0984 17.7004L17.6984 19.1004L9.99844 11.4004L2.29844 19.1004Z" fill="white"/>
                                </svg>
                            </span>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="pt-3 bg-[#2B2C32] px-[16px] py-[16px] rounded-lg">
            <div class="flex gap-3 xl:flex-row flex-col items-start mb-3">
                <div class="w-full">
                    <div :class="'input-wrapper ' + (form.name.error_type)">
                        <label class="text-xs mb-1">Name</label>
                        <input v-model="form.name.value" @keyup="validateFields(['name'])" placeholder="Dried Flower" class="placeholder-theme-878686 block border-none rounded bg-gray-black w-full text-sm text-theme-white h-10" type="text">
                        <p class="field-error label-text mt-1" v-if="form.name.error_message" v-text="form.name.error_message"></p>
                    </div>
                </div>
                <!-- <div class="w-full xl:w-1/4">
                    <div :class="'input-wrapper ' + (form.category.error_type)">
                        <label class="text-xs mb-1">Category</label>
                        <select-category disabled v-model="form.category.value" @keyup="validateFields(['category'])" :options="categories" placeholder="Featured" class="placeholder-theme-878686 block border-none rounded bg-gray-black w-full text-sm text-theme-white" />
                        <p class="field-error label-text mt-1" v-if="form.category.error_message" v-text="form.category.error_message"></p>
                    </div>
                </div> -->
            </div>
            <div class="flex gap-3 xl:flex-row flex-col items-start mb-3">
                <div class="xl:w-6/12 flex">
                    <div class="input-wrapper w-full">
                        <label class="text-xs mb-1">Category Items</label>
                        <div class="flex justify-between items-center">
                            <span class="placeholder-white block bg-theme-modal_inner_bg pl-3 w-full focus:outline-0 text-sm text-theme-white border-none">{{selectedPids?selectedPids.length:0}} items selected</span>
                            <div class="flex justify-between items-center">
                                <a href="#" class="bg-white p-2 rounded hover:bg-slate-300" @click.prevent="showCategoryWithProductModal('catProd')">
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.3 19.1508L2.75 15.6008L3.8 14.5508L6.3 17.0258L10.775 12.5508L11.825 13.6258L6.3 19.1508ZM6.3 11.1508L2.75 7.60078L3.8 6.55078L6.3 9.02578L10.775 4.55078L11.825 5.62578L6.3 11.1508ZM13.75 17.2508V15.7508H22.75V17.2508H13.75ZM13.75 9.25078V7.75078H22.75V9.25078H13.75Z" fill="#2BA66B"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="input-wrapper ml-4">
                        <label class="text-xs mb-1">Sort</label>
                        <div class="flex justify-between items-center">
                            <a href="#" class="bg-white px-2 pt-3 pb-2 rounded hover:bg-slate-300" @click.prevent="itemOrder = true">
                                <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.58268 21.0993L7.18268 19.6993L9.31602 17.4993C6.87157 17.4993 4.77713 16.666 3.03268 14.9993C1.28824 13.3327 0.416016 11.2771 0.416016 8.83268C0.416016 6.4549 1.24379 4.41602 2.89935 2.71602C4.5549 1.01602 6.57157 0.166016 8.94935 0.166016H14.016V2.16602H8.94935C7.12713 2.16602 5.58268 2.81602 4.31602 4.11602C3.04935 5.41602 2.41602 6.98824 2.41602 8.83268C2.41602 10.7216 3.11046 12.3049 4.49935 13.5827C5.88824 14.8605 7.54935 15.4993 9.48268 15.4993L7.28268 13.2993L8.68268 11.8993L13.216 16.466L8.58268 21.0993ZM16.816 17.4993V15.4993H27.0827V17.4993H16.816ZM16.816 9.83268V7.83268H27.0827V9.83268H16.816ZM16.016 2.16602V0.166016H27.0827V2.16602H16.016Z" fill="#2BA66B"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- <div class="xl:w-6/12 w-full">
                    <div class="input-wrapper">
                        <label class="text-xs mb-1">Recommendations</label>
                        <div class="flex justify-between items-center">
                            <span class="placeholder-white block bg-theme-modal_inner_bg pl-3 w-full focus:outline-0 text-sm text-theme-white border-none">0 items selected</span>
                            <div class="flex justify-between items-center">
                                <svg width="28" height="22" class="mr-4" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.58268 21.0993L7.18268 19.6993L9.31602 17.4993C6.87157 17.4993 4.77713 16.666 3.03268 14.9993C1.28824 13.3327 0.416016 11.2771 0.416016 8.83268C0.416016 6.4549 1.24379 4.41602 2.89935 2.71602C4.5549 1.01602 6.57157 0.166016 8.94935 0.166016H14.016V2.16602H8.94935C7.12713 2.16602 5.58268 2.81602 4.31602 4.11602C3.04935 5.41602 2.41602 6.98824 2.41602 8.83268C2.41602 10.7216 3.11046 12.3049 4.49935 13.5827C5.88824 14.8605 7.54935 15.4993 9.48268 15.4993L7.28268 13.2993L8.68268 11.8993L13.216 16.466L8.58268 21.0993ZM16.816 17.4993V15.4993H27.0827V17.4993H16.816ZM16.816 9.83268V7.83268H27.0827V9.83268H16.816ZM16.016 2.16602V0.166016H27.0827V2.16602H16.016Z" fill="#2BA66B"/>
                                </svg>
                                <a href="#" class="bg-white p-2 rounded hover:bg-slate-300" @click.prevent="showCategoryWithProductModal('catRecommend')">
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.3 19.1508L2.75 15.6008L3.8 14.5508L6.3 17.0258L10.775 12.5508L11.825 13.6258L6.3 19.1508ZM6.3 11.1508L2.75 7.60078L3.8 6.55078L6.3 9.02578L10.775 4.55078L11.825 5.62578L6.3 11.1508ZM13.75 17.2508V15.7508H22.75V17.2508H13.75ZM13.75 9.25078V7.75078H22.75V9.25078H13.75Z" fill="#2BA66B"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <CategoryWithProductModal v-if="categoryWithproductObj.type=='catProd' && categoryWithproductObj.active" :showProductOnly="true" :existingproducts="selectedPids"  :closeClick="closeCategoryWithProductModal" :actionClick="setCategoriesWithProduct"/>
    <CategoryWithRecommendationModal v-if="categoryWithproductObj.type=='catRecommend' && categoryWithproductObj.active" :existingproducts="selectedPids"  :closeClick="closeCategoryWithProductModal" :actionClick="setCategoriesWithProduct"/>
    <SelectCategoryModal
    v-if="itemOrder"
    @close="closeItemModel"
    :item="item.editItem"
  />
</template>

<script>
import Helpers from '@/helpers';
import SelectCategory from '@/components/form/SelectCategory.vue'
import {defineComponent, ref} from "vue"
import { useSnackbar } from "vue3-snackbar"
import { mapGetters, mapActions, mapMutations } from "vuex"
import BusinessProduct from '@/controllers/BusinessProductApis'
import BusinessCategoryApis from "@/controllers/BusinessCategoryApis";
import CategoryWithProductModal from '@/components/CategoriesWithProductModal.vue';
import CategoryWithRecommendationModal from '@/views/Products/Category/FeaturedCategory/CategoriesWithRecommendationModal.vue';
import SelectCategoryModal from '@/views/Products/Category/FeaturedCategory/ItemOrderModal.vue'

export default defineComponent({
    props: ['item'],
    components: {
        SelectCategory,
        CategoryWithProductModal,
        CategoryWithRecommendationModal,
        SelectCategoryModal
    },
    setup() {
        const snackbar = useSnackbar();
        const form = ref({
            name: {
                value: '',
                error_type: '',
                error_message: '',
                validations: ['required', 'min:3', 'max:100'],
            },
            category: {
                value: '',
                error_type: '',
                error_message: '',
                validations: ['required', 'min:3'],
            },
        })
        const itemOrder = ref(false)
        const categories = ref([])
        const selectedPids = ref([]);
        const categoryWithproductObj = ref({
            type: '',
            active: false,
        });

        return {
            snackbar,
            form,
            itemOrder,
            categories,
            selectedPids,
            categoryWithproductObj
        }
    },
    mounted(){
        // this.getCategory()
        this.form.name.value = this.item.editItem.name
        this.form.category.value = this.item.editItem.id
        this.getSelectedProduct()
    },
    watch: {
        'item.editItem.id'(){
            // this.getCategory()
            this.form.name.value = this.item.editItem.name
            this.form.category.value = this.item.editItem.id
            this.getSelectedProduct()
        }
    },
    computed: {
        ...mapGetters({
            vg_selectedLocation: 'location/selected'
        }),
    },
    methods: {
        /**
         * Validation captures
         */
        async validateFields(fields) {
            let res = await Helpers.validateFormFields(this.form, fields);
            // capture the fields
            this.form = res.data;
            return res.error ? false : true;
        },
        async saveFeaturedCategory(){
            // validate business field
            let validate = await this.validateFields(['name', 'category']);
            if(!validate) {
                return ;
            }
            
            let catData = {...this.item.editItem}
            catData.name = this.form.name.value
            catData.category = this.form.category.value
            this.vm_spinner(true)
            await BusinessCategoryApis.updateCategory(this.item.editItem.id, catData)
            await this.syncProduct()
            this.vm_spinner(false)
            this.$emit('clearComponent')
            this.emitter.emit('reloadFeaturedList', true)
        },
        // async getCategory(){
        //     let res = await BusinessProduct.fetchAllCategory()
        //     if(res.data?.payload?.category){
        //         for(let i=0; i<res.data.payload.category.length; i++){
        //             this.categories.push(res.data.payload.category[i])
        //             if(res.data.payload.category[i].subCategories && res.data.payload.category[i].subCategories.length>0){
        //                 for(let j=0; j<res.data.payload.category[i].subCategories.length; j++){
        //                     this.categories.push(res.data.payload.category[i].subCategories[j])
        //                 }
        //             }
        //         }
        //     }
        // },
        async getSelectedProduct(){
            let res = await BusinessCategoryApis.FetchFeaturedCategory(this.vg_selectedLocation.id, this.item.editItem.id)
            if(res.data?.payload){
                this.selectedPids = res.data.payload.map(each => each.id)
            }else{
                this.selectedPids = []
            }
        },
        async syncProduct(msg='Featured category updated successfully.'){
            let res = await BusinessCategoryApis.syncFeaturedProduct(this.item.editItem.id, {
                locationId: this.vg_selectedLocation.id,
                productIds: this.selectedPids,
                replace: true
            })
            if(res.status = 200){
                this.snackbar.add({
                    type: 'info',
                    text: res.status == 200 ? msg : Helpers.catchErrorMessage(res.response.data),
                    duration: 3000,
                })
            }
        },
        removeFeaturedCategory(){
            this.confirm('Are you sure to delete this?').then(async () => {
                this.vm_spinner(true)
                let res = await BusinessCategoryApis.deleteCategory(this.item.editItem.id)
                if(res.status == 200){
                    this.snackbar.add({
                        type: 'info',
                        text: 'Featured category deleted successfully',
                        duration: 3000,
                    })
                }
                this.vm_spinner(false)
                this.$emit('clearComponent')
                this.emitter.emit('reloadFeaturedList', true)
            })
        },
        closeItemModel(){
            this.itemOrder = false
            this.getSelectedProduct()
            this.emitter.emit('reloadFeaturedList', true)
        },
        /**
         * Open product with category Model
         */
        showCategoryWithProductModal(type) {
            this.categoryWithproductObj.type = type
            this.categoryWithproductObj.active = true;
        },
        /**
         * Close select category modal
         */
        closeCategoryWithProductModal() {
            this.categoryWithproductObj.active = false;
        },
        /**
         * Get data selected from category modal
         */
        setCategoriesWithProduct(data) {
            this.selectedPids = data.pids;
            this.closeCategoryWithProductModal();
        },
        closeAdd(){
            this.$emit('clearComponent')
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }
})
</script>

