/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import store from '@/store/index';
import ProductApis from '@/controllers/ProductApis';
import UserApis from '@/controllers/UserApis'
import ApiHelper from '@/controllers/ApiHelpers';

export default {
    /**
     * Fetch all products with location
     */
    importCovaProducts(data) {
       const url = `/product/importCovaProducts`;
       return ProductApis.postApi(url, null, data)
         .then(res => res)
         .catch(err => {
           ApiHelper.detectStatusAndRedirect(err);
           return err;
         });
    },

    importCustomer(data){
       const url = `/users/importCustomers`;
       return UserApis.postApi(url, null, data)
         .then(res => res)
         .catch(err => {
           ApiHelper.detectStatusAndRedirect(err);
           return err;
         });
    },

    migrateCustomer(data){
       const url = `users/exportCustomers/`;
       return UserApis.postApi(url, null, data)
         .then(res => res)
         .catch(err => {
           ApiHelper.detectStatusAndRedirect(err);
           return err;
         });
    }

}