import Helpers from '@/helpers';
/**
 * Check if token exist or not, if not redirect back to login page
 */
export default function checkpermissions({ next, router, to}) {
    if(to.meta.permissions && to.meta.permissions.length > 0) {
        // Check for every permission to valid if any of invalid throw them to products
        to.meta.permissions.forEach(term => {
           if(Helpers.getMyPermission(term) == false) {
            return router.push({ name: 'Products' });
           }
        })
    }
   
    return next();
}
