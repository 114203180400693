
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
import BusinessCategoryApis from "@/controllers/BusinessCategoryApis";
import LocationCannabisSettingApi from '@/controllers/LocationCannabisSetting';
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';
import TaxSelectCheckbox from '@/components/form/CategorySelectCheckbox.vue'
import BusinessTaxApis from "@/controllers/BusinessTaxApis";

  export default defineComponent({
  name: "ProductCategoryAdd",
  components: {
    TaxSelectCheckbox
  },
  props: {
    clearComponent: {
      type: Function,
      required: true,
    },
  },

  setup() {
    const snackbar = useSnackbar();
    const locationTaxes = ref([]);
    const form = <any>ref({
      // businessId: '',
      isSubCategory: false,
      lotTracking: false,
      parent: {
        value: null,
        error_type: '',
        error_message: '',
        validations: ['required_if:isSubCategory|true|string'],
      },
      name: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'min:3', 'max:30'],
      },
      description: "",
      ComplianceType: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      // stockThreshold: {
      //   value: 1,
      //   error_type: '',
      //   error_message: '',
      //   validations: ['required','positivenumber', 'maxval:500000'],
      // },
      posColour: {
        value: '#000000',
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      concentrationUnit: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['notrequired_multiple_if:ComplianceType|Non-cannabis|object'],
      },
      thcCbdValueType: "single"
    })
    const thcCbdValueTypeRange = ref(false);
    const existingProductParentCategories = <any>ref([]);
    const globalLotSetting = ref(false)
    const taxes = ref([])
    const categoryTaxes = ref([])

    return {
      snackbar,
      locationTaxes,
      form,
      thcCbdValueTypeRange,
      existingProductParentCategories,
      globalLotSetting,
      taxes,
      categoryTaxes
    };
  },

  computed: {
    ...mapGetters({
      vg_activeBusiness: 'auth/activeBusiness',
      vg_selectedLocation: 'location/selected'
    }),
  },
  watch: {
    'thcCbdValueTypeRange'(value) {
      this.form.thcCbdValueType = value ? "range" : "single";
    },
    'form.isSubCategory'(value) {
      this.form.parent.value = value ? this.form.parent.value : null;
      if(value == false) {
        this.validateFields(['parent']);
      }
    }
  },

  async mounted() {
    this.fetchLotSetting();
    this.fetchExistingProductCategories();
    this.fetchTaxes()
  },
 
  methods: {

    /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },

    /**
     * Fetch location lot setting
     */
    async fetchLotSetting(){
      let res = await LocationCannabisSettingApi.fetchSetting();
      // console.log(res.data.payload.lotTracking)
      this.globalLotSetting =  res.status == 200 ? res.data.payload.lotTracking : false;
    },

    /**
     * Fetch existing categories
     */
    async fetchExistingProductCategories() {
      let res = await BusinessCategoryApis.fetchAllBusinessCategries({
        businessId: this.vg_activeBusiness.id, 
        page: 1, 
        perPage: 1000
      });  
      if(res.status == 200) {
        this.existingProductParentCategories = res.data.payload.category.filter(item => !item.parent);
      }
    },

    async fetchTaxes(){
        let res = await BusinessTaxApis.getTaxForBusiness(this.vg_activeBusiness.id)
        if(res.status==200){
          this.taxes = res.data.payload
        }
    },
 
 
    async addNewCategory() {
      // validate business field
      let validate = await this.validateFields(['name', 'parent', 'ComplianceType', 'posColour', 'concentrationUnit']);
      if(!validate) {
        return ;
      }

      // this.form.businessId = this.vg_activeBusiness.id;
      let data = <any>Helpers.getFormKeyValue(this.form);
      this.va_spinner(true);
      let res = await BusinessCategoryApis.addNewCategory(data);
      if(res.data.payload && this.vg_selectedLocation?.id){
        // update only if location selected
        await BusinessTaxApis.assignTaxToCategory(res.data.payload.id, {taxIds: this.categoryTaxes, locationId: this.vg_selectedLocation.id})
      }
      this.va_spinner(false);
      this.snackbar.add({
        type: 'info',
        text: res.status == 201 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
      if(res.status == 201) {
        this.clearComponent()
         this.emitter.emit("refresh-business-categories-list", true);
      }
    },

    /**
     * Open category modal
     */
    openCategoryModal() {
      alert('waiting for api')
    },

    /**
     * Open category modal
     */
    openRecommendCategoryModal() {
      alert('waiting for api')
    },
    /**
     * Do not allow to add +,-,e
     */
    preventNonNumbers(e) {
       var ASCIICode = (e.which) ? e.which : e.keyCode
      if(ASCIICode == 43 || ASCIICode == 45 || ASCIICode == 101){
        return false;
      }
      return true;
    },
    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  },
});

