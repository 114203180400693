<template>

    <block-loading v-if="loading" />
    <table-lite
      v-else
      :class="'admin-table'"
      :is-loading="table.isLoading"
      :is-slot-mode='true'
      :columns="columns"
      :rows="table.rows"
      :rowClasses="customRowClass"
      :total="table.totalRecordCount"
      :sortable="table.sortable"
      @is-finished="table.isLoading = false"
      :page-size='table.pagesize'
      @do-search="doSearch"
      :pageOptions='table.pageOptions'
      :has-checkbox="false"
      @row-clicked="selectedRow"
      :checked-return-type="'id'"
      :is-hide-paging="true"
      :pageSizeChangeLabel="'pageSizeChangeLabel'"
      :messages="{
        pagingInfo: '',
        pageSizeChangeLabel: 'Items per Page',
        gotoPageLabel: '',
        noDataAvailable: 'No records found!',
      }"
    >
    <template v-slot:startDate="data">
        <div>
          {{ data.value.startDate ? formatDate(data.value.startDate) : '' }}
        </div>
      </template>
      <template v-slot:endDate="data">
        <div>
          {{ data.value.endDate ? formatDate(data.value.endDate) : '' }}
        </div>
      </template>

    </table-lite>

</template>

<script>
import  { defineComponent , ref} from "vue";
import { mapGetters, mapActions } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import moment from 'moment'
import LocationHolidayHoursApis from "@/controllers/LocationHolidayHoursApis";

export default defineComponent({
  name: "LocationHolidayList",
  components: {
  },
  props: {
    iscomponentactive: {
      type: Boolean,
      required: false,
    },
    selectedCallback: {
      type: Function,
      required: true,
    },
    clearComponent: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const snackbar = useSnackbar();
  
    const activeItem = ref({});
    const loading = ref(false);
    const columnsMain = ref([
      {
        label: "Name",
        field: "name",
        sortable: true,
      },
      {
        label: "Start Date",
        field: "startDate",
        sortable: true,
        width: '150px'
      },
      {
        label: "End Date",
        field: "endDate",
        sortable: true,
        width: '150px'
      },
    ]);

    const table = ref({
      isLoading: false,
      rows: [],
      totalRecordCount: 0,
      sortable: {
        order: "",
        sort: "",
      },
      pagesize: 5,
      pageOptions: [{ value: 5, text: 5 }, { value: 10, text: 10 } ],
      count: 0,
    });
 
    return  {
      table,
      columnsMain,
      activeItem,
      loading,
      snackbar,
    };
  },

  async mounted() {
    this.loading = true;
    await this.fetchList();
    this.loading = false;
    this.emitter.on("refresh-location-holidays-list", data => {
      this.fetchList();
    });
  },

  beforeDestroy() {
    this.emitter.off('refresh-location-holidays-list', () => {});
  },

  watch: {
    'vg_selectedLocation'(val) {
      // if location selected is changed fetch the data
      this.fetchList();
    },
  },

  computed: {
    ...mapGetters({
      vg_selectedLocation: 'location/selected'
    }),

    /**
     * Compute columns
     */
    columns() {
      // No filter for this
      return this.columnsMain;
    }
   
  },

  methods: {
     formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    /**
     * Fetch list
     */
    async fetchList() {
      let res = await LocationHolidayHoursApis.fetchAllLocationHolidayHours();  
      if(res.status == 200) {
        this.table.rows = res.data.payload;
      }
    },

    /**
     * Select the item
     */
    setActive(item) {
      // Select unselect the item
      if(this.activeItem.id) {
        let oldactive = document.getElementsByClassName('thisid_'+this.activeItem.id);
        if(oldactive.length > 0){
          oldactive[0].classList.remove("active-row");
        }
      }
      this.activeItem = item;
      let newActive = document.getElementsByClassName('thisid_' + this.activeItem.id);
      if(newActive.length > 0){
        newActive[0].classList.add("active-row");
      }
      this.selectedCallback(item);
    },

    /**
     * Create custom class on tr for active colors
     */
    customRowClass(item) {
      return 'thisid_' + item.id;
    },

    selectedRow(obj){
      this.setActive(obj)
    },
 
    /**
     * Search now
     */
    doSearch(offset, limit, order, sort) {
      if(order && sort) {
         this.table.rows = this._lodash.orderBy(this.table.rows, [order],[sort]);
      }
    },

    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })

  }
});
</script>

