<template>
  
  <div class="bg-[#464851] text-white rounded-xl p-4"  v-if="loading">
    <block-loading/>
  </div>

  <div class="pb-2"  v-else>
    <div class="bg-theme-green border-0 rounded-t-xl px-4 py-2 text-white">
      <div class="flex justify-between items-center">
        <h2 class="font-semibold leading-tight text-2xl mt-0">Print Menu</h2>
        <span @click="closePrintMenu" class="cursor-pointer rounded-md  text-white py-2 pl-1  hover:opacity-70">CLOSE</span>
        <!-- <div class="flex flex-col">
          <div class="flex justify-end items-center">
            <mdicon @click="closePrintMenu"  size="32" name="close" class="cursor-pointer text-lg hover:opacity-60"/>
          </div>
        </div> -->
      </div>
    </div>

  
    <div class="pt-3 bg-[#2B2C32] px-[16px] py-[16px] rounded-lg">
      <div class="overflow-y-auto max-h-[600px] pr-5 pl-2">
        <p v-if="!vg_locationSelected.id" class="text-theme-red">Please select a location.</p>
        <ul>
          <!-- Main-category -->
          <li class="my-4" v-bind:key="'main_cat' + categoryIndex" v-for="category, categoryIndex in catWithProducts">
            <div class="flex justify-between">
              <label class="flex justify-between items-center cursor-pointer" @click="selectMainCategory(categoryIndex)">
                <div :class="'h-4 w-4 border-[1px]' + (category.selected ? ' border-theme-green bg-theme-green addcheckbox' : ' border-white')">
                  <mdicon v-if="category.selected" name="check" size="14" />
                  <mdicon v-if="category.selected == false && category.someSelected" name="minus" size="14" />
                </div>
                <span class="w-5 h-5 rounded  mx-4" :style="'background:'+category.posColour"></span>
                <div class="flex flex-col">
                  <p class="text-base tracking-wider font-semibold">{{ category.name }}</p>
                  <div class="text-[10px] tracking-widest font-black text-theme-878686">MAIN CATEOGRY</div>
                </div>
              </label>
              <!-- <p v-if="category.selected" class="uppercase flex align-middle items-center font-medium text-sm tracking-widest text-theme-green">select items</p> -->
            </div>

            <!-- Sub-category -->
            <ul class="ml-8 my-4" v-if="category.subCategories && category.subCategories.length > 0">
              <li class="my-5"  v-bind:key="'subcat_index' + subcatIndex" v-for="subcat, subcatIndex in category.subCategories">
                <div class="flex justify-between">
                  <label class="flex justify-between items-center cursor-pointer" @click="selectSubCategory(categoryIndex, subcatIndex)">
                    <div :class="'h-4 w-4 border-[1px]' + (subcat.selected ? ' border-theme-green bg-theme-green addcheckbox' : ' border-white')">
                      <mdicon v-if="subcat.selected" name="check" size="14" />
                      <mdicon v-if="subcat.selected == false && subcat.someSelected" name="minus" size="14" />
                    </div>
                    <span class="w-5 h-5 rounded bg-theme-green mx-4" :style="'background:'+subcat.posColour"></span>
                    <div class="flex flex-col">
                      <p class="text-base tracking-wider font-semibold">{{ subcat.name }}</p>
                      <div class="text-[10px] tracking-widest font-black text-theme-878686">SUB CATEOGRY</div>
                    </div>
                  </label>
                  <!-- <p v-if="subcat.selected" class="uppercase flex align-middle items-center font-medium text-sm tracking-widest text-theme-green">select items</p> -->
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div class=" flex justify-center items-center mt-8" v-if="catWithProducts.length > 0">
        <button class="w-96 px-2 py-3 bg-theme-green hover:bg-[#2B5545] uppercase text-center rounded-lg text-white" @click="processSelectedCategories()">
          Confirm
        </button>
      </div>
      <!-- <PrintMenuProductsModal :locationAllCategoriesObj="locationAllCategoriesObj" v-if="productsModal.open" :products="productsModal.products" :closeClick="closePrintProductModal"/> -->
    </div>
  </div>
</template>

<script>
import { ref, defineComponent } from 'vue';
import BusinessCategoryApis from "@/controllers/BusinessCategoryApis";
import { mapGetters, mapActions, mapMutations } from "vuex";
//import PrintMenuProductsModal from '@/views/Products/Product/PrintMenu/SelectProductModal.vue';
import writeXlsxFile from 'write-excel-file';
import { useSnackbar} from "vue3-snackbar";
import Helpers from '@/helpers';
import moment from 'moment';
import string from '@/helpers/string';

export default defineComponent({
  components: {
    // PrintMenuProductsModal
  },
  setup() {
   const loading = ref(false);
   const catWithProducts = ref([]);
   const productsModal = ref({
      open: false,
      products: [],
   });
   const locationAllCategoriesObj = ref({});
   const snackbar = useSnackbar();
   return { loading, catWithProducts, productsModal, snackbar, locationAllCategoriesObj };
  },
  watch: {
    'vg_locationSelected.id'() {  
      this.fetchData();
    },
  },
  computed: {

    checkedRecords() {
        let products = [];
        // console.log(this.catWithProducts)

        this.catWithProducts.forEach(category => {
          let mainCategoryName = category.name;

          if(category.products && category.products.length > 0){
            category.products.forEach(categoryProduct => {
              if(category.selected){
                categoryProduct.parentCat = mainCategoryName;
                products.push(categoryProduct)
              }
            })
          }
          // For sub cats, ignore main product f sub cat available
          if(category.subCategories && category.subCategories.length > 0){
            category.subCategories.forEach(subcat => {
              // SUb cat products
              let subCategoryName = subcat.name;
              if(subcat.products && subcat.products.length > 0){
                subcat.products.forEach(subcatProduct => {
                  if(subcat.selected){
                    subcatProduct.parentCat = mainCategoryName;
                    subcatProduct.subCat = subCategoryName;
                    products.push(subcatProduct)
                  }
                })
              }
            })
          } 
        })
        return {
          products,
        }
      },

    ...mapGetters({
      vg_activeBusiness: 'auth/activeBusiness',
      vg_locationSelected: "location/selected",
    }),
  },

  async mounted() {
    this.fetchData();
  },

  methods: {
      /**
       * Fetch data
       */
      async fetchData() {
        if(!this.vg_locationSelected.id) {
          return false;
        }
        this.loading = true;
        let res = await BusinessCategoryApis.fetchCategoryWithProductsInDetail(this.vg_activeBusiness.id, this.vg_locationSelected.id);
          // Once we get loction fetch all categories of this location
        this.loading = false;
        if(res.status == 200) {
          
          this.locationAllCategoriesObj = await Helpers.collectAllCategoriesInObj(res.data.payload);
          let categoryWithProducts = res.data.payload;
          // push toggle key on all categories upto child
          categoryWithProducts.map(item => {

            item.products.map(mainProduct => {
              mainProduct.selected = false;
            })
            
            // for sub cat
            if(item.subCategories && item.subCategories.length > 0){
              item.subCategories.map(subitem => {
                // if subcat check for subcat product
                subitem.products.map(subitemProduct => {
                  subitemProduct.selected = false;
                })
                subitem.selected = false;
                subitem.someSelected = false;
              })
            }
            item.selected = false;
            item.someSelected = false;
              
          })
          this.catWithProducts = categoryWithProducts;
         
        }
      },

      /**
     * Select main category
     */
    async selectMainCategory(mainCatIndex) {
      this.catWithProducts = await Helpers.toggleMainCategorySelect(this.catWithProducts, mainCatIndex);
    },
    
    /**
     * Main cat product
    */
    async selectMainCategoryProduct(mainCatIndex, mainCatproductIndex){
      this.catWithProducts = await Helpers.toggleMainCategoryProductSelect(this.catWithProducts, mainCatIndex, mainCatproductIndex);
    },

    /**
     * Select sub cat index
     */
    async selectSubCategory(mainCatIndex, subcatIndex) {
      let a = await Helpers.toggleSubCategorySelect(this.catWithProducts, mainCatIndex, subcatIndex);
      this.catWithProducts  = a;
    },

    /**
     * Sub cat products
     */
    async selectSubCategoryProduct(mainCatIndex, subcatIndex, subcatProductIndex) {
      
      this.catWithProducts = await Helpers.toggleSubCategoryProductSelect(this.catWithProducts, mainCatIndex, subcatIndex, subcatProductIndex);
    },

    /**
     * processSelectedCategories
     */
    processSelectedCategories() {
      
      if(this.checkedRecords.products.length < 1) {
        return this.snackbar.add({
          type: 'info',
          text: 'Must have at least a product on category',
          duration: 3000,
        })
      }

      //  we do not need to pass to product model now lets download from here
      this.createXlSheet();


      /**
       * Incase we need to select product
       */

      // this.productsModal = {
      //   open: true,
      //   products: this.checkedRecords.products
      // };
    },

    /**
     * Close modal
     */
    // closePrintProductModal() {
    //   this.productsModal = {
    //     open: false,
    //     products: []
    //   };
    // },

    /**
     * Create xl sheet
     */
    async createXlSheet() {
       // let selectedProducts = this.checkedRecords.products.filter(each => each.productPricing && each.productPricing.stock && each.productPricing.stock>0);
       let selectedProducts = this.checkedRecords.products;
        let selectedProductsWithVariant = []
        // let selectedProducts = this.checkedRecords.products
        if(selectedProducts.length < 1) {
          return this.snackbar.add({
            type: 'info',
            text: 'No product found on the selected category.',
            duration: 3000,
          })
        }
        // get all variants as well
        for(let i=0; i<selectedProducts.length; i++){
          if(selectedProducts[i].ppiStock && selectedProducts[i].ppiStock>0){
            selectedProductsWithVariant.push(selectedProducts[i])
            if(selectedProducts[i].variants?.length>0){
              selectedProductsWithVariant = [...selectedProductsWithVariant, ...selectedProducts[i].variants]
            }
          }
        }

        try {
         let schema = [
            {
              column: 'CATEGORY',
              type: String,
              wrap: true,
              alignVertical: 'center',
              align: 'center',
              value: (i) => {
                if(i.parentCat) {
                  return i.parentCat;
                }else{
                  ''
                }
              }, 
            },
            {
              column: 'SUB CATEGORY',
              type: String,
              wrap: true,
              alignVertical: 'center',
              align: 'center',
              value: (i) => {
                if(i.subCat) {
                  return i.subCat
                } else {
                  return '';
                }
              }, 
            },
            {
              column: 'PRODUCT',
              type: String,
              wrap: true,
              alignVertical: 'center',
              align: 'center',
              value: i => i.name ? i.name :''
            },
            {
              column: 'BRAND',
              type: String,
              wrap: true,
              alignVertical: 'center',
              align: 'center',
              value: i => {
                if(i.brand && i.brand.name){
                  return i.brand.name;
                } else {
                  return '';
                }
              }
            },
            {
              column: 'SIZE',
              type: String,
              wrap: true,
              alignVertical: 'center',
              align: 'center',
              value: i => i.variantName ? i.variantName : '' 
            },
            {
              column: 'TYPE',
              type: String,
              wrap: true,
              alignVertical: 'center',
              align: 'center',
              value: i => i.strain ? i.strain : ''
            },
            {
              column: 'PRICE',
              type: Number,
              format: '0.00',
              wrap: true,
              alignVertical: 'center',
              align: 'center',
              value: (i) =>  {
                if(i.ppiPrice ) {
                  return Math.round( i.ppiPrice * 100) / 100;
                } else {
                  return 0.00;
                }
              } 
            },

            {
              column: 'PRICE/G',
              type: Number,
              format: '0.00',
              wrap: true,
              alignVertical: 'center',
              align: 'center',
              value: (i) =>  {
                if(i.ppiPrice && i.ppiPrice ) {
                  let val =  i.dryCannabisEquivalency ? i.ppiPrice/i.dryCannabisEquivalency : i.ppiPrice;
                  return Math.round(val * 100) / 100;
                } else {
                  return 0.00;
                }
              } 
            },

            {
              column: 'AROMAS',
              type: String,
              wrap: true,
              alignVertical: 'center',
              align: 'center',
              value: (i) =>  {
                if(i.aroma && (i.aroma.length > 0) ) {
                  let datas = this._lodash.map(i.aroma, 'name');
                  return datas.join(',');
                }
              } 
            },

            {
              column: 'TERPENES',
              type: String,
              wrap: true,
              alignVertical: 'center',
              align: 'center',
              value: (i) =>  {
                if(i.terpene && (i.terpene.length > 0) ) {
                  let datas = this._lodash.map(i.terpene, 'name');
                  return datas.join(',');
                }
              } 
            },

            {
              column: 'SALE PRICE',
              type: Number,
              format: '0.00',
              wrap: true,
              alignVertical: 'center',
              align: 'center',
              value: (i) =>  {
                if(i.ppiSalePrice) {
                  return i.ppiSalePrice
                } else {
                  return 0.00;
                }
              } 
            },

            {
              column: 'TOTAL TERPS',
              type: String,
              wrap: true,
              alignVertical: 'center',
              align: 'center',
              value: (i) =>  {
                if(i.plcfTotalTerp) {
                  return i.plcfTotalTerp+'%'
                } else {
                  return '';
                }
              } 
            },

            {
              column: 'STOCK',
              type: Number,
              wrap: true,
              alignVertical: 'center',
              align: 'center',
              value: (i) =>  {
                if(i.ppiStock) {
                  return i.ppiStock
                } else {
                  return '';
                }
              } 
            },

            {
              column: 'DISCOUNT',
              type: String,
              wrap: true,
              alignVertical: 'center',
              align: 'center',
              value: (i) =>  {
                if(i.ppiSalePrice && i.ppiPrice) {
                  let percent = Math.round(((i.ppiPrice-i.ppiSalePrice)/i.ppiPrice*100))
                  if(percent==0){
                    return ''
                  }else{
                    return percent.toString()+'%'
                  }
                } else {
                  return ''
                }
              } 
            },

            {
              column: 'THC',
              type: String,
              wrap: true,
              alignVertical: 'center',
              align: 'center',
              value: (i) =>  {
                if( i.complianceType != 'Accessory') {
                  if(i.thcCbdValueType == 'range') {
                    if(i.thcMax || i.thcMin){
                      return ((i.thcMax)  ? i.thcMax.toString() : '0') + ' - ' + (i.thcMin ? i.thcMin.toString() : '0') + '' + (i.concentration_unit ? i.concentration_unit : '');
                    }else{
                      return ''
                    }
                  } else {
                    if(i.thc){
                      return  ((i.thc) ? i.thc.toString() : '') + '' + (i.concentration_unit ? i.concentration_unit : '');
                    }else{
                      return ''
                    }
                  }

                } else {
                  return '';
                }
              } 
            },
            {
              column: 'CBD',
              type: String,
              wrap: true,
              alignVertical: 'center',
              align: 'center',
              value: (i) =>  {
                if( i.complianceType != 'Accessory') {
                  let val = ''
                  if(i.thcCbdValueType == 'range') {
                    if(i.cbdMax || i.cbdMin){
                      return ((i.cbdMax) ? i.cbdMax.toString() : '0') + ' - ' + (i.cbdMin ?  i.cbdMin.toString(): '0') + '' + (i.concentration_unit ? i.concentration_unit : '');
                    }else{
                      return ''
                    }
                  } else {
                    if(i.cbd){
                      return (i.cbd ? i.cbd.toString() : '') + '' + (i.concentration_unit ? i.concentration_unit : '') ;
                    }else{
                      return ''
                    }
                  }
                }
              } 
            },

            
          ];


          
          let xlBlob = await writeXlsxFile(selectedProductsWithVariant, {
            schema: schema,
          });
          let fileName = moment().unix() + '-print-product-menu.xls';
          let url = URL.createObjectURL(xlBlob);
          let link = document.createElement("a");
          link.setAttribute('href', url);
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          // this.closeClick();
        } catch(err) {
          console.log(err)
           return this.snackbar.add({
            type: 'info',
            text: 'Something went wrong. Please try again later.',
            duration: 3000,
          })
        }
      },
    
    closePrintMenu() {
      this.$emit('close')
    },
  },
});

</script>