/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import Env from '@/env';
const apiVersion = 'api';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /**
   * Fetch location info
   */
  fetchLocationInfo() {
    const url = `${Env.loginUrl}/${apiVersion}/locations/show`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * Update location info
   */
  updateLocationInfo(data) {
    const url = `${Env.loginUrl}/${apiVersion}/locations/update`;
    return AuthApis.patchApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * Delete location info
   */
  deleteLocationInfo() {
    const url = `${Env.loginUrl}/${apiVersion}/locations/remove`;
    return AuthApis.deleteApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


}