<template>
  <div>
    <ul class="flex bg-[#2B2C32] rounded-full px-8 py-1 mb-1">
      <li :class="'mr-4' + ($route.name == 'ComplianceReport' ? ' active' : '')">
        <router-link :to="{ name: 'ComplianceReport' }" class="inline-block text-theme-white uppercase tracking-widest text-sm font-semibold px-2">
          Compliance Report
        </router-link>
      </li>
    </ul>
  </div>
</template>

<style scoped>
  .active:before {
    content: '';
    position: absolute;
    background: #2BA66B;
    width: 100%;
    height: 4px;
    bottom: -8px;
  }
  .active {
    position:relative;
  }
</style>