
import { defineComponent, ref, reactive } from "vue";
import EditSecurityRole from './EditSecurityRole.vue'
import AddSecurityRole from './AddSecurityRole.vue'
import SecurityRoleApi from "@/controllers/RoleApis";
import { mapMutations } from "vuex";


export default defineComponent({
    components: {
        AddSecurityRole,
        EditSecurityRole
    },
    setup(){
        // table
        const table = reactive({
            loading: true,
            columns: [
                {label: 'Role', field:'role', sortable: true, isKey: true},
                {label: 'Description', field:'description', sortable: true}
            ],
            rows: [],
            sortable: {
                order: "role",
                sort: "asc",
            }
        })

        //edit
        const edit = ref(false)
        const editItem = ref()

        const add = ref(false)

        return {table, add, edit, editItem}
    },
    mounted(){
        this.edit = false
        this.getData()
    },
    methods: {
        async getData(){
            this.table.loading = true
            let {data: {payload}} = await SecurityRoleApi.getAllRoles()

            this.table.rows = payload
            this.table.loading = false
        },
        selectedRow(item){
            // selection effect
             let activeRow = document.getElementsByClassName(item.id);
             if(activeRow[0] && activeRow[0].parentNode && activeRow[0].parentNode.children){
                for(let sibling of activeRow[0].parentNode.children){
                    sibling.classList.remove('active-row')
                }
             }
             activeRow[0].classList.add("active-row")

            // action
            this.add = false
            this.edit = true
            this.editItem = item
        },
        edited(){
            this.closeEdit()
            this.getData()
        },
        closeEdit(){
            this.edit = false
            this.add = false
        },
        createSecurityRole(){
            this.add = true
            this.edit = false
        },
        added(){
            this.closeAdd()
            this.getData()
        },
        closeAdd(){
            this.add = false
            this.edit = false
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }
})
