
import  { defineComponent , ref} from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import StoreHours from '@/views/Location/LocationInfo/StoreHours.vue';
import Info from '@/views/Location/LocationInfo/Index.vue';

export default defineComponent({
  name: "MessageBanner",
  components: {
    Info,
    StoreHours
  },

  setup() {
    let showComponent = ref(true);
    return {
      showComponent
    }
  },

  computed: {
    ...mapGetters({
      vg_selectedLocation: 'location/selected'
    }),
  },

  watch: {
    'vg_selectedLocation': async function() {
    
    },
  },

  async mounted() {
 
  },

  methods: {
    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })
  }
});
