
import { useSnackbar } from "vue3-snackbar";
import {defineComponent, ref} from "vue"
import Helpers from '@/helpers';
import WebsiteSettingApi from "@/controllers/WebsiteSettingApis";
import { mapActions } from "vuex";

  export default defineComponent({
  name: "Website",
  setup() {
      const snackbar = useSnackbar();
      const loading = ref(false);
      const websiteForm = ref({
        domain: {
          value: '',
          error_type: '',
          error_message: '',
          validations: ['required', 'urlOnly'],
        },
        googleAnalyticsKey: {
          value: '',
          error_type: '',
          error_message: '',
          validations: ['required', 'min:3'],
        }
      });

      return {
        websiteForm,
        loading,
        snackbar
      };
  },
  async mounted() {
    this.loading = true;
    await this.fetchWebsiteSetting();
    this.loading = false;
  },
  methods: {
    /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.websiteForm, fields);
      // capture the fields
      this.websiteForm = res.data;
      return res.error ? false : true;
    },
    async fetchWebsiteSetting(){
        let res = await WebsiteSettingApi.fetchWebsiteSetting();
        if(res.status == 200) {
            this.websiteForm.domain.value = res.data.payload.domain
            this.websiteForm.googleAnalyticsKey.value = res.data.payload.googleAnalyticsKey
        }
    },
    async createOrSave(){
        // validate business field
      let validate = await this.validateFields(['domain', 'googleAnalyticsKey']);
      if(!validate) {
        return ;
      }

      this.va_spinner(true);
      // rest data need to update on business branding
      let res = await WebsiteSettingApi.updateWebsiteSetting({
        domain: this.websiteForm.domain.value,
        googleAnalyticsKey: this.websiteForm.googleAnalyticsKey.value
      });
      this.va_spinner(false);
      this.snackbar.add({
        type: 'info',
        text: (res.status == 200 || res.status==201) ? res.data.message : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
    },
    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  }

});

