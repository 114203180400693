/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import store from '@/store/index';
import Env from '@/env';
const apiVersion = 'api';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
      /**
   * Fetch all sales
   */
  create(data) {
    const url = `${Env.orderNestUrl}/api/location-vault`;
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch((err) => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },
  
  list(locationId, params){
    const url = `${Env.orderNestUrl}/api/location-vault/forLocation/${locationId}?${params}`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch((err) => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  closeVault(locationId, data) {
    const url = `${Env.orderNestUrl}/api/location-vault/closeLocationVault/${locationId}`;
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch((err) => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  getVaultBalance(locationId){
    const url = `${Env.orderNestUrl}/api/location-vault/getVaultBalance/${locationId}`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch((err) => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  }
}