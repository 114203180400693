/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import store from '@/store/index';
import Env from '@/env';
const apiVersion = 'api';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
    /**
     * Fetch all location tax
     */
    fetchAllBusinessTaxes() {
        const url = `${Env.loginUrl}/${apiVersion}/business-taxes/all`;
        return AuthApis.getApi(url, null)
         .then(res => res)
         .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
         });
    },


     /**
     * Add new tax for a location
     */
      addLocationTax(data) {
       const url = `${Env.loginUrl}/${apiVersion}/business-taxes/create`;
       return AuthApis.postApi(url, null, data)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
   },


   /**
     * Update tax for a location
     */
   updateLocationTax(businessTaxId, data) {
     const url = `${Env.loginUrl}/${apiVersion}/business-taxes/${businessTaxId}/update`;
     return AuthApis.patchApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
   },


   /**
     * Delete tax for a location
     */
    deleteBusinessTax(businessTaxId) {
     const url = `${Env.loginUrl}/${apiVersion}/business-taxes/${businessTaxId}/remove`;
     return AuthApis.deleteApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
   },

   /**
    * Apply tax on all product
    */
   assignTaxToAllProduct(data) {
     const url = `${Env.productUrl}/${apiVersion}/product/tax/applyToAllItems`;
     return AuthApis.postApi(url, {
       'api_token': Env.productApiToken,
       'Authorization': 'Bearer '+ store.getters['auth/userToken'],
     }, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
   },

   /**
    * Apply tax on all category
    */
   assignCategoryToTax(taxId, data) {
    const url = `${Env.productUrl}/${apiVersion}/location/sync/tax/categories/${taxId}`;
    return AuthApis.postApi(url, {
      'api_token': Env.productApiToken,
      'Authorization': 'Bearer '+ store.getters['auth/userToken'],
    }, data)
     .then(res => res)
     .catch(err => {
       ApiHelper.detectStatusAndRedirect(err);
       return err;
     });
  },
  /**
    * Get category applied to the tax
    */
  getTaxCategory(taxId, locationId){
    const url = `${Env.productUrl}/${apiVersion}/location/list/tax/categories/${taxId}/${locationId}`;
    return AuthApis.getApi(url, {
      'api_token': Env.productApiToken,
      'Authorization': 'Bearer '+ store.getters['auth/userToken'],
    })
     .then(res => res)
     .catch(err => {
       ApiHelper.detectStatusAndRedirect(err);
       return err;
     });
  },

  /**
    * Get Taxes applied to the category
    */
  getCategoryTaxes(catId, locationId){
    const url = `${Env.productUrl}/${apiVersion}/location/list/category/taxes/${catId}/${locationId}`;
    return AuthApis.getApi(url, {
      'api_token': Env.productApiToken,
      'Authorization': 'Bearer '+ store.getters['auth/userToken'],
    })
     .then(res => res)
     .catch(err => {
       ApiHelper.detectStatusAndRedirect(err);
       return err;
     });
  },

  /**
    * Apply tax on all category
    */
  assignTaxToCategory(catId, data) {
    const url = `${Env.productUrl}/${apiVersion}/location/sync/category/taxes/${catId}`;
    return AuthApis.postApi(url, {
      'api_token': Env.productApiToken,
      'Authorization': 'Bearer '+ store.getters['auth/userToken'],
    }, data)
     .then(res => res)
     .catch(err => {
       ApiHelper.detectStatusAndRedirect(err);
       return err;
     });
  },

  getTaxForBusiness(businessId){
    const url = `${Env.productUrl}/${apiVersion}/location/tax/forBusiness/${businessId}`;
    return AuthApis.getApi(url, {
      'api_token': Env.productApiToken,
      'Authorization': 'Bearer '+ store.getters['auth/userToken'],
    })
     .then(res => res)
     .catch(err => {
       ApiHelper.detectStatusAndRedirect(err);
       return err;
     });
  }
    
}