import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom bg-[#23242A]" }
const _hoisted_2 = { class: "flex xl:flex-row flex-col px-4 pt-4 bg-[#23242A] text-white" }
const _hoisted_3 = {
  key: 0,
  class: "xl:w-[60%] w-full custom-right transition-all duration-100 ease-linear"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_edit_product = _resolveComponent("edit-product")!
  const _component_PurchaseOrderUploadPop = _resolveComponent("PurchaseOrderUploadPop")!
  const _component_ProductImportPop = _resolveComponent("ProductImportPop")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["transition-all duration-100 ease-linear custom-left mr-2", _ctx.edit?'xl:w-[40%] w-full':'w-full'])
      }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.listType.component), {
          payload: _ctx.listType.data,
          resized: _ctx.edit,
          onToggleAddEdit: _ctx.toggleAddEdit,
          onUpdatedEditItem: _ctx.updatedEditItem,
          onTogglePurchaseOrderUploadPop: _ctx.togglePurchaseOrderUploadPop,
          onToggleProductImportPop: _ctx.toggleProductImportPop
        }, null, 40, ["payload", "resized", "onToggleAddEdit", "onUpdatedEditItem", "onTogglePurchaseOrderUploadPop", "onToggleProductImportPop"]))
      ], 2),
      (_ctx.edit)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.edit)
              ? (_openBlock(), _createBlock(_component_edit_product, {
                  key: 0,
                  asn: _ctx.asn,
                  editItem: _ctx.editItem,
                  onClose: _ctx.closeEdit
                }, null, 8, ["asn", "editItem", "onClose"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.purchaseOrderUploadPop)
      ? (_openBlock(), _createBlock(_component_PurchaseOrderUploadPop, {
          key: 0,
          onClose: _ctx.closePurchaseOrderUpload,
          onUploadedPayload: _ctx.uploadedPayload
        }, null, 8, ["onClose", "onUploadedPayload"]))
      : _createCommentVNode("", true),
    (_ctx.importProductPop)
      ? (_openBlock(), _createBlock(_component_ProductImportPop, {
          key: 1,
          onClose: _ctx.closeImportProductPop,
          onUploadedProduct: _ctx.uploadedProduct
        }, null, 8, ["onClose", "onUploadedProduct"]))
      : _createCommentVNode("", true)
  ]))
}