<template>
  <div class="mb-24">
      <div class="text-white flex-1 col-span-1 border-0 rounded-t-xl">
        <div class="bg-theme-green px-4 py-2 text-white rounded-t-xl">
          <div class="flex justify-between items-center">
            <div class="font-medium leading-tight truncate text-single-line break-all text-2xl mt-0">{{ form.name.value ? form.name.value : 'New Tax'}}</div>
            <div class="flex justify-between items-center">
              <button @click="addNew()" class="h-10 hover:bg-theme-darkgreen border-0 hover:text-white rounded-md cursor-pointer text-green-400 bg-white py-2 px-4 uppercase">Save</button>
              <span @click.prevent="clearComponent" class="cursor-pointer rounded-md  text-white py-2 pl-2  hover:opacity-70">CLOSE</span>
              <!-- <mdicon name="close" size="32" class="flex justify-end cursor-pointer hover:opacity-60 text-4xl ml-3" @click="clearComponent" /> -->
            </div>
          </div>
        </div>

        <div class="pt-3 bg-[#2B2C32] px-[16px] py-[16px] rounded-lg">
          <div class="flex gap-3 xl:flex-row flex-col items-start mb-3">
            <div class="w-full xl:w-1/3">
               <div :class="'input-wrapper ' + (form.name.error_type)">
                  <label class="text-xs mb-1">Name:</label>
                  <input placeholder="Get Creative!" @keyup="validateFields(['name'])" v-model="form.name.value" class="block rounded bg-gray-black w-full" type="text" />
                  <p class="field-error label-text mt-1" v-if="form.name.error_message" v-text="form.name.error_message"></p>
              </div>
            </div>

            <div class="w-full xl:w-1/3">
                <div :class="'input-wrapper ' + (form.code.error_type)">
                  <label class="text-xs mb-1">Tax Code:</label>
                  <input placeholder="GST" @keyup="validateFields(['code'])" v-model="form.code.value" class="block rounded bg-gray-black w-full" type="text" />
                  <p class="field-error label-text mt-1" v-if="form.code.error_message" v-text="form.code.error_message"></p>
                </div>
            </div>

            <div class="w-full xl:w-1/3">
              <div class="">
                <label class="text-xs mb-1">Status:</label>
                 <label class="flex items-center cursor-pointer">
                  <input type="checkbox" v-model="form.status" class="hidden peer">
                  <div :class="'relative toggle-bg  border-2  h-6 w-11 rounded-full ' + (form.status ? 'bg-theme-green border-theme-green' : 'bg-[#F53333] border-[#F53333]')"></div>
                  <span class="ml-2 text-white text-sm font-medium uppercase" :class="form.status ? 'text-theme-green' : 'text-[#F53333]'">{{form.status ? 'On' : 'Off'}}</span>
                </label>
              </div>
            </div>
          </div>

          <div class="flex xl:flex-row flex-col gap-3 items-start">
              <div class="w-full xl:w-1/3">
                <div :class="'input-wrapper ' + (form.taxRate.error_type)">
                  <label class="text-xs mb-1">Rate:</label>
                  <div class="block rounded bg-gray-black w-full relative">
                    <input  @keyup="validateFields(['taxRate'])" placeholder="Rate" v-model="form.taxRate.value" class="bg-gray-black rounded  w-full pr-[100px]" type="number" />
                    <label class="flex items-center cursor-pointer absolute right-1 top-2">
                      <input type="checkbox" v-model="taxRateTypePercentage" class="hidden peer">
                      <div :class="'relative toggle-bg label-inside-switch border-2  h-6 w-11 rounded-full ' + (form.taxRateTypePercentage ? 'bg-theme-darkgreen border-theme-green' : 'bg-theme-darkgreen  border-theme-green')">
                        <span :class="'text-white ' + (taxRateTypePercentage ? 'left' : 'right')">{{taxRateTypePercentage ? '%' : '$'}}</span>
                      </div>
                    </label>
                  </div>
                   <p class="field-error label-text mt-1" v-if="form.taxRate.error_message" v-text="form.taxRate.error_message"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>


<script>
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
import BusinessTaxApis from "@/controllers/BusinessTaxApis";
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';
import CategorySelectCheckbox from '@/components/form/CategorySelectCheckbox.vue'
import BusinessProduct from '@/controllers/BusinessProductApis'

export default defineComponent({
  name: "AddLocationTax",
  components: {
    CategorySelectCheckbox
  },
  setup() {
    const snackbar = useSnackbar();
    const taxRateTypePercentage = ref(true);
    const categories = ref([])
    const form = ref({
      name: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'min:3'],
      },
      code: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'min:3'],
      },
      category: {
        value: [],
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      taxRate: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'flat', 'maxval:100|when|taxRateType:percentage'],
      },
      taxType: 'primary',
      taxRateType: 'percentage',
      status: false,
    });
    return {
      form,
      snackbar,
      taxRateTypePercentage,
      categories
    };
  },
  props:{
    clearComponent: {
      type: Function,
      required: true,
    },
  },

  watch:{
     // Fields cannot have white spaces
    'form.code.value'(val){
      this.form.code.value = val.replaceAll(/\s/g,'').trim();
    },
    'taxRateTypePercentage'(value) {
      this.form.taxRateType = value ? 'percentage' : 'flat';
      this.validateFields(['taxRate'])
    },
  },

  mounted(){
    // this.getCategory()
  },

  computed: {
    ...mapGetters({
     vg_locationSelected: "location/selected",
    }),
  },
 
  methods: {
    async getCategory(){
        let res = await BusinessProduct.fetchAllCategory()
        if(res.data?.payload?.category){
            for(let i=0; i<res.data.payload.category.length; i++){
                this.categories.push(res.data.payload.category[i])
                if(res.data.payload.category[i].subCategories && res.data.payload.category[i].subCategories.length>0){
                    for(let j=0; j<res.data.payload.category[i].subCategories.length; j++){
                        this.categories.push(res.data.payload.category[i].subCategories[j])
                    }
                }
            }
        }
    },
     /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },


    /**
     * Add new location Tax
     */
    async addNew() {
      let validate = await this.validateFields(['name', 'code', 'taxType', 'taxRate']);
      if(!validate) {
        return ;
      }
      this.va_spinner(true);
      let res = await BusinessTaxApis.addLocationTax({
          name: this.form.name.value,
          code: this.form.code.value,
          taxType: this.form.taxType,
          taxRate: parseFloat(this.form.taxRate.value),
          taxRateType: this.taxRateTypePercentage ? 'percentage' : 'flat',
          status: this.form.status,
      });
      let response = res.status == 201 ? res.data.message : Helpers.catchErrorMessage(res.response.data)
      // if(res.data.payload && res.data.payload.id){
      //   let taxRes = await BusinessTaxApis.assignCategoryToTax(res.data.payload.id, {locationId:this.vg_locationSelected.id, categoryIds: this.form.category.value})
      //   response = response+', '+(taxRes.status == 201 ? taxRes.data.message : Helpers.catchErrorMessage(taxRes.response.data))
      // }
      this.va_spinner(false);
      this.snackbar.add({
        type: 'info',
        text: response,
        duration: 3000,
      })
      if(res.status <= 201 ) {
        this.clearComponent();
        this.emitter.emit("refresh-business-tax-list", true);
      }
    },

    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  },
});

</script>