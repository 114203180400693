<template>
    <table class="table-auto" border="1">
      <thead class="h-[42px]">
          <tr class="">
              <th class="text-start font-bold text-sm leading-[17.5px] tracking-[0.5px]">Name</th>
              <th class="text-start font-bold text-sm leading-[17.5px] tracking-[0.5px]">Category</th>
              <th class="text-start font-bold text-sm leading-[17.5px] tracking-[0.5px]">Sku</th>
              <th class="text-start font-bold text-sm leading-[17.5px] tracking-[0.5px]">Variant</th>
              <th class="text-start font-bold text-sm leading-[17.5px] tracking-[0.5px]">Lot #</th>
              <th class="text-start font-bold text-sm leading-[17.5px] tracking-[0.5px]">Transfer Qty</th>
              <!-- <th class="text-start font-bold text-sm leading-[17.5px] tracking-[0.5px]">{{status==='shipped transfer'?'Transfer Qty':'Expected Qty'}}</th>
              <th v-if="status!=='shipped transfer'" class="text-start font-bold text-sm leading-[17.5px] tracking-[0.5px]">Actual Qty</th> -->
              <!-- <th v-if="status!=='shipped transfer'" class="text-start font-bold text-sm leading-[17.5px] tracking-[0.5px]">Difference</th> -->
          </tr>
      </thead>
      <tbody>
        <template v-for="(product) in products" :key="product.id">
          <tr class='h-[52px] border-y border-[#464851]' >
              <td class="font-normal text-sm leading-[17.5px]">
                <div class="flex items-center ">{{product?.productDetails?.name}}</div>
              </td>
              <td>
                  <div class="flex items-center" v-if="product?.categoryName">
                      <span v-if="product?.productDetails?.category?.posColour" class="inline-block w-4 h-4 mr-2 rounded-sm" :style="`background-color: ${product.productDetails.category.posColour};`"></span>
                      <span v-else class="inline-block w-4 h-4 mr-2 rounded-sm" style="background-color: rgb(0, 0, 0);"></span>
                      <span class="text-sm font-normal  leading-[17.5px] capitalize">{{product?.categoryName}}</span>
                  </div>
              </td>
              <td class="font-normal text-sm leading-[17.5px]">{{product?.productDetails?.sku}}</td>
              <td></td>
              <td></td>
              <!-- <td></td> -->
          </tr>
          <!-- main variant -->
          <template v-for="(batch, index) in product.transferBatches" :key="'m-'+index">
            <ProductListEach v-if="batch.transferQty" :status="status" :showVariantName="index===0" :product="product" :batch="batch" :item="selected" />
          </template>
          <ProductListEach :status="status" v-if="product.transferBatches.length===0 && product.transferQty" :showVariantName="true" :product="product" :item="selected" />
          
          <!-- sub variant -->
          <template v-for="(variant, index) in product.transferVariants" :key="index">
            <template v-for="(batch, i) in variant.transferBatches" :key="'b-'+index+i">
              <ProductListEach v-if="batch.transferQty" :status="status" :showVariantName="i===0" :product="variant" :batch="batch" :item="selected" />
            </template>
            <ProductListEach :status="status" v-if="variant.transferBatches.length===0 && variant.transferQty" :showVariantName="true" :product="variant" :item="selected" />
          </template>
        </template>
        <tr v-if="products.length==0">
          <td colspan="9" class="text-sm">No Product found.</td>
        </tr>
      </tbody>
  </table>

</template>

<script>
import  { defineComponent , ref} from "vue";
import { mapGetters, mapActions } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import ProductListEach from './ProductListEach.vue'

export default defineComponent({
  name: 'Transfer List',
  components: {
    ProductListEach
  },
  props: ['products', 'selected', 'status'],
  setup() {
    const snackbar = useSnackbar();

    return  {
      snackbar
    };
  },

  async mounted() {
    
  },

  computed: {
    ...mapGetters({
      vg_selectedLocation: 'location/selected'
    }),

    /**
     * Compute columns
     */
    columns() {
      if(this.iscomponentactive){
        let removeFields= ['min_points', 'is_active'];
        return this.columnsMain.filter(item => {
            if( removeFields.indexOf(item.field) == -1 ) {
              return item;
            }
        })
      }
      return this.columnsMain;
    },
  },

  methods: {
    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })

  }
});
</script>

