<template>
    <div class="custom-modal-wrap overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal md:h-full justify-center items-center flex">
        <div class="relative p-4 w-full h-full md:h-auto max-w-3xl">
            <div class="relative rounded-lg shadow bg-theme-modalbg h-max max-h-screen overflow-y-auto">
                <div class="p-4 text-[14px] rounded-lg">
                    <div class="relative">
                        <h2 class="text-2xl text-white text-center pr-[40px]">Archive/Delete</h2>
                        <span class="absolute right-1 top-1 pointer cursor-pointer hover:opacity-60"  @click.prevent="close()">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.3333 2.54602L17.4533 0.666016L10 8.11935L2.54667 0.666016L0.666672 2.54602L8.12001 9.99935L0.666672 17.4527L2.54667 19.3327L10 11.8793L17.4533 19.3327L19.3333 17.4527L11.88 9.99935L19.3333 2.54602Z" fill="white"/>
                        </svg>
                        </span>
                    </div>
                    <div class="m-2 mt-4 tracking-wider grid-cols-4 bg-theme-modal_inner_bg rounded py-2 px-2 pb-4">
                        <div class="flex flex-row justify-evenly">
                            <button @click="remove" :class="actionType=='delete'?'bg-theme-green/60':'hover:bg-theme-green/30'" class="border border-theme-green w-full m-2 rounded p-4 text-center">
                                <div class="flex flex-col items-center">
                                    <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.6987 24C3.16536 24 2.6987 23.8 2.2987 23.4C1.8987 23 1.6987 22.5333 1.6987 22V3H0.332031V1H6.5987V0H15.3987V1H21.6654V3H20.2987V22C20.2987 22.5333 20.0987 23 19.6987 23.4C19.2987 23.8 18.832 24 18.2987 24H3.6987ZM18.2987 3H3.6987V22H18.2987V3ZM7.23203 19.1333H9.23203V5.83333H7.23203V19.1333ZM12.7654 19.1333H14.7654V5.83333H12.7654V19.1333ZM3.6987 3V22V3Z" fill="white"/>
                                    </svg>
                                    <span class="mt-2">DELETE</span>
                                </div>
                            </button>
                            <button @click="retire" :class="actionType=='retire'?'bg-theme-green/60':'hover:bg-theme-green/30'" class="border border-theme-green w-full m-2 rounded p-4">
                                <div class="flex flex-col items-center">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 24L0 17V7L7 0H17L24 7V17L17 24H7ZM7.9 17.5L12 13.4L16.1 17.5L17.5 16.1L13.4 12L17.5 7.9L16.1 6.5L12 10.6L7.9 6.5L6.5 7.9L10.6 12L6.5 16.1L7.9 17.5ZM7.83333 22H16.1667L22 16.1667V7.83333L16.1667 2H7.83333L2 7.83333V16.1667L7.83333 22Z" fill="white"/>
                                    </svg>
                                    <span class="mt-2 uppercase">Archive</span>
                                </div>
                            </button>
                        </div>
                        <div class="rounded border border-theme-orange bg-theme-orange/10 p-2 mx-2">
                            <div class="flex flex-row">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.325 11.175H10.825V4.85H9.325V11.175ZM10 15C10.2333 15 10.4292 14.9208 10.5875 14.7625C10.7458 14.6042 10.825 14.4083 10.825 14.175C10.825 13.9417 10.7458 13.7458 10.5875 13.5875C10.4292 13.4292 10.2333 13.35 10 13.35C9.76667 13.35 9.57083 13.4292 9.4125 13.5875C9.25417 13.7458 9.175 13.9417 9.175 14.175C9.175 14.4083 9.25417 14.6042 9.4125 14.7625C9.57083 14.9208 9.76667 15 10 15ZM10 20C8.58333 20 7.26667 19.7458 6.05 19.2375C4.83333 18.7292 3.775 18.025 2.875 17.125C1.975 16.225 1.27083 15.1667 0.7625 13.95C0.254167 12.7333 0 11.4167 0 10C0 8.6 0.254167 7.29167 0.7625 6.075C1.27083 4.85833 1.975 3.8 2.875 2.9C3.775 2 4.83333 1.29167 6.05 0.775C7.26667 0.258333 8.58333 0 10 0C11.4 0 12.7083 0.258333 13.925 0.775C15.1417 1.29167 16.2 2 17.1 2.9C18 3.8 18.7083 4.85833 19.225 6.075C19.7417 7.29167 20 8.6 20 10C20 11.4167 19.7417 12.7333 19.225 13.95C18.7083 15.1667 18 16.225 17.1 17.125C16.2 18.025 15.1417 18.7292 13.925 19.2375C12.7083 19.7458 11.4 20 10 20ZM10 18.5C12.3333 18.5 14.3333 17.6667 16 16C17.6667 14.3333 18.5 12.3333 18.5 10C18.5 7.66667 17.6667 5.66667 16 4C14.3333 2.33333 12.3333 1.5 10 1.5C7.66667 1.5 5.66667 2.33333 4 4C2.33333 5.66667 1.5 7.66667 1.5 10C1.5 12.3333 2.33333 14.3333 4 16C5.66667 17.6667 7.66667 18.5 10 18.5Z" fill="#FCB852"/>
                                </svg>
                                <span class="ml-2">Warning: This action is final and cannot be undone.</span>
                            </div>
                            <ul class="list-disc ml-5">
                                <li class="my-1">Before deleting a product, consider archiving it instead. Archiving a product will remove it from the Product List but retain its information in the system.</li>
                                <li class="my-1">If you choose to delete a product, please note that this action will result in a negative change in inventory reporting. To add the product back, you will have to input all the information manually or by purchase order.</li>
                            </ul>
                        </div>
                        <div class="text-center">
                            <button @click="confirm" class="px-12 py-2 mt-4 rounded text-white bg-theme-green text-center hover:bg-theme-green/90">CONFIRM</button>
                        </div>
                    </div>
                    <!-- <div class="mt-3 mb-1 text-center">
                        <button @click.prevent="close()" class="text-white hover:text-theme-green text-center">CLOSE</button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import Helpers from '@/helpers';
import { useSnackbar } from "vue3-snackbar"
import {mapMutations} from 'vuex'
import ProductApi from '@/controllers/BusinessProductApis'

export default defineComponent({
    props: ['editItem'],
    setup() {
        const snackbar = useSnackbar();
        const actionType = ref('')

        return {actionType, snackbar}
    },
    methods: {
        close(){
            this.$emit('close')
        },
        remove(){
            this.actionType = 'delete'
        },
        retire(){
            this.actionType = 'retire'
        },
        confirm(){
            if(this.actionType=='delete'){
                this.confirmRemove()
            }else if(this.actionType=='retire'){
                this.confirmRetire()
            }
        },
        async confirmRemove(){
            this.vm_spinner(true)
            let res = await ProductApi.deleteProduct(this.editItem.id)
            this.$emit('close', true)
            this.snackbar.add({
                type: 'info',
                text: res.status == 200 ? "Product deleted successfully." : Helpers.catchErrorMessage(res.response.data),
                duration: 3000,
            })
            this.vm_spinner(false)
        },
        async confirmRetire(){
            this.vm_spinner(true)
            let editObj = {...this.editItem}
            editObj.retired = true
            let res = await ProductApi.editProduct(this.editItem.id, editObj)
            this.$emit('close', true)
            this.snackbar.add({
                type: 'info',
                text: res.status == 200 ? "Product archive successfully." : Helpers.catchErrorMessage(res.response.data),
                duration: 3000,
            })
            this.vm_spinner(false)
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }
})
</script>
