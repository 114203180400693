/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import Env from '@/env';
const apiVersion = 'api/v1';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /**
   * Fetch susers
   */
  getUsers({
    data,
    limit,
    page,
    sort='',
    order=''
  }) {
    let url = `${Env.loyaltyUrl}/${apiVersion}/users/search?limit=${limit}&page=${page}`;
    if(sort){
      url+=`&sort=${sort}&order=${order?order:'asc'}`
    }
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Create susers
   */
  createUser(data) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/users/`;
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * get by user ID
   */
  getUserById(user_id) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/users/${user_id}`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * update
   */
  update(user_id, data) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/users/${user_id}`;
    return AuthApis.patchApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * remove
   */
  remove(user_id) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/users/${user_id}`;
    return AuthApis.deleteApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


}