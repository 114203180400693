<template>
    <div class="w-full">
        <slot name="custom" :onClick="toggleModal" :onClear="clearData" />
        <!-- <div :class="classes" @click="toggleModal">{{label}}</div> -->
        <div v-if="modal.open" class="file-manager absolute w-full h-full left-0 top-0 bottom-0 right-0 z-50 bg-field-black drop-shadow-2xl">
            <div class="w-full h-16 items-center flex flex-row justify-between bg-theme-green">
                <div @click="closeModal" class="m-2 cursor-pointer"><svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 fill-white" viewBox="0 0 512 512"><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/></svg></div>
                <div class="flex flex-row self-center justify-end gap-2 ">
                    <a @click="selectFolder('')" class="text-white cursor-pointer">Home</a>
                    <div class="flex flex-row" v-for="folder in stackedFolder" :key="folder.id">
                        <div class="text-white">/</div>
                        <a @click="selectFolder(folder)" class="ml-1 text-white cursor-pointer">{{folder.name}}</a>
                    </div>
                </div>
                <div class="flex flex-row-reverse mx-2 gap-2 w-[380px]">
                    <div v-if="selected?.id" @click="confirmSelection" class="flex flex-row bg-white px-2 py-1 rounded cursor-pointer">
                        <svg class="w-6 h-6 fill-theme-green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
                        <span class="text-theme-green ml-2">Confirm</span>
                    </div>
                    <div @click="showAddFolderPop" class="flex flex-row bg-white px-2 py-1 rounded cursor-pointer">
                        <svg class="w-6 h-6 fill-theme-green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H181.5c17 0 33.3 6.7 45.3 18.7l26.5 26.5c12 12 28.3 18.7 45.3 18.7H448c35.3 0 64 28.7 64 64V416zM232 376c0 13.3 10.7 24 24 24s24-10.7 24-24V312h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H280V200c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H168c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z"/></svg>
                        <span class="text-theme-green ml-2">Add Folder</span>
                    </div>
                    <div @click="showAddFilePop" class="flex flex-row bg-white px-2 py-1 rounded cursor-pointer">
                        <svg class="w-6 h-6 fill-theme-green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M396.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z"/></svg>
                        <span class="text-theme-green ml-2">Add File</span>
                    </div>
                </div>
            </div>
            <div class="divide-y divide-theme-green">
                <div class="h-[130px] overflow-scroll flex">
                    <div v-if="folders.length>0" class="flex flex-row flex-wrap">
                        <div v-for="(folder, ind) in folders" :key="ind" @click="selectFolder(folder)" class="mx-4 my-2 py-2 px-4 cursor-pointer hover:bg-theme-green/20 hover:rounded-xl">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-12 h-12 fill-white" viewBox="0 0 512 512"><path d="M64 480H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64z"/></svg>
                            <span class="text-white">{{folder.name}}</span>
                        </div>
                    </div>
                    <div v-else class="mx-auto flex flex-row p-4 text-white items-center">
                        <span>No Folder found. Create one by clicking on the "Add Folder" from the top bar.</span>
                    </div>
                </div>
                <div class="flex h-[500px] overflow-scroll overflow-scroll">
                    <!-- file list -->
                    <div v-if="files.length>0" class="flex flex-row flex-wrap justify-evenly gap-2 m-2">
                        <div v-for="(file, ind) in files" :key="ind" @click="selectFile(file)" :class="selected.id==file.id?'border border-theme-green':'border border-white '" class="w-[200px] h-[180px] overflow-hidden relative cursor-pointer hover:border hover:border-theme-green">
                            <div>
                                <div @click="remove(file)" class="absolute right-2 top-2 rounded-full bg-white"><svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 fill-theme-green" viewBox="0 0 512 512"><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/></svg></div>
                                <div v-if="selected.id==file.id" class="absolute left-2 top-2 rounded-full bg-white"><svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 fill-theme-green" viewBox="0 0 512 512"><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg></div>
                                <img class="max-w-auto h-[150px] m-auto" :src="file.full_url" />
                            </div>
                            <div class="text-center text-white mx-1 break-all truncate">{{file.name}}</div>
                        </div>
                    </div>
                    <div v-else class="mx-auto h-16 flex flex-row p-4 text-white items-center">
                        <span>No Resources found. Create one by clicking on the "Add File" from the top bar.</span>
                    </div>

                    <!-- pagination list -->

                </div>
            </div>
        </div>
        <folder-pop v-if="modal.addFolderPop" :selectedFolder="modal.selectedFolder" @close="closeAddFolderPop" />
        <file-pop v-if="modal.addFilePop" :selectedFolder="modal.selectedFolder" @close="closeAddFilePop" />
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import FolderPop from './folderPop'
import FilePop from './filePop'
import Api from './api'
import {mapMutations} from 'vuex'
import { useSnackbar } from "vue3-snackbar"

export default defineComponent({
    props: ['name', 'modelValue', 'classes', 'label'],
    components: {
        FolderPop,
        FilePop
    },
    setup() {
        const modal = ref({
            open: false,
            addFolderPop: false,
            addFilePop: false,
            selectedFolder: ''
        })
        const folders = ref([])
        const files = ref([])
        const selected = ref({})
        const stackedFolder = ref([])
        const snackbar = useSnackbar();

        return {modal, folders, files, selected, stackedFolder, snackbar}
    },
    mounted(){
        this.getFileFolder()
    },
    watch:{
        'modal.open'(isOpen){
            if(isOpen){
                this.getFileFolder()
            }
        }
    },
    methods: {
        toggleModal(){
            this.modal.open = !this.modal.open
        },
        clearData(){
            this.$emit('update:modelValue', '')
        },
        closeModal(){
            this.modal.addFolderPop = false
            this.modal.addFilePop = false
            this.modal.open = false
        },
        getFileFolder(){
            let self = this
            this.vm_spinner(true)
            let promise1 = this.getFolder()
            let promise2 =this.getFile()
            Promise.all([promise1, promise2]).then(()=>{
                self.vm_spinner(false)
            })
        },
        async getFolder(){
            let res
            if(this.modal?.selectedFolder?.id){
                res = await Api.getSubFolder(this.modal.selectedFolder.id)
            }else{
                res = await Api.getRootFolder('page=1&limit=15')
            }
            if(res?.data?.payload?.folders){
                this.folders = res.data.payload.folders
            }else{
                this.folders = []
            }
        },
        async getFile(){
            let res 
            if(this.modal?.selectedFolder?.id){
                console.log("files in folder = "+this.modal.selectedFolder.id)
                res = await Api.getFolderFiles(this.modal.selectedFolder.id, 'page=1&limit=50')
            }else{
                res = await Api.getRootFiles('page=1&limit=50')
            }
            if(res?.data?.payload?.resources){
                this.files = res.data.payload.resources
            }else{
                this.files = []
            }
        },
        updateStackFolder(selectedFolder){
            if(selectedFolder?.id){
                // don't add if selected current folder
                if(this.stackedFolder.length>0 && this.stackedFolder[this.stackedFolder.length-1].id==selectedFolder.id){
                    return
                }

                // remove all stacked folder below selected folder
                let parentIndex = this.stackedFolder.findIndex(each => each?.parent_id==selectedFolder.id)
                if(parentIndex>=0){
                    this.stackedFolder.splice(parentIndex)
                }else{
                    this.stackedFolder.push(selectedFolder)
                }
            }else{
                this.stackedFolder = []
            }
        },
        remove(file){
            let self = this
            this.confirm('Are you sure to delete this file?').then(async () => {
                self.vm_spinner(true)
                await Api.removeFile(file.id)
                self.getFile()
                self.vm_spinner(false)
                self.snackbar.add({
                        type: 'info',
                        text: file.display_name+" file deleted",
                        duration: 3000,
                    })
            })
        },
        selectFolder(folder){
            this.selected = {}
            this.modal.selectedFolder = folder
            this.updateStackFolder(folder)
            this.getFileFolder()
        },
        selectFile(file){
            
            if(this.selected.id==file.id){
                this.selected = {}
            }else{
                this.selected = file
            }
        },
        confirmSelection(){
            
            if(this.selected?.full_url){
                this.$emit('update:modelValue', this.selected.full_url)
                this.closeModal()
            }
        },

        showAddFolderPop(){
            this.modal.addFilePop = false
            this.modal.addFolderPop = true
        },
        closeAddFolderPop(isFolderAdded){
            this.modal.addFolderPop = false
            if(isFolderAdded){
                this.getFolder()
            }
            
        },

        showAddFilePop(){
            this.modal.addFolderPop = false
            this.modal.addFilePop = true
        },
        closeAddFilePop(){
            this.modal.addFilePop = false
            this.getFile()
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }
})
</script>
