<template>
  <custom-modal :size="'max-w-2xl'">
    <template v-slot:maincontent>
      <div>
        <div class="bg-[#464851] text-white rounded-xl p-4"  v-if="loading">
          <block-loading/>
        </div>

        <div class="bg-[#464851] text-white rounded-xl p-4  overflow-y-auto" v-else>
          <div class="relative pb-4">
            <h1 class="text-3xl font-normal tracking-wide text-center">{{title?title:'Category Items'}}</h1>
            <span class="absolute right-0 top-2 cursor-pointer" @click="closeClick">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.3327 2.54602L17.4527 0.666016L9.99935 8.11935L2.54602 0.666016L0.666016 2.54602L8.11935 9.99935L0.666016 17.4527L2.54602 19.3327L9.99935 11.8793L17.4527 19.3327L19.3327 17.4527L11.8793 9.99935L19.3327 2.54602Z"
                      fill="white" />
              </svg>
            </span>
          </div>
          <div class="bg-light-black p-4 gap-6 rounded-xl">
            <div class="flex gap-2 flex-col items-start mb-3">

              <div class="w-full mb-3">
                <div class="input-wrapper">
                  <label class="text-xs mb-1">Search</label>
                  <div class="flex justify-between gap-2 xl:flex-row flex-col ">
                    <div class="relative w-full xl:w-4/4">
                      <input type="search"
                            v-model="searchFilter.search"
                            class="ring-2 ring-offset-gray-black ring-gray-black block rounded bg-gray-black w-full text-sm text-theme-white border-0 px-8 py-2"
                            placeholder="Search by Name, SKU">
                      <div class="absolute top-3 pl-2">
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"
                            class="h-4">
                          <path d="M17.4 18.9746L10.825 12.3996C10.325 12.8329 9.74167 13.1704 9.075 13.4121C8.40833 13.6538 7.7 13.7746 6.95 13.7746C5.15 13.7746 3.625 13.1496 2.375 11.8996C1.125 10.6496 0.5 9.14128 0.5 7.37461C0.5 5.60794 1.125 4.09961 2.375 2.84961C3.625 1.59961 5.14167 0.974609 6.925 0.974609C8.69167 0.974609 10.1958 1.59961 11.4375 2.84961C12.6792 4.09961 13.3 5.60794 13.3 7.37461C13.3 8.09128 13.1833 8.78294 12.95 9.44961C12.7167 10.1163 12.3667 10.7413 11.9 11.3246L18.5 17.8746L17.4 18.9746ZM6.925 12.2746C8.275 12.2746 9.425 11.7954 10.375 10.8371C11.325 9.87878 11.8 8.72461 11.8 7.37461C11.8 6.02461 11.325 4.87044 10.375 3.91211C9.425 2.95378 8.275 2.47461 6.925 2.47461C5.55833 2.47461 4.39583 2.95378 3.4375 3.91211C2.47917 4.87044 2 6.02461 2 7.37461C2 8.72461 2.47917 9.87878 3.4375 10.8371C4.39583 11.7954 5.55833 12.2746 6.925 12.2746Z"
                                fill="#878686" />
                        </svg>
                      </div>
                    </div>
                    <div class="w-full xl:w-2/4 flex">
                      <div class="relative w-full xl:w-4/4 block rounded bg-gray-black text-sm border-none">
                        <SelectBrand v-model="searchFilter.brand_id" :options="brandList" placeholder="Brand" class="relative block rounded bg-gray-black w-full border-none text-sm " />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class=" w-full">

                <div class="categories h-[300px] overflow-y-auto">
                  <!-- flowers -->

                  <p v-if="catWithProducts.length == 0" class="text-center text-sm">Sorry no data found!</p>
                  <!-- main category -->
                  <template v-bind:key="'main_cat' + categoryIndex" v-for="category, categoryIndex in catWithProducts">

                    <div class="main-category" v-if="category.show">
                      <div :class="'px-4 py-4 rounded-t ' + ( categoryIndex % 2 == 0 ? 'bg-gray-grey' : 'bg-gray-black')">
                        <div class="flex items-center justify-between h-10">
                          <div class="flex items-center cursor-pointer" @click="selectMainCategory(categoryIndex)">
                            <div :class="'h-4 w-4 border-[1px] pr-[15px]' + (category.selected ? ' border-theme-green bg-theme-green addcheckbox' : ' border-white')">
                              <mdicon v-if="category.selected" name="check" size="14" />
                              <mdicon v-if="category.selected == false && category.someSelected" name="minus" size="14" />
                            </div>
                            <label class="flex flex-col pl-2 w-[400px] cursor-pointer hover:opacity-80">
                                <span class="ml-2 pb-0 text-base font-normal truncate text-single-line tracking-wider text-white">{{ category.name }}</span> 
                                <span class="ml-2 text-sm font-normal tracking-wide text-theme-878686">Main Category</span>
                            </label>
                          </div>
                          <mdicon v-if="(category.subCategories && category.subCategories.length > 0) || category.products && category.products.length > 0" size="25" @click="toggleCat('parentcat',categoryIndex)" :name="category.toggle ? 'chevron-up' : 'chevron-down'" class="cursor-pointer text-lg hover:opacity-60" />
                        </div>
                      </div>
                    </div>
                    <!-- main category ends -->

                    <div class="main-category-products" v-if=" category.products && category.products.length > 0 && category.toggle">
                        <template v-for="mainCatProduct, mainCatproductIndex in category.products">
                          <div :class="'pl-14 px-4 py-4 ' + ( mainCatproductIndex % 2 == 0 ? 'bg-gray-grey p-separate' : 'bg-gray-black p-separate')" v-if="mainCatProduct.show" v-bind:key="'main_cat_p' + mainCatproductIndex">
                            <div class="flex items-center h-10">
                              <div class="flex items-center cursor-pointer" @click="selectMainCategoryProduct(categoryIndex, mainCatproductIndex)">
                                <div :class="'h-4 w-4 border-[1px]' + (mainCatProduct.selected ? ' border-theme-green bg-theme-green addcheckbox' : ' border-white')">
                                  <mdicon v-if="mainCatProduct.selected" name="check" size="14" />
                                </div>
                                <label class="flex flex-col pl-3 cursor-pointer hover:opacity-80">
                                  <span v-if="showProductOnly" class="ml-2 pb-0 text-base font-normal tracking-wider text-white">{{ mainCatProduct.name }}</span> 
                                  <span v-else class="ml-2 pb-0 text-base font-normal tracking-wider text-white">{{ mainCatProduct.name }} - {{ mainCatProduct.variantName }}</span> 
                                  <span class="ml-2 text-sm font-normal tracking-wide text-theme-878686">{{ mainCatProduct.brand ? mainCatProduct.brand.name :''}}</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>

                    <!-- sub category  -->
           
                    <template v-if="category.subCategories && category.subCategories.length > 0 && category.toggle">
                      <template v-for="subcat, subcatIndex in category.subCategories">
                        <div v-if="subcat.show" class="sub-category" v-bind:key="'subcat_index' + subcatIndex">
                          <div :class="'pl-14 px-4 py-4 ' + ( subcatIndex % 2 == 0 ? 'bg-gray-grey p-separate' : 'bg-gray-black p-separate')">
                            <div class="flex items-center justify-between h-10">
                              <div class="flex items-center cursor-pointer" @click="selectSubCategory(categoryIndex, subcatIndex)">
                                <div :class="'h-4 w-4 border-[1px]' + (subcat.selected ? ' border-theme-green bg-theme-green addcheckbox' : ' border-white')">
                                  <mdicon v-if="subcat.selected" name="check" size="14" />
                                  <mdicon v-if="subcat.selected == false && subcat.someSelected" name="minus" size="14" />
                                </div>
                                <label  class="flex flex-col pl-3 cursor-pointer hover:opacity-80">
                                  <span class="ml-2 pb-0 text-base font-normal tracking-wider text-white">{{subcat.name}}</span> 
                                  <span class="ml-2 text-sm font-normal tracking-wide text-theme-878686">Sub Category</span>
                                </label>
                              </div>
                              <mdicon @click="toggleCat('subcat',categoryIndex, subcatIndex)" v-if="subcat.products && subcat.products.length > 0" size="25" :name="subcat.toggle ? 'chevron-up' : 'chevron-down'" class="cursor-pointer text-lg hover:opacity-60" />
                            </div>
                          </div>
                        

                          <!-- if sub cat has product -->
              
                          <div class="sub-category-products" v-if="subcat.products && subcat.products.length > 0 && subcat.toggle">
                            <template v-for="subcatProduct, subcatProductIndex in subcat.products">
                              <div v-if="subcatProduct.show" v-bind:key="'main_cat_p' + subcatProductIndex">
                                
                                <div class="pl-24 px-4 py-4" :class="( subcatProductIndex % 2 == 0 ? 'bg-gray-grey p-separate' : 'bg-gray-black p-separate')" >
                                  <div class="flex items-center h-10">
                                    <div class="" >
                                      
                                      <div :class="'flex items-center cursor-pointer'" @click="selectSubCategoryProduct(categoryIndex, subcatIndex, subcatProductIndex)">
                                          <div :class="'h-4 w-4 border-[1px]' + (subcatProduct.selected ? ' border-theme-green bg-theme-green addcheckbox' : ' border-white')">
                                            <mdicon v-if="subcatProduct.selected" name="check" size="14" />
                                          </div>
                                          <label  class="flex flex-col pl-3 cursor-pointer hover:opacity-80">
                                            <span v-if="showProductOnly" class="ml-2 pb-0 text-base font-normal tracking-wider text-white">{{ subcatProduct.name }}</span> 
                                            <span v-else class="ml-2 pb-0 text-base font-normal tracking-wider text-white">{{ subcatProduct.name }} - {{ subcatProduct.variantName }}</span> 
                                            <span class="ml-2 text-sm font-normal tracking-wide text-theme-878686">{{ subcatProduct.brand ? subcatProduct.brand.name : '' }}</span>
                                          </label>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </template>
                          </div>

                          <!-- if sub cat product has variant -->
                        
                        <!-- sub cat product ends -->
                        </div> 
                      </template>
                     
                    </template>
            
                    <!-- sub category ends -->

                    <!-- if main cat has product no no subcat -->
              
                  </template>
     
                </div>
              </div>
            </div>
            <div class="bg-theme-grey w-1/2 relative left-0 right-0 my-0 mx-auto mt-8 rounded">
              <div class="p-2">
                <span class="text-theme-878686 text-sm font-normal tracking-wider">Applied To</span>
                <h5 class="text-base font-semibold tracking-wider text-right pt-4">{{ checkedRecords.totalCategories.length }} Categories, {{ checkedRecords.products.length }} Products</h5>
              </div>
            </div>
            <div class="flex justify-center mt-6">
              <button @click="actionClick({ pids: checkedRecords.productIds })" class="bg-theme-green text-theme-white hover:bg-theme-darkgreen py-4 rounded-[8px] text-sm font-medium tracking-widest w-1/2">CONFIRM</button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </custom-modal>
</template>
<script>
  import {
    defineComponent,
    ref
  } from "vue"
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from "vuex";
  import {
    useSnackbar
  } from "vue3-snackbar";
  import Helpers from '@/helpers';
  import debounce from 'lodash/debounce';
  import filter from 'lodash/filter';
  import BusinessCategoryApis from "@/controllers/BusinessCategoryApis";
  import BusinessBrandApis from "@/controllers/BusinessBrandApis";
  import SelectBrand from '@/components/form/SelectBrand.vue'

  export default defineComponent({
    name: "ProductCategorySelect",
    components: {
      SelectBrand
    },
    setup() {
      const loading = ref(false);
      const catWithProducts = ref([]);
      const allProductIds = [];
      const brandList = [];
      const searchFilter =  ref({
        search: '',
        brand_id: [],
      });
      return {
        loading,
        catWithProducts,
        allProductIds,
        brandList,
        searchFilter
      };
    },
    props: {
      title: {
        type: String,
        required: false,
      },
      showProductOnly: {
        type: Boolean,
        required: false,
      },
      closeClick: {
        type: Function,
        required: true,
      },
      actionClick: {
        type: Function,
        required: true,
      },
      existingproducts:{
        type: Array,
        required: true,
      }
    },

    watch: {

      searchFilter:{
        handler: debounce(function(){
          this.doSearch();
        }, 1000),
        deep: true,
      },

    },

    computed: {
      /**
       * return unchecked product ids
       */
      uncheckedProductsIds() {
        let checkedPids = this.checkedRecords.productIds;
        return this.allProductIds.filter(pid => {
          return !checkedPids.includes(pid);
        });
      },

      ...mapGetters({
        vg_activeBusiness: 'auth/activeBusiness',
      }),
      
      checkedRecords() {
        let mainCats = [];
        let subCats = [];
        let products = [];
        let productIds = [];
        this.catWithProducts.forEach(category => {

          // For main category
          if(category.selected){
            mainCats.push({
              name: category.name,
              id: category.id,
            })
          }

          if(category.products && category.products.length > 0){
            category.products.forEach(categoryProduct => {
              if(categoryProduct.selected){
                products.push({
                  name: categoryProduct.name,
                  id: categoryProduct.id,
                })
                productIds.push(categoryProduct.id)
              }
            })
          }

          // For sub cats, ignore main product f sub cat available
          if(category.subCategories && category.subCategories.length > 0){
            category.subCategories.forEach(subcat => {
              if(subcat.selected){
                subCats.push({
                  name: subcat.name,
                  id: subcat.id,
                })
              }
              // SUb cat products
              if(subcat.products && subcat.products.length > 0){
                subcat.products.forEach(subcatProduct => {
                  if(subcatProduct.selected){
                    products.push({
                      name: subcatProduct.name,
                      id: subcatProduct.id,
                    })
                    productIds.push(subcatProduct.id)
                  }
                })
              }
            })
          } 
        })
        return {
          mainCats,
          subCats,
          products,
          productIds,
          totalCategories: [...mainCats, ...subCats]
        }
      },
    },
    
    async mounted() {
      
      
      const self = this;
      this.loading = true;
      let catWithProduct = BusinessCategoryApis.fetchCategoryProductTreeWithFilterBrandSearch(this.vg_activeBusiness.id);
      let brandRes = BusinessBrandApis.fetchAllBrandsNoPagination();

      Promise.all([catWithProduct, brandRes]).then(async (res) => {

        this.loading = false;
        // Categories
        if(res[0].status == 200) {
          self.catWithProducts = await self.getCategoryWithProduct(res[0]);
        }
        // Brands
        this.brandList = (res[1].status == 200) ? res[1].data.payload.brands : [];
        
      });
    },


    methods: {


      async doSearch() {
        this.va_spinner(true)
        for(let i=0; i<this.catWithProducts.length; i++){
          // main with products
          for(let j=0; j<this.catWithProducts[i].products?.length; j++){
              let match1 = false
              let match2 = false
              if(this.searchFilter.search){
                let productNameMatch = this.catWithProducts[i].products[j].name?.toLowerCase().includes(this.searchFilter.search.toLowerCase())
                let variantNameMatch = this.catWithProducts[i].products[j].variantName?.toLowerCase().includes(this.searchFilter.search.toLowerCase())
                let skuMatch = this.catWithProducts[i].products[j].sku?.toLowerCase().includes(this.searchFilter.search.toLowerCase())
                let barcodeMatch = this.catWithProducts[i].products[j].barcode?.toLowerCase().includes(this.searchFilter.search.toLowerCase())
                match1 = (productNameMatch || variantNameMatch || skuMatch || barcodeMatch)?true:false
              }else{
                match1 = true
              }
              if(this.searchFilter.brand_id.length>0){
                match2 = this.searchFilter.brand_id.includes(this.catWithProducts[i].products[j].brandId)
              }else{
                match2 = true
              }
              this.catWithProducts[i].products[j].show = match1 && match2
          }

          // main with subcategory
          for(let k=0; k<this.catWithProducts[i].subCategories?.length; k++){
            for(let l=0; l<this.catWithProducts[i].subCategories[k]?.products.length; l++){
              let match1 = false
              let match2 = false
              if(this.searchFilter.search){
                let productNameMatch = this.catWithProducts[i].subCategories[k].products[l].name?.toLowerCase().includes(this.searchFilter.search.toLowerCase())
                let variantNameMatch = this.catWithProducts[i].subCategories[k].products[l].variantName?.toLowerCase().includes(this.searchFilter.search.toLowerCase())
                let skuMatch = this.catWithProducts[i].subCategories[k].products[l].sku?.toLowerCase().includes(this.searchFilter.search.toLowerCase())
                let barcodeMatch = this.catWithProducts[i].subCategories[k].products[l].barcode?.toLowerCase().includes(this.searchFilter.search.toLowerCase())
                match1 = (productNameMatch || variantNameMatch || skuMatch || barcodeMatch)?true:false
              }else{
                match1 = true
              }
              if(this.searchFilter.brand_id.length>0){
                match2 = this.searchFilter.brand_id.includes(this.catWithProducts[i].subCategories[k].products[l].brandId)
              }else{
                match2 = true
              }
              this.catWithProducts[i].subCategories[k].products[l].show = match1 && match2
            }

            this.catWithProducts[i].subCategories[k].show = this.catWithProducts[i].subCategories[k].products.some(each => each.show)
          }

          this.catWithProducts[i].show = (this.catWithProducts[i].products.length>0 || this.catWithProducts[i].subCategories.length>0) && (this.catWithProducts[i].products.some(each => each.show) || this.catWithProducts[i].subCategories.some(each => each.show))
        }
        this.va_spinner(false)
      },


      /**
       * Get category with product formatted
       */
      async getCategoryWithProduct(res) {
        let categoryWithProducts = []
        if(this.showProductOnly){
          categoryWithProducts =  await Helpers.cleanProductOnCategoryTree(res.data.payload);
        }else{
          categoryWithProducts =  await Helpers.makeVariantsProductOnCategoryTree(res.data.payload);
        }
        
          // push toggle key on all categories upto child
          categoryWithProducts.map(async(item) => {

            if(item.products && item.products.length>0){
              // if no sub cat then check for product
              item.products.map(mainProduct => {
                this.allProductIds.push(mainProduct.id)
                mainProduct.selected = this.checkIfProductIdAvailable(mainProduct.id);
                mainProduct.show = true
              })
            }

            // for sub cat
            if(item.subCategories && item.subCategories.length > 0){
              item.subCategories.map(async(subitem) => {
                subitem.toggle = false;
                // if subcat check for subcat product
                subitem.products.map(subitemProduct => {
                  subitemProduct.show = true
                  subitemProduct.selected = this.checkIfProductIdAvailable(subitemProduct.id);
                  this.allProductIds.push(subitemProduct.id)
                })
                let subCatProductSelectedCount = filter(subitem.products, (d) => d.selected);
                subitem.selected = (subCatProductSelectedCount.length == subitem.products.length && subitem.products.length > 0) ? true : false;

                let atLeastOneChecked = await Helpers.atLeastAproductCheckedOnNormalCategory(subitem);
                subitem.someSelected = atLeastOneChecked ? true : false;
                subitem.show = true
              })
              
            }

            let allChecked = await Helpers.isAllProductAndNormalCatsChecked(item);
            let atLeastOneChecked = await Helpers.atLeastAproductCheckedOnNormalCategory(item);
            item.toggle = false;
            item.selected = allChecked ? true : false;
            item.someSelected = atLeastOneChecked ? true : false;
            item.show = true
            
          })
          return categoryWithProducts;
      },

      /**
       * Select main category
       */
      async selectMainCategory(mainCatIndex) {
        this.catWithProducts = await Helpers.toggleMainCategorySelect(this.catWithProducts, mainCatIndex);
      },
      
      /**
       * Main cat product
      */
      async selectMainCategoryProduct(mainCatIndex, mainCatproductIndex){
        this.catWithProducts = await Helpers.toggleMainCategoryProductSelect(this.catWithProducts, mainCatIndex, mainCatproductIndex);
      },

      /**
       * Select sub cat index
       */
       async selectSubCategory(mainCatIndex, subcatIndex) {
        this.catWithProducts = await Helpers.toggleSubCategorySelect(this.catWithProducts, mainCatIndex, subcatIndex);
      },

      /**
       * Sub cat products
       */
       async selectSubCategoryProduct(mainCatIndex, subcatIndex, subcatProductIndex) {
        
        this.catWithProducts = await Helpers.toggleSubCategoryProductSelect(this.catWithProducts, mainCatIndex, subcatIndex, subcatProductIndex);
      },

      /**
       * toggle cat and close rest
       */
      toggleCat(type, mainCatIndex, subCatIndex = null) {
        if(type == 'parentcat') {
          this.catWithProducts.map( (cat, catIndex) => {
            cat.toggle = (catIndex == mainCatIndex) ? !cat.toggle : false;
            // close sub cat if any of open
            if(catIndex != mainCatIndex) {
              this.catWithProducts[mainCatIndex].subCategories.map( (subcat) => {
                subcat.toggle = false;
              })
            }
          })
        } else {
          this.catWithProducts[mainCatIndex].subCategories.map( (subcat, subcatIndex) => {
            subcat.toggle = (subcatIndex == subCatIndex) ? !subcat.toggle : false;
          })
        }
      },

      /**
       * Check if product id available
       */
      checkIfProductIdAvailable(pid) {
        return this.existingproducts.indexOf(pid) > -1 ? true : false;
      },

      ...mapActions({
        va_spinner: 'spinner/setSpinner',
      })
    },
  });
</script>