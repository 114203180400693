
import { defineComponent, reactive, ref} from "vue";
import addProduct from '@/views/Products/Product/Add/Add.vue'
import editProduct from '@/views/Products/Product/Edit/Edit.vue'
import QrCodeModal from '@/views/Products/Product/QrCode/Index.vue'
import ProductList from '@/views/Products/Product/List/ProductList.vue'
import PurchaseOrderUploadPop from '@/views/Products/Product/List/OrderUploadPop.vue'
import OrderUploadList from '@/views/Products/Product/List/OrderUploadList.vue'
import PrintSelectedCategories from '@/views/Products/Product/PrintMenu/SelectCategories.vue'
import CategoryModal from '@/views/Products/Product/Category/index.vue'
import MergeProducts from '@/views/Products/Product/Merge/MergeProducts.vue'
import SelectProduct from '@/views/Products/Product/ProductCatalogue/SelectProduct.vue'


export default defineComponent({
    components: {
        addProduct,
        editProduct,
        PurchaseOrderUploadPop,
        QrCodeModal,
        ProductList,
        OrderUploadList,
        PrintSelectedCategories,
        CategoryModal,
        MergeProducts,
        SelectProduct
    },
    setup(){
        // list 
        const listType = ref({
            component: 'ProductList', 
            data: [],
            newAdded: false,
            newAddedData: {},
        })

        // poup
        const purchaseOrderUploadPop = ref(false)

        // add edit
        const add = ref<boolean>(false)
        const edit = ref(false)
        const printMenu = ref(false)
        const mergeProducts = ref(false)
        const exportProductCatalogue = ref(false)
        const exportProductSelected = ref([])
        const asn = <any>ref({})
        const fulfillment = <any>ref(null)
        const editItem = <any>ref({})
        const noLocationProduct = ref({})
        // merge from edit page dropdown
        const editPageMerge = ref(false)
        const category = ref({
            items: [],
            modal: false
        })

        // qrcode
        const qrcode = ref({
          modal: false,
        })

        const categoryData = ref([])
        const showTendyUpload = ref(false)

        return {listType, purchaseOrderUploadPop, add, edit, editPageMerge, printMenu, mergeProducts, exportProductCatalogue, exportProductSelected, asn, fulfillment, editItem, noLocationProduct, category, qrcode, categoryData, showTendyUpload}
    },
    mounted(){
        this.add = false
        this.emitter.on("closeAddEdit", this.closeAddEdit);
    },
    beforeDestroy(){
        this.emitter.off("closeAddEdit", this.closeAddEdit);
    },
    methods: {
        toggleAddEdit(status){
            this.editItem = status.editItem
            this.asn = status.asn
            this.fulfillment = status.fulfillment
            this.edit = status.edit
            this.add = status.add
            this.noLocationProduct = status.noLocationProduct
            this.editPageMerge = status.editMerge
            // showPrintMenu 
            this.printMenu = status.printMenu
            this.exportProductCatalogue = status.exportProductCatalogue
            if(status.selectedProducts){
                this.exportProductSelected = status.selectedProducts
            }
        },
        closeAdd(){
            this.add = false
        },
        closeEdit(){
            this.edit = false
        },
        closeAddEdit(){
            this.add = false
            this.edit = false
        },
        closePrintMenu(){
            this.printMenu = false
        },
        closeMergeProduct(){
            this.mergeProducts = false
        },
        addedRow(data){
            if(this.listType.component=='OrderUploadList'){
                this.add = false
                this.edit = false
                this.listType.newAdded = true
                this.listType.newAddedData = data
            }else{
                this.add = false
                this.editItem = data
                this.edit = false
                this.listType.newAdded = true
                this.emitter.emit('reloadProductList')
            }
        },
        updatedEditItem(item){
            this.editItem = item
        },
        openQrCode(items){
            this.qrcode.modal = true;
            this.categoryData = items
        },
        openMergeProducts(items){
            this.mergeProducts = true
            this.categoryData = items
        },
        qrCloseCallBack() {
          this.qrcode.modal = false;
          this.emitter.emit('reloadProductList')
        },
        openCategorySelect(items){
            this.category.modal = true
            this.categoryData = items
        },
        closeCategory(){
            this.category.modal = false
        },
        closeExportCatalogue(){
            this.exportProductCatalogue = false
        },
        // purchase order upload
        togglePurchaseOrderUploadPop(status, tendyUpload){
            this.showTendyUpload = tendyUpload
            this.purchaseOrderUploadPop = status
        },
        closePurchaseOrderUpload(){
            this.purchaseOrderUploadPop = false
        },
        uploadedPayload(payload){
            this.closePurchaseOrderUpload()
            this.listType = {
                component: 'OrderUploadList',
                data: payload,
                newAdded: false,
                newAddedData: {}
            }
        },
        closeBulkUpload(){
            this.listType = {
                component: 'ProductList',
                data: [],
                newAdded: false,
                newAddedData: {}
            }
            this.add = false
            this.edit = false
        }
    }
})
