import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full overflow-x-auto" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "inline-block truncate text-single-line break-all align-top" }
const _hoisted_4 = { class: "inline-block" }
const _hoisted_5 = { class: "h-10" }
const _hoisted_6 = {
  key: 0,
  class: "inline-block"
}
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_block_loading = _resolveComponent("block-loading")!
  const _component_CustomSortableTable = _resolveComponent("CustomSortableTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_block_loading, { key: 0 }))
      : (_openBlock(), _createBlock(_component_CustomSortableTable, {
          key: 1,
          table: _ctx.table,
          callback: _ctx.search,
          disabletoggle: true,
          draggable: true,
          sortingcallback: _ctx.sortCategory,
          activeItem: _ctx.activeItem
        }, {
          name: _withCtx((data) => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", {
                class: "w-[24px] h-[24px] rounded mr-3",
                style: _normalizeStyle('background:'+data.value.posColour)
              }, null, 4),
              _createElementVNode("span", _hoisted_3, _toDisplayString(data.value.name), 1)
            ])
          ]),
          parent: _withCtx((data) => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(data.value.parent ? 'Sub': 'Main'), 1)
            ])
          ]),
          action: _withCtx((data) => [
            (_ctx.can_edit_categories)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("button", {
                    onClick: _withModifiers(($event: any) => (_ctx.setActive(data.value)), ["prevent"]),
                    class: "border-[1px] border-black text-theme-green hover:text-white hover:bg-theme-green hover:border-theme-green rounded w-32 text-[14px] p-[4px]"
                  }, "VIEW & EDIT", 8, _hoisted_7)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["table", "callback", "sortingcallback", "activeItem"]))
  ]))
}