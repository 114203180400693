export default {
  namespaced: true,
  state: {
    left_nav: true,
  },
  getters: {
    leftNav: state => state.left_nav,
  },
  mutations: {
    leftNav(state, data) {
      state.left_nav = data
    }
  },
  actions: {
    setLeftNav(context, data) {
      context.commit('leftNav', data);
    }
  }
};
