
import store from '@/store';
import Env from '@/env';

/**
 * Check if token exist or not, if not redirect back to login page
 */
export default function auth({ next, router, to}) {
    let userToken = store.getters['auth/userToken'];
    let activeBusiness = store.getters['auth/activeBusiness'];
    if (!userToken) {

      return router.push({ name: 'Login' });
    }
    return next();
}
