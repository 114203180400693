<template>
  <div class="pb-2 ">
    <div class="bg-theme-green border-0 rounded-t-xl  px-4 py-2 text-white">
      <div class="flex justify-between items-center">
        <h2 class="font-semibold truncate text-single-line break-all leading-tight text-2xl mt-0">{{ form.tier_name.value ? form.tier_name.value : 'New Reward Tier' }}
        </h2>
        <div class="flex flex-col">
          <div class="flex justify-end items-center">
            <button v-if="can_edit_membership_details" @click.prevent="update()" class="cursor-pointer w-[90px] rounded-md bg-white text-theme-green py-2 px-4 hover:bg-theme-darkgreen hover:text-white mr-3">
              SAVE 
            </button>
            <button  v-if="can_edit_membership_details" @click.prevent="deleteRecordConfirm" class="cursor-pointer uppercase w-[90px] rounded-md bg-white text-theme-red py-2 px-4 hover:bg-theme-darkgreen hover:text-white mr-3">
              Delete 
            </button>
            <span @click.prevent="clearComponent" class="cursor-pointer rounded-md  text-white py-2 pl-1  hover:opacity-70">CLOSE</span>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-3 bg-[#2B2C32] border border-black border-opacity-20 shadow-lg px-[16px] py-[16px] rounded-b-lg"> 
      <div class="flex gap-2 flex-col items-start mb-4">
        <div class="w-full ">
          <div :class="'input-wrapper ' + (form.tier_name.error_type)">
            <label class="text-xs mb-1">Name</label>
            <input  v-model="form.tier_name.value" @keyup="validateFields(['tier_name'])"  placeholder="Be Creative!" class="placeholder-theme-878686 h-[40px] block border-none rounded bg-gray-black w-full text-sm text-theme-white" type="text">
            <p class="field-error label-text mt-1" v-if="form.tier_name.error_message" v-text="form.tier_name.error_message"></p>
          </div>
        </div>

       <div class="w-full ">
          <div :class="'input-wrapper ' + (form.min_points.error_type)">
            <label class="text-xs mb-1">Points Needed</label>
            <input v-model="form.min_points.value" @keyup="validateFields(['min_points'])"  placeholder="ei. 100" class="placeholder-theme-878686 h-[40px] block border-none rounded bg-gray-black w-full text-sm text-theme-white" type="number">
            <p class="field-error label-text mt-1" v-if="form.min_points.error_message" v-text="form.min_points.error_message"></p>
          </div>
        </div>
      </div>

      <h5 class="font-bold text-xl mt-6 mb-4 leading-6 tracking-[0.15px]">Rewards</h5>
      <div v-for="(reward, ind) in form.rewards" :key="ind">
        <redeem :reward="reward" :allSelectedSku="allSelectedSku" :index="ind" :total="form.rewards.length" @addBlankReward="addBlankReward" @removeReward="removeReward" @updateReward="updateReward" />
      </div>

      <div class="w-full ">
        <div :class="'input-wrapper ' ">
          <label class="text-xs mb-1 mt-0">Is Active</label>
          <label class="flex items-center cursor-pointer xl:mt-2" @click.prevent="form.is_active.value = !form.is_active.value">
            <input type="checkbox" v-model="form.is_active.value" class="hidden peer">
            <div :class="'relative toggle-bg  border-2  h-6 w-11 rounded-full ' + ((form.is_active.value) ? 'bg-theme-green border-theme-green' : 'bg-[#F53333] border-[#F53333]')"></div>
            <span class="ml-2 text-white text-sm font-medium uppercase" :class="(form.is_active.value)  ? 'text-theme-green' : 'text-[#F53333]'">{{form.is_active.value ? 'On' : 'Off'}}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <ReturnConfirmModal v-if="deleteObj.modal" :obj="deleteObj.obj" :closeClick="closeDeleteModal" :actionClick="deleteNow" :heading="'You Are About To Delete ' + deleteObj.obj.tier_name "/>
</template>
<script>
  import { defineComponent, ref} from "vue"
  import {mapGetters,mapActions} from "vuex";
  import { useSnackbar} from "vue3-snackbar";
  import Helpers from '@/helpers';
  import ObjectHelper from '@/helpers/object'
  import RewardTierApis from "@/controllers/RewardTierApis";
  import Redeem from '@/views/Membership/Program/RedeemPoints/Rewards.vue'
  import ReturnConfirmModal from "@/components/general/dialog/ReturnConfirmModal.vue";

  export default defineComponent({
    name: "ProductPromotionsEdit",
    components: {
      Redeem,
      ReturnConfirmModal
    },
    props: {
      item: {
        type: Object,
        required: true,
      },
      clearComponent: {
        type: Function,
        required: true,
      },
    },
    setup() {
      const snackbar = useSnackbar();
      const form = ref({
       id: '',
       tier_name: {
          value: '',
          error_type: '',
          error_message: '',
          validations: ['required'],
        },
        min_points: {
          value: '',
          error_type: '',
          error_message: '',
          validations: ['required', 'required_min_0'],
        },
        rewards: [],
        is_active: {
          value: true,
          error_type: '',
          error_message: '',
          validations: [],
        },
      });
      const reward = {
        type: '',
        discount: '',
        sku: '',
        name: '',
        variant: '',
        status: true
      }
      const deleteObj = ref({
        modal: false,
        obj: {},
      });
      const allSelectedSku = ref([])

      return {
        snackbar,
        form,
        reward,
        deleteObj,
        allSelectedSku
      };
    },
    computed: {
      ...mapGetters({
        vg_activeBusiness: 'auth/activeBusiness',
        vg_allLocations: 'location/list',
      }),
      /**
       * Edit restriction
       */
      can_edit_membership_details() {
        return Helpers.getMyPermission('can_edit_membership_details')
      },
    },

    async mounted() {
      this.load()
    },

    watch: {
      item: {
        handler: function(){
          this.load()
        },
        deep: true,
      }
    },

    methods: {
      load(){
        this.allSelectedSku = []
        this.form.rewards = []
        this.form.id = this.item.id
        this.form.tier_name.value = this.item.tier_name
        this.form.min_points.value = this.item.min_points
        this.form.is_active.value = this.item.is_active
        if(this.item.rewards.length>0){
          this.form.rewards = [...this.item.rewards]
          for(let i=0; i<this.item.rewards.length; i++){
            if(this.item.rewards[i]?.sku && !this.allSelectedSku.includes(this.item.rewards[i].sku)){
              this.allSelectedSku.push(this.item.rewards[i].sku)
            }
          }
        }else{
          this.form.rewards.push(this.reward)
        }
      },
      addBlankReward(){
        this.form.rewards.push(this.reward)
      },

      updateReward(index, reward){
        let updatedReward = {
          id: reward.id,
          type: reward.type,
          status: reward.status,
        }
        if(reward.type=='item'){
          updatedReward.sku = reward.sku
          updatedReward.name = reward.name
          updatedReward.image = reward.image
          updatedReward.variant = reward.variant
          updatedReward.details = {productId: reward.pId}
          if(reward.sku && !this.allSelectedSku.includes(reward.sku)){
            this.allSelectedSku.push(reward.sku)
          }
        }else if(reward.type=='discount_percentage'){
          updatedReward.discount = reward.discount
        }else if(reward.type=='custom'){
          updatedReward.name = reward.name
          updatedReward.image = reward.image
        }
        this.form.rewards[index] = updatedReward
      },

      removeReward(index){
        this.form.rewards.splice(index, 1)
      },

      /**
       * Validation captures
       */
      async validateFields(fields) {
        let res = await Helpers.validateFormFields(this.form, fields);
        // capture the fields
        this.form = res.data;
        return res.error ? false : true;
      },

      /**
       * Update data
       */
      async update() {
        
        // validate business field
        let validate = await this.validateFields([
          'tier_name', 'min_points'
        ]);
        if (!validate) {
          return;
        }

        let msg = ''
        // check if discount is empty
        let emptyDiscount = this.form.rewards.some(each => each.type=='discount_percentage' && !each.discount)
        // check if discount more than 100
        let discountExceed = this.form.rewards.some(each => each.type=='discount_percentage' && each.discount>100)
        // check if name is empty
        let nameEmpty = this.form.rewards.some(each => each.type=='item' && !each.name)
        // check if sku is empty
        let skuEmpty = this.form.rewards.some(each => each.type=='item' && !each.sku)
        // check if name is empty
        let imageEmpty = this.form.rewards.some(each => each.type=='custom' && !each.image)
        let nameImageEmpty = this.form.rewards.some(each => each.type=='custom' && !each.name)
        if(emptyDiscount) {
          msg = 'Discount is required'
        }else if(discountExceed){
          msg = 'Discount cannot be more than 100'
        }else if(nameEmpty){
          msg = 'Please select reward item'
        }else if(skuEmpty){
          msg = 'Please select valid reward item'
        }else if(imageEmpty){
          msg = 'Image is required'
        }else if(nameImageEmpty){
          msg = 'Custom name is required'
        }

        if(msg){
          this.snackbar.add({
            type: 'info',
            text: msg,
            duration: 3000,
          })
          return
        }

       this.va_spinner(true);

       let rewardArr = []
       for(let i=0; i<this.form.rewards.length; i++){
        if(this.form.rewards[i].type){
          ObjectHelper.removeEmptyString(this.form.rewards[i])
          if(this.form.rewards[i].type==='item'){
            if(!this.form.rewards[i].image){
              this.form.rewards[i].image = ''
            }
          }
          delete this.form.rewards[i].id
          rewardArr.push(this.form.rewards[i])
        }
       }

       let obj = {
          tier_name: this.form.tier_name.value,
          min_points: this.form.min_points.value,
          is_active: this.form.is_active.value,
          rewards: rewardArr?rewardArr:[]
       }

        let res = await RewardTierApis.update(this.item.id, obj);

        this.va_spinner(false);
        this.snackbar.add({
          type: 'info',
          text: res.status == 200 ? res.data.msg : res.response.data.msg,
          duration: 3000,
        })
        if(res.status == 200 ) {
          this.clearComponent();
          this.emitter.emit("refresh-reward-list", true);
        }
      },

      
      /**
       * Do not allow to add +,-,e
       */
      preventNonNumbers(e) {
        var ASCIICode = (e.which) ? e.which : e.keyCode
        if (ASCIICode == 43 || ASCIICode == 45 || ASCIICode == 101) {
          return false;
        }
        return true;
      },

    // /**
    //  * Open Delete confirm
    //  */
    async deleteRecordConfirm() {
      this.deleteObj = {
        modal: true,
        obj: this.item,
      };
    },

    // /**
    //  * Close delete confirm 
    //  */
    closeDeleteModal() {
      this.deleteObj = {
        modal: false,
        obj: {},
      };
    },

    // /**
    //  * Delete 
    //  */
    async deleteNow() {
      this.va_spinner(true);
      let res = await RewardTierApis.delete(this.item.id);
      this.va_spinner(false);
        this.snackbar.add({
        type: 'info',
        text: (res.status && res.status == 200) ? res.data.msg : Helpers.catchErrorMessage(res.response.msg),
        duration: 3000,
      })
      if(res.status == 200 ) {
        this.emitter.emit("refresh-reward-list", true);
        this.clearComponent();
      }
    },

    // /**
    //  * SetSelectedLocations
    //  */
    // setSelectedLocations(data) {
    //   this.selectedLocationIds = data;
    // },

    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  },
    
  });
</script>