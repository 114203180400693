
    import {
        defineComponent,
        ref
    } from "vue"
    import {
        mapGetters,
        mapActions,
        mapMutations
    } from "vuex";
    import LocationPosStationApis from "@/controllers/LocationPosStationApis";
    import LocationIntegrationApis from "@/controllers/LocationIntegrationApis";
    import {
        useSnackbar
    } from "vue3-snackbar";
    import Helpers from '@/helpers';
    import ReturnConfirmModal from "@/components/general/dialog/ReturnConfirmModal.vue";
    import LocationPaymentProcessorApis from "@/controllers/LocationPaymentProcessorApis";
    import Env from '@/env';
    
    export default defineComponent({
        name: "LocationPosAdd",
        components: {
            ReturnConfirmModal
        },
        setup() {
            const snackbar = useSnackbar();
            const deleteObj = ref({
                modal: false,
                obj: {},
            });
            const cloverAccess = < any > ref({
                verified: false,
                accessCode: '',
            });
            const form:any = ref({
                integrationProvider: {
                value: '',
                error_type: '',
                error_message: '',
                validations: ['required'],
                },
                integrationKey: 'access_token',
                sort: 1,
                merchantId: {
                    value: '', //E7JT323858QX1
                    error_type: '',
                    error_message: '',
                    validations: [],
                },
            
                active: false,
            });
            return {
                form,
                snackbar,
                deleteObj,
                cloverAccess
            };
        },
        props: {
            clearComponent: {
                type: Function,
                required: true,
            },
            item: {
                type: Object,
                required: true,
            },
        },
    
        watch: {
    
           
        },
    
        computed: {
       
        },
    
        mounted() {
        
        },
    
        methods: {
    
            /**
             * Delete location tax
             */
            async deleteRecord() {
                this.deleteObj = {
                    modal: true,
                    obj: this.item,
                };
            },
    
            /**
             * Close delete modal
             */
            closeDeleteModal() {
                this.deleteObj = {
                    modal: false,
                    obj: {},
                };
            },
    
            /**
             * Delete Pos
             */
            async deleteNow(obj) {
                this.va_spinner(true);
                let res = await LocationIntegrationApis.deleteClover();
                this.va_spinner(false);
                this.snackbar.add({
                    type: 'info',
                    text: res.status == 200 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
                    duration: 3000,
                })
                if (res.status == 200) {
                    this.emitter.emit("refresh-location-access-token-list", true);
                    this.clearComponent();
                }
            },


            /**
             * Accesstoken display
             */

            visibleToken(token) {
                if(!token)
                return;
                return `${String(token).slice(0, 4)}*****${String(token).slice(-4)}`

            },
    
            ...mapActions({
                va_spinner: 'spinner/setSpinner',
            })
        },
    });
    