
import {defineComponent, ref} from "vue"
import LocationPickupApis from '@/controllers/LocationPickupApis';
import { mapGetters, mapActions, mapMutations } from "vuex";
import Helpers from '@/helpers';
import { useSnackbar } from "vue3-snackbar";

export default defineComponent({
  name: "LocationPickup",
  components: {
  },

  setup() {
      const snackbar = useSnackbar();
      const loading = ref(false);
      const has_setting = ref(false);
      const form = <any>ref({
        pickupActive: false,
        enableAsap: true,
        pickupTimeSlots: false,
        timeSlotDuration: {
          value: '',
          error_type: '',
          error_message: '',
          validations: ['required'],
        },
        orderPrepTime: {
          value: 30,
          error_type: '',
          error_message: '',
          validations: ['required'],
        },
        orderLimitPerTimeSlot: {
          value: 10,
          error_type: '',
          error_message: '',
          validations: ['required', 'positivenumber', 'maxval:99999'],
        },
        timeSlotPeriod: {
          value: 1,
          error_type: '',
          error_message: '',
          validations: ['required', 'positivenumber', 'maxval:99999'],
        },
        cancellationCutoff: {
          value: 10,
          error_type: '',
          error_message: '',
          validations: []
        },
        payAtStore: false,
        payOnline: false
      });

    return {
      form,
      loading,
      snackbar,
      has_setting
    };
  },

   watch : {
    'vg_selectedLocation'(val) {
      this.fetchLocationPickupSetting();
    },
    'form.orderLimitPerTimeSlot.value'(val) {
      this.form.orderLimitPerTimeSlot.value = val ? Math.trunc(val) : '';
    },
    'form.pickupTimeSlots'(val) {
      if(val == false) {
        this.form = Helpers.removeValidationOnly(this.form, ['timeSlotDuration', 'orderPrepTime', 'orderLimitPerTimeSlot', 'timeSlotPeriod']);
      }else{
        this.form.enableAsap = false
      }
    },
    'form.enableAsap'(val){
      if(val){
        this.form.pickupTimeSlots = false
      }
    }
  },

  computed: {
    ...mapGetters({
      vg_selectedLocation: 'location/selected'
    }),
  },
 

  async mounted() {
    this.loading = true;
    await this.fetchLocationPickupSetting();
    this.loading = false;
  },

  methods: {
    isInvalidToSave(){
      if(!this.form.pickupActive) return false

      if(!this.form.enableAsap && !this.form.pickupTimeSlots) return true

      if(!this.form.payOnline && !this.form.payAtStore) return true

      return false
    },

    /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },

    preventNonNumbers(evt) {
      var ASCIICode = (evt.which) ? evt.which : evt.keyCode
      if(ASCIICode == 43 || ASCIICode == 45){
        return false;
      }
      return true;
    },

    /**
     * Fetch business branding
     */
    async fetchLocationPickupSetting() {
      this.loading = true;
      let res = await LocationPickupApis.fetchLocationPickupSetting();
      this.loading = false;
      if(res.status == 200) {
        this.has_setting = true;
        this.form = <any>Helpers.assignFormValues(this.form, res.data.payload);
      } else {
        this.has_setting = false;
        this.form = <any>Helpers.assignFormValues(this.form, {});
      }
    },

    /**
     * Create business branding
     */
    async save() {
      // validate business field
    
      let validate = true;
      if(this.form.pickupTimeSlots == true) {
        validate = await this.validateFields(['timeSlotDuration', 'orderPrepTime', 'orderLimitPerTimeSlot', 'timeSlotPeriod']);
      }

      
      if(!validate) {
        return ;
      }
      if(this.has_setting) {
        this.updateSetting();
      } else {
        this.createSetting();
      }
    },

    /**
     * Create setting 
     */
    async createSetting() {
      this.va_spinner(true);
      let res = await LocationPickupApis.createLocationPickupSetting(Helpers.getFormKeyValue(this.form));
      this.va_spinner(false);

      if(res.status == 201) {
        this.has_setting = true;
      }
      
      this.snackbar.add({
        type: 'info',
        text: res.status == 201 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
    },

    /**
     * Update setting 
     */
    async updateSetting() {
      this.va_spinner(true);
      let res = await LocationPickupApis.updateLocationPickupSetting(Helpers.getFormKeyValue(this.form));
      this.va_spinner(false);
      this.snackbar.add({
        type: 'info',
        text: res.status == 200 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
    },

    ...mapMutations({

    }),

    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })

  },
});

