<template>
  <custom-modal :size="'max-w-[915px] mt-10'">
    <template v-slot:maincontent>
      <div class="w-[883px]">
        <div class="text-white flex-1 col-span-1 border-0 rounded-t-xl max-h-[650px] overflow-auto">
          <div class="bg-theme-green px-4 py-2 text-white">
            <div class="flex justify-between items-center">
              <div class="flex flex-col justify-start text-start ">
                <h2 class="font-semibold truncate text-single-line break-all  text-xl mb-0.5 leading-6 mt-0 ml-2">Close Vault</h2>
              </div>
              
              <div class="flex flex-col">
                <div class="flex items-center content-center gap-2">
                  <button @click.prevent="close" class="text-sm flex items-center justify-center text-center w-[100px] h-[44px] tracking-[0.15px] font-medium cursor-pointer hover:bg-white border border-white p-4 rounded hover:text-theme-green  text-white ml-3">CANCEL</button>
                  <button @click.prevent="saveAudit" class="text-sm flex items-center justify-center text-center w-[100px] h-[44px] tracking-[0.15px] font-medium cursor-pointer bg-white p-4 rounded text-theme-green hover:bg-theme-darkgreen hover:text-white ml-3">
                    <span>SAVE</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class=" bg-[#2B2C32] px-[16px] py-[16px] rounded-b-lg pb-8">
            <div class="mb-6 flex justify-between">
              <div class="border-r border-[#414141] pr-[58px]">
                <!-- 5¢ -->
                <div class="flex flex-row justify-between items-center gap-20 mb-4">
                  <span class="font-bold rounded-full bg-[#B4B4B4] text-white p-1 w-[44px] h-[44px] flex items-center justify-center text-center text-xl leading-6 tracking-[0.15px]">5¢</span>
                  <div class="flex flex-row justify-between items-center gap-7">
                    <button @click="(money.fiveCent>0)?--money.fiveCent:null" class="w-[44px] h-[44px] hover:opacity-60 flex items-center justify-center rounded bg-[#DC3545]">
                      <svg width="26" height="4" viewBox="0 0 26 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.8334 3.83366H0.166748V0.166992H25.8334V3.83366Z" fill="white"/>
                      </svg>
                    </button>
                    <input class="w-[78px] h-[44px] flex items-center justify-center rounded p-2 border bg-[#2B2C32] text-center focus:outline-0 border-white" @blur="money.fiveCent==''?money.fiveCent=0:money.fiveCent" v-model="money.fiveCent" />
                    <button @click="++money.fiveCent" class="w-[44px] h-[44px] hover:opacity-60 flex items-center justify-center rounded bg-[#2BA66B]">
                      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.8334 14.8337H14.8334V25.8337H11.1667V14.8337H0.166748V11.167H11.1667V0.166992H14.8334V11.167H25.8334V14.8337Z" fill="white"/>
                      </svg>
                    </button>
                  </div>
                </div>
                <!-- 10¢ -->
                <div class="flex flex-row justify-between items-center gap-20 mb-4">
                  <span class="font-bold rounded-full bg-[#B4B4B4] text-white p-1 w-[44px] h-[44px] flex items-center justify-center text-center text-xl leading-6 tracking-[0.15px]">10¢</span>
                  <div class="flex flex-row justify-between items-center gap-7">
                    <button @click="(money.tenCent>0)?--money.tenCent:null" class="w-[44px] h-[44px] hover:opacity-60 flex items-center justify-center rounded bg-[#DC3545]">
                      <svg width="26" height="4" viewBox="0 0 26 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.8334 3.83366H0.166748V0.166992H25.8334V3.83366Z" fill="white"/>
                      </svg>
                    </button>
                    <input class="w-[78px] h-[44px] flex items-center justify-center rounded p-2 border bg-[#2B2C32] text-center focus:outline-0 border-white" @blur="money.tenCent==''?money.tenCent=0:money.tenCent" v-model="money.tenCent" />
                    <button @click="++money.tenCent" class="w-[44px] h-[44px] hover:opacity-60 flex items-center justify-center rounded bg-[#2BA66B]">
                      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.8334 14.8337H14.8334V25.8337H11.1667V14.8337H0.166748V11.167H11.1667V0.166992H14.8334V11.167H25.8334V14.8337Z" fill="white"/>
                      </svg>
                    </button>
                  </div>
                </div>
                <!-- 25¢ -->
                <div class="flex flex-row justify-between items-center gap-20 mb-4">
                  <span class="font-bold rounded-full bg-[#B4B4B4] text-white p-1 w-[44px] h-[44px] flex items-center justify-center text-center text-xl leading-6 tracking-[0.15px]">25¢</span>
                  <div class="flex flex-row justify-between items-center gap-7">
                    <button @click="(money.twentyFiveCent>0)?--money.twentyFiveCent:null" class="w-[44px] h-[44px] hover:opacity-60 flex items-center justify-center rounded bg-[#DC3545]">
                      <svg width="26" height="4" viewBox="0 0 26 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.8334 3.83366H0.166748V0.166992H25.8334V3.83366Z" fill="white"/>
                      </svg>
                    </button>
                    <input class="w-[78px] h-[44px] flex items-center justify-center rounded p-2 border bg-[#2B2C32] text-center focus:outline-0 border-white" @blur="money.twentyFiveCent==''?money.twentyFiveCent=0:money.twentyFiveCent" v-model="money.twentyFiveCent" />
                    <button @click="++money.twentyFiveCent" class="w-[44px] h-[44px] hover:opacity-60 flex items-center justify-center rounded bg-[#2BA66B]">
                      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.8334 14.8337H14.8334V25.8337H11.1667V14.8337H0.166748V11.167H11.1667V0.166992H14.8334V11.167H25.8334V14.8337Z" fill="white"/>
                      </svg>
                    </button>
                  </div>
                </div>
                <!-- $1 -->
                <div class="flex flex-row justify-between items-center gap-20 mb-4">
                  <span class="font-bold rounded-full bg-[#D6A567] text-white p-1 w-[44px] h-[44px] flex items-center justify-center text-center text-xl leading-6 tracking-[0.15px]">$1</span>
                  <div class="flex flex-row justify-between items-center gap-7">
                    <button @click="(money.oneDollar>0)?--money.oneDollar:null" class="w-[44px] h-[44px] hover:opacity-60 flex items-center justify-center rounded bg-[#DC3545]">
                      <svg width="26" height="4" viewBox="0 0 26 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.8334 3.83366H0.166748V0.166992H25.8334V3.83366Z" fill="white"/>
                      </svg>
                    </button>
                    <input class="w-[78px] h-[44px] flex items-center justify-center rounded p-2 border bg-[#2B2C32] text-center focus:outline-0 border-white" @blur="money.oneDollar==''?money.oneDollar=0:money.oneDollar" v-model="money.oneDollar" />
                    <button @click="++money.oneDollar" class="w-[44px] h-[44px] hover:opacity-60 flex items-center justify-center rounded bg-[#2BA66B]">
                      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.8334 14.8337H14.8334V25.8337H11.1667V14.8337H0.166748V11.167H11.1667V0.166992H14.8334V11.167H25.8334V14.8337Z" fill="white"/>
                      </svg>
                    </button>
                  </div>
                </div>
                <!-- $2 -->
                <div class="flex flex-row justify-between items-center gap-20 mb-4">
                  <span class="font-bold rounded-full bg-[#D6A567] text-white p-1 w-[44px] h-[44px] flex items-center justify-center text-center text-xl leading-6 tracking-[0.15px]">$2</span>
                  <div class="flex flex-row justify-between items-center gap-7">
                    <button @click="(money.twoDollar>0)?--money.twoDollar:null" class="w-[44px] h-[44px] hover:opacity-60 flex items-center justify-center rounded bg-[#DC3545]">
                      <svg width="26" height="4" viewBox="0 0 26 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.8334 3.83366H0.166748V0.166992H25.8334V3.83366Z" fill="white"/>
                      </svg>
                    </button>
                    <input class="w-[78px] h-[44px] flex items-center justify-center rounded p-2 border bg-[#2B2C32] text-center focus:outline-0 border-white" @blur="money.twoDollar==''?money.twoDollar=0:money.twoDollar" v-model="money.twoDollar" />
                    <button @click="++money.twoDollar" class="w-[44px] h-[44px] hover:opacity-60 flex items-center justify-center rounded bg-[#2BA66B]">
                      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.8334 14.8337H14.8334V25.8337H11.1667V14.8337H0.166748V11.167H11.1667V0.166992H14.8334V11.167H25.8334V14.8337Z" fill="white"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <!-- $5 -->
                <div class="flex flex-row justify-between items-center  gap-20 mb-4">
                  <span class="font-bold rounded bg-[#52C8E2] text-white p-1 w-[88px] h-[44px] flex items-center justify-center text-center text-xl leading-6 tracking-[0.15px]">$5</span>
                  <div class="flex flex-row justify-between items-center gap-7">
                    <button @click="(money.fiveDollar>0)?--money.fiveDollar:null" class="w-[44px] h-[44px] hover:opacity-60 flex items-center justify-center rounded bg-[#DC3545]">
                      <svg width="26" height="4" viewBox="0 0 26 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.8334 3.83366H0.166748V0.166992H25.8334V3.83366Z" fill="white"/>
                      </svg>
                    </button>
                    <input class="w-[78px] h-[44px] flex items-center justify-center rounded p-2 border bg-[#2B2C32] text-center focus:outline-0 border-white" @blur="money.fiveDollar==''?money.fiveDollar=0:money.fiveDollar" v-model="money.fiveDollar" />
                    <button @click="++money.fiveDollar" class="w-[44px] h-[44px] hover:opacity-60 flex items-center justify-center rounded bg-[#2BA66B]">
                      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.8334 14.8337H14.8334V25.8337H11.1667V14.8337H0.166748V11.167H11.1667V0.166992H14.8334V11.167H25.8334V14.8337Z" fill="white"/>
                      </svg>
                    </button>
                  </div>
                </div>
                <!-- $10 -->
                <div class="flex flex-row justify-between items-center  gap-20 mb-4">
                  <span class="font-bold rounded bg-[#866BC9] text-white p-1 w-[88px] h-[44px] flex items-center justify-center text-center text-xl leading-6 tracking-[0.15px]">$10</span>
                  <div class="flex flex-row justify-between items-center gap-7">
                    <button @click="(money.tenDollar>0)?--money.tenDollar:null" class="w-[44px] h-[44px] hover:opacity-60 flex items-center justify-center rounded bg-[#DC3545]">
                      <svg width="26" height="4" viewBox="0 0 26 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.8334 3.83366H0.166748V0.166992H25.8334V3.83366Z" fill="white"/>
                      </svg>
                    </button>
                    <input class="w-[78px] h-[44px] flex items-center justify-center rounded p-2 border bg-[#2B2C32] text-center focus:outline-0 border-white" @blur="money.tenDollar==''?money.tenDollar=0:money.tenDollar" v-model="money.tenDollar" />
                    <button @click="++money.tenDollar" class="w-[44px] h-[44px] hover:opacity-60 flex items-center justify-center rounded bg-[#2BA66B]">
                      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.8334 14.8337H14.8334V25.8337H11.1667V14.8337H0.166748V11.167H11.1667V0.166992H14.8334V11.167H25.8334V14.8337Z" fill="white"/>
                      </svg>
                    </button>
                  </div>
                </div>
                <!-- $20 -->
                <div class="flex flex-row justify-between items-center  gap-20 mb-4">
                  <span class="font-bold rounded bg-[#92CD63] text-white p-1 w-[88px] h-[44px] flex items-center justify-center text-center text-xl leading-6 tracking-[0.15px]">$20</span>
                  <div class="flex flex-row justify-between items-center gap-7">
                    <button @click="(money.twentyDollar>0)?--money.twentyDollar:null" class="w-[44px] h-[44px] hover:opacity-60 flex items-center justify-center rounded bg-[#DC3545]">
                      <svg width="26" height="4" viewBox="0 0 26 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.8334 3.83366H0.166748V0.166992H25.8334V3.83366Z" fill="white"/>
                      </svg>
                    </button>
                    <input class="w-[78px] h-[44px] flex items-center justify-center rounded p-2 border bg-[#2B2C32] text-center focus:outline-0 border-white" @blur="money.twentyDollar==''?money.twentyDollar=0:money.twentyDollar" v-model="money.twentyDollar" />
                    <button @click="++money.twentyDollar" class="w-[44px] h-[44px] hover:opacity-60 flex items-center justify-center rounded bg-[#2BA66B]">
                      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.8334 14.8337H14.8334V25.8337H11.1667V14.8337H0.166748V11.167H11.1667V0.166992H14.8334V11.167H25.8334V14.8337Z" fill="white"/>
                      </svg>
                    </button>
                  </div>
                </div>
                <!-- $50 -->
                <div class="flex flex-row justify-between items-center  gap-20 mb-4">
                  <span class="font-bold rounded bg-[#C76D61] text-white p-1 w-[88px] h-[44px] flex items-center justify-center text-center text-xl leading-6 tracking-[0.15px]">$50</span>
                  <div class="flex flex-row justify-between items-center gap-7">
                    <button @click="(money.fiftyDollar>0)?--money.fiftyDollar:null" class="w-[44px] h-[44px] hover:opacity-60 flex items-center justify-center rounded bg-[#DC3545]">
                      <svg width="26" height="4" viewBox="0 0 26 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.8334 3.83366H0.166748V0.166992H25.8334V3.83366Z" fill="white"/>
                      </svg>
                    </button>
                    <input class="w-[78px] h-[44px] flex items-center justify-center rounded p-2 border bg-[#2B2C32] text-center focus:outline-0 border-white" @blur="money.fiftyDollar==''?money.fiftyDollar=0:money.fiftyDollar" v-model="money.fiftyDollar" />
                    <button @click="++money.fiftyDollar" class="w-[44px] h-[44px] hover:opacity-60 flex items-center justify-center rounded bg-[#2BA66B]">
                      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.8334 14.8337H14.8334V25.8337H11.1667V14.8337H0.166748V11.167H11.1667V0.166992H14.8334V11.167H25.8334V14.8337Z" fill="white"/>
                      </svg>
                    </button>
                  </div>
                </div>
                <!-- $100 -->
                <div class="flex flex-row justify-between items-center  gap-20 mb-4">
                  <span class="font-bold rounded bg-[#D6A567] text-white p-1 w-[88px] h-[44px] flex items-center justify-center text-center text-xl leading-6 tracking-[0.15px]">$100</span>
                  <div class="flex flex-row justify-between items-center gap-7">
                    <button @click="(money.hundredDollar>0)?--money.hundredDollar:null" class="w-[44px] h-[44px] hover:opacity-60 flex items-center justify-center rounded bg-[#DC3545]">
                      <svg width="26" height="4" viewBox="0 0 26 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.8334 3.83366H0.166748V0.166992H25.8334V3.83366Z" fill="white"/>
                      </svg>
                    </button>
                    <input class="w-[78px] h-[44px] flex items-center justify-center rounded p-2 border bg-[#2B2C32] text-center focus:outline-0 border-white" @blur="money.hundredDollar==''?money.hundredDollar=0:money.hundredDollar" v-model="money.hundredDollar" />
                    <button @click="++money.hundredDollar" class="w-[44px] h-[44px] hover:opacity-60 flex items-center justify-center rounded bg-[#2BA66B]">
                      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.8334 14.8337H14.8334V25.8337H11.1667V14.8337H0.166748V11.167H11.1667V0.166992H14.8334V11.167H25.8334V14.8337Z" fill="white"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex items-center w-full justify-between flex-row mb-3 border-2 rounded-md py-0.5 px-4 border-[#414141]">
              <div class='input-wrapper '>
                <label class="text-base leading-5 text-[#9E9E9E] font-normal">Total Cash</label>
              </div>
              <div class="w-32">
                <div class='input-wrapper relative'>
                  <input disabled v-model="form.amount" placeholder="$0.00" class="block leading-[30px] pl-[6px] focus:outline-none focus:ring-offset-0 focus:ring-offset-inherit focus:ring-transparent focus:outline-offset-0 font-bold text-end border-none rounded placeholder-theme-878686 bg-transparent w-full text-2xl" type="text">
                </div>
              </div>
            </div> 
            <div class="flex items-center  xl:flex-row flex-col gap-3 ">  
              <div class="w-full xl:w-1/2">
                <div class='flex items-center flex-row mb-3 rounded-md py-[10px] px-4 bg-[#1C1D21]'>
                  <span class="text-base w-64 leading-5 text-white font-normal">Expected Total</span>
                  <input readonly v-model="form.expectedAmount" @keyup="form.expectedAmount?form.expectedAmount='$'+(form.expectedAmount).replace('$',''):null" placeholder="$700.00" class="leading-[30px] focus:outline-0 focus:border-0 px-0 font-bold text-end border-none rounded bg-transparent w-full text-2xl">
                </div>
              </div>
              <div class="w-full xl:w-1/2">
                <div class='flex items-center justify-center mb-3 flex-row rounded-md py-[10px] px-4 bg-[#1C1D21]'>
                  <span class="text-base leading-5 text-white font-normal">Difference</span>
                  <span :class="isPositive()?'text-[#FF6868]':''" class="leading-[30px] px-0 font-bold text-end border-none rounded bg-transparent w-full text-2xl">{{form.difference}}</span>
                </div>
              </div>
            </div>
            <div class="flex items-center  xl:flex-row flex-col gap-3 ">  
              <div class="w-full xl:w-1/2">
                <div class='flex items-center flex-row mb-3 rounded-md py-[10px] px-4 border-2 border-[#414141]'>
                  <span class="text-base w-64 leading-5 text-white font-normal">Float</span>
                  <input v-model="form.float" :onkeypress="preventNonNumbersAndDecimal" @keyup="form.float?form.float='$'+(form.float).replace('$',''):null" placeholder="$0.00" class="leading-[30px] focus:outline-0 focus:border-0 px-0 font-bold text-end border-none rounded bg-transparent w-full text-2xl">
                </div>
              </div>
              <div class="w-full xl:w-1/2">
                <div class='flex items-center justify-center mb-3 flex-row rounded-md py-[10px] px-4 border-2 border-[#414141]'>
                  <span class="text-base w-64 leading-5 text-white font-normal">Cash To Deposit</span>
                  <input v-model="form.cashToDeposit" :onkeypress="preventNonNumbersAndDecimal" @keyup="form.cashToDeposit?form.cashToDeposit='$'+(form.cashToDeposit).replace('$',''):null" placeholder="$0.00" class="leading-[30px] focus:outline-0 focus:border-0 px-0 font-bold text-end border-none rounded bg-transparent w-full text-2xl">
                </div>
              </div>
            </div>
            <div class="flex items-center w-full justify-between flex-row mb-3 border-2 rounded-md py-0.5 px-4 border-[#414141]">
              <div class='input-wrapper '>
                <label class="text-base leading-5 text-[#9E9E9E] font-normal">Bank Deposit #</label>
              </div>
              <div class="w-[400px]">
                <div class='input-wrapper relative'>
                  <input v-model="form.depositId" placeholder="ei. Chequing 00002-54-07583" class="block leading-[30px] pl-[6px] focus:outline-none focus:ring-offset-0 focus:ring-offset-inherit focus:ring-transparent focus:outline-offset-0 text-end border-none rounded placeholder-theme-878686 bg-transparent w-full text-[20px]" type="text">
                </div>
              </div>
            </div> 
            
            <div class="w-full mb-6">
              <div class="input-wrapper relative ">
                <label class="text-xs tracking-[0.4px]">Notes</label>
                <textarea v-model="form.note" placeholder="Add a note here" class="block tracking-wider border-none rounded placeholder-theme-878686 bg-gray-black w-full text-sm"></textarea>
              </div>
              <div class="flex items-center justify-center mt-6">
                <button @click.prevent="saveAudit" class="w-[400px] h-[44px] py-4 px-2 flex items-center justify-center hover:opacity-60 bg-theme-green disabled:bg-theme-grey rounded-md font-bold text-base leading-5 text-white tracking-[0.15px]">CONFIRM</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </custom-modal>
</template>


<script>
import {defineComponent, ref} from "vue"
import { mapGetters } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import VaultApis from '@/controllers/VaultApis'
import Helpers from '@/helpers'

export default defineComponent({
  name: "CloseVault",
  props: ['totalBalance'],
  setup() {
    const money = ref({
      fiveCent: 0,
      tenCent: 0,
      twentyFiveCent: 0,
      oneDollar: 0,
      twoDollar: 0,
      fiveDollar: 0,
      tenDollar: 0,
      twentyDollar: 0,
      fiftyDollar: 0,
      hundredDollar: 0
    })
    const form = ref({
      amount: '',
      expectedAmount: '',
      difference: '$0.00',
      float: '',
      cashToDeposit: '',
      depositId: '',
      note: ''
    })
    const calculating = ref(false)
    const snackbar = useSnackbar();

    return {
      money,
      form,
      snackbar,
      calculating,
    }
  },
  watch: {
    money: {
      handler: function(){
        let amount = (this.money.fiveCent*0.05)+(this.money.tenCent*0.1)+(this.money.twentyFiveCent*0.25)+(this.money.oneDollar*1)+(this.money.twoDollar*2)+(this.money.fiveDollar*5)+(this.money.tenDollar*10)+(this.money.twentyDollar*20)+(this.money.fiftyDollar*50)+(this.money.hundredDollar*100)
        if(amount){
          this.form.amount = '$'+amount.toFixed(2)
        }else{
          this.form.amount = ''
        }
      },
      deep: true
    },
    'form.amount'(){
      this.calculateDifference()
      this.form.cashToDeposit = ''
      this.form.float = ''
    },
    'form.expectedAmount'(){
      this.calculateDifference()
    },
    'form.float'(float){
      if(!this.calculating){
        this.floatAndCashToDepositEqualToTotal('float', float)
      }
    },
    'form.cashToDeposit'(cashToDeposit){
      if(!this.calculating){
        this.floatAndCashToDepositEqualToTotal('cashToDeposit', cashToDeposit)
      }
    }
  },
  mounted(){
    this.form.expectedAmount = '$'+this.totalBalance
  },
  computed: {
     ...mapGetters({
        vg_selectedLocation: 'location/selected'
      }),
  },
  methods: {
    preventNonNumbersAndDecimal(e) {
      var ASCIICode = (e.which) ? e.which : e.keyCode
      if(ASCIICode == 43 || ASCIICode == 45 || ASCIICode == 101 || e.which == 8 || ASCIICode !== 46 && ASCIICode != 0 && (ASCIICode < 48 || ASCIICode > 57)){
        return false
      }
      return true;
    },
    floatAndCashToDepositEqualToTotal(type, value){
      this.calculating = true
      let amount = this.form.amount.replace('$', '')
      let cashToDeposit = type==='float'?value.replace('$', ''):this.form.cashToDeposit.replace('$', '')
      let float = type!=='float'?value.replace('$', ''):this.form.float.replace('$', '')
      if(amount){
        if(type==='float'){
          if(float){
            if((parseFloat(amount)-parseFloat(float))<0){
              this.form.cashToDeposit = '-$'+Math.abs((parseFloat(amount)-parseFloat(float)))
            }else{
              this.form.cashToDeposit = '$'+(parseFloat(amount)-parseFloat(float))
            }
          }else{
            this.form.cashToDeposit = ''
          }
        }else{
          if(cashToDeposit){
            if((parseFloat(amount)-parseFloat(cashToDeposit))<0){
              this.form.float = '-$'+Math.abs((parseFloat(amount)-parseFloat(cashToDeposit)))
            }else{
              this.form.float = '$'+(parseFloat(amount)-parseFloat(cashToDeposit))
            }
          }else{
            this.form.float = ''
          }
        }
      }
      this.calculating = false
    },
    close(){
      this.$emit('close')
    },
    isPositive(){
      return parseFloat(this.form.amount.replace('$',''))<parseFloat(this.form.expectedAmount.replace('$',''))
    },
    calculateDifference(){
      if(this.form.expectedAmount || this.form.amount){
        let value = this.form.amount.replace('$','')-this.form.expectedAmount.replace('$','')
        if(value<0){
          this.form.difference = '-$'+Math.abs(value).toFixed(2)
        }else{
          this.form.difference = '$'+value.toFixed(2)
        }
      }else{
        this.form.difference = '$0.00'
      }
    },
    async saveAudit(){
      let msg = ''
      if(!this.form.float.replace('$','')){
        msg = 'Float is required'
      }else if(this.form.float.indexOf('-')>=0){
        msg = 'Float shouldn\'t be negative'
      }else if(!this.form.cashToDeposit){
        msg = 'Cash To Deposit is required'
      }else if(this.form.cashToDeposit.indexOf('-')>=0){
        msg = 'Cash To Deposit shouldn\'t be negative'
      }else if(!this.form.amount.replace('$','') && !this.form.expectedAmount.replace('$','')){
        msg = 'Total Cash or Expected Total is required'
      }


      if(msg.length>0){
        this.snackbar.add({
          type: 'info',
          text: msg,
          duration: 3000,
        })
      }else{
          let self = this
          let obj = {
            transactionType: 'close vault',
            amount: this.form.amount?parseFloat(this.form.amount.replace('$','')):0,
            expectedAmount: this.form.expectedAmount?parseFloat(this.form.expectedAmount.replace('$','')):0,
            difference: parseFloat(this.form.difference.replace('$','')),
            float: this.form.float?parseFloat(this.form.float.replace('$','')):0,
            cashToDeposit: this.form.cashToDeposit?parseFloat(this.form.cashToDeposit.replace('$','')):0,
            note: this.form.note,
            depositId: this.form.depositId
          }
          if(obj.difference!=0){
            this.confirm('Your total does not match your expected total.').then(async () => {
              this.proceedAudit(obj)
            })
          }else{
            this.proceedAudit(obj)
          }
        }
      },
      async proceedAudit(obj){
        let res = await VaultApis.closeVault(this.vg_selectedLocation.id, obj)
        this.snackbar.add({
          type: 'info',
          text: res.status == 201 ? 'Vault is closed' : Helpers.catchErrorMessage(res.response.data),
          duration: 3000,
        })
        if(res.status==201){
          this.$emit('close', true)
        }
      }
    }
});

</script>
