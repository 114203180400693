import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-[#23242A]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Nav = _resolveComponent("Nav")!
  const _component_CashVault = _resolveComponent("CashVault")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Nav, {
      active: _ctx.activeTab,
      callback: _ctx.changeActiveTab,
      onUpdateBalance: _cache[0] || (_cache[0] = ($event: any) => (_ctx.totalBalance = $event))
    }, null, 8, ["active", "callback"]),
    _createVNode(_component_CashVault, { totalBalance: _ctx.totalBalance }, null, 8, ["totalBalance"])
  ]))
}