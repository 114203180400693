<template>
    <div class="flex flex-row items-center mb-6" @blur="closeDropdown" tabindex="0">
        <div class="flex flex-grow relative">
            <div class="flex justify-between  w-full relative">
                <svg  class="absolute z-[99] left-[16px] top-[17px]" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 15L10.3333 10.3333M11.8889 6.44444C11.8889 9.45133 9.45133 11.8889 6.44444 11.8889C3.43756 11.8889 1 9.45133 1 6.44444C1 3.43756 3.43756 1 6.44444 1C9.45133 1 11.8889 3.43756 11.8889 6.44444Z" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>
                <input @blur="closeDropdown" :disabled="disabled" @focus="openDropDown" v-model="search" type="text" class="rounded w-full relative pl-10 h-[50px] bg-transparent align-top mr-4 border-[#464851] border-2" placeholder="Search by Product Name, Sku #, Variant, Lot #">
                <svg v-if="open" @click="resetSearch" class="cursor-pointer absolute right-[30px] top-[17px]" width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z" fill="white"/>
                </svg>
            </div>
            <div v-if="open" class="w-[calc(100%_-_15px)] flex flex-col flex-grow z-[999] absolute top-[50px] max-h-96" :class="classString">
                <div class="max-h-[300px] overflow-auto">
                    <template v-for="(result, ind) in results" :key="ind" >
                        <div @click="toggle(result.id)" class="relative flex flex-row p-4 border-t border-light-black drop-shadow transition duration-500 bg-theme-modalbg hover:bg-theme-grey cursor-pointer">
                            <div class="mr-2 self-center">
                                <svg v-if="selected.includes(result.id)" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.45 17.55C0.75 17.85 1.1 18 1.5 18H16.5C16.9 18 17.25 17.85 17.55 17.55C17.85 17.25 18 16.9 18 16.5V1.5C18 1.1 17.85 0.75 17.55 0.45C17.25 0.15 16.9 0 16.5 0H1.5C1.1 0 0.75 0.15 0.45 0.45C0.15 0.75 0 1.1 0 1.5V16.5C0 16.9 0.15 17.25 0.45 17.55ZM16.5 16.5H1.5V1.5H16.5V16.5Z" fill="#2BA66B"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 16.5H16.5V1.5H1.5V16.5ZM14.7 5.75L7.475 12.975L3.425 8.925L4.5 7.85L7.475 10.825L13.625 4.675L14.7 5.75Z" fill="#2BA66B"/>
                                    <path d="M7.475 12.975L14.7 5.75L13.625 4.675L7.475 10.825L4.5 7.85L3.425 8.925L7.475 12.975Z" fill="white"/>
                                </svg>
                                <svg v-else width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.5 18C1.1 18 0.75 17.85 0.45 17.55C0.15 17.25 0 16.9 0 16.5V1.5C0 1.1 0.15 0.75 0.45 0.45C0.75 0.15 1.1 0 1.5 0H16.5C16.9 0 17.25 0.15 17.55 0.45C17.85 0.75 18 1.1 18 1.5V16.5C18 16.9 17.85 17.25 17.55 17.55C17.25 17.85 16.9 18 16.5 18H1.5ZM1.5 16.5H16.5V1.5H1.5V16.5Z" fill="white"/>
                                </svg>
                            </div>
                            <div class="flex-start">{{result.name}}</div>
                        </div>
                    </template>
                    <div v-if="results.length===0" class="relative flex flex-row p-4 border-t border-light-black drop-shadow transition duration-500 bg-theme-modalbg hover:bg-theme-grey">
                        <div class="flex-start">No product found.</div>
                    </div>
                </div>
            </div>
        </div>

        <button @click="addProduct" class="w-[100px] h-[50px] rounded p-4 bg-theme-green text-white hover:opacity-50 font-medium text-sm leading-[17.5px] tracking-[0.15px]">Add</button>
    </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import debounce from 'lodash/debounce'
import TransferApi from '@/controllers/Transfer'
import {mapMutations} from 'vuex'
import { useSnackbar } from "vue3-snackbar"
import Helpers from '@/helpers';

export default defineComponent({
    props: ['disabled', 'payload'],
    setup() {
        const snackbar = useSnackbar();
        const search = ref('')
        const open = ref(false)
        const selected = ref([])
        const results = ref([])

        return {
            snackbar,
            open,
            search,
            selected,
            results
        }
    },
    watch: {
        search: debounce(function (val) {
            if(val.length>2){
                this.searchProduct(val)
            }
        }, 600)
    },
    methods: {
        async searchProduct(search){
            let params = `search=${search}`
            let res = await TransferApi.fetchAllLocationProduct(this.payload.fromLocationId, params)
            if(res?.data?.payload){
                this.open = true
                this.results = res.data.payload
            }
        },
        openDropDown(){
            if(this.search){
                this.open = true
            }
        },
        closeDropdown(e){
            if(!e.relatedTarget){
                this.open = false
            }
        },
        resetSearch(){
            this.search = ''
            this.selected = []
            this.open = false
        },
        toggle(productId){
            let findIndex = this.selected.indexOf(productId)
            if(findIndex>=0){
                this.selected.splice(findIndex, 1)
            }else{
                this.selected.push(productId)
            }
        },
        async addProduct(){
            if(this.selected.length>0){
                this.vm_spinner(true)
                let data = {
                    transferId: parseInt(this.$route.params.id),
                    productIds: this.selected
                }
                let res = await TransferApi.addProducts(data)
                this.resetSearch()
                this.$emit('reload')
                this.snackbar.add({
                    type: 'info',
                    text: res.status == 201 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
                    duration: 3000,
                })
                this.vm_spinner(false)
            }
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }
})
</script>
