/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

 import store from '@/store/index';
 import Env from '@/env';
 const apiVersion = 'api';
 import AuthApis from '@/controllers/AuthApis';
 import ApiHelper from '@/controllers/ApiHelpers';
 import router from '@/router';

 export default {
     /**
      * Fetch selected location delivery setting
      */
      fetchLocationDeliverySetting() {
        const url = `${Env.loginUrl}/${apiVersion}/location-delivery-settings/show`;
        return AuthApis.getApi(url, null)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
    },

    /**
      * Create location delivery setting
      */
     createLocationDeliverySetting(data) {
        const url = `${Env.loginUrl}/${apiVersion}/location-delivery-settings/create`;
        return AuthApis.postApi(url, null, data)
         .then(res => res)
         .catch(err => {
           ApiHelper.detectStatusAndRedirect(err);
           return err;
         });
    },

    /**
      * Update delivery Setting
      */
     updateLocationDeliverySetting(data) {
        const url = `${Env.loginUrl}/${apiVersion}/location-delivery-settings/update`;
        return AuthApis.patchApi(url, null, data)
         .then(res => res)
         .catch(err => {
           ApiHelper.detectStatusAndRedirect(err);
           return err;
         });
    },

    /**
      * Fetch selected location delivery hours
      */
     fetchLocationDeliveryHour() {
        const url = `${Env.loginUrl}/${apiVersion}/location-delivery-hours/all`;
        return AuthApis.getApi(url, null)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
    },

    /**
      * Create location delivery hour
      */
     createLocationDeliveryHour(data) {
        const url = `${Env.loginUrl}/${apiVersion}/location-delivery-hours/create`;
        return AuthApis.postApi(url, null, data)
         .then(res => res)
         .catch(err => {
           ApiHelper.detectStatusAndRedirect(err);
           return err;
         });
    },

    removeLocationDeliveryHour(){
      const url = `${Env.loginUrl}/${apiVersion}/location-delivery-hours/remove`;
        return AuthApis.deleteApi(url, null)
         .then(res => res)
         .catch(err => {
           ApiHelper.detectStatusAndRedirect(err);
           return err;
         });
    }



}



