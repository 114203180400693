/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import store from '@/store/index';
import ProductApis from '@/controllers/ProductApis';
import ApiHelper from '@/controllers/ApiHelpers';
import router from '@/router';

export default {
  /**
    * Fetch all products
    */
  fetchList(page = 1, perPage = 10, search='', locationId='') {
      let url = `inventory-transfer/list?page=${page}&perPage=${perPage}`;
      if(search) url+=`&searchTerm=${search}`
      if(locationId) url+=`&locationId=${locationId}`
      return ProductApis.getApi(url, null)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
      });
  },

  getTransfer(id, locationId, toLocationId) {
    const location = store.getters['location/selected']
    let url = `/inventory-transfer/${id}?locationId=${locationId}&toLocationId=${toLocationId}`;
    return ProductApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
    });
  },

  addProducts(data){
    let url = `/inventory-transfer/addProducts`;
    return ProductApis.postApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
    });
  },

  /**
      * Fetch all products with location
      */
  fetchAllLocationProduct(location, params) {
    const url = `/product/admin/location/${location}?availableOnly=false&isPagination=false&includeRetired=false${params?'&'+params:''}`;
    return ProductApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
 },

 bulkUpdate(data){
  const url = `/inventory-transfer/update/products`;
    return ProductApis.putApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
 },

 createTransfer(data){
  const url = `/inventory-transfer`;
  return ProductApis.postApi(url, null, data)
    .then(res => res)
    .catch(err => {
      ApiHelper.detectStatusAndRedirect(err);
      return err;
  });
 },

 updateTransfer(id, data){
  const url = `/inventory-transfer/${id}`;
  return ProductApis.putApi(url, null, data)
    .then(res => res)
    .catch(err => {
      ApiHelper.detectStatusAndRedirect(err);
      return err;
  });
 },
 deleteProduct(data){
  const url = `/inventory-transfer/removeProduct`;
  return ProductApis.postApi(url, null, data)
    .then(res => res)
    .catch(err => {
      ApiHelper.detectStatusAndRedirect(err);
      return err;
  });
 },

}