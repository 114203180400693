<template>
    <div class="">
      <div class="text-white flex-1 col-span-1 border-0 rounded-t-xl overflow-hidden">
        <div class="bg-theme-green px-4 py-2 text-white">
          <div class="flex justify-between items-center">
            <div class="font-bold tracking-wider leading-tight text-2xl mt-0 capitalize">
              {{ form.integrationProvider.value ? form.integrationProvider.value : 'Add New Access Key'}}
            </div>
            <div class="flex  items-center">
              
              <!-- <mdicon @click.prevent="clearComponent" size="32" class="close-icon flex justify-end cursor-pointer hover:opacity-60 mb-2 " name="close" /> -->
              <button v-if="form.integrationProvider.value && (form.integrationProvider.value != 'clover')" @click.prevent="addNew()" class="h-10 hover:bg-theme-darkgreen border-0 hover:text-white rounded-md cursor-pointer text-green-400 bg-white py-2 px-4 uppercase font-semibold">
                SAVE
              </button>
              <template v-else>
                <button v-if="cloverVerifyUrl && form.integrationProvider.value"  @click.prevent="openUrlWindow()" class="h-10 hover:bg-theme-darkgreen border-0 hover:text-white rounded-md cursor-pointer text-green-400 bg-white py-2 px-4 uppercase font-semibold">
                  VERIFY
                </button>


                <!-- <a v-if="cloverVerifyUrl" class="is-flex hover:bg-theme-darkgreen border-0 hover:text-white rounded-md cursor-pointer text-green-400 bg-white py-2 px-4 uppercase font-semibold" target="_blank" @click.prevent="openUrlWindow()" href="#">
                    Verify
                  </a> -->
                  
                <span v-else class="h-[40px] w-[80px]">&nbsp;</span>
              </template>
              <span  @click.prevent="clearComponent" class="cursor-pointer rounded-md  text-white py-2 pl-2  hover:opacity-70">CLOSE</span>
            </div>
          </div>
        </div>
      

        <div class="pt-3 bg-[#2B2C32] px-[16px] py-[16px] rounded-lg">
          <div class="flex xl:flex-row flex-col gap-3 items-start mb-3">
          
              <div class="w-full xl:w-1/2">
                <div :class="'input-wrapper ' + (form.integrationProvider.error_type)">
                  <label class="text-xs mb-1">Name:</label>
                  <select @change="validateFields(['integrationProvider'])" v-model="form.integrationProvider.value" class="block rounded bg-gray-black w-full">
                     <option disabled value="">--Select--</option>
                    <option value="clover">Clover</option>
                  </select>
                  <p class="field-error label-text mt-1" v-if="form.integrationProvider.error_message" v-text="form.integrationProvider.error_message"></p>
                </div>
              </div>
  
              <div class="w-full xl:w-1/2">
                <div :class="'input-wrapper ' + (form.merchantId.error_type)">
                  <label class="text-xs mb-1">Merchant ID:</label>
                  <input placeholder="Merchant ID (Optional)"  @keyup="validateFields(['merchantId'])" v-model="form.merchantId.value" class="block rounded bg-gray-black w-full" type="text" />
                  <p class="field-error label-text mt-1" v-if="form.merchantId.error_message" v-text="form.merchantId.error_message"></p>
                </div>
              </div>
            </div>
  
            <div class="flex xl:flex-row flex-col gap-3 items-start mb-3">
  
            </div>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  import {defineComponent, ref} from "vue"
  import { mapGetters, mapActions, mapMutations } from "vuex";
  import LocationPaymentProcessorApis from "@/controllers/LocationPaymentProcessorApis";
  import LocationCloverApis from "@/controllers/LocationCloverApis";
  import LocationIntegrationApis from "@/controllers/LocationIntegrationApis";
  import { useSnackbar } from "vue3-snackbar";
  import Helpers from '@/helpers';
  import Env from '@/env';
  
  export default defineComponent({
    name: "LocationPosAdd",
    components: {
     
    },
    setup() {
      const snackbar = useSnackbar();
      const form = ref({
        integrationProvider: {
          value: 'clover',
          error_type: '',
          error_message: '',
          validations: ['required'],
        },
        integrationKey: ['access_token','access_token_expiration','refresh_token','refresh_token_expiration'],
        sort: 1,
        merchantId: {
          value: '', //E7JT323858QX1
          error_type: '',
          error_message: '',
          validations: [],
        },
       
        active: false,
      });
  
      const cloverAccess = ref({
          verified: false,
          accessCode: '',
      });
      
      return {
        form,
        snackbar,
        cloverAccess
      };
    },
    props:{
      clearComponent: {
        type: Function,
        required: true,
      },
    },
  
    watch:{
      
     
      // merchantId cannot have whitespaces
      'form.merchantId.value'(val){
        if(val) {
          val = val.replaceAll(/\s/g,'').trim();
          this.form.merchantId.value = val;
        } else {
          this.form.merchantId.value = '';
        }
      },
  
      vg_cloverDetails: {
        handler: async function(cloverData) {
          if(cloverData && cloverData.code){
            this.cloverAccess.verified = true;
            this.cloverAccess.accessCode = cloverData.code;
            await this.getCloverAccessToken(cloverData.code);
            this.cloverWindow.close();
          }
  
        },
        deep:true
      },
  
    },
  
    computed: {
        cloverVerifyUrl() {
          if(this.vg_selectedLocation?.id){
            let redirectUrl = Env.cloverRedirectUrl + '/' + this.vg_selectedLocation.id
            // return 'https://sandbox.dev.clover.com/oauth/v2/authorize?client_id=3ERGK6HHS1MWG&merchant_id=E7JT323858QX1&redirect_uri=http://localhost:8080/clover-response/location';
            return `${Env.cloverVerificationUrl}/oauth/v2/authorize?client_id=${Env.cloverClientId}&merchant_id=${this.form.merchantId.value}&redirect_uri=${redirectUrl}`;
          }
        },
  
        ...mapGetters({
            vg_cloverDetails: "clover/details",
            vg_selectedLocation: 'location/selected'
        }),
    },
  
  
    mounted() {
      this.vm_mutateCloverDetails({
        clover_response: {},
      })
   
    },
   
    methods: {
  
      async getCloverAccessToken(code) {
        let res = await LocationCloverApis.generateCloverAccessToken(code);
        if(res.status == 201) {
          let addRes = await LocationIntegrationApis.createMany({integrations:[{
            "integrationProvider": this.form.integrationProvider.value,
            "integrationKey":  this.form.integrationKey[0],
            "integrationValue": res.data.payload.access_token,
            "sort": this.form.sort
          },{
            "integrationProvider": this.form.integrationProvider.value,
            "integrationKey":  this.form.integrationKey[1],
            "integrationValue": res.data.payload.access_token_expiration.toString(),
            "sort": this.form.sort
          },{
            "integrationProvider": this.form.integrationProvider.value,
            "integrationKey":  this.form.integrationKey[2],
            "integrationValue": res.data.payload.refresh_token,
            "sort": this.form.sort
          },{
            "integrationProvider": this.form.integrationProvider.value,
            "integrationKey":  this.form.integrationKey[3],
            "integrationValue": res.data.payload.refresh_token_expiration.toString(),
            "sort": this.form.sort
          }]});
          this.snackbar.add({
            type: 'info',
            text: addRes.status == 201 ? addRes.data.message : Helpers.catchErrorMessage(addRes.response.data),
            duration: 3000,
          })
          this.clearComponent();
          this.emitter.emit("refresh-location-access-token-list", true);
        }
  
      },
      
       /**
       * Validation captures
       */
      async validateFields(fields) {
        let res = await Helpers.validateFormFields(this.form, fields);
        // capture the fields
        this.form = res.data;
        return res.error ? false : true;
      },
  
  
      /**
       * Add 
       */
      async addNew() {
        let validate = await this.validateFields(['integrationValue']);
        if(!validate) {
          return ;
        }
        this.va_spinner(true);
        let res = await LocationIntegrationApis.create(Helpers.getFormKeyValue(this.form));
        this.va_spinner(false);
          this.snackbar.add({
          type: 'info',
          text: res.status == 201 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
          duration: 3000,
        })
        if(res.status == 201 ) {
          this.clearComponent();
          this.emitter.emit("refresh-location-access-token-list", true);
        }
      },
  
      openUrlWindow() {
        this.cloverWindow = window.open(this.cloverVerifyUrl, "", "width=500,height=500");
      },
      
      ...mapMutations({
        vm_mutateCloverDetails: "clover/detailsMutate",
      }),
  
      ...mapActions({
        va_spinner: 'spinner/setSpinner',
      })
    },
  });
  
  </script>