

import {defineComponent, ref} from "vue"
import BrandingApis from '@/controllers/BrandingApis';
import { mapGetters, mapActions, mapMutations } from "vuex";
import Helpers from '@/helpers';
import { useSnackbar } from "vue3-snackbar";
import BusinessApis from "@/controllers/BusinessApis";
import FileManager from '@/components/manager/filemanager.vue'

export default defineComponent({
  name: "GeneralSettings",
  components: {
    FileManager
  },

  setup() {
      const snackbar = useSnackbar();
      const branding = ref({
        id: '',
      });
      const loading = ref(false);
      const businessForm = ref({
        name: {
          value: '',
          error_type: '',
          error_message: '',
          validations: ['required', 'min:3'],
        },
        logo: {
          value: ''
        }
      });
      const form = ref({
        primaryColor: '',
        secondaryColor: '',
        logo: '', 
        sharing: false,
      });

    return {
      businessForm,
      form,
      branding,
      loading,
      snackbar
    };
  },

  computed: {
    can_edit_general_settings() {
      return Helpers.getMyPermission('can_edit_general_settings')
    },
    ...mapGetters({
      va_activeBusiness: 'auth/activeBusiness',
    }),
  },
 

  async mounted() {
    this.loading = true;
    await this.fetchBusinessBranding();
    this.loading = false;
  },

  methods: {

    /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.businessForm, fields);
      // capture the fields
      this.businessForm = res.data;
      return res.error ? false : true;
    },

    /**
     * Fetch business branding
     */
    async fetchBusinessBranding() {
      let res = await BrandingApis.fetchBusinessBranding(this.va_activeBusiness.id);
      if(res.status == 200) {
        this.branding = res.data.payload;
        this.form.primaryColor = res.data.payload.primaryColor;
        this.form.secondaryColor = res.data.payload.secondaryColor
        this.form.logo = res.data.payload.logo
      } else {
        // Defaults
        this.form.primaryColor = '#FFFFFF';
        this.form.secondaryColor = '#FFFFFF';
        this.form.logo = '';
      }
      this.businessForm.name.value = this.va_activeBusiness.name;
    },

    /**
     * Create business branding
     */
    async createBusinessBranding() {
      // validate business field
      let validate = await this.validateFields(['name']);
      if(!validate) {
        return ;
      }
      this.va_spinner(true);
     
      // rest data need to update on business branding
      let res = await BrandingApis.createBusinessBranding({
        logo: this.form.logo,
        primaryColor: this.form.primaryColor,
        secondaryColor: this.form.secondaryColor,
      });

      // name should be update in business api
      let updateBusinessRes = await BusinessApis.updateBusiness(this.va_activeBusiness.id, {
        name: this.businessForm.name.value.trim(),
        slug: this.va_activeBusiness.slug,
      })
      if(updateBusinessRes.status == 200) {
        this.vm_mutateUserActiveBusiness(updateBusinessRes.data.payload);
      }
      await this.fetchBusinessBranding();

      this.va_spinner(false);
      this.snackbar.add({
        type: 'info',
        text: res.status == 201 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
       

    },

    /**
     * Update business branding
     */
    async updateBusinessBranding() {
      // validate business field
      let validate = await this.validateFields(['name']);
      if(!validate) {
        return ;
      }
      this.va_spinner(true);
     
      // rest data need to update on business branding
      let res = await BrandingApis.updateBusinessBranding(this.branding.id, {
        logo: this.form.logo,
        primaryColor: this.form.primaryColor,
        secondaryColor: this.form.secondaryColor,
      });
      // name should be update in business api
      let updateBusinessRes = await BusinessApis.updateBusiness(this.va_activeBusiness.id, {
        name: this.businessForm.name.value.trim(),
        slug: this.va_activeBusiness.slug,
      })
      if(updateBusinessRes.status == 200) {
        this.vm_mutateUserActiveBusiness(updateBusinessRes.data.payload);
      }
      this.va_spinner(false);
      this.snackbar.add({
        type: 'info',
        text: res.status == 200 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })

    },

     ...mapMutations({
      vm_mutateUserActiveBusiness: "auth/mutateActiveBusiness",
    }),

    ...mapActions({
      va_spinner: 'spinner/setSpinner',
      va_setUserActiveBusiness: "auth/setUserActiveBusiness",
    })

  },
});

