
import Helpers from '@/helpers';
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
import List from '@/views/CashMgmt/Vault/CashVault/List.vue';
import Add from '@/views/Membership/Program/Badges/Add.vue';
import Update from '@/views/Membership/Program/Badges/Update.vue';
import Nav from "@/views/Membership/Program/Nav.vue";
import AddCash from '@/views/CashMgmt/Vault/CashVault/AddCash.vue'
import ViewCash from '@/views/CashMgmt/Vault/CashVault/ViewCash.vue'
import BankDeposit from '@/views/CashMgmt/Vault/CashVault/BankDeposit.vue'
import CloseVault from '@/views/CashMgmt/Vault/CashVault/CloseVault.vue'
import SafeAudit from '@/views/CashMgmt/Vault/CashVault/SafeAudit.vue'
import ViewSafeAudit from '@/views/CashMgmt/Vault/CashVault/ViewSafeAudit.vue'

export default defineComponent({
  name: "CashVault",
  props: ['totalBalance'],
  components: {
    Nav,
    List,
    Add,
    Update,
    AddCash,
    ViewCash,
    BankDeposit,
    CloseVault,
    SafeAudit,
    ViewSafeAudit,
  },
  

  setup() {
    const action = ref<object>({
      component: '',
      data: {},
    });
    const loyaltyProgram = <any>ref({
      id: null
    });
    const toggleAddDropDown = ref(false)
    const earningSetting = ref({});
    const loadingBadges = ref(false);
    const cashModal = ref({
      popup: false,
      type: ''
    })
    const search = ref('')
    return {
      action,
      earningSetting,
      loyaltyProgram,
      loadingBadges,
      toggleAddDropDown,
      cashModal,
      search
    };
  },

  watch : {
    'vg_selectedLocation'(val) {
      // if location selected clear component
      this.clearComponent();
    },
  },

  computed: {
    /**
     * View restriction
     */
     can_view_or_manage_vault() {
      return Helpers.getMyPermission('can_view_or_manage_vault')
    },

    ...mapGetters({
     vg_selectedLocation: 'location/selected'
    }),
  },

  async mounted() {
    this.emitter.on("reset-badges-component", data => {
      this.clearComponent();
    });
  },

  beforeDestroy() {
    this.emitter.off('reset-badges-component', () => {});
  },
 
  methods: {
    openModal(type){
      this.cashModal = {
        type: type,
        popup: true
      }
    },
    toggleDropDown(){
      this.toggleAddDropDown = !this.toggleAddDropDown
    },
    closeDropDown(){
      this.toggleAddDropDown = false
    },
    /**
     * Clear action
     */
    clearComponent() {
      this.action = {
        component: '',
        data: {},
      };
      let activeItem = document.getElementsByClassName('active-row');
      if(activeItem.length > 0){
        activeItem[0].classList.remove("active-row");
      }
    },

    /**
     * Change add / update component
     */
    addUpdate(val: any) {
      this.action = val;
    },

    /**
     * Capture selected item
     */
    selectedItem(item) {
      console.log(item)
      if(item.transactionType=='audit' || item.transactionType=='safe audit' || item.transactionType=='float' || item.transactionType=='close vault'){
        this.addUpdate({
          component: 'ViewSafeAudit', 
          data: item
        })
      }else{
        this.addUpdate({
          component: 'ViewCash', 
          data: item
        })
      }
    },

    closeCreate(reload){
      this.cashModal.popup = false
      if(reload){
        this.emitter.emit('refresh-vault-list')
        this.emitter.emit('refresh-vault-balance')
      }
    },

    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  },
});

