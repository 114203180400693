/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import Env from '@/env';
const apiVersion = 'api/v1';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /**
   * List all program categories with product
   */
  listAll(program_id, addQuery:any = null) {
      var url = `${Env.loyaltyUrl}/${apiVersion}/programs/${program_id}/categories?products=true`;
      if(addQuery) {
        url += addQuery;
      }

    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Fetch loyalty branding
   */
  getById(program_id) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/programs/${program_id}/branding`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Update loyalty branding
   */
  update(program_id, data) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/programs/${program_id}/branding`;
    return AuthApis.patchApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * add / or update program category
   */
  upsertProgramCategoryAction(program_id, category_id, data) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/programs/${program_id}/category/${category_id}/actions`;
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch(async (err) => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

}