export default {
    namespaced: true,
    state: {
      completed: false,
    },
    getters: {
      completed: state => state.completed,
    },
    mutations: {
      mutateAudit(state, data) {
        state.completed = data;
      },
    },
    actions: {
      setAudit(context, data) {
        context.commit('mutateAudit', data);
      },
    },
  };
  