
import  { defineComponent , HtmlHTMLAttributes, ref} from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Env from '@/env';
import Helpers from '@/helpers';
import LocationInfoApis from '@/controllers/LocationInfoApis';


export default defineComponent({
  name: "LocationInfoIndex",
  setup() {
    const form = <any>ref({
        name:  {
          value: '',
          error_type: '',
          error_message: '',
          validations: ['required', 'min:2'],
        },
        slug: {
          value: '',
          error_type: '',
          error_message: '',
          validations: ['required', 'min:2'],
        },
        licenseId: {
          value: '',
          error_type: '',
          error_message: '',
          validations: [],
        },
        corporate: true,
        internalId: {
          value: '',
          error_type: '',
          error_message: '',
          validations: ['required', 'min:3'],
        },
        address1: {
          value: '',
          error_type: '',
          error_message: '',
          validations: ['required'],
        },
        address2: '',
        city: {
          value: '',
          error_type: '',
          error_message: '',
          validations: ['required', 'min:3'],
        },
        postalCode: {
          value: '',
          error_type: '',
          error_message: '',
          validations: ['required', 'min:3'],
        },
        country: {
          value: '',
          error_type: '',
          error_message: '',
          validations: ['required'],
        },
        timezone: {
           value: '',
          error_type: '',
          error_message: '',
          validations: ['required'],
        },
        lat: '',
        long: '',
        phone: {
          value: '',
          error_type: '',
          error_message: '',
          validations: ['required', 'phone'],
        },
        email: {
          value: '',
          error_type: '',
          error_message: '',
          validations: ['required', 'email'],
        },
        province:  {
          value: '',
          error_type: '',
          error_message: '',
          validations: ['required'],
        },
    });
    const watchCount = ref(1);
    const loading = ref(false);
    const autocomplete = <any>ref({})
    return {
      watchCount,
      loading,
      form,
      autocomplete
    };
  },

  computed: {
    
    timeZones() {
      return Env.timeZones;
    },
    webStoreUrl() {
      return Env.webStoreUrl;
    },
    ...mapGetters({
      vg_selectedLocation: <any>'location/selected',
      vg_activeBusiness: <any>'auth/activeBusiness',
    }),
    
  },

  watch: {
    'form.slug.value'(val) {
      if(val) {
        val = val.toLowerCase();
        val = val.replaceAll(/\s/g,'').trim();
        this.form.slug.value = val;
      } else {
        this.form.slug.value = '';
      }
    },
    'form.phone.value'(val) {
      if(val){
        let newval = val.toString();
        this.form.phone.value = newval;
      }
    },
    'form.province.value'() {
      // do not validate when the data is assign on mount
      if(this.watchCount > 1){
        this.validateFields(['province']);
      }
    },
    'form.city.value'() {
      if(this.watchCount > 1){
        this.validateFields(['city']);
      }
    },
    'form.postalCode.value'() {
      if(this.watchCount > 1){
        this.validateFields(['postalCode']);
      }
    },
    'form.country.value'() {
      if(this.watchCount > 1){
        this.validateFields(['country']);
      }
    },

    'vg_selectedLocation': async function() {
      this.watchCount = 1;
      this.loading = true;
      await this.fetchLocationInfo()
      this.loading = false;
    },
  },

  async mounted() {


    const self = this;
    
    // need to load dom before execute so need to add 1 s time
    setTimeout(async () => {
      let ele:any = document.getElementById("address1");
      // const loader = new Loader({
      //   apiKey: 'AIzaSyBJs2f0TNNui_OcRHgVRTIsJAvGQ0EB7oA',
      //   libraries: ["drawing", "places"]
      // });

      this._gloader
      .load()
      .then((google) => {
        self.autocomplete = new google.maps.places.Autocomplete(ele, {
          componentRestrictions: { country: "ca" }

        });
        self.autocomplete.addListener('place_changed', self.setPlace)
      })
      .catch(e => {
        console.log(e)
        // do something
      });

    }, 3000)
      
    this.watchCount = 1;
    this.fetchLocationInfo();
    this.emitter.on("validate-location-info", data => {
      this.validateFieldsAndReturnData();
    });
  },

  beforeDestroy() {
   this. emitter.off('validate-location-info', () => {})
  },

  methods: {
    acceptNumber() {
        var x = this.form.phone.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.form.phone.value = !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    

    /**
     * Fetch location info
     */
    async fetchLocationInfo( reset = false) {
      this.loading = true;
      let res = await LocationInfoApis.fetchLocationInfo();
      if(res.status == 200) {
        this.form = await Helpers.assignFormValues(this.form, res.data.payload);
      } else {
        this.form = await Helpers.assignFormValues(this.form, {});
      }
      this.loading = false;
      this.$nextTick(() => {
        let address1ID = <any>document.getElementById('address1');
        address1ID.value = this.form.address1?.value;
      });
      this.watchCount = 2;
    },

    /**
     * Validates form fields and return the data to push on Api
     */
    async validateFieldsAndReturnData() {
      let validate = await this.validateFields(['name', 'slug',  'internalId', 'address1', 'city', 'province', 'postalCode', 'country', 'timezone', 'phone', 'email']);
      if(!validate) {
        return ;
      }
      let data = await <any>Helpers.getFormKeyValue(this.form);
      data.phone = data.phone.toString(); // conver phone to string
      data.address2 = data.address2 ? data.address2 : null; // need to pass null instead of empty string
      this.emitter.emit("location-info-submit", data);
    },

    /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      this.form = res.data;
      return res.error ? false : true;
    },

    async resetPlace() {
     let address1ID = <any>document.getElementById('address1');
      if (address1ID.value == undefined || address1ID.value == '') {
        this.form.postalCode.value = '';
        this.form.province.value = '';
        this.form.city.value = '';
        this.form.country.value  = '';
        this.form.lat = '';
        this.form.long = '';
        this.form.address1.value = ''; //vue bind
        await this.validateFields(['name', 'slug', 'internalId', 'address1', 'city', 'province', 'postalCode', 'country', 'timezone', 'phone', 'email']);
      }
    },

    /**
     * Set place
     */
    setPlace() {
      
      let addressData = this.autocomplete.getPlace();
      this.form.address1.value = addressData.name;
      let address1 = <any>document.getElementById('address1');
      address1.value = addressData.name; // for element
      this.form.address1.value = addressData.name; // vue bind
      this.form.address2 = addressData.vicinity
      this.validateFields(['address1']);
      this.form.postalCode.value = Helpers.getDetailFromGmapAutoComplete(addressData.address_components, 'postal_code');
      this.form.province.value = Helpers.getDetailFromGmapAutoComplete(addressData.address_components, 'administrative_area_level_1') ? Helpers.getDetailFromGmapAutoComplete(addressData.address_components, 'administrative_area_level_1', 'short_name') : Helpers.getDetailFromGmapAutoComplete(addressData.address_components, 'administrative_area_level_2', 'short_name');
      this.form.city.value = Helpers.getDetailFromGmapAutoComplete(addressData.address_components, 'locality');
      this.form.country.value  = Helpers.getDetailFromGmapAutoComplete(addressData.address_components, 'country', 'long_name');
      let lat;
      let long;
      if (typeof addressData.geometry.location.lat === 'function') {
        lat = addressData.geometry.location.lat();
        long = addressData.geometry.location.lng();
      } else {
        lat = addressData.geometry.location.lat;
        long = addressData.geometry.location.lng;
      }
      this.form.lat = lat;
      this.form.long = long;
    },

    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })
  }
});
