/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import Env from '@/env';
const apiVersion = 'api';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /**
   * Fetch all locations
   */
  fetchAllLocations(header:any = null) {
    const url = `${Env.loginUrl}/${apiVersion}/locations/all`;
    return AuthApis.getApi(url, header)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Create  location
   */
  createLocation(data) {
    const url = `${Env.loginUrl}/${apiVersion}/locations/create`;
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

}