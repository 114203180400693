<template>
   <div class="pt-[16px] px-[24px] max-w-full">
    <div class="flex justify-between shadow-xl box-border items-center text-white h-[62px] rounded p-4 bg-[#2B2C32] px-8 py-1 mb-2">
      <h2 class="font-bold text-2xl leading-[30px] tracking-[0.05px]">Vault</h2>
      <div class="flex items-center">
        <div class="flex items-center border-r border-[#464851] pr-4">
          <h5 class="font-bold text-xl leading-6 tracking-[0.15px] mr-2">Total In Vault</h5>
          <span class="font-bold text-xl leading-6 tracking-[0.15px] text-[#2BA66B]">${{totalBalance}}</span>
        </div>
        <div class="flex items-center pl-4">
          <h5 class="font-bold text-xl leading-6 tracking-[0.15px] mr-2">Open Date</h5>
          <span class="font-bold text-xl leading-6 tracking-[0.15px] text-[#9E9E9E]">{{openDate}}</span>
        </div>
      </div>
    </div>
    <ul class="flex bg-[#2B2C32] rounded px-8 py-1 mb-1">
      <li :class="'mr-4 uppercase' + (active == 'current' ? ' active' : '')">
        <router-link :to="{ name: 'CurrentVault' }" href="#" class="inline-block text-theme-white tracking-widest text-sm text-white font-semibold px-2">
          CURRENT VAULT
        </router-link>
      </li>
      <li :class="active == 'transaction' ? ' active' : ''">
        <router-link :to="{ name: 'TransactionHistory' }" class="text-theme-theme-white text-white tracking-widest text-sm font-semibold">
         TRANSACTION HISTORY
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import {defineComponent, ref} from "vue"
import VaultApis from "@/controllers/VaultApis";
import { mapGetters } from "vuex";
import moment from 'moment'

export default defineComponent({
  props: {
    active: {
      required: true,
      type: String,
    },
    callback: {
      required: true,
      type: Function,
    }
  },
  setup() {
    const totalBalance = ref(0)
    const openDate = ref(null)

    return {totalBalance, openDate}
  },
  mounted(){
     this.getData()

     this.emitter.on("refresh-vault-balance", data => {
      this.getData();
    });
  },
  beforeDestroy() {
    this.emitter.off('refresh-vault-balance', () => {});
  },
  watch: {
    vg_selectedLocation(){
      this.getData()
    },
  },
  computed: {
    ...mapGetters({
      vg_selectedLocation:  'location/selected'
    })
  },
  methods: {
    formatCurrency(val){
      if(!val) return 0

      return val.toFixed(2)
    },
    formatDate(date){
      if(!date) return

      return moment(date).format('YYYY-MM-DD')
    },
    async getData(){
      let res = await VaultApis.getVaultBalance(this.vg_selectedLocation.id)
      if(res.status===200){
        this.totalBalance = this.formatCurrency(res.data.payload.vaultBalance)
        this.openDate = this.formatDate(res.data.payload.openDate)
        this.$emit('updateBalance', this.totalBalance)
      }
    }
  }
})
</script>

<style scoped>
  .active:before {
    content: '';
    position: absolute;
    background: #2BA66B;
    width: 100%;
    height: 4px;
    bottom: -8px;
  }
  .active {
    position:relative;
  }
</style>