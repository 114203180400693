<template>
    <div class="rounded-[10px] bg-[#2B2C32] w-full">
        <div class="text-[24px] text-white px-4 py-4">
            <div class="flex justify-between">
                <span class="font-semibold">Purchase Orders</span>
            
                <div class="flex gap-3">
                    <button disabled class="flex flex-row items-center disabled:bg-field-black disabled:text-theme-modalbg text-white bg-theme-green rounded px-4 uppercase">
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19 9H15V3H9V9H5L12 16L19 9ZM11 11V5H13V11H14.17L12 13.17L9.83 11H11ZM19 20V18H5V20H19Z" class="fill-gray-700"/>
                        </svg>
                        <span class="text-sm">Export</span>
                    </button>
                    <button @click="openPurchaseOrderUpload" class="flex flex-row items-center disabled:bg-field-black disabled:text-theme-modalbg text-white bg-theme-green rounded px-4 uppercase">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="white"/>
                        </svg>
                        <span class="text-sm">Upload Purchase Order</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="w-full mt-6 px-2 py-4">
            <div class="rounded-[10px] bg-[#2B2C32] w-full py-4">
                <new-table-lite
                    :is-static-mode="true"
                    :is-slot-mode="true"
                    :is-loading="table.loading"
                    :class="'w-full admin-table admin-table-custom'"
                    :columns="table.columns"
                    :rows="table.rows"
                    :total="table.totalRecords"
                    :sortable="table.sortable"
                    :page-size="table.pagesize"
                    :page="table.page"
                    @row-clicked="selectedRow"
                    :pageOptions='table.pageOptions'
                    :messages="{
                        pagingInfo: 'Total {2}',
                        pageSizeChangeLabel: 'Items per Page',
                        gotoPageLabel: 'Go to page',
                        noDataAvailable: 'No purchase order uploaded yet',
                    }"
                >
                    <template v-slot:location="data">
                        <span>{{data?.value?.location?.name}}</span>
                    </template>
                    <template v-slot:createdAt="data">
                        <span>{{data?.value?.createdAt ? readAbleDate(data.value.createdAt): ''}}</span>
                    </template>
                </new-table-lite>
            </div>
        </div>
    </div>
</template>
<script>
import Env from '@/env';
import { defineComponent, ref} from 'vue'
import NewTableLite from '@/components/table/tableLite.vue'
import ProductApis from '@/controllers/BusinessProductApis'
import { mapGetters, mapActions, mapMutations } from "vuex";
import moment from 'moment-timezone'

export default defineComponent({
    components: {
        NewTableLite
    },
    setup() {
        const table = ref({
          loading: true,
          columns: [
            {label: 'Invoice # (Shipment ID)', field: 'shipmentId', sortable:false, isKey:true },
            {label: 'Location', field:'location', sortable: false},
            {label: 'Date', field:'createdAt', sortable:false},
            // {label: 'Employee', field:'employee', sortable:false},
          ],
          rows: [],
          totalRecords: 0,
          page: 1,
          sortable: {
            order: "",
            sort: "",
          },
           pagesize: Env.defaultPageSize,
           pageOptions: Env.pageList,
           count: 0
        })

        return {table}
    },
    mounted(){
        this.fetchList()

        this.emitter.on("reloadPurchaseOrderList", this.reloadPurchaseOrderList);
    },
    beforeDestroy() {
        this.emitter.off("reloadPurchaseOrderList", this.reloadPurchaseOrderList);
    },
    computed: {
        ...mapGetters({
            vg_selectedLocation: 'location/selected',
        }),
    },
    methods: {
         openPurchaseOrderUpload(){
            this.toggleAddDropDown = false
            this.$emit('togglePurchaseOrderUploadPop', true)
        },
        readAbleDate(str) {
            if(!str) return ''
            let defaultTimeZone = this.vg_selectedLocation.timezone?this.vg_selectedLocation.timezone:'America/Toronto';
            return moment(str).tz(defaultTimeZone).format('YYYY-MM-DD')
        },
        async reloadProductList(editItem){
            this.fetchList()
        },
        async fetchList(){
            this.table.loading = true
            console.log(ProductApis)
            let res = await ProductApis.purchaseOrderList(this.vg_selectedLocation.id)
            if(res.status===200){
                this.table.rows = res.data.payload
                this.table.pagesize = Env.defaultPageSize
                this.table.totalRecords = res.data.payload.length
            }else{
                this.table.rows = []
                this.table.pagesize = Env.defaultPageSize
                this.table.totalRecords = 0
            }
            this.table.loading = false
        },
    }
})
</script>