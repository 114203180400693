
import  { defineComponent , ref} from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import LocationPaymentProcessorApis from "@/controllers/LocationPaymentProcessorApis";
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';
import ReturnConfirmModal from "@/components/general/dialog/ReturnConfirmModal.vue";

export default defineComponent({
  components: {
    ReturnConfirmModal,
  },
  props: {
    iscomponentactive: {
      type: Boolean,
      required: false,
    },
    selectedItemCallback: {
      type: Function,
      required: true,
    },
    clearComponent: {
      type: Function,
      required: true,
    },
    addComponent: {
      type: Function,
      required: true,
    },
    
  },
  setup() {
    const confirmStatusObj = ref({
      modal: false,
      obj: {},
    });
    const activeItemObject = ref<any>({});
    const initialLoading = ref<Boolean>(false);
    const locationPosList = ref([]);
    const snackbar = useSnackbar();
    return  {
      activeItemObject,
      initialLoading,
      confirmStatusObj,
      locationPosList,
      snackbar
    };
  },

  async mounted() {
    this.initialLoading = true;
    await this.fetchList();
    this.initialLoading = false;
    this.emitter.on("refresh-location-payment-processor-list", data => {
      this.fetchList();
    });
    this.emitter.on("reset-location-payment-processor-component", data => {
      this.activeItemObject = {};
    });
  },

  beforeDestroy() {
    this.emitter.off('refresh-location-payment-processor-list', () => {});
    this.emitter.off('reset-location-payment-processor-component', () => {});
  },

  watch: {
    'vg_selectedLocation': async function (val) {
      // if location selected is changed fetch the data
      this.initialLoading = true;
      await this.fetchList();
      this.initialLoading = false;
    },
  },

  computed: {
    ...mapGetters({
      vg_selectedLocation: 'location/selected'
    }),
  },

  methods: {
    /**
     * Fetch list
     */
    async fetchList() {
      let res = await LocationPaymentProcessorApis.fetchAll();  
      if(res.status == 200) {
        this.locationPosList = res.data.payload;
      }
    },

    /**
     * Set active
     */
    setActiveItem(item) {
      this.activeItemObject = item;
      this.selectedItemCallback(item);
    },
    /**
     * Close Confirm status modal
     */
    closeConfirmStatusModal() {
      this.confirmStatusObj = {
        modal: false,
        obj: {},
      };
    },
    /**
     * Open confirm satus modal
     */
    openConfirmStatusModal(obj) {
      this.confirmStatusObj = {
        modal: true,
        obj,
      };
    },

     /**
     * Toggle business status
     */
    async togglePosStatus(obj) {

      this.va_spinner(true);
      let res = await LocationPaymentProcessorApis.update(obj.id, {
        active: obj.active ? false : true,
      });
      this.va_spinner(false);
        this.snackbar.add({
        type: 'info',
        text: res.status == 200 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
      if(res.status == 200 ) {
        this.closeConfirmStatusModal();
        this.clearComponent();
        this.emitter.emit("refresh-location-payment-processor-list", true);
      }
    },

    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })
    
  }
});
