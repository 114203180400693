/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */
import Env from '@/env';
const apiVersion = 'api';
import ProductApis from '@/controllers/ProductApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /**
   * Fetch all  location pos station
   */
  fetchByLocationId(locationId) {
    const url = `/markup-price/listBy/${locationId}`;
    return ProductApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * Add new  location pos station
   */
  bulkCreate(data) {
    const url = `/markup-price/bulkCreate`;
    return ProductApis.postApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * Update  location pos station
   */
  bulkUpdate(locationId, data) {
    const url = `/markup-price/bulkUpdate/${locationId}`;
    return ProductApis.putApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

}