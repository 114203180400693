
import {defineComponent, ref} from "vue"
import LocationDeliverySettingApi from "@/controllers/LocationDeliverySetting";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Helpers from '@/helpers';
import Nav from "./Nav.vue"
import { useSnackbar } from "vue3-snackbar";

export default defineComponent({
  name: "LocationPickup",
  components: {
    Nav
  },

  setup() {
      const snackbar = useSnackbar();
      const loading = ref(false);
      const has_setting = ref(false);
      const form = <any>ref({
        deliveryActive: false,
        enableAsap: true,
        deliveryTimeSlots: false,
        timeSlotDuration: {
          value: '',
          error_type: '',
          error_message: '',
          validations: ['required'],
        },
        orderPrepTime: {
          value: 30,
          error_type: '',
          error_message: '',
          validations: ['required'],
        },
        orderLimitPerTimeSlot: {
          value: 10,
          error_type: '',
          error_message: '',
          validations: ['required', 'positivenumber', 'maxval:99999'],
        },
        timeSlotPeriod: {
          value: 1,
          error_type: '',
          error_message: '',
          validations: ['required', 'positivenumber', 'maxval:99999'],
        },
        cancellationCutoff: {
          value: 10,
          error_type: '',
          error_message: '',
          validations: []
        },
        afterHoursOrdering: false,
        deliverySameAsStoreHours: false,
        payOnline: false,
        payAtDoorCc: false,
        payAtDoorCash: false,
      });

    return {
      form,
      loading,
      snackbar,
      has_setting
    };
  },

   watch : {
    'vg_selectedLocation'(val) {
      this.fetchLocationPickupSetting();
    },
    'form.orderLimitPerTimeSlot.value'(val) {
      this.form.orderLimitPerTimeSlot.value = val ? Math.trunc(val) : '';
    },

    'form.deliveryTimeSlots'(val) {
      if(val == false) {
        this.form = Helpers.removeValidationOnly(this.form, ['timeSlotDuration', 'orderPrepTime', 'orderLimitPerTimeSlot', 'timeSlotPeriod']);
      }else{
        this.form.enableAsap = false
      }
    },
    'form.enableAsap'(val){
      if(val){
        this.form.deliveryTimeSlots = false
      }
    }
  },

  computed: {
    ...mapGetters({
      vg_selectedLocation: 'location/selected'
    }),
  },
 

  async mounted() {
    this.loading = true;
    await this.fetchLocationPickupSetting();
    this.loading = false;
  },

  methods: {
    isInvalidToSave(){
      if(!this.form.deliveryActive) return false

      if(!this.form.enableAsap && !this.form.deliveryTimeSlots) return true

      if(!this.form.payOnline && !this.form.payAtDoorCash && !this.form.payAtDoorCc) return true

      return false
    },
    
    /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },

    preventNonNumbers(evt) {
      var ASCIICode = (evt.which) ? evt.which : evt.keyCode
      if(ASCIICode == 43 || ASCIICode == 45){
        return false;
      }
      return true;
    },

    /**
     * Fetch business branding
     */
    async fetchLocationPickupSetting() {
      this.loading = true;
      let res = await LocationDeliverySettingApi.fetchLocationDeliverySetting();
      this.loading = false;
      if(res.status == 200) {
        this.has_setting = true;
        this.form = <any>Helpers.assignFormValues(this.form, res.data.payload);
      } else {
        this.has_setting = false;
        this.form = <any>Helpers.assignFormValues(this.form, {});
      }
    },

    /**
     * Create business branding
     */
    async save() {
      // validate only if delivery timeslots
      let validate = true;
      if(this.form.deliveryTimeSlots == true) {
        validate = await this.validateFields(['timeSlotDuration', 'orderPrepTime', 'orderLimitPerTimeSlot', 'timeSlotPeriod']);
      }
    
      if(!validate) {
        return ;
      }
      if(this.has_setting) {
        this.updateSetting();
      } else {
        this.createSetting();
      }
    },

    /**
     * Create setting 
     */
    async createSetting() {
      this.va_spinner(true);
      let res = await LocationDeliverySettingApi.createLocationDeliverySetting(Helpers.getFormKeyValue(this.form));
      this.va_spinner(false);

      if(res.status == 201) {
        this.has_setting = true;
      }
      
      this.snackbar.add({
        type: 'info',
        text: res.status == 201 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
    },

    /**
     * Update setting 
     */
    async updateSetting() {
      this.va_spinner(true);
      let res = await LocationDeliverySettingApi.updateLocationDeliverySetting(Helpers.getFormKeyValue(this.form));
      this.va_spinner(false);
      this.snackbar.add({
        type: 'info',
        text: res.status == 200 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
    },

    ...mapMutations({

    }),

    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })

  },
});

