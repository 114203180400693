<template>
    <div class="text-white flex-1 col-span-1 border-0 rounded-t-xl overflow-hidden mt-1">
        <div class="pt-3 bg-[#2B2C32] px-[16px] py-[16px] rounded-xl">
            <div class="w-full">
                <div class="flex flex-row justify-between">
                    <div class="flex flex-row gap-2">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.6719 5.60156V17H11.4219V8.19531L8.71875 9.05469V7.28125L13.4297 5.60156H13.6719Z" fill="white"/>
                            <rect x="0.75" y="0.75" width="22.5" height="22.5" rx="11.25" stroke="#2BA66B" stroke-width="1.5"/>
                        </svg>
                        <span class="font-bold">Select the variant &amp; the lot</span>
                    </div>
                    <a @click.prevent="editVariantLot" v-if="lotTracking && selectedLot && selectedLot.id || !lotTracking && selectedVariant && selectedVariant.id" href="#" class="text-theme-green text-sm font-medium tracking-widest">EDIT</a>
                </div>
            </div>
            <div v-if="showVariantLot">
                <div class="text-grey font-bold mt-2">Variants</div>
                <div class="grid grid-cols-4 items-center gap-2 mt-3 mb-3">                         
                    <!-- default -->
                    <div class="input-wrapper">
                        <button @click="selectVariant(editItem)" :class="(selectedVariant && selectedVariant.id==editItem.id)?'bg-theme-green':'bg-theme-grey'" class="px-[40px] py-3 w-full rounded border border-theme-green text-white text-sm font-bold tracking-wider">{{editItem.variantName}}</button>
                    </div>
                    <!-- variants -->
                    <div class="input-wrapper" v-for="(variant, ind) in editItem.variants" :key="'var-'+ind">
                        <button @click="selectVariant(variant)" :class="(selectedVariant && selectedVariant.id==variant.id)?'bg-theme-green':'bg-theme-grey'" class="px-[40px] py-3 w-full rounded border border-theme-green text-white text-sm font-bold tracking-wider">{{variant.variantName}}</button>
                    </div>
                </div>
                <div v-if="lotTracking && editItem.globalLotTracking && selectedVariant.complianceType && selectedVariant.complianceType!='Accessory'">
                    <div class="text-grey font-bold">Lots</div>
                    <div v-if="lotList && lotList.length>0" class="grid grid-cols-4 items-center gap-2 mt-3 mb-3"> 
                        <div class="mt-1 input-wrapper" v-for="(lot, ind) in lotList" :key="'lot-'+ind">
                            <button @click="selectLot(lot)" :class="(selectedLot && selectedLot.id==lot.id)?'bg-theme-green':'bg-theme-grey'" class="py-1 w-full rounded border border-theme-green text-sm tracking-wider">
                                <div class="text-white font-bold self-center ">{{lot.batch_lot}}</div>
                                <div class="text-grey self-center">{{formatData(lot)}}</div>
                            </button>
                        </div>
                    </div>
                    <p v-else class="text-xs tracking-wider text-theme-878686 text-center py-3">Select a variant first</p>
                </div>
            </div>
            <div class="bg-theme-borderblack h-1 w-full my-4"></div>


            <div class="w-full">
                <div>
                    <div class="flex flex-row gap-2">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.9688 15.2422V17H8.1875V15.5L11.8672 11.5547C12.237 11.1432 12.5286 10.7812 12.7422 10.4688C12.9557 10.151 13.1094 9.86719 13.2031 9.61719C13.3021 9.36198 13.3516 9.11979 13.3516 8.89062C13.3516 8.54688 13.2943 8.2526 13.1797 8.00781C13.0651 7.75781 12.8958 7.5651 12.6719 7.42969C12.4531 7.29427 12.1823 7.22656 11.8594 7.22656C11.5156 7.22656 11.2188 7.3099 10.9688 7.47656C10.724 7.64323 10.5365 7.875 10.4062 8.17188C10.2812 8.46875 10.2188 8.80469 10.2188 9.17969H7.96094C7.96094 8.5026 8.1224 7.88281 8.44531 7.32031C8.76823 6.7526 9.22396 6.30208 9.8125 5.96875C10.401 5.63021 11.099 5.46094 11.9062 5.46094C12.7031 5.46094 13.375 5.59115 13.9219 5.85156C14.474 6.10677 14.8906 6.47656 15.1719 6.96094C15.4583 7.4401 15.6016 8.01302 15.6016 8.67969C15.6016 9.05469 15.5417 9.42188 15.4219 9.78125C15.3021 10.1354 15.1302 10.4896 14.9062 10.8438C14.6875 11.1927 14.4219 11.5469 14.1094 11.9062C13.7969 12.2656 13.4505 12.638 13.0703 13.0234L11.0938 15.2422H15.9688Z" fill="white"/>
                            <rect x="0.75" y="0.75" width="22.5" height="22.5" rx="11.25" stroke="#2BA66B" stroke-width="1.5"/>
                        </svg>
                        <span class="font-bold">Select the reason &amp; the amount</span>
                    </div>
                    <div v-if="!showVariantLot">
                        <div class="grid grid-cols-2 items-center gap-2">
                            <div class="input-wrapper mt-2">
                                <div class="text-xs mb-1"># of Items to be Adjusted</div>
                                <div class="grid grid-cols-3 tracking-wider border-none rounded bg-gray-black w-full text-sm text-theme-white px-2 ">
                                    <input v-model.number="adjustment" min="0" oninput="validity.valid||(value='');" placeholder="1" type="number" class="border-0 focus:border-0 focus:ring-0 bg-gray-black" />
                                    <span class="col-span-2 text-grey self-center justify-self-end">Inventory Count: {{stock}}</span>
                                </div>
                            </div>
                            <div class="input-wrapper pt-5">
                                <label for="item-range" class="flex items-center cursor-pointer ">
                                    <span class="mr-2 text-white text-sm tracking-wider"> Remove </span>
                                    <input v-model="status" value="range" type="checkbox" id="item-range" class="hidden peer">
                                    <div class="w-12 h-6 bg-theme-red peer-focus:outline-none peer-focus:ring-4 
                                    peer-focus:ring-theme-green dark:peer-focus:ring-theme-green relative rounded-full peer 
                                    dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] 
                                    after:absolute  after:bg-white after:border-gray-400 after:border after:rounded-full 
                                    after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-theme-green"></div>
                                    <span class="ml-2 text-white text-sm tracking-wider"> Add </span>
                                </label>
                            </div>
                            <template v-if="status">
                                <div class="input-wrapper" v-for="(btnName, ind) in add" :key="'add-'+ind">
                                    <button @click="selectReason(btnName)" :class="(btnName==reason)?'bg-theme-green':''" class="px-[40px] py-3 w-full rounded border border-theme-green text-white text-sm font-bold tracking-wider">{{btnName}}</button>
                                </div>
                            </template>
                            <template v-else>
                                <template v-if="editItem.complianceType==='accessory'">
                                    <div class="input-wrapper" v-for="(btnName, ind) in accessoryRemove" :key="'remove-'+ind">
                                        <button @click="selectReason(btnName)" :class="(btnName==reason)?'bg-theme-green':''" class="px-[20px] py-3 w-full rounded border border-theme-green text-white text-sm font-bold tracking-wider capitalize">{{btnName}}</button>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="input-wrapper" v-for="(btnName, ind) in remove" :key="'remove-'+ind">
                                        <button @click="selectReason(btnName)" :class="(btnName==reason)?'bg-theme-green':''" class="px-[20px] py-3 w-full rounded border border-theme-green text-white text-sm font-bold tracking-wider capitalize">{{btnName}}</button>
                                    </div>
                                </template>
                            </template>
                            <div v-if="reason=='Other'">
                                <select v-if="status" v-model="event" class="relative w-full h-12 bg-gray-black border border-theme-green rounded focus:border-theme-green focus:ring-0" :class="event?'text-white':'text-theme-878686'">
                                    <option value="" disabled>Select One</option>
                                    <template v-for="each in addOther" :key="each">
                                        <option :value="each">
                                            {{each}}
                                        </option>
                                    </template>
                                </select>
                                <select v-else v-model="event" class="relative w-full h-12 bg-gray-black border border-theme-green rounded focus:border-theme-green focus:ring-0" :class="event?'text-white':'text-theme-878686'">
                                    <option value="" disabled>Select One</option>
                                    <template v-for="each in removeOther" :key="each">
                                        <option :value="each">
                                            {{each}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                        </div>
                        <div v-if="!status" class="input-wrapper mt-2" :key="'remove-'+ind">
                            <button @click="selectReason('Destruction')" :class="('Destruction'==reason)?'bg-red-300':''" class="px-[20px] py-3 w-full rounded border border-theme-red text-theme-red text-sm font-bold tracking-wider capitalize">Record Time of Destruction</button>
                        </div>
                        <div v-if="reason=='Transfer in' || reason=='Transfer out'" class="input-wrapper mt-3">
                            <div class="text-xs">Retail Licence Number</div>
                            <input v-model="counterPartyId" placeholder="Counter party id" class="tracking-wider border-none h-[30px] focus:outline-0 focus:ring-0 rounded bg-gray-black w-full text-sm text-theme-white px-2 py-1" />
                        </div>
                        <div class="input-wrapper mt-3">
                            <div class="text-xs">Notes</div>
                            <textarea v-model="note" placeholder="Whatever helps!" rows="4" class="tracking-wider focus:outline-0 focus:ring-0 border-none rounded bg-gray-black w-full text-sm text-theme-white px-2 py-1"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-theme-borderblack h-1 w-full my-4"></div>

            <div class="flex justify-center">
                <button @click="createInventory" :disabled="!adjustment || !reason || (reason=='Other' && !event) || ((reason=='Transfer in' || reason=='Transfer out') && !counterPartyId)" class="rounded bg-theme-green disabled:bg-theme-grey disabled:text-grey disabled:cursor-not-allowed hover:bg-theme-green-300 cursor-pointer px-28 py-3 text-sm tracking-wider font-medium">CONFIRM</button>
            </div>
        </div>
    </div> 
</template>
<script>
import Helpers from '@/helpers';
import moment from 'moment'
import {mapGetters, mapMutations} from 'vuex'
import { defineComponent, ref } from 'vue'
import { useSnackbar } from "vue3-snackbar"
import ProductApi from '@/controllers/BusinessProductApis'

export default defineComponent({
    props: ['editItem'],
    setup() {
        const snackbar = useSnackbar();
        const showVariantLot = ref(true)
        const status = ref(false)
        const add = ref(['Transfer in', 'Purchase order', 'Refund', 'Cancellation', 'Other'])
        const remove = ref(['Transfer out', 'Stolen', 'Damaged', 'Lost', 'Expired', 'Recall', 'marketing', 'Other'])
        const accessoryRemove = ref(['Transfer out', 'Stolen', 'Damaged', 'Lost', 'Expired', 'Recall', 'marketing', 'staff', 'Other'])
        const addOther = ref([
            'Physical Inventory Count (Operationally Initiated)', 
            'Physical Inventory Count (AGCO Initiated)', 
            'Data Entry Error', 
            'Sold Product Handling Error', 
            'Receiving Error', 
            'Cycle Count', 
            'Internal Audit'
        ])
        const removeOther = ref([
            'Physical Inventory Count (Operationally Initiated)', 
            'Physical Inventory Count (AGCO Initiated)', 
            'Data Entry Error', 
            'Sold Product Handling Error', 
            'Receiving Error', 
            'Cycle Count', 
            'Internal Audit'
        ])
        const lotList = ref([])
        const inventoryList = ref([])
        const lotTracking = ref(false)
        const editInventory = ref({})
        const loadingInventory = ref(false)
        const selectedVariant = ref({})
        const selectedLot = ref({})
        const adjustment = ref('')
        const reason = ref('')
        const event = ref('')
        const note = ref('')
        const counterPartyId = ref('')
        const stock = ref(null)

        return {snackbar, showVariantLot, status, add, remove, accessoryRemove, addOther, removeOther, lotList, inventoryList, editInventory, lotTracking, loadingInventory, selectedVariant, selectedLot, adjustment, reason, event, note, counterPartyId, stock}
    },
    mounted(){
        if(this.editItem.globalLotTracking){
            this.lotTracking = this.editItem.lotTracking
        }else{
            this.lotTracking = false
        }
        this.stock = this.editItem.ppiStock
    },
    watch: {
        status(){
            if(!this.loadingInventory){
                this.reason = ''
            }
        },
        reason(val){
            if(val!=='Transfer in' || val!='Transfer out'){
                this.counterPartyId = ''
            }
        },
        selectedLot(){
            //check if inventory already exist
            if(this.inventoryList.length>0){
                let self = this
                this.loadingInventory = true
                this.adjustment = ''
                this.status = false
                this.reason = ''
                this.note = ''
                setTimeout(function(){
                    self.loadingInventory = false
                }, 400)
            }
        }
    },
    computed:{
        ...mapGetters({
            vg_selectedBusiness: 'auth/activeBusiness',
            vg_selectedLocation: 'location/selected'
        })
    },
    methods: {
        editVariantLot(){
            this.showVariantLot = true
        },
        formatData(lot){
            if(lot && lot.packaged_on){
                return moment(lot.packaged_on).format('YYYY-MM-DD')
            }
        },
        async selectVariant(variant){
            this.selectedVariant = variant
            this.lotTracking = variant.lotTracking
            if(this.lotTracking && this.editItem.globalLotTracking && variant.complianceType!='Accessory'){
                this.vm_spinner(true)
                await this.getLotList(variant)
                await this.getInventoryList()
                this.vm_spinner(false)
            }else{
                await this.getStock()
                this.showVariantLot = false
            }
        },
        async getStock(){
            let data = {locationId: this.vg_selectedLocation.id, productIds:[this.selectedVariant.id]}
            let res = await ProductApi.fetchPricingByProductId(data)
            if(res.data?.payload[0]){
                this.stock = res.data.payload[0].stock
            }
        },
        async getLotList(variant){
            this.lotList = []
            if(this.editItem?.id){
                let lotParams = {locationId: this.vg_selectedLocation.id, productId: variant.id, gtin: null, lotOrder: 'desc'}
                let res = await ProductApi.fetchLotByProductId(lotParams)
                if(res.data?.payload?.batchUploadDetails){
                    this.lotList = res.data.payload.batchUploadDetails
                }
            }
        },
        async getInventoryList(){
            this.inventoryList = []
            if(this.editItem?.id){
                let inventoryData = {productId: this.selectedVariant.id}
                let params = ''
                if(this.vg_selectedLocation?.id){
                    params='&locationId='+this.vg_selectedLocation.id
                }
                let res = await ProductApi.listInventory(params, inventoryData)
                if(res.data?.payload?.inventoryAdjustments){
                    this.inventoryList = res.data.payload.inventoryAdjustments
                }
            }
        },
        selectLot(lot){
            this.stock = lot.current_nou
            this.selectedLot = lot
            this.showVariantLot = false
        },
        selectReason(reason){
            if(reason!=='Other') this.event = ''
            this.reason = reason
        },
        async createInventory(){
            this.vm_spinner(true)
            let data = {
                locationId: this.vg_selectedLocation.id,
                productId:this.selectedVariant.id,
                batchNo:this.selectedLot.batch_lot,
                adjustment:this.adjustment,
                batchDetailId: this.selectedLot.id,
                direction:this.status?"add":"remove",
                reason: this.reason,
                event: this.event,
                note:this.note,
                counterPartyId: this.counterPartyId
            }
            let res = null
            if(this.editInventory?.id){
                res = await ProductApi.updateInventory(this.editInventory.id, data)
                this.snackbar.add({
                    type: 'info',
                    text: res.status == 200 ? "Product inventory has been adjusted successfully!" : Helpers.catchErrorMessage(res.response.data),
                    duration: 3000,
                })
            }else{
                res = await ProductApi.addInventory(data)
                this.snackbar.add({
                    type: 'info',
                    text: res.status == 201 ? "Product inventory has been adjusted successfully!" : Helpers.catchErrorMessage(res.response.data),
                    duration: 3000,
                })
            }
            this.vm_spinner(false)
            if(res.status==201 || res.status==200){
                this.emitter.emit('reloadProduct', this.editItem.id)
                this.emitter.emit('reloadProductList', true)
                this.emitter.emit("showDefaultVariant", true)
            }

        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }
})
</script>
