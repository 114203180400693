
import {defineComponent, ref} from "vue"

export default defineComponent({
  props: {
    active: {
      required: true,
      type: String,
    },
    callback: {
      required: true,
      type: Function,
    }
    
  }
})
