import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9c553ae4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav" }
const _hoisted_2 = { class: "flex bg-[#2B2C32] rounded-full px-8 py-1" }
const _hoisted_3 = { class: "mr-4" }
const _hoisted_4 = { class: "mr-4" }
const _hoisted_5 = {
  key: 0,
  class: "mr-4"
}
const _hoisted_6 = { class: "mr-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, {
          class: _normalizeClass('text-white text-sm' + (_ctx.$route.name == 'LocationIntegrationPayment' ? ' active' : '')),
          to: { name: 'LocationIntegrationPayment'}
        }, {
          default: _withCtx(() => [
            _createTextVNode("PAYMENTS")
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _createElementVNode("li", _hoisted_4, [
        _createVNode(_component_router_link, {
          class: _normalizeClass('text-white text-sm uppercase' + (_ctx.$route.name == 'LocationIntegrationsCrm' ? ' active' : '')),
          to: { name: 'LocationIntegrationsCrm'}
        }, {
          default: _withCtx(() => [
            _createTextVNode("CRM ")
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      (_ctx.can_manage_compliance_settings)
        ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
            _createVNode(_component_router_link, {
              class: _normalizeClass('text-white text-sm' + (_ctx.$route.name == 'LocationComplianceSettings' ? ' active' : '')),
              to: { name: 'LocationComplianceSettings'}
            }, {
              default: _withCtx(() => [
                _createTextVNode(" COMPLIANCE ")
              ]),
              _: 1
            }, 8, ["class"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("li", _hoisted_6, [
        _createVNode(_component_router_link, {
          class: _normalizeClass('text-white text-sm uppercase' + (_ctx.$route.name == 'Marketplace' ? ' active' : '')),
          to: { name: 'Marketplace'}
        }, {
          default: _withCtx(() => [
            _createTextVNode("Marketplace ")
          ]),
          _: 1
        }, 8, ["class"])
      ])
    ])
  ]))
}