<template>
    <div>
        <!-- lot list -->
        <each-lot v-for="(each, ind) in lotList" @updatedLot="updatedLot" :lotNamesExceptThis="lotNameExceptThis(lotList, ind)" :editVariant="editVariant" :lot="each" :key="ind" :productCbdThcType="productCbdThcType" />

        <new-lot v-if="newLot" @close="closeNew" :editVariant="editVariant" :productCbdThcType="productCbdThcType" />

        <!-- add lot -->
        <div v-else class="flex justify-center mt-4">
            <button @click="addLot" class="rounded bg-theme-green uppercase text-white px-[140px] py-[14px] text-sm tracking-wider font-medium">new lot</button>
        </div>
    </div>
</template>
<script>
import { ref, defineComponent } from 'vue'
import eachLot from './each.vue'
import newLot from './new.vue'
import {mapGetters, mapMutations} from 'vuex'
import ProductApi from '@/controllers/BusinessProductApis'

export default defineComponent({
    props: ['asn', 'editVariant', 'productCbdThcType'],
    components: {
        eachLot,
        newLot
    },
    setup() {
        const newLot = ref(false)
        const lotList = ref([])

        return {newLot, lotList}
    },
    mounted(){
        this.getLotList()
    },
    watch: {
        editVariant(){
            this.getLotList()
        }
    },
    computed:{
        ...mapGetters({
            vg_selectedBusiness: 'auth/activeBusiness',
            vg_selectedLocation: 'location/selected'
        })
    },
    methods: {
        updatedLot(){
            this.getLotList()
        },
        async getLotList(){
            console.log("update lot list")
            this.lotList = []
            if(this.editVariant?.id){
                let lotParams = {locationId: this.vg_selectedLocation.id, productId: this.editVariant.id, gtin: null, lotOrder: 'desc'}
                let res = await ProductApi.fetchLotByProductId(lotParams)
                if(res.data?.payload?.batchUploadDetails){
                    this.lotList = res.data.payload.batchUploadDetails
                }
            }
        },
        addLot(){
            this.newLot = true
        },
        closeNew(){
            this.newLot = false
            this.getLotList()
        },
        lotNameExceptThis(lots, ind) {
            let batchLotsNames = [];
             lots.forEach((l,i) => {
                if(ind != i && l.batch_lot) {
                    batchLotsNames.push(l.batch_lot)
                }
            });
            return batchLotsNames;
        }
    }
})
</script>
