/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import Env from '@/env';
const apiVersion = 'api';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /**
   * Fetch location banner message
   */
  fetchLocationBannerMessage() {
    const url = `${Env.loginUrl}/${apiVersion}/location-web-store-settings/show`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * Create location banner message
   */
  createLocationBannerMessage(data) {
    const url = `${Env.loginUrl}/${apiVersion}/location-web-store-settings/create`;
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * Update Location banner message
   */
  updateLocationBannerMessage(data) {
    const url = `${Env.loginUrl}/${apiVersion}/location-web-store-settings/update`;
    return AuthApis.patchApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * Delete location banner message
   */
  deleteLocationBannermMessage(locationHolidayId) {
    const url = `${Env.loginUrl}/${apiVersion}/location-holidays/${locationHolidayId}/remove`;
    return AuthApis.deleteApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


}