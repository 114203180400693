<template>
    <div>
        <!-- <template v-if="vg_selectedLocation.id"> -->
        <div>
            <ul class="flex bg-[#2B2C32] rounded-full px-8 py-1">
                <li class="mr-4">
                    <router-link :class="'text-theme-white tracking-wide text-sm' + ($route.name == 'LocationDeliverySetting' ? ' active' : '')" :to="{ name: 'LocationDeliverySetting'}">
                        DELIVERY SETTING
                    </router-link>
                </li>
                <li class="mr-4">
                    <router-link  :class="'text-theme-white tracking-wide text-sm' + ($route.name == 'LocationDeliveryZone' ? ' active' : '')" :to="{ name: 'LocationDeliveryZone'}">
                        DELIVERY ZONES
                    </router-link>
                </li>
                <li class="mr-4">
                    <router-link  :class="'text-theme-white tracking-wide text-sm' + ($route.name == 'LocationDeliveryHours' ? ' active' : '')" :to="{ name: 'LocationDeliveryHours'}">
                        DELIVERY HOURS
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>
<style scoped>
  .active:before {
    content: '';
    position: absolute;
    background: #2BA66B;
    width: 100%;
    height: 4px;
    bottom: -10px;
  }
  .active{
    position:relative;
  }
</style>