
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
import Helpers from '@/helpers';
import { useSnackbar } from "vue3-snackbar";
import Nav from "@/views/Membership/Program/Nav.vue";
import List from "@/views/Membership/Program/EarningPoints/List.vue";
import Add from "@/views/Membership/Program/EarningPoints/Add.vue";
import Update from "@/views/Membership/Program/EarningPoints/Update.vue";
import CategoryWithProductModal from '@/views/Membership/Program/EarningPoints/CategoryWithProductModal.vue';
import LoyaltyProgramApis from "@/controllers/LoyaltyProgramApis";
import LoyaltyEarnSettingApis from "@/controllers/LoyaltyEarnSettingApis";
import LoyaltyProgramProductApis from "@/controllers/LoyaltyProgramProductApis";

export default defineComponent({
  name: "MembershipProgramEarningPoints",
  components: {
    Nav,
    CategoryWithProductModal,
    List,
    Add,
    Update
  },

  setup() {
    const snackbar = useSnackbar();
    const form = ref({
      earn_on: {
        value: 'item',
        error_type: '',
        error_message: '',
        validations: ['required', 'min:3'],
      },
      points_per_dollar: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'numeric'],
      },
      min_purchase_amount: {
        value: '0',
        error_type: '',
        error_message: '',
        validations: [], // 'required_min_0', 'numeric'
      },
      
    });
    const loyaltyProductCountObj = ref({ earnable_items: 0 });
    const categoryWithproductObj = ref({
      active: false,
    });
    const loyaltyProgram = <any>ref({});
    const earningSetting = <any>ref({});
    const action = ref<object>({
      component: '',
      data: {},
    });
    const loadingEarning = ref(false);

    return {
      action,
      form,
      snackbar,
      categoryWithproductObj,
      loyaltyProgram,
      earningSetting,
      loadingEarning,
      loyaltyProductCountObj
    };
  },

  computed: {

    /**
     * Edit restriction
     */
     can_edit_membership_details() {
      return Helpers.getMyPermission('can_edit_membership_details')
    },
    
    ...mapGetters({
   
    }),
  },
 

  async mounted() {
    this.loadingEarning = true;
    let loyaltyProgramRes = await LoyaltyProgramApis.getProgramByCurrentActiveBusiness();
    if(loyaltyProgramRes.status == 200 || loyaltyProgramRes.status == 201) {
      this.loyaltyProgram = loyaltyProgramRes.data;
      this.fetchLoyaltyProductCount();
      let res = await LoyaltyEarnSettingApis.fetchSetting(loyaltyProgramRes.data.id);
     if(res.status == 200) {
        this.earningSetting = res.data;
        this.form.earn_on.value = res.data.earn_on
        this.form.points_per_dollar.value = res.data.points_per_dollar
        this.form.min_purchase_amount.value = res.data.min_purchase_amount
     }
    }
    this.loadingEarning = false;
  },

  methods: {

    /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },

    /**
     * Save
     */
    async save() {
       // check first step 
      let validate = await this.validateFields(['earn_on', 'points_per_dollar']);
      if(!validate) {
        return ;
      }
      this.va_spinner(true);
      let data = {
        earn_on: this.form.earn_on.value,    
        points_per_dollar: this.form.points_per_dollar.value,
        min_purchase_amount: this.form.min_purchase_amount.value ? this.form.min_purchase_amount.value : 0
      }
      let res:any = {};
      let msg = '';
      if(this.earningSetting.id) {
        // update
        res = await LoyaltyEarnSettingApis.update(this.loyaltyProgram.id, data);
        msg = res.status == 200 ? res.data.msg : Helpers.catchErrorMessage(res.response.data)
      } else {
        // add
        res = await LoyaltyEarnSettingApis.create(this.loyaltyProgram.id, data);
        msg = res.status == 201 ? res.data.msg : Helpers.catchErrorMessage(res.response.data)
      }
      
      if(res.status == 200 || res.status == 201) {
        this.earningSetting = res.data;
      }
      

      this.va_spinner(false);
      this.snackbar.add({
        type: 'info',
        text: msg,
        duration: 3000,
      })
    },


    /**
     * Close select category modal
     */
    closeCategoryWithProductModal() {
     this.categoryWithproductObj.active = false;
    },

    /**
     * Get data selected from category modal
     */
    async setCategoriesWithProduct(data) {
      this.va_spinner(true);
      let res = await LoyaltyEarnSettingApis.assignProducts(this.loyaltyProgram.id, data);
      this.va_spinner(false);
      if(res.status == 200) {
        this.closeCategoryWithProductModal();
        this.fetchLoyaltyProductCount();
      }
      this.snackbar.add({
        type: 'info',
        text: res.status == 200 ? res.data.msg : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
      
    },

     /**
     * Clear action
     */
    clearComponent() {
      this.action = {
        component: '',
        data: {},
      };
      let activeItem = document.getElementsByClassName('active-row');
      if(activeItem.length > 0){
        activeItem[0].classList.remove("active-row");
      }
    },

    /**
     * Change add / update component
     */
    addUpdate(val: any) {
      this.action = val;
    },

    /**
     * Capture selected item
     */
    selectedItem(item) {
      this.addUpdate({
        component: 'Update', 
        data: item
      })
    },

    /**
     * Fetch loyalty prduct count
     */
    async fetchLoyaltyProductCount() {
      let loyaltyProgramProductCountRes = await LoyaltyProgramProductApis.programCount(this.loyaltyProgram.id);
      if(loyaltyProgramProductCountRes.status == 200) {
        this.loyaltyProductCountObj = loyaltyProgramProductCountRes.data;
      } else {
        this.loyaltyProductCountObj = { earnable_items: 0 };
      }
    },

    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })

  },
});

