<template>
    <div>
        <div class="text-white flex-1 col-span-1 border-0 rounded-t-xl overflow-hidden">
            <div class="bg-theme-green px-4 py-2 text-white">
                <div class="flex justify-between items-center" :class="(item?.BatchLot)?'pb-10':''">
                    <div v-if="item?.pmdProduct" class="font-bold tracking-wider leading-tight text-2xl mt-0">
                        {{item.pmdProduct.name}}
                    </div>
                    <div v-else class="font-bold tracking-wider leading-tight text-2xl mt-0">
                        Add New Product
                    </div>
                    <!-- <div @click="closeAdd" class="flex flex-col relative justify-between items-center"> -->
                        <span @click="closeAdd" class="cursor-pointer rounded-md  text-white py-2 pl-1  hover:opacity-70">CLOSE</span>
                        <!-- <span role="img" class=" close-icon flex justify-end cursor-pointer hover:opacity-60 text-4xl ml-3 mdi mdi-close">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.29844 19.1004L0.898438 17.7004L8.59844 10.0004L0.898438 2.30039L2.29844 0.900391L9.99844 8.60039L17.6984 0.900391L19.0984 2.30039L11.3984 10.0004L19.0984 17.7004L17.6984 19.1004L9.99844 11.4004L2.29844 19.1004Z" fill="white"/>
                            </svg>
                        </span> -->
                    <!-- </div> -->
                </div>
            </div>
            <div class="pt-3 bg-[#2B2C32] px-[16px] py-[16px] rounded-lg">
                <!-- pmdProduct product -->
                <div v-if="item?.SKU || item?.SKU">
                    <div class="flex gap-3 items-center  xl:flex-row flex-col">
                        <div class="w-full">
                            <div class="input-wrapper">
                                <div class="text-xs mb-1">GTIN</div>
                                <input disabled :value="(item?.EachGTIN)?item.EachGTIN:''"  class="block tracking-wider border-none rounded bg-gray-black w-full text-sm placeholder-theme-878686 text-theme-white" type="text">
                            </div>
                        </div>
                        <div class="w-full">
                            <div class="input-wrapper">
                                <div class="text-xs mb-1">Lot</div>
                                <input disabled :value="(item?.BatchLot)?item.BatchLot:''"  class="block tracking-wider border-none rounded bg-gray-black w-full text-sm placeholder-theme-878686 text-theme-white" type="text">
                            </div>
                        </div>
                        <div class="w-full">
                            <div class="input-wrapper">
                                <div class="text-xs mb-1">Size</div>
                                <input disabled :value="(item?.pmdProduct?.size)?item.pmdProduct.size:''"  class="block tracking-wider border-none rounded bg-gray-black w-full text-sm placeholder-theme-878686 text-theme-white" type="text">
                            </div>
                        </div>
                    </div>
                    <div v-if="forceMerge" class="border border-theme-green bg-theme-green/[0.3] rounded-lg px-2 py-2 mt-4">
                        <div>
                            <business-search-select placeholder="Search" @select="selectForceProductItem" />
                        </div>
                        <div class="text-sm py-2" v-if="noLocationProduct?.length>0">This might be a variant of one of these suggestions:</div>
                        <div class="text-sm py-2 " v-else>Please search product you would like to merge</div>
                        <div class="mx-h-96 overflow-hidden px-2">
                            <div class="flex items-center" v-for="(eachNoLocation, ind) in noLocationProduct" :key="ind">
                                <input :id="`noLoc-${eachNoLocation.id}`" type="radio" v-model="mergeIndex" :value="eachNoLocation.id" name="no_reward" class="w-4 h-4  bg-transparent border-2 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                <label :for="`noLoc-${eachNoLocation.id}`" class="ml-2 text-sm font-normal tracking-wider text-white dark:text-gray-300">{{eachNoLocation.name}} ({{eachNoLocation.variantName}})</label>
                            </div>
                        </div>
                        <div class="grid grid-cols-2 gap-1 mt-2 mb-1">
                            <button @click="forceMerge=false" class="bg-theme-green hover:bg-theme-green-300 border-theme-green w-full border rounded uppercase  py-2">Back</button>
                            <button :disabled="!mergeIndex && !mergeProduct.id" class="w-full border border-theme-green rounded uppercase py-2" :class="(mergeIndex || mergeProduct.id)?'bg-theme-green hover:bg-theme-green-300 border-theme-green':'border-transparent bg-field-black text-theme-modalbg'" @click="confirmForceMerge">Confirm</button>
                        </div>

                    </div>
                    <div v-else class="border border-theme-orange bg-theme-orange/[0.1] rounded-lg px-2 py-1 mt-4">
                        <div class="flex flex-row">
                            <div class="pt-1 pr-2">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.325 11.175H10.825V4.85H9.325V11.175ZM10 15C10.2333 15 10.4292 14.9208 10.5875 14.7625C10.7458 14.6042 10.825 14.4083 10.825 14.175C10.825 13.9417 10.7458 13.7458 10.5875 13.5875C10.4292 13.4292 10.2333 13.35 10 13.35C9.76667 13.35 9.57083 13.4292 9.4125 13.5875C9.25417 13.7458 9.175 13.9417 9.175 14.175C9.175 14.4083 9.25417 14.6042 9.4125 14.7625C9.57083 14.9208 9.76667 15 10 15ZM10 20C8.58333 20 7.26667 19.7458 6.05 19.2375C4.83333 18.7292 3.775 18.025 2.875 17.125C1.975 16.225 1.27083 15.1667 0.7625 13.95C0.254167 12.7333 0 11.4167 0 10C0 8.6 0.254167 7.29167 0.7625 6.075C1.27083 4.85833 1.975 3.8 2.875 2.9C3.775 2 4.83333 1.29167 6.05 0.775C7.26667 0.258333 8.58333 0 10 0C11.4 0 12.7083 0.258333 13.925 0.775C15.1417 1.29167 16.2 2 17.1 2.9C18 3.8 18.7083 4.85833 19.225 6.075C19.7417 7.29167 20 8.6 20 10C20 11.4167 19.7417 12.7333 19.225 13.95C18.7083 15.1667 18 16.225 17.1 17.125C16.2 18.025 15.1417 18.7292 13.925 19.2375C12.7083 19.7458 11.4 20 10 20ZM10 18.5C12.3333 18.5 14.3333 17.6667 16 16C17.6667 14.3333 18.5 12.3333 18.5 10C18.5 7.66667 17.6667 5.66667 16 4C14.3333 2.33333 12.3333 1.5 10 1.5C7.66667 1.5 5.66667 2.33333 4 4C2.33333 5.66667 1.5 7.66667 1.5 10C1.5 12.3333 2.33333 14.3333 4 16C5.66667 17.6667 7.66667 18.5 10 18.5Z" fill="#FCB852"/>
                                </svg>
                            </div>
                            <div class="font-bold pb-2">This is a brand new product</div>
                        </div>
                        <div class="text-sm pb-8">We couldn’t find any potential master products that that could be associated with this GTIN.</div>
                        <div class="grid grid-cols-2 gap-1 mt-2 mb-1">
                            <button class="bg-theme-green hover:bg-theme-green-300 border-theme-green w-full border rounded uppercase  py-2" @click="doForceMerge">Force Merge</button>
                            <button class="w-full border border-theme-green rounded uppercase bg-theme-green/[0.33]  py-2">new product</button>
                        </div>
                    </div>
                </div>
                <!-- Autofill Manual gtin -->
                <div v-else class="flex gap-3 items-center  xl:flex-row flex-col">
                    <div class="w-full">
                        <div class="input-wrapper">
                            <label for="item-auto-fill" class="flex justify-center items-center cursor-pointer px-1 py-1">
                                <span class="mr-2 text-white text-base font-bold tracking-wider"> Autofill </span>
                                <input v-model="autoFill" type="checkbox" value="" id="item-auto-fill" class="sr-only peer">
                                <div class="w-12 h-6 bg-theme-green relative rounded-full peer 
                                dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] 
                                after:absolute  after:bg-white after:border-gray-400 after:border after:rounded-full 
                                after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-theme-green"></div>
                                <span class="ml-2 text-white text-base font-bold tracking-wider"> Manual </span>
                            </label>
                        </div>
                    </div>
                </div>

                <div v-if="autoFill?'':'hidden'">
                    <div class="bg-theme-borderblack h-1 w-full my-4"></div>
                    <div class="flex items-center">  
                        <search-select placeholder="Search by GTIN, SKU, or Name" @select="masterProduct" />
                    </div>
                </div>
                <div class="bg-theme-borderblack h-1 w-full my-4"></div>

                <div :class="(autoFill || searchResult.name)?'':'hidden'">
                    <div>
                        <div class="flex items-center">   
                            <div class="w-full flex justify-between">
                                <div class="input-wrapper flex items-center">
                                    <span class="product-num text-base font-bold">1</span>
                                    <h2 class=" text-base font-bold tracking-wider ml-2">Product Information</h2>
                                </div>
                                <a v-if="productValidated" @click.prevent="editProductInfo" href="#" class="text-theme-green text-sm font-medium tracking-widest">EDIT</a>
                            </div>
                        </div>
                        <div :class="current=='product'?'':'hidden'">
                            <product-info :defaults="searchResult" @validated="productEmitted" />
                        </div>
                    </div>

                    <div class="bg-theme-borderblack h-1 w-full my-4"></div>
                    <div class="flex items-center">   
                        <div class="w-full flex justify-between">
                            <div class="input-wrapper flex items-center">
                                <span class="product-num text-base font-bold">2</span>
                                <h2 class=" text-base font-bold tracking-wider ml-2">Variant &amp; Stock Information</h2>
                            </div>
                            <a v-if="variantValidated" @click.prevent="editVariantInfo" href="#" class="text-theme-green text-sm font-medium tracking-widest">EDIT</a>
                        </div>
                    </div>
                    <div :class="current=='variant'?'':'hidden'">
                        <variant-info :defaults="searchResult" :productForm="productForm" @validated="variantEmitted" />
                    </div>
                    <div class="bg-theme-borderblack h-1 w-full my-4"></div>
                    <div class="flex items-center">   
                        <div class="w-full flex justify-between">
                            <div class="input-wrapper flex items-center">
                                <span class="product-num text-base font-bold">3</span>
                                <h2 class=" text-base font-bold tracking-wider ml-2">Lot Information</h2>
                            </div>
                        </div>
                    </div>
                    <div :class="current=='lot'?'':'hidden'">
                        <lot-info :defaults="searchResult" :productForm="productForm" :variantForm="variantForm" @validated="lotEmitted" />
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
import { ref, defineComponent } from 'vue'
import {mapGetters, mapMutations} from 'vuex'
import SearchSelect from './searchSelect'
import BusinessSearchSelect from './businessSearchSelect'
import ProductInfo from '@/views/Products/Product/Add/ProductInfo'
import VariantInfo from '@/views/Products/Product/Add/VariantInfo'
import LotInfo from '@/views/Products/Product/Add/LotInfo'
import ProductApi from '@/controllers/BusinessProductApis'
import { useSnackbar } from "vue3-snackbar"
import StringHelper from '@/helpers/string'
import pick from 'lodash/pick'
import ObjectHelper from '@/helpers/object'
import Helpers from '@/helpers';

export default defineComponent({
    props: ['item', 'noLocationProduct', 'editPageMerge', 'fulfillment'],
    components: {
        BusinessSearchSelect,
        SearchSelect,
        ProductInfo,
        VariantInfo,
        LotInfo
    },
    setup() {
        const snackbar = useSnackbar();
        const autoFill = ref(true)
        const searchResult = ref({})
        const current = ref('')  // product, variant, lot
        const forceMerge = ref(false)
        const mergeIndex = ref('')
        const mergeProduct = ref({})
        const productForm = ref({})
        const productValidated = ref(false)
        const variantForm = ref({})
        const variantValidated = ref(false)
        const lotForm = ref({})
        const productCreated = ref({})
        const productTemplate = {
            name: '',
            brandId: '',
            categoryId: '',
            tax: '',
            strain: '',
            complianceType: '',
            sku: '',
            concentration_unit: '',
            description: '',
            variantName: '',
            dryCannabisEquivalency: '',
            thc_cbd_value_type: 'single',
            cost: '',
            sale_price: '',
            price: '',
            barcode: '',
            thc: '',
            thc_min: '',
            thc_max:'',
            cbd: '',
            cbd_min: '',
            cbd_max:'',
            badge_running: true,
            batch_lot: '',
            packaged_on: '',
            current_nou: '',
            lot_cost: '',
            caseSize: ''
        }

        return {
            snackbar, autoFill, searchResult, current, forceMerge, mergeIndex, mergeProduct,
            productForm, productValidated, variantForm, variantValidated, lotForm,
            productCreated, productTemplate
        }
    },
    mounted(){
        this.loadItems()
    },
    watch: {
        'item.EachGTIN'(){
            this.loadItems()
        }
    },
    computed: {
        ...mapGetters({
            vg_selectedBusiness: 'auth/activeBusiness',
            vg_selectedLocation: 'location/selected'
        })
    },
    methods: {
        loadItems(){
            this.current = 'product'
            if(this.item.SKU){
                // reset form
                this.productValidated = false
                this.variantValidated = false

                let items = []
                if(this.item.pmdProduct){
                    items = {...this.item.pmdProduct}
                    items.complianceType = StringHelper.capitalize(items.complianceType)   
                }
                items.brand_id = this.item.brand_id

                // get from file if not in pmd product
                if(items.concentration_unit===undefined || items.concentration_unit==='' || items.concentration_unit===null) items.concentration_unit = this.item.ConcentrationUnit
                // thc
                if(items.thc===undefined || items.thc==='' || items.thc===null){
                    items.thc = this.item.THC
                }
                // thc_min
                if(items.thc_min===undefined || items.thc_min==='' || items.thc_min===null){
                    items.thc_min = this.item.THC_MIN
                }
                // thc_max
                if(items.thc_max===undefined || items.thc_max==='' || items.thc_max===null){
                    items.thc_max = this.item.THC_MAX
                }
                // cbd
                if(items.cbd===undefined || items.cbd==='' || items.cbd===null){
                     items.cbd = this.item.CBD
                }
                // cbd_min
                if(items.cbd_min===undefined || items.cbd_min==='' || items.cbd_min===null){
                     items.cbd_min = this.item.CBD_MIN
                }
                // cbd_max
                if(items.cbd_max===undefined || items.cbd_max==='' || items.cbd_max===null){
                     items.cbd_max = this.item.CBD_MAX
                }

                if(this.item.TotalTerps  || this.item.TotalTerps===0) items.totalTerp = this.item.TotalTerps
                if(this.item.UOMCONVERSION) items.caseSize = this.item.UOMCONVERSION

                items.name = this.item.ItemName
                items.sku = this.item.SKU
                items.cost = this.item.UnitPrice
                items.batch_lot = this.item.BatchLot
                items.gtin = this.item.EachGTIN
                items.packaged_on = this.item.PackagedOnDate?new Date(this.item.PackagedOnDate):''
                items.current_nou = this.item.Shipped_Qty
                items.lot_cost = this.item.UnitPrice
                this.masterProduct(items)
            }else{
                this.uploadProductWithNoMaster(this.item)
            }
        },
        masterProduct(result){
            this.searchResult = ObjectHelper.pickWithDefault(
                result, 
                ['name','strain','complianceType','current_nou','sku','concentration_unit','description','thc_cbd_value_type','thc','thc_min','thc_max','cbd','cbd_min','cbd_max','cost','sale_price','batch_lot','packaged_on','lot_cost', 'totalTerp','caseSize'],
                ['', '', '', null, '', '', '', 'single', null, null, null, null, null, null, null, null, null, null, null, '']
            )
            this.searchResult.categoryId = result.categoryId
            this.searchResult.brandId = result.brand_id
            this.searchResult.brandName = this.item.Brand
            this.searchResult.tax = ''
            this.searchResult.barcode = result.gtin
            this.searchResult.variantName = result.size
            this.searchResult.dryCannabisEquivalency = result.weight_equivalent_dry_cannabis
            
            // for asn file
            if(result.image) this.searchResult.image = result.image
            if(result.aroma) this.searchResult.aroma = result.aroma
            if(result.terpene) this.searchResult.terpene = result.terpene
        },
        uploadProductWithNoMaster(item){
            this.searchResult = {...this.productTemplate}
            this.searchResult.barcode = item.EachGTIN
        },
        closeAdd(){
            this.$emit('close')
        },
        editProductInfo(){
            this.current = 'product'
        },
        productEmitted(form){
            this.current = 'variant'
            this.productValidated = true
            this.productForm = form
        },
        editVariantInfo(){
            this.current = 'variant'
        },
        variantEmitted(form){
            this.variantValidated = true
            this.variantForm = form
            this.current = 'lot'
            if(!this.vg_selectedLocation.id){
                this.createProduct()
            }
        },
        lotEmitted(form){
            this.lotForm = form
            this.createProduct()
        },
        doForceMerge(){
            this.forceMerge = true
        },
        selectForceProductItem(productObj){
            this.mergeIndex = null
            this.mergeProduct = productObj
        },
        async confirmForceMerge(){
            if(this.editPageMerge){
                this.vm_spinner(true)
                let variantIds = this.item.pmdProduct?.variants?.map(each => each.id)
                let mergeProduct = await ProductApi.mergeProductAssigningVariant(this.mergeProduct.id, {variantIds:[this.item.pmdProduct.productId, ...variantIds]})
                if(mergeProduct.status==201){
                    this.snackbar.add({
                        type: 'info',
                        text: "Product merged.",
                        duration: 3000,
                    })
                }
                this.$emit('close')
                this.emitter.emit('reloadProductList')
                this.vm_spinner(false)
            }else{
                if(this.mergeIndex){
                    let selectedNoLocationProduct = this.noLocationProduct.find(each => each.id==this.mergeIndex)
                    if(selectedNoLocationProduct?.id){
                        await this.addLotInProduct(selectedNoLocationProduct)
                        this.$emit('toggleAddEdit', {add: false, edit: true, editItem: selectedNoLocationProduct})
                    }
                }else{
                    await this.addLotInProduct(this.mergeProduct)
                    this.$emit('toggleAddEdit', {add: false, edit: true, editItem: this.mergeProduct})
                }
            }
        },
        async addLotInProduct(product){
            let productData = pick(product, ['name', 'categoryId', 'productFamily', 'sort', 'brandId', 'complianceType', 'description', 'strain', 'concentration_unit', 'sku', 'barcode'])
            product.thc_cbd_value_type = product.productCannabisProfile.thc_cbd_value_type
            product.thc = this.searchResult.thc
            product.thc_min = this.searchResult.thc_min
            product.thc_max = this.searchResult.thc_max
            product.cbd = this.searchResult.cbd
            product.cbd_min = this.searchResult.cbd_min
            product.cbd_max = this.searchResult.cbd_max

            let cannabisProfileData = {
                locationId: this.vg_selectedLocation.id,
                thc_cbd_value_type: product.productCannabisProfile.thc_cbd_value_type,
                thc: this.searchResult.thc,
                thc_min: this.searchResult.thc_min,
                thc_max: this.searchResult.thc_max,
                cbd: this.searchResult.cbd,
                cbd_min: this.searchResult.cbd_min,
                cbd_max: this.searchResult.cbd_max,
                fullfillmentTime: this.fulfillment?parseInt(this.fulfillment):null,
                totalTerps: this.searchResult.totalTerps
            }

            let productPricingData = {
                locationId: this.vg_selectedLocation.id,
                productId: product.id,
                price: product.productPricing.price,
                sale_price: product.productPricing.sale_price,
                cost: product.productPricing.cost,
                stock: parseInt(product.productPricing.stock),
            }

            let batchData = {
                locationId: this.vg_selectedLocation.id,
                batch_lot: this.item.BatchLot?this.item.BatchLot.toString():null,
                packaged_on: this.item.PackagedOnDate?new Date(this.item.PackagedOnDate):'',
                current_nou: parseInt(this.item.Shipped_Qty),
                badge_running: true,
                cost: this.item.UnitPrice?parseFloat(this.item.UnitPrice):product.productPricing.cost,
                thc: this.searchResult.thc,
                thc_min: this.searchResult.thc_min,
                thc_max: this.searchResult.thc_max,
                cbd: this.searchResult.cbd,
                cbd_min: this.searchResult.cbd_min,
                cbd_max: this.searchResult.cbd_max,
                on_hold: false,
                productId: product.id,
                stacked_barcode: this.item.GTINBarCode,
                shipmentId: this.item.ShipmentID?this.item.ShipmentID.toString():'',
                sku: this.item.SKU,
                totalTerp: this.item.TotalTerps
            }
            this.vm_spinner(true)
            let res = await ProductApi.editProductProfileCostBatch({
                product: productData,
                cannabisProfile: cannabisProfileData,
                pricing: productPricingData,
                batch: [batchData]
            })
            if(res.status==201){
                this.snackbar.add({
                    type: 'info',
                    text: res.status==201 ? `${this.item.Shipped_Qty} x ${product.name} added to your catalogue!` :  Helpers.catchErrorMessage(res.response.data),
                    duration: 3000,
                })
                this.$emit('added', this.item)
            }
            this.vm_spinner(false)
        },
        async createProduct(){
            let product = pick(this.productForm, ['name', 'categoryId', 'featuredCategoryIds', 'brandId', 'complianceType', 'description', 'strain', 'sku', 'caseSize'])
            let lot = pick(this.lotForm, ['thc', 'cbd', 'thc_min', 'thc_max', 'cbd_min', 'cbd_max', 'totalTerp'])
            let productData = {
                businessId: this.vg_selectedBusiness.id,
                variantName: this.variantForm.variantName,
                dryCannabisEquivalency: this.variantForm.dryCannabisEquivalency,
                barcode: this.variantForm.barcode?this.variantForm.barcode.toString():null,
                productFamily: 'cannabis',
                sort:1,
                concentration_unit: this.productForm.concentration_unit,
                ...product, 
            }

            let cannabisProfileData = {
                locationId: this.vg_selectedLocation.id,
                thc_cbd_value_type: this.variantForm.thc_cbd_value_type,
                thc: parseFloat(lot.thc),
                cbd: parseFloat(lot.cbd),
                thc_min: parseFloat(lot.thc_min),
                thc_max: parseFloat(lot.thc_max),
                cbd_min: parseFloat(lot.cbd_min),
                cbd_max: parseFloat(lot.cbd_max),
                totalTerp: StringHelper.emptyStringToNull(lot.totalTerp),
                fullfillmentTime: this.fulfillment?parseInt(this.fulfillment):null
            } 

            let productPricingData = {
                locationId: this.vg_selectedLocation.id,
                price:  parseFloat(this.variantForm.price),
                sale_price: this.variantForm.sale_price?parseFloat(this.variantForm.sale_price):parseFloat(this.variantForm.price),
                cost: parseFloat(this.variantForm.cost),
                stock: 0,
            }

            let batchData = {
                locationId: this.vg_selectedLocation.id,
                batch_lot: this.lotForm.batch_lot?.toString(),
                packaged_on: this.lotForm.packaged_on,
                badge_running: this.lotForm.badge_running,
                current_nou: parseInt(this.lotForm.current_nou),
                cost: parseFloat(this.lotForm.lot_cost),
                on_hold: false,
                totalTerp: StringHelper.emptyStringToNull(lot.totalTerp)
            }
            if(this.lotForm.cbd || this.lotForm.cbd===0){
                batchData.cbd = this.lotForm.cbd
            }
            if(this.lotForm.cbd_min){
                batchData.cbdMin = this.lotForm.cbd_min
            }
            if(this.lotForm.thc_max){
                batchData.cbdMax = this.lotForm.cbd_max
            }
            if(this.lotForm.thc_min){
                batchData.thcMin = this.lotForm.thc_min
            }
            if(this.lotForm.thc_max){
                batchData.thcMax = this.lotForm.thc_max
            }
            if(this.lotForm.thc || this.lotForm.thc===0){
                batchData.thc = this.lotForm.thc
            }
            // add shipping id, stacked_barcode, sku
            if(this.item.SKU){
                batchData.stacked_barcode = this.item.GTINBarCode
                batchData.shipmentId = this.item.ShipmentID?this.item.ShipmentID.toString():''
                batchData.sku = this.item.SKU
            }

            if(this.item.SKU){
                this.asnSaveProduct(productData, cannabisProfileData, productPricingData, batchData)
            }else{
                this.saveProduct(productData, cannabisProfileData, productPricingData, batchData)
            }
        },
        async asnSaveProduct(productData, cannabisProfileData, productPricingData, batchData){
            this.vm_spinner(true)
            productPricingData.stock = this.item.BatchLot?batchData.current_nou:this.item.Shipped_Qty
            let obj = {}
            if(productData.complianceType==="Accessory"){
                obj = {
                    product: productData,
                    pricing: productPricingData,
                }
            }else{
                obj = {
                    product: productData,
                    cannabisProfile: cannabisProfileData,
                    pricing: productPricingData,
                }
            }
            if(this.item.BatchLot){
                obj.batch = [batchData]
            }
            let res = await ProductApi.editProductProfileCostBatch(obj)
            if(res.status==201){
                this.productCreated = res.data.payload.product
                // add tax to product
                await ProductApi.addBulkProductTax({locationId: this.vg_selectedLocation.id, productId: res.data.payload.product.id, taxIds: this.productForm.tax})

                // add featured category
                if(productData.featuredCategoryIds?.length>0){
                    let featuredData = {
                        locationId: this.vg_selectedLocation.id,
                        categoryIds: productData.featuredCategoryIds
                    }
                    await ProductApi.setFeaturedCategory(this.productCreated.id, featuredData)
                }

                // asn bulk upload - add image
                if(this.searchResult.image){
                    let productImageData = {
                        productId: this.productCreated.id,
                        url: this.searchResult.image,
                        default: true,
                        sort: 1,
                    }
                    await ProductApi.createProductImage(productImageData)
                }

                // asn bulk upload - add terpene & aroma
                let aromaTerpenePromises = []
                let self = this
                if(this.searchResult?.aroma?.length>0){
                    this.searchResult.aroma.forEach(function(each){
                        aromaTerpenePromises.push(ProductApi.addAromas(self.productCreated.id, {aromaId: each.id}))
                    })
                }
                if(this.searchResult?.terpene?.length>0){
                    this.searchResult.terpene.forEach(function(each){
                        aromaTerpenePromises.push(ProductApi.addTerpene(self.productCreated.id, {terpeneId: each.id}))
                    })
                }
                await Promise.all(aromaTerpenePromises)
                
                this.snackbar.add({
                    type: 'info',
                    text: `${batchData.current_nou} x ${productData.name} added to your catalogue!`,
                    duration: 3000,
                })
            }else{
                if(res.response?.data?.message){
                    this.snackbar.add({
                        type: 'info',
                        text: res.response.data.message,
                        duration: 3000,
                    })
                }
            }
            this.resetDefault()
            this.$emit('added', this.item)
            this.vm_spinner(false)
        },
        async saveProduct(productData, cannabisProfileData, productPricingData, batchData){
            this.vm_spinner(true)
            // create product
            let res = await ProductApi.createProduct(productData)
            let success = true
            let msg = ''
            if(res.data && res.data.payload && res.data.payload.id){
                this.productCreated = res.data.payload
                // add tax to product
                await ProductApi.addBulkProductTax({locationId: this.vg_selectedLocation.id, productId: res.data.payload.id, taxIds: this.productForm.tax})
                msg = 'Product created, '

                if(this.vg_selectedLocation.id){
                    // create cannabis profile
                    let profileRes = await ProductApi.createCannabisProfile(this.productCreated.id, cannabisProfileData)
                    if(profileRes.status=='200'){
                        msg += 'Cannabis profile created, '
                    }else{
                        msg += 'Cannabis profile not created, '
                        success = false
                    }
                
                    // create product pricing
                    productPricingData.productId = res.data.payload.id
                    let pricingRes = await ProductApi.createProductPricing(productPricingData)
                    if(pricingRes.status=='201'){
                        msg += 'Pricing created, '
                    }else{
                        msg += 'Pricing not created, '
                        success = false
                    }

                    // create batch
                    batchData.productId = res.data.payload.id
                    let batchRes = await ProductApi.createProductBatch(batchData)
                    if(batchRes.status=='201'){
                        msg += 'Batch created, '
                    }else{
                        msg += 'Batch not created, '
                        success = false
                    }

                    // add featured category
                    if(productData.featuredCategoryIds?.length>0){
                        let featuredData = {
                            locationId: this.vg_selectedLocation.id,
                            categoryIds: productData.featuredCategoryIds
                        }
                        await ProductApi.setFeaturedCategory(this.productCreated.id, featuredData)
                    }

                    // asn bulk upload - add image
                    if(this.searchResult.image){
                        let productImageData = {
                            productId: this.productCreated.id,
                            url: this.searchResult.image,
                            default: true,
                            sort: 1,
                        }
                        await ProductApi.createProductImage(productImageData)
                    }

                    // asn bulk upload - add terpene & aroma
                    let aromaTerpenePromises = []
                    let self = this
                    if(this.searchResult?.aroma?.length>0){
                        this.searchResult.aroma.forEach(function(each){
                            aromaTerpenePromises.push(ProductApi.addAromas(self.productCreated.id, {aromaId: each.id}))
                        })
                    }
                    if(this.searchResult?.terpene?.length>0){
                        this.searchResult.terpene.forEach(function(each){
                            aromaTerpenePromises.push(ProductApi.addTerpene(self.productCreated.id, {terpeneId: each.id}))
                        })
                    }
                    await Promise.all(aromaTerpenePromises)

                }
                this.resetDefault()
                this.vm_spinner(false)
                this.snackbar.add({
                    type: 'info',
                    text: success ? "New product created successfully." : msg,
                    duration: 3000,
                })
                this.$emit('added', this.item)
            }else{
                this.vm_spinner(false)
                if(res.response?.data?.message){
                    this.snackbar.add({
                        type: 'info',
                        text: res.response.data.message,
                        duration: 3000,
                    })
                }
            }
        },
        resetDefault(){
            this.search = ''
            this.productValidated = false
            this.variantValidated = false
            this.current = 'product'
            this.searchResult = {...this.productTemplate}
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }
})
</script>
