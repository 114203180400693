/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import Env from '@/env';
const apiVersion = 'api';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /**
   * Fetch selected location pickup setting
   */
  fetchLocationPickupSetting() {
    const url = `${Env.loginUrl}/${apiVersion}/location-pickup-settings/show`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * Create location pickup setting
   */
  createLocationPickupSetting(data) {
    const url = `${Env.loginUrl}/${apiVersion}/location-pickup-settings/create`;
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * Update pickup Setting
   */
  updateLocationPickupSetting(data) {
    const url = `${Env.loginUrl}/${apiVersion}/location-pickup-settings/update`;
    return AuthApis.patchApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * Delete location pickup Setting
   */
  deleteLocationPosStation() {
    const url = `${Env.loginUrl}/${apiVersion}/location-pickup-settings/remove`;
    return AuthApis.deleteApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


}