/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import Env from '@/env';
const apiVersion = 'api/v1';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /**
   * List all actions
   */
  fetchAll({
    program_id,
    page,
    limit
  }) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/programs/${program_id}/actions?page=${page}&limit=${limit}`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Update
   */
  update({
    program_id,
    action_id,
    data
  }) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/programs/${program_id}/actions/${action_id}`;
    return AuthApis.patchApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Trigger Action 
   */
  triggerAction({
    program_id,
    data
  }) {
    let actionKey = data.action_key;
    if (!actionKey) return;
    let slug = '';
    let specialActions = ['signup', 'birthday'];
    if (specialActions.indexOf(actionKey) > -1) {
      slug = 'system/' + actionKey;
    }
    const url = `${Env.loyaltyUrl}/${apiVersion}/programs/${program_id}/actions/${slug}`;
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * Delete
   */
  delete(program_id, action_id) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/programs/${program_id}/actions/${action_id}`;
    return AuthApis.deleteApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

}