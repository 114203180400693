<template>
    <div class="mb-24 bg-[#2B2C32] border-0 rounded-lg py-[50px] px-[30px] w-full">

        <div class="border-[1px] flex bg-theme-red border-theme-red rounded-[12px] text-white px-4 py-5 shadow-md" role="alert">
            <div class="flex">
                <div>
                    <p class="font-semibold flex">
                        <svg width="30" height="30" class="mr-[10px] relative" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.325 11.175H10.825V4.85H9.325V11.175ZM10 15C10.2333 15 10.4292 14.9208 10.5875 14.7625C10.7458 14.6042 10.825 14.4083 10.825 14.175C10.825 13.9417 10.7458 13.7458 10.5875 13.5875C10.4292 13.4292 10.2333 13.35 10 13.35C9.76667 13.35 9.57083 13.4292 9.4125 13.5875C9.25417 13.7458 9.175 13.9417 9.175 14.175C9.175 14.4083 9.25417 14.6042 9.4125 14.7625C9.57083 14.9208 9.76667 15 10 15ZM10 20C8.58333 20 7.26667 19.7458 6.05 19.2375C4.83333 18.7292 3.775 18.025 2.875 17.125C1.975 16.225 1.27083 15.1667 0.7625 13.95C0.254167 12.7333 0 11.4167 0 10C0 8.6 0.254167 7.29167 0.7625 6.075C1.27083 4.85833 1.975 3.8 2.875 2.9C3.775 2 4.83333 1.29167 6.05 0.775C7.26667 0.258333 8.58333 0 10 0C11.4 0 12.7083 0.258333 13.925 0.775C15.1417 1.29167 16.2 2 17.1 2.9C18 3.8 18.7083 4.85833 19.225 6.075C19.7417 7.29167 20 8.6 20 10C20 11.4167 19.7417 12.7333 19.225 13.95C18.7083 15.1667 18 16.225 17.1 17.125C16.2 18.025 15.1417 18.7292 13.925 19.2375C12.7083 19.7458 11.4 20 10 20ZM10 18.5C12.3333 18.5 14.3333 17.6667 16 16C17.6667 14.3333 18.5 12.3333 18.5 10C18.5 7.66667 17.6667 5.66667 16 4C14.3333 2.33333 12.3333 1.5 10 1.5C7.66667 1.5 5.66667 2.33333 4 4C2.33333 5.66667 1.5 7.66667 1.5 10C1.5 12.3333 2.33333 14.3333 4 16C5.66667 17.6667 7.66667 18.5 10 18.5Z" fill="#FFFFFF"></path></svg>
                            <span class="text-xl">Page is restricted.</span>
                    </p>
                    <p class="mt-1 text-sm pl-[40px]">Sorry you are not authorised to access the page.</p>
                </div>
            </div>
        </div>

    </div>
</template>