<template>
  <div>
    <block-loading v-if="loading" />
    <new-table-list  v-else
      :table="table"
      @row-clicked="selectedRow" 
      @dragged="sortedRow"
      @disabletoggle="true"
      @draggable="true"

    >
      <template v-slot:name="row" >
        <div class="flex flex-row">
          <div class="pl-2">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill="#F5CB33" d="M11.5245 1.46353C11.6741 1.00287 12.3259 1.00287 12.4755 1.46353L14.5819 7.9463C14.6488 8.15232 14.8408 8.2918 15.0574 8.2918H21.8738C22.3582 8.2918 22.5596 8.9116 22.1677 9.1963L16.6531 13.2029C16.4779 13.3302 16.4046 13.5559 16.4715 13.7619L18.5779 20.2447C18.7276 20.7053 18.2003 21.0884 17.8085 20.8037L12.2939 16.7971C12.1186 16.6698 11.8814 16.6698 11.7061 16.7971L6.19153 20.8037C5.79967 21.0884 5.27243 20.7053 5.42211 20.2447L7.52849 13.7619C7.59543 13.5559 7.5221 13.3302 7.34685 13.2029L1.83227 9.1963C1.44041 8.9116 1.6418 8.2918 2.12616 8.2918H8.94256C9.15917 8.2918 9.35115 8.15232 9.41809 7.9463L11.5245 1.46353Z"/>
            </svg>
          </div>
          <div class="pl-2 font-bold">{{row.data.name}}</div>
        </div>
      </template>
    </new-table-list>
  </div>   
  
</template>

<script>
import Env from '@/env';
import { defineComponent, ref } from 'vue'
import BusinessCategoryApis from "@/controllers/BusinessCategoryApis";
import newTableList from '@/components/table/newTableLite'
import { mapGetters, mapActions, mapMutations } from "vuex";
import Helpers from '@/helpers';
import ObjectHelper from '@/helpers/object'

export default defineComponent({
  components: {
    newTableList
  },
  setup() {
    const loading = ref(false)
    const table = ref({
      loading: false,
      draggable: true,
      columns: [
        {label: 'Name', field:'name', sortable: true, isKey: true},
        {label: 'Items', field:'itemsCount', sortable: true},
      ],
      rows: [],
      totalRecords: 0,
      pagination: false,
      sortable: {
        order: "",
        sort: "",
      },
    })

    return {loading, table}
  },
  async mounted(){
    this.loading = true
    await this.fetch()
    this.loading = false

    this.emitter.on('reloadFeaturedList', this.reload)
  },
  unmounted(){
    this.emitter.off('reloadFeaturedList', this.reload)
  },
  watch: {
    async vg_selectedLocation(){
      this.loading = true
      await this.fetch()
      this.loading = false
    }
  },
  computed: {
     /**
     * Edit restriction
     */
    can_edit_categories() {
      return Helpers.getMyPermission('can_edit_categories')
    },
    ...mapGetters({
       vg_activeBusiness: 'auth/activeBusiness',
       vg_selectedLocation: 'location/selected'
    }),
  },
  methods: {
    selectedRow(item){
      if(!this.can_edit_categories) return;
      // selection effect
      this.$emit('toggleEdit', {edit: true, editItem: item})
    },
    async sortedRow(rows){
      let ids = rows.map(each => each.id)
      this.vm_spinner(true)
      await BusinessCategoryApis.sortFeaturedCategory({categoryIds: ids, locationId: this.vg_selectedLocation.id})
      await this.reload()
      this.vm_spinner(false)
    },

    async doSearch(offset, limit, order, sort){
      let pageNo = (offset/limit) +1;
      this.table.page = pageNo
      this.table.loading = true
      await this.fetch()
      this.table.loading = false
    },
    async reload(){
      this.loading = true
      this.table.loading = true
      await this.fetch()
      this.table.loading = false
      this.loading = false
    },
    async fetch(){
      let res = await BusinessCategoryApis.fetchFeaturedCategoryByLocation(this.vg_selectedLocation.id);
      let featuredPayload = []
      let sortedPayload = []
      if(res.data){
        featuredPayload = res.data.payload.featuredCategories
        sortedPayload = this.getSortedData(featuredPayload)
      }
      // before we push we need to add is active key on items
      this.table.rows = sortedPayload
      this.table.totalRecords = sortedPayload.length

    },
    getFeaturedData(categories){
      let payload = []
      for(let i=0; i<categories.length; i++){
        if(categories[i].featured){
          payload = [...payload, categories[i]]
        }
        // if(categories[i].subCategories){
        //   payload = [...payload, ...categories[i].subCategories]
        // }
      }
      return payload
    },
    getSortedData(categories){
      return ObjectHelper.sort(categories, 'featuredSort')
    },
    ...mapMutations({
      vm_spinner: 'spinner/mutateSpinner'
    })
  }
})
</script>


