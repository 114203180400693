
import {defineComponent} from "vue"
import { mapGetters} from "vuex";
import Env from '@/env';

export default defineComponent({
  name: "ReportDashboard",

  computed: {
    /**
     * business and location iframes
     */
     businessIframe() {
        let businessID:any = this.vg_activeBusiness.id;
        let url:any = Env.dashboardUrl
        return url.replace(/__BUSINESSID__/g, businessID)
     },
    ...mapGetters({
      vg_activeBusiness: 'auth/activeBusiness',
    }),
  },
});

