/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import Env from '@/env';
import AuthApis from '@/controllers/AuthApis';
const apiVersion = 'api';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /**
   * Fetch Business branding
   */
  fetchBusinessBranding(businessId) {
    const url = `${Env.loginUrl}/${apiVersion}/business-branding/show?businessId=${businessId}`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Create business branding
   */
  createBusinessBranding(data) {
    const url = `${Env.loginUrl}/${apiVersion}/business-branding/create`;
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * Update business branding
   */
  updateBusinessBranding(brandingId, data) {
    const url = `${Env.loginUrl}/${apiVersion}/business-branding/${brandingId}/update`;
    return AuthApis.patchApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  }


}