<template>
    <Form @submit="submitProductInfo" :validation-schema="getSchema(productForm.complianceType)">
        <div class="flex items-center  xl:flex-row flex-col gap-3 mb-3">
            <div class="xl:w-3/4 w-full">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="name" v-model="productForm.name">
                        <span class="text-xs " :class="labelClass(field.value, errorMessage)">Name</span>
                        <input v-bind="field" class="block tracking-wider border-none rounded bg-gray-black w-full text-sm placeholder-theme-878686 text-theme-white" :class="inputClass(field.value, errorMessage)" placeholder="E.g. Skywalker OG" type="text">
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
            <div class="xl:w-1/4 w-full">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="brandId" v-model="productForm.brandId">
                        <span class="mb-0 text-xs" :class="labelClass(field.value, errorMessage)">Brand</span>
                        <SelectSearchBrand v-bind="field" :options="brands" placeholder="Select One" class="relative block rounded bg-gray-black w-full border-none text-sm " />
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
        </div>
        <div class="flex gap-3 xl:flex-row flex-col items-start mb-3">
            <div class="w-full xl:w-3/4">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="categoryId" v-model="productForm.categoryId">
                        <label class="text-xs mb-1" :class="labelClass(field.value, errorMessage)">Category</label>
                        <div class="block rounded bg-gray-black w-full text-sm px-1 overflow-hidden">
                        <div class="flex-1 justify-between">
                            <div class="flex flex-row">
                                <span class="mt-2 w-5 h-5 rounded" :style="'background:'+categoryColor+';'" ></span>
                                <select v-bind="field" class="relative left-2 pt-1.5 w-full h-9 bg-gray-black focus:border-0 focus:ring-0 border-0" :class="productForm.categoryId?'text-white':'text-theme-878686'">
                                    <option value="" disabled>Select One</option>
                                    <template v-for="category in categories" :key="'category-'+category.id">
                                        <option v-if="!category.featured" :value="category.id">
                                            {{category.parent?'&nbsp;&nbsp;&nbsp;':''}}{{category.name}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                        </div>
                        </div>
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
            <div class="w-full xl:w-3/4">
                <div class="input-wrapper">
                    <span class="mb-0 text-xs">Featured Category</span>
                    <select-checkbox v-model="productForm.featuredCategoryIds" :options="featuredOptions" placeholder="Select One" class="block rounded bg-gray-black border-none w-full text-sm " :class="productForm.featuredCategoryIds?'text-white':'text-theme-878686'" /> 
                </div>
            </div>
            <div class="w-full xl:w-2/4">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="strain" v-model="productForm.strain" >
                        <span class="mb-0 text-xs" :class="labelClass(field.value, errorMessage)">Plant Type</span>
                        <select v-bind="field" class="capitalize block rounded border-none bg-gray-black w-full text-sm tracking-wider"  :class="productForm.strain?'text-white':'text-theme-878686'">
                            <option value="">Select One</option>
                            <option v-for="(plantType, ind) in plantTypes" :key="'plant-'+ind" :value="plantType">{{plantType}}</option>
                        </select>
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
        </div>
        <div class="flex gap-3 xl:flex-row flex-col items-start mb-3">
            <div class="w-full xl:w-3/4" v-if="vg_selectedLocation.id">
                <div class="input-wrapper">
                    <span class="mb-0 text-xs">Tax</span>
                    <select-checkbox v-model="productForm.tax" :options="taxOptions" placeholder="Select All That Apply" class="block rounded bg-gray-black border-none w-full text-sm " :class="productForm.tax?'text-white':'text-theme-878686'" /> 
                </div>
            </div>
            <div class="w-full xl:w-3/4">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="complianceType" v-model="productForm.complianceType" >
                        <span class="mb-0 text-xs" :class="labelClass(field.value, errorMessage)">Compliance Type</span>
                        <select v-model="productForm.complianceType" class="block rounded bg-gray-black border-none w-full text-sm " :class="productForm.complianceType?'text-white':'text-theme-878686'">
                            <option value="" disabled>Select One</option>
                            <option v-for="(compliance, ind) in complianceList" :value="compliance" :key="'compliance-'+ind">{{compliance}}</option>
                        </select>
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
            <div class="w-full xl:w-2/4">
                <!-- <div class="input-wrapper" v-if="vg_selectedLocation.id">
                    <Field v-slot="{field, errors, errorMessage}" name="stock_threshold" v-model="productForm.stock_threshold" >
                        <label class="text-xs tracking-[-.2px]" :class="labelClass(field.value, errorMessage)">Min. Stock Threshold</label>
                        <input maxlength="8" v-model="productForm.stock_threshold" placeholder="0-10" class="block tracking-wider border-none rounded bg-gray-black w-full text-sm placeholder-theme-878686" type="text">
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div> -->
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="concentration_unit" v-model="productForm.concentration_unit" >
                        <span class="mb-0 text-xs" :class="labelClass(field.value, errorMessage)">THC/CBD Unit</span>
                        <select v-bind="field" class="block rounded bg-gray-black w-full border-none text-sm tracking-wider" :class="productForm.concentration_unit?'text-white':'text-theme-878686'">
                            <option value="" disabled>Select One</option>
                            <option value="%">%</option>
                            <option value="mg/g">Mg/g</option>
                            <option value="mg/ml">Mg/Ml</option>
                            <option value="mg">Mg</option>
                            <option value="ml">Ml</option>
                            <option value="mg/bottle">Mg/bottle</option>
                            <option value="mg/pack">Mg/pack</option>
                            <option value="mg/unit">Mg/unit</option>
                        </select>
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
        </div>
        <div class="flex gap-3 xl:flex-row flex-col items-start mb-3">
            <div class="w-full xl:w-3/4">
                <div class="input-wrapper">
                    <Field v-model="productForm.sku" v-slot="{field, errors, errorMessage}" name="sku" >
                        <label class="text-xs" :class="labelClass(field.value, errorMessage)">SKU</label>
                        <input v-bind="field" placeholder="GJFKD2342" class="block tracking-wider border-none rounded bg-gray-black w-full text-sm placeholder-theme-878686" type="text">
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
            <div class="w-full xl:w-1/4">
                <div class="input-wrapper">
                    <label class="text-xs">Case Size</label>
                    <input v-model="productForm.caseSize" maxlength="16" placeholder="4" class="block tracking-wider border-none rounded bg-gray-black w-full text-sm placeholder-theme-878686" type="text">
                </div>
            </div>
        </div>
        <div class="flex items-start mb-3">
            <div class="w-full">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="description" v-model="productForm.description" >
                        <label class="text-xs" :class="labelClass(field.value, errorMessage)">Description</label>
                        <textarea v-bind="field" id="message" rows="4" class="block rounded border-none p-2.5 w-full text-sm bg-gray-black placeholder-theme-878686" placeholder="The Description should have a minimum of 30 words."></textarea>
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
        </div>

        <div class="flex justify-center">
            <button type="submit" class="rounded bg-theme-green hover:bg-theme-green-300 px-28 py-3 text-sm tracking-wider font-medium">CONTINUE</button>
        </div>
    </Form>
</template>

<script>
import { ref, defineComponent } from 'vue'
import {Form, Field} from 'vee-validate'
import SelectCheckbox from '@/components/form/SelectCheckbox.vue'
import SelectSearchBrand from '@/components/form/SelectSearchBrand'
import ProductApi from '@/controllers/BusinessProductApis'
import ObjectHelper from '@/helpers/object'
import {mapGetters, mapMutations} from 'vuex'
import BusinessTaxApis from "@/controllers/BusinessTaxApis";
import BusinessCategoryApis from "@/controllers/BusinessCategoryApis";

export default defineComponent({
    props: ['defaults'],
    components: {
        Form,
        Field,
        SelectCheckbox,
        SelectSearchBrand
    },
    setup() {
        const categories = ref([])
        const brands = ref([])
        const featuredOptions = ref([])
        const taxOptions = ref([])
        const catLotTracking = ref(false)
        
        const productForm = ref({
            name: '',
            brandId: '',
            categoryId: '',
            featuredCategoryIds: [],
            tax: '',
            strain: '',
            complianceType: '',
            // stock_threshold: '',
            sku: '',
            concentration_unit: '',
            description: '',
            caseSize: ''
        })
        const productLocationSchema = {
            name: 'required',
            complianceType: 'required',
            // stock_threshold: 'positiveInteger',
            sku: 'required|max:100',
            strain: 'required',
            concentration_unit: 'required'
        }
        const productLocationAccessorySchema = {
            name: 'required',
            complianceType: 'required',
            // stock_threshold: 'positiveInteger',
            sku: 'required|max:100',
        }
        const productSchema = {
            name: 'required',
            complianceType: 'required',
            sku: 'required|max:100',
            strain: 'required',
            concentration_unit: 'required'
        }
        const productAccessorySchema = {
            name: 'required',
            complianceType: 'required',
            sku: 'required|max:100',
        }
                
        let complianceList = ['Dried flower','Pre rolled','Extracts ingested','Extracts inhaled','Edibles solids','Edibles non-solids','Topicals','Accessory']
        let plantTypes = ['sativa', 'indica', 'hybrid', 'blend']

        return {
            categories, brands, taxOptions, catLotTracking, featuredOptions,
            productLocationSchema, productLocationAccessorySchema, productSchema, productAccessorySchema, productForm, complianceList, plantTypes,
        }
    },
    mounted() {
        let self = this
        this.vm_spinner(true)
        let promise1 = this.getCategory()
        let promise2 = this.getBrand()
        let promise3 = this.getTaxes()
        Promise.all([promise1, promise2, promise3]).then(() => {
            self.loadData(self.defaults)
            self.vm_spinner(false)
        })
    },
    computed: {
        categoryColor: function() {
            if(this.productForm.categoryId){
                let currentCategory = this.categories.filter(each => each.id == this.productForm.categoryId)
                if(currentCategory[0]?.posColour)
                    return currentCategory[0].posColour
                else
                    return 'black'
            }else{
                return 'black'
            }
        },
        
        ...mapGetters({
            vg_selectedBusiness: 'auth/activeBusiness',
            vg_selectedLocation: 'location/selected'
        })
    },
    watch: {
        defaults(value){
            this.loadData(value)
        },
        'productForm.categoryId'(val){
            if(val){
                this.getLotTrackingForSelectedCategory(val)
                this.getTaxForSelectedCategory(val)
            }
        }
    },
    methods: {
        loadData(value){
            this.productForm = _.pick(value, ['name', 'complianceType', 'description', 'strain', 'concentration_unit', 'sku', 'caseSize'])
            // check if category id exist
            this.productForm.categoryId = ''
            if(value.categoryId){
                this.productForm.categoryId = value.categoryId
            }
            // check if brand id available in list else get from  name
            this.productForm.brandId = ''
            if(value.brandId){
                let isBrandId = this.brands.find(each => each.id==value.brandId)
                if(isBrandId){
                    this.productForm.brandId = value.brandId
                }
            }else if(value.brandName){
                let brandName = isNaN(value.brandName)?value.brandName.toLowerCase:value.brandName
                let brandResult = this.brands.find(each => each.name.toLowerCase()==brandName)
                if(brandResult){
                    this.productForm.brandId = brandResult.id
                }
            }
            if(value.complianceType){
                this.productForm.complianceType = value.complianceType.charAt(0).toUpperCase() + value.complianceType.slice(1)
            }else{
                this.productForm.complianceType = ''
            }
            this.productForm.tax = ''
        },
        getSchema(type){
            if(this.vg_selectedLocation.id){
                if(type=='Accessory'){
                    return this.productLocationAccessorySchema
                }else{
                    return this.productLocationSchema
                }
            }else{
                if(type=='Accessory'){
                    return this.productAccessorySchema
                }else{
                    return this.productSchema
                }
            }
        },
        labelClass(value, error){
            if(value && error){
                return 'text-theme-red'
            }else if(error){
                return 'text-theme-orange'
            }else if(value){
                return 'text-theme-green'
            }
        },
        inputClass(value, error){
            if(value && error){
                return 'border-theme-red'
            }else if(error){
                return 'border-theme-orange'
            }else if(value){
                return 'border-theme-green'
            }
        },
        errorClass(value, error){
            if(value && error){
                return 'text-theme-red'
            }else if(error){
                return 'text-theme-orange'
            }
        },
        async getCategory(){
            let res = await ProductApi.fetchAllCategory()
            if(res.data?.payload?.category){
                for(let i=0; i<res.data.payload.category.length; i++){
                    this.categories.push(res.data.payload.category[i])
                    if(res.data.payload.category[i].subCategories && res.data.payload.category[i].subCategories.length>0){
                        for(let j=0; j<res.data.payload.category[i].subCategories.length; j++){
                            this.categories.push(res.data.payload.category[i].subCategories[j])
                        }
                    }

                    // featured
                    if(res.data.payload.category[i].featured){
                        this.featuredOptions.push(res.data.payload.category[i])
                    }
                }
            }
        },
        async getBrand(){
            let res = await ProductApi.fetchAllBrands()
            if(res.data?.payload?.brands){
                this.brands = res.data.payload.brands
            }
        },
        async getTaxes(){
            if(this.vg_selectedBusiness.id){
                let businessId = this.vg_selectedBusiness.id
                let res = await ProductApi.fetchAllTaxes(businessId)
                if(res.data?.payload){
                    let taxes = res.data.payload
                    this.taxOptions = taxes.map(each => {return {id: each.id, name:each.name}})
                }
            }
        },
        async getTaxForSelectedCategory(catId){
            let categoryTax = await BusinessTaxApis.getCategoryTaxes(catId, this.vg_selectedLocation.id)
            if(categoryTax.status==200 && categoryTax.data.payload){
                this.productForm.tax = categoryTax.data.payload.map(each => each.id)
            }else{
                this.productForm.tax = []
            }
        },
        async getLotTrackingForSelectedCategory(catId){
            let category = await BusinessCategoryApis.getCategoryById(catId)
            if(category.status==200 && category.data.payload){
                this.catLotTracking = category.data.payload.lotTracking
            }else{
                this.catLotTracking = false
            }
        },
        submitProductInfo(){
            let formData = ObjectHelper.emptyStringToNull(this.productForm)
            formData.lotTracking = this.catLotTracking
            this.$emit('validated', ObjectHelper.emptyStringToNull(this.productForm))
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }
})
</script>
