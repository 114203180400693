
import  { defineComponent , ref} from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import Editor from '@tinymce/tinymce-vue'
import LocationBannerMessageApis from '@/controllers/LocationBannerMessageApis';
import Helpers from '@/helpers';

export default defineComponent({
  name: "MessageBanner",
  components: {
    'editor': Editor
  },

  setup() {
    const snackbar = useSnackbar();
    const hasRecord = ref(false);
    const loading = ref(false);
    const editorMaxLen = 1500
    const validLength = ref(false)
    const form:any = ref({
      displayHeaderMessage: false,
      headerTitle: {
          value: '',
          error_type: '',
          error_message: '',
          validations: [],
      },
      headerMessage: {
          value: '',
          error_type: '',
          error_message: '',
          validations: [],
      },
    });
    return  {
      snackbar,
      editorMaxLen,
      validLength,
      form,
      hasRecord,
      loading
    };
  },

  watch : {
    'vg_selectedLocation'(val) {
      // if location selected clear component
      this.fetchLocationMessageBanner();
    },
    'form.displayHeaderMessage'(val) {
      if(val) {
        this.form.headerTitle.validations = ['required', 'min:3']
        this.form.headerMessage.validations = ['required']
      }  else {
        this.form.headerTitle.validations = []
        this.form.headerTitle.error_type = ''
        this.form.headerTitle.error_message = ''
        this.form.headerMessage.validations = []
        this.form.headerMessage.error_type = ''
        this.form.headerMessage.error_message = ''
      }
    }
  },

  computed: {
    ...mapGetters({
        vg_selectedLocation: 'location/selected'
    }),
  },

  async mounted() {
    this.loading = true;
    await this.fetchLocationMessageBanner();
    this.loading = false;
  },

  methods: {
    editorChange(e, editor){
      this.validLength = false
      let characterCount = editor.plugins.wordcount.body.getCharacterCount()
      if(characterCount>this.editorMaxLen){
        this.validLength = false
        this.form.headerMessage.error_type = 'error'
        this.form.headerMessage.error_message = `Must be less than ${this.editorMaxLen} characters!`
      }else if(characterCount===0){
        this.validLength = false
        this.form.headerMessage.error_type = ''
        this.form.headerMessage.error_message = ''
      }else{
        this.validLength = true
        this.form.headerMessage.error_type = ''
        this.form.headerMessage.error_message = ''
      }
      
    },
    /**
   * Fetch banner message
   */
    async fetchLocationMessageBanner() {
      let res = await LocationBannerMessageApis.fetchLocationBannerMessage();
      if(res.status == 200) {
        this.hasRecord = true;
        this.form.displayHeaderMessage = res.data.payload.displayHeaderMessage;
        this.form.headerTitle.value = res.data.payload.headerTitle;
        this.form.headerMessage.value = res.data.payload.headerMessage;
      } else {
        this.hasRecord = false;
        this.form.displayHeaderMessage = false;
        this.form.headerTitle.value = '';
        this.form.headerMessage.value = '';
      }
    },

    /**
     * Create location message
     */
    async create() {
      this.va_spinner(true);
      let res = await LocationBannerMessageApis.createLocationBannerMessage(Helpers.getFormKeyValue(this.form))
      this.va_spinner(false);
      if(res.status == 201){
        this.hasRecord = true;
      }
      this.snackbar.add({
        type: 'info',
        text: res.status == 201 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
    },

    /**
   * Update location message
   */
    async update() {
      this.va_spinner(true);
      let res = await LocationBannerMessageApis.updateLocationBannerMessage(Helpers.getFormKeyValue(this.form))
      this.va_spinner(false);
      this.snackbar.add({
        type: 'info',
        text: res.status == 200 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
    },

    /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },

    /**
     * Save
     */
    async save() {
      // validate business field
      let validate = await this.validateFields(['headerTitle', 'headerMessage']);
      if(!validate) {
        return ;
      }
      if( this.hasRecord ) {
        this.update()
      } else {
        this.create();
      }
    },
   
    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })

  }
});
