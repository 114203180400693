
  import {defineComponent, ref} from "vue"
  import { mapActions } from "vuex";
  import CashHistory from '@/views/CashMgmt/Vault/CashVault/CashHistory.vue';
  import CashVault from '@/views/CashMgmt/Vault/CashVault/CashVault.vue';
  import Nav from '@/views/CashMgmt/Vault/Nav.vue';

  
    export default defineComponent({
    name: "CurrentVault",
    components: {
      Nav,
      CashVault,
      CashHistory,
    },
  
    setup() {
      const totalBalance = ref(null)
      const activeTab = ref('current')
      return {
        activeTab,
        totalBalance
      };
    },
 
  
   
    methods: {
      
        changeActiveTab(data) {
        this.activeTab = data;
        },

  
      ...mapActions({
        va_spinner: 'spinner/setSpinner',
      })
    },
  });
  
  