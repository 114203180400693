
import { defineComponent, ref } from "vue";
import LocationsApis from '@/controllers/LocationsApis';
import { mapGetters, mapActions, mapMutations } from "vuex";
import Helpers from '@/helpers';
import { useSnackbar } from "vue3-snackbar";

export default defineComponent({
  name: "AddNewLocatonModal",
  components: {
  },
  
  setup() {
    const form = ref({ 
      name: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required','min:2'],
      }, 
      slug: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'min:2'],
      }, 
      licenseId: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'min:3'],
      }, 
      corporate: {
        value: false,
        error_type: '',
        error_message: '',
        validations: [],
      },
    });
    const snackbar = useSnackbar();
    return {
      form,
      snackbar,
    };
  },
  
  props: {
    callback: {
      type: Function,
      required: true,
    },
  },

  watch:{
    // Slug cannot have whitespaces
    'form.slug.value'(val){
      if(val) {
        val = val.toLowerCase();
        val = val.replaceAll(/\s/g,'').trim();
        this.form.slug.value = val;
      } else {
        this.form.slug.value = '';
      }
    },
  },

  methods: {

    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },


    async addNewLocation() {
      let validate = await this.validateFields(['name', 'slug', 'licenseId']);
      if(!validate) {
        return ;
      }
      this.va_spinner(true);
      let res = await LocationsApis.createLocation({
        name: this.form.name.value.trim(), 
        slug: this.form.slug.value, 
        licenseId: this.form.licenseId.value.trim(), 
        corporate: this.form.corporate.value,
      });
      this.va_spinner(false);

      this.snackbar.add({
        type: 'info',
        text: res.status == 201 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })

      if(res.status == 201){
        this.va_setAllLocations();
        this.callback(); // close mdoal
      }
   
    },

    ...mapActions({
      va_spinner: "spinner/setSpinner",
      va_setAllLocations: 'location/setAllLocations',
    })

  },
});
