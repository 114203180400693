
import { defineComponent, reactive, ref, createApp, h, SetupContext} from "vue";
import AddEmployee from './AddEmployee.vue'
import EditEmployee from './EditEmployee.vue'
import EmployeeApi from "@/controllers/EmployeeApis";
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';
import debounce from 'lodash/debounce'
import Env from '@/env'

export default defineComponent({
    components: {
        AddEmployee,
        EditEmployee
    },
    setup(){
        const snackbar = useSnackbar();
        // search
        let search = ref('')

        // list
        const table:any = reactive({
          loading: true,
          columns: [
            {label: 'Name', field:'name', sortable: true, isKey: true, checkbox: true },
            {label: 'Email', field:'email', sortable: true},
            {label: 'Role', field:'role', sortable: true},
            // {label: 'Location', field:'location', sortable: true},
          ],
          rows: [],
          totalRecords: 0,
          page: 1,
          sortable: {
            order: "",
            sort: "",
          },
           pagesize: Env.defaultPageSize,
           pageOptions: Env.pageList,
           count: 0,
        })

        // add
        const add = ref(false)

        //edit
        const edit = ref(false)
        const editItem = ref<any>()

        return {snackbar, search, table, add, edit, editItem}
    },

    computed: {
        
        can_manage_employees() {
            return Helpers.getMyPermission('can_manage_employees');
        },

    },

    mounted(){
        this.add = false
        this.edit = false
        this.getData()
    },
    methods: {
        async doSearch(offset, limit, order, sort){             
            this.table.loading =true
            let sorting = (sort)?sort:'asc'
            let page = (offset/limit)+1
            this.table.page = page
            let params = `page=${page}&perPage=${limit}&sortByName=${sorting}&includeRoles=1`
            if(this.search){
                params+=`&search=${this.search}`
            }
            let {data: {payload}} = await EmployeeApi.getAllUsers(params)
            this.table.rows = payload.data
            this.table.totalRecords = payload.totalRecords;
            this.table.pagesize = payload.perPage
            this.table.sortable.order = ''
            this.table.sortable.sort = ''
            this.table.loading = false
        },
        selectedRow(item){
            // reject if no permission
            if(!this.can_manage_employees) return;

            // selection effect
             let activeRow = document.getElementsByClassName(item.id);
             if(activeRow[0] && activeRow[0].parentNode && activeRow[0].parentNode.children){
                for(let sibling of activeRow[0].parentNode.children){
                    sibling.classList.remove('active-row')
                }
             }
             activeRow[0].classList.add("active-row")

            // action
            this.add = false
            this.edit = true
            this.editItem = item
        },
        async getData(page='', perPage='', sorting='', search=''){
            this.table.loading =true
            let params = ''
            if(page){
                params = 'page='+page+'&'; 
            }else {
                params = 'page=1&'
            }
            this.table.page = page;

            if(perPage){
                params += `perPage=${perPage}&`
            }else{
                params += `perPage=${Env.defaultPageSize}&`
            }

            if(sorting){
                params += `sortByName=${sorting}&`
            }else{
                params += `sortByName=asc&`
            }

            if(search){
                params += `search=${search}&`
            }
            params += 'includeRoles=1'

            let res = await EmployeeApi.getAllUsers(params)
            if(res.data && res.data.payload){
                let payload = res.data.payload
                this.table.rows = payload.data
                this.table.totalRecords = payload.totalRecords
                this.table.pagesize = payload.perPage
                this.table.loading =false
            }else{
                this.snackbar.add({
                    type: 'info',
                    text: res.status == 201 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
                    duration: 3000,
                })
                this.table.loading = false
            }
        },
        openAdd(){
            this.edit = false
            this.add = true
        },
        closeAdd(){
            this.add = false
        },
        closeAddAndGetData(){
            this.add = false
            this.getData(this.table.page)
        },
        edited(){
            this.closeEdit()
            this.getData(this.table.page)
        },
        closeEdit(){
            this.edit = false
        },
        searchData: debounce(function(this:any){
            if(this.search.length>=3 || this.search==''){
                this.getData('','','',this.search)
            }
        }, 400),
    }
})
