<template>
<div class="variant-select-wrap">
  <div class="custom-select" :tabindex="tabindex" @blur="closeThisDropDown()">
    <div class="selected text-white bg-gray-black" :class="{ open: open }" @click="open = !open">
      <span :class="'text-white text-sm ' + (dropDownSelected ? 'is-flex justify-content-between' : '')" v-html="selected"></span> <i :class="open ? 'fa fa-chevron-up' : 'fa fa-chevron-down'" aria-hidden="true"></i>
    </div>
    <div class="items bg-gray-black" :class="{ selectHide: !open }">
      <div
        v-for="(option, i) of options"
        :key="i"
        class="optionitem aa"
        @click.prevent="selectThisItem(i, option)"
      >
        <div class="flex items-center py-2">
          <div class="mr-2">
            <div :class="'w-4 h-4 flex items-center justify-center border-2 ' + (option.selected ? 'bg-theme-green border-theme-green' : 'border-white')">
              <svg v-if="option.selected" width="10" height="8" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.59948 15.7995L0.132812 8.33281L1.56615 6.89948L7.59948 12.9328L20.3995 0.132812L21.8328 1.56615L7.59948 15.7995Z" fill="#FFFFFF"/>
              </svg>
            </div>
          </div>
          <div>
            <p class="text-[14px] text-white leading-4">{{ option.name }}</p>
            <p class="text-[12px] text-theme-878686 leading-4" v-if="option.taxType">{{ option.taxType }}</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  props: {
    placeholdertext:{
      type: String,
      required: false,
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    callback: {
      type: Function,
      required: true,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    value: {
      type: Array,
      required: false,
      default: [],
    },
    valueLabel: {
      type: String,
      required: false,
      default: 'item',
    },
  },
  data() {
    return {
      dropDownSelected: false,
      selected: this.placeholdertext,
      open: false,
    };
  },
  computed: {
    selectedOptions() {
      return this.options.filter(function( obj ) {
        return obj.selected;
      });
    },
  },
  mounted() {
    this.options.map(item => {
      item.selected = this.value.includes(item.id);
    })
   this.selected = this.selectedOptions.length > 0 ? this.selectedOptions.length + (this.selectedOptions.length == 1 ? (' '+this.valueLabel) : (' '+this.valueLabel+'s') ) + ' selected' : this.placeholdertext;
  },
  methods: {
    selectThis(option) {
      this.dropDownSelected = true;
      this.selected = option.size ? option.size : '-n/a-';
      this.open = false;
      this.callback(option)
    },

    selectThisItem(index, item) {
      this.options[index].selected = !this.options[index].selected;
      this.selected = this.selectedOptions.length > 0 ? this.selectedOptions.length + (this.selectedOptions.length == 1 ? (' '+this.valueLabel) : (' '+this.valueLabel+'s') ) + ' selected' : this.placeholdertext;
    },

    closeThisDropDown() {
      this.open = false;
      this.callback(this.selectedOptions)
    },
   
  },
};
</script>

<style scoped>

.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 35px;
  line-height: 35px
}

.custom-select .selected {
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 35px;
  cursor: pointer;
  user-select: none;
}

.custom-select .selected.open {
  border-radius: 4px;
}

.custom-select .items {
  margin-top: 5px;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  overflow-y: auto;
  max-height: 160px;
  bottom: 41px;
}
.variant-select-wrap.down .custom-select .items{
    bottom: inherit;
    top: 35px;
}
.custom-select .items .optionitem {
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid #000;
  user-select: none;
}
.custom-select .items .optionitem:last-child{
  border-bottom: none;
}
.selectHide {
  display: none;
}

</style>