<template>

    <div class="flex xl:flex-row flex-col justify-between mb-5">
      <h2 class="text-[24px] font-semibold text-white">Customer list</h2>
      <div class="table-actions xl:flex-row flex-col xl:items-center items-end flex" v-if="!iscomponentactive">
        <!-- <div class="flex mb-2">
          <span class="flex mr-3">
            <input v-model="filters.verified_users" id="verified_users" type="checkbox" value="1" class="w-4 h-4 text-theme-green bg-transparent border-white focus:border-transparent focus:ring-0">
            <label for="verified_users" class="ml-2 text-sm font-normal cursor-pointer text-white"> Verified Users</label>
          </span>
          <span class="flex xl:mr-3">
            <input v-model="filters.casl_users" id="casl_users" type="checkbox" value="1" class="w-4 h-4 text-theme-green bg-transparent border-white focus:border-transparent focus:ring-0">
            <label for="casl_users" class="ml-2 text-sm font-normal cursor-pointer text-white">CASL opted-in Users</label>
          </span>
        </div> -->
        <div class="flex mb-2">
          <div class="flex flex-row justify-center items-center input-wrapper mb-3 mr-2">
            <label class="text-xs mb-1 mt-2 mr-2">Verified only:</label>
              <label class="flex items-center cursor-pointer xl:mt-2">
              <input type="checkbox" v-model="verified" class="hidden peer">
              <div :class="'relative toggle-bg  border-2  h-6 w-11 rounded-full ' + (verified ? 'bg-theme-green border-theme-green' : 'bg-[#F53333] border-[#F53333]')"></div>
              <span class="ml-2 text-white text-sm font-medium uppercase w-[30px]" :class="verified ? 'text-theme-green' : 'text-[#F53333]'">{{verified ? 'On' : 'Off'}}</span>
            </label>
          </div>
          <span class="flex w-52 mr-3">
            <input v-model="filters.search" placeholder="Search..." class="placeholder-theme-878686 block border-none rounded bg-gray-black w-full text-sm text-theme-white" type="text">
          </span>
          <!-- <span class="flex w-52 direction-bottom xl:mr-3">
            <Datepicker v-model="filters.since" placeholder="Member Since" class="text-sm focus:border-transparent focus:ring-0 bg-gray-black rounded-sm border-0 w-full" />
          </span> -->
        </div>

        <div class="flex mb-2">
          <!-- <mdicon @click="customSearch()"  size="26" name="magnify" class="flex px-[10px] justify-center items-center text-theme-878686 mr-2 bg-gray-black hover:bg-theme-green hover:text-white py-1 h-10 text-2xl border-0 rounded-md cursor-pointer"/> -->
          <!-- <mdicon @click="changeComponent({ component: 'Add', data: {} })"  size="32" name="plus" class="text-white bg-theme-green hover:bg-theme-darkgreen py-1 h-10 px-1 text-2xl border-0 rounded-md cursor-pointer"/> -->
        </div>
      </div>
    </div>

    <block-loading v-if="loading" />
    <table-lite
      v-else
      :class="'admin-table'"
      :is-loading="table.isLoading"
      :is-slot-mode='true'
      :columns="columns"
      :rows="table.rows"
      :rowClasses="customRowClass"
      :total="table.totalRecordCount"
      :sortable="table.sortable"
      @is-finished="table.isLoading = false"
      :page-size='table.pagesize'
      @do-search="doSearch"
      :pageOptions='table.pageOptions'
      :has-checkbox="false"
      :checked-return-type="'id'"
      :is-hide-paging="false"
      :pageSizeChangeLabel="'pageSizeChangeLabel'"
      :messages="{
        pagingInfo: 'Total: {2}',
        pageSizeChangeLabel: 'Items per Page',
        gotoPageLabel: '',
        noDataAvailable: 'No records found!',
      }"
      :page="table.page"
      @row-clicked="selectedRow"
    >
      <template v-slot:createdAt="data">
        {{ getReadAbleDate(data.value.createdAt) }}
      </template>
      
    </table-lite>

</template>

<script>
import  { defineComponent , ref} from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';
import Env from '@/env';
import Datepicker from 'vue3-datepicker'
import UserCustomerApis from '@/controllers/UserCustomerApis';
import moment from 'moment';
import debounce from 'lodash/debounce'

export default defineComponent({
  name: 'MembershipCustomerList',
  components: {
    Datepicker
  },
  props: {
    iscomponentactive: {
      type: Boolean,
      required: false,
    },
    selectedCallback: {
      type: Function,
      required: true,
    },
    clearComponent: {
      type: Function,
      required: true,
    },
    changeComponent: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const snackbar = useSnackbar();
    const filters = ref({
      verified_users: false,
      casl_users: false,
      search: '',
      since: '',//new Date('2000-01-01'),
    });
    const verified = ref(false)
    const activeItem = ref({});
    const loading = ref(false);
    const columnsMain = ref([
      {
        label: "First Name",
        field: "firstName",
        sortable: true,
      },
      {
        label: "Last Name",
        field: "lastName",
        sortable: true,
      },
      {
        label: "Email",
        field: "email",
        sortable: true,
      },
       {
        label: "Phone",
        field: "phone",
        sortable: true,
      },
       {
        label: "Total Spent",
        field: "totalOrderValue",
        sortable: true,
      },
      {
        label: "Membership Balance",
        field: "points",
        sortable: true,
      },
      {
        label: "Member Since",
        field: "createdAt",
        sortable: true,
      },
   
    ]);

    const table = ref({
      isLoading: false,
      rows: [],
      totalRecordCount: 0,
      count: 0,
      sortable: {
        order: "",
        sort: "",
      },
      pagesize: Env.defaultPageSize,
      pageOptions: Env.pageList,
      page: 1,
    });

    return  {
      table,
      columnsMain,
      verified,
      activeItem,
      loading,
      filters,
      snackbar,
    };
  },

  async mounted() {
    this.loading = true;
    await this.fetchList(this.table.page);
    this.loading = false;
    this.emitter.on("refresh-loyalty-user-list", data => {
      this.fetchList(this.table.page);
    });
    this.emitter.on("update-loyalty-user", (data) => {
      let getTheIndexOfUser =  this._lodash.findIndex(this.table.rows, (user) => user.id === data.id);
      if(getTheIndexOfUser >= 0) {
        this.table.rows[getTheIndexOfUser] = data
      }
    });
  },

  beforeDestroy() {
   this.emitter.off('refresh-loyalty-user-list', () => {})
  },

  watch: {
    'filters.search': debounce(function(val){
      if(val.length>=3){
        this.doSearch(1, this.table.pagesize, this.table.sortable.order, this.table.sortable.sort)
      }else if(val.length==0){
        this.doSearch(0, this.table.pagesize, this.table.sortable.order, this.table.sortable.sort)
      }
    }, 900),
    verified(){
      this.doSearch(0, this.table.pagesize, this.table.sortable.order, this.table.sortable.sort)
    }
  },

  computed: {
    ...mapGetters({
      vg_selectedBusiness: 'auth/activeBusiness',
    }),

    /**
     * Compute columns
     */
    columns() {
      if(this.iscomponentactive){
        let removeFields= ['createdAt', 'total_spent', 'points', 'phone'];
        return this.columnsMain.filter(item => {
            if( removeFields.indexOf(item.field) == -1 ) {
              return item;
            }
        })
      }
      return this.columnsMain;
    },
  },

  methods: {
    /**
     * Fetch list
     */
    async fetchList(page) {
      this.table.isLoading = true;
      let res = await UserCustomerApis.getBusinessUsers({
        verified: this.verified,
        businessId: this.vg_selectedBusiness.id,
        search: this.filters.search,
        limit: this.table.pagesize,
        page,
        sort: this.table.sortable.order,    // api and table lite have opposite
        order: this.table.sortable.sort     // api and table lite have opposite
      });
      this.table.rows = (res.status == 200) ? res.data.payload.users  : [];
      this.table.totalRecordCount = (res.status == 200) ? res.data.payload.totalRecords : 0
      this.table.isLoading = false;
    },

    /**
     * Select the item
     */
    selectedRow(item) {
      // Select unselect the item
      if(this.activeItem.id) {
        let oldactive = document.getElementsByClassName('thisid_'+this.activeItem.id);
        if(oldactive.length > 0){
          oldactive[0].classList.remove("active-row");
        }
      }
      this.activeItem = item;
      let newActive = document.getElementsByClassName('thisid_' + this.activeItem.id);
      if(newActive.length > 0){
        newActive[0].classList.add("active-row");
      }
      this.selectedCallback(item);
    },

    /**
     * Create custom class on tr for active colors
     */
    customRowClass(item) {
      return 'thisid_' + item.id;
    },

  

    /**
     * Search now
     */
    doSearch(offset, limit, order, sort) {
      this.table.sortable.order = order
      this.table.sortable.sort = sort
      this.table.pagesize = limit;
      let page = parseInt(offset/limit)+1
      this.table.page = page
      this.fetchList(page);
    },
    /**
     * GEt readable date
     */
    getReadAbleDate(dateString) {
     return dateString ? moment(dateString).format('YYYY-MM-DD') : '';
    },

    customSearch() {
      this.table.page = 1;
      this.fetchList(this.table.page);
    },


    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })

  }
});
</script>
<style>
  .customer-list .admin-table tbody{
    display: block;
    height:calc(100vh - 320px);
    overflow-y: scroll;
  }
  .customer-list .admin-table thead tr,
  .customer-list .admin-table tbody tr{
    display: table;
    width: 100%;
    table-layout: fixed;
  }
</style>

