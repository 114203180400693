<template>
    <div class="absolute z-50 top-[20%] left-[20%] w-[900px] h-[200px] drop-shadow-2xl">
        <div class="flex flex-row justify-between w-full h-[50px] bg-theme-green p-4">
            <div class="text-white">Add a resource to {{selectedFolder.name?selectedFolder.name:'root'}} folder</div>
            <div class="cursor-pointer" @click="closeAddFilePop">
                <svg class="fill-white h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/></svg>
            </div>
        </div>
        <div class="bg-field-black px-4 py-4">
            <div class="rounded border-dashed border border-white h-24" @dragenter.prevent="dragging=true" @dragover.prevent="dragging=true"  @dragleave.prevent="dragging=false" @drop.prevent="addFile" draggable="false">
                <div class="mt-2 text-white text-center">Drag files here to upload</div>
                <label for="uploadFile">
                    <div class="mt-4 flex justify-center text-theme-green cursor-pointer">CLICK TO SELECT FILES</div>
                    <input ref="uploadFile" id="uploadFile" class="hidden" multiple type="file" @change="addFile" />
                </label>
            </div>
            <div class="flex flex-row flex-wrap gap-2 p-2 max-h-[280px] overflow-auto">
                <div class="relative w-[200px] h-[180px] border" v-for="(file, ind) in files" :key="ind">
                    <img :src="file.data" class="w-auto h-[130px] mx-auto" />
                    <div class="absolute right-2 top-2">
                        <div v-if="file.uploaded"><svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 fill-theme-green" viewBox="0 0 512 512"><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg></div>
                        <div v-else-if="file.startUpload"><svg class="animate-spin w-5 h-5 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M304 48c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zm0 416c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zM48 304c26.5 0 48-21.5 48-48s-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48zm464-48c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zM142.9 437c18.7-18.7 18.7-49.1 0-67.9s-49.1-18.7-67.9 0s-18.7 49.1 0 67.9s49.1 18.7 67.9 0zm0-294.2c18.7-18.7 18.7-49.1 0-67.9S93.7 56.2 75 75s-18.7 49.1 0 67.9s49.1 18.7 67.9 0zM369.1 437c18.7 18.7 49.1 18.7 67.9 0s18.7-49.1 0-67.9s-49.1-18.7-67.9 0s-18.7 49.1 0 67.9z"/></svg></div>
                        <div v-else @click="removeFile(file)" class="rounded-full bg-white cursor-pointer"><svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 fill-theme-green" viewBox="0 0 512 512"><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/></svg></div>                        
                    </div>
                    <div class="mx-2">
                        <input type="text" v-model="file.name" class="w-full text-white bg-field-black border-b border-white border-0 focus:ring-0" />
                    </div>
                </div>
            </div>
            <div class="flex justify-end">
                <button @click.prevent="uploadAll" class="px-4 py-2 mt-2 rounded border border-white hover:border-theme-green text-white">Upload</button>
                <button @click.prevent="closeAddFilePop" class="ml-2 px-4 py-2 mt-2 rounded border border-white hover:border-theme-green text-white">Close</button>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import Api from './api'
import { useSnackbar } from "vue3-snackbar"

export default defineComponent({
    props: ['selectedFolder'],
    setup() {
        const files = ref([])
        const snackbar = useSnackbar();

        return {files, snackbar}
    },
    methods: {
        closeAddFilePop(){
            this.$emit('close')
        },
        async addFile(e){
            let fileList = e.target.files || e.dataTransfer.files
            if(fileList){
                let cnt = 0
                let self = this
                this.uploading = true
                for(let i=0; i<fileList.length; i++){
                    this.readFile(fileList[i], async fileData =>{
                        let file = {
                            name: fileList[i].name,
                            type: fileList[i].type,
                            size: fileList[i].size,
                            file: fileList[i],
                            data: fileData,
                            startUpload: false,
                            uploaded: false
                        }
                        this.files.push(file)
                        cnt++

                        if(cnt==fileList.length){
                            this.$refs.uploadFile.value = null
                        }
                    })
                }
                
            }
        },
        readFile(file, callback){
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = e => {
                let result = reader.result
                callback(result)
            }
        },
        removeFile(file){
            let fileInd = this.files.findIndex(each => (each.name==file.name && each.size==file.size))
            this.files.splice(fileInd, 1)
        },
        async uploadAll(){
            for(let i=0; i<this.files.length; i++){
                if(!this.files[i].uploaded){
                    this.files[i].startUpload = true
                    let presignedData = {
                        content_type: this.files[i].type,
                        name: this.files[i].name,
                        description: ''
                    }
                    if(this.selectedFolder?.id){
                        presignedData.folder_id = this.selectedFolder.id
                    }
                    // get presigned url
                    let presignedUrlData = await Api.getPresignedUrl(presignedData)
                    if(presignedUrlData?.data?.payload?.upload_url){
                        // upload image
                        let imageConfig = {
                            headers: {
                                'Content-Type': this.files[i].type
                            }
                        }
                        await Api.uploadResource(presignedUrlData.data.payload.upload_url, this.files[i].file, imageConfig)
                        this.files[i].uploaded = true
                    }
                    this.snackbar.add({
                        type: 'info',
                        text: this.files[i].name+" file Uploaded",
                        duration: 3000,
                    })
                }
            }
        }
    }
})
</script>