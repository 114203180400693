
    import {
        defineComponent,
        ref
    } from "vue"
    import {
        mapGetters,
        mapActions,
        mapMutations
    } from "vuex";
    import Nav from '@/views/Location/Integrations/Nav.vue';
    import CustomDailogue from "@/components/general/dialog/CustomDailogue.vue";
    import Helpers from '@/helpers';
    import LocationComplianceApis from '@/controllers/LocationComplianceApis';
    import { useSnackbar } from "vue3-snackbar";

    export default defineComponent({
        name: "LocationIntregationIndex",
        components: {
            Nav,
            CustomDailogue,
        },

        setup() {
            const confirmModal:any = ref({
                modal: false,
            });
            const loading = ref(false);
            const form:any = ref({
                complianceEnabled: false,
                providerName: {
                    value: '',
                    error_type: '',
                    error_message: '',
                    validations: ['required'],
                },
                integrationKey: {
                    value: '',
                    error_type: '',
                    error_message: '',
                    validations: ['required', 'max:15'],
                },
                integrationSecret: {
                    value: '',
                    error_type: '',
                    error_message: '',
                    validations: ['required'],
                },

            });
            const snackbar = useSnackbar();
            const hasSetting = ref(false);

            return {
                snackbar,
                hasSetting,
                form,
                loading,
                confirmModal,
            };
        },

        watch: {
            'vg_selectedLocation'(val) {
              this.fetchData();
            },
        },

        computed: {
            ...mapGetters({
                vg_selectedLocation: 'location/selected',
                vg_user: 'auth/user',
            }),

            can_manage_compliance_settings() {
                return Helpers.getMyPermission('can_manage_compliance_settings');
            },

        },

        mounted() {
            this.fetchData();
        },

        beforeDestroy() {

        },

        methods: {

             /**
             * Validation captures
             */
            async validateFields(fields) {
                let res = await Helpers.validateFormFields(this.form, fields);
                // capture the fields
                this.form = res.data;
                return res.error ? false : true;
            },
            
            /**
             * Save data
             */
             async fetchData() {
                this.loading = true;
                let res = await LocationComplianceApis.fetch();
                if(res.status == 200) {
                    this.hasSetting = true;
                    this.form = Helpers.assignFormValues(this.form, res.data.payload);
                } else {
                    this.hasSetting = false;
                    this.form = Helpers.resetFormValue(this.form)
                    this.form.complianceEnabled = false;
                }
                this.loading = false;
            },

            /**
             * Save data
             */
            async save() {
                this.va_spinner(true);
                let res = await LocationComplianceApis.create(Helpers.getFormKeyValue(this.form));
                this.va_spinner(false);
                if(res.status == 201){
                    this.hasSetting = true;
                }
                this.snackbar.add({
                    type: 'info',
                    text: res.status == 201 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
                    duration: 3000,
                })
                this.closeModal();
            },

            /**
             * validate and show return confirm modal
             */
            async confirm() {
                let validate = await this.validateFields(['providerName', 'integrationKey', 'integrationSecret']);
                if(!validate) {
                    return ;
                }
                
                this.confirmModal.modal = true;
            },

            /**
             * Close return confirm modal
             */

            closeModal() {
                this.confirmModal.modal = false;
            },

            preventNonNumbersAndDecimal(e) {
                var ASCIICode = (e.which) ? e.which : e.keyCode
                if (ASCIICode == 43 || ASCIICode == 45 || ASCIICode == 101 || e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
                    return false;
                }
                return true;
            },

            ...mapActions({
                va_spinner: 'spinner/setSpinner',
            })
        },
    });
