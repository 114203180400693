<template>
  <custom-modal :size="'max-w-2xl'">
    <template v-slot:maincontent>
      <div
        class="grid grid-cols-1 lg:grid-cols-1 gap-3"
        style="font-family: Arial"
      >
        <div
          class="text-white flex-1 col-span-1 border-0 rounded-t-xl overflow-hidden"
        >
          <div class="pt-3 bg-[#464851] px-[16px] py-[16px] rounded-lg">
            <div class="flex justify-center items-center relative pb-5">
            <span class="text-2xl pt-4 font-bold tracking-wider"
              >Product Catalogue Export</span
            >
            <a
              href="#"
              @click.prevent="close"
              class="right-0 top-[20px] absolute"
              ><mdicon class="cursor-pointer hover:text-slate-200 " name="close"/></a
            >
          </div>
          </div>
          <div class="ml-5 mr-4 mb-4 px-[16px] pb-[16px] pt-[22px] bg-[#2B2C32] rounded-xl">
            <div class="flex justify-center mb-[32px]">Select the following fields you wish to export:</div>
            <div class="px-[70px]">
              <!-- Select All -->
              <div class="flex justify-start items-center gap-6 border-b border-[#464851] mb-4 pb-2">
                <div @click="doSelectAll()" :class="'h-4 w-4 border-[1px]' + (selectAll ? ' border-theme-green bg-theme-green addcheckbox' : ' border-white')">
                  <mdicon v-if="selectAll" name="check" size="14" />
                </div>
                <span class="text-sm">Select All</span>
              </div>
              <!-- Fields -->
              <div class="flex">
                <div class="h-change grid grid-cols-2 gap-2 w-full">
                  <div class="flex items-center gap-6 mb-2" v-for="field in fields" :key="field" >
                    <div @click="toggle(field)" :class="'h-4 w-4 border-[1px] pointer ' + (field.value ? ' border-theme-green bg-theme-green addcheckbox' : ' border-white')">
                      <mdicon v-if="field.value" name="check" size="14" />
                    </div>
                    <span class="text-sm">{{field.label}}</span>
                  </div>
                </div>
              </div>

              <div class="flex justify-center mt-[32px]">
                <button @click="exportFile()" :disabled="!isSelected()" class="bg-theme-green hover:opacity-90 disabled:bg-theme-grey rounded px-36 py-2 my-4 uppercase text-sm">Continue</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </custom-modal>
</template>

<script>
import { ref, defineComponent } from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import { useSnackbar } from "vue3-snackbar"
import BusinessProduct from '@/controllers/BusinessProductApis'
import Helpers from '@/helpers';

export default defineComponent({
  props: ['exportProductSelected'],
  setup(){
    const snackbar = useSnackbar();
    const selectAll = ref(false)
    const fields = ref({
      MasterProductName: {
        key: 'Master Product Name',
        value: false,
        label: 'Master product name'
      },
      VariantProductName: {
        key: 'Variant Product Name',
        value: false,
        label: 'Variant product name'
      },
      Category: {
        key: 'Category',
        value: false,
        label: 'Category'
      },
      ComplianceType: {
        key: 'Compliance Type',
        value: false,
        label: 'Compliance type'
      },
      Cost: {
        key: 'Cost',
        value: false,
        label: 'Cost'
      },
      Price: {
        key: 'Price',
        value: false,
        label: 'Price'
      },
      SalePrice: {
        key: 'Sale Price',
        value: false,
        label: 'Sale Price'
      },
      BarcodeGTIN: {
        key: 'Barcode/GTIN',
        value: false,
        label: 'Barcode/GTIN'
      },
      Qty: {
        key: 'Qty',
        value: false,
        label: 'Qty'
      },
      Brand: {
        key: 'Brand',
        value: false,
        label: 'Brand'
      },
      Sku: {
        key: 'SKU',
        value: false,
        label: 'SKU'
      },
      Strain: {
        key: 'Strain',
        value: false,
        label: 'Strain'
      },
      LotTrackingEnabled: {
        key: 'Lot Tracking Enabled',
        value: false,
        label: 'Lot Tracking Enabled'
      },
      Description: {
        key: 'Description',
        value: false,
        label: 'Description'
      },
      Image: {
        key: 'Image URL',
        value: false,
        label: 'Image'
      },
      ConcentrationUnit: {
        key: 'Concentration Unit',
        value: false,
        label: 'THC/CBD Unit'
      },
      DryCannabisEquivalency: {
        key: 'Dry Cannabis Equivalency',
        value: false,
        label: 'Cart Equivalency'
      },
      THC: {
        key: 'THC',
        value: false,
        label: 'THC'
      },
      CBD: {
        key: 'CBD',
        value: false,
        label: 'CBD'
      },
      LotTHC: {
        key: 'Lot THC', 
        value: false, 
        label: 'Lot THC'
      },
      LotCBD: {
        key: 'Lot CBD', 
        value: false, 
        label: 'Lot CBD'
      },
      LotCost: {
        key: 'Lot Cost', 
        value: false, 
        label: 'Lot Cost'
      },
      PackagedOnDate: {
        key: 'PackagedOnDate',
        value: false,
        label: 'Packaged on date'
      },
      BatchLot: {
        key: 'BatchLot',
        value: false,
        label: 'Batch lot'
      },
      Terp: {
        key: 'Terp',
        value: false,
        label: 'Terps'
      }
    })

    return {snackbar, selectAll, fields}
  },
  computed: {
    ...mapGetters({
      vg_selectedLocation: 'location/selected'
    })
  },
  methods: {
    isSelected(){
      let values = []
      for (let each in this.fields) {
        values.push(this.fields[each].value)
      }
      return values.some(each => each)
    },
    close(){
        this.$emit('close')
    },
    doSelectAll(){
      for(let each in this.fields){
        this.fields[each].value = !this.selectAll
      }
      this.selectAll = !this.selectAll
    },
    toggle(field){
      field.value = !field.value
    },
    async exportFile(){
      if(!this.vg_selectedLocation.id){
          this.snackbar.add({
              type: 'info',
              text: "Please select a location from the top left corner.",
              duration: 3000,
          })
          return
      }
      if(this.exportProductSelected?.length>0){
        let selectedKeys = []
        for(let each in this.fields){
          if(this.fields[each].key && this.fields[each].value){
            selectedKeys.push(this.fields[each].key)
          }
        }
        let data = {
            locationId: this.vg_selectedLocation.id,
            productIds: this.exportProductSelected.map(each => parseInt(each)),
            Fields: selectedKeys
        }
        this.vm_spinner(true)
        let res = await BusinessProduct.templateExport(data)
        if(res.status==201){
            const url = window.URL.createObjectURL(new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}));
            const link = document.createElement('a');
            const filename = res.headers['x-suggested-filename']?res.headers['x-suggested-filename']:`Template-Export-Product-Columns.xlsx`
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            document.body.removeChild(link)
            link.click();
            this.resetData()
            this.close()
        }
        this.snackbar.add({
            type: 'info',
            text: res.status===201?'The ProductThe product catalogue is exported.':Helpers.catchErrorMessage(res.response.data),
            duration: 3000,
        })
        this.vm_spinner(false)
      }
    },
    resetData(){
      for(let each in this.fields){
        this.fields[each].value = false
      }
    },
    ...mapMutations({
        vm_spinner: 'spinner/mutateSpinner'
    })
  }
})
</script>