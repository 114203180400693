
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
import Nav from '@/views/Products/Category/Nav.vue';
import List from '@/views/Products/Category/RegularCategory/List.vue';
import Add from '@/views/Products/Category/RegularCategory/Add.vue';
import Update from '@/views/Products/Category/RegularCategory/Update.vue';
import Helpers from '@/helpers';

  export default defineComponent({
  name: "ProductCategories",
  components: {
    Nav,
    List,
    Add,
    Update,
  },

  setup() {
    const action = ref<object>({
      component: '',
      data: {},
    });

    return {
      action,
    };
  },

   watch: {
    'vg_selectedLocation'(val) {
      // if location selected clear component
      this.clearComponent();
    },
  },

  computed: {

    /**
     * Edit restriction
     */
    can_edit_categories() {
      return Helpers.getMyPermission('can_edit_categories')
    },

    ...mapGetters({
     vg_selectedLocation: 'location/selected'
    }),
  },

  mounted() {
    this.emitter.on("reset-product-categories-component", data => {
      this.clearComponent();
    });
  },

  beforeDestroy() {
    this.emitter.off('reset-product-categories-component', () => {});
    this.emitter.off('refresh-product-categories-list', () => {});
  },
 
  methods: {
    /**
     * Clear action
     */
    clearComponent() {
      this.action = {
        component: '',
        data: {},
      };
    },

    /**
     * Change add / update component
     */
    addUpdate(val: any) {
      this.action = val;
    },

    /**
     * Capture selected item
     */
    selectedItem(item) {
      this.addUpdate({
        component: 'Update', 
        data: item
      })
    },

    
    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  },
});

