
import {
    defineComponent,
    ref
} from "vue"
import {
    mapGetters,
    mapActions,
    mapMutations
} from "vuex";
import LocationPosStationApis from "@/controllers/LocationPosStationApis";
import {
    useSnackbar
} from "vue3-snackbar";
import Helpers from '@/helpers';
import ReturnConfirmModal from "@/components/general/dialog/ReturnConfirmModal.vue";
import LocationPaymentProcessorApis from "@/controllers/LocationPaymentProcessorApis";
import Env from '@/env';

export default defineComponent({
    name: "LocationPosAdd",
    components: {
        ReturnConfirmModal
    },
    setup() {
        const snackbar = useSnackbar();
        const deleteObj = ref({
            modal: false,
            obj: {},
        });
        const cloverAccess = < any > ref({
            verified: false,
            accessCode: '',
        });
        const form = < any > ref({
            processor: {
                value: '',
                error_type: '',
                error_message: '',
                validations: ['required'],
            },
            merchantId: {
                value: '',
                error_type: '',
                error_message: '',
                validations: ['required'],
            },
            terminalId: {
                value: '',
                error_type: '',
                error_message: '',
                validations: ['required'],
            },
            active: false,
        });

        return {
            form,
            snackbar,
            deleteObj,
            cloverAccess
        };
    },
    props: {
        clearComponent: {
            type: Function,
            required: true,
        },
        item: {
            type: Object,
            required: true,
        },
    },

    watch: {
        'item'(val) {
            this.assignItemValue();
        },
        // terminalId cannot have whitespaces
        'form.terminalId.value'(val) {
            if (val) {
               // val = val.toLowerCase();
                val = val.replaceAll(/\s/g, '').trim();
                this.form.terminalId.value = val;
            } else {
                this.form.terminalId.value = '';
            }
        },
        // merchantId cannot have whitespaces
        'form.merchantId.value'(val) {
            if (val) {
               // val = val.toLowerCase();
                val = val.replaceAll(/\s/g, '').trim();
                this.form.merchantId.value = val;
            } else {
                this.form.merchantId.value = '';
            }
        },
    },

    computed: {
        ...mapGetters({

        }),
    },

    mounted() {
        this.assignItemValue();
    },

    methods: {

        assignItemValue() {
            this.form = Helpers.assignFormValues(this.form, this.item)
        },

        /**
         * Validation captures
         */
        async validateFields(fields) {
            let res = await Helpers.validateFormFields(this.form, fields);
            // capture the fields
            this.form = res.data;
            return res.error ? false : true;
        },

        /**
         * Update
         */
        async update() {
            let validate = false
            if(this.form.processor.value==='clover'){
                validate = await this.validateFields(['processor', 'merchantId', 'terminalId']);
            }else{
                validate = await this.validateFields(['processor', 'terminalId']);
            }

            if (!validate) {
                return;
            }
            this.va_spinner(true);
            let data = Helpers.getFormKeyValue(this.form)
            if(this.form.processor.value==='moneris'){
                delete data['merchantId']
            }
            let res = await LocationPaymentProcessorApis.update(this.item.id, data);
            this.va_spinner(false);
            this.snackbar.add({
                type: 'info',
                text: res.status == 200 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
                duration: 3000,
            })
            if (res.status == 200) {
                this.clearComponent();
                this.emitter.emit("refresh-location-payment-processor-list", true);
            }
        },

        /**
         * Delete location tax
         */
        async deleteRecord() {
            this.deleteObj = {
                modal: true,
                obj: this.item,
            };
        },

        /**
         * Close delete modal
         */
        closeDeleteModal() {
            this.deleteObj = {
                modal: false,
                obj: {},
            };
        },

        /**
         * Delete Pos
         */
        async deleteNow(obj) {
            this.va_spinner(true);
            let res = await LocationPaymentProcessorApis.delete(obj.id);
            this.va_spinner(false);
            this.snackbar.add({
                type: 'info',
                text: res.status == 200 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
                duration: 3000,
            })
            if (res.status == 200) {
                this.emitter.emit("refresh-location-payment-processor-list", true);
                this.clearComponent();
            }
        },

        ...mapActions({
            va_spinner: 'spinner/setSpinner',
        })
    },
});
