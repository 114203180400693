/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import Env from '@/env';
const apiVersion = 'api/v1';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /**
   * Fetch all badges
   */
  fetchAll({
    page,
    limit
  }) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/badges?page=${page}&limit=${limit}`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Create badge
   */
  create(data) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/badges`;
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Update badge
   */
  update(badge_id, data) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/badges/${badge_id}`;
    return AuthApis.patchApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Delete badge
   */
  delete(badge_id) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/badges/${badge_id}`;
    return AuthApis.deleteApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Assign product to the specific badge
   */
  assignProducts(badge_id, data) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/badges/${badge_id}/items`;
    return AuthApis.postApi(url, null, {
        item_sku: data
      })
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Fetch assigned products
   */
  fetchAssignedProducts(badge_id) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/badges/${badge_id}/items`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

}