import axios from 'axios';
import Env from '@/env';
import store from '@/store';
import router from '@/router';

const resourceAxiosInstance:any = axios.create({
    baseURL: `${Env.resourceUrl}/api/v2`,
    // headers: {
    //   'api-token': Env.resourceApiToken,
    //   'Authorization': '1bcb0a3a84a33b108e38805b0d794b996362aefb'
    // }
})

export default {
    getRootFolder: (query) => {
    
        return resourceAxiosInstance({
            url: `/folders?${query}`,
            method: 'GET',
            headers: {
                'api-token': Env.resourceApiToken,
                'Authorization': 'Bearer ' + store.getters['auth/userToken']
            }
        }).then(res => res)
        .catch(err => {
            if(err.response.status == 401) {
                // clear user login data
                store.dispatch('auth/clearUserData');
                router.push({
                  name: 'Login'
                })
            }
            return err;
        })
    },
    getSubFolder: (folderId) => {
        return resourceAxiosInstance({
            url: `/folders/${folderId}`,
            method: 'GET',
            headers: {
                'api-token': Env.resourceApiToken,
                'Authorization': 'Bearer ' + store.getters['auth/userToken']
            }
        }).then(res => res)
        .catch(err => {
            if(err.response.status == 401) {
                // clear user login data
                store.dispatch('auth/clearUserData');
                router.push({
                  name: 'Login'
                })
            }
            return err;
        })
    },
    createFolder(data){
        return resourceAxiosInstance({
            url: `/folders`,
            method: 'POST',
            headers: {
                'api-token': Env.resourceApiToken,
                'Authorization': 'Bearer ' + store.getters['auth/userToken']
            },
            data: data
        }).then(res => res)
        .catch(err => {
            if(err.response.status == 401) {
                // clear user login data
                store.dispatch('auth/clearUserData');
                router.push({
                  name: 'Login'
                })
            }
            return err;
        })
    },


    getRootFiles: (query) => {
        return resourceAxiosInstance({
            url: `/resources?${query}`,
            method: 'GET',
            headers: {
                'api-token': Env.resourceApiToken,
                'Authorization': 'Bearer ' + store.getters['auth/userToken']
            }
        }).then(res => res)
        .catch(err => {
            if(err.response.status == 401) {
                // clear user login data
                store.dispatch('auth/clearUserData');
                router.push({
                  name: 'Login'
                })
            }
            return err;
        })
    },

    getFolderFiles: (folderId, query) => {
        return resourceAxiosInstance({
            url: `/resources/folders/${folderId}?${query}`,
            method: 'GET',
            headers: {
                'api-token': Env.resourceApiToken,
                'Authorization': 'Bearer ' + store.getters['auth/userToken']
            }
        }).then(res => res)
        .catch(err => {
            if(err.response.status == 401) {
                // clear user login data
                store.dispatch('auth/clearUserData');
                router.push({
                  name: 'Login'
                })
            }
            return err;
        })
    },

    getPresignedUrl(data){
        return resourceAxiosInstance({
            url: `/resources/upload`,
            method: 'POST',
            headers: {
                'api-token': Env.resourceApiToken,
                'Authorization': 'Bearer ' + store.getters['auth/userToken']
            },
            data: data
        }).then(res => res)
        .catch(err => {
            if(err.response.status == 401) {
                // clear user login data
                store.dispatch('auth/clearUserData');
                router.push({
                  name: 'Login'
                })
            }
            return err;
        })
    },

    uploadResource(url, data){

        return resourceAxiosInstance({
            url: url,
            method: 'PUT',
            headers: {
                'api-token': Env.resourceApiToken,
               // 'Authorization': 'Bearer ' + store.getters['auth/userToken']
            },
            data: data
        }).then(res => res)
        .catch(err => {
            if(err.response.status == 401) {
                // clear user login data
                store.dispatch('auth/clearUserData');
                router.push({
                  name: 'Login'
                })
            }
            return err;
        })
    },

    removeFile(resourceId){
        return resourceAxiosInstance({
            url: `/resources/${resourceId}`,
            method: 'DELETE',
            headers: {
                'api-token': Env.resourceApiToken,
                'Authorization': 'Bearer ' + store.getters['auth/userToken']
            }
        }).then(res => res)
        .catch(err => {
            if(err.response.status == 401) {
                // clear user login data
                store.dispatch('auth/clearUserData');
                router.push({
                  name: 'Login'
                })
            }
            return err;
        })
    }
}