
import { ref, computed, defineComponent } from "vue";
import Base from "./Base.vue";
import { useSnackbar } from "vue3-snackbar";
import LoginApis from  '@/controllers/LoginApis';
import Helpers from '@/helpers';
import { mapGetters, mapActions, mapMutations } from "vuex";

export default defineComponent({
  name: 'ForgotPassword',
  components: {
    Base
  },
  setup() {
    const snackbar = useSnackbar();
    const forgotPassForm = ref({
        email: {
          value: '',
          error_type: '',
          error_message: '',
          validations: ['required', 'email'],
        },
      });
    return {
      forgotPassForm,
      snackbar
    };
  },

  methods: {
    
    /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.forgotPassForm, fields);
      // capture the fields
      this.forgotPassForm = res.data;
      return res.error ? false : true;
    },


    async onSubmit() {
      let validate = await this.validateFields(['email']);
      if(!validate) {
        return ;
      }

      this.va_spinner(true);
      let res = await LoginApis.forgetPassword({
        email: this.forgotPassForm.email.value
      });
      this.va_spinner(false);
      this.snackbar.add({
          type: 'info',
          text: res.status == 201 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
          duration: 3000,
      })
      // on Success 
      if(res.status == 201) {
        this.forgotPassForm.email = {
            value: '',
            error_type: '',
            error_message: '',
            validations: ['required', 'email'],
        };
      }
    },

  
    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })
  }
});

