
  import {defineComponent, ref} from "vue"
  import { mapGetters, mapActions, mapMutations } from "vuex";
  import Nav from '@/views/Location/Integrations/Nav.vue';
  import List from '@/views/Location/Integrations/AccessToken/List.vue';
  import Add from '@/views/Location/Integrations/AccessToken/Add.vue';
  import Update from '@/views/Location/Integrations/AccessToken/Update.vue';
  
  export default defineComponent({
    name: "LocationIntregationIndex",
    components: {
     Nav,
     List,
     Add,
     Update
    },
  
    setup() {
      const action = ref<object>({
        component: '',
        data: {},
      });
      return {
        action,
      };
    },
  
     watch: {
      'vg_selectedLocation'(val) {
        // if location selected clear component
        this.clearComponent();
      },
    },
  
    computed: {
      ...mapGetters({
       vg_selectedLocation: 'location/selected'
      }),
    },
  
    mounted() {
     
    },
  
    beforeDestroy() {
     
    },
   
    methods: {
      /**
       * Clear action
       */
      clearComponent() {
        this.action = {
          component: '',
          data: {},
        };
      },
  
      /**
       * Change add / update component
       */
      addUpdate(val: any) {
        this.action = val;
      },
  
      /**
       * Capture selected item
       */
      selectedItem(item) {
        this.addUpdate({
          component: 'Update', 
          data: item
        })
      },
  
      ...mapActions({
        va_spinner: 'spinner/setSpinner',
      })
    },
  });
  
  