/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import store from '@/store/index';
import Env from '@/env';
const apiVersion = 'api';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
      /**
   * Fetch all sales
   */
  fetchAll(locationId, page = 1, perPage = 10, query = '') {
    const url = `${Env.orderNestUrl}/api/shifts/forLocation/${locationId}?page=${page}&perPage=${perPage + (query ? query : '')}`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch((err) => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  }
}