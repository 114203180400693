<template>
  <div class="bg-[#23242A] text-white app-header p-3 custom" id="content_screen">
    <div class="p-3 flex content-main gap-5">
      <div class="custom-full custom-left">
        <div class="bg-[#2B2C32] rounded-lg max-w-full py-[16px]">
            <div class="flex justify-between px-[16px] mb-5 h-[40px]">
                <div class="flex flex-row items-center gap-2">
                    <svg @click="back" class="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z" fill="white"/>
                    </svg>
                    <h2 class="text-xl font-bold tracking-wider text-white">{{audit?.name}}</h2>
                </div>
                <div class="flex flex-row items-center gap-2">
                    <button :disabled="audit.status!=='completed'" @click="exportReport" class="cursor-pointer text-sm font-medium tracking-widest rounded text-white border border-theme-green py-2 px-3 disabled:cursor-default disabled:border-0 disabled:cursor-default disabled:bg-[#414141] hover:bg-theme-darkgreen hover:text-white">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19 9.5H15V3.5H9V9.5H5L12 16.5L19 9.5ZM11 11.5V5.5H13V11.5H14.17L12 13.67L9.83 11.5H11ZM19 20.5V18.5H5V20.5H19Z" fill="white"/>
                        </svg>
                    </button>
                    <button @click="back" :disabled="audit.status==='completed'" class="cursor-pointer text-sm font-medium tracking-widest rounded text-white border border-theme-green py-2 px-3 hover:bg-theme-darkgreen hover:text-white disabled:border-0 disabled:cursor-default disabled:bg-[#414141]">
                        EXIT
                    </button>
                    <button @click="completeAudit" :disabled="!can_complete_audit || (!allActualQtyAdded() || audit.status==='completed')" class="cursor-pointer text-sm font-medium tracking-widest rounded text-white bg-theme-green py-2 px-3 disabled:cursor-default disabled:bg-[#414141] hover:bg-theme-darkgreen hover:text-white">
                        COMPLETE
                    </button>
                </div>
            </div>

            
            <div class="flex mx-4 gap-4">
                <div class="w-full input-wrapper">
                    <label class="text-xs mb-1">Started On</label>
                    <input disabled :value="startedOn" placeholder="24/04/2022 @ 9:30 AM" class="block border-0 rounded bg-[#1C1D21] w-full text-sm" type="text">
                </div>
                <div class="w-full input-wrapper">
                    <label class="text-xs mb-1">Completed On</label>
                    <input disabled :value="completedOn" placeholder="TBD" class="block border-0 rounded bg-[#1C1D21] w-full text-sm" type="text">
                </div>
                <div class="w-full input-wrapper">
                    <label class="text-xs mb-1">Audit Type</label>
                    <input disabled value="Regular" placeholder="Regular" class="block border-0 rounded bg-[#1C1D21] w-full text-sm" type="text">
                </div>
                <div class="w-full input-wrapper">
                    <label class="text-xs mb-1">Employee</label>
                    <input disabled :value="audit.creator" placeholder="John Crenwich" class="block border-0 rounded bg-[#1C1D21] w-full text-sm" type="text">
                </div>
                </div>
        </div>

        <div class="bg-[#2B2C32] mt-10">
            <div class="flex gap-4 items-center p-4 w-full">
                <div class="w-4/5">
                    <input v-model="search" class="bg-[#464851] border rounded w-full h-10 focus:ring-0 focus:border-theme-green" placeholder="Search" type="text">
                </div>
                <div class="w-[150px]" v-if="audit.status!=='completed'">
                    <button @click="refreshStock" class="border border-theme-green rounded w-full h-10">Refresh Stock</button>
                </div>
                <div class="flex items-center w-[200px]">
                    <input type="checkbox" v-model="showDiscrepancies" class="w-6 h-6 mr-3 text-theme-green border-2 rounded-sm bg-transparent border-white focus:border-0 focus:ring-0 focus:outline-0">
                    <label class="text-sm tracking-wider font-normal">Discrepancies Only</label>
                </div>
            </div>
            <new-table-lite
                :is-static-mode="true"
                :is-slot-mode="true"
                :is-loading="table.loading"
                :class="'block w-full min-h-[480px] admin-template'"
                :columns="table.columns"
                :rows="table.rows"
                :page="table.page"
                :total="table.totalRecords"
                :sortable="table.sortable"
                :page-size="table.pagesize"
                @do-search="changePage"
                :pageOptions='table.pageOptions'
                :messages="{
                    pagingInfo: 'Total: {2}',
                    pageSizeChangeLabel: 'Items per Page',
                    gotoPageLabel: 'Go to page',
                    noDataAvailable: 'No records found!',
                }"
            >
                <template v-slot:productSku="data">
                    <span>{{ data.value?.product?.sku }}</span>
                </template>
                <template v-slot:liveStock="data">
                    <span>{{ data.value.batchId?live[`${data.value.productId}-${data.value.batchId}`]:live[data.value.productId] }}</span>
                </template>
                <template v-slot:adjustBy="data">
                  <span :class="data.value.adjustBy>0?'text-theme-green':(data.value.adjustBy<0?'text-theme-red':'')">{{ data.value.adjustBy>0?'+':null }}{{data.value.adjustBy}}</span>
                </template>
                <template v-slot:actualInventory="data">
                  <div class="flex flex-col">
                    <span class="text-grey">{{data.value.updatedAt?formatTime(data.value.updatedAt):'-'}}</span>
                    <input :disabled="audit.status==='completed' || updating" tabIndex="1" type="number" min="0" oninput="validity.valid||(value='');" class="w-16 disabled:border-theme-black bg-transparent border rounded" :value="data.value.actualInventory" :id="data.value.id" @keyup="focusNext" @change="updateQty(data.value, $event)" />
                  </div>
                </template>
                <template v-slot:final="data">
                  <span>{{finalCount(data.value)}}</span>
                </template>
                <template v-slot:newInventory="data">
                  <span>{{data.value?.newInventory? data.value.newInventory:0}}</span>
                </template>
            </new-table-lite>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Env from '@/env';
import {defineComponent, ref} from "vue"
import ProductAuditApi from '@/controllers/ProductAuditApis'
import NewTableLite from '@/components/table/tableLite.vue'
import moment from 'moment-timezone'
import {mapActions} from 'vuex'
import Helpers from '@/helpers';
import { useSnackbar } from "vue3-snackbar";
import debounce from 'lodash/debounce'
import writeXlsxFile from 'write-excel-file';

export default defineComponent({
  name: "AuditView",
  components: {
    NewTableLite
  },
  setup() {
    const snackbar = useSnackbar();
    const loading = ref(false)
    const audit = ref([])
    const live = ref({})
    const startedOn = ref('')
    const completedOn = ref('')
    const search = ref('')
    const showDiscrepancies = ref(false)
    const columns = [
      {label: 'Id', field: 'id', sortable:false, isKey:true, columnClasses:'hidden', headerClasses:'hidden' },
      {label: 'Product', field:'productName', sortable: true},
      {label: 'Sku', field:'productSku', sortable:false, headerClasses:'width'},
      {label: 'Lot #', field: 'batchName', sortable: false},
      {label: 'Category', field:'categoryName', sortable:true},
      {label: 'In Stock', field:'expectedInventory', sortable: false},
      {label: 'Live Stock', field:'liveStock', sortable: false},
      {label: 'Adjust By', field: 'adjustBy', sortable: true},
      {label: 'Inv. Count', field:'actualInventory', sortable: false},
      {label: 'Final Count', field:'final', sortable: false},
    ]
    const completedColumn = [
      {label: 'Product', field:'productName', sortable: true},
      {label: 'Sku', field:'productSku', sortable:false, headerClasses:'width'},
      {label: 'Lot #', field: 'batchName', sortable: false},
      {label: 'Category', field:'categoryName', sortable:true},
      {label: 'Stock At Start', field:'expectedInventory', sortable: false},
      {label: 'Inv. Count', field:'actualInventory', sortable: false},
      {label: 'Adjust By', field: 'adjustBy', sortable: true},
      {label: 'Final Count', field:'newInventory', sortable: false},
    ]
    const table = ref({
        loading: false,
        columns: [],
        rows: [],
        totalRecords: 0,
        page: 1,
        pagesize: Env.defaultPageSize,
        sortable: {
            order: "productName",
            sort: "asc",
        },
        count: 0,
        clearChecked: false,
    })
    const timer = ref(null)

    return {
      snackbar,
      loading,
      audit,
      live,
      startedOn,
      completedOn,
      search,
      showDiscrepancies,
      table,
      timer,
      columns,
      completedColumn
    }
  },
  async mounted() {
    this.loading = true
    this.table.loading = true
    await this.fetchData()
    await this.liveInventory()
    this.table.loading = false
    this.loading = false
    this.searchProduct()
  },
  watch: {
    search(){
      this.searchProduct()
    },
    showDiscrepancies(){
      this.searchProduct()
    }
  },
  computed: {
    can_complete_audit() {
        return Helpers.getMyPermission('can_approve_inventory_audits')
    },
  },
  methods: {
    finalCount(data){
      let liveCount = data.batchId?this.live[`${data.productId}-${data.batchId}`]:this.live[`${data.productId}`]
      if(data.adjustBy){
        return parseInt(liveCount)+(data.adjustBy)
      }else{
        return liveCount
      }
    },
    focusNext(e){
      if(e.code=='Enter'){
        let ind = this.table.rows.findIndex(each => each.id==e.target.id)
        if(ind>=0 && this.table.rows[ind+1]?.id){
          let ele = document.getElementById(this.table.rows[ind+1].id)
          if(ele){
            ele.focus()
          }
        }
      }else if(this.timer){
        clearTimeout(this.timer)
      }
    },
    formatDate(date){
      if(!date) return

      return moment(date).format('YYYY-MM-DD')
    },
    formatTime(time){
       if(!time) return
      return moment(time).format('HH:mm')
    },
    async fetchData(){
      let res =  await ProductAuditApi.auditDetail(this.$route.params.id);
      if(res.data?.payload){
        this.audit = res.data.payload
        this.startedOn = this.audit?.startedOn? moment(this.audit.startedOn).format('YYYY-MM-DD, hh:mm A'):null
        this.completedOn = this.audit?.completedOn? moment(this.audit.completedOn).format('YYYY-MM-DD, hh:mm A'):null
        this.table.rows = res.data.payload.locationInventoryAuditdetails
        this.table.totalRecords = res.data.payload.locationInventoryAuditdetails.length
        this.va_audit(res.data.payload.status==='completed'?true:false)
      }
    },
    async liveInventory(){
        let res = await ProductAuditApi.liveInventory(this.$route.params.id)
        if(res.data?.payload){
            this.live = res.data.payload
        }
    },
    async refreshStock(){
        this.loading = true
        this.table.loading = true
        // await this.fetchData()
        await this.liveInventory()
        this.searchProduct()
        this.table.loading = false
        this.loading = false
    },
    changePage(offset, limit){
        this.table.page = (offset/limit)+1
    },
    searchProduct(){
      if(this.audit){
        this.table.loading = true
        this.table.columns = (this.completedOn) ? this.completedColumn : this.columns
        if(this.search && this.showDiscrepancies){
          this.table.rows = this.audit.locationInventoryAuditdetails.filter(each => each.productName.toLowerCase().includes(this.search.toLowerCase()) && each.adjustBy!=0)
          this.table.totalRecords = this.table.rows.length
          this.table.page = 1
        }else if(this.search){
          this.table.rows = this.audit.locationInventoryAuditdetails.filter(each => each.productName.toLowerCase().includes(this.search.toLowerCase()))
          this.table.totalRecords = this.table.rows.length
          this.table.page = 1
        } else if(this.showDiscrepancies){
          this.table.rows = this.audit.locationInventoryAuditdetails.filter(each => each.adjustBy!=0)
          this.table.totalRecords = this.table.rows.length
          this.table.page = 1
        } else{
          this.table.rows = this.audit.locationInventoryAuditdetails
          this.table.totalRecords = this.audit.locationInventoryAuditdetails.length
        }
        this.table.loading = false
      }
    },
    allActualQtyAdded(){
        return this.table.rows.every(each => each.actualInventory!==null)
    },
    async updateQty(data, e) {
      let self = this
      let obj = {
          actualInventory: parseInt(e.target.value),
          expectedInventory: data.expectedInventory,
          realTimeStock: data.batchId?this.live[`${data.productId}-${data.batchId}`]:this.live[data.productId]
      }
      let res =  await ProductAuditApi.updateProductAudit(data.id, obj);
      this.snackbar.add({
        type: 'info',
        text: res.status == 200 ? 'Product Audit Saved.' : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
      clearTimeout(this.timer)
      this.timer = setTimeout(async () => {
        await self.fetchData()
        self.searchProduct()
      }, 1000)
      
    },
    async completeAudit(){
        this.va_spinner(true)
        let res = await ProductAuditApi.completeProductAudit(this.$route.params.id)
        this.snackbar.add({
            type: 'info',
            text: res.status == 200 ? 'Audit Complete' : Helpers.catchErrorMessage(res.response.data),
            duration: 3000,
        })
        if(res.status==200){
          this.va_audit(true)
          this.$router.push({name: 'ProductAudits'})
        }
        this.va_spinner(false)
    },
    back(){
      this.$router.push({name: 'ProductAudits'})
    },
    async exportReport(){
      try {
        let schema = [
          {
            column: 'Product',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.productName
          },
          {
            column: 'SKU',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.product?.sku?i.product.sku:''
          },
          {
            column: 'Variant',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => {
              if(i.product?.variantName){
                return i.product.variantName;
              } else {
                return '';
              }
            }
          },
          {
            column: 'Category',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => {
              if(i.categoryName){
                return i.categoryName;
              } else {
                return '';
              }
            }
          },
          {
            column: 'Brand',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.brandName ? i.brandName : '' 
          },
          {
            column: 'Lot #',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.batchName ? i.batchName : '' 
          },
          {
            column: 'In Stock',
            type: Number,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.expectedInventory ? i.expectedInventory : '' 
          },
          {
            column: 'Adjust By',
            type: Number,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.adjustBy ? i.adjustBy : 0 
          },
          {
            column: 'Final Count',
            type: Number,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.actualInventory ? i.actualInventory : '' 
          },
          
        ];

        let xlBlob = await writeXlsxFile(this.audit.locationInventoryAuditdetails, {
          schema: schema,
        });
        let fileName = `audit-${this.audit.name}.xlsx`;
        let url = URL.createObjectURL(xlBlob);
        let link = document.createElement("a");
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        let self = this
        setTimeout(function(){
          self.snackbar.add({
            type: 'info',
            text: 'File downloaded',
            duration: 3000,
          })
        }, 1000)
      } catch(err) {
        console.log(err)
        return this.snackbar.add({
          type: 'info',
          text: 'Something went wrong. Please try again later.',
          duration: 3000,
        })
      }
    },
    ...mapActions({
        va_spinner: 'spinner/setSpinner',
        va_audit: 'audit/setAudit'
    })
  }
})

</script>
