<template>
    <tr class="h-[52px]" :class="((error && $route.query.status && $route.query.status==='pending')?'bg-[#F53333] opacity-30':'')+(showVariantName?' border-t border-[#464851]':'')">
        <td></td>
        <td></td>
        <td></td>
        <td class="flex flex-row gap-2 font-normal h-full text-sm leading-[17.5px]">
            <div class="flex flex-grow pr-2 gap-2 py-4 h-[50px] items-center border-r border-[#464851]">
                <div v-if="!batch">
                    <template v-if="$route.query.status && $route.query.status==='pending'">
                        <svg v-if="isProductBatchSelected(product)" @click="removeProductBatch(product)" class="cursor-pointer" width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.45 17.55C0.75 17.85 1.1 18 1.5 18H16.5C16.9 18 17.25 17.85 17.55 17.55C17.85 17.25 18 16.9 18 16.5V1.5C18 1.1 17.85 0.75 17.55 0.45C17.25 0.15 16.9 0 16.5 0H1.5C1.1 0 0.75 0.15 0.45 0.45C0.15 0.75 0 1.1 0 1.5V16.5C0 16.9 0.15 17.25 0.45 17.55ZM16.5 16.5H1.5V1.5H16.5V16.5Z" fill="#2BA66B"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 16.5H16.5V1.5H1.5V16.5ZM14.7 5.75L7.475 12.975L3.425 8.925L4.5 7.85L7.475 10.825L13.625 4.675L14.7 5.75Z" fill="#2BA66B"/>
                            <path d="M7.475 12.975L14.7 5.75L13.625 4.675L7.475 10.825L4.5 7.85L3.425 8.925L7.475 12.975Z" fill="white"/>
                        </svg>
                        <svg v-else @click="addProductBatch(product)" class="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.5 21C4.1 21 3.75 20.85 3.45 20.55C3.15 20.25 3 19.9 3 19.5V4.5C3 4.1 3.15 3.75 3.45 3.45C3.75 3.15 4.1 3 4.5 3H19.5C19.9 3 20.25 3.15 20.55 3.45C20.85 3.75 21 4.1 21 4.5V19.5C21 19.9 20.85 20.25 20.55 20.55C20.25 20.85 19.9 21 19.5 21H4.5ZM4.5 19.5H19.5V4.5H4.5V19.5Z" fill="white"/>
                        </svg>
                    </template>
                    <div v-else></div>
                </div>
                <div v-if="showVariantName">
                    <span>{{product.variantName}}</span>
                </div>
            </div>
        </td>
        <td> 
            <div v-if="batch" class="pl-2 flex flex-row gap-2 flex-start">
                <div v-if="$route.query.status && $route.query.status==='pending'">
                    <svg v-if="isProductBatchSelected(product, batch)" @click="removeProductBatch(product, batch)" class="cursor-pointer" width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.45 17.55C0.75 17.85 1.1 18 1.5 18H16.5C16.9 18 17.25 17.85 17.55 17.55C17.85 17.25 18 16.9 18 16.5V1.5C18 1.1 17.85 0.75 17.55 0.45C17.25 0.15 16.9 0 16.5 0H1.5C1.1 0 0.75 0.15 0.45 0.45C0.15 0.75 0 1.1 0 1.5V16.5C0 16.9 0.15 17.25 0.45 17.55ZM16.5 16.5H1.5V1.5H16.5V16.5Z" fill="#2BA66B"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 16.5H16.5V1.5H1.5V16.5ZM14.7 5.75L7.475 12.975L3.425 8.925L4.5 7.85L7.475 10.825L13.625 4.675L14.7 5.75Z" fill="#2BA66B"/>
                        <path d="M7.475 12.975L14.7 5.75L13.625 4.675L7.475 10.825L4.5 7.85L3.425 8.925L7.475 12.975Z" fill="white"/>
                    </svg>
                    <svg v-else @click="addProductBatch(product, batch)" class="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.5 21C4.1 21 3.75 20.85 3.45 20.55C3.15 20.25 3 19.9 3 19.5V4.5C3 4.1 3.15 3.75 3.45 3.45C3.75 3.15 4.1 3 4.5 3H19.5C19.9 3 20.25 3.15 20.55 3.45C20.85 3.75 21 4.1 21 4.5V19.5C21 19.9 20.85 20.25 20.55 20.55C20.25 20.85 19.9 21 19.5 21H4.5ZM4.5 19.5H19.5V4.5H4.5V19.5Z" fill="white"/>
                    </svg>
                </div>
                <div v-else></div>
                <span class="font-normal flex items-center text-sm leading-[17.5px]">{{batch.batchName}}</span>
            </div>
            <span v-else class="pl-2 text-[#9E9E9E]">Lot is disabled</span>
        </td>
        <td class="font-normal text-sm leading-[17.5px]">{{batch?batch.actualBatch?.current_nou:(product.actualProductStock?.activeStock?product.actualProductStock?.activeStock:0)}}</td>
        <td class="font-normal text-sm leading-[17.5px]">{{batch?.toBatch ? batch.toBatch.current_nou:(product.toProductStock?.activeStock?product.toProductStock.activeStock:0)}}</td>
        <td class="font-normal text-sm leading-[17.5px] ">
            <div v-if="$route.query.status && $route.query.status==='pending'">
                <input maxlength="4" @paste.prevent :onkeypress="preventNonNumbers" v-if="isProductBatchSelected(product,batch)" v-model="value" v-on:keyup.enter="updateProductBatchValue(product, batch, this.value)" @change="updateProductBatchValue(product, batch, this.value)" class="w-[60px] border border-[#9E9E9E] p-2 text-[#9E9E9E] bg-[#2B2C32] rounded focus:ring-0 focus:outline-0" />
            </div>
            <div v-else-if="$route.query.status && $route.query.status==='shipped'">{{batch?batch.transferQty:product.transferQty}}</div>
            <div v-else>{{batch?batch.actualTransferQty:product.actualTransferQty}}</div>
        </td>
        <td></td>
    </tr>
</template>
<script>
import { defineComponent, ref } from 'vue'
import debounce from 'lodash/debounce'

export default defineComponent({
    props: ['showVariantName', 'mainProduct', 'product', 'batch', 'item', 'selectedProduct'],
    setup() {
        const value =ref(0)
        const maxValue = ref(0)
        const error =ref(false)
        return {value, maxValue, error}

    },
    mounted(){
        if(this.batch){
            this.maxValue = this.batch?.actualBatch?.current_nou?this.batch.actualBatch.current_nou:0
        }else{
            this.maxValue = this.product?.actualProductStock?.activeStock?this.product.actualProductStock.activeStock:0
        }
        this.getValue()
    },
    watch: {
        value: debounce(function(){
            let limit = 0
            if(this.batch){
                limit = this.batch?.actualBatch?.current_nou?this.batch.actualBatch.current_nou:0
            }else{
                limit = this.product?.actualProductStock?.activeStock?this.product.actualProductStock.activeStock:0
            }
            if(this.value || this.value>0){
                this.error = parseInt(limit)<parseInt(this.value)
            }else{
                this.error = (this.value || this.value<=0)?true:false
            }
        }, 400)
    },
    methods: {
        getValue(){
            let ind = this.item.findIndex(item => item.transferProductId === this.product.id)
            if(ind>=0){
                if(this.batch){
                    let batchInd = this.item[ind].batches.findIndex(item => item.transferBatchId === this.batch.id)
                    if(batchInd>=0){
                        this.value = this.item[ind].batches[batchInd].transferQty
                    }
                }else{
                    this.value = this.item[ind].transferQty
                }
            }
        },
        preventNonNumbers(evt) {
            var ASCIICode = (evt.which) ? evt.which : evt.keyCode
            if((ASCIICode>=48 && ASCIICode<=57)){
                return true;
            }
            return false
        },
        isProductBatchSelected(product, batch=null){
            if(this.item){
                let ind = this.item.findIndex(item => item.transferProductId === product.id)
                if(ind>=0){
                    if(batch){
                        let batchInd = this.item[ind].batches.findIndex(item => item.transferBatchId === batch.id)
                        if(batchInd>=0){
                            return true
                        }else{
                            return false
                        }
                    }else{
                        return true
                    }
                }else{
                    return false
                }
            }
        },
        addProductBatch(product, batch = null){
            if(this.item){
                // add or remove main product id
                this.toggleProductId(this.mainProduct, 'add')
                this.value = 0

                let ind = this.item.findIndex(item => item.transferProductId === product.id)
                if(ind>=0){
                    if(batch){
                        let batchInd = this.item[ind].batches.findIndex(item => item.transferBatchId === batch.id)
                        if(batchInd<0){
                            this.item[ind].batches.push({
                                transferBatchId: batch.id,
                                transferQty: 0,
                                availableQty: batch.actualBatch.current_nou?batch.actualBatch.current_nou:0
                            })
                        }
                    }
                }else{
                    if(batch){
                        this.item.push({
                            transferProductId: product.id,
                            transferQty: 0,
                            actualTransferQty: 0,
                            batches: [{
                                transferBatchId: batch.id,
                                transferQty: 0,
                                availableQty: batch.actualBatch.current_nou?batch.actualBatch.current_nou:0
                            }]
                        })
                    }else{
                        this.item.push({
                            transferProductId: product.id,
                            transferQty: 0,
                            availableQty: product.actualProductStock.activeStock?product.actualProductStock.activeStock:0
                        })
                    }
                }
            }
        },
        removeProductBatch(product, batch = null){
            if(this.item){
                let ind = this.item.findIndex(item => item.transferProductId === product.id)
                if(ind>=0){
                    if(batch){
                        let batchInd = this.item[ind].batches.findIndex(item => item.transferBatchId === batch.id)
                        if(batchInd>=0){
                            this.item[ind].batches.splice(batchInd, 1)
                            // remove if all batch is removed
                            if(this.item[ind].batches.length===0){
                                this.item.splice(ind, 1)                              
                            }
                        }
                    }else{
                        this.item.splice(ind, 1)
                    }
                }

                // add or remove main product id
                this.toggleProductId(this.mainProduct, 'remove')
            }
        },
        updateProductBatchValue(product, batch, value){
            let ind = this.item.findIndex(item => item.transferProductId === product.id)
            if(batch){
                let batchInd = this.item[ind].batches.findIndex(item => item.transferBatchId === batch.id)
                this.item[ind].batches[batchInd].transferQty = value?parseInt(value):''
                this.value = value?parseInt(value):''
                this.item[ind].transferQty = 0
                for(let i=0; i<this.item[ind].batches.length; i++){
                    this.item[ind].transferQty += parseInt(this.item[ind].batches[i].transferQty)
                }
            }else{
                this.item[ind].transferQty = value?parseInt(value):''
                this.value = value?parseInt(value):''
            }
        },
        toggleProductId(mainProduct, type){
            let allIds = []
            for(let i=0; i<mainProduct.transferBatches.length; i++){
                allIds.push(mainProduct.transferBatches[i].transferProductId)
            }
            for(let j=0; j<mainProduct.transferVariants.length; j++){
                for(let k=0; k<mainProduct.transferVariants[j].transferBatches.length; k++){
                    allIds.push(mainProduct.transferVariants[j].transferBatches[k].transferProductId)
                }
            }
            
            let allSelectedIds = this.item.map(each => each.transferProductId)
            if(type==='add'){
                if(!allSelectedIds.some(each => allIds.includes(each))){
                    if(!this.selectedProduct.includes(mainProduct.id)){
                        this.selectedProduct.push(mainProduct.id)
                    }
                }
            }else{
                if(!allSelectedIds.some(each => allIds.includes(each))){
                    let ind = this.selectedProduct.indexOf(mainProduct.id)
                    this.selectedProduct.splice(ind, 1)
                }
            }

        }
    }
})
</script>
