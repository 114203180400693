
import {defineComponent, ref} from "vue"
import { mapGetters} from "vuex";
import Nav from './Nav.vue';

import CustomerImport from './CustomerImport.vue';


export default defineComponent({
  name: "Tools",
  components: {
    Nav,
    CustomerImport,
  },



  computed: {
    ...mapGetters({
     vg_selectedLocation: 'location/selected'
    }),
  },
 
 
});

