
import {defineComponent} from "vue"
import moment from 'moment'

export default defineComponent({
  name: "SafeAuditModal",
  props:{
    item: {
      type: Function,
      required: true,
    },
    clearComponent: {
      type: Function,
      required: true,
    },
  },
  methods: {
    getAmount(item){
      if(item.transactionType=='remove cash'){
        return item.amount?'-$'+item.amount.toFixed(2):'0.00'
      }else{
        return item.amount?'$'+item.amount.toFixed(2):'0.00'
      }
    },
    getCurrency(amount){
      if(!amount) return '0.00'
      if(amount>0){
        return '$'+amount.toFixed(2)
      }else{
        return '- $' + Math.abs(amount).toFixed(2);
      }
    },
    formatDate(date){
      if(!date) return
      return moment(date).format('YYYY-MM-DD h:mm:s a')
    }
  }
});

