<template>
  <custom-modal :size="'max-w-2xl'">
    <template v-slot:maincontent>
  
     <SelectQrType v-show="screen == 'select_type'" :count="products.length" :back="closeCallBack" :locationAllCategoriesObj="locationAllCategoriesObj" :isSingle="false" :closeCallBack="closeCallBack" :products="includeVariants(products)"/>
    </template>
  </custom-modal>
</template>

<script>
  import { defineComponent, ref } from "vue"
  import SelectQrType from "@/views/Products/Product/QrCode/QrCodeTypeModal.vue";
  export default defineComponent({
    components: {
      SelectQrType
    },
    props: {
      products: {
        type: Array,
        required: true,
      },
      closeCallBack: {
        type: Function,
        required: true,
      },
    },
    setup() {
      const screen = ref('select_type');
      const locationAllCategoriesObj = ref({});
      return { screen, locationAllCategoriesObj };
    },
    methods: {
      // includeVariants(products){
      //   let productWithVariants = []
      //   for(let i=0; i<products.length; i++){
      //     productWithVariants.push(products[i], ...products[i].variants)
      //   }
      //   return productWithVariants
      // }
      includeVariants(products){
        let productWithVariants = []
        for(let i=0; i<products.length; i++){
          let variants = products[i]?.variants ?? [];
          for(let j=0; j<variants.length; j++){
            variants[j].category = products[i].category
          }
          productWithVariants.push(products[i], ...variants)
        }
        return productWithVariants
      }
    },
  })
</script>
