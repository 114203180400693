
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
import Helpers from '@/helpers';
import { useSnackbar } from "vue3-snackbar";
import Nav from "@/views/Membership/Program/Nav.vue";
import LoyaltyProgramApis from "@/controllers/LoyaltyProgramApis";
import LoyaltyProgramBrandingApis from "@/controllers/LoyaltyProgramBrandingApis";
import FileManager from '@/components/manager/filemanager.vue'

export default defineComponent({
  name: "MembershipProgramSetting",
  components: {
    Nav,
    FileManager
  },

  setup() {
    const loading = ref(false);
    const membershipNotActivated = ref(false)
    const snackbar = useSnackbar();
    const loyaltyProgram = <any>ref({});
    const loyaltyBranding = <any>ref({});
    const form = <any>ref({
      // status: false,
      name: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'min:3'],
      },
      primary_color: {
        value: '#FFFFFF',
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      secondary_color: {
        value: '#FFFFFF',
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      accent_color: {
        value: '#FFFFFF',
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      is_active: false,
      card_background_image: '',
      logo_url: '',
    });
    return {
      loading,
      membershipNotActivated,
      form,
      snackbar,
      loyaltyProgram,
      loyaltyBranding
    };
  },

  computed: {

    /**
     * Edit restriction
     */
    can_edit_membership_details() {
      return Helpers.getMyPermission('can_edit_membership_details')
    },

    ...mapGetters({
      vg_selectedBusiness: 'auth/activeBusiness',
    }),
  },
 

  async mounted() {
    this.loading = true;
    let loyaltyProgramRes = await LoyaltyProgramApis.getProgramByCurrentActiveBusiness();
    if(loyaltyProgramRes.status == 200 || loyaltyProgramRes.status == 201) {
      let loyaltyBrandingRes = await LoyaltyProgramBrandingApis.getById(loyaltyProgramRes.data.id);
      this.loyaltyProgram = loyaltyProgramRes.data;
      this.form.is_active = loyaltyProgramRes.data.is_active;
      this.form.name.value = loyaltyProgramRes.data.name;
      if(loyaltyBrandingRes.status == 200 || loyaltyBrandingRes.status == 201) {
        this.loyaltyBranding = loyaltyBrandingRes.data;
        this.form.primary_color.value = loyaltyBrandingRes.data.primary_color;
        this.form.secondary_color.value = loyaltyBrandingRes.data.secondary_color;
        this.form.accent_color.value = loyaltyBrandingRes.data.accent_color;
        this.form.logo_url = loyaltyBrandingRes.data.logo_url;
        this.form.card_background_image = loyaltyBrandingRes.data.card_background_image;
      }
    }else{
      this.membershipNotActivated = true
    }
    this.loading = false;
  },

  methods: {
    clearBackground(){
      this.form.card_background_image = ''
    },
    clearLogo(){
      this.form.logo_url = ''
    },
    /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },

    /**
     * createProgramSetting
     */
     async createProgramSetting() {
       // validate business field
      let validate = await this.validateFields(['name','primary_color','secondary_color','accent_color']);
      if(!validate) {
        return ;
      }
      this.va_spinner(true);
      let loyaltyProgram = await LoyaltyProgramApis.create(true);
      if(loyaltyProgram.status  == 201){

        let obj = {
          primary_color: this.form.primary_color.value,
          secondary_color: this.form.secondary_color.value,
          accent_color: this.form.accent_color.value,
          logo_url: this.form.logo_url,
          card_background_image: this.form.card_background_image,
        }

        if(this._lodash.isEmpty(this.loyaltyBranding)) {
          await LoyaltyProgramBrandingApis.create(this.loyaltyProgram.data.payload.id, obj);
        } else {
          await LoyaltyProgramBrandingApis.update(this.loyaltyProgram.data.payload.id, obj);
        }
         
      } 
      this.snackbar.add({
        type: 'info',
        text: loyaltyProgram.status == 200 ? loyaltyProgram.data.msg : Helpers.catchErrorMessage(loyaltyProgram.response.data),
        duration: 3000,
      })

      this.va_spinner(false);
      
     
    },


    /**
     * update Program Setting
     */
    async updateProgramSetting() {
       // validate business field
      let validate = await this.validateFields(['name','primary_color','secondary_color','accent_color']);
      if(!validate) {
        return ;
      }
      let loyaltyProgram =  LoyaltyProgramApis.update(this.loyaltyProgram.id, {
        name: this.form.name.value,
        desc: 'My loyalty description',
        is_active: this.form.is_active
      });


      let loyaltyBranding;
      let obj = {
        primary_color: this.form.primary_color.value,
        secondary_color: this.form.secondary_color.value,
        accent_color: this.form.accent_color.value,
        logo_url: this.form.logo_url,
        card_background_image: this.form.card_background_image,
      };

      if(this._lodash.isEmpty(this.loyaltyBranding)) {
        loyaltyBranding = LoyaltyProgramBrandingApis.create(this.loyaltyProgram.id, obj);
      } else {
        loyaltyBranding = LoyaltyProgramBrandingApis.update(this.loyaltyProgram.id, obj);
      }

      const self = this;
      this.va_spinner(true);
      Promise.all([loyaltyProgram, loyaltyBranding]).then(async function (res) {
        self.va_spinner(false);
        self.snackbar.add({
          type: 'info',
          text: res[0].status!==200?res[0].response.data:(res[1].status!==200?res[1].response.data:'Changes Saved'),
          duration: 3000,
        })
      })
    },

    /**
     * Sync catalogue
     */

    async syncCatalogue() {
      this.va_spinner(true);
      let res:any =  await LoyaltyProgramApis.syncCatalogue(this.vg_selectedBusiness.id);
      this.va_spinner(false);
      this.snackbar.add({
          type: 'info',
          text: res.status == 200 ? res.data.msg : Helpers.catchErrorMessage(res.response.data),
          duration: 3000,
        })

    },
    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })

  },
});

