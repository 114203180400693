
import Helpers from '@/helpers';
import SelectCategory from '@/components/form/SelectCategory.vue'
import {defineComponent, ref} from "vue"
import { useSnackbar } from "vue3-snackbar"
import { mapGetters, mapActions, mapMutations } from "vuex"
import BusinessProduct from '@/controllers/BusinessProductApis'
import BusinessCategoryApis from "@/controllers/BusinessCategoryApis";
import CategoryWithProductModal from '@/components/CategoriesWithProductModal.vue';
import CategoryWithRecommendationModal from '@/views/Products/Category/FeaturedCategory/CategoriesWithRecommendationModal.vue';

export default defineComponent({
    components: {
        SelectCategory,
        CategoryWithProductModal,
        CategoryWithRecommendationModal
    },
    setup() {
        const snackbar = useSnackbar();
        const form = <any>ref({
            name: {
                value: '',
                error_type: '',
                error_message: '',
                validations: ['required', 'min:3', 'max:100'],
            },
            category: {
                value: '',
                error_type: '',
                error_message: ''
            },
        })
        const categories = ref<any>([])
        const selectedPids = ref([]);
        const categoryWithproductObj = ref({
            type: '',
            active: false,
        });

        return {
            snackbar,
            form,
            categories,
            selectedPids,
            categoryWithproductObj
        }
    },
    mounted(){
        // this.getCategory()
    },
    computed: {
        ...mapGetters({
            vg_selectedLocation: 'location/selected'
        }),
    },
    methods: {
        /**
         * Validation captures
         */
        async validateFields(fields) {
            let res = await Helpers.validateFormFields(this.form, fields);
            // capture the fields
            this.form = res.data;
            return res.error ? false : true;
        },
        async saveFeaturedCategory(){
            // validate business field
            let validate = await this.validateFields(['name', 'category']);
            if(!validate) {
                return ;
            }
            let featuredCategoryId = await this.createFeatureCategory()
            await this.syncProduct(featuredCategoryId)
            this.$emit('clearComponent')
            this.emitter.emit('reloadFeaturedList', true)

        },
        // async getCategory(){
        //     let res = await BusinessProduct.fetchAllCategory()
        //     if(res.data?.payload?.category){
        //         for(let i=0; i<res.data.payload.category.length; i++){
        //             this.categories.push(res.data.payload.category[i])
        //         }
        //     }
        // },
        getCurrentCategory(catId){
            if(!catId) return

            return  this.categories.find(each => each.id==catId)
        },
        async createFeatureCategory(){
            this.vm_spinner(true)
            let catData = {
                locationId: this.vg_selectedLocation.id,
                name: this.form.name.value,
                parent: this.form.category.value?this.form.category.value:null,
                ComplianceType: 'Dried flower',
                stockThreshold: 1,
                posColour: '#0000',
                concentrationUnit: '%',
                thcCbdValueType: 'range',
                featured: true
            }
            let {data: {payload}} = await BusinessCategoryApis.addNewCategory(catData)
            if(payload?.id){
                return payload.id
            }
            
        },
        async syncProduct(featuredCatId){
            this.vm_spinner(true)
            let res = await BusinessCategoryApis.syncFeaturedProduct(featuredCatId, {
                locationId: this.vg_selectedLocation.id,
                productIds: this.selectedPids
            })
            if(res.status = 200){
                this.snackbar.add({
                    type: 'info',
                    text: res.status == 200 ? "Featured category added successfully." : Helpers.catchErrorMessage(res.response.data),
                    duration: 3000,
                })
            }
            this.vm_spinner(false)
        },
        /**
         * Open product with category Model
         */
        showCategoryWithProductModal(type) {
            this.categoryWithproductObj.type = type
            this.categoryWithproductObj.active = true;
        },
        /**
         * Close select category modal
         */
        closeCategoryWithProductModal() {
            this.categoryWithproductObj.active = false;
        },
        /**
         * Get data selected from category modal
         */
        setCategoriesWithProduct(data) {
            this.selectedPids = data.pids;
            this.closeCategoryWithProductModal();
        },
        closeAdd(){
            this.$emit('clearComponent')
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }
})
