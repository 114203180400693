
import {defineComponent} from "vue"
import Helpers from '@/helpers';

export default defineComponent({
  name: "MembershipProgramNav",
  setup() {
    return {
      
    };
  },

  computed: {
    /**
     * Restriction based
     */
    can_view_customers_and_membership_details() {
      return Helpers.getMyPermission('can_view_customers_and_membership_details')
    },
  },
})
