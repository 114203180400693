
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
import LocationHolidayHoursApis from "@/controllers/LocationHolidayHoursApis";
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';
 import Datepicker from 'vue3-datepicker'
import moment from 'moment';
//moment.tz.setDefault('America/Toronto')
import ReturnConfirmModal from "@/components/general/dialog/ReturnConfirmModal.vue";

export default defineComponent({
  name: "EditLocationHoliday",
  components: {
    Datepicker,
    ReturnConfirmModal
  },
  props:{
    clearComponent: {
      type: Function,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const snackbar = useSnackbar();
    const deleteObj = ref({
      modal: false,
      obj: {},
    });
    const holidayDates = <any>ref([]);
    const form = <any>ref({
      name: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'min:3'],
      },
      message: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'min:3'],
      },
      startDate: {
        value: null,
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      endDate: {
        value: null,
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      isActive: false,
    });
    return {
      form,
      snackbar,
      deleteObj,
      holidayDates
    };
  },

  watch:{
    'item'() {
      this.assignValues();
    },
  },

  computed: {
    ...mapGetters({
    }),
  },
 

  mounted() {
    this.assignValues();
  },


  methods: {
    assignValues() {
      this.holidayDates = [];
      this.form.name.value = this.item.name;
      this.form.message.value = this.item.message;
      this.form.message.value = this.item.message;
      this.form.startDate.value = moment(this.item.startDate).toDate();
      this.form.endDate.value = moment(this.item.endDate).toDate();
      this.form.isActive = this.item.isActive;
      if(this.item.holidayHours && this.item.holidayHours.length > 0) {
        this.item.holidayHours.forEach(record => {
           let open24HrTime = record.openTime;
           let open12HrTime = moment(open24HrTime, ["HH:mm:ss"]);
           let close24HrTime = record.closeTime;
           let close12HrTime = moment(close24HrTime, ["HH:mm:ss"]);
  
          this.holidayDates.push({
            day: record.day,
            dayName: record.dayName,
            holidayDate: record.holidayDate,
            holidayDateDisplay: moment(record.holidayDate).format('MM/DD'),
            isOpen: record.isOpen,
            is_valid_openTime_closeTime: true,
            openTime: {
              HH: open12HrTime.format('hh'),
              MM: open12HrTime.format('mm'),
            },
            openTimeTypeAm: open12HrTime.format('A') == 'AM' ?  true : false,
            closeTime: {
              HH: close12HrTime.format('hh'),
              MM: close12HrTime.format('mm'),
            },
            closeTimeTypeAm: close12HrTime.format('A') == 'AM' ?  true : false,
          })
        })
      }
    },
    /**
     * Validate start end time
     */
    async validateStartEndTime(index) {
      this.holidayDates = await Helpers.validateStartEndTime(this.holidayDates, index);
    },
    
     /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },


    /**
     * Add new location Tax
     */
    async update() {
      let validate = await this.validateFields(['name', 'message', 'startDate', 'endDate']);
      if(!validate) {
        return ;
      }
      // Validate datepicker date before submit
      let startDate = moment(this.form.startDate.value);
      let endDate = moment(this.form.endDate.value);
      let totalDays = endDate.diff(startDate, 'days') + 1;
      if(totalDays < 1){
        return this.snackbar.add({
          type: 'info',
          text: 'End time ' + endDate.format('YYYY-MM-DD') +' must be greater or equals to start time ' + startDate.format('YYYY-MM-DD'),
          duration: 3000,
        })
      }
      // Validate holiday dates must be min 1 
      if(this.holidayDates.length == 0){
        return this.snackbar.add({
          type: 'info',
          text: 'You must have at least 1 holiday hours.',
          duration: 3000,
        })
      }

      // validate holidays date's time must be valid
      
      for (let i = 0; i < this.holidayDates.length; i++) {
        if(this.holidayDates[i].is_valid_openTime_closeTime == false) {
          return this.snackbar.add({
            type: 'info',
            text: 'End time must be greater than start time on ' + this.holidayDates[i].holidayDateDisplay,
            duration: 3000,
          })
        }
      }
      
      this.va_spinner(true);
      let data = <any>Helpers.getFormKeyValue(this.form);
      // now we need to chnage the date values
      data.startDate = moment(data.startDate).format('YYYY-MM-DD');
      data.endDate = moment(data.endDate).format('YYYY-MM-DD');
      data.holidayHours = <any>Helpers.convertLocation12hoursTo24hours(this.holidayDates);
      let res = await LocationHolidayHoursApis.updateLocationHolidayHours(this.item.id, data);
      this.va_spinner(false);
      this.snackbar.add({
        type: 'info',
        text: res.status == 200 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
      if(res.status == 200 ) {
        this.clearComponent();
        this.emitter.emit("refresh-location-holidays-list", true);
      }
    },

     /**
     * Delete location tax
     */
    async deleteRecord() {
      this.deleteObj = {
        modal: true,
        obj: this.item,
      };
    },
    
    /**
     * Close delete modal
     */
    closeDeleteModal() {
      this.deleteObj = {
        modal: false,
        obj: {},
      };
    },

    /**
     * Delete Pos
     */
    async deleteNow(obj) {
      this.va_spinner(true);
      let res = await LocationHolidayHoursApis.deleteLocationHolidayHours(obj.id);
      this.va_spinner(false);
        this.snackbar.add({
        type: 'info',
        text: res.status == 200 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
      if(res.status == 200 ) {
        this.emitter.emit("refresh-location-holidays-list", true);
        this.clearComponent();
      }
    },

    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  },
});

