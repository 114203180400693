
import {defineComponent} from "vue"



export default defineComponent({
  name: "ReportGeneration",
  props: {
    activeTab: {
      type: String,
      required: true
    },
    changeTab:{
      type: Function,
      required: true
    }
  }
})

