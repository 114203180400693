import store from '@/store/index';
import ProductApis from '@/controllers/ProductApis';
import NormalApis from '@/controllers/NormalApis'
import ApiHelper from '@/controllers/ApiHelpers';
import AuthApis from '@/controllers/AuthApis';
import Env from '@/env';
const apiVersion = 'api';

export default {
    fetchBusinessProfile(business_slug) {
        const url = `${Env.loginUrl}/${apiVersion}/web/business-by-slug/${business_slug}?includeBranding=1&includeCrmSetting=1`;
        return AuthApis.getApi(url, {
            'Authorization': 'Bearer ' + store.getters['auth/userToken'],
            'apiToken': Env.loginApiToken,
        })
          .then(res => res)
          .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
        });
    },

    categoryTree(businessId) {
        const url = `${Env.productUrl}/${apiVersion}/category/web/categoryTree/${businessId}`;
        return NormalApis.getApi(url, {
            'Api_token': Env.productApiToken,
        })
          .then(res => res)
          .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
        });
    },

    fetchAllBusinessProducts(businessId) {
        const url = `${Env.productUrl}/${apiVersion}/product/forBusiness/${businessId}?isPagination=false`;
        return NormalApis.getApi(url, {
            'Api_token': Env.productApiToken,
        })
          .then(res => res)
          .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
        });
    },

    fetchAllLocationByBusinessIdNotDetails(businessId) {
        const url = `${Env.loginUrl}/${apiVersion}/web/locations?businessId=${businessId}`;
        return AuthApis.getApi(url,{
            'Authorization': 'Bearer ' + store.getters['auth/userToken'],
            'apiToken': Env.loginApiToken,
        })
          .then(res => res)
          .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
        });
    },
}