import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36643faa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pt-[16px] px-[24px] max-w-full" }
const _hoisted_2 = { class: "flex bg-[#2B2C32] rounded-full px-8 py-1 mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", {
        class: _normalizeClass('mr-4 uppercase' + (_ctx.active == 'regular' ? ' active' : ''))
      }, [
        _createVNode(_component_router_link, {
          to: { name: 'ProductCategories' },
          href: "#",
          class: "inline-block text-theme-white tracking-widest text-sm text-white font-semibold px-2"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" REGULAR CATEGORIES ")
          ]),
          _: 1
        })
      ], 2),
      _createElementVNode("li", {
        class: _normalizeClass(_ctx.active == 'featured' ? ' active' : '')
      }, [
        _createVNode(_component_router_link, {
          to: { name: 'ProductFeaturedCategories' },
          class: "text-theme-theme-white text-white tracking-widest text-sm font-semibold"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" FEATURED CATEGORIES ")
          ]),
          _: 1
        })
      ], 2)
    ])
  ]))
}