<template>
  <div class="bg-[#23242A] text-white h-screen appheader overflow-y-auto px-6 pt-6 pb-[158px]">
      <Nav/>
      <div class="bg-[#2B2C32] border-0 rounded-lg mb-[24px]">
        <div class="p-3">
            <div class="p-3 mb-3 flex justify-between">
              <h3 class="text-[24px] text-white font-semibold">Redeeming Points</h3>
            </div>

            <block-loading v-if="loadingRedeem"/>

            <template v-else>
              <template v-if="loyaltyProgram && loyaltyProgram.id">
              <div class="flex gap-3 xl:flex-row flex-col items-start mb-4">
                <div class="w-full xl:w-1/4">
                    <div :class="'input-wrapper ' + (form.points_for_dollar.error_type)">
                      <label class="block text-white text-xs" for="name">Redeeming Rate</label>
                      <div class="relative">
                        <input :disabled="earningSetting.id && earningSetting.points_for_dollar" @keyup="validateFields(['points_for_dollar'])" v-model.number="form.points_for_dollar.value" class="rounded border-none pr-[50px] w-full py-2.5 bg-[#464851] text-white tracking-wider leading-tight focus:outline-none focus:shadow-outline text-sm"  placeholder="1,000" type="number">
                        <span class="absolute flex bottom-2 right-2 text-base font-semibold tracking-wider">= $1</span>
                      </div>
                      <p class="field-error label-text mt-1" v-if="form.points_for_dollar.error_message" v-text="form.points_for_dollar.error_message"></p>
                    </div>
                    <div class="flex mt-1">
                        <span class="text-xs font-normal break-normal text-theme-878686 tracking-wider">You can't change your redemption rate for points from here. Please contact your account manager to make changes</span>
                      </div>
                    <div class="flex mt-1">
                      <span class="text-xs font-normal break-normal text-theme-878686  tracking-wider">Make sure to update FAQ/Loyalty Terms if you are changing your redeeming rate</span>
                      <span> <mdicon  size="20" name="alert-circle-outline" class=""/></span>
                    </div>
                </div>
                <div class="w-full xl:w-1/4">
                  <div :class="'input-wrapper ' + (form.redeem_limit.error_type)">
                    <label class="block text-white text-xs" for="name">Redemption Limit</label>
                    <div class="relative">
                      <span class="absolute bottom-2.5 left-1.5"><mdicon  size="20" name="currency-usd" class=""/></span>
                      <input v-model.number="form.redeem_limit.value" @keyup="validateFields(['redeem_limit'])" class="rounded border-none w-full pl-6 py-2.5 bg-[#464851] text-white tracking-wider leading-tight focus:outline-none focus:shadow-outline text-sm"  placeholder="100,000" type="number">
                    </div>
                    <p class="field-error label-text mt-1" v-if="form.redeem_limit.error_message" v-text="form.redeem_limit.error_message"></p>
                  </div>
                </div>
                <div class="w-full xl:w-1/4">
                  <div :class="'input-wrapper ' + (form.redeem_on.error_type)">
                    <label class="block text-white text-xs tracking-[0.4px]">Redeem On</label>
                    <select :disabled="this.earningSetting?.id" @onchange="validateFields(['redeem_on'])" v-model="form.redeem_on.value" name="action-type" class="block tracking-wider border-none rounded bg-gray-black w-full text-white text-sm">
                      <option value="item">Item</option>
                      <option value="cart">Cart</option>
                    </select>
                    <p class="field-error label-text mt-1" v-if="form.redeem_on.error_message" v-text="form.redeem_on.error_message"></p>
                  </div>
                </div>
                <div class="w-full xl:w-1/4">
                    <div class="input-wrapper flex xl:justify-end mt-3">
                      <button v-if="can_edit_membership_details" @click.prevent="save()" class="bg-white text-[#2BA66B] px-4 py-2 border-0 rounded cursor-pointer text-sm font-medium tracking-widest hover:bg-theme-green hover:text-white" for="">SAVE</button>
                    </div>
                  </div>
              </div>

              <div class="flex gap-3 xl:flex-row flex-col items-start mb-4">
                <div class="w-full xl:w-1/4">
                  <div class="input-wrapper ">
                    <label class="block text-white text-xs tracking-[0.4px]">Redeemable Items</label>
                    <div class="text-white text-sm tracking-wider mt-2">{{ loyaltyProductCountObj.redeemable_items }} {{ loyaltyProductCountObj.redeemable_items > 1  ? 'Items' : 'Item' }} selected</div>
                  </div>
                </div>
                <div class="w-full xl:w-1/4">
                  <div class="input-wrapper flex xl:justify-start mt-3 mr-2">
                    <button v-if="can_edit_membership_details" @click.prevent="categoryWithproductObj.active = true" class="bg-white text-[#2BA66B] px-4 py-2 border-0 rounded cursor-pointer text-sm font-medium tracking-widest hover:bg-black hover:text-white" for="">SELECT</button>
                  </div>
                </div>
              </div>
            </template>
            <p v-else class="text-sm"> Loyalty Program is inactive or not set.</p>
          </template>
        </div>
      

        <div :class="'flex content-main gap-5 bg-[#23242A]' + (action.component ? ' half-it' : '')">
          <div class="custom-full rounded-lg bg-[#2B2C32] mt-5 py-5">
            <div class="mt-5 flex justify-between p-3">
              <h3 class="text-[24px] text-white font-semibold">Creating Reward Tiers</h3>
              <mdicon @click="add()" v-if="!action.component && can_edit_membership_details" size="32" name="plus" class="text-white bg-theme-green hover:bg-theme-darkgreen py-1 h-10 px-1 text-2xl border-0 rounded-md cursor-pointer"/>
            </div>
            <List v-if="loyaltyProgram && loyaltyProgram.id" :selectedCallback="selectedItem" :loyaltyProgram="loyaltyProgram" :clearComponent="clearComponent" :iscomponentactive="action.component ? true : false" @updateRewardTierCount="updateRewardTierCount"/> 
          </div>
          <div :class="'mt-5 w-full hidden-component'" v-if="action.component" >
            <component :is="action.component" :item="action.data" :clearComponent="clearComponent"></component>
          </div>
        </div>
      </div>

  </div>
  <CategoryWithProductModal v-if="categoryWithproductObj.active" :closeClick="closeCategoryWithProductModal" :loyaltyProgramId="loyaltyProgram.id" :actionClick="setCategoriesWithProduct"/>
</template>


<script>
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
import Helpers from '@/helpers';
import { useSnackbar } from "vue3-snackbar";
import Nav from "@/views/Membership/Program/Nav.vue";
import CategoryWithProductModalSingleSelect from '@/views/Membership/Program/RedeemPoints/CategoryWithProductModalSingleSelect.vue';
import CategoryWithProductModal from '@/views/Membership/Program/RedeemPoints/CategoryWithProductModal.vue';
import LoyaltyProgramApis from "@/controllers/LoyaltyProgramApis";
import List from "@/views/Membership/Program/RedeemPoints/List.vue";
import LoyaltyEarnSettingApis from "@/controllers/LoyaltyEarnSettingApis";
import LoyaltyRedeemSettingApis from "@/controllers/LoyaltyRedeemSettingApis";
import LoyaltyProgramProductApis from "@/controllers/LoyaltyProgramProductApis";
import Add from '@/views/Membership/Program/RedeemPoints/Add.vue';
import Update from '@/views/Membership/Program/RedeemPoints/Update.vue';

export default defineComponent({
  name: "MembershipProgramRedeemPoints",
  components: {
    Nav,
    CategoryWithProductModal,
    List,
    Add,
    Update
  },

  setup() {
    const snackbar = useSnackbar();
      const action = ref({
      component: '',
      data: [],
    });
    const form = ref({
      redeem_on: {
        value: 'item',
        error_type: '',
        error_message: '',
        validations: ['required', 'min:3'],
      },
      redeem_limit: {
        value: '0',
        error_type: '',
        error_message: '',
        validations: [],// 'required_min_0', 'numeric'
      },
      points_for_dollar: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'numeric'],
      },
    });

    const categoryWithproductObj = ref({
      active: false,
    });
    const loyaltyProgram = ref({});
    const earningSetting = ref({});
    const loadingRedeem = ref(false);
    const loyaltyProductCountObj = ref({ redeemable_items: 0 });
    const rewardTierCount = ref(0)
    return {
      form,
      snackbar,
      categoryWithproductObj,
      loyaltyProgram,
      earningSetting,
      loadingRedeem,
      loyaltyProductCountObj,
      action,
      rewardTierCount
    };
  },

  computed: {
    /**
     * Edit restriction
     */
    can_edit_membership_details() {
      return Helpers.getMyPermission('can_edit_membership_details')
    },
    ...mapGetters({
   
    }),
  },
   watch: {
    'vg_selectedLocation'(val) {
      this.clearComponent();
    },
  },
 

  async mounted() {
    this.loadingRedeem = true;
    let loyaltyProgramRes = await LoyaltyProgramApis.getProgramByCurrentActiveBusiness();
    if(loyaltyProgramRes.status == 200 || loyaltyProgramRes.status == 201) {
      this.loyaltyProgram = loyaltyProgramRes.data;
      this.fetchLoyaltyProductCount();
      let res = await LoyaltyEarnSettingApis.fetchSetting(loyaltyProgramRes.data.id);
      if(res.status == 200) {
        this.earningSetting = res.data;
        this.form.redeem_on.value = res.data.redeem_on
        this.form.points_for_dollar.value = res.data.points_for_dollar
        this.form.redeem_limit.value = res.data.redeem_limit
      }
    }
    this.loadingRedeem = false;
  },

  methods: {
    updateRewardTierCount(val){
      this.rewardTierCount = val
    },
    clearComponent() {
      this.action = {
        component: '',
        data: [],
      };
      let activeItem = document.getElementsByClassName('active-row');
      if(activeItem.length > 0){
        activeItem[0].classList.remove("active-row");
        let subGroup = document.getElementsByClassName('active-sub-row')
        Array.from(subGroup).forEach((item) => {
          item.classList.remove('active-sub-row')
        })
      }
    },

    /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },

    /**
     * Save
     */
    async save() {
       // check first step 
      let validate = await this.validateFields(['redeem_on', 'points_for_dollar']);
      if(!validate) {
        return ;
      }
      this.va_spinner(true);
      let data = {
        redeem_on: this.form.redeem_on.value,    
        points_for_dollar: this.form.points_for_dollar.value,
        redeem_limit: this.form.redeem_limit.value ? this.form.redeem_limit.value : 0,
      }
      let res = {};
      let msg = '';
      if(this.earningSetting.id) {
        // update
        res = await LoyaltyEarnSettingApis.update(this.loyaltyProgram.id, data);
        msg = res.status == 200 ? res.data.msg : Helpers.catchErrorMessage(res.response.data)
      } else {
        // add
        res = await LoyaltyRedeemSettingApis.create(this.loyaltyProgram.id, data);
        msg = res.status == 201 ? res.data.msg : Helpers.catchErrorMessage(res.response.data)
      }

      let settingRes = await LoyaltyEarnSettingApis.fetchSetting(this.loyaltyProgram.id);
      if(settingRes.status == 200) {
        this.earningSetting = settingRes.data;
        // this.form.redeem_on.value = settingRes.data.redeem_on
        // this.form.points_for_dollar.value = settingRes.data.points_for_dollar
        // this.form.redeem_limit.value = settingRes.data.redeem_limit
      }

      this.va_spinner(false);
      this.snackbar.add({
        type: 'info',
        text: msg,
        duration: 3000,
      })
    },

    /**
     * Close select category modal
     */
    closeCategoryWithProductModal() {
      this.categoryWithproductObj.active = false;
    },

    /**
     * Get data selected from category modal
     */
    async setCategoriesWithProduct(data) {
      this.va_spinner(true);
      let res = await LoyaltyEarnSettingApis.assignProducts(this.loyaltyProgram.id, data);
      this.va_spinner(false);
      if(res.status == 200) {
        this.closeCategoryWithProductModal();
        this.fetchLoyaltyProductCount();
      }
      this.snackbar.add({
        type: 'info',
        text: res.status == 200 ? res.data.msg : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
    },

    /**
     * Fetch loyalty prduct count
     */
    async fetchLoyaltyProductCount() {
      let loyaltyProgramProductCountRes = await LoyaltyProgramProductApis.programCount(this.loyaltyProgram.id);
      if(loyaltyProgramProductCountRes.status == 200) {
        this.loyaltyProductCountObj = loyaltyProgramProductCountRes.data;
      } else {
        this.loyaltyProductCountObj = { redeemable_items: 0 };
      }
    },
    /**
     * Change add / update component
     */
    addUpdate(val) {
      this.action = val;
    },

    add(){
      if(this.rewardTierCount<4){
        this.addUpdate({ component: 'Add', data: []})
      }else{
        this.snackbar.add({
          type: 'info',
          text: 'You cannot create more than 4 reward tiers',
          duration: 3000,
        })
      }
    },

    /**
     * Capture selected item
     */
    selectedItem(item) {
      this.action
      this.addUpdate({
        component: 'Update', 
        data: item
      })
    },

    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })

  },
});

</script>

