/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import Env from '@/env';
const apiVersion = 'api/v1';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /**
   * Get All Faq Categories
   */
  getAll(program_id) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/programs/${program_id}/faqs/categories?sortBy=sort_order&order=ASC&faqs=true&page=1&limit=2`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },


  /**
   * crete Faq category
   */
  create(program_id, data) {
    const url = `${Env.loyaltyUrl}/${apiVersion}/programs/${program_id}/faqs/categories`;
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

}