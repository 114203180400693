/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import store from '@/store/index';
import Env from '@/env';
const apiVersion = 'api';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {


  /**
   * Fetch all sales
   */
  fetchAll() {
    const url = `${Env.loginUrl}/${apiVersion}/business-crm-settings/show`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch((err) => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Create 
   */
  create(data) {
    const url = `${Env.loginUrl}/${apiVersion}/business-crm-settings/create`;
    return AuthApis.postApi(url, null, data)
      .then(res => res)
      .catch((err) => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },

  /**
   * Update 
   */
  update(data) {
    const url = `${Env.loginUrl}/${apiVersion}/business-crm-settings/update`;
    return AuthApis.patchApi(url, null, data)
      .then(res => res)
      .catch((err) => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },



}