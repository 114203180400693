/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

import store from '@/store/index';
import Env from '@/env';
const apiVersion = 'api';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';

export default {
  /**
   * Fetch all brands no paging
   */
  fetchAllBrandsNoPagination() {
    let selected = store.getters['location/selected']
    let url = `${Env.productUrl}/${apiVersion}/brand?page=1&perPage=1&isPagination=false`;
    if(selected?.id){
      url += `&locationId=${selected.id}`
    }
    return AuthApis.getApi(url, {
        'Authorization': 'Bearer ' + store.getters['auth/userToken'],
        'api_token': Env.productApiToken,
      })
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
  },
}