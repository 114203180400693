export default {
    namespaced: true,
    state: {
      show: false,
    },
    getters: {
      show: state => state.show,
    },
    mutations: {
      mutatePurchaseOrderWarning(state, data) {
        state.show = data;
      },
    },
    actions: {
      setPurchaseOrderWarning(context, data) {
        context.commit('mutatePurchaseOrderWarning', data);
      },
    },
  };
  