<template>
  <v-card depressed>
    <v-card-text class="ma-0 pa-0">
      <div ref="mapDiv" id="id1" style="width: 100%; height: 60vh"></div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["drawable", "regions", "cancelHandler", "centerLocation"],
  data() {
    return {
      google: {},
      map: {},
      mapRegions: [],
      DrawingManager: {},
      lat: 48.117266,
      lng: -1.6777926,
      type: "",
      radius: "",
      place: "",
      places: [],
      lastId: 0,
      adjuster: {
        center: { lat: 48.117266, lng: -1.6777926 },
        zoom: 13,
        mapTypeControl: true,
        gestureHandling: "cooperative",
        streetViewControl: false,
        mapTypeControlOptions: {
          mapTypeIds: []
        }
      },
      editIndex: 0,
      activeZone: {}
    };
  },
  watch: {
    drawable: function(value) {
      if (!value) {
        this.DrawingManager.setOptions({
          drawingControl: value,
          drawingMode: null
        });
      } else {
        this.DrawingManager.setOptions({ drawingControl: value });
      }
    },
    regions: function(value) {
      this.resetMap();
      this.manager();
    },
    centerLocation: {
      async handler(){
        try {
          // const loader = new Loader({
          //   apiKey: Env.googleApiKey,
          //   libraries: ["drawing", "places"]
          // });
          this.google = await this._gloader.load();
          this.aftermount(google);
          this.drawingManager(google);
        } catch (err) {
          console.log(err);
        }
      },
      deep:true
    },
    cancelHandler: function(value) {
      this.resetMap();
      this.manager();
    }
  },
  async mounted() {
    try {
      // const loader = new Loader({
      //   apiKey: 'AIzaSyBJs2f0TNNui_OcRHgVRTIsJAvGQ0EB7oA',
      //   libraries: ["drawing"]
      // });
      this.google = await this._gloader.load();
      this.aftermount(google);
      this.drawingManager(google);
    } catch (err) {
      console.log(err);
    }
  },
  computed: {},
  methods: {
    async manager() {
      this.mapRegions = [];
      if(this.regions.length !== 0) {
        await this.adjustCenterZoom();
      }
      await this.displayRegions();
      this.mapRegions.forEach((region, index) => {
        this.addEditListeners(region, index);
      });
    },
    resetMap() {
      this.mapRegions.forEach(region => {
        region.setMap(null);
      });
    },
    aftermount(google) {
      this.map = new google.maps.Map(
        document.getElementById("id1"),
        {...this.adjuster, center: this.centerLocation}
      );
      new google.maps.Marker({
        position: this.centerLocation,
        map: this.map,
      });
      if (this.regions.length) {
        this.manager();
      }
    },
    addEditListeners(region, index) {
      let self = this;
      region.getPaths().forEach(function(path, ind) {
        google.maps.event.addListener(path, "insert_at", function() {
          self.shapeEditing(region, index);
        });
        google.maps.event.addListener(path, "remove_at", function() {
          self.shapeEditing(region, index);
        });
        google.maps.event.addListener(path, "set_at", function() {
          self.shapeEditing(region, index);
        });
      });
      google.maps.event.addListener(region, "dragend", function() {
        self.shapeEditing(region, index);
      });
      google.maps.event.addListener(region, "click", function(event) {
        if (!self.regions[index].isEditable) {
          self.zoneOptions(region, index);
        }
      });
    },
    zoneOptions(zone, index) {
      this.activeZone = zone;
      this.editIndex = index;
      this.$emit("selectZoneEmit", this.regions[this.editIndex].id);
    },
    drawingManager(google) {
      this.DrawingManager = new google.maps.drawing.DrawingManager(
        this.getDrawingManagerOptions()
      );
      let self = this;
      this.DrawingManager.setMap(this.map);
      // this.google.maps.event.addListener(
      //   this.DrawingManager,
      //   ['circlecomplete'],
      //   function (circle) {
      // 	self.shapeComplete(circle)
      //   }
      // )
      this.google.maps.event.addListener(
        this.DrawingManager,
        ["polygoncomplete"],
        function(polygon) {
          self.shapeComplete(polygon);
        }
      );
      // this.google.maps.event.addListener(
      //   this.DrawingManager,
      //   ['rectanglecomplete'],
      //   function (rectangle) {
      // 	self.shapeComplete(rectangle)
      //   }
      // )
      //  this.google.maps.event.addListener(this.DrawingManager, 'click', function () {
      //     //alert the index of the polygon
      // })
    },
    getDrawingManagerOptions() {
      return {
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.BOTTOM_LEFT,
          drawingModes: [
            google.maps.drawing.OverlayType.POLYGON
          ]
        },
        //   circleOptions: {
        // ...this.areaStyles(),
        //     editable: true,
        // draggable: true
        //   },
        //   rectangleOptions: {
        // ...this.areaStyles(),
        //     editable: true,
        // draggable: true,
        //   },
        polygonOptions: {
          ...this.areaStyles(),
          editable: true,
          draggable: true
        }
      };
    },
    areaStyles() {
      return {
        strokeColor: "black",
        strokeOpacity: 0.5,
        strokeWeight: 1,
        fillColor: "black",
        fillOpacity: 0.4
        // zIndex: 1
      };
    },

    shapeComplete(area) {
      // console.log(area.getCenter())
      // console.log(area.getBounds())//for rectangle
      // console.log(area.getPath().getArray())//for polygon
      let coordinates = area.getPath().getArray();
      coordinates = coordinates.map(vertex => [vertex.lat(), vertex.lng()]);
      this.mapRegions.push(area);
      this.mapRegions.forEach((region, index) => {
        this.addEditListeners(region, index);
      });
      this.DrawingManager.setOptions(this.getDrawingManagerOptions());
      this.emitAddRegion({ coordinates });
    },
    shapeEditing(area, index) {
      let coordinates = area.getPath().getArray();
      coordinates = coordinates.map(vertex => [vertex.lat(), vertex.lng()]);
      this.emitEditRegion(coordinates, index);
    },
    emitEditRegion(data, index) {
      this.$emit("editRegion", data, index);
    },
    emitAddRegion(data) {
      this.$emit("addRegion", data);
    },
    displayRegions() {
      this.regions.forEach((polygon, index) => {
        const paths = polygon.deliveryPolygon;
        if (paths && paths.length) {
          let newPolygon = new google.maps.Polygon({
            paths: paths.map(vertex => ({
              lat: vertex[0],
              lng: vertex[1]
            })),
            editable: polygon.isEditable,
            draggable: polygon.isEditable,
            strokeColor: "black",
            strokeOpacity: 0.5,
            strokeWeight: 1,
            fillColor: polygon.color,
            fillOpacity: 0.4
          });
          this.mapRegions.push(newPolygon);
          this.mapRegions[this.mapRegions.length - 1].setMap(this.map);
        }
      });
    },
    adjustCenterZoom() {
      let bounds = new this.google.maps.LatLngBounds();
      this.regions.forEach(region => {
        if (region.deliveryPolygon && region.deliveryPolygon.length) {
          const paths = region.deliveryPolygon;
          for (let i = 0; i < paths.length; i++) {
            bounds.extend({
              lat: paths[i][0],
              lng: paths[i][1]
            });
          }
        }
      });
      this.map.fitBounds(bounds);
    }
  }
};
</script>
<style>
div.gm-bundled-control-on-bottom{
  top:95px !important;
  bottom: inherit !important
}
button[title="Stop drawing"]{
  border-radius: 5px 0  0 5px !important;
}
button[title="Draw a shape"]{
  border-radius: 0 5px 5px 0 !important;
}
button[title="Stop drawing"] span {
  padding: 2px;
  height: 40px;
  content: url('data:image/svg+xml;utf8,<svg width="10" height="13" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 25V1L25 15.7692L14.8462 16.6923L22.2308 25.9231L17.6154 28.6923L9.30769 18.5385L1 25Z" fill="black" stroke="black"/></svg>');
}
button[title="Draw a shape"] span{
  height: 40px;
  padding: 2px;
  content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAkCAYAAADsHujfAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIeSURBVHgBzZgxbsIwFIZ/6IzUzixe2ejAnrFj4QS+AfQEbQ+A1BuQsRv0AqQ7Ax1hCgdAooKlW2rHdpuYJI5dE/WTfhHZsf1iv/dsA7hBmCKmA1PCNJdljUIyBmR1QMPGLPjAo9EoWa1WyXK5TIbDoTImgiMt2MMHBDMA3W43LTgejxgMBvzxk+kGDrRhDx8MnU7np+B0OqnHayYKhyW6gj13fKD9fo9er5cWTKdTbDYbVX/PNJG//IUvph0uAEG5s/aZAqaQKdbq5tJAb9FGMp2r3wjFHfIyqg3uLdrUl1LY4TXaxrLhDPakg3IDttttKm6QLD/YRA2BWOMd0zPsqYw2m6h5gXDEB6Z32FMVbW91O6EQU7iGOwQetgbloLUbVBjzAXO0FfIoGz7BDxEcoo7IRjH8oZaH2DRS1gfwQwCHD6NwzxllTGz7JBBWx/B72EmzKyz8I4SDQ9XA6B+8Qt+Y5vBLHwb/IGjmDEph8I/crsjl4wxaNg5KlruFC51BC1COfwuRXXO0YT6D8v2F4m8QqV2REYo0afHl4GcFbWmy4l80g5vfUNnHAgZry5w1kJ3EWl0Eu1kKUTMdEIhwrdoVg0yH2VkawzxLa/l+Hx4hEKlan6UZzvck/m6EfG4iuABUG0jNEpVGNX5HJji/y6TyfUe2geLXJ0pP7Xojl7uviRAiaZnyUw6Xu29dTKf2VzQEwT/5Q0cZY8pPKd/AIlOL/NU7fgAAAABJRU5ErkJggg==')
}
</style>