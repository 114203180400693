<template>
    <div class="absolute shadow-2xl top-0 left-0 right-0 w-full z-10 text-white flex-1 col-span-1 border-0 rounded-t-xl overflow-x-scroll overflow-hidden">
        <div class="w-[550px] mx-auto mt-0 pt-3 bg-[#464851] px-[16px] py-[16px] rounded-lg">
            <div class="flex justify-center relative pb-5">
                <span class="text-3xl">Add to Category</span>
                <a  @click.prevent="close"  href="" class="right-0 top-2 absolute"><mdicon class="cursor-pointer hover:text-slate-200 " name="close"/></a>
            </div>
            <div class="px-[16px] py-[16px] bg-[#2B2C32] rounded-xl">
                <div class="min-h-[200px] max-h-[400px] overflow-auto">
                    <div class="text-center text-sm">You must assign a product to one regular category, and may assign them to multiple featured categories.</div>
                    <block-loading v-if="loading" />
                    <div v-if="!loading && category?.length>0" class="mt-2 border-2 rounded border-theme-grey">
                        <div class="p-2 font-bold text-grey">Regular Categories</div>
                        <div v-for="each in category" :key="each.id" class="flex items-center justify-between p-2 font-bold even:bg-theme-modalbg">
                            <div @click="selectCategory(each)" :class="each.parent?'ml-4':''" class="flex items-center cursor-pointer">
                                <div class="mr-2">
                                    <svg v-if="isCategoryAdded(each.id)" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.875 19.2125C8.09167 19.7375 9.38333 20 10.75 20C12.1333 20 13.4333 19.7375 14.65 19.2125C15.8667 18.6875 16.925 17.9708 17.825 17.0625C18.725 16.1542 19.4375 15.0917 19.9625 13.875C20.4875 12.6583 20.75 11.3667 20.75 10C20.75 8.61667 20.4875 7.31667 19.9625 6.1C19.4375 4.88333 18.725 3.825 17.825 2.925C16.925 2.025 15.8667 1.3125 14.65 0.7875C13.4333 0.2625 12.1333 0 10.75 0C9.38333 0 8.09167 0.2625 6.875 0.7875C5.65833 1.3125 4.59583 2.025 3.6875 2.925C2.77917 3.825 2.0625 4.88333 1.5375 6.1C1.0125 7.31667 0.75 8.61667 0.75 10C0.75 11.3667 1.0125 12.6583 1.5375 13.875C2.0625 15.0917 2.77917 16.1542 3.6875 17.0625C4.59583 17.9708 5.65833 18.6875 6.875 19.2125ZM16.775 16.0125C15.125 17.6708 13.1167 18.5 10.75 18.5C8.4 18.5 6.39583 17.6708 4.7375 16.0125C3.07917 14.3542 2.25 12.35 2.25 10C2.25 7.63333 3.07917 5.625 4.7375 3.975C6.39583 2.325 8.4 1.5 10.75 1.5C13.1167 1.5 15.125 2.325 16.775 3.975C18.425 5.625 19.25 7.63333 19.25 10C19.25 12.35 18.425 14.3542 16.775 16.0125Z" fill="white"/>
                                        <path d="M10.75 14.65C12.05 14.65 13.15 14.2 14.05 13.3C14.95 12.4 15.4 11.3 15.4 10C15.4 8.7 14.95 7.6 14.05 6.7C13.15 5.8 12.05 5.35 10.75 5.35C9.45 5.35 8.35 5.8 7.45 6.7C6.55 7.6 6.1 8.7 6.1 10C6.1 11.3 6.55 12.4 7.45 13.3C8.35 14.2 9.45 14.65 10.75 14.65Z" fill="#2BA66B"/>
                                    </svg>
                                    <svg v-else width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.75 20C9.38333 20 8.09167 19.7375 6.875 19.2125C5.65833 18.6875 4.59583 17.9708 3.6875 17.0625C2.77917 16.1542 2.0625 15.0917 1.5375 13.875C1.0125 12.6583 0.75 11.3667 0.75 10C0.75 8.61667 1.0125 7.31667 1.5375 6.1C2.0625 4.88333 2.77917 3.825 3.6875 2.925C4.59583 2.025 5.65833 1.3125 6.875 0.7875C8.09167 0.2625 9.38333 0 10.75 0C12.1333 0 13.4333 0.2625 14.65 0.7875C15.8667 1.3125 16.925 2.025 17.825 2.925C18.725 3.825 19.4375 4.88333 19.9625 6.1C20.4875 7.31667 20.75 8.61667 20.75 10C20.75 11.3667 20.4875 12.6583 19.9625 13.875C19.4375 15.0917 18.725 16.1542 17.825 17.0625C16.925 17.9708 15.8667 18.6875 14.65 19.2125C13.4333 19.7375 12.1333 20 10.75 20ZM10.75 18.5C13.1167 18.5 15.125 17.6708 16.775 16.0125C18.425 14.3542 19.25 12.35 19.25 10C19.25 7.63333 18.425 5.625 16.775 3.975C15.125 2.325 13.1167 1.5 10.75 1.5C8.4 1.5 6.39583 2.325 4.7375 3.975C3.07917 5.625 2.25 7.63333 2.25 10C2.25 12.35 3.07917 14.3542 4.7375 16.0125C6.39583 17.6708 8.4 18.5 10.75 18.5Z" fill="white"/>
                                    </svg>
                                </div>
                                <span class="block w-6 h-6 rounded border border-[#333]" :style="`background-color:${each.posColour};`"></span>
                                <span class="ml-2">{{each.name}}</span>
                            </div>
                            <div class="text-[10px] tracking-widest text-grey">{{each.parent?'SUB CATEGORY':'MAIN CATEGORY'}}</div>
                        </div>
                    </div>
                    <div v-if="!loading && featuredCat?.length>0" class="mt-2 border-2 rounded border-theme-grey">
                        <div class="p-2 font-bold text-grey">Featured Categories</div>
                        <div v-for="each in featuredCat" :key="each.id" class="flex items-center justify-between p-2 font-bold even:bg-theme-modalbg">
                            <div @click="selectFeatured(each.id)" class="flex items-center cursor-pointer">
                                <div>
                                    <svg v-if="isFeaturedAdded(each.id)" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.2 17.55C1.5 17.85 1.85 18 2.25 18H17.25C17.65 18 18 17.85 18.3 17.55C18.6 17.25 18.75 16.9 18.75 16.5V1.5C18.75 1.1 18.6 0.75 18.3 0.45C18 0.15 17.65 0 17.25 0H2.25C1.85 0 1.5 0.15 1.2 0.45C0.9 0.75 0.75 1.1 0.75 1.5V16.5C0.75 16.9 0.9 17.25 1.2 17.55ZM17.25 16.5H2.25V1.5H17.25V16.5Z" fill="#2BA66B"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 16.5H17.25V1.5H2.25V16.5ZM15.45 5.75L8.225 12.975L4.175 8.925L5.25 7.85L8.225 10.825L14.375 4.675L15.45 5.75Z" fill="#2BA66B"/>
                                        <path d="M8.225 12.975L15.45 5.75L14.375 4.675L8.225 10.825L5.25 7.85L4.175 8.925L8.225 12.975Z" fill="white"/>
                                    </svg>
                                    <svg v-else width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.25 18C1.85 18 1.5 17.85 1.2 17.55C0.9 17.25 0.75 16.9 0.75 16.5V1.5C0.75 1.1 0.9 0.75 1.2 0.45C1.5 0.15 1.85 0 2.25 0H17.25C17.65 0 18 0.15 18.3 0.45C18.6 0.75 18.75 1.1 18.75 1.5V16.5C18.75 16.9 18.6 17.25 18.3 17.55C18 17.85 17.65 18 17.25 18H2.25ZM2.25 16.5H17.25V1.5H2.25V16.5Z" fill="white"/>
                                    </svg>
                                </div>
                                <div>
                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.2255 1.46353C13.0759 1.00287 12.4241 1.00287 12.2745 1.46353L10.1681 7.9463C10.1012 8.15232 9.90917 8.2918 9.69256 8.2918H2.87616C2.3918 8.2918 2.19041 8.9116 2.58227 9.1963L8.09685 13.2029C8.2721 13.3302 8.34543 13.5559 8.27849 13.7619L6.17211 20.2447C6.02243 20.7053 6.54967 21.0884 6.94153 20.8037L12.4561 16.7971C12.6314 16.6698 12.8686 16.6698 13.0439 16.7971L18.5585 20.8037C18.9503 21.0884 19.4776 20.7053 19.3279 20.2447L17.2215 13.7619C17.1546 13.5559 17.2279 13.3302 17.4031 13.2029L22.9177 9.1963C23.3096 8.9116 23.1082 8.2918 22.6238 8.2918H15.8074C15.5908 8.2918 15.3988 8.15232 15.3319 7.9463L13.2255 1.46353Z" fill="#FCB852"/>
                                        <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 15.75 10)" fill="#464851"/>
                                        <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 11.75 10)" fill="#464851"/>
                                        <path d="M15.7578 13.945C15.7762 13.6917 15.5637 13.5 15.3098 13.5H10.2059C9.94986 13.5 9.73632 13.6945 9.7554 13.9498C9.7936 14.461 9.98061 15.2561 10.7515 15.4995C11.9178 15.8677 13.5721 15.9122 14.7554 15.5C15.5348 15.2285 15.7213 14.4468 15.7578 13.945Z" fill="#464851"/>
                                    </svg>
                                </div>
                                <span class="ml-2">{{each.name}}</span>
                            </div>
                            <div class="text-[10px] tracking-widest text-grey">{{each.parent?'SUB CATEGORY':'MAIN CATEGORY'}}</div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-center">
                    <button @click="addProductToCategory" class="p-2 mt-2 w-72" :disabled="!selectedCategory && selectedFeaturedCat.length==0" :class="(selectedCategory || selectedFeaturedCat.length>0)?'bg-theme-green':'bg-theme-borderblack'">Continue</button>
                </div>
            </div>
            <!-- <div class="flex justify-center">
                <a class="mt-1" href="">Close</a>
            </div> -->
        </div>
    </div>
</template>

<script>
import { useSnackbar } from "vue3-snackbar"
import StringHelper from '@/helpers/string'
import BusinessCategoryApis from "@/controllers/BusinessCategoryApis";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { ref, defineComponent } from 'vue'
import BusinessProductApis from "@/controllers/BusinessProductApis";
export default defineComponent({
    props: ['data'],
    setup(){
        const snackbar = useSnackbar();
        const loading = ref(false)
        const category= ref([])
        const featuredCat= ref([])
        const selectedCategory = ref('')
        const selectedFeaturedCat = ref([])

        return {snackbar, loading, category, featuredCat, selectedCategory, selectedFeaturedCat}
    },
    async mounted(){
        this.loading = true
        await this.fetchCategory()
        await this.fetchFeaturedCategory()
        this.loading = false
    },
    computed: {
        ...mapGetters({
            vg_activeBusiness: 'auth/activeBusiness',
            vg_selectedLocation: 'location/selected'
        }),
    },
    methods: {
        async fetchCategory(){
            this.category = []
            let res = await BusinessCategoryApis.fetchAllBusinessCategries({
                businessId: this.vg_activeBusiness.id,
                page: 1,
                perPage: 999,
            });
            if(res?.data?.payload?.category){
                for(let i=0; i<res.data.payload.category.length; i++){
                    if(!res.data.payload.category[i].featured){
                        this.category.push(res.data.payload.category[i])
                        if(res.data.payload.category[i].subCategories){
                          this.category = [...this.category, ...res.data.payload.category[i].subCategories]
                        }
                    }
                }
            }
            
        },
        async fetchFeaturedCategory(){
            let res = await BusinessCategoryApis.fetchFeaturedCategoryByLocation(this.vg_selectedLocation.id)
            if(res?.data?.payload?.featuredCategories){
                this.featuredCat = res.data.payload.featuredCategories
            }
        },
        isCategoryAdded(id){
            if(this.selectedCategory){
                return this.selectedCategory==id
            }
        },
        isFeaturedAdded(id){
            if(this.selectedFeaturedCat){
                return this.selectedFeaturedCat.includes(id)
            }
        },
        selectCategory(item){
            this.selectedCategory = item.id
        },
        selectFeatured(id){
            if(this.selectedFeaturedCat && this.selectedFeaturedCat.length>0){
                const ind = this.selectedFeaturedCat.indexOf(id)
                if(ind>-1){
                    this.selectedFeaturedCat.splice(ind, 1)
                }else{
                    this.selectedFeaturedCat.push(id)
                }
            }else{
                this.selectedFeaturedCat.push(id)
            }
        },
        addProductToCategory(){
            let self = this
            self.vm_spinner(true)
            let promises = []
            if(this.selectedCategory){
                promises.push(BusinessProductApis.addProductToCategoryAndFeaturedCategory({categoryId: this.selectedCategory, productIds: StringHelper.arrayOfStringToNumber(this.data)}))
            }
            for(let i=0; i<this.selectedFeaturedCat.length; i++){
                promises.push(BusinessCategoryApis.syncFeaturedProduct(this.selectedFeaturedCat[i], {locationId: this.vg_selectedLocation.id, productIds: StringHelper.arrayOfStringToNumber(this.data), replace: false}))
            }
            Promise.all(promises).then(() => {
                self.vm_spinner(false)
                self.snackbar.add({
                    type: 'info',
                    text: "Selected products added to selected categories.",
                    duration: 3000,
                })
                self.$emit('close')
                self.emitter.emit('reloadProductList', true)
            })
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        }),
        close(){
            this.$emit('close')
        }
    }
})
</script>