import LoginApis from '@/controllers/LoginApis';
import BusinessApis from '@/controllers/BusinessApis';
import store from '@/store';
import LocationsApis from '@/controllers/LocationsApis';
import BrandingApis from '@/controllers/BrandingApis';

const structure = {
  namespaced: true,
  state: {
    user: {
      email: null,
      id: null,
      name: null,
      phone: null,
      status: null,
      token: null,
      userType: null
    },
    userRoles: [],
    userPermissions: [],
    businesses: [],
    activeBusiness: {},
    branding: {}
  },
  getters: {
    user: state => state.user,
    userRoles: state => state.userRoles,
    userPermissions: state => state.userPermissions,
    userToken: state => state.user.token,
    isUserTypeSuper: state => state.user.userType=='super',
    userType: state => state.user.userType,
    businesses: state => state.businesses,
    activeBusiness: state => state.activeBusiness,
    branding: state => state.branding
  },
  mutations: {
    
    mutateUser(state, data) {
      state.user = data;
    },

    mutateUserByKey(state, data) {
      for(let key in data){
        if(state.user.hasOwnProperty(key)){
          state.user[key] = data[key];
        }
      }
    },

    mutateBusiness(state, data) {
      state.businesses = data;
    },

    mutateActiveBusiness(state, data) {
      state.activeBusiness = data;
    },
    mutateUserPermissions(state, data) {
      state.userPermissions = data;
    },
    mutateUserRoles(state, data) {
      state.userRoles = data;
    },
    mutateBranding(state, data){
      state.branding = data
    }

  },
  actions: {    
    async setUser(context, data) {
      // hit logn api, set data on response
      let res = await LoginApis.login({
        username: data.email,
        password: data.password,
      });

      // if success return with commit
      if(res.status == 201) {
        await context.commit('mutateUser', res.data.payload);
        
        let myDetailRes  = await LoginApis.getMyDetails('?includeRoles=1&includePermissions=1');
        context.commit('mutateUserPermissions', (myDetailRes.status == 200 ? myDetailRes.data.payload.permissions : []) );
        context.commit('mutateUserRoles', (myDetailRes.status == 200 ? myDetailRes.data.payload.roles : []));

      } else {
        // if return return with commit, login failed mean set blank user
        context.dispatch('clearUserData', {});
      }
      
      return res;
    },

    clearUserData(context, data) {
      // hit logout api, set data on response
      context.commit('mutateUser', {
        email: null,
        id: null,
        name: null,
        phone: null,
        status: null,
        token: null,
        userType: null
      });
      context.commit('mutateActiveBusiness', {});
      context.commit('mutateUserPermissions', []);
      context.commit('mutateUserRoles', []);
      store.commit('location/mutateSelectedLocation', {});
    },

    async setUserBusinesses(context) {
      let res: any = await BusinessApis.fetchUserBusinesses();
      if(res.status == 200) {
        context.commit('mutateBusiness', res.data.payload);
      } else {
        context.commit('mutateBusiness', []);
      }
      return res;
    },

    async setUserActiveBusiness(context, data: any ) {
      // Pass business id to get new token
      let res: any = await LoginApis.getRefreshToken({
        businessId: data.id,
        locationId: null,
      });
      // set this business as active
      if(res.status == 200) {

        
        if(context.state.userRoles.indexOf('employee') > -1) {
          // fetch api  if no locaiton for employee then redirect 
          let locationRes = await LocationsApis.fetchAllLocations({
            'Authorization': 'Bearer '+ res.data.payload.newToken
          });

          if(locationRes.status == 200 && locationRes.data.payload.length == 0) {
            return {
              response:{
                status: 401,
                data: {
                  msg: 'Logging out, No location available for the user.'
                }
              }
            }
          }
        }

        
         // set the business as active
         context.commit('mutateActiveBusiness', data);
         // now here we get new token that need to replaced
         context.commit('mutateUserByKey', {
           token: res.data.payload.newToken
         })

        let branding:any = await BrandingApis.fetchBusinessBranding(data.id)
        if(branding.status==200){
          context.commit('mutateBranding', branding.data.payload)
        }

      } else {
        context.commit('mutateActiveBusiness', {});
      }

      return res;
    },
  },
};

export default structure; 