import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c50dea2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex bg-[#2B2C32] rounded-full py-1 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("ul", _hoisted_1, [
      _createElementVNode("li", {
        class: _normalizeClass('mr-4' + (_ctx.activeTab == 'location' ? ' active' : ''))
      }, [
        _createElementVNode("a", {
          href: "#",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.changeTab('location')), ["prevent"])),
          class: "inline-block text-theme-white tracking-widest text-sm font-semibold px-2 upper"
        }, " Location Analytics ")
      ], 2),
      _createElementVNode("li", {
        class: _normalizeClass(_ctx.activeTab == 'business' ? ' active' : '')
      }, [
        _createElementVNode("a", {
          href: "#",
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.changeTab('business')), ["prevent"])),
          class: "text-theme-theme-white tracking-widest text-sm font-semibold upper"
        }, " Business Analytics ")
      ], 2)
    ])
  ]))
}